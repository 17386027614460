<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          Languages
        </h1>

        <Button
          id="button_add-language"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="addLanguage"
        >
          Add New Language
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">Show</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              class="py-2"
              name="results_per_page"
              :items="[10, 25, 50, 100]"
              @onChangeFunction="getAllLanguages"
            />
          </div>
          <span class="text-sm">entries</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              @dataFetchfunction="getAllLanguages(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>Country</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'country_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>Language Code</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('lang_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'lang_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>Language Name</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('lang_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'lang_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(language, index) in languages"
                :key="index"
              >
                <td> {{ language.country_name }} </td>
                <td> {{ language.lang_code }} </td>
                <td> {{ language.lang_name }} </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="editLanguage(language.lang_id)"
                    />
                    <tippy
                      v-if="true"
                      placement="left"
                    >
                      <button-circle
                        type="danger"
                        icon="trash"
                      />
                      <template #content>
                        <span>You won't be able to delete this Language, as it is
                          attached to a User.</span>
                      </template>
                    </tippy>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="true" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        v-if="languages.length"
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllLanguages($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    :close-button-text="slideOver.close_button_text"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <div
            v-if="languageId != null"
            class="
              px-3
              py-2.5
              border border-gray-200
              rounded
              bg-gray-100
              text-sm
            "
          >
            Sample language file
            <router-link
              class="text-primary hover:underline"
              to=""
            >
              download
            </router-link>
          </div>
        </div>

        <div>
          <form-label for="country">
            Country <required />
          </form-label>
          <form-select
            id="country"
            v-model="form.country_code"
            :value="form.country_code"
            :items="countryOptions"
            name="country"
            class="mt-1"
          />
          <form-error
            v-if="errors.country_code"
            :message="errors.country_code ? errors.country_code : ''"
          />
        </div>

        <div>
          <form-label for="lang_code">
            Language Code <required />
          </form-label>
          <form-input
            id="lang_code"
            v-model="form.lang_code"
            type="text"
            name="lang_code"
            class="mt-1"
          />
          <form-error
            v-if="errors.lang_code"
            :message="errors.lang_code ? errors.lang_code : ''"
          />
        </div>

        <div>
          <form-label for="lang_name">
            Language Name <required />
          </form-label>
          <form-input
            id="lang_name"
            v-model="form.lang_name"
            type="text"
            name="lang_name"
            class="mt-1"
          />
          <form-error
            v-if="errors.lang_name"
            :message="errors.lang_name ? errors.lang_name : ''"
          />
        </div>

        <div>
          <form-label>
            Language File <required />
          </form-label>
          <div class="mt-1 break-words text-sm">
            selected_file_name.json
          </div>
          <input
            ref="file"
            class="hidden"
            type="file"
          >
          <button-secondary
            id="lang-file"
            name="lang-file"
            class="mt-1 btn-xs"
            type="button"
            @click="$refs.file.click()"
          >
            Browse File
          </button-secondary>
          <form-error
            v-if="errors.file"
            :message="errors.file ? errors.file : ''"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="save()"
      >
        Save
      </Button>
    </template>
  </slide-over>
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { Tippy } from 'vue-tippy'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import FormError from '@/components/FormError.vue'
export default {
  name: 'Languages',

  components: {
    Tippy,
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormLabel,
    FormInput,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    FormError
  },
  

  setup() {
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const store = useStore()
    const lastPage = ref(0)
    const totatlRecord = ref(0)
    const languages = ref(0)
    const sortColumn = ref('')
    const page = ref(1)
    const perPage = ref(10)
    const searchText = ref('')
    const languageId = ref(null)
    let countryOptions = ref(0)
    const file = ref(null)
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      close_button_text: 'Close',
      title: 'Create Language',
    })

    const form = reactive({
      lang_id : null,
      country_code: null,
      lang_code: null,
      lang_name: null,
      row_version : 1
    })

    const errors = reactive({
      country_code: null,
      lang_code: null,
      lang_name: null,
      file : null,
    })

    const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    });

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('country')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }
getCountry()
 const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })

  async function getCountry() {
      await store.dispatch('Country/getCountryList')
      countryOptions.value = await store.state.Country.countryListDropDown
    }

    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getAllLanguages()
    }

    async function getAllLanguages(
      currentPage,
      column = sortColumn.value,
      search = '@#@'
    ) {
      isLoading.value = true
      const OptionValue = {}
      OptionValue.page = currentPage
      OptionValue.column = column
      OptionValue.sortColumnDirection = sortColumnDirection.value
      OptionValue.perPage = perPage.value
      if (search != '@#@') {
        searchText.value = search
        OptionValue.searchText = searchText.value
      } else {
        OptionValue.searchText = searchText.value
      }
      await store.dispatch('Languages/getLanguagesList', OptionValue)
      if ((await store.state.Languages.languagesListData) != null) {
        languages.value = await store.state.Languages.languagesListData.data
        lastPage.value = await store.state.Languages.languagesListData.last_page
        totatlRecord.value = await store.state.Languages.languagesListData.total
      }
      isLoading.value = false
    }

    async function editLanguage(id){
      reetForm()
      resetErrors()
      languageId.value = id
      file.value = null
        await store.dispatch('Languages/viewLanguage', id)
        if ((await store.state.Languages.languageGetData.status) != null) {
          let data = await store.state.Languages.languageGetData.data
          form.lang_id = await data.lang_id
          form.country_code = await data.country_code
          form.lang_code = await data.lang_code
          form.lang_name = await data.lang_name
          form.row_version = await data.row_version
          slideOver.show = true
      }
    }

    async function addLanguage(){
          reetForm()
          resetErrors()
          file.value = null
          form.lang_name =  null
          slideOver.show = true
    }

    async function save(){
      resetErrors()
      if((languageId.value == null) &&(file.value.files[0] === undefined)){
            errors.file=  "Please select a file to import."
      } else {
       let data = null
       let request = new FormData();
         request.append('lang_id', form.lang_id);
         request.append('country_code', form.country_code);
         request.append('lang_code', form.lang_code);
         request.append('lang_name',  form.lang_name);
         request.append('file', file.value.files[0]); 
         request.append('row_version', form.row_version); 
       if(languageId.value == null){
        await store.dispatch('Languages/postLanguage', request )
          data = await store.state.Languages.languagePostData
          modal.message = 'Language insert successfully.'
       }else {
         await store.dispatch('Languages/updateLanguage', request)
           data = await store.state.Languages.languagePostData
           modal.message = 'Language update successfully.'
       }

      if (data.status == true) {
        isLoading.value = false
        reetForm()
        languageId.value = null
        slideOver.show = false
        modal.confirm_button = false
        modal.button_text = 'Ok'
        modal.type = 'success'
        modal.title = 'Success'
        modal.show = true
        file.value.files[0] = 'undefined'
      } else if(data.status == false){
        modal.type = 'error'
        modal.title = 'Error'
        modal.array = false
        modal.message = data.msg
        modal.show = true
      } else {
        isLoading.value = false
        bindValidationErrors(data.data.errors.validations)
      }
      }
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function reetForm() {
      form.lang_id = null
      form.country_code = null
      form.lang_code = null
      form.lang_name= null
      form.row_version = null
    }

    function resetErrors() {
      errors.country_code = null
      errors.lang_code = null
      errors.lang_name= null
      errors.file= null
    }

    function closeAlert() {
      modal.show = false
    }

    return {
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      getAllLanguages,
      sortColumn,
      languages,
      lastPage,
      totatlRecord,
      page,
      perPage,
      searchText, 
      sort,
      editLanguage,
      addLanguage,
      save,
      countryOptions,
      form,
      modal,
      errors,
      closeAlert,
      file,
      languageId
    }
  },
}
</script>
