<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2
              id="heading-reset_password"
              class="mb-3 text-2xl font-medium text-black"
            >
              Reset Password
            </h2>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form
                method="POST"
                class="space-y-5"
              >
                <div>
                  <div class="relative">
                    <form-label
                      id="label-username"
                      for="username"
                    >
                      Username
                    </form-label>
                    <form-input
                      id="username"
                      v-model="form.showUsername"
                      class="mt-1 border-primary-lighter"
                      name="username"
                      disabled
                      type="text"
                    />
                  </div>
                </div>
                <div>
                  <form-label
                    id="label-new_password"
                    for="password"
                  >
                    New Password
                  </form-label>
                  <div class="mt-1 relative">
                    <form-input
                      id="password"
                      v-model="form.password"
                      class="pr-10 border-primary-lighter"
                      name="password"
                      autofocus
                      :type="form.passwordFieldType"
                    />
                    <button
                      class="
                        absolute
                        w-7
                        h-7
                        inset-y-0
                        right-0
                        flex
                        items-center
                        justify-center
                        my-auto
                        mr-1
                        p-1
                        rounded-sm
                        opacity-75
                        hover:opacity-100
                        focus:ring-2 focus:ring-offset-1 focus:ring-primary
                        focus:outline-none
                        z-10
                      "
                      type="button"
                      @click="switchPasswordVisibility"
                    >
                      <svg-icon
                        v-if="form.passwordFieldType === 'text'"
                        class="w-5 h-5 text-gray-900"
                        icon="visible"
                      />
                      <svg-icon
                        v-if="form.passwordFieldType === 'password'"
                        class="w-5 h-5 text-gray-900"
                        icon="invisible"
                      />
                    </button>
                  </div>
                  <form-error
                    v-if="errors.validations"
                    :message="
                      errors.validations.password
                        ? errors.validations.password[0]
                        : ''
                    "
                  />
                </div>

                <div>
                  <form-label
                    id="label-confirm_password"
                    for="confirm_password"
                  >
                    Confirm Password
                  </form-label>
                  <div class="mt-1 relative">
                    <form-input
                      id="confirm_password"
                      v-model="form.password_confirmation"
                      class="pr-10 border-primary-lighter"
                      name="confirm_password"
                      :type="form.passwordFieldType"
                    />
                    <button
                      class="
                        absolute
                        w-7
                        h-7
                        inset-y-0
                        right-0
                        flex
                        items-center
                        justify-center
                        my-auto
                        mr-1
                        p-1
                        rounded-sm
                        opacity-75
                        hover:opacity-100
                        focus:ring-2 focus:ring-offset-1 focus:ring-primary
                        focus:outline-none
                        z-10
                      "
                      type="button"
                      @click="switchPasswordVisibility"
                    >
                      <svg-icon
                        v-if="form.passwordFieldType === 'text'"
                        class="w-5 h-5 text-gray-900"
                        icon="visible"
                      />
                      <svg-icon
                        v-if="form.passwordFieldType === 'password'"
                        class="w-5 h-5 text-gray-900"
                        icon="invisible"
                      />
                    </button>
                  </div>
                  <form-error
                    v-if="errors.validations"
                    :message="
                      errors.validations.password_confirmation
                        ? errors.validations.password_confirmation[0]
                        : ''
                    "
                  />
                </div>

                <div>
                  <Button
                    id="button-reset_password"
                    class="w-full"
                    type="submit"
                    @click.prevent="changePassword"
                  >
                    Reset Password
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { reactive, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'

export default {
  name: 'ResetPassword',

  components: {
    Modal,
    Button,
    SvgIcon,
    Loading,
    FormError,
    LogoWhite,
    FormLabel,
    FormInput,
    AuthFooter,
  },

  setup() {
    const form = reactive({
      username: '',
      password: '',
      password_confirmation: '',
      token: '',
      passwordFieldType: 'password',
      showUsername: '',
    })
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })
    const errors = reactive({
      validations: '',
    })
    const isLoading = ref(false)
    const fullPage = ref(true)
    const router = useRouter()
    const route = useRoute()

    onMounted(() => {
      form.token = route.params.token
      form.username = route.query.username
      form.showUsername = route.query.username
      isLoading.value = true
      axios
        .get('/password/reset/' + form.token + '?email=' + form.username)
        .then((response) => {
          isLoading.value = false
          if (!response.data.data && !response.data.status) {
            modal.type = 'error'
            modal.title = 'Sorry'
            modal.message = 'Your password reset link has expired.'
            modal.close_button = true
            modal.show = true
          }
        })
        .catch((exception) => {
          isLoading.value = false
          console.log(exception)
        })
    })

    function changePassword() {
      isLoading.value = true
      axios
        .post('/password/reset', form)
        .then((response) => {
          isLoading.value = false
          if (response.data.status) {
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Password reset successful.'
            modal.close_button = true
            modal.show = true
            resetErrors()
          } else if (response.data.data.errors) {
            errors.validations = response.data.data.errors.validations
          } else {
            console.error('Error!')
          }
        })
        .catch((exception) => {
          isLoading.value = false
          console.log(exception)
        })
    }

    async function switchPasswordVisibility() {
      form.passwordFieldType =
        form.passwordFieldType === 'password' ? 'text' : 'password'
    }

    function closeAlert() {
      modal.show = false
      router.push({ name: 'signin' })
    }

    function resetErrors() {
      errors.validations = ''
    }

    return {
      form,
      modal,
      route,
      errors,
      fullPage,
      onMounted,
      isLoading,
      closeAlert,
      changePassword,
      switchPasswordVisibility,
    }
  },
}
</script>
