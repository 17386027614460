<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Terms & Conditions") }}
        </h1>

        <Button
          id="button_add-terms"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="$router.push({ name: 'terms-add' })"
        >
          {{ t("Add Terms & Conditions") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllTermsAndConditions()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="
                getAllTermsAndConditions(1, sortColumn, $event)
              "
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="terms_and_condition_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Version") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('version')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'version'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Published Date") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('published_date')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'published_date'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(terms_and_condition, index) in termsAndCondition"
                :id="'terms_and_condition_' + index"
                :key="index"
              >
                <td>
                  {{ terms_and_condition.version }}
                </td>
                <td>
                  {{
                    terms_and_condition.published_date
                      ? formatDateTime(terms_and_condition.published_date)
                      : ''
                  }}
                </td>

                <td class="text-center">
                  <badge
                    v-if="terms_and_condition.status == 'ACT'"
                    type="success"
                    text="Published"
                  />
                  <badge
                    v-else
                    type="danger"
                    text="Unpublished"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'terms_and_condition_view_' + index"
                      type="info"
                      icon="eye"
                      @click="viewTermsAndCondition(terms_and_condition.tc_id)"
                    />
                    <button-circle
                      :id="'terms_and_condition_edit_' + index"
                      type="info"
                      icon="edit"
                      :disabled="terms_and_condition.published_date"
                      @click="editTermsAndCondition(terms_and_condition.tc_id)"
                    />
                    <button-circle
                      :id="'terms_and_condition_delete_' + index"
                      type="danger"
                      icon="trash"
                      :disabled="terms_and_condition.published_date"
                      @click="deleteTerms(terms_and_condition.tc_id)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!termsAndCondition.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllTermsAndConditions($event)"
      />
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import moment from 'moment'
import { useRouter } from 'vue-router'
import Search from '@/components/Search'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'
import router from "../../routes";

export default {
  name: 'Terms',

  components: {
    Badge,
    Modal,
    Button,
    Search,
    SvgIcon,
    Loading,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
  },

  setup() {
    const { t } = useI18n()
    const termsAndCondition = ref(0)
    const sortColumnDirection = ref('desc')
    const router = useRouter()
    const searchText = ref('')
    const searchParam = ref('')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const lastPage = ref(0)
    const perPage = ref(10)
    const IdToDelete = ref(false)
    const sortColumn = ref('')
    const totatlRecord = ref(0)
    const currentPage = ref(0)

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: false,
      button_text: 'Done',
    })
    function getAllTermsAndConditions(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        searchText.value = search
      }

      axios
        .get('/v1/terms-and-condition', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            termsAndCondition.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totatlRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getAllTermsAndConditions(1, sortColumn.value, searchText.value)
    }

    function editTermsAndCondition(tc_id) {
      router.push({ name: 'terms-edit', params: { tc_id: tc_id } })
    }

    function viewTermsAndCondition(tc_id) {
      router.push({ name: 'term', params: { tc_id: tc_id } })
    }

    function deleteTerms(id) {
      IdToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function confirmAlert() {
      deleteTermsAndCondition()
      modal.show = false
      return true
    }

    function deleteTermsAndCondition() {
      let data = {}
      data.tc_id = IdToDelete.value

      axios
        .delete('/v1/delete-terms-and-condition/' + IdToDelete.value, {params: data})
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getAllTermsAndConditions()
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function closeAlert() {
      if (modal.button_text === 'No') {
        modal.show = false
      } else {
        modal.show = false
        router.push('/settings/terms')
      }
    }

    function formatDateTime(dateTime, format = 'YYYY-MM-DD HH:mm') {
      if (format == 'YYYY-MM-DD HH:mm') {
        return moment(dateTime).format(format)
      } else {
        return moment(dateTime, format).format(format)
      }
    }

    return {
      t,
      sort,
      onMounted,
      modal,
      lastPage,
      fullPage,
      perPage,
      isLoading,
      IdToDelete,
      searchText,
      searchParam,
      sortColumn,
      termsAndCondition,
      sortColumnDirection,
      editTermsAndCondition,
      viewTermsAndCondition,
      deleteTermsAndCondition,
      getAllTermsAndConditions,
      confirmAlert,
      closeAlert,
      deleteTerms,
      formatDateTime,
      totatlRecord,
      currentPage,
    }
  },
}
</script>
