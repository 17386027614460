<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <div
      v-if="showMobileNav"
      class="fixed inset-0 flex z-20 lg:hidden"
      role="dialog"
      aria-modal="true"
    >
      <div
        class="fixed inset-0 bg-gray-600 bg-opacity-75"
        aria-hidden="true"
      />

      <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
        <div class="absolute top-0 right-0 -mr-12 pt-4">
          <button
            id="button-close-hamburger"
            class="
              ml-1
              flex
              items-center
              justify-center
              h-10
              w-10
              rounded-full
              focus:outline-none
              focus:ring-2 focus:ring-inset focus:ring-white
            "
            @click="showMobileNav = !showMobileNav"
          >
            <span class="sr-only">Close sidebar</span>
            <svg-icon
              class="h-6 w-6 text-white"
              icon="hamburger-close"
            />
          </button>
        </div>

        <nav-header />

        <div class="flex-1 h-0 pb-4 overflow-y-auto">
          <nav-items />
        </div>

        <nav-footer />
      </div>

      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </div>

    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-64">
        <div class="flex flex-col h-0 flex-1 bg-white">
          <nav-header />

          <div class="flex-1 flex flex-col pb-4 overflow-y-auto">
            <nav-items />
          </div>

          <nav-footer />
        </div>
      </div>
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="lg:hidden py-1 pl-1 sm:pl-3 flex items-center">
        <button
          id="button-open-hamburger"
          class="
            -ml-0.5
            h-12
            w-12
            inline-flex
            items-center
            justify-center
            rounded-md
            text-gray-500
            hover:text-gray-900
            focus:outline-none
            focus:ring-2 focus:ring-inset focus:ring-primary
          "
          @click="showMobileNav = !showMobileNav"
        >
          <span class="sr-only">Open sidebar</span>
          <svg-icon
            class="h-6 w-6 text-primary"
            icon="hamburger"
            stroke-width="2"
          />
        </button>
      </div>

      <main
        class="
          sidebar-shadow
          flex-1
          relative
          z-0

          focus:outline-none
          bg-white
        "
      >
        <div class="overflow-y-auto h-screen">
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import NavItems from '@/components/Nav.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import NavHeader from '@/components/dashboard/NavHeader.vue'
import NavFooter from '@/components/dashboard/NavFooter.vue'

export default {
  name: 'DashboardLayout',

  components: {
    SvgIcon,
    NavItems,
    NavHeader,
    NavFooter,
  },

  setup() {
    const showMobileNav = ref(false)

    return {
      showMobileNav,
    }
  },
}
</script>
