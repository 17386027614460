import CryptoJs from 'crypto-js'

const constant = {
  decimalPlaces: 2,
  urlCharCount: 20,
  encryptionKey: '●●●●●●●●●●',
  CryptoJsAesJson: {
    stringify: function (cipherParams) {
      var j = {
        ct: cipherParams.ciphertext.toString(CryptoJs.enc.Base64),
      }
      if (cipherParams.iv) j.iv = cipherParams.iv.toString()
      if (cipherParams.salt) j.s = cipherParams.salt.toString()
      return JSON.stringify(j).replace(/\s/g, '')
    },
    parse: function (jsonStr) {
      var j = JSON.parse(jsonStr)
      var cipherParams = CryptoJs.lib.CipherParams.create({
        ciphertext: CryptoJs.enc.Base64.parse(j.ct),
      })
      if (j.iv) cipherParams.iv = CryptoJs.enc.Hex.parse(j.iv)
      if (j.s) cipherParams.salt = CryptoJs.enc.Hex.parse(j.s)
      return cipherParams
    },
  },

  add3Dots: function (string, limit) {
    var dots = "...";
    if (string != null) {
      if (string.length > limit) {
        // you can also use substr instead of substring
        string = string.substring(0, limit) + dots;
      }
      return string;
    }

  },

  frontEndSort: function (array, sortColumn, sortColumnDirection) {
    array.sort((a, b) => {
      let fa = a[sortColumn] !== undefined && a[sortColumn] !== null && a[sortColumn] !== '' ? a[sortColumn].toLowerCase() : '',
        fb = b[sortColumn] !== undefined && b[sortColumn] !== null && b[sortColumn] !== '' ? b[sortColumn].toLowerCase() : '';

      if (sortColumnDirection == 'asc') {
        if (fa < fb) {return -1;}
        if (fa > fb) {return 1;}
        return 0;

      } else {
        if (fa > fb) {return -1;}
        if (fa < fb) {return 1;}
        return 0;
      }

    });
    return array;
  }
}

  export default constant
