<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Tenants") }}
        </h1>

        <Button
          id="button_add-tenant"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="
            ;(slideOver.show = true),
              resetForm(),
              resetErrors(),
              (environment = 'add')
          "
        >
          {{ t("Add Tenant") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              class="py-2"
              name="results_per_page"
              :items="[10, 25, 50, 100]"
              @onChangeFunction="getTenants()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getTenants(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="tenants_owner_portal_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>  {{ t("Name") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(tenant, index) in tenants"
                :id="'tenants_owner_portal_' + index"
                :key="index"
              >
                <td>{{ tenant.name }}</td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="
                        ;(slideOver.show = true),
                          getTenantById(tenant.tnt_uuid),
                          (environment = 'edit')
                      "
                    />
                    <button-circle
                      v-if="false"
                      type="danger"
                      icon="trash"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!tenants.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        v-if="tenants.length"
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getTenants($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="tenant-name">
            {{ t("Tenant Name") }}<required />
          </form-label>
          <form-input
            id="tenant-name"
            v-model="form.name"
            name="tenant-name"
            class="mt-1"
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.tnt_name
                ? form.errors.validations.tnt_name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="email">
            {{ t("Email") }}<required />
          </form-label>
          <form-input
            id="email"
            v-model="form.email"
            name="email"
            class="mt-1"
            type="email"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.email
                ? form.errors.validations.email[0]
                : ''
            "
          />
        </div>

        <div v-if="environment == 'edit'">
          <form-label for="email">
            {{ t("Tenant UUID") }}
          </form-label>
          <form-input
            id="uuid"
            v-model="form.uuid"
            name="email"
            class="mt-1"
            type="email"
            disable="disable"
          />
        </div>

        <div v-if="environment == 'add'">
          <form-label for="username">
            {{ t("Username") }}<required />
          </form-label>
          <form-input
            id="username"
            v-model="form.username"
            name="username"
            class="mt-1"
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.username
                ? form.errors.validations.username[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        :disable="disableSaveButton"
        @click="onSubmit"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watch } from 'vue'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormError from '@/components/FormError.vue'
import axios from 'axios'
import Modal from '@/components/Modal'
import {useI18n} from "vue-i18n";
import Required from "@/components/Required";

export default {
  name: 'Tenants',

  components: {
    Button,
    Search,
    Loading,
    SvgIcon,
    FormLabel,
    FormInput,
    SlideOver,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    Modal,
    FormError,
    Required,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const disableSaveButton = ref(false)
    const perPage = ref(10)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const currentPage = ref('')
    const lastPage = ref('')
    const totalRecord = ref('')
    const searchText = ref('')
    const tenants = ref([])
    const environment = ref('add')
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      title: t("Add Tenant"),
    })

    const form = reactive({
      id: null,
      name: null,
      email: null,
      username: null,
      uuid: null,
      row_version: null,
      errors: '',
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    async function getTenants(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('v1/tenants?', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            tenants.value = response.data.data.data
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.error('Error!')
          }
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getTenants(1, sortColumn.value, searchText.value)
    }
    async function getTenantById(tenantId) {
      await axios.get('v1/tenant/' + tenantId).then((response) => {
        if (response.data.data != null) {
          let tenant = response.data.data
          resetForm()
          resetErrors()
          form.id = tenant.tnt_uuid
          form.uuid = tenant.tnt_uuid
          form.name = tenant.tnt_name
          if(tenant.tenant_user != null){
            form.email = tenant.tenant_user.email_address
          }
          form.username = tenant.username
          form.row_version = tenant.row_version
          slideOver.title = t("Edit Tenant")
        }
      })
    }

    function onSubmit() {
      isLoading.value = true
      let SubmitArr = {}
      let url = ''
      SubmitArr.tnt_uuid = form.id
      SubmitArr.tnt_name = form.name
      SubmitArr.email = form.email
      SubmitArr.username = form.username
      SubmitArr.row_version = form.row_version
      disableSaveButton.value = true

      if (form.id == '' || form.id == null) {
        url = '/v1/new-tenant'
      } else {
        url = '/v1/edit-tenant/' + form.id
      }

      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == '' || form.id == null) {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Tenant added successfully.'
            modal.show = true
            disableSaveButton.value = false
            isLoading.value = false
          } else {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Tenant updated successfully.'
            modal.show = true
            disableSaveButton.value = false
            isLoading.value = false
          }
          slideOver.show = false
          resetForm()
          resetErrors()
          getTenants(currentPage)
        } else {
          form.errors = response.data.data.errors
          disableSaveButton.value = false
          isLoading.value = false
        }
      })
    }

    function closeAlert() {
      modal.show = false
      resetForm()
      return true
    }

    function confirmAlert() {
      modal.show = false
      return true
    }

    function resetForm() {
      form.id = null
      form.uuid = null
      form.name = null
      form.email = null
      form.username = null
      form.row_version = null
      slideOver.title = t("Add Tenant")
    }
    function resetErrors() {
      form.errors = ''
    }

    function setEnvironment() {
      if (form.id != null) {
        environment.value = 'edit'
      } else {
        environment.value = 'add'
      }
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('tenant-name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    return {
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      sortColumn,
      searchText,
      form,
      onSubmit,
      closeAlert,
      confirmAlert,
      modal,
      tenants,
      perPage,
      getTenants,
      resetForm,
      resetErrors,
      environment,
      getTenantById,
      totalRecord,
      lastPage,
      sort,
      t,
      disableSaveButton,
    }
  },
}
</script>
