<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center">
        <back-button :route-to="{ name: 'accounts' }" />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Edit Account") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div
      class="py-4 relative"
      style="height: calc(100vh - 126px);"
    >
      <h4 class="my-3 font-medium text-black text-opacity-75 text-lg">
        {{ t("Merchant Account Details") }}
      </h4>

      <div class="grid md:grid-cols-4 gap-4">
        <div>
          <form-label
            for="merchant-sub-account-name"
          >
            {{ t("Merchant Account Name") }}
            <required />
          </form-label>
          <form-input
            id="merchant-sub-account-name"
            v-model="form.organisationName"
            :disabled="disableValue"
            autofocus
            class="mt-1"
            name="merchant_sub_account_name"
            required
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.organisationName
                ? form.errors.validations.organisationName[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="website-url">
            {{ t("Website URL") }}
          </form-label>
          <form-input
            id="website-url"
            v-model="form.websiteUrl"
            class="mt-1"
            name="website_url"
            type="url"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.websiteUrl
                ? form.errors.validations.websiteUrl[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="country">
            {{ t("Status") }}
            <required />
          </form-label>
          <form-select
            id="status"
            v-model="form.status"
            :items="activeOptions"
            class="mt-1"
            name="country"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.status
                ? form.errors.validations.status[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label
            v-if="form.is_parent == 1 "
            for="MainAgencyAccount"
          >
            {{ t("Main Agency Account") }}
          </form-label>
          <form-label
            v-if="form.is_parent == 0 "
            for="MainAgencyAccount"
          >
            {{ t("Agency Account") }}
          </form-label>
          <div class="mt-2 text-black font-medium">
            <badge
              v-if="form.act_as_agent == 1"
              type="success"
              text="Yes"
            />
            <badge
              v-if="form.act_as_agent == 0"
              type="danger"
              text="No"
            />
          </div>
        </div>



        <div class="hidden md:block">
          <!-- Dummy Grid -->
        </div>
      </div>

      <div class="mt-2 tab-group lg:pb-20">
        <div class="tabs-wrapper mb-0">
          <nav
            aria-label="Tabs"
            class="tabs"
          >
            <a
              v-for="(tabItem, index) in accountEditTabItems"
              :key="index"
              :class="{ active: activeMainTabId == tabItem.id }"
              class="tab-item"
              href="#"
              @click="showTabContent('activeMainTabId', tabItem.id)"
            >{{ t(tabItem.name) }}</a>
          </nav>
        </div>

        <div class="tab-container pt-3">
          <div v-if="activeMainTabId == 'tab0'">
            <div class="mb-4 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <form-label for="country">
                  {{ t("Country") }}
                  <required />
                </form-label>
                <form-select
                  id="country"
                  v-model="form.countryId"
                  :change="onchange"
                  :disabled="disableValue || disableAddressDetailsValue"
                  :items="countryOptions"
                  class="mt-1"
                  name="country"
                  required
                  @onChangeFunction="

                    resetTempAddressValue(),
                    (form.stateId = ''),
                    (form.suburbId = ''),
                    (form.postalCodeId = ''), getParentMerchantAccountDetails()
                  "
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.countryId
                      ? form.errors.validations.countryId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="state">
                  {{ t("State") }}
                  <required />
                </form-label>
                <form-select
                  id="state"
                  v-model="form.stateId"
                  :disable="stateDisable"
                  :disabled="disableValue || disableAddressDetailsValue"
                  :items="stateOptions"
                  class="mt-1"
                  name="state"
                  required
                  @onChangeFunction="

                    resetTempAddressValue(),
                    (form.suburbId = ''),
                    (form.postalCodeId = '')
                  "
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.stateId
                      ? form.errors.validations.stateId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="suburb">
                  {{ t("Suburb/Town") }}
                  <required />
                </form-label>
                <form-select
                  id="suburb"
                  v-model="form.suburbId"
                  :disable="suburbDisable"
                  :disabled="disableValue || disableAddressDetailsValue"
                  :items="townOptions"
                  class="mt-1"
                  name="suburb"
                  required
                  @onChangeFunction="

                    resetTempAddressValue(),
                    (form.postalCodeId = '')
                  "
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.suburbId
                      ? form.errors.validations.suburbId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="postcode">
                  {{ t("Post Code") }}
                  <required />
                </form-label>
                <form-select
                  id="postcode"
                  v-model="form.postalCodeId"
                  :disable="postalCodeDisale"
                  :disabled="disableValue || disableAddressDetailsValue"
                  :items="postalCodeOptions"
                  class="mt-1"
                  name="postcode"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.postalCodeId
                      ? form.errors.validations.postalCodeId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="address-line-1"
                >
                  {{ t("Address Line 1") }}
                  <required />
                </form-label>
                <form-input
                  id="address-line-1"
                  v-model="form.address1"
                  :disabled="disableValue || disableAddressDetailsValue"
                  class="mt-1"
                  name="address_line_1"
                  required
                  type="text"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.address1
                      ? form.errors.validations.address1[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="address-line-2">
                  {{ t("Address Line 2") }}
                </form-label>
                <form-input
                  id="address-line-2"
                  v-model="form.address2"
                  :disabled="disableValue || disableAddressDetailsValue"
                  class="mt-1"
                  name="address_line_2"
                  type="text"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.address2
                      ? form.errors.validations.address2[0]
                      : ''
                  "
                />
              </div>
            </div>
          </div>

          <div v-if="activeMainTabId == 'tab1'">
            <div class="mb-4 grid grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <form-label
                  for="select-user"
                >
                  {{ t("Select User") }}
                  <required />
                </form-label>
                <div class="flex items-center space-x-4">
                  <form-select
                    id="select-user"
                    v-model="form.user_uuid"
                    :change="onchange"
                    :items="usersOptions"
                    class="mt-1"
                    name="select_user"
                    @onChangeFunction="getMerchantUserDetailsByUserId()"
                  />
                </div>
              </div>

              <div>
                <form-label class="invisible">
                  &nbsp;
                </form-label>
                <ButtonSecondary
                  class="mt-1"
                  type="button"
                  @click="addNewUser()"
                >
                  {{ t("New") }}
                </ButtonSecondary>
              </div>
            </div>

            <div class="mb-4 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <form-label for="last-name">
                  {{ t("Last Name") }}
                  <required />
                </form-label>
                <form-input
                  id="last-name"
                  v-model="form.last_name"
                  :disabled="disable"
                  class="mt-1"
                  name="last_name"
                  required
                  type="text"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.last_name
                      ? form.errors.validations.last_name[0]
                      : ''
                  "
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.fname_lname_email
                      ? form.errors.validations.fname_lname_email[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="given-name"
                >
                  {{ t("Given Name") }}
                  <required />
                </form-label>
                <form-input
                  id="given-name"
                  v-model="form.first_name"
                  :disabled="disable"
                  class="mt-1"
                  name="last_name"
                  required
                  type="text"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.first_name
                      ? form.errors.validations.first_name[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="phone-number"
                >
                  {{ t("Phone Number") }}
                  <required />
                </form-label>
                <form-input
                  id="phone-number"
                  v-model="form.phone_number"
                  :disabled="disable"
                  class="mt-1"
                  name="phone_number"
                  required
                  type="text"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.phone_number
                      ? form.errors.validations.phone_number[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="business-email"
                >
                  {{ t("Business Email") }}
                  <required />
                </form-label>
                <form-input
                  id="business-email"
                  v-model="form.business_email"
                  :disabled="disable"
                  class="mt-1"
                  name="business_email"
                  required
                  type="email"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.business_email
                      ? form.errors.validations.business_email[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="security-profile"
                >
                  {{ t("Security Profile") }}
                  <required />
                </form-label>
                <form-select
                  id="security-profile"
                  v-model="form.sec_profile_id"
                  :disabled="disable"
                  :items="securityProfiles"
                  class="mt-1"
                  name="security_profile"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.sec_profile_id
                      ? form.errors.validations.sec_profile_id[0]
                      : ''
                  "
                />
              </div>
            </div>
          </div>

          <div v-if="activeMainTabId == 'tab2'">
            <div
              class="mb-5"
            >
              <!-- start  mulitple GWs ui  ui   ---->


              <TableDefault>
                <template #table>
                  <table
                    id="accounts_edit_payment_gateways_tbl"
                    aria-describedby=""
                    class="table"
                  >
                    <thead class="block w-full">
                      <tr class="block w-full">
                        <th
                          class="w-full"
                          scope="col"
                        >
                          <div class="flex items-center">
                            <span>{{ t("Payment Gateways") }}</span>
                            <button
                              class="inline-flex focus:outline-none opacity-40"
                              type="button"
                              @click="sortGateway('name')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'name'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col" />
                        <th
                          scope="col"
                          class="th-action"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      class="block w-full scrollBody"
                    >
                      <tr
                        v-for="(gateway_details, index) in gatewayDetails"
                        :key="index"
                        class="w-full"
                      >
                        <td class="w-full">
                          {{ gateway_details.name }}
                        </td>
                        <td>
                          <div
                            class="flex items-center justify-center space-x-5"
                          >
                            <form-checkbox
                              id="index"
                              name="gateway"
                              :checked="checkGatewayDetailsCheckbox(gateway_details.gw_id)"
                              :disabled="checkboxDisableValue == true"
                              @click="clickGateway($event, gateway_details.gw_id, gateway_details.name, gateway_details.device_pairing, gateway_details.is_js_support)"
                            />
                          </div>
                        </td>
                        <td>
                          <div class="flex items-center justify-center space-x-5">
                            <button-circle
                              v-if="blueEditButtonIsVisible(gateway_details.gw_id)"
                              type="info"
                              icon="edit"
                              :disabled="disableEditButton(gateway_details.gw_id)"
                              @click="(AddGwsSlideOver.show = true),
                                      editPaymentGateway(gateway_details.gw_id, gateway_details.name, null),
                                      getGatewayPaymentMethods()"
                            />
                            <tippy
                              v-if="redEditButtonIsVisible(gateway_details.gw_id)"
                              placement="left"
                            >
                              <button-circle
                                v-if="redEditButtonIsVisible(gateway_details.gw_id)"
                                type="warning"
                                icon="warning"
                                @click="(AddGwsSlideOver.show = true),
                                        editPaymentGateway(gateway_details.gw_id, gateway_details.name, null),
                                        getGatewayPaymentMethods()"
                              />
                              <template #content>
                                <span>{{ t("Please complete configuration for this gateway.") }}</span>
                              </template>
                            </tippy>
                          </div>
                        </td>
                      </tr>

                      <tr
                        v-if="gatewayDetails.length == 0"
                        class="block w-full"
                      >
                        <td
                          class="text-center block w-full"
                          colspan="100"
                        >
                          <span class="my-4 block opacity-70">{{ t("No records available") }} </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
              </TableDefault>



              <div>
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.mch_acc_pgw_id
                      ? form.errors.validations.mch_acc_pgw_id[0]
                      : ''
                  "
                />
              </div>
            </div>

            <slide-over
              :close-button="slideOver.close_button"
              :mode="slideOver.editMode"
              :show="slideOver.show"
              :slideover-size="slideOver.size"
              :slideover-title="slideOver.title"
              @closeSlideOver="(slideOver.show = false), (AddGwsSlideOver.show = true)"
            >
              <template #slideover-body>
                <div class="space-y-4">
                  <div>
                    <form-label for="gateway-name">
                      {{ t("Gateway") }}
                    </form-label>
                    <form-input
                      id="gateway-name"
                      v-model="gatewayNameInEditMode"
                      class="mt-1"
                      disabled
                      name="gateway"
                    />
                  </div>

                  <div>
                    <form-label for="device-type">
                      {{ t("Device Type") }}
                      <required />
                    </form-label>
                    <form-select
                      id="device-type"
                      v-model="formDevice.gw_pd_id"
                      :items="paymentDeviceOptions"
                      class="mt-1"
                      name="device_type"
                    />
                    <form-error
                      v-if="formDevice.errors"
                      :message="
                        formDevice.errors.validations.gw_pd_id
                          ? formDevice.errors.validations.gw_pd_id[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label for="device-name">
                      {{ t("Name") }}
                      <required />
                    </form-label>
                    <form-input
                      id="device-name"
                      v-model="formDevice.pd_name"
                      class="mt-1"
                      name="device_name"
                      required
                      type="text"
                    />
                    <form-error
                      v-if="formDevice.errors"
                      :message="
                        formDevice.errors.validations.pd_name
                          ? formDevice.errors.validations.pd_name[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      for="device-id"
                    >
                      {{ t("Device ID") }}
                      <required />
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_serial_number == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_serial_number, 'pd_serial_number')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="device-id_1"
                        v-model="formDevice.encrypt_pd_serial_number"
                        class="mt-1"
                        name="device_name"
                        :disabled="formDevice.encrypt_pd_serial_number == constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="formDevice.errors"
                        :message="
                          formDevice.errors.validations.encrypt_pd_serial_number
                            ? formDevice.errors.validations.encrypt_pd_serial_number[0]
                            : ''
                        "
                      />
                    </div>
                  </div>

                  <div>
                    <form-label for="key-1">
                      {{ t("Device Key 1") }}
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_key1 == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_key1, 'pd_key1')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="pd_key1"
                        v-model="formDevice.encrypt_pd_key1"
                        class="mt-1"
                        name="pd_key1"
                        :disabled="formDevice.encrypt_pd_key1 == constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="false"
                      />
                    </div>
                  </div>

                  <div>
                    <form-label for="key-2">
                      {{ t("Device Key 2") }}
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_key2 == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_key2, 'pd_key2')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="pd_key2"
                        v-model="formDevice.encrypt_pd_key2"
                        class="mt-1"
                        name="pd_key2"
                        :disabled="formDevice.encrypt_pd_key2 == constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="false"
                      />
                    </div>
                  </div>

                  <div>
                    <form-label for="key-3">
                      {{ t("Device Key 3") }}
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_key3 == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_key3, 'pd_key3')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="pd_key3"
                        v-model="formDevice.encrypt_pd_key3"
                        class="mt-1"
                        name="pd_key3"
                        :disabled="formDevice.encrypt_pd_key3 == constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="false"
                      />
                    </div>
                  </div>

                  <div>
                    <form-label for="key-4">
                      {{ t("Device Key 4") }}
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_key4 == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_key4, 'pd_key4')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="pd_key4"
                        v-model="formDevice.encrypt_pd_key4"
                        class="mt-1"
                        name="pd_key4"
                        :disabled="formDevice.encrypt_pd_key4 == constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="false"
                      />
                    </div>
                  </div>

                  <!-- Start Device Key 5-->
                  <div>
                    <form-label for="key-5">
                      {{ t("Device Key 5") }}
                    </form-label>
                    <div class="relative">
                      <button
                        v-if="formDevice.encrypt_pd_key5 == constantValue"
                        class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                        type="button"
                        @click="decryptDeviceData(formDevice.pd_key5, 'pd_key5')"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                          />
                        </svg>
                      </button>
                      <form-input
                        id="pd_key5"
                        v-model="formDevice.encrypt_pd_key5"
                        class="mt-1"
                        name="pd_key5"
                        :disabled="formDevice.encrypt_pd_key5 === constantValue"
                        required
                        type="text"
                      />
                      <form-error
                        v-if="false"
                      />
                    </div>
                  </div>
                  <!-- End Device Key 5 -->

                  <div>
                    <form-label for="device-status">
                      {{ t("Status") }}
                    </form-label>
                    <form-select
                      id="device-status"
                      v-model="formDevice.status"
                      :items="statusOptions"
                      class="mt-1"
                      name="device_status"
                      required
                    />
                  </div>

                  <div>
                    <form-label for="device-location">
                      {{ t("Location") }}
                    </form-label>
                    <form-textarea
                      id="device-location"
                      v-model="formDevice.location"
                      class="mt-1"
                      name="device_location"
                    />
                    <form-error
                      v-if="formDevice.errors"
                      :message="
                        formDevice.errors.validations.location
                          ? formDevice.errors.validations.location[0]
                          : ''
                      "
                    />
                  </div>


                  <div class="divice_note">
                    <span class="bg-blue-100 block text-blue-800 text-xs font-medium px-2.5 py-1 rounded dark:bg-blue-900 dark:text-blue-300">
                      After you add the device, please click on apply button and then click on save button to save the record.
                    </span>
                  </div>
                </div>
              </template>

              <template #slideover-actions>
                <Button
                  v-if="slideOver.editMode == false"
                  class="btn-wide"
                  type="button"
                  :disabled="disableApplyButton"
                  @click="applyMerchantAccountGatewayPaymentDeviceDetails()"
                >
                  {{ t("Apply") }}
                </Button>
              </template>
            </slide-over>
          </div>

          <div v-if="activeMainTabId == 'tab3'">
            <form-api-credentials
              :mch="mchAccountId"
              @send-cred="getCred"
            />
          </div>

          <div v-if="activeMainTabId == 'tab4'">
            <account-plan @send-status="getStatus" />
          </div>
        </div>
      </div>

      <div
        v-if="activeMainTabId != 'tab3'"
        class="gatwayFixedFooter mt-5 lg:mt-0 max-w-7xl flex items-center fixed  justify-between space-x-2 bottom-0 bg-white w-full py-4 border-t border-gray-200"
      >
        <div class="flex-1">
          <div class="flex items-center">
            <div

              class="flex items-center flex-wrap text-sm sm:space-x-3"
            >
              <div class="flex items-center mr-3 sm:mr-10">
                <tippy
                  v-if="isInvoiceDirectlyAvailable == false"
                  placement="left"
                >
                  <form-checkbox
                    v-if="activeMainTabId != 'tab3'"
                    id="invoice_account_directly_with_tippy"
                    v-model="form.is_invoice_directly"
                    :checked="
                      ($emit('sendStatus', form.is_invoice_directly),
                       form.is_invoice_directly == 1 ? true : false)
                    "
                    :disabled="form.is_parent == 1 || disableInvoiceDirectoryValue == true || isInvoiceDirectlyAvailable == false"
                    :model-value="form.is_invoice_directly == 1 ? 0 : 1"
                    class="mr-2"
                    name="invoice_account_directly"
                  />
                  <label
                    v-if="activeMainTabId != 'tab3'"
                    class="text-sm"
                    for="invoice_account_directly_with_tippy"
                  >{{ t("Invoice Account Directly") }}</label>
                  <template #content>
                    <span>{{ t("Sub Account cannot be invoiced directly. State is not included in the Tax Group assigned to the registered Merchant Account") }}.</span>
                  </template>
                </tippy>
                <div v-else>
                  <form-checkbox
                    v-if="activeMainTabId != 'tab3'"
                    id="invoice_account_directly"
                    v-model="form.is_invoice_directly"
                    :checked="
                      ($emit('sendStatus', form.is_invoice_directly),
                       form.is_invoice_directly == 1 ? true : false)
                    "
                    :disabled="form.is_parent == 1 || disableInvoiceDirectoryValue == true || isInvoiceDirectlyAvailable == false"
                    :model-value="form.is_invoice_directly == 1 ? 0 : 1"
                    class="mr-2"
                    name="invoice_account_directly"
                  />
                  <label
                    v-if="activeMainTabId != 'tab3'"
                    class="text-sm"
                    for="invoice_account_directly"
                  >{{ t("Invoice Account Directly") }}</label>
                </div>
              </div>
              <div
                class="flex items-center"
              >
                <span class="mr-1">View</span>
                <a
                  class="text-primary underline"
                  href="#"
                  @click="viewTermsAndCondition()"
                >{{ t("Terms & Conditions") }}</a>
              </div>
            </div>
          </div>
        </div>

        <Button
          v-if="activeMainTabId != 'tab3'"
          class="btn-wide"
          type="button"
          @click="editMerchantAccountDetails"
        >
          {{ t("Save") }}
        </Button>
      </div>
    </div>
  </div>

  <modal
    :close-button="modal.close_button"
    :message="modal.message"
    :show="modal.show"
    :title="modal.title"
    :type="modal.type"
    @closeModal="closeAlert"
  />

  <modal
    :type="modalWarning.type"
    :show="modalWarning.show"
    :title="modalWarning.title"
    :message="modalWarning.message"
    :array="modalWarning.array"
    :close-button="modalWarning.close_button"
    :confirm-button="modalWarning.confirm_button"
    :button-text="modalWarning.button_text"
    @closeModal="closeWarning"
  />

  <modal
    :type="modalLinkToParentAccount.type"
    :show="modalLinkToParentAccount.show"
    :title="modalLinkToParentAccount.title"
    :message="modalLinkToParentAccount.message"
    :close-button="modalLinkToParentAccount.close_button"
    :confirm-button="modalLinkToParentAccount.confirm_button"
    :button-text="modalLinkToParentAccount.button_text"
    @closeModal="closeAlertLinkToParentAccount"
  />

  <modal
    :button-text="modalDevice.button_text"
    :close-button="modalDevice.close_button"
    :confirm-button="modalDevice.confirm_button"
    :message="modalDevice.message"
    :show="modalDevice.show"
    :title="modalDevice.title"
    :type="modalDevice.type"
    @closeModal="closeAlertDevice"
    @confirmModal="confirmAlert"
  />

  <modal
    :button-text="modalPairWarning.button_text"
    :close-button="modalPairWarning.close_button"
    :confirm-button="modalPairWarning.confirm_button"
    :message="modalPairWarning.message"
    :show="modalPairWarning.show"
    :title="modalPairWarning.title"
    :type="modalPairWarning.type"
    @closeModal="closeAlertDevice"
    @confirmModal="ConfirmPairDeviceModal()"
  />
  <modal-form
    :close-button="modalTerms.close_button"
    :confirm-button="modalTerms.confirm_button"
    :modal-size="modalTerms.size"
    :show="modalTerms.show"
    :title="modalTerms.title"
    @closeModal="modalTerms.show = false"
  >
    <template #modal-body>
      <div
        class="tinymce-wrapper space-y-4"
        style="min-height: calc(100vh - 230px);"
      >
        <editor
          v-model="tcDescription"
          :api-key="tinymce_api_key"
          :disabled="true"
          :init="editorOptions"
          menu="false"
          menubar="false"
          toolbar="false"
        />
      </div>
    </template>
  </modal-form>

  <slide-over
    :show="AddGwsSlideOver.show"
    :slideover-size="AddGwsSlideOver.size"
    :slideover-title="AddGwsSlideOver.title"
    :close-button="AddGwsSlideOver.close_button"
    @closeSlideOver="AddGwsSlideOver.show = false,resetSlideOverDetails()"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div
          v-if="form.is_parent == 0"
          class="mb-5"
        >
          <div class="pr-4">
            <h4 class="mb-3 font-medium text-black text-opacity-75 text-lg">
              {{ t("Please select one of the following options") }}:
            </h4>

            <div>
              <div class="mt-1 flex items-center">
                <form-radio
                  id="multiiple-link-parent-gateway"
                  v-model="form.is_parent_cred_used"
                  name="multiiple-gateway_link"
                  :checked="form.is_parent_cred_used == 1"
                  @click="setToParentGatewayAccount()"
                />
                <label
                  class="ml-2 text-sm"
                  for="multiiple-link-parent-gateway"
                >{{ t("Link to the Parent Entity's gateway account(s)") }}</label>
              </div>
              <div class="mt-1 flex items-center">
                <form-radio
                  id="multiiple-link-existing-gateway"
                  v-model="form.is_parent_cred_used"
                  name="multiiple-gateway_link"
                  :checked="form.is_parent_cred_used == 0"
                  :disabled="form.act_as_agent == 1"
                  @click="setToExistingGatewayAccount"
                />
                <label
                  class="ml-2 text-sm"
                  for="multiiple-link-existing-gateway"
                >{{ t("Link to an existing gateway account") }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
          <div>
            <form-label
              for="merchant-id"
            >
              {{ t("Merchant ID") }}
              <required v-if="requiredApiKeysValue == false" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_mch_id == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_mch_id, 'gw_mch_id')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="merchant-id"
                v-model="form.encrypt_gw_mch_id"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_mch_id == constantValue"
                class="mt-1"
                name="merchant_id"
                required
                type="text"
              />
              <form-error
                v-if="errorMchId"
                :message="errorMchId"
              />
            </div>
          </div>



          <div>
            <form-label
              for="api-private-key"
            >
              {{ t("API Private Key") }}
              <required v-if="requiredApiKeysValue == false" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_private_key == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_private_key, 'gw_private_key')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="api-private-key"
                v-model="form.encrypt_gw_private_key"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_private_key == constantValue"
                class="mt-1"
                name="api_private_key"
                required
                type="text"
              />
              <form-error
                v-if="errorPrivateKey"
                :message="errorPrivateKey"
              />
            </div>
          </div>

          <div>
            <form-label
              for="api-public-key"
            >
              {{ t("API Public Key") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_public_key == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_public_key, 'gw_public_key')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="api-public-key"
                v-model="form.encrypt_gw_public_key"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_public_key == constantValue"
                class="mt-1"
                name="api_public_key"
                required
                type="text"
              />
              <form-error
                v-if="errorPublicKey"
                :message="errorPublicKey"
              />
            </div>
          </div>
        </div>

        <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
          <div>
            <form-label for="key-1">
              {{ t("API Key 1") }}
              <required v-if="form.is_js_support" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_key1 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key1, 'gw_key1')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="gw_key1"
                v-model="form.encrypt_gw_key1"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_key1 == constantValue"
                class="mt-1"
                name="gw_key1"
                required
                type="text"
              />
              <form-error
                v-if="errorApiKey1"
                :message="errorApiKey1"
              />
            </div>
          </div>

          <div>
            <form-label for="key-2">
              {{ t("API Key 2") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_key2 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key2, 'gw_key2')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="gw_key2"
                v-model="form.encrypt_gw_key2"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_key2 == constantValue"
                class="mt-1"
                name="gw_key2"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>

          <div>
            <form-label for="key-3">
              {{ t("API Key 3") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_key3 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key3, 'gw_key3')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="gw_key3"
                v-model="form.encrypt_gw_key3"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_key3 == constantValue"
                class="mt-1"
                name="gw_key3"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>

          <div>
            <form-label for="key-4">
              {{ t("API Key 4") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.encrypt_gw_key4 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key4, 'gw_key4')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                id="gw_key4"
                v-model="form.encrypt_gw_key4"
                :disabled="disableGatewayDetailsValue && form.is_parent == 0 || form.encrypt_gw_key4 == constantValue"
                class="mt-1"
                name="gw_key4"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>
        </div>

        <div class="my-5 border-b border-gray-200">
          <nav
            aria-label="Tabs"
            class="-mb-px flex space-x-8"
          >
            <a
              :class="{ active: activeSubTab == 'payment_methods' }"
              class="secondary-tab-item"
              href="#"
              @click="activeSubTab = 'payment_methods'"
            >
              {{ t("Payment Methods") }}
            </a>

            <a
              :class="{ active: activeSubTab == 'devices' }"
              class="secondary-tab-item"
              href="#"
              @click="activeSubTab = 'devices', getAllDevicesByMchAccIdAndGwId()"
            >
              {{ t("Devices") }}
            </a>
          </nav>
        </div>

        <div v-if="activeSubTab == 'payment_methods'">
          <div class="mb-4 grid grid-cols-2 gap-y-3">
            <div class="w-full flex justify-end" />
          </div>

          <TableDefault>
            <template #table>
              <table
                id="accounts_edit_payment_methods_tbl"
                aria-describedby=""
                class="table"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Payment Methods") }}</span>
                        &nbsp;
                        <required />
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                          @click="sort('paymentMethod')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'paymentMethod'
                            "
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-center">
                        <form-checkbox
                          v-model="form.isSelectAllGatewayPaymentMethod"
                          :check-value="true"
                          :checked="
                            form.isSelectAllGatewayPaymentMethod == 1
                              ? true
                              : false
                          "
                          :model-value="
                            form.isSelectAllGatewayPaymentMethod == 1
                              ? 0
                              : 1
                          "
                          name="all-payment_method"
                          :disable="disableSelectAllGatewayPaymentMethod()"
                          @click="selectAllPaymentMethod()"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(
                      payment_method_data, index
                    ) in merchantAccountGatewayPaymentMethods"
                    :id="'accounts_edit_payment_methods_' + index"
                    :key="index"
                  >
                    <td
                      v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                      :for="index"
                    >
                      {{ payment_method_data.description }}
                    </td>
                    <td v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'">
                      <div
                        class="flex items-center justify-center space-x-5"
                      >
                        <form-checkbox
                          v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                          :id="index"
                          :checked="
                            setPaymentMethodCheck(
                              payment_method_data.tnt_pm_id
                            )
                          "
                          :model-value="
                            payment_method_data.tnt_pm_id ? 1 : 0
                          "
                          name="payment_method"
                          @click="
                            setPaymentMethods(payment_method_data.tnt_pm_id)
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <no-table-data
                    v-if="!merchantAccountGatewayPaymentMethods"
                  />
                </tbody>
              </table>
            </template>
          </TableDefault>
          <form-error
            v-if="errorPaymentMethod"
            :message="errorPaymentMethod"
          />
        </div>

        <div v-if="activeSubTab == 'devices'">
          <div class="mb-4 grid grid-cols-2 gap-y-3">
            <div class="flex items-center">
              <span
                v-if="false"
                class="text-sm"
              >{{ t("Show") }}</span>
              <div
                v-if="false"
                class="mx-2"
              >
                <form-select
                  id="results_per_page1"
                  v-model="perPage"
                  :items="[10, 25, 50, 100]"
                  class="py-2"
                  name="results_per_page"
                  @onChangeFunction="getGatewayPaymentDevices()"
                />
              </div>
              <span
                v-if="false"
                class="text-sm"
              >{{ t("entries") }}</span>
            </div>

            <div class="w-full flex items-center justify-end space-x-2">
              <Button
                type="button"
                @click="(addDeviceOpen()), (AddGwsSlideOver.show = false)"
              >
                {{ t("Add Device") }}
              </Button>
            </div>
          </div>

          <div class="my-4 hidden">
            <div class="sm:grid grid-cols-3 gap-4">
              <div>
                <form-label for="gateway-name">
                  Gateway
                </form-label>
                <form-input
                  id="gateway-name"
                  class="mt-1"
                  name="gateway"
                  disabled
                />
              </div>

              <div>
                <form-label for="device-type">
                  Device Type <required />
                </form-label>
                <form-select
                  id="device-type"
                  class="mt-1"
                  name="device_type"
                />
                <form-error v-if="false" />
              </div>

              <div>
                <form-label for="device-name">
                  Name <required />
                </form-label>
                <form-input
                  id="device-name"
                  class="mt-1"
                  name="device_name"
                  type="text"
                  required
                />
                <form-error v-if="false" />
              </div>

              <div>
                <form-label
                  for="device-id"
                >
                  Device ID <required />
                </form-label>
                <form-input
                  id="device-id"
                  class="mt-1"
                  name="device_name"
                  type="text"
                  required
                />
                <form-error v-if="false" />
              </div>

              <div>
                <form-label for="device-status">
                  Status
                </form-label>
                <form-select
                  id="device-status"
                  class="mt-1"
                  name="device_status"
                  required
                />
              </div>

              <div>
                <form-label for="device-location">
                  Location
                </form-label>
                <form-input
                  id="device-location"
                  class="mt-1"
                  name="device_location"
                  type="text"
                />
              </div>
            </div>

            <div class="my-4 flex justify-end">
              <Button type="button">
                Add
              </Button>
            </div>
          </div>

          <TableDefault class="hidden">
            <template #table>
              <table
                class="table"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Gateway</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Device Type</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Name</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Device ID</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Status</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>Location</span>
                        <button
                          class="inline-flex focus:outline-none opacity-40"
                          type="button"
                        >
                          <svg-icon
                            v-if="true"
                            class="text-black w-7 h-7"
                            icon="caret-down"
                          />
                          <svg-icon
                            v-else
                            class="text-black w-7 h-7"
                            icon="caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th
                      scope="col"
                      class="th-action"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="max-w-xs">
                      Lorem ipsum, dolor sit.
                    </td>
                    <td class="max-w-xs">
                      Lorem ipsum, dolor sit.
                    </td>
                    <td class="max-w-xs">
                      Lorem ipsum, dolor sit.
                    </td>
                    <td class="max-w-xs">
                      832486432657
                    </td>
                    <td class="text-center">
                      <badge
                        v-if="true"
                        type="success"
                        text="Approved"
                      />
                    </td>
                    <td class="max-w-xs">
                      Lorem ipsum, dolor sit.
                    </td>
                    <td>
                      <div class="flex items-center justify-center space-x-5">
                        <button-circle
                          v-if="true"
                          type="danger"
                          icon="trash"
                        />
                      </div>
                    </td>
                  </tr>

                  <no-table-data
                    v-if="!merchantAccountGatewayPaymentDevices.length"
                  />
                </tbody>
              </table>
            </template>
          </TableDefault>

          <TableDefault>
            <template #table>
              <div class="overflow-y-auto-custom">
                <table
                  id="accounts_edit_payment_devices_tbl"
                  aria-describedby=""
                  class="table"
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Name") }}</span>
                          <button
                            class="inline-flex focus:outline-none opacity-40"
                            type="button"
                            @click="sortPaymentDevice('pd_name')"
                          >
                            <div v-if="merchantAccountGatewayPaymentDevices.length">
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'pd_name'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                            </div>
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Location") }}</span>
                          <button
                            class="inline-flex focus:outline-none opacity-40"
                            type="button"
                            @click="sortPaymentDevice('location')"
                          >
                            <div v-if="merchantAccountGatewayPaymentDevices.length">
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'location'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                            </div>
                          </button>
                        </div>
                      </th>
                      <th
                        v-if="form.device_paring"
                        scope="col"
                      >
                        <div class="flex items-center justify-center">
                          <span>{{ t("Device Pairing") }}</span>
                          <button
                            class="inline-flex focus:outline-none opacity-40"
                            type="button"
                            @click="sortPaymentDevice('device_pair')"
                          >
                            <div v-if="merchantAccountGatewayPaymentDevices.length">
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'device_pair'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                            </div>
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center justify-center">
                          <span>{{ t("Status") }}</span>
                          <button
                            class="inline-flex focus:outline-none opacity-40"
                            type="button"
                            @click="sortPaymentDevice('status')"
                          >
                            <div v-if="merchantAccountGatewayPaymentDevices.length">
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'status'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                            </div>
                          </button>
                        </div>
                      </th>
                      <th
                        class="th-action"
                        scope="col"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="merchantAccountGatewayPaymentDevices.length > 0">
                    <tr
                      v-for="(
                        payment_device_data, index
                      ) in merchantAccountGatewayPaymentDevices.slice().reverse()"
                      :id="'accounts_edit_payment_devices_' + index"
                      :key="index"
                    >
                      <td>
                        <router-link
                          :to="{ path: '/accounts/edit/' + form.mch_acc_id }"
                          class="text-primary underline"
                          @click="
                            getGatewayPaymentDeviceByMchAccPaymentDeviceId(payment_device_data),
                            (slideOver.show = true),(AddGwsSlideOver.show = false),
                            (slideOver.title = 'Edit Device')
                          "
                        >
                          {{ payment_device_data.pd_name }}
                        </router-link>
                      </td>
                      <td>{{ payment_device_data.location }}</td>
                      <td
                        v-if="form.device_paring"
                        class="text-center"
                      >
                        <!--{{ payment_device_data.pair_status ? 'Success' : 'Failed' }}-->
                        <badge
                          v-if="payment_device_data.pair_status"
                          text="PAIRED"
                          type="success"
                        />
                        <badge
                          v-if="!payment_device_data.pair_status"
                          text="UNPAIRED"
                          type="danger"
                        />
                      </td>
                      <td class="text-center">
                        <badge
                          v-if="payment_device_data.status == 'ACT'"
                          text="Active"
                          type="success"
                        />
                        <badge
                          v-if="payment_device_data.status == 'INA'"
                          text="Inactive"
                          type="danger"
                        />
                      </td>
                      <td>
                        <div
                          class="flex items-center justify-center space-x-5"
                        >
                          <button-circle
                            v-if="form.device_paring && !payment_device_data.pair_status"
                            class="pair_linksvg"
                            icon="link"
                            type="info"
                            @click="
                              pairingCodePopup(payment_device_data.mch_acc_pd_id)
                            "
                          />
                          <button-circle
                            v-if="form.device_paring && payment_device_data.pair_status"
                            icon="unlink"
                            type="danger"
                            @click="unpairWarningPopup(payment_device_data.mch_acc_pd_id)"
                          />
                          <button-circle
                            icon="trash"
                            type="danger"
                            @click="
                              deletePaymentDeviceType(
                                payment_device_data.mch_acc_pd_id, index
                              )
                            "
                          />
                        </div>
                      </td>
                    </tr>

                    <no-table-data
                      v-if="!merchantAccountGatewayPaymentDevices.length"
                    />
                  </tbody>
                </table>
              </div>
            </template>
          </TableDefault>

          <vue-pagination
            v-if="false"
            :last-page="lastPage"
            :page-size="perPage"
            :sort-column="sortColumn"
            :sort-direction="sortColumnDirection"
            :total-records="totatlRecord"
            @page-changed="getGatewayPaymentDevices($event)"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="applyGatewayPaymentChanges()"
      >
        {{ t("Apply") }}
      </Button>
    </template>
  </slide-over>

  <modal-form
    :show="modalPairCode.show"
    :close-button="modalPairCode.close_button"
    :confirm-button="modalPairCode.confirm_button"
    @closeModal="modalPairCode.show = false"
    @confirmModal="confirmAlert"
  >
    <template #modal-body>
      <div class="space-y-4">
        <div>
          <form-label for="assign-plan">
            Pairing Code <required />
          </form-label>
          <form-input
            v-model="pairDetails.pair_code"
            class="mt-1"
            name="pairing-code"
            type="text"
            required
          />
          <form-error
            v-if="pairDetails.pair_error"
            :message="pairDetails.pair_error"
          />
        </div>
      </div>
    </template>

    <template #modal-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="proceedPairing()"
      >
        {{ t("Pair Device") }}
      </Button>
    </template>
  </modal-form>

  <modal
    :show="pairLoading.show"
    class="pairLoading"
  >
    <template #modal-body>
      <div class="h-full flex flex-col items-center justify-center">
        <svg-icon
          class="w-10 h-10 text-primary"
          icon="loader"
        />
        <div class="mt-4 text-black text-base font-medium text-center">
          Pairing...
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import {ref, reactive, onMounted} from 'vue'
  import { useI18n } from 'vue-i18n'
  import Loading from 'vue3-loading-overlay'
  import Button from '@/components/Button.vue'
  import Required from '@/components/Required.vue'
  import FormError from '@/components/FormError.vue'
  import FormInput from '@/components/FormInput.vue'
  import FormLabel from '@/components/FormLabel.vue'
  import FormRadio from '@/components/FormRadio.vue'
  import FormSelect from '@/components/FormSelect.vue'
  import PageHeader from '@/components/PageHeader.vue'
  import BackButton from '@/components/BackButton.vue'
  import AccountPlan from '@/pages/merchant-portal/accounts/AccountPlan.vue'
  import FormApiCredentials from '@/pages/merchant-portal/accounts/FormApiCredentials.vue'
  import SlideOver from '@/components/SlideOver.vue'
  import FormTextarea from '@/components/FormTextarea.vue'
  import axios from 'axios'
  import {useRoute, useRouter} from 'vue-router'
  import Modal from '@/components/Modal.vue'
  import Badge from '@/components/Badge.vue'
  import ButtonCircle from '@/components/ButtonCircle.vue'
  import NoTableData from '@/components/NoTableData.vue'
  import TableDefault from '@/components/TableDefault.vue'
  import SvgIcon from '@/components/SvgIcon.vue'
  import FormCheckbox from '@/components/FormCheckbox.vue'
  import ButtonSecondary from '@/components/ButtonSecondary.vue'
  import Editor from '@tinymce/tinymce-vue'
  import tinymceConfig from '@/plugins/tinymce-config.js'
  import ModalForm from '@/components/ModalForm.vue'
  import VuePagination from '@/components/VuePagination'
  import { Tippy } from 'vue-tippy'
  import router from "../../../../routes";
  import constant from "@/constant";

  export default {
    name: 'AccountEdit',

    components: {
      Modal,
      Button,
      Loading,
      Required,
      FormError,
      FormInput,
      FormLabel,
      FormRadio,
      FormSelect,
      PageHeader,
      BackButton,
      AccountPlan,
      FormApiCredentials,
      SlideOver,
      FormTextarea,
      Badge,
      ButtonCircle,
      TableDefault,
      SvgIcon,
      NoTableData,
      FormCheckbox,
      ButtonSecondary,
      editor: Editor,
      ModalForm,
      VuePagination,
      Tippy,
    },

    emits: ['sendStatus'],

    setup() {
      const { t } = useI18n()
      const activeSubTab = ref('payment_methods');

      const slideOver = reactive({
        show: false,
        size: 'max-w-md',
        close_button: false,
        title: 'Add Device',
      });

      let stateDisable = ref(true);
      let suburbDisable = ref(true);
      let postalCodeDisale = ref(true);
      const router = useRouter();
      const mchAccountId = ref(0);

      const fullPage = ref(true);
      const isLoading = ref(false);
      const route = useRoute();
      const merchantAccountDetails = ref(0);
      const parentGatewayDetails = ref(0);
      const merchantAccountCredentialsDetails = ref(0);
      const merchantAccountPaymentMethods = ref(0);
      const merchantAccountGatewayPaymentMethods = ref(0);
      const pm_id = ref(0);
      const paymentMethodValue = ref(false);
      const countryOptions = ref([
        {value: null, text: 'Please select a country'},
      ]);
      const stateOptions = ref([]);
      const townOptions = ref([]);
      const postalCodeOptions = ref([]);
      const gatewayOptions = ref([{value: '', text: 'Please select a gateway'}]);
      const PaymentDeviceTypeIdToDelete = ref(false);
      const PaymentDeviceIndexToDelete = ref(false);
      const paymentDeviceOptions = ref([
        {value: '', text: 'Please select a device type'},
      ]);
      const usersOptions = ref([{value: '', text: 'Please select a user'}]);
      const securityProfiles = ref([
        {value: '', text: 'Please select a security profile'},
      ]);
      const disable = ref(true);
      const uuid = ref(0);
      const requiredApiKeysValue = ref(false);

      const errorMchId = ref('');
      const errorPrivateKey = ref('');
      const errorApiKey1 = ref('');
      const errorPublicKey = ref('');
      const errorPaymentMethod = ref('');

      const form = reactive({
        id: '',
        organisationName: '',
        lastName: '',
        givenName: '',
        businessMail: '',
        phoneNo: '',
        country: '',
        countryId: '',
        state: '',
        stateId: '',
        address1: '',
        address2: '',
        suburb: '',
        suburbId: '',
        postalCode: '',
        postalCodeId: '',
        websiteUrl: '',
        username: '',
        errors: '',
        status: '',
        is_invoice_directly: 0,
        is_parent: '',
        gw_id: '',
        gw_name: '',
        gw_public_key: '',
        gw_private_key: '',
        gw_key1: '',
        gw_key2: '',
        gw_key3: '',
        gw_key4: '',
        gw_mch_id: '',
        mch_acc_id: '',
        op_api_key: '',
        op_api_secret: '',
        state1: '',
        states: [],
        last_name: '',
        first_name: '',
        phone_number: '',
        business_email: '',
        sec_profile_id: '',
        user_uuid: '',
        prev_user_uuid: '',
        mch_acc_uuid: '',
        mch_acc_pgw_id: '',
        not_completed: false,
        tempUserUuid: '',
        isSelectAllGatewayPaymentMethod: 0,
        is_parent_cred_used: '',
        editMode: true,
        deleted_mch_acc_gw_devices:[],
        fname_lname_email: true,
        gateways: [],
        parentGws: [],
        payment_devices:[],
        paymentGatewayDetails:[],
        checkedGateway:0,
        encrypt_gw_mch_id: constant.encryptionKey,
        encrypt_gw_public_key: constant.encryptionKey,
        encrypt_gw_private_key: constant.encryptionKey,
        encrypt_gw_key1: constant.encryptionKey,
        encrypt_gw_key2: constant.encryptionKey,
        encrypt_gw_key3: constant.encryptionKey,
        encrypt_gw_key4: constant.encryptionKey,
        act_as_agent: 0,
        parentMchAccId: '',
        parentGateways: [],
        parentAceeptedCountrygateways: [],
        subAccount: [],
        device_paring : false,
        is_js_support : false
      });

      const formDevice = reactive({
        arrayIndex: null,
        mch_acc_pd_id: '',
        mch_acc_id: '',
        gw_id: '',
        gw_pd_id: '',
        pd_name: '',
        pd_serial_number: '',
        location: '',
        status: '',
        pd_key1: '',
        pd_key2: '',
        pd_key3: '',
        pd_key4: '',
        pd_key5: '',
        errors: '',
        encrypt_pd_serial_number: constant.encryptionKey,
        encrypt_pd_key1: constant.encryptionKey,
        encrypt_pd_key2: constant.encryptionKey,
        encrypt_pd_key3: constant.encryptionKey,
        encrypt_pd_key4: constant.encryptionKey,
        encrypt_pd_key5: constant.encryptionKey,
      });

      const decryptDataForm = reactive({
        data: '',
      })

      const decryptDeviceDataForm = reactive({
        data: '',
      })

      const formApiCredentials = reactive({
        op_api_key: '',
        op_api_secret: '',
        mch_acc_uuid: '',
      });

      const AddGwsSlideOver = reactive({
      show: false,
      size: 'max-w-5xl',
      close_button: false,
      title: 'Payment Gateways',
    })

      const merchantAccountGatewayPaymentDevices = ref(0);
      const paymentDevicesByPaymentDeviceId = ref(0);

      const accountEditTabItems = reactive([
        {id: 'tab0', name: 'Address Details'},
        {id: 'tab1', name: 'Primary Contact'},
        {id: 'tab2', name: 'Gateways'},
        {id: 'tab3', name: 'opuspay API Credentials'},
        {id: 'tab4', name: 'Plan'},
      ]);

      const statusOptions = ref([
        {value: 'ACT', text: 'Active'},
        {value: 'INA', text: 'Inactive'},
      ]);

      const activeMainTabId = ref(accountEditTabItems[0].id);

      const modal = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        close_button: true,
      });
      const modalDevice = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        close_button: true,
      });
      const modalPairWarning = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        close_button: true,
      });

      const modalWarning = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        array: '',
        close_button: true,
      })

      const perPage = ref(10);
      const sortColumn = ref('');
      const searchText = ref('');
      const totatlRecord = ref(0);
      const currentPage = ref(0);
      const lastPage = ref(0);
      const sortColumnDirection = ref('desc');
      const url = ref('');
      const msg = ref('');
      const disableValue = ref(false);
      const disableGatewayDetailsValue = ref(false);
      const disableAddressDetailsValue = ref(false);
      const paymentMethodList = ref([]);
      const initialLoad = ref(false);
      const parentMchId = ref(0);
      const disableInvoiceDirectoryValue = ref(false)
      const parentMerchantCountryCode = ref('')
      const gatewayDetails = ref(0)
      const checkboxDisableValue = ref(false)
      const gatewayIdInEditMode = ref(0)
      const gatewayNameInEditMode = ref('')
      const paymentGatewayDetails = ref([])
      const gatewayPaymentDevices = ref([])
      const decryptionButtonShow = ref(true)
      const constantValue = constant.encryptionKey
      const cardNotPresent = ref('')
      const formViewMode = ref(false)
      const fromDevice = ref(false)
      const notComplete = ref(false)
      const mchAccPdId = ref(null)

      const activeOptions = ref([
        {value: 'ACT', text: 'Active'},
        {value: 'INA', text: 'Inactive'},
      ]);

      const tempAddressValue = reactive({
        country: '',
        state: '',
        suburb: '',
        postcode: '',
      });

      const modalTerms = reactive({
        show: false,
        size: 'max-w-6xl',
        title: 'Terms and Conditions',
        close_button: false,
        confirm_button: false,
      });

      const modalLinkToParentAccount = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        close_button: true,
      })

      const modalPairCode = reactive({
      show: false,
      close_button: false,
      confirm_button: false,
    })

      const pairLoading = reactive({
        show: false,
      })

      const pairDetails = reactive({
        pair_code: '',
        pair_device_id: '',
        pair_error: '',
      })

      const tcDescription = ref('');
      const editorOptions = reactive(tinymceConfig.viewOnModalSettings);
      const disableApplyButton = ref(false)
      const applyFunctionIsProcessing = ref(false)
      const isInvoiceDirectlyAvailable = ref(false)
      const errorMsgForGateway = ref('')

      onMounted(async () => {
        if (route.params.tab) {
          activeMainTabId.value = route.params.tab
        }
        if (route.params.subTab) {
          activeSubTab.value = route.params.subTab
        }
        if (route.params.gwId) {
          formViewMode.value = true
          AddGwsSlideOver.show = true
        }
        await getParentMerchantAccountDetails()
        await getSecurityProfiles();
        await findPublishedTermAndCondition();
        await checkInvoiceDirectlyAvailable(route.params.mch_acc_id);
        await getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId(formViewMode.value);
        slideOver.editMode = false
      });

      function showTabContent(tab, tabId) {
        eval(tab).value = tabId
      }

      async function getMerchantAccountDetailsById() {
        isLoading.value = true;
        countryOptions.value = [];
        stateOptions.value = [];
        townOptions.value = [];
        postalCodeOptions.value = [];
        gatewayOptions.value = [{value: '', text: 'Please select a gateway'}];
        usersOptions.value = [{value: '', text: 'Please select a user'}];
        securityProfiles.value = [{value: '', text: 'Please select a security profile'}];

        const mch_acc_id = route.params.mch_acc_id;
        form.mch_acc_id = mch_acc_id;
        mchAccountId.value = form.mch_acc_id;

        await axios
          .get('/v1/get-merchant-account/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountDetails.value = response.data.data;
              form.organisationName = merchantAccountDetails.value.mch_acc_name;
              form.websiteUrl = merchantAccountDetails.value.website;
              form.address1 = merchantAccountDetails.value.address1;
              form.address2 = merchantAccountDetails.value.address2;
              form.is_parent = merchantAccountDetails.value.is_parent;
              form.countryId = merchantAccountDetails.value.country_code;
              form.country = merchantAccountDetails.value.country_name;
              form.stateId =
                merchantAccountDetails.value.merchant_account_state.state_id;
              form.state =
                merchantAccountDetails.value.merchant_account_state.state_name;
              form.suburbId =
                merchantAccountDetails.value.merchant_account_suburb.suburb_id;
              form.suburb =
                merchantAccountDetails.value.merchant_account_suburb.suburb_name;
              form.postalCodeId =
                merchantAccountDetails.value.merchant_account_post_code.postcode_id;
              form.postalCode =
                merchantAccountDetails.value.merchant_account_post_code.postcode;
              form.status = merchantAccountDetails.value.status;
              form.gw_id = merchantAccountDetails.value.gw_id;
              form.act_as_agent = merchantAccountDetails.value.merchant.act_as_agent;
              form.is_invoice_directly =
                merchantAccountDetails.value.is_invoice_directly;

              let objCountry = {};
              objCountry.text = form.country;
              objCountry.value = form.countryId;
              countryOptions.value.push(objCountry)

              let objState = {};
              objState.text = form.state;
              objState.value = form.stateId;
              stateOptions.value.push(objState)

              let objSuburb = {};
              objSuburb.text = form.suburb;
              objSuburb.value = form.suburbId;
              townOptions.value.push(objSuburb)

              let objPostCode = {};
              objPostCode.text = form.postalCode;
              objPostCode.value = form.postalCodeId;
              postalCodeOptions.value.push(objPostCode)

              if (form.gw_id) {
                form.gw_name =
                  merchantAccountDetails.value.merchant_account_gateway.name;
                getMerchantAccountPaymentMethodsByMchAccId();
                getGatewayPaymentMethods();
                // getGatewayPaymentDevices();
                if(form.act_as_agent == 1 && form.is_parent == 1){
                  getAllGatewaysWithoutCountrySelection(sortColumn.value);
                }else if (form.act_as_agent == 1 && form.is_parent == 0){
                  getParentGatewaysWithAcceptedCountry(form.parentMchAccId, form.countryId)
                }else{
                  getGateways(sortColumn.value);
                }

                let obj = {};
                obj.text = form.gw_name;
                obj.value = form.gw_id;
                gatewayOptions.value.push(obj)
              } else {
                if(form.act_as_agent == 1 && form.is_parent == 1){
                  getAllGatewaysWithoutCountrySelection(sortColumn.value);
                }else if (form.act_as_agent == 1 && form.is_parent == 0){
                  getParentGatewaysWithAcceptedCountry(form.parentMchAccId, form.countryId)
                }else{
                  getGateways(sortColumn.value);
                }
                form.is_parent_cred_used = 1
              }

              if (form.is_parent == 1) {
                disableAddressDetailsValue.value = true
              }

              form.last_name =
                merchantAccountDetails.value.merchant_user.last_name;
              form.first_name =
                merchantAccountDetails.value.merchant_user.first_name;
              form.username = merchantAccountDetails.value.merchant_user.username;
              form.user_uuid =
                merchantAccountDetails.value.merchant_user.user_uuid;
              form.tempUserUuid =
                merchantAccountDetails.value.merchant_user.user_uuid;

              uuid.value = merchantAccountDetails.value.merchant_user.user_uuid;

              if (form.username == null) {
                let obj = {};
                obj.text = form.first_name + ' ' + '-' + ' ' + form.last_name;
                obj.value = form.user_uuid;
                usersOptions.value.push(obj)
              } else {
                let obj = {};
                obj.text =
                  form.username +
                  ' ' +
                  '-' +
                  ' ' +
                  form.first_name +
                  ' ' +
                  '-' +
                  ' ' +
                  form.last_name;
                obj.value = form.user_uuid;
                usersOptions.value.push(obj)
              }
              form.business_email =
                merchantAccountDetails.value.merchant_user.business_email;
              form.phone_number =
                merchantAccountDetails.value.merchant_user.phone_number;
              form.sec_profile_id =
                merchantAccountDetails.value.merchant_user.sec_profile_id;

              tempAddressValue.country = merchantAccountDetails.value.country_code;
              tempAddressValue.state =
                merchantAccountDetails.value.merchant_account_state.state_id;
              tempAddressValue.suburb =
                merchantAccountDetails.value.merchant_account_suburb.suburb_id;
              tempAddressValue.postcode =
                merchantAccountDetails.value.merchant_account_post_code.postcode_id;
              getAllPaymentDevicePerGateway(form.gw_id);
              form.user_uuid = '';
              setTimeout(
                () => (form.user_uuid = uuid.value),
                100
              )
              getMerchantUsers()
            } else {
              console.error('Error!')
            }
          })
          .catch(err => {
            router.push({name: 'unauthorized-access'})
          });
        await getSecurityProfiles()
      }

      async function getCountry() {
        isLoading.value = true;
        stateDisable.value = true;
        await axios
          .get('v1/country_and_currency/get_all_tnt_countries_by_tnt_id')
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((country) => {
                let obj = {};
                obj.text = country.country_name;
                obj.value = country.country_code;
                countryOptions.value.push(obj)
              })
            }
            isLoading.value = false;
          })
      }

      async function getState() {
        stateOptions.value = [{value: '', text: 'Please select a state'}];
        townOptions.value = [{value: '', text: 'Please select a suburb/town'}];
        postalCodeOptions.value = [
          {value: '', text: 'Please select a post code'},
        ];
        isLoading.value = true;
        await axios
          .get('v1/address/state-names-for-country/' + form.countryId)
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((state) => {
                let obj = {};
                obj.text = state.state_name;
                obj.value = state.state_id;
                stateOptions.value.push(obj)
              });
            }
            isLoading.value = false
            stateDisable.value = false
          })
      }

      async function getTown() {
        townOptions.value = [{value: '', text: 'Please select a suburb/town'}];
        postalCodeOptions.value = [
          {value: '', text: 'Please select a post code'},
        ];
        isLoading.value = true;
        if (Number.isInteger(parseInt(form.stateId))) {
          await axios
            .get(
              'v1/address/suburb-names-for-state/' +
              form.countryId +
              '/' +
              form.stateId
            )
            .then((response) => {
              if (response.data.data != null) {
                response.data.data.find((state) => {
                  let obj = {};
                  obj.text = state.suburb_name;
                  obj.value = state.suburb_id;
                  townOptions.value.push(obj)
                });
                isLoading.value = false
              }
              suburbDisable.value = false
            })
        } else {
          suburbDisable.value = true;
          postalCodeDisale.value = true;
          form.suburbId = null;
          form.postalCodeId = null
        }
      }

      async function getPostCode(initialRun = false) {
        postalCodeOptions.value = [
          {value: '', text: 'Please select a post code'},
        ];
        isLoading.value = true;
        if (Number.isInteger(parseInt(form.suburbId))) {
          await axios
            .get(
              'v1/address/postal-codes-for-suburb/' +
              form.countryId +
              '/' +
              form.stateId +
              '/' +
              form.suburbId
            )
            .then((response) => {
              if (response.data.data != null) {
                if (response.data.data.length == 1) {
                  form.postalCodeId = 0;
                  postalCodeOptions.value = [
                    {
                      value: response.data.data[0].postcode_id,
                      text: response.data.data[0].postcode,
                    },
                  ];
                  setTimeout(
                    () => (form.postalCodeId = response.data.data[0].postcode_id),
                    100
                  )
                } else {
                  response.data.data.find((postcode) => {
                    let obj = {};
                    obj.text = postcode.postcode;
                    obj.value = postcode.postcode_id;
                    postalCodeOptions.value.push(obj)
                  })
                }
                if (initialRun) {
                  form.countryId = tempAddressValue.country;
                  form.stateId = 0;
                  form.suburbId = 0;
                  setTimeout(() => (form.stateId = tempAddressValue.state), 100);
                  setTimeout(() => (form.suburbId = tempAddressValue.suburb), 100);
                  form.postalCodeId = tempAddressValue.postcode
                }

                // isLoading.value = false
              } else {
                //
              }
            });
          postalCodeDisale.value = false
        } else {
          postalCodeDisale.value = true;
          form.postalCodeId = null
        }
        isLoading.value = false
      }

      async function getGateways(column = sortColumn.value) {
        gatewayDetails.value = []
        await axios
            .get('v1/gateway/accepted-countries/get-all-gateways-per-country/' + form.countryId, {
              params: {
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
            if (response.data.data != null) {
              gatewayDetails.value = response.data.data
            }
          })
      }

      async function getAllGatewaysWithoutCountrySelection(column = sortColumn.value) {
        gatewayDetails.value = []
        await axios
            .get('v1/gateway/list/', {
              params: {
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
                search: searchText.value,
                paginate: false,
              },
            })
            .then((response) => {
              // isLoading.value = false;
              if (response.data.data != null) {
                gatewayDetails.value = response.data.data
              }
            })
      }

      async function sortGateway(column) {
        isLoading.value = true
        sortColumn.value = column
        sortColumnDirection.value =
            sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

        if(form.act_as_agent == 1 && form.is_parent == 1){
          await getAllGatewaysWithoutCountrySelection(sortColumn.value);
        }else if (form.act_as_agent == 1 && form.is_parent == 0){
          await getParentGatewaysWithAcceptedCountry(form.parentMchAccId, form.countryId)
        }else{
          await getGateways(sortColumn.value);
        }
        isLoading.value = false
      }

      async function getMerchantUsers() {
        isLoading.value = true;
        if (initialLoad.value == false) {
          await axios.get('/v1/merchant-active-users-by-mchId').then((response) => {
            if (response.data.data != null) {
              response.data.data.find((users) => {
                if (form.user_uuid != null || form.user_uuid != ''){
                  if (form.user_uuid != users.user_uuid) {
                    let obj = {};
                    obj.text =
                      users.username +
                      ' ' +
                      '-' +
                      ' ' +
                      users.first_name +
                      ' ' +
                      '-' +
                      ' ' +
                      users.last_name;
                    obj.value = users.user_uuid;
                    usersOptions.value.push(obj)
                  }
                }else {
                  if (form.prev_user_uuid != users.user_uuid) {
                    let obj = {};
                    obj.text =
                      users.username +
                      ' ' +
                      '-' +
                      ' ' +
                      users.first_name +
                      ' ' +
                      '-' +
                      ' ' +
                      users.last_name;
                    obj.value = users.user_uuid;
                    usersOptions.value.push(obj)
                  }
                }

              })
            }
          });
          usersOptions.value.sort((a, b) => {
            if(a.value != '' && b.value != ''){
              let fa = a.text.toLowerCase(),
                fb = b.text.toLowerCase();
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            }
          });
          form.user_uuid = null
          setTimeout(
            () => (form.user_uuid = form.tempUserUuid),
            20
          )
          initialLoad.value = true
        }
      }

      async function getMerchantAccountPaymentMethodsByMchAccId() {
        const mch_acc_id = route.params.mch_acc_id;
        form.states = []
        await axios
          .get('/v1/get-merchant-account-payment-methods/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountPaymentMethods.value = response.data.data;
              response.data.data.find((paymentMethod) => {
                if(paymentMethod.payment_details.pm_cat != 'CARD_NOT_PRESENT'){
                  form.states.push(paymentMethod.tnt_pm_id)
                }
              })
            } else {
              console.error('Error!')
            }
          });
      }

      async function getGatewayPaymentMethods(
        page = 1,
        column = sortColumn.value,
        search = null
      ) {
        paymentMethodList.value = [];
        form.isSelectAllGatewayPaymentMethod = 0;
        merchantAccountPaymentMethods.value = '';
        if (search != null) {
          searchText.value = search
        }

        if (form.gw_id) {
          await axios
            .get('v1/gateway/payment-methods/get-all/' + gatewayIdInEditMode.value, {
              params: {
                search: searchText.value,
                count_per_page: perPage.value,
                page: page,
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
              if (response.data.data != null) {
                merchantAccountGatewayPaymentMethods.value = response.data.data.data;
                paymentMethodList.value = [];
                response.data.data.data.forEach((paymentMethod) => {
                  if(paymentMethod.pm_cat != 'CARD_NOT_PRESENT'){
                    paymentMethodList.value.push(paymentMethod.tnt_pm_id)
                  }else{
                    cardNotPresent.value = paymentMethod.tnt_pm_id
                  }
                });

                checkAllPaymentMethodSelect()
              }
            })
        } else {
          form.isSelectAllGatewayPaymentMethod = 0;
          merchantAccountGatewayPaymentMethods.value = ''

        }
      }

      function sort(column) {
        sortColumn.value = column;
        sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc';

        getGatewayPaymentMethods(1, sortColumn.value, searchText.value)
      }

      async function editMerchantAccountDetails() {
        isLoading.value = true;
        fromDevice.value = false
        const mch_acc_id = route.params.mch_acc_id;
        form.not_completed = false
        if(notComplete.value) {
          form.not_completed = true
        }
        form.errors = ''

        form.op_api_key = formApiCredentials.op_api_key;
        form.op_api_secret = formApiCredentials.op_api_secret;
        form.mch_acc_uuid = formApiCredentials.mch_acc_uuid;
        form.payment_devices = merchantAccountGatewayPaymentDevices.value;
        form.paymentGatewayDetails = paymentGatewayDetails.value

        if (form.is_parent == 1) {
          form.is_parent_cred_used = 1
        }

        if(form.paymentGatewayDetails.length != form.gateways.length){
          form.not_completed = true
          form.errors = ''
        }

       await axios
              .post('/v1/edit-merchant-account/' + form.mch_acc_id, form)
              .then((response) => {
                isLoading.value = false;
                if (response.data != null && !response.data.data.errors) {
                  modal.type = 'success';
                  modal.title = 'Success';
                  modal.message = 'Account details updated successfully.';
                  modal.show = true
                } else {
                  form.errors = response.data.data.errors;
                  if (form.errors.validations.mch_acc_id != null) {
                    if (form.errors.validations.mch_acc_id[0]) {
                      router.push({name: 'unauthorized-access'})
                    }
                  }

                  if (form.errors.validations.address1) {
                    activeMainTabId.value = 'tab0'
                  }
                  else if (form.errors.validations.business_email ||
                    form.errors.validations.first_name ||
                    form.errors.validations.last_name ||
                    form.errors.validations.phone_number ||
                    form.errors.validations.sec_profile_id ||
                    form.errors.validations.fname_lname_email
                  ) {
                    activeMainTabId.value = 'tab1'
                  }
                  else if (form.errors.validations.gw_id ||
                    form.errors.validations.gw_mch_id ||
                    form.errors.validations.gw_public_key ||
                    form.errors.validations.gw_private_key ||
                    form.errors.validations.mch_acc_pgw_id ||
                    form.errors.validations.not_completed
                  ) {
                    activeMainTabId.value = 'tab2'
                    if(form.errors.validations.not_completed){
                      form.errors.validations.mch_acc_pgw_id = ''
                      modalWarning.type = 'warning'
                      modalWarning.title = 'Warning'
                      modalWarning.message = form.errors.validations.not_completed[0]
                      modalWarning.array = null
                      modalWarning.show = true
                    }
                  }
                  if(form.errors.validations.gw_id){
                    form.errors.validations.states = ''
                  }else{
                    if (form.errors.validations.states) {
                      activeMainTabId.value = 'tab2';
                      activeSubTab.value = 'payment_methods'
                    }
                  }
                }
              })
              .catch(err => {
                router.push({name: 'unauthorized-access'})
        })
      }

      async function getGatewayPaymentDevices(
        page = 1,
        column = sortColumn.value,
        search = null
      ) {
        isLoading.value = true;
        if (search != null) {
          searchText.value = search
        }

        await axios
          .get(
            '/v1/get-merchant-account-payment-device-details/' + form.mch_acc_id,
            {
              params: {
                search: searchText.value,
                count_per_page: perPage.value,
                page: page,
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
                gwId: gatewayIdInEditMode.value,
                mch_acc_pgw_id: form.mch_acc_pgw_id,
              },
            }
          )
          .then((response) => {
            isLoading.value = false;
            if (response.data.data != null) {

              merchantAccountGatewayPaymentDevices.value = [];
              response.data.data.data.forEach((device)=>{
                let obj = {}
                obj.mch_acc_pd_id = device.mch_acc_pd_id
                obj.mch_acc_id = device.mch_acc_id
                obj.gw_id = device.gw_id
                obj.gw_pd_id = device.gw_pd_id
                obj.pd_name = device.pd_name
                obj.pd_serial_number = device.pd_serial_number
                obj.status = device.status
                obj.location = device.location
                obj.pd_key1 = device.pd_key1
                obj.pd_key2 = device.pd_key2
                obj.pd_key3 = device.pd_key3
                obj.pd_key4 = device.pd_key4
                obj.pd_key5 = device.pd_key5

                obj.encrypt_pd_key1 = device.encrypt_pd_key1 != null ? device.encrypt_pd_key1 : ''
                obj.encrypt_pd_key2 = device.encrypt_pd_key2 != null ? device.encrypt_pd_key2 : ''
                obj.encrypt_pd_key3 = device.encrypt_pd_key3 != null ? device.encrypt_pd_key3 : ''
                obj.encrypt_pd_key4 = device.encrypt_pd_key4 != null ? device.encrypt_pd_key4 : ''
                obj.encrypt_pd_key5 = device.encrypt_pd_key5 != null ? device.encrypt_pd_key5 : ''
                obj.encrypt_pd_serial_number = device.encrypt_pd_serial_number != null ? device.encrypt_pd_serial_number : ''

                if(form.deleted_mch_acc_gw_devices.length > 0){
                  form.deleted_mch_acc_gw_devices.forEach((deleted_devices) => {
                    if(obj.mch_acc_pd_id != deleted_devices.mch_acc_pd_id){
                      merchantAccountGatewayPaymentDevices.value.push(obj);
                    }
                  })
                }else{
                  merchantAccountGatewayPaymentDevices.value.push(obj);
                }
              })

              totatlRecord.value = response.data.data.total;
              currentPage.value = response.data.data.current_page;
              lastPage.value = response.data.data.last_page
            }
          })
      }

      async function getAllPaymentDevicePerGateway(gatewayIdInEditMode) {
        paymentDeviceOptions.value = [
          {value: '', text: 'Please select a device type'},
        ];
        isLoading.value = true;
        if(gatewayIdInEditMode !== undefined && gatewayIdInEditMode !== null){
          await axios
            .get('v1/gateway/payment-device/get-active-devices/' + gatewayIdInEditMode)
            .then((response) => {
              isLoading.value = false
              if (response.data.data != null) {
                response.data.data.find((paymentDevice) => {
                  let obj = {};
                  obj.text = paymentDevice.gw_pd_type;
                  obj.value = paymentDevice.gw_pd_id;
                  paymentDeviceOptions.value.push(obj)
                })
              }
            });
        }

      }

      async function getMerchantUserDetailsByUserId() {
        isLoading.value = true;
        disable.value = true;
        if (form.user_uuid) {
          await axios
            .get('/v1/merchant-user-details/' + form.user_uuid)
            .then((response) => {
              if (response.data.data != null) {
                form.last_name = response.data.data.last_name;
                form.first_name = response.data.data.first_name;
                form.phone_number = response.data.data.phone_number;
                form.business_email = response.data.data.business_email;
                form.sec_profile_id = response.data.data.sec_profile_id;
                form.username = response.data.data.username
              }
            })
        } else {
          form.last_name = '';
          form.first_name = '';
          form.phone_number = '';
          form.business_email = '';
          form.sec_profile_id = '';
          form.username = ''
        }
        isLoading.value = false
      }

      function addNewUser() {
        disable.value = false;
        form.prev_user_uuid = form.user_uuid;
        form.user_uuid = '';
        form.last_name = '';
        form.first_name = '';
        form.phone_number = '';
        form.business_email = '';
        form.sec_profile_id = ''
      }

      async function getSecurityProfiles() {
        securityProfiles.value = [{value: '', text: 'Please select a security profile'}];
        await axios
          .get('v1/get-merchant-security-profiles')
          .then((response) => {
            if (response.data != null) {
              response.data.data.find((securityProfile) => {
                let obj = {};
                obj.text = securityProfile.sec_profile_name;
                obj.value = securityProfile.sec_profile_id;
                securityProfiles.value.push(obj)
              });
            } else {
              console.error('Error!')
            }
          })
      }

      function setPaymentMethods(stateId) {
        if (form.states.includes(parseInt(stateId))) {
          form.states.splice(form.states.indexOf(parseInt(stateId)), 1)
        } else {
          form.states.push(stateId)
        }
        checkAllPaymentMethodSelect()
      }

      function checkAllPaymentMethodSelect() {
        let _arr1 = form.states.sort();
        let _arr2 = paymentMethodList.value.sort();

        _arr1.forEach((paymentMethod) => {
         if(paymentMethod == cardNotPresent.value){
           form.states.splice(form.states.indexOf(parseInt(paymentMethod)), 1)
         }
        });

        if (
          Array.isArray(_arr1) &&
          Array.isArray(_arr2) &&
            _arr1.length === _arr2.length
        ) {
          if (paymentMethodList.value.length != 0) {
            form.isSelectAllGatewayPaymentMethod = 1
          } else {
            form.isSelectAllGatewayPaymentMethod = 0
          }
        }

        if (
          !Array.isArray(_arr1) ||
          !Array.isArray(_arr2) ||
            _arr1.length !== _arr2.length
        ) {
          form.isSelectAllGatewayPaymentMethod = 0
        }
        isLoading.value = false
      }

      function setPaymentMethodCheck(paymentMethodId) {
        if (form.states.includes(parseInt(paymentMethodId))) {
          return true
        }
      }

      function selectAllPaymentMethod() {
        if (form.isSelectAllGatewayPaymentMethod == 0) {
          form.states = [];
          paymentMethodList.value.forEach((value) => {
            form.states.push(value)
          })
        } else if (form.isSelectAllGatewayPaymentMethod == 1) {
          // isLoading.value = true;
          form.states = [];
          // isLoading.value = false
        }
      }

      function closeAlert() {
        modal.show = false;
        if(fromDevice.value == false) {
          router.push({
            name: 'accounts',
            params: {
              merchantAccountId: form.mch_acc_id,
              activeTab: activeMainTabId.value,
              activeSubTab: activeSubTab.value,
            },
          })
        }
      }


      function getStatus(val) {
        form.is_invoice_directly = val
      }

      function getCred(val) {
        formApiCredentials.op_api_key = val.op_api_key;
        formApiCredentials.op_api_secret = val.op_api_secret;
        formApiCredentials.mch_acc_uuid = val.op_mch_id
      }

      async function getGatewayDetailsByGatewayId() {
        isLoading.value = true;
        await axios.get('v1/gateway/view/' + gatewayIdInEditMode.value).then((response) => {
          if (response.data.data != null) {
            isLoading.value = false;
            form.gw_name = response.data.data.name;
            form.gw_id = response.data.data.gw_id;
            getAllPaymentDevicePerGateway(gatewayIdInEditMode.value)
          }
        })
      }

      function openSlideOver() {
        formDevice.gw_pd_id = '';
        formDevice.pd_name = '';
        formDevice.pd_serial_number = '';
        formDevice.pd_key1 = '',
        formDevice.pd_key2 = '',
        formDevice.pd_key3 = '',
        formDevice.pd_key4 = '',
        formDevice.pd_key5 = '',
        formDevice.encrypt_pd_key1 = '',
        formDevice.encrypt_pd_key2 = '',
        formDevice.encrypt_pd_key3 = '',
        formDevice.encrypt_pd_key4 = '',
        formDevice.encrypt_pd_key5 = '',
        formDevice.encrypt_pd_serial_number = '',
        formDevice.status = 'ACT';
        formDevice.location = '';
        slideOver.show = true
      }

      function addDeviceOpen() {
        getGatewayDetailsByGatewayId();
        slideOver.title = 'Add Device';
        formDevice.gw_pd_id = '';
        formDevice.pd_name = '';
        formDevice.pd_serial_number = '';
        formDevice.status = 'ACT';
        formDevice.location = '';
        formDevice.errors = '';
        formDevice.arrayIndex=null;
        formDevice.mch_acc_pd_id='';
        slideOver.title = 'Add Device';
        openSlideOver()
      }

      function deletePaymentDeviceType(id,index) {
        PaymentDeviceTypeIdToDelete.value = id;
        PaymentDeviceIndexToDelete.value = index;
        modalDevice.type = 'warning';
        modalDevice.title = 'Warning';
        modalDevice.confirm_button = true;
        modalDevice.button_text = 'No';
        modalDevice.message =
          'You are about to delete a record. This cannot be undone. Are you sure you want to continue?';
        modalDevice.show = true
      }

      function unpairWarningPopup(mchAccId) {
        modalPairWarning.type = 'warning';
        modalPairWarning.title = 'Warning';
        modalPairWarning.confirm_button = true;
        modalPairWarning.button_text = 'No';
        modalPairWarning.message =
          'You are about to unpair the device. Are you sure you want to continue?';
        modalPairWarning.show = true
        mchAccPdId.value = mchAccId
      }


      function confirmAlert() {
        deletePaymentDeviceTypeOption();
        modalDevice.show = false;
        return true
      }
      function ConfirmPairDeviceModal() {
        unpairDevice(mchAccPdId.value);
        modalPairWarning.show = false;
        return true
      }

      function closeAlertDevice() {
        modalDevice.show = false;
        modalPairWarning.show = false;
        slideOver.show = false;
      }

      function sortPaymentDevice(column) {
        sortColumn.value = column;
        sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc';
        if(sortColumn.value == 'device_pair') {
          merchantAccountGatewayPaymentDevices.value.forEach((gatewayPaymentDevices) => {
            if(gatewayPaymentDevices.pair_status) {
              gatewayPaymentDevices.device_pair = 'PAIRED'
            } else {
              gatewayPaymentDevices.device_pair = 'UNPAIRED'
            }
          })
        }
        merchantAccountGatewayPaymentDevices.value =  constant.frontEndSort(merchantAccountGatewayPaymentDevices.value,sortColumn.value,  sortColumnDirection.value);
      }

      function resetTempAddressValue() {
        tempAddressValue.country = '';
        tempAddressValue.state = '';
        tempAddressValue.suburb = '';
        tempAddressValue.postcode = ''
      }

      function viewTermsAndCondition() {
        modalTerms.show = true
      }

      async function findPublishedTermAndCondition() {
        await axios.get('/v1/get-published-terms-and-condition/').then((response) => {
          if (response.data != null) {
            tcDescription.value = response.data.data.description
          } else {
            console.error('Error!')
          }
        })
      }

      async function setToParentGatewayAccount() {
        resetSlideOverDetails()
        form.is_parent_cred_used = 1;
        gatewayOptions.value = [];
        form.states = [];
        form.isSelectAllGatewayPaymentMethod = 0;

        disableGatewayDetailsValue.value = true;
        isLoading.value = true;
        await axios
          .get('v1/get-merchant-accounts-by-merchant-id-is-parent')
          .then((response) => {
            isLoading.value = false;
            if (response.data.data != null) {
              parentMchId.value = response.data.data.mch_acc_id;
              getMerchantAccountCredentialsDetailsByParentAccId(parentMchId.value);
              removePaymentDevices()
              form.errors = ''
            }
          })
      }

      async function getMerchantAccountCredentialsDetailsByParentAccId(parentMchId) {
         isLoading.value = true;
        await axios
          .get('/v1/get-merchant-account-payment-gateway-credentials/' + parentMchId)
          .then((response) => {
            isLoading.value = false
            if (response.data != null) {
              merchantAccountCredentialsDetails.value = response.data.data;

              merchantAccountCredentialsDetails.value.forEach((gatewayCredentials) => {
                form.parentGws.push(gatewayCredentials.gw_id)
              })

              if(form.parentGws.includes(parseInt(gatewayIdInEditMode.value))){
                merchantAccountCredentialsDetails.value.forEach((gatewayCredentials) => {
                  if(gatewayCredentials.gw_id == gatewayIdInEditMode.value){
                    form.gw_public_key = gatewayCredentials.gw_public_key;
                    form.gw_private_key = gatewayCredentials.gw_private_key;
                    form.gw_mch_id = gatewayCredentials.gw_mch_id;
                    form.gw_key1 = gatewayCredentials.gw_key1;
                    form.gw_key2 = gatewayCredentials.gw_key2;
                    form.gw_key3 = gatewayCredentials.gw_key3;
                    form.gw_key4 = gatewayCredentials.gw_key4
                    // isLoading.value = true
                    form.encrypt_gw_public_key = form.gw_public_key? constant.encryptionKey : '';
                    form.encrypt_gw_private_key = form.gw_private_key ? constant.encryptionKey : '';
                    form.encrypt_gw_mch_id = form.gw_mch_id ? constant.encryptionKey : '';
                    form.encrypt_gw_key1 = form.gw_key1 ? constant.encryptionKey : '';
                    form.encrypt_gw_key2 = form.gw_key2 ? constant.encryptionKey : '';
                    form.encrypt_gw_key3 = form.gw_key3 ? constant.encryptionKey : '';
                    form.encrypt_gw_key4 = form.gw_key4 ? constant.encryptionKey : ''
                    // isLoading.value = false
                    gatewayCredentials.credential_payment_methods.forEach((paymentMethod) => {
                      form.states.push(paymentMethod.tnt_pm_id)
                    })
                    checkAllPaymentMethodSelect()
                  }
                });
              }else{
                modalLinkToParentAccount.type = 'warning'
                modalLinkToParentAccount.title = 'Warning'
                modalLinkToParentAccount.message =
                  'Gateway credential details missing for this gateway in parent account.'
                modalLinkToParentAccount.show = true
              }
            } else {
              console.error('Error!')
            }
          });
        // getGatewayIdAndNameOfParentMerchantAccount(parentMchId);
        isLoading.value = false
      }

      function closeAlertLinkToParentAccount() {
        modalLinkToParentAccount.show = false
        form.is_parent_cred_used = 0
        disableGatewayDetailsValue.value = false
      }

      async function getGatewayIdAndNameOfParentMerchantAccount(parentMchId) {
        await axios.get('/v1/get-merchant-account/' + parentMchId).then((response) => {
          if (response.data != null) {
            merchantAccountDetails.value = response.data.data;
            if (merchantAccountDetails.value.gw_id) {
              form.gw_id = merchantAccountDetails.value.gw_id;
              if (form.gw_id) {
                form.gw_name =
                  merchantAccountDetails.value.merchant_account_gateway.name
              }
              let obj = {};
              obj.text = form.gw_name;
              obj.value = form.gw_id;
              gatewayOptions.value.push(obj);
              getGatewayPaymentMethods()
            }
          } else {
            console.error('Error!')
          }
        })
        await getSecurityProfiles()
      }

      function setToExistingGatewayAccount() {
        form.states = [];
        form.isSelectAllGatewayPaymentMethod = 0;
        form.gw_mch_id = '';
        form.gw_public_key = '';
        form.gw_private_key = '';
        form.gw_key1 = '';
        form.gw_key2 = '';
        form.gw_key3 = '';
        form.gw_key4 = '';
        form.encrypt_gw_mch_id = '';
        form.encrypt_gw_public_key = '';
        form.encrypt_gw_private_key = '';
        form.encrypt_gw_key1 = '';
        form.encrypt_gw_key2 = '';
        form.encrypt_gw_key3 = '';
        form.encrypt_gw_key4 = '';
        form.gw_id = '';
        form.is_parent_cred_used = 0
        disableGatewayDetailsValue.value = false;
        removePaymentDevices()
      }

      function getMerchantAccountCredentialsDetailsSetToExistingGateway() {
        isLoading.value = true;
        const mch_acc_id = route.params.mch_acc_id;
        axios
          .get('/v1/get-merchant-account-credentials/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountCredentialsDetails.value = response.data.data;
              form.gw_public_key = merchantAccountCredentialsDetails.value.gw_public_key;
              form.gw_private_key =
                merchantAccountCredentialsDetails.value.gw_private_key;
              form.gw_mch_id = merchantAccountCredentialsDetails.value.gw_mch_id;
              form.gw_key1 = merchantAccountCredentialsDetails.value.gw_key1;
              form.gw_key2 = merchantAccountCredentialsDetails.value.gw_key2;
              form.gw_key3 = merchantAccountCredentialsDetails.value.gw_key3;
              form.gw_key4 = merchantAccountCredentialsDetails.value.gw_key4
            } else {
              console.error('Error!')
            }
          });
        isLoading.value = false
      }

      function removePaymentDevices(){
        if ( merchantAccountGatewayPaymentDevices.value.length>0) {
          merchantAccountGatewayPaymentDevices.value.forEach((paymentDevice, index) => {
            if (paymentDevice.mch_acc_pd_id != null && paymentDevice.mch_acc_pd_id != '') {
              let obj = {}
              obj.mch_acc_pd_id = paymentDevice.mch_acc_pd_id;
              obj.row_version = paymentDevice.row_version;
              form.deleted_mch_acc_gw_devices.push(obj)
            }
          })
        }
        merchantAccountGatewayPaymentDevices.value = []
      }

      async function getParentMerchantAccountDetails() {
        disableInvoiceDirectoryValue.value = false
        isLoading.value = true
        await axios
          .get('v1/get-merchant-accounts-by-merchant-id-is-parent')
          .then((response) => {
            if (response.data.data != null) {
              form.parentMchAccId = response.data.data.mch_acc_id
              if(form.act_as_agent == 1){
                getParentGateways(form.parentMchAccId)
              }
              parentMerchantCountryCode.value = response.data.data.country_code
              if(form.countryId != parentMerchantCountryCode.value){
                disableInvoiceDirectoryValue.value = true
                form.is_invoice_directly = 0
              }
            }
          })
        await getMerchantAccountDetailsById()
      }

      async function getParentGateways(parentMchAccId){
        gatewayDetails.value = []
        await axios
            .get('/v1/get-merchant-account-payment-gateway-credentials/' + parentMchAccId)
            .then((response) => {
              if (response.data != null) {
                parentGatewayDetails.value = response.data.data

                parentGatewayDetails.value.forEach((gatewayCredentials) => {
                  let obj = {}
                  obj.gw_id = gatewayCredentials.credential_gateway.gw_id
                  obj.name = gatewayCredentials.credential_gateway.name
                  form.parentGateways.push(obj)
                })

                gatewayDetails.value = form.parentGateways
              }
            })
      }

      async function getGatewayApiKeysDetails(gw_id) {
        requiredApiKeysValue.value = false
        if(gw_id != null && gw_id != ''){
          await axios
            .get('v1/gateway/api-keys/view/' + gw_id)
            .then((response) => {
              if (response.data.data != null) {
                if(response.data.data.is_merchant_cred_used == 0){
                  requiredApiKeysValue.value = true
                }
              }
            })
        }
      }

      async function checkInvoiceDirectlyAvailable(mchAccId){
        if(mchAccId != null && mchAccId != '') {
         await axios
            .get('v1/check-merchant-account-invoice-directly-available/' + mchAccId)
            .then((response) => {
              isLoading.value = false
              if (response.data.data != null) {
                isInvoiceDirectlyAvailable.value = response.data.data['isAvailableInvoiceDirectly'];
              }
            })
        }
      }

      async function getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId(formViewMode){
        disableValue.value = false
        const mch_acc_id = route.params.mch_acc_id;
        await axios
          .get('/v1/get-merchant-account-payment-gateway-credentials/' + mch_acc_id)
          .then( async (response) => {
            isLoading.value = false
            if (response.data != null) {
              paymentGatewayDetails.value = response.data.data
              paymentGatewayDetails.value.forEach((gatewayCredentials) => {
                form.gateways.push(gatewayCredentials.gw_id)
              })

              if(formViewMode == true){
                await editPaymentGateway(route.params.gwId,route.params.gwName, formViewMode)
                await getGatewayPaymentMethods()
              }
              if(form.gateways.length != 0){
                disableValue.value = true
              }
            } else {
              console.error('Error!')
            }
          })
      }

      function checkGatewayDetailsCheckbox(gwId){
          if (form.gateways.includes(parseInt(gwId))) {
            return true
        } else {
          paymentGatewayDetails.value.forEach((paymentMethod) => {
            if(paymentMethod.gw_id == gwId) {
              if(paymentMethod.hasOwnProperty("credential_payment_methods")) {
                unset(paymentMethod.credential_payment_methods)
              }
            }
              })
        }
      }


      async function editPaymentGateway(gwId, gwName, mode = null){
        if(mode != true){
          activeSubTab.value = 'payment_methods'
        }
        gatewayIdInEditMode.value = gwId
        gatewayNameInEditMode.value = gwName
        form.gw_id = gatewayIdInEditMode.value
        form.errors = ''
        errorMchId.value = ''
        errorPrivateKey.value = ''
        errorPublicKey.value = ''
        errorPaymentMethod.value = ''
        errorApiKey1.value = ''
        form.encrypt_gw_mch_id = constant.encryptionKey
        form.encrypt_gw_public_key = constant.encryptionKey
        form.encrypt_gw_private_key = constant.encryptionKey
        form.encrypt_gw_key1 = constant.encryptionKey
        form.encrypt_gw_key2 = constant.encryptionKey
        form.encrypt_gw_key3 = constant.encryptionKey
        form.encrypt_gw_key4 = constant.encryptionKey
        let obj = {}
        obj.value = form.gw_id
        obj.text = gatewayNameInEditMode.value
        gatewayOptions.value.push(obj)
        resetSlideOverDetails()
        getAllPaymentDevicePerGateway(gatewayIdInEditMode.value)
        setDetailsToSlideOver(gatewayIdInEditMode.value, gatewayNameInEditMode.value)
        getGatewayApiKeysDetails(gatewayIdInEditMode.value)
      }

      function setDetailsToSlideOver(gatewayIdInEditMode, gatewayNameInEditMode = null){
        merchantAccountGatewayPaymentDevices.value = []
        form.states = []
        form.isSelectAllGatewayPaymentMethod = 0
        let newValue = true
        decryptionButtonShow.value = true
        AddGwsSlideOver.title = "Payment Gateways - " + gatewayNameInEditMode
        paymentGatewayDetails.value.forEach((gatewayDetails) => {
          if(gatewayDetails.gw_id == gatewayIdInEditMode){
            newValue = false
            form.gw_mch_id = gatewayDetails.gw_mch_id
            form.gw_private_key = gatewayDetails.gw_private_key
            form.gw_public_key = gatewayDetails.gw_public_key
            form.gw_key1 = gatewayDetails.gw_key1
            form.gw_key2 = gatewayDetails.gw_key2
            form.gw_key3 = gatewayDetails.gw_key3
            form.gw_key4 = gatewayDetails.gw_key4
            form.mch_acc_pgw_id = gatewayDetails.mch_acc_pgw_id
            if((gatewayDetails.hasOwnProperty('credential_gateway'))) {
              form.device_paring = gatewayDetails.credential_gateway.device_pairing
              form.is_js_support = gatewayDetails.credential_gateway.is_js_support
            }
            if(gatewayDetails.is_parent_cred_used == 1){
              form.is_parent_cred_used = 1
              disableGatewayDetailsValue.value = true
            }else{
              form.is_parent_cred_used = 0
              disableGatewayDetailsValue.value = false
            }
            if(gatewayDetails.newValue == true || gatewayDetails.applyChanges == true){
              decryptionButtonShow.value  = false
              isLoading.value = true
              form.encrypt_gw_mch_id = (gatewayDetails.encrypt_gw_mch_id) ? gatewayDetails.encrypt_gw_mch_id : decryptData(gatewayDetails.gw_mch_id, 'gw_mch_id')
              form.encrypt_gw_private_key = (gatewayDetails.encrypt_gw_private_key) ? gatewayDetails.encrypt_gw_private_key : decryptData(gatewayDetails.gw_private_key,'gw_private_key')
              form.encrypt_gw_public_key = (gatewayDetails.encrypt_gw_public_key) ? gatewayDetails.encrypt_gw_public_key : decryptData(gatewayDetails.gw_public_key, 'gw_public_key')
              form.encrypt_gw_key1 = (gatewayDetails.encrypt_gw_key1) ? gatewayDetails.encrypt_gw_key1 : gatewayDetails.gw_key1 ? decryptData(gatewayDetails.gw_key1, 'gw_key1') : ''
              form.encrypt_gw_key2 = (gatewayDetails.encrypt_gw_key2) ? gatewayDetails.encrypt_gw_key2 : gatewayDetails.gw_key2 ? decryptData(gatewayDetails.gw_key2, 'gw_key2') : ''
              form.encrypt_gw_key3 = (gatewayDetails.encrypt_gw_key3) ? gatewayDetails.encrypt_gw_key3 : gatewayDetails.gw_key3 ? decryptData(gatewayDetails.gw_key3, 'gw_key3') : ''
              form.encrypt_gw_key4 = (gatewayDetails.encrypt_gw_key4) ? gatewayDetails.encrypt_gw_key4 : gatewayDetails.gw_key4 ? decryptData(gatewayDetails.gw_key4, 'gw_key4') : ''
              isLoading.value = false
            }else{
              if(gatewayDetails.gw_mch_id == null || gatewayDetails.gw_mch_id == ''){
                form.encrypt_gw_mch_id = ''
              }
              if(gatewayDetails.gw_private_key == null || gatewayDetails.gw_private_key == ''){
                form.encrypt_gw_private_key = ''
              }
              if(gatewayDetails.gw_public_key == null || gatewayDetails.gw_public_key == ''){
                form.encrypt_gw_public_key = ''
              }
              if(gatewayDetails.gw_key1 == null || gatewayDetails.gw_key1 == ''){
                form.encrypt_gw_key1 = ''
              }
              if(gatewayDetails.gw_key2 == null || gatewayDetails.gw_key2 == ''){
                form.encrypt_gw_key2 = ''
              }
              if(gatewayDetails.gw_key3 == null || gatewayDetails.gw_key3 == ''){
                form.encrypt_gw_key3 = ''
              }
              if(gatewayDetails.gw_key4 == null || gatewayDetails.gw_key4 == ''){
                form.encrypt_gw_key4 = ''
              }
            }

            if(gatewayDetails.paymentMethods){
              gatewayDetails.paymentMethods.forEach((paymentMethod) => {
                form.states.push(paymentMethod)
              })
            }else{
              gatewayDetails.credential_payment_methods.forEach((paymentMethod) => {
                form.states.push(paymentMethod.tnt_pm_id)
              })
            }

            if(gatewayDetails.device){
              gatewayDetails.device.forEach((deviceDetails) => {
                let obj = {}
                obj.mch_acc_pd_id = deviceDetails.mch_acc_pd_id
                obj.pd_name = deviceDetails.pd_name
                obj.location = deviceDetails.location
                obj.status = deviceDetails.status
                obj.gw_id = deviceDetails.gw_id
                obj.gw_pd_id = deviceDetails.gw_pd_id
                obj.applyDeviceChanges = deviceDetails.applyDeviceChanges

                obj.pd_serial_number = deviceDetails.pd_serial_number
                obj.pd_key1 = deviceDetails.pd_key1
                obj.pd_key2 = deviceDetails.pd_key2
                obj.pd_key3 = deviceDetails.pd_key3
                obj.pd_key4 = deviceDetails.pd_key4
                obj.pd_key5 = deviceDetails.pd_key5

                obj.encrypt_pd_serial_number = deviceDetails.encrypt_pd_serial_number ? deviceDetails.encrypt_pd_serial_number : deviceDetails.pd_serial_number
                // obj.encrypt_pd_key1 = deviceDetails.encrypt_pd_key1 ? deviceDetails.encrypt_pd_key1 : constant.encryptionKey
                // obj.encrypt_pd_key2 = deviceDetails.encrypt_pd_key2 ? deviceDetails.encrypt_pd_key2 : constant.encryptionKey
                // obj.encrypt_pd_key3 = deviceDetails.encrypt_pd_key3 ? deviceDetails.encrypt_pd_key3 : constant.encryptionKey
                // obj.encrypt_pd_key4 = deviceDetails.encrypt_pd_key4 ? deviceDetails.encrypt_pd_key4 : constant.encryptionKey
                // obj.encrypt_pd_key5 = deviceDetails.encrypt_pd_key5 ? deviceDetails.encrypt_pd_key5 : constant.encryptionKey

                if (form.gw_id == obj.gw_id) {
                  merchantAccountGatewayPaymentDevices.value.push(obj)
                }
              })
            }else{
              gatewayDetails.credential_payment_device.forEach((deviceDetails) => {
                let obj = {}
                obj.mch_acc_pd_id = deviceDetails.mch_acc_pd_id
                obj.pd_name = deviceDetails.pd_name
                obj.location = deviceDetails.location
                obj.pd_serial_number = deviceDetails.pd_serial_number
                obj.status = deviceDetails.status
                obj.gw_id = deviceDetails.gw_id
                obj.pd_key1 = deviceDetails.pd_key1
                obj.pd_key2 = deviceDetails.pd_key2
                obj.pd_key3 = deviceDetails.pd_key3
                obj.pd_key4 = deviceDetails.pd_key4
                obj.pd_key5 = deviceDetails.pd_key5
                obj.gw_pd_id = deviceDetails.gw_pd_id

                obj.encrypt_pd_serial_number = constant.encryptionKey
                obj.encrypt_pd_key1 = constant.encryptionKey
                obj.encrypt_pd_key2 = constant.encryptionKey
                obj.encrypt_pd_key3 = constant.encryptionKey
                obj.encrypt_pd_key4 = constant.encryptionKey
                obj.encrypt_pd_key5 = constant.encryptionKey

                merchantAccountGatewayPaymentDevices.value.push(obj)
              })
            }
          }
        })
        if(newValue == true){
          form.encrypt_gw_mch_id = ''
          form.encrypt_gw_public_key = ''
          form.encrypt_gw_private_key = ''
          form.encrypt_gw_key1 = ''
          form.encrypt_gw_key2 = ''
          form.encrypt_gw_key3 = ''
          form.encrypt_gw_key4 = ''
          decryptionButtonShow.value = false
        }
      }

      function applyGatewayPaymentChanges(){
        let newValue = true
        let payment_method_id = ''
        errorMchId.value = ''
        errorPrivateKey.value = ''
        errorPublicKey.value = ''
        errorPaymentMethod.value = ''
        errorApiKey1.value = ''

        if((form.encrypt_gw_mch_id == null || form.encrypt_gw_mch_id == '') && requiredApiKeysValue.value == false){
          errorMchId.value = 'Please enter the merchant id.'
        }
        if((form.encrypt_gw_private_key == null || form.encrypt_gw_private_key == '') && requiredApiKeysValue.value == false){
          errorPrivateKey.value = 'Please enter the API private key.'
        }
        if((form.encrypt_gw_key1 == null || form.encrypt_gw_key1 == '') && form.is_js_support == true){
          errorApiKey1.value = 'Please enter the API key 1.'
        }

        if(form.states.length == 1){
          form.states.forEach((paymentMethod) => {
            payment_method_id = paymentMethod
          });

          merchantAccountGatewayPaymentMethods.value.forEach((paymentMethod) => {
            if(paymentMethod.tnt_pm_id == payment_method_id){
              if(paymentMethod.pm_cat == 'CARD_NOT_PRESENT'){
                errorPaymentMethod.value = 'Please select at least one payment method.'
                activeMainTabId.value = 'tab2';
                activeSubTab.value = 'payment_methods'
              }
            }
          });
        }
        if(form.states.length == 0){
          errorPaymentMethod.value = 'Please select at least one payment method.'
          activeMainTabId.value = 'tab2';
          activeSubTab.value = 'payment_methods'
        }

        if(errorMchId.value == '' && errorPrivateKey.value == '' && errorPublicKey.value == '' && errorPaymentMethod.value == '' && errorApiKey1.value == ''){
          paymentGatewayDetails.value.forEach((gatewayData) => {
            if(gatewayData.gw_id == gatewayIdInEditMode.value){

              newValue = false
              gatewayData.applyChanges = true
              if(form.encrypt_gw_mch_id == constant.encryptionKey){
                gatewayData.gw_mch_id = form.gw_mch_id
              }else{
                gatewayData.encrypt_gw_mch_id = form.encrypt_gw_mch_id
                gatewayData.gw_mch_id = ''
              }

              if(form.encrypt_gw_private_key == constant.encryptionKey){
                gatewayData.gw_private_key = form.gw_private_key
              }else{
                gatewayData.encrypt_gw_private_key = form.encrypt_gw_private_key
                gatewayData.gw_private_key = ''
              }

              if(form.encrypt_gw_public_key == constant.encryptionKey){
                gatewayData.gw_public_key = form.gw_public_key
              }else{
                gatewayData.encrypt_gw_public_key = form.encrypt_gw_public_key
                gatewayData.gw_public_key = ''
              }

              if(form.encrypt_gw_key1 == constant.encryptionKey){
                gatewayData.gw_key1 = form.gw_key1
              }else{
                gatewayData.encrypt_gw_key1 = form.encrypt_gw_key1
                gatewayData.gw_key1 = ''
              }

              if(form.encrypt_gw_key2 == constant.encryptionKey){
                gatewayData.gw_key2 = form.gw_key2
              }else{
                gatewayData.encrypt_gw_key2 = form.encrypt_gw_key2
                gatewayData.gw_key2 = ''
              }

              if(form.encrypt_gw_key3 == constant.encryptionKey){
                gatewayData.gw_key3 = form.gw_key3
              }else{
                gatewayData.encrypt_gw_key3 = form.encrypt_gw_key3
                gatewayData.gw_key3 = ''
              }

              if(form.encrypt_gw_key4 == constant.encryptionKey){
                gatewayData.gw_key4 = form.gw_key4
              }else{
                gatewayData.encrypt_gw_key4 = form.encrypt_gw_key4
                gatewayData.gw_key4 = ''
              }

              gatewayData.is_parent_cred_used = form.is_parent_cred_used
              gatewayData.paymentMethods = form.states

              gatewayData.device = []
              merchantAccountGatewayPaymentDevices.value.forEach((device) => {
                let deviceObj = {}
                let applyDeviceChanges = 'false'
                deviceObj.mch_acc_pd_id = device.mch_acc_pd_id
                deviceObj.pd_name = device.pd_name
                deviceObj.location = device.location
                deviceObj.status = device.status
                deviceObj.gw_id = device.gw_id

                if(device.encrypt_pd_serial_number == constant.encryptionKey){
                  deviceObj.pd_serial_number = device.pd_serial_number
                  deviceObj.encrypt_pd_serial_number = ''
                }else{
                  deviceObj.encrypt_pd_serial_number = device.encrypt_pd_serial_number
                  deviceObj.pd_serial_number = ''
                  applyDeviceChanges = 'true'
                }

                if(device.encrypt_pd_key1 == constant.encryptionKey){
                  deviceObj.pd_key1 = device.pd_key1
                  deviceObj.encrypt_pd_key1 = ''
                }else{
                  deviceObj.encrypt_pd_key1 = device.encrypt_pd_key1
                  deviceObj.pd_key1 = ''
                  applyDeviceChanges = 'true'
                }

                if(device.encrypt_pd_key2 == constant.encryptionKey){
                  deviceObj.pd_key2 = device.pd_key2
                  deviceObj.encrypt_pd_key2 = ''
                }else{
                  deviceObj.encrypt_pd_key2 = device.encrypt_pd_key2
                  deviceObj.pd_key2 = ''
                  applyDeviceChanges = 'true'
                }

                if(device.encrypt_pd_key3 == constant.encryptionKey){
                  deviceObj.pd_key3 = device.pd_key3
                  deviceObj.encrypt_pd_key3 = ''
                }else{
                  deviceObj.encrypt_pd_key3 = device.encrypt_pd_key3
                  deviceObj.pd_key3 = ''
                  applyDeviceChanges = 'true'
                }

                if(device.encrypt_pd_key4 == constant.encryptionKey){
                  deviceObj.pd_key4 = device.pd_key4
                  deviceObj.encrypt_pd_key4 = ''
                }else{
                  deviceObj.encrypt_pd_key4 = device.encrypt_pd_key4
                  deviceObj.pd_key4 = ''
                  applyDeviceChanges = 'true'
                }

                if(device.encrypt_pd_key5 == constant.encryptionKey){
                  deviceObj.pd_key5 = device.pd_key5
                  deviceObj.encrypt_pd_key5 = ''
                }else{
                  deviceObj.encrypt_pd_key5 = device.encrypt_pd_key5
                  deviceObj.pd_key5 = ''
                  applyDeviceChanges = 'true'
                }

                if(applyDeviceChanges == 'true'){
                  deviceObj.applyDeviceChanges = 'true'
                }

                deviceObj.gw_pd_id = device.gw_pd_id ? device.gw_pd_id : device.gw_pd_id
                gatewayData.device.push(deviceObj)
              })
              blueEditButtonIsVisible(gatewayIdInEditMode.value);
            }
          })

          if(newValue == true){
            let obj = {}
            obj.gw_id = gatewayIdInEditMode.value

            if(form.encrypt_gw_mch_id == constant.encryptionKey){
              obj.gw_mch_id = form.gw_mch_id
            }else{
              obj.encrypt_gw_mch_id = form.encrypt_gw_mch_id
            }

            if(form.encrypt_gw_private_key == constant.encryptionKey){
              obj.gw_private_key = form.gw_private_key
            }else{
              obj.encrypt_gw_private_key = form.encrypt_gw_private_key
            }

            if(form.encrypt_gw_public_key == constant.encryptionKey){
              obj.gw_public_key = form.gw_public_key
            }else{
              obj.encrypt_gw_public_key = form.encrypt_gw_public_key
            }

            if(form.encrypt_gw_key1 == constant.encryptionKey){
              obj.gw_key1 = form.gw_key1
            }else{
              obj.encrypt_gw_key1 = form.encrypt_gw_key1
            }

            if(form.encrypt_gw_key2 == constant.encryptionKey){
              obj.gw_key2 = form.gw_key2
            }else{
              obj.encrypt_gw_key2 = form.encrypt_gw_key2
            }

            if(form.encrypt_gw_key3 == constant.encryptionKey){
              obj.gw_key3 = form.gw_key3
            }else{
              obj.encrypt_gw_key3 = form.encrypt_gw_key3
            }

            if(form.encrypt_gw_key4 == constant.encryptionKey){
              obj.gw_key4 = form.gw_key4
            }else{
              obj.encrypt_gw_key4 = form.encrypt_gw_key4
            }

            obj.is_parent_cred_used = form.is_parent_cred_used
            obj.paymentMethods = form.states

            merchantAccountGatewayPaymentDevices.value.forEach((device) => {
              if(device.gw_id == form.gw_id){
                let obj1 = {}
                obj1.mch_acc_pd_id = device.mch_acc_pd_id
                obj1.pd_name = device.pd_name
                obj1.location = device.location
                obj1.pd_serial_number = device.pd_serial_number ? device.pd_serial_number : device.encrypt_pd_serial_number
                obj1.status = device.status
                obj1.gw_id = device.gw_id
                obj1.pd_key1 = device.pd_key1 ? device.pd_key1 : device.encrypt_pd_key1
                obj1.pd_key2 = device.pd_key2 ? device.pd_key2 : device.encrypt_pd_key2
                obj1.pd_key3 = device.pd_key3 ? device.pd_key3 : device.encrypt_pd_key3
                obj1.pd_key4 = device.pd_key4 ? device.pd_key4 : device.encrypt_pd_key4
                obj1.pd_key5 = device.pd_key5 ? device.pd_key5 : device.encrypt_pd_key5
                obj1.gw_pd_id = device.gw_pd_id
                gatewayPaymentDevices.value.push(obj1)
              }
            })
            obj.device = gatewayPaymentDevices.value
            obj.newValue = newValue
            paymentGatewayDetails.value.push(obj)
          }
          AddGwsSlideOver.show = false
          resetSlideOverDetails()
        }

      }

      function resetSlideOverDetails(){
        AddGwsSlideOver.title = "Payment Gateways"
        form.gw_mch_id = ''
        form.gw_private_key = ''
        form.gw_public_key = ''
        form.gw_key1 = ''
        form.gw_key2 = ''
        form.gw_key3 = ''
        form.gw_key4 = ''
        form.states = []
        form.isSelectAllGatewayPaymentMethod = 0
        if(form.act_as_agent == 1 && form.is_parent == 0){
          form.is_parent_cred_used = 1
        }else{
          form.is_parent_cred_used = 0
        }
        disableGatewayDetailsValue.value = false
      }

      async function clickGateway(event, gw_id, gw_name, device_pairing, is_js_support){
        await deleteUncheckedGatewayDevices(gw_id)
        if(event.target.checked){
          form.is_js_support = is_js_support
          form.device_paring = device_pairing
          errorMchId.value = ''
          errorPrivateKey.value = ''
          errorPublicKey.value = ''
          errorPaymentMethod.value = ''
          errorApiKey1.value = ''
          AddGwsSlideOver.show = true
          editPaymentGateway(gw_id, gw_name, null)
          form.gateways.push(gw_id)
          errorMsgForGateway.value = ''
          getGatewayPaymentMethods()
          if(form.act_as_agent == 1 && form.is_parent == 0){
            setToParentGatewayAccount()
          }
        }else{
          if(form.is_parent){
            event.preventDefault()
            getAllSubAccountsLinkToGateway(event, gw_id)
          }else{
            removeGatewayFromAccount(gw_id)
          }
        }
      }

      function disableEditButton(gwId){
        return !form.gateways.includes(parseInt(gwId));
      }

      function redEditButtonIsVisible(gwId) {
        let value = false
        let js_payment_value = false
          paymentGatewayDetails.value.forEach((gwDetails) => {
            if(gwDetails.gw_id == gwId){
              if(gwDetails.hasOwnProperty("credential_payment_methods")) {
                if(gwDetails.credential_payment_methods.length != 0) {
                  value = true
                }else if(gwDetails.hasOwnProperty("paymentMethods")) {
                  if(gwDetails.paymentMethods.length != 0) {
                    value = true
                  }else {
                    notComplete.value = true
                  }
                }else {
                    notComplete.value = true
                }
              }else {
                value = true
              }
              if(gwDetails.hasOwnProperty("credential_gateway")) {
                if(gwDetails.credential_gateway.is_js_support) {
                  if((gwDetails.encrypt_gw_key1 == '' || gwDetails.encrypt_gw_key1 == null) && (gwDetails.gw_key1 == '' || gwDetails.gw_key1 == null)) {
                    notComplete.value = true
                  } else {
                    js_payment_value = true
                  }
                } else {
                  js_payment_value = true
                }
              }else {
                js_payment_value = true
              }
            }
          })
          if(form.gateways.includes(parseInt(gwId)) && (value == false || js_payment_value == false) ){
            return true
          }

      }

      function blueEditButtonIsVisible(gwId) {
        let value = false
        let js_payment_value = false
          paymentGatewayDetails.value.forEach((gwDetails) => {
            if(gwDetails.gw_id == gwId){
              if(gwDetails.hasOwnProperty("credential_payment_methods")) {
                if(gwDetails.credential_payment_methods.length != 0) {
                  value = true
                }else if(gwDetails.hasOwnProperty("paymentMethods")) {
                  if(gwDetails.paymentMethods.length != 0) {
                    value = true
                  }else {
                    notComplete.value = true
                  }
                }else {
                    notComplete.value = true
                }
              }else {
                value = true
              }
              //Check if the js_payment is enable and if it is enable, API key is required. If it is not entered, should be displayed incomple button
              if(gwDetails.hasOwnProperty("credential_gateway")) {
                if(gwDetails.credential_gateway.is_js_support) {
                  if((gwDetails.encrypt_gw_key1 == '' || gwDetails.encrypt_gw_key1 == null) && (gwDetails.gw_key1 == '' || gwDetails.gw_key1 == null)) {
                    notComplete.value = true
                  } else {
                    js_payment_value = true
                  }
                } else {
                  js_payment_value = true
                }
              }else {
                js_payment_value = true
              }
            }
          })
          if(!form.gateways.includes(parseInt(gwId)) || (value == true && js_payment_value == true)){
            notComplete.value = false
            return true
          }
      }

      function closeWarning() {
        modalWarning.show = false
      }

      async function decryptData(data, value){
        decryptDataForm.data = data
        isLoading.value = true
        if (decryptDataForm.data !==  undefined){
          await axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
            if (response.data != null) {
              if(value == 'gw_mch_id'){
                form.encrypt_gw_mch_id = response.data
              }
              else if(value == 'gw_public_key'){
                form.encrypt_gw_public_key = response.data
              }
              else if(value == 'gw_private_key'){
                form.encrypt_gw_private_key = response.data
              }
              else if(value == 'gw_key1'){
                form.encrypt_gw_key1 = response.data
              }
              else if(value == 'gw_key2'){
                form.encrypt_gw_key2 = response.data
              }
              else if(value == 'gw_key3'){
                form.encrypt_gw_key3 = response.data
              }
              else if(value == 'gw_key4'){
                form.encrypt_gw_key4 = response.data
              }
            } else {
              console.error('Error!')
              // isLoading.value = false
            }
            isLoading.value = false
          })
        }
      }

      async function decryptDeviceData(data, value){
        decryptDeviceDataForm.data = data
        isLoading.value = true
        await axios.post('/v1/decrypt-data', decryptDeviceDataForm).then((response) => {
          if (response.data != null) {
            if(value == 'pd_serial_number'){
              formDevice.encrypt_pd_serial_number = response.data
            }
            else if(value == 'pd_key1'){
              formDevice.encrypt_pd_key1 = response.data
            }
            else if(value == 'pd_key2'){
              formDevice.encrypt_pd_key2 = response.data
            }
            else if(value == 'pd_key3'){
              formDevice.encrypt_pd_key3 = response.data
            }
            else if(value == 'pd_key4'){
              formDevice.encrypt_pd_key4 = response.data
            }
            else if(value == 'pd_key5'){
              formDevice.encrypt_pd_key5 = response.data
            }
          } else {
            console.error('Error!')
            // isLoading.value = false
          }
          isLoading.value = false
        })
      }

      async function getParentGatewaysWithAcceptedCountry(parentMchAccId, countryId){
        gatewayDetails.value = []
        form.parentAceeptedCountrygateways = []
        if(parentMchAccId !== '' && countryId !== ''){
          await axios
              .get('/v1/get-parent-gateways-with-accepted-country/' + parentMchAccId + '/' + countryId)
              .then((response) => {
                if (response.data != null) {
                  response.data.data.forEach((gateways) => {
                    let obj = {}
                    obj.gw_id = gateways.gw_id
                    obj.name = gateways.name
                    form.parentAceeptedCountrygateways.push(obj)
                  })
                  gatewayDetails.value = form.parentAceeptedCountrygateways
                } else {
                  console.error('Error!')
                }
              })
        }else {
          console.error('Error!')
        }
      }

      async function getAllSubAccountsLinkToGateway(event, gw_id){
        isLoading.value = true
        form.subAccount = []

        if(form.is_parent && !event.target.checked){
          await axios
              .get('/v1/get-all-sub-accounts-link-to-gateway/' + gw_id)
              .then((response) => {
                isLoading.value = false
                if (response.data != null) {
                  response.data.data.forEach((subAccount) => {
                      form.subAccount.push(subAccount.mch_acc_name)
                  });

                  if(form.subAccount.length > 0){
                    event.target.checked = true;
                    modalWarning.type = 'warning'
                    modalWarning.title = 'Warning'
                    modalWarning.message = 'Please remove the gateway from below sub merchant accounts to remove the gateway from ' + form.organisationName  + ' merchant account.'
                    modalWarning.array = form.subAccount
                    modalWarning.show = true
                  }else{
                    removeGatewayFromAccount(gw_id)
                  }
                } else {
                  console.error('Error!')
                }
          })
        }
      }

      function removeGatewayFromAccount(gw_id){
        form.gateways.forEach((gw, index1) => {
          if(gw_id == gw){
            form.gateways.splice((parseInt(index1)), 1)
          }
        })

        paymentGatewayDetails.value.forEach((gatewayCredentials, index) => {
          if (gatewayCredentials.gw_id == gw_id) {
            paymentGatewayDetails.value.splice((parseInt(index)), 1)
            if (gatewayCredentials.hasOwnProperty('credential_payment_device')) {
              gatewayCredentials.credential_payment_device.forEach((device, index) => {
                let obj = {}
                obj.mch_acc_pd_id = device.mch_acc_pd_id
                obj.row_version = device.row_version
                form.deleted_mch_acc_gw_devices.push(obj)
              })
            }
          }
        })

        removeDevicesFromGateway(gw_id, form.mch_acc_id)
      }

      //get device details by device id
      function getGatewayPaymentDeviceByMchAccPaymentDeviceId(data) {
        formDevice.gw_pd_id = data.gw_pd_id;
        formDevice.pd_name = data.pd_name;
        formDevice.location = data.location;
        formDevice.status = data.status;
        formDevice.mch_acc_pd_id = data.mch_acc_pd_id;
        formDevice.encrypt_pd_serial_number = data.pd_serial_number != null ? constantValue : "";
        formDevice.encrypt_pd_key1 = data.pd_key1 != null ? constantValue : "";
        formDevice.encrypt_pd_key2 = data.pd_key2 != null ? constantValue : "";
        formDevice.encrypt_pd_key3 = data.pd_key3 != null ? constantValue : "";
        formDevice.encrypt_pd_key4 = data.pd_key4 != null ? constantValue : ""
        formDevice.encrypt_pd_key5 = data.pd_key5 != null ? constantValue : "";
        formDevice.pd_key1 = data.pd_key1;
        formDevice.pd_key2 = data.pd_key2;
        formDevice.pd_key3 = data.pd_key3;
        formDevice.pd_key4 = data.pd_key4;
        formDevice.pd_key5 = data.pd_key5;
        formDevice.pd_serial_number = data.pd_serial_number;
        formDevice.errors = '';
      }

      async function applyMerchantAccountGatewayPaymentDeviceDetails() {
        try {
          formDevice.gw_id = form.gw_id;
          formDevice.mch_acc_id = form.mch_acc_id;
          const apiUrl = slideOver.title === 'Add Device'
              ? '/v1/new-merchant-account-payment-device-details/' + form.mch_acc_id
              : '/v1/edit-merchant-account-payment-device-details/' + formDevice.mch_acc_pd_id;

          const response = await axios.post(apiUrl, formDevice);
          if (response.data != null && !response.data.data.errors) {
            let deviceData = response.data.data;
            if(
              formDevice.mch_acc_pd_id !== null &&
              formDevice.mch_acc_pd_id !== ''
            ){
              let removeObj = null;
              let existsDevice = merchantAccountGatewayPaymentDevices.value
              existsDevice.forEach((element, index) => {
                  if(element.mch_acc_pd_id === deviceData.mch_acc_pd_id){
                    removeObj = index
                  }
              });
              if(removeObj !== null){
                merchantAccountGatewayPaymentDevices.value.splice(removeObj, 1);
                merchantAccountGatewayPaymentDevices.value.push({...deviceData})
              }
            }else{
              merchantAccountGatewayPaymentDevices.value.push({...deviceData})
            }
            slideOver.show = false;
            AddGwsSlideOver.show = true;
          } else {
            formDevice.errors = response.data.data.errors;
          }
        } catch (error) {
          console.log("Error:", error);
        }
      }
      // get all devices by mch acc id and gw id
      async function getAllDevicesByMchAccIdAndGwId() {
        try {
          const response = await axios.get('/v1/get-all-devices/' + form.mch_acc_id + '/' + form.gw_id);

          if (response.data != null) {
            if(merchantAccountGatewayPaymentDevices.value.length === 0) {
              let deviceDetails = response.data.data;
                deviceDetails.forEach((element) => {
                  merchantAccountGatewayPaymentDevices.value.push({ ...element })
                })
            }else{
              merchantAccountGatewayPaymentDevices.value = response.data.data;
            }

          } else {
            console.error('Error!');
          }
        } catch (error) {
          console.log("Error:", error);
        }
      }


      //delete payment device
      async function deletePaymentDeviceTypeOption() {
        isLoading.value = true
        try{
          const response = await axios.post  ('/v1/delete-payment-device/' + PaymentDeviceTypeIdToDelete.value)
          if (response.data != null) {
            let arrIndex = null;
            let deviceData = merchantAccountGatewayPaymentDevices.value
            deviceData.forEach((element, index) => {
              if(element.mch_acc_pd_id === PaymentDeviceTypeIdToDelete.value){
                arrIndex = index
              }
            })
            if(arrIndex !== null){
              setTimeout(() => {
                merchantAccountGatewayPaymentDevices.value.splice(arrIndex, 1);
                isLoading.value = false
              }, 1000)
            }
          } else {
            console.error('Error!')
          }
        }catch (error){
          console.error('Error!', error)
        }
      }

      //remove devices form gateway
      function removeDevicesFromGateway(gw_id, mch_acc_id){
        axios.post  ('/v1/delete-all-payment-devices-per-gateway/' + gw_id + '/' + mch_acc_id).then((response) => {
          if (response.data != null) {
          } else {
            console.error('Error!')
          }
        })
      }

      //open pairing modal form
      function pairingCodePopup(pd_id){
        modalPairCode.show = true
        pairDetails.pair_device_id = pd_id
        pairDetails.pair_error = ''
        pairDetails.pair_code = ''
      }

      //pairing process
      function proceedPairing(){
        pairDetails.pair_error = ''
        fromDevice.value = false
        if(pairDetails.pair_code){
          modalPairCode.show = false
          pairLoading.show = true
          axios.post  ('/v1/generate-pair-key', pairDetails).then((response) => {
            if (response.data != null && !response.data.data.errors && response.data.data == true) {
              pairLoading.show = false
              modal.type = 'success';
              modal.title = 'Success';
              modal.message = 'Device paired successfully.';
              modal.show = true
              fromDevice.value = true
              getAllDevicesByMchAccIdAndGwId();

            } else {
              pairLoading.show = false
              modal.type = 'error';
              modal.title = 'Pairing failed';
              modal.message = 'Fail to find the device. Please check the device pairing code.';
              modal.show = true
              fromDevice.value = true
            }
          })
        }else{
          pairDetails.pair_error = 'Please enter a pair code.'
        }
      }

      //Change the device's pair status
      async function unpairDevice(mchAccPdId) {
        let response = await axios.post  ('/v1/unpair-device/' + mchAccPdId);
        if(response.data === null) {
          console.error("Error")
        } else {
          getAllDevicesByMchAccIdAndGwId();
        }
      }
      //Check if the payment method tick box should be disabled or not
      function disableSelectAllGatewayPaymentMethod(){
        if(paymentMethodList.value.length > 0) {
          return false;
        } else {
          return true;
        }
      }
      //Delete gateway devices which does not tick by the user
      async function deleteUncheckedGatewayDevices(gw_id){

        const mch_acc_id = route.params.mch_acc_id;
        let response = await axios.get('/v1/delete-unchecked-gateway-devices/' + mch_acc_id + '/' +gw_id);
      }

      return {
        t,
        fullPage,
        isLoading,
        showTabContent,
        activeMainTabId,
        accountEditTabItems,
        getMerchantAccountDetailsById,
        form,
        countryOptions,
        stateOptions,
        townOptions,
        postalCodeOptions,
        gatewayOptions,
        onMounted,
        onchange,
        getCountry,
        getState,
        getTown,
        getPostCode,
        stateDisable,
        suburbDisable,
        postalCodeDisale,
        editMerchantAccountDetails,
        slideOver,
        activeSubTab,
        getMerchantAccountPaymentMethodsByMchAccId,
        merchantAccountDetails,
        merchantAccountCredentialsDetails,
        merchantAccountPaymentMethods,
        merchantAccountGatewayPaymentMethods,
        getGateways,
        getGatewayPaymentMethods,
        setPaymentMethods,
        closeAlert,
        modal,
        merchantAccountGatewayPaymentDevices,
        getGatewayPaymentDeviceByMchAccPaymentDeviceId,
        paymentDevicesByPaymentDeviceId,
        getAllPaymentDevicePerGateway,
        paymentDeviceOptions,
        statusOptions,
        formDevice,
        getGatewayPaymentDevices,
        usersOptions,
        getMerchantUserDetailsByUserId,
        addNewUser,
        disable,
        getSecurityProfiles,
        securityProfiles,
        getStatus,
        mchAccountId,
        getCred,
        searchText,
        perPage,
        sortColumn,
        sort,
        sortColumnDirection,
        addDeviceOpen,
        openSlideOver,
        modalDevice,
        closeAlertDevice,
        sortPaymentDevice,
        confirmAlert,
        deletePaymentDeviceType,
        url,
        msg,
        activeOptions,
        disableValue,
        disableGatewayDetailsValue,
        disableAddressDetailsValue,
        resetTempAddressValue,
        viewTermsAndCondition,
        modalTerms,
        tcDescription,
        editorOptions,
        tempAddressValue,
        setPaymentMethodCheck,
        selectAllPaymentMethod,
        getMerchantUsers,
        getGatewayDetailsByGatewayId,
        lastPage,
        totatlRecord,
        currentPage,
        formApiCredentials,
        setToParentGatewayAccount,
        parentMchId,
        getMerchantAccountCredentialsDetailsByParentAccId,
        getGatewayIdAndNameOfParentMerchantAccount,
        setToExistingGatewayAccount,
        removePaymentDevices,
        getParentMerchantAccountDetails,
        disableInvoiceDirectoryValue,
        pm_id,
        paymentMethodValue,
        applyMerchantAccountGatewayPaymentDeviceDetails,
        getGatewayApiKeysDetails,
        requiredApiKeysValue,
        disableApplyButton,
        isInvoiceDirectlyAvailable,
        AddGwsSlideOver,
        gatewayDetails,
        checkGatewayDetailsCheckbox,
        checkboxDisableValue,
        editPaymentGateway,
        gatewayIdInEditMode,
        gatewayNameInEditMode,
        resetSlideOverDetails,
        setDetailsToSlideOver,
        getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId,
        paymentGatewayDetails,
        applyGatewayPaymentChanges,
        gatewayPaymentDevices,
        clickGateway,
        disableEditButton,
        closeAlertLinkToParentAccount,
        modalLinkToParentAccount,
        redEditButtonIsVisible,
        blueEditButtonIsVisible,
        closeWarning,
        modalWarning,
        errorMsgForGateway,
        decryptData,
        decryptDataForm,
        decryptionButtonShow,
        constantValue,
        decryptDeviceDataForm,
        decryptDeviceData,
        sortGateway,
        errorMchId,
        errorPrivateKey,
        errorPublicKey,
        errorPaymentMethod,
        getAllGatewaysWithoutCountrySelection,
        getAllSubAccountsLinkToGateway,
        getAllDevicesByMchAccIdAndGwId,
        pairingCodePopup,
        modalPairCode,
        proceedPairing,
        pairLoading,
        pairDetails,
        fromDevice,
        notComplete,
        unpairDevice,
        unpairWarningPopup,
        ConfirmPairDeviceModal,
        modalPairWarning,
        disableSelectAllGatewayPaymentMethod,
        deleteUncheckedGatewayDevices,
        errorApiKey1
      }
    },
  }
</script>
