<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Security Profiles") }}
        </h1>

        <Button
          v-if="merchantUser == null "
          id="button_add-security-profile"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="$router.push({ name: 'merchant-security-profile-add' })"
        >
          {{ t("Add Security Profile") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getSecurityProfiles()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getSecurityProfiles(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="security_profiles_merchant_portal_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Profile Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('profile_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'profile_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(profile, index) in securityProfiles"
                :id="'security_profiles_merchant_portal_' + index"
                :key="index"
              >
                <td class="max-w-screen-lg">
                  {{ profile.profile_name }}
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'security_profiles_merchant_portal_view_' + index"
                      type="info"
                      icon="eye"
                      @click="
                        openSecurityProfilesEdit(profile.sec_profile_id, 'view')
                      "
                    />
                    <button-circle
                      v-if="profile.is_admin_profile != 1 && merchantUser == null"
                      :id="'security_profiles_merchant_portal_edit_' + index"
                      type="info"
                      icon="edit"
                      @click="
                        openSecurityProfilesEdit(profile.sec_profile_id, 'edit')
                      "
                    />
                    <tippy
                      v-if="profile.is_admin_profile == 1 && merchantUser == null"
                      placement="left"
                    >
                      <button-circle
                        :id="'security_profiles_merchant_portal_edit_' + index"
                        type="info"
                        icon="edit"
                        disabled
                        @click="
                          openSecurityProfilesEdit(
                            profile.sec_profile_id,
                            'edit'
                          )
                        "
                      />
                      <template #content>
                        <span>{{ t("Admin profile cannot be edited") }}.</span>
                      </template>
                    </tippy>
                    <button-circle
                      v-if="!(profile.users || profile.is_admin_profile) && merchantUser == null"
                      :id="'security_profiles_merchant_portal_delete_' + index"
                      type="danger"
                      icon="trash"
                      @click="deleteValue(profile.sec_profile_id)"
                    />
                    <tippy
                      v-if="(profile.users || profile.is_admin_profile) && merchantUser == null"
                      placement="left"
                    >
                      <button-circle
                        :id="'security_profiles_merchant_portal_delete_' + index"
                        type="danger"
                        icon="trash"
                        disabled
                      />
                      <template #content>
                        <span
                          v-if="
                            profile.users && !profile.is_admin_profile
                          "
                        >{{ t("You won't be able to delete this profile, as it is attached to one or more users") }}.</span>
                        <span v-if="profile.is_admin_profile">
                          {{ t("Admin profile cannot be deleted") }}.</span>
                      </template>
                    </tippy>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!securityProfiles.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getSecurityProfiles($event)"
      />
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import router from '@/routes'
import { Tippy } from 'vue-tippy'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'

export default {
  name: 'SecurityProfiles',

  components: {
    Tippy,
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const showAddNewRow = ref(false)
    const sortColumnDirection = ref('desc')
    const perPage = ref(10)
    const isLoading = ref(false)
    const securityProfiles = ref([])
    const searchText = ref('')
    const searchParam = ref('')
    const alertMsg = ref('')
    const idToDelete = ref('')
    const currentPage = ref('')
    const sortColumn = ref('')
    const totalRecord = ref(0)
    const merchantUser = ref('')
    const lastPage = ref(0)
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getSecurityProfiles(1, sortColumn.value, searchText.value)
    }

    async function getSecurityProfiles(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      if (localStorage.getItem('merchant') != ''){
        merchantUser.value = localStorage.getItem('merchant')
      }
      await axios
        .get('v1/merchant-security-profiles', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            securityProfiles.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function openSecurityProfilesEdit(id, environment) {
      if (environment == 'add') {
        router.push({ name: 'merchant-security-profile-add' })
      }
      if (environment == 'edit') {
        router.push({
          name: 'merchant-security-profile-edit',
          params: { slug: id },
        })
      }
      if (environment == 'view') {
        router.push({
          name: 'merchant-security-profile-view',
          params: { slug: id },
        })
      }
    }

    function deleteValue(id) {
      idToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function confirmAlert() {
      deleteSecurityProfile()
      modal.show = false
      return true
    }

    function deleteSecurityProfile() {
      let data = {}
      data.sec_profile_id = idToDelete.value
      axios
        .delete('/v1/delete-security-profile/' + idToDelete.value,{ params:data})
        .then((response) => {
          if (response.data != null  && response.data.status == true) {
            getSecurityProfiles(currentPage.value)
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function closeAlert() {
      modal.show = false
    }

    return {
      t,
      sort,
      modal,
      perPage,
      fullPage,
      lastPage,
      isLoading,
      sortColumn,
      searchText,
      closeAlert,
      totalRecord,
      searchParam,
      deleteValue,
      confirmAlert,
      showAddNewRow,
      securityProfiles,
      getSecurityProfiles,
      sortColumnDirection,
      openSecurityProfilesEdit,
      merchantUser
    }
  },
}
</script>

