<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Plans") }}
        </h1>

        <div class="flex items-center justify-between space-x-3">
          <Button
            id="button_copy-plans"
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            :disabled="form.copyPlan.length == 0"
            @click="onSubmitCopyPlan()"
          >
            {{ t("Copy Plan") }}
          </Button>

          <Button
            id="button_add-plan"
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            @click="
              ;(slideOver.show = true),
                resetForm(),
                isClickedType(),
                resetDataArrays(),
                (showTransactionChargesAddRow = false),
                (showAssignedAccountRow = false),
                (environment = 'add'),
                (isPlanHasMerchants = false),
                (assignedAccountsSortColumnDirection = 'desc'),
                (selectedRow = null),
                (assignedAccountsSortColumn = ''),
                resetChangedAssignAccounts()
            "
          >
            {{ t("Add Plan") }}
          </Button>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page_1"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getPlans()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getPlans(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="plans_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  class="th-action"
                >
                  <form-checkbox
                    id="allIndex"
                    v-model="form.isAllPlanActive"
                    name="states"
                    :check-value="true"
                    :model-value="form.isAllPlanActive == 1 ? 0 : 1"
                    :checked="form.isAllPlanActive == 1 ? true : false"
                    @click="selectAllPlanCheck()"
                  />
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Type") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('type')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'type'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Merchant Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'country'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Invoicing By") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('invoice_by')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'invoice_by'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(plan, index) in plans"
                :id="'plans_' + index"
                :key="index"
              >
                <td class="tb-action text-center">
                  <form-checkbox
                    :id="plan.plan_id"
                    v-model="form.copyPlanId"
                    name="states"
                    :model-value="plan.plan_id"
                    :checked="setPlanCheck(plan.plan_id)"
                    @click="setFromPlan(plan.plan_id)"
                  />
                </td>
                <td style="max-width: 300px">
                  {{ plan.name }}
                </td>
                <td v-if="plan.type == 0">
                  {{ t("Standard") }}
                </td>
                <td v-if="plan.type == 1">
                  {{ t("Custom") }}
                </td>
                <td>{{ plan.country.country_name }}</td>
                <td v-if="!plan.plan_tiers > 0" />
                <td v-if="plan.plan_tiers > 0">
                  {{ plan.invoice_by }}
                </td>

                <td class="text-center">
                  <badge
                    v-if="plan.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="plan.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'plans_view_' + index"
                      type="info"
                      icon="eye"
                      @click="openPlanSummary(plan.plan_id, 'view')"
                    />
                    <button-circle
                      :id="'plans_edit_' + index"
                      type="info"
                      icon="edit"
                      @click="
                        openPlanSummary(plan.plan_id, 'edit'),
                        (activeTabId = 'tab1'),
                        isClickedType(),
                        resetErrors(),
                        resetChangedAssignAccounts()
                      "
                    />
                    <button-circle
                      v-if="!plan.merchants > 0"
                      :id="'plans_delete_' + index"
                      type="danger"
                      icon="trash"
                      @click="deleteValue(plan.plan_id)"
                    />
                    <tippy
                      v-if="plan.merchants > 0"
                      placement="left"
                    >
                      <button-circle
                        :id="'plans_delete_' + index"
                        type="danger"
                        icon="trash"
                        disabled
                      />
                      <template
                        #content
                      >
                        {{ t("You won't be able to delete this Plan as it is attached to one or more merchants") }}.
                      </template>
                    </tippy>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!plans.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getPlans($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="environment == 'edit' ? 'Edit Plan' : 'Add Plan'"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-4 md:flex xl:-mx-4">
        <div class="w-full px-4 md:w-2/3 grid sm:grid-cols-2 gap-4">
          <div>
            <form-label for="name">
              {{ t("Name") }} <required />
            </form-label>
            <form-input
              id="name"
              v-model="form.name"
              class="mt-1"
              name="name"
              type="text"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.name
                  ? form.errors.validations.name[0]
                  : ''
              "
            />
          </div>

          <div>
            <form-label for="country">
              {{ t("Merchant Country") }} <required />
            </form-label>
            <form-select
              id="country"
              v-model="form.country"
              :change="onchange"
              class="mt-1"
              name="country"
              :items="countryOptions"
              @onChangeFunction="getCurrencyByCountyId(), setMerchantOptions()"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.country && !form.errors.validations.name
                  ? form.errors.validations.country[0]
                  : ''
              "
            />
          </div>

          <div>
            <form-label for="currency">
              {{ t("Currency") }} <required />
            </form-label>
            <form-select
              id="currency"
              v-model="form.currency"
              class="mt-1"
              name="currency"
              :items="currencyOptions"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.currency &&
                  !form.errors.validations.name &&
                  !form.errors.validations.country
                  ? form.errors.validations.currency[0]
                  : ''
              "
            />
          </div>

          <div>
            <form-label for="status">
              {{ t("Status") }}
            </form-label>
            <form-select
              id="status"
              v-model="form.status"
              class="mt-1"
              name="status"
              :items="statusOptions"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.status &&
                  !form.errors.validations.name &&
                  !form.errors.validations.country &&
                  !form.errors.validations.currency
                  ? form.errors.validations.status[0]
                  : ''
              "
            />
          </div>
        </div>

        <div class="w-full px-4 md:w-1/3 mt-4 md:mt-0">
          <form-label for="status">
            {{ t("Type") }}
          </form-label>
          <div class="mt-2 flex items-center">
            <form-radio
              id="type_standard"
              v-model="form.type"
              name="type"
              :model-value="0"
              :checked="form.type == 0"
              @click=";(form.type = 0), isClickedType(), showWarningModal()"
            />
            <label
              class="ml-2 text-sm"
              for="type_standard"
            >{{ t("Standard") }}</label>
          </div>
          <div class="mt-1 flex items-center">
            <form-radio
              id="type_custom"
              v-model="form.type"
              name="type"
              :model-value="1"
              :checked="form.type == 1"
              @click=";(form.type = 1), isClickedType(), showWarningModal()"
            />
            <label
              class="ml-2 text-sm"
              for="type_custom"
            >{{ t("Custom") }}</label>
          </div>
        </div>
      </div>

      <divider />

      <div class="tab-group">
        <div class="tabs-wrapper">
          <nav
            class="tabs"
            aria-label="Tabs"
          >
            <a
              v-for="(tabItem, index) in tabItems"
              :key="index"
              href="#"
              class="tab-item"
              :class="{ active: activeTabId == tabItem.id }"
              @click="showTabContent('activeTabId', tabItem.id)"
            >{{ t(tabItem.name) }}</a>
          </nav>
        </div>

        <div class="tab-container">
          <div v-if="activeTabId == 'tab1'">
            <div
              v-if="false"
              class="mb-6 font-medium text-black text-opacity-75"
            >
              {{ t("opuspay Transaction Charges") }}
            </div>

            <div class="mb-4 grid grid-cols-2 gap-y-3">
              <div class="flex items-center">
                <span
                  v-if="false"
                  class="text-sm"
                >{{ t("Show") }}</span>
                <div
                  v-if="false"
                  class="mx-2"
                >
                  <form-select
                    id="results_per_page_2"
                    :items="[10, 25, 50, 100]"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span
                  v-if="false"
                  class="text-sm"
                >{{ t("entries") }}</span>
              </div>

              <div class="w-full flex items-center justify-end space-x-2">
                <div
                  v-if="false"
                  class="w-full lg:w-3/6"
                >
                  <search />
                </div>
                <Button
                  type="button"
                  class="whitespace-nowrap"
                  :disabled="selectedRow != null || showTransactionChargesAddRow"
                  @click="
                    ;(showTransactionChargesAddRow = true),
                      (form.errors = ''),
                      (selectedRow = null),
                      resetTier()
                  "
                >
                  {{ t("Add Row") }}
                </Button>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <div class="overflow-y-auto-custom">
                  <table
                    class="table"
                    aria-describedby=""
                  >
                    <thead>
                      <tr>
                        <th
                          class="th-action"
                          scope="col"
                        />
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Quantity From") }}<required /></span>
                            <button
                              v-if="false"
                              class="btn_th-sort"
                              type="button"
                            >
                              <svg-icon
                                v-if="sortColumnDirection"
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Quantity To") }}<required /></span>
                            <button
                              v-if="false"
                              class="btn_th-sort"
                              type="button"
                            >
                              <svg-icon
                                v-if="sortColumnDirection"
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th
                          scope="col"
                          style="min-width: 160px"
                        >
                          <div class="flex items-center">
                            <span>{{ t("Invoice By") }}</span>
                            <button
                              v-if="false"
                              class="btn_th-sort"
                              type="button"
                            >
                              <svg-icon
                                v-if="sortColumnDirection"
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Rate (%)") }}<required /></span>
                            <button
                              v-if="false"
                              class="btn_th-sort"
                              type="button"
                            >
                              <svg-icon
                                v-if="sortColumnDirection"
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th
                          scope="col"
                          class="th-action"
                        >
                          {{ t("Actions") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(transactionCharge, index) in form.tiers"
                        :key="index"

                        :class="(selectedRow != null && selectedRow == index) ? 'td-align-top' : ''"
                      >
                        <td class="text-center">
                          {{ index + 1 }}
                        </td>
                        <td>
                          <span v-if="index !== selectedRow">{{
                            transactionCharge.quantity_from
                          }}</span>
                          <div v-if="index == selectedRow">
                            <form-input
                              v-model="form.tier.quantity_from"
                              class="py-1.5"
                              name="quantity_form"
                              type="number"
                              min="0"
                              required
                              onkeydown="javascript: return event.keyCode == 69 ? false : true"
                              oninput="javascript:this.value = this.value.replace(/[^0-9]/g, '');"
                              @keypress="noDecimalNumbers"
                            />
                            <form-error
                              v-if="form.errors"
                              :message="
                                form.errors.validations.quantity_from
                                  ? form.errors.validations.quantity_from[0]
                                  : ''
                              "
                            />
                          </div>
                        </td>
                        <td>
                          <span v-if="index !== selectedRow">{{
                            transactionCharge.quantity_to
                          }}</span>
                          <div v-if="index == selectedRow">
                            <form-input
                              v-model="form.tier.quantity_to"
                              class="py-1.5"
                              name="quantity_to"
                              type="number"
                              min="0"
                              required
                              onkeydown="javascript: return event.keyCode == 69 ? false : true"
                              oninput="javascript:this.value = this.value.replace(/[^0-9]/g, '');"
                              @keypress="noDecimalNumbers"
                            />
                            <form-error
                              v-if="form.errors"
                              :message="
                                form.errors.validations.quantity_to &&
                                  !form.errors.validations.quantity_from
                                  ? form.errors.validations.quantity_to[0]
                                  : ''
                              "
                            />
                          </div>
                        </td>
                        <td>
                          <span v-if="index !== selectedRow">{{
                            capitalizeFirstLetter(transactionCharge.invoice_by)
                          }}</span>
                          <div v-if="index == selectedRow">
                            <form-select
                              v-model="form.tier.invoice_by"
                              class="py-1.5"
                              name="invoice_by"
                              required
                              :items="invoiceByOptions"
                            />
                            <form-error
                              v-if="form.errors"
                              :message="
                                form.errors.validations.invoice_by
                                  ? form.errors.validations.invoice_by[0]
                                  : ''
                              "
                            />
                          </div>
                        </td>
                        <td>
                          <span v-if="index !== selectedRow">{{
                            parseFloat(transactionCharge.tier_rate).toFixed(2)
                          }}</span>
                          <div v-if="index == selectedRow">
                            <form-input
                              v-model="form.tier.tier_rate"
                              class="py-1.5"
                              name="tier_rate"
                              type="number"
                              min="0"
                              required
                              step="0.01"
                              onkeydown="javascript: return event.keyCode == 69 ? false : true"
                              @keypress="onlyTwoDecimalNumbers"
                            />
                            <form-error
                              v-if="form.errors"
                              :message="
                                form.errors.validations.tier_rate &&
                                  !form.errors.validations.quantity_from &&
                                  !form.errors.validations.quantity_to &&
                                  !form.errors.validations.invoice_by
                                  ? form.errors.validations.tier_rate[0]
                                  : ''
                              "
                            />
                          </div>
                        </td>
                        <td>
                          <div
                            :class="(selectedRow != null && selectedRow == index) ? 'td-cell' : ''"
                          >
                            <div class="flex items-center justify-center space-x-5">
                              <button-circle
                                v-if="selectedRow != index"
                                type="info"
                                icon="edit"
                                :disabled="
                                  showTransactionChargesAddRow ||
                                    (selectedRow != null && selectedRow != index)
                                "
                                @click="selectRow(index)"
                              />
                              <button-circle
                                v-if="isEditMode && selectedRow == index"
                                type="success"
                                icon="check"
                                :disabled="showTransactionChargesAddRow"
                                @click="checkQuantityFromValidation(index)"
                              />
                              <button-circle
                                v-if="selectedRow != index"
                                type="danger"
                                icon="trash"
                                :disabled="
                                  showTransactionChargesAddRow ||
                                    (selectedRow != null && selectedRow != index)
                                "
                                @click="deleteTier(index)"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr
                        v-if="showTransactionChargesAddRow"
                        class="td-align-top"
                      >
                        <td class="text-center" />
                        <td>
                          <form-input
                            v-model="form.tier.quantity_from"
                            class="py-1.5"
                            name="quantity_form"
                            type="number"
                            required
                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                            oninput="javascript:this.value = this.value.replace(/[^0-9]/g, '');"
                            min="0"
                            @keypress="noDecimalNumbers"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.quantity_from
                                ? form.errors.validations.quantity_from[0]
                                : ''
                            "
                          />
                        </td>
                        <td>
                          <form-input
                            v-model="form.tier.quantity_to"
                            class="py-1.5"
                            name="quantity_to"
                            type="number"
                            required
                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                            oninput="javascript:this.value = this.value.replace(/[^0-9]/g, '');"
                            min="0"
                            @keypress="noDecimalNumbers"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.quantity_to &&
                                !form.errors.validations.quantity_from
                                ? form.errors.validations.quantity_to[0]
                                : ''
                            "
                          />
                        </td>
                        <td>
                          <form-select
                            v-model="form.tier.invoice_by"
                            class="py-1.5"
                            name="invoice_by"
                            required
                            :items="invoiceByOptions"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.invoice_by &&
                                !form.errors.validations.quantity_from &&
                                !form.errors.validations.quantity_to
                                ? form.errors.validations.invoice_by[0]
                                : ''
                            "
                          />
                        </td>
                        <td>
                          <form-input
                            v-model="form.tier.tier_rate"
                            class="py-1.5"
                            name="tier_rate"
                            type="number"
                            min="0"
                            required
                            step="0.01"
                            onkeydown="javascript: return event.keyCode == 69 ? false : true"
                            @keypress="onlyTwoDecimalNumbers"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.tier_rate &&
                                !form.errors.validations.quantity_from &&
                                !form.errors.validations.quantity_to &&
                                !form.errors.validations.invoice_by
                                ? form.errors.validations.tier_rate[0]
                                : ''
                            "
                          />
                        </td>
                        <td>
                          <div class="td-cell">
                            <div class="flex items-center justify-center space-x-5">
                              <button-circle
                                v-if="true"
                                type="success"
                                icon="check"
                                @click="checkQuantityFromValidation()"
                              />
                              <button-circle
                                v-if="true"
                                type="danger"
                                icon="trash"
                                @click="showTransactionChargesAddRow = false"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>

                      <no-table-data v-if="!form.tiers.length" />
                    </tbody>
                  </table>
                </div>
              </template>
            </TableDefault>
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.tiers &&
                  !form.errors.validations.status &&
                  !form.errors.validations.name &&
                  !form.errors.validations.country &&
                  !form.errors.validations.currency
                  ? form.errors.validations.tiers[0]
                  : ''
              "
            />

            <vue-pagination
              v-if="false"
              :page-size="0"
              :total-records="0"
            />
          </div>

          <div v-if="activeTabId == 'tab2' && form.type == '1'">
            <div class="mb-4 grid grid-cols-2 gap-y-3">
              <div class="flex items-center">
                <span
                  v-if="false"
                  class="text-sm"
                >{{ t("Show") }}</span>
                <div
                  v-if="false"
                  class="mx-2"
                >
                  <form-select
                    id="results_per_page"
                    :items="[10, 25, 50, 100]"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span
                  v-if="false"
                  class="text-sm"
                >{{ t("entries") }}</span>
              </div>

              <div class="w-full flex items-center justify-end space-x-2">
                <div
                  v-if="false"
                  class="w-full lg:w-3/6"
                >
                  <search />
                </div>
                <Button
                  type="button"
                  class="whitespace-nowrap"
                  :disabled="form.status == 'INA' || showAssignedAccountRow"
                  @click="
                    ;(showAssignedAccountRow = true),
                      (form.errors = ''),
                      resetAssignedAccounts(),
                      setMerchantOptionsWithoutPlan()
                  "
                >
                  {{ t("Add Row") }}
                </Button>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <table
                  class="table"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Merchant") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('organisation_name')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn ==
                                  'organisation_name'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Address 1") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                          >
                            <svg-icon
                              v-if="sortColumnDirection"
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Suburb/Town") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('suburb')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'suburb'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("State") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('state')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'state'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Country") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('country')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'country'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        class="th-action"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(assignedAccount, index) in form.assignedAccounts"
                      :key="index"
                    >
                      <td class="max-w-xs">
                        {{ assignedAccount.merchant_name }}
                      </td>
                      <td class="max-w-xs">
                        {{ assignedAccount.address1 }}
                      </td>
                      <td>
                        {{ assignedAccount.town }}
                      </td>
                      <td>
                        {{ assignedAccount.state }}
                      </td>
                      <td>
                        {{ assignedAccount.country }}
                      </td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            v-if="assignedAccount.isNew"
                            type="danger"
                            icon="trash"
                            @click="form.assignedAccounts.splice(index, 1)"
                          />
                          <button-circle
                            v-if="!assignedAccount.isNew"
                            type="danger"
                            icon="trash"
                            @click="
                              setAssignPlanData(assignedAccount.merchant),
                              resetErrors()
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <tr v-if="showAssignedAccountRow">
                      <td>
                        <div>
                          <form-select
                            v-model="form.assignedAccount.merchant"
                            class="py-1.5"
                            name=""
                            required
                            :change="onchange"
                            :items="merchantOptionsWithoutPlan"
                            @onChangeFunction="setMerchantsDetails()"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.merchant_id
                                ? form.errors.validations.merchant_id[0]
                                : ''
                            "
                          />
                          <form-error
                            v-if="form.errorInMerchant"
                            :message="
                              form.errorInMerchant
                                ? form.errorInMerchant
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>{{ form.assignedAccount.address1 }}</td>
                      <td>{{ form.assignedAccount.town }}</td>
                      <td>{{ form.assignedAccount.state }}</td>
                      <td>{{ form.assignedAccount.country }}</td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            type="success"
                            icon="check"
                            @click="addAssignedAccountArrayData()"
                          />
                          <button-circle
                            type="danger"
                            icon="trash"
                            @click="showAssignedAccountRow = false"
                          />
                        </div>
                      </td>
                    </tr>
                    <no-table-data v-if="!form.assignedAccounts.length" />
                  </tbody>
                </table>
              </template>
            </TableDefault>

            <vue-pagination
              v-if="false"
              :page-size="0"
              :total-records="0"
            />
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        :disabled="selectedRow != null || showAssignedAccountRow || showTransactionChargesAddRow"
        @click="onSubmit()"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  >
    <template
      v-if="isWarning"
      #modal-body
    >
      <p class="mb-1">
        {{ t("Changing the Plan Type will delete all Tier-Level rows") }}.
      </p>
      <p class="mb-1">
        {{ t("Would you like to continue") }}?
      </p>
      <p>{{ t("Click Cancel to revert back to the previous Plan Type") }}.</p>
    </template>

    <template
      v-if="isWarning"
      #modal-actions
    >
      <ButtonSecondary
        class="btn-wide btn-danger"
        type="button"
        @click="closeAlert()"
      >
        {{ t("Cancel") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
        @click="confirmWarning()"
      >
        {{ t("Continue") }}
      </Button>
    </template>
  </modal>

  <modal-form
    :show="modalAssignPlan.show"
    :close-button="modalAssignPlan.close_button"
    :confirm-button="modalAssignPlan.confirm_button"
    @closeModal="closeAssignPlanAlert"
  >
    <template #modal-body>
      <p class="mb-3 text-sm">
        {{ t("The following Merchant has been removed from a Custom Plan and must be assigned to a new Plan") }}.
      </p>

      <div class="space-y-4">
        <div>
          <form-label
            for="merchant-name"
          >
            {{ t("Merchant Name") }} <required />
          </form-label>
          <form-input
            id="merchant-name"
            v-model="assignPlanForm.merchant_name"
            class="mt-1"
            name="name"
            required
            :disable="true"
          />
        </div>

        <div>
          <form-label
            for="assign-plan"
          >
            {{ t("Assign a Plan") }} <required />
          </form-label>
          <form-select
            id="assign-plan"
            v-model="assignPlanForm.plan"
            class="mt-1"
            name="assign-plan"
            required
            :items="planOptions"
          />
          <form-error
            v-if="errorMessageForAssignPlan != null"
            :message="
              errorMessageForAssignPlan
            "
          />
        </div>
      </div>
    </template>

    <template #modal-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="applyAssignPlan(assignPlanForm.merchant, assignPlanForm.plan)"
      >
        {{ t("Apply") }}
      </Button>
    </template>
  </modal-form>
</template>

<script>
import { ref, reactive, watch, watchEffect, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Tippy } from 'vue-tippy'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormRadio from '@/components/FormRadio.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import ButtonSecondary from '@/components/ButtonSecondary'
import axios from 'axios'
import router from '@/routes'
import { useRoute } from 'vue-router'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'Plans',

  components: {
    Tippy,
    Badge,
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    Divider,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormRadio,
    FormSelect,
    PageHeader,
    NoTableData,
    FormCheckbox,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ModalForm,
    ButtonSecondary,
  },

  setup() {
    const { t } = useI18n()
    const environment = ref('')
    const fullPage = ref(true)
    const onchange = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const countryOptions = ref([
      { value: '', text: 'Please select a merchant country' },
    ])
    const currencyOptions = ref([
      { value: '', text: 'Please select a currency' },
    ])
    const merchantOptions = ref([
      { value: '', text: 'Please select a merchant' },
    ])
    const planOptions = ref([{ value: '', text: 'Select a Plan' }])
    const merchants = ref([])
    const merchantsByPlan = ref([])
    const merchantOptionsWithoutPlan = ref([])
    const selectedRow = ref(null)
    const isEditMode = ref(false)
    const isPlanHasMerchants = ref(false)
    const showAssignedAccountRow = ref(false)
    const showTransactionChargesAddRow = ref(false)
    const idToDelete = ref('')
    const idToDeleteTier = ref(null)
    const searchText = ref('')
    const perPage = ref('10')
    const totalRecord = ref(0)
    const lastPage = ref(0)
    const currentPage = ref('')
    const plans = ref([])
    const planList = ref([])
    const isWarning = ref(false)
    const previousType = ref(null)
    const route = useRoute()
    const tiersLastPage = ref(0)
    const tiersPerPage = ref('10')
    const tiersSortColumnDirection = ref('desc')
    const tiersSortColumn = ref('')
    const tiersSearchText = ref('')
    const tiersTotalRecord = ref(0)
    const assignedAccountsLastPage = ref(0)
    const assignedAccountsPerPage = ref('10')
    const assignedAccountsSortColumnDirection = ref('desc')
    const assignedAccountsSortColumn = ref('')
    const assignedAccountsSearchText = ref('')
    const assignedAccountsTotalRecord = ref(0)
    const initialLoad = ref(false)
    const errorMessageForAssignPlan = ref(null)

    const form = reactive({
      id: null,
      name: null,
      country: null,
      currency: null,
      status: 'ACT',
      type: 0,
      isAllPlanActive: 0,
      copyPlanId: null,
      copyPlan: [],
      tier: {
        plan_tier_id: null,
        quantity_from: null,
        quantity_to: null,
        invoice_by: 'transaction',
        tier_rate: null,
      },
      assignedAccount: {
        merchant: null,
        merchant_name: null,
        address1: null,
        town: null,
        state: null,
        country: null,
      },
      tiers: [],
      assignedAccounts: [],
      errors: '',
      errorInMerchant: '',
    })
    const planTempData = reactive({
      id: null,
      name: null,
      country: null,
      currency: null,
      status: null,
      type: null,
    })
    const slideOver = reactive({
      show: false,
      size: 'max-w-5xl',
      close_button: true,
      title: '',
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: true,
      button_text: 'No',
      confirm_button_text: 'Yes',
    })

    const modalAssignPlan = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: true,
      button_text: 'No',
      confirm_button_text: 'Yes',
    })

    const tabItems = reactive([
      { id: 'tab1', name: 'Transaction Charges' },
      // { id: 'tab2', name: 'Assigned Accounts' },
    ])
    const activeTabId = ref(tabItems[0].id)
    const invoiceByOptions = reactive([
      { value: 'month', text: 'Month' },
      { value: 'transaction', text: 'Transaction' },
    ])
    const statusOptions = reactive([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])

    const assignPlanForm = reactive({
      merchant: null,
      merchant_name: null,
      plan: null,
    })
    const changedAssignAccounts = ref([]);

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    onMounted(async () => {
      let planId = sessionStorage.getItem('plan_id')
      if (planId != null) {
        sessionStorage.removeItem('plan_id')
        await getPlanById(planId)
        slideOver.show = true
        isClickedType()
        showTransactionChargesAddRow.value = false
        showAssignedAccountRow.value = false
        environment.value = 'edit'
      }
    })

    watch(() => {
      form.tier.tier_rate = checkTwoDecimalPlacesInValueCopyPaste(
        form.tier.tier_rate
      )
      form.tier.quantity_from = checkNoDecimalPlacesInValueCopyPaste(
        form.tier.quantity_from
      )
      form.tier.quantity_to = checkNoDecimalPlacesInValueCopyPaste(
        form.tier.quantity_to
      )
    })
    function checkTwoDecimalPlacesInValueCopyPaste(value) {
      if (value != null) {
        if (value != null && value != '') {
          let input = value.toString()
          let output
          if (input.indexOf('.') > -1 && input.split('.')[1].length > 2) {
            output = input.substring(input.indexOf('.') + 3, 0)
          } else {
            output = input
          }
          return output
        }
      }
    }

    function checkNoDecimalPlacesInValueCopyPaste(value) {
      let output = null
      if ((value != null && value != '' ) || value == 0) {
        let input = value.toString()
        if (input.includes('.')) {
          output = input.substring(0, input.indexOf('.'))
        } else {
          output = input
        }
        return +output
      }
    }

    function noDecimalNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault()
      }
    }

    function onlyTwoDecimalNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 ||
          (form.tier.tier_rate != null &&
            form.tier.tier_rate.indexOf('.') != -1))
      ) {
        // 46 is dot
        $event.preventDefault()
      }

      // restrict to 2 decimal places
      if (
        form.tier.tier_rate != null &&
        form.tier.tier_rate.indexOf('.') > -1 &&
        form.tier.tier_rate.split('.')[1].length > 1
      ) {
        $event.preventDefault()
      }

      if (
        form.tier.tier_rate != null &&
        form.tier.tier_rate.indexOf('.') > -1 &&
        form.tier.tier_rate.indexOf('.') == 0
      ) {
        form.tier.tier_rate = '0' + form.tier.tier_rate
        $event.preventDefault()
      }
    }

    function selectRow(index) {
      selectedRow.value = index
      isEditMode.value = true
      form.tier = form.tiers[index]
      form.errors = ''
    }
    getCountry()
    getMerchants()

    function isClickedType() {
      if (form.type == 1 && tabItems.length < 2) {
        tabItems.push({ id: 'tab2', name: 'Assigned Accounts' })
      } else if (form.type == 0 && tabItems.length == 2) {
        tabItems.splice(1, 1)
        activeTabId.value = 'tab1'
      }
    }

    function openPlanSummary(id, env) {
      if (env == 'edit') {
        initialLoad.value = false
        environment.value = 'edit'
        getPlanById(id)
        slideOver.show = true
        isClickedType()
        showTransactionChargesAddRow.value = false
        showAssignedAccountRow.value = false
        selectedRow.value = null
        isEditMode.value = false
      }
      if (env == 'view') {
        router.push({ name: 'plan-summary-edit', params: { slug: id } })
      }
    }

    function checkQuantityFromValidation(index = null) {
      form.errors = ''
      if (parseInt(form.tiers.length) > 0 && parseInt(form.tier.quantity_from) != null) {
        if (index != null || index == 0) {
          if (index > 0) {
            if (
              parseInt(form.tiers[index - 1]['quantity_to']) >= parseInt(form.tier.quantity_from)
            ) {
              form.errors = {
                validations: {
                  quantity_from: [
                    'Please enter a value greater than the value entered in the "quantity to" field of the previous row.',
                  ],
                },
              }
            }else {
              if (index != parseInt(form.tiers.length - 1)) {
                if (parseInt(form.tier.quantity_to) >= parseInt(form.tiers[index + 1]['quantity_from'])) {
                  form.errors = {
                    validations: {
                      quantity_to: [
                        'Please enter a value less than the value entered in the "quantity from" field of the next row.',
                      ],
                    },
                  }
                }else {
                  addTierArrayData(index)
                }
              } else {
                addTierArrayData(index)
              }
            }
          } else {
            if(index != parseInt(form.tiers.length - 1)){
              if(parseInt(form.tier.quantity_to) >= parseInt(form.tiers[index + 1]['quantity_from'])){
                form.errors = {
                  validations: {
                    quantity_to: [
                      'Please enter a value less than the value entered in the "quantity from" field of the next row.',
                    ],
                  },
                }
              }
              else {
                addTierArrayData(index)
              }
            }
            else{
              addTierArrayData(index)
            }

          }
        } else {
          if (
            parseInt(form.tiers[form.tiers.length - 1]['quantity_to']) >=
            parseInt(form.tier.quantity_from)
          ) {
            form.errors = {
              validations: {
                quantity_from: [
                  'Please enter a value greater than the value entered in the "quantity to" field of the previous row.',
                ],
              },
            }
          } else {
            addTierArrayData(index)
          }
        }
      } else {
        addTierArrayData(index)
      }
    }

    async function addTierArrayData(index) {
      await axios.post('v1/check-tier', form.tier).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          let obj = {}
          obj.plan_id = null
          obj.plan_tier_id = form.tier.plan_tier_id
          obj.quantity_from = form.tier.quantity_from
          obj.quantity_to = form.tier.quantity_to
          obj.invoice_by = form.tier.invoice_by
          obj.tier_rate = parseFloat(form.tier.tier_rate).toFixed(2)
          if (index == null) {
            form.tiers.push(obj)
          } else {
            form.tiers[index] = obj
            isEditMode.value = false
            selectedRow.value = null
          }
          showTransactionChargesAddRow.value = false
          resetTier()
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    async function addAssignedAccountArrayData() {
      let index = 0;
      let SubmitArr = {}
      await  form.assignedAccounts.filter(function (elm) {
        if (elm.merchant== form.assignedAccount.merchant) {
          form.errorInMerchant = "Merchant is already assigned."
        }
        index++
      })
      SubmitArr.validate_assign_merchant = true
      SubmitArr.merchant_id = form.assignedAccount.merchant
      if (form.errorInMerchant == null || form.errorInMerchant == ''){
        await axios
          .post('v1/check-assigned-account', SubmitArr)
          .then((response) => {
            if (response.data != null && !response.data.data.errors) {
              let obj = {}
              obj.merchant_name = form.assignedAccount.merchant_name
              obj.merchant = form.assignedAccount.merchant
              obj.address1 = form.assignedAccount.address1
              obj.town = form.assignedAccount.town
              obj.state = form.assignedAccount.state
              obj.country = form.assignedAccount.country
              obj.isNew = true
              form.assignedAccounts.push(obj)
              resetAssignedAccounts()
              showAssignedAccountRow.value = false
            } else {
              form.errors = response.data.data.errors
            }
          })
      }

    }

    async function getCountry() {
      countryOptions.value = [
        { value: '', text: 'Please select a merchant country' },
      ]
      isLoading.value = true
      await axios.get('v1/get-all-countries').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((country) => {
            let obj = {}
            obj.text = country.country_name
            obj.value = country.country_code
            countryOptions.value.push(obj)
          })
        }
      })
      isLoading.value = false
    }

    async function getCurrencyByCountyId() {
      currencyOptions.value = null
      form.currency = ''
      form.errors ={ validations: {currency: []}}
      // isLoading.value = true
      if (form.country != '') {
        await axios
          .get(
            'v1/country_and_currency/get_tnt_currencies_by_country/' +
              form.country
          )
          .then((response) => {
            if(response.data.data && response.data.data.length <= 0){
              form.errors ={
                validations: {
                  currency: ["A currency is not configured for the selected country. Please contact opuspay."]
                }
              }
            }
            if (response.data.data != null && !response.data.data.errors) {
              currencyOptions.value = [
                { value: '', text: 'Please select a currency' },
              ]
              if (response.data.data.length > 0) {
                if (response.data.data.length == 1) {
                  currencyOptions.value = [
                    {
                      value: response.data.data[0].currency_code,
                      text: response.data.data[0].currency_name,
                    },
                  ]
                  form.currency = response.data.data[0].currency_code
                } else {
                  response.data.data.find((value) => {
                    let obj = {}
                    obj.text = value.currency_name
                    obj.value = value.currency_code
                    currencyOptions.value.push(obj)
                  })
                  if (initialLoad.value == false){
                    setTimeout(() => (form.currency = planTempData.currency), 500)
                    initialLoad.value = true
                  }
                }
              } else {
                currencyOptions.value = [
                  { value: '', text: 'Please select a currency' },
                ]
                form.currency = ''
              }
            }
          })
      }
      // isLoading.value = false
    }

    async function getMerchants() {
      isLoading.value = true
      await axios.get('v1/get-active-merchants').then((response) => {
        if (response.data.data != null) {
          merchants.value = response.data.data
        }
      })
      isLoading.value = false
    }

    function setMerchantOptions() {
      merchantOptions.value = [{ value: '', text: 'Please select a merchant' }]
      merchants.value.find((merchant) => {
        if (merchant.country_code == form.country) {
          let obj = {}
          obj.text = merchant.organisation_name
          obj.value = merchant.mch_id
          merchantOptions.value.push(obj)
        }
      })
    }

    function setMerchantOptionsWithoutPlan() {
      merchantOptionsWithoutPlan.value = [
        { value: '', text: 'Please select a merchant' },
      ]
      merchants.value.find((merchant) => {
        if (
          merchant.country_code == form.country &&
          merchant.plan_id != form.id
        ) {
          let obj = {}
          obj.text = merchant.organisation_name
          obj.value = merchant.mch_id
          merchantOptionsWithoutPlan.value.push(obj)
        }
      })
    }

    async function setMerchantsDetails() {
      isLoading.value = true
      merchants.value.forEach((merchant) => {
        if (merchant.mch_id == form.assignedAccount.merchant) {
          form.assignedAccount.merchant_name = merchant.organisation_name
          form.assignedAccount.address1 = merchant.address1
          form.assignedAccount.town = merchant.suburb_name
          form.assignedAccount.state = merchant.state_name
          form.assignedAccount.country = merchant.country_name
        }
      })
      isLoading.value = false
    }

    async function getPlans(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      planList.value = []
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('v1/plans?', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            plans.value = response.data.data.data
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
            response.data.data.data.find((plan) => {
              planList.value.push(plan.plan_id)
            })
            resetCopyPlan()
          } else {
            console.error('Error!')
          }
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getPlans(1, sortColumn.value, searchText.value)
    }

    async function getPlanById(plan_id) {
      // isLoading.value = true
      if (plan_id != null) {
        await axios.get('/v1/plan/' + plan_id).then((response) => {
          if (response.data != null) {
            form.id = response.data.data.plan_id
            form.name = response.data.data.plan_name
            form.country = response.data.data.country_code
            planTempData.currency = response.data.data.currency_code
            form.type = response.data.data.plan_type
            form.status = response.data.data.status
            form.row_version = response.data.data.row_version
            form.tiers = response.data.data.plan_tiers
            previousType.value = response.data.data.plan_type
            getCurrencyByCountyId()
          } else {
            console.error('Error!')
          }
          // isLoading.value = false
        })
      }
      isClickedType()
      await getMerchantsByPlanId()
      setMerchantOptions()
    }

    async function getMerchantsByPlanId(
      page = 1,
      column = assignedAccountsSortColumn.value,
      search = null
    ) {
      // isLoading.value = true
      const plan_id = form.id
      if (search != null) {
        assignedAccountsSearchText.value = search
      }
      await axios
        .get('v1/merchants', {
          params: {
            plan_id: plan_id,
            search: assignedAccountsSearchText.value,
            count_per_page: assignedAccountsPerPage.value,
            page: page,
            sortColumn: column,
            sortDirection: assignedAccountsSortColumnDirection.value,
          },
        })
        .then((response) => {
          form.assignedAccounts = []
          if (response.data.data.data.length != 0) {
            form.assignedAccounts = []
            response.data.data.data.find((merchant) => {
              let obj = {}
              obj.merchant = merchant.mch_id
              obj.merchant_name = merchant.organisation_name
              obj.address1 = merchant.address1
              obj.state = merchant.state_name
              obj.town = merchant.suburb_name
              obj.country = merchant.country_name
              obj.isNew = false
              form.assignedAccounts.push(obj)
              merchantsByPlan.value.push(obj)
            })
            assignedAccountsLastPage.value = response.data.data.last_page
            assignedAccountsTotalRecord.value = response.data.data.total
            isPlanHasMerchants.value = true
          } else {
            isPlanHasMerchants.value = false
          }
        })
      // isLoading.value = false
    }

    async function sortMerchantsByPlanId(column) {
      if (environment.value == 'edit') {
        assignedAccountsSortColumn.value = column

        assignedAccountsSortColumnDirection.value =
          assignedAccountsSortColumnDirection.value === 'asc' ? 'desc' : 'asc'

        await getMerchantsByPlanId(
          1,
          assignedAccountsSortColumn.value,
          assignedAccountsSearchText.value
        )
      }
    }

    function setAssignPlanData(merchantId) {
      resetAssignPlanForm()
      merchantsByPlan.value.forEach((merchant) => {
        if (merchant.merchant == merchantId) {
          assignPlanForm.merchant = merchantId
          assignPlanForm.merchant_name = merchant.merchant_name
        }
      })
      showAssignPlanModal()
      getPlanByCountryId()
    }

    async function getPlanByCountryId() {
      planOptions.value = [{ value: '', text: 'Select a Plan' }]
      await axios
        .get('/v1/get-plans-by-country/' + form.country)
        .then((response) => {
          if (response.data != null) {
            response.data.data.find((plan) => {
              if (plan.plan_id != form.id) {
                let obj = {}
                obj.text = plan.plan_name
                obj.value = plan.plan_id
                planOptions.value.push(obj)
              }
            })
          } else {
            console.error('Error!')
          }
        })
    }

    function onSubmit() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.plan_id = form.id
      SubmitArr.name = form.name
      SubmitArr.type = form.type
      SubmitArr.currency = form.currency
      SubmitArr.status = form.status
      SubmitArr.country = form.country
      SubmitArr.status = form.status
      SubmitArr.tiers = form.tiers
      SubmitArr.assignedAccounts = form.assignedAccounts
      SubmitArr.changedAssignAccounts = changedAssignAccounts.value
      // showTransactionChargesAddRow.value = false
      isLoading.value = true
      if (form.id == null) {
        url = '/v1/new-plan'
      } else {
        url = '/v1/edit-plan/' + form.id
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == null) {
            slideOver.show = false
            modal.confirm_button = false
            modal.close_button = true
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Plan created successfully.'
            modal.show = true
          } else {
            slideOver.show = false
            modal.confirm_button = false
            modal.close_button = true
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Plan updated successfully.'
            modal.show = true
          }
          resetCopyPlan()
          getPlans(currentPage.value)
          resetForm()
          resetDataArrays()
          resetChangedAssignAccounts()
        } else {

          if(currencyOptions.value.length <=1 && response.data.data.errors.validations.currency[0] === 'Please select a currency.' && form.country != null){
            form.errors ={
              validations: {
                currency: ["A currency is not configured for the selected country. Please contact opuspay."]
              }
            }
          }else{
            form.errors = response.data.data.errors
          }

          if (response.data.data.errors.validations.tiers != null){
            activeTabId.value = 'tab1'
          }
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function applyAssignPlan(merchantId, PlanId) {
      if(PlanId == null || PlanId == ''){
        errorMessageForAssignPlan.value = 'Please assign a plan to the merchant.'
      }
      else{
        let obj = {}
        obj.merchant_id = merchantId;
        obj.plan_id = PlanId;
        changedAssignAccounts.value.push(obj);
        modalAssignPlan.show = false
        resetCopyPlan()
        resetAssignPlanForm()
      }
    }

    function onSubmitCopyPlan() {
      isLoading.value = true
      let SubmitArr = {}
      let url = 'v1/copy-plan'
      SubmitArr.copy_plan = form.copyPlan

      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          setTimeout(function() {
            isWarning.value = false
          }, 200)
          modal.confirm_button = false
          modal.close_button = true
          modal.button_text = 'Ok'
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = 'Plan copied successfully.'
          modal.show = true
          resetCopyPlan()
          getPlans(currentPage.value)
          isLoading.value = false
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function setFromPlan(planId) {
      if (form.copyPlan.includes(parseInt(planId))) {
        form.copyPlan.splice(form.copyPlan.indexOf(parseInt(planId)), 1)
      } else {
        form.copyPlan.push(planId)
      }
      let _arr1 = form.copyPlan.sort()
      let _arr2 = planList.value.sort()

      if (
        Array.isArray(_arr1) &&
        Array.isArray(_arr2) &&
        _arr1.length === _arr2.length
      ) {
        form.isAllPlanActive = 1
      }

      if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
      ) {
        form.isAllPlanActive = 0
      }
    }

    function selectAllPlanCheck() {
      if (form.isAllPlanActive == 0) {
        form.copyPlan = []
        planList.value.forEach((value) => {
          form.copyPlan.push(value)
        })
      } else if (form.isAllPlanActive == 1) {
        form.copyPlan = []
      }
    }

    function setPlanCheck(planId) {
      if (form.copyPlan.includes(parseInt(planId))) {
        return true
      }
    }

    function updatePlanType() {
      form.tiers = []
      previousType.value = form.type
    }

    function isChangeType() {
      if (previousType.value != form.type) {
        return true
      } else {
        return false
      }
    }

    function showWarningModal() {
      if (isChangeType() && environment.value == 'edit') {
        modal.type = 'warning'
        modal.title = 'Warning'
        modal.message = ''
        modal.show = true
        modal.confirm_button = false
        modal.close_button = false
        isWarning.value = true
      }
    }

    function showAssignPlanModal() {
      modalAssignPlan.close_button = false
      modalAssignPlan.message = ''
      modalAssignPlan.confirm_button = false
      modalAssignPlan.show = true
    }

    function setPlanData() {
      form.currency = null
      if (form.id != null && initialLoad.value == false) {
        form.currency = planTempData.currency
        initialLoad.value = true
      }
    }

    function deleteValue(id) {
      idToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.close_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }
    function deleteTier(id) {
      idToDeleteTier.value = id
      if(id == 0 || id == form.tiers.length - 1){
        modal.message =
          'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      }
      else{
        modal.message =
          'Please check "quantity from" value of the next row (if applicable) to be sure there are no gaps in quantity counts. Would you like to continue to delete this row?'
      }
      modal.type = 'warning'
      modal.confirm_button = true
      modal.close_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.show = true
    }

    function confirmAlert() {
      if (idToDelete.value != '') {
        deletePlan()
        idToDelete.value = ''
      } else if (idToDeleteTier.value != null) {
        form.tiers.splice(parseInt(idToDeleteTier.value), 1)
        idToDeleteTier.value = null
      }
      modal.show = false
      return true
    }

    function confirmWarning() {
      modal.show = false
      setTimeout(function() {
        isWarning.value = false
      }, 200)
      isClickedType()
      updatePlanType()
      form.tier.tier_rate = 0
      form.tier.quantity_from = 0
      form.tier.quantity_to = 0
      return true
    }

    function deletePlan() {
      let data = {}
      data.plan_id = idToDelete.value
      axios.delete('/v1/delete-plan/' + idToDelete.value, {params: data}).then((response) => {
        if (response.data.data != null && response.data.status == true) {
          getPlans(currentPage.value)
        }
        else {
          router.push({ name: 'unauthorized-access'})
        }
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function closeAlert() {
      modal.show = false
      if(isWarning.value == true){
        form.type = previousType.value
      }
      setTimeout(function() {
        isWarning.value = false
      }, 200)
      isClickedType()
      router.push({ name: 'plans' })
    }

    function closeAssignPlanAlert() {
      modalAssignPlan.show = false
    }

    function resetForm() {
      form.id = null
      form.name = null
      form.country = null
      form.currency = null
      currencyOptions.value = null
      currencyOptions.value = [
        { value: '', text: 'Please select a currency' },
      ]
      form.type = 0
      form.status = 'ACT'
      ;(form.tier = {
        plan_tier_id: null,
        quantity_from: null,
        quantity_to: null,
        invoice_by: 'transaction',
        tier_rate: null,
      }),
        (form.assignedAccount = {
          merchant: null,
          merchant_name: null,
          address1: null,
          town: null,
          state: null,
          country: null,
        }),
        resetTier()
      resetAssignedAccounts()
      resetDataArrays()
    }

    function resetTier() {
      form.tier.plan_tier_id = null
      form.tier.quantity_from = null
      form.tier.quantity_to = null
      form.tier.invoice_by = 'transaction'
      form.tier.tier_rate = null
      form.errors = ''
    }

    function resetAssignedAccounts() {
      form.assignedAccount.merchant = null
      form.assignedAccount.address1 = null
      form.assignedAccount.town = null
      form.assignedAccount.state = null
      form.assignedAccount.country = null
      form.errorInMerchant = null
    }

    function resetDataArrays() {
      form.tiers = []
      form.assignedAccounts = []
    }

    function resetCopyPlan() {
      form.isAllPlanActive = 0
      form.copyPlan = []
      form.copyPlanId = null
    }

    function resetErrors() {
      form.errors = ''
      form.errorInMerchant = ''
      errorMessageForAssignPlan.value = null
    }
    function resetAssignPlanForm() {
      assignPlanForm.merchant = null
      assignPlanForm.merchant_name = null
      assignPlanForm.plan = null
    }

    function resetChangedAssignAccounts(){
      changedAssignAccounts.value = []
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return {
      t,
      form,
      modal,
      fullPage,
      tabItems,
      onchange,
      isLoading,
      resetTier,
      slideOver,
      selectRow,
      isEditMode,
      selectedRow,
      activeTabId,
      countryOptions,
      showTabContent,
      currencyOptions,
      invoiceByOptions,
      addTierArrayData,
      sortColumnDirection,
      showAssignedAccountRow,
      showTransactionChargesAddRow,
      statusOptions,
      getCountry,
      getMerchants,
      setMerchantsDetails,
      addAssignedAccountArrayData,
      merchantOptions,
      plans,
      getCurrencyByCountyId,
      resetForm,
      onSubmit,
      openPlanSummary,
      isClickedType,
      deleteValue,
      confirmAlert,
      closeAlert,
      getPlans,
      perPage,
      totalRecord,
      sortColumn,
      lastPage,
      sort,
      setMerchantOptions,
      searchText,
      checkQuantityFromValidation,
      resetDataArrays,
      resetAssignedAccounts,
      environment,
      confirmWarning,
      isWarning,
      isChangeType,
      setPlanData,
      tiersLastPage,
      tiersPerPage,
      tiersSortColumnDirection,
      tiersSortColumn,
      tiersSearchText,
      tiersTotalRecord,
      assignedAccountsLastPage,
      assignedAccountsPerPage,
      assignedAccountsSortColumnDirection,
      assignedAccountsSortColumn,
      assignedAccountsSearchText,
      assignedAccountsTotalRecord,
      showWarningModal,
      modalAssignPlan,
      showAssignPlanModal,
      assignPlanForm,
      planOptions,
      setAssignPlanData,
      resetErrors,
      closeAssignPlanAlert,
      applyAssignPlan,
      onSubmitCopyPlan,
      selectAllPlanCheck,
      setFromPlan,
      setPlanCheck,
      sortMerchantsByPlanId,
      onlyTwoDecimalNumbers,
      noDecimalNumbers,
      setMerchantOptionsWithoutPlan,
      merchantOptionsWithoutPlan,
      isPlanHasMerchants,
      resetCopyPlan,
      deleteTier,
      capitalizeFirstLetter,
      resetChangedAssignAccounts,
      errorMessageForAssignPlan
    }
  },
}
</script>
