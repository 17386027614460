<template>
  <div class="flex items-center justify-between flex-shrink-0 mb-1 px-4 py-5 bg-gray-100">
    <logo
      id="app-logo"
      class="h-6"
    />

    <tippy
      placement="bottom-end"
      theme="dropdown"
      :arrow="true"
      hide-on-click="false"
      trigger="mouseenter"
      interactive="true"
    >
      <button
        id="button-lang-switcher"
        class="
          px-2
          pl-3
          py-1
          flex
          items-center
          justify-between
          bg-white
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-primary focus:ring-offset-1
        "
        type="button"
      >
        <img
          class="w-auto h-3 rounded-sm"
          :src="'/images/flags/'+`${ language.countryCode }`+'.svg'"
        >
        <span class="mx-1 pt-0.5 2xl:pt-0 text-xs font-medium text-gray-500">{{ language.langCode }}</span>
      </button>

      <template #content>
        <div class="w-52 max-h-48 space-y-1 overflow-x-hidden overflow-y-auto">
          <div
            v-for="(lang, index) in languages"
            :key="index"
          >
            <button
              class="w-full px-2.5 py-2 hover:bg-gray-50 focus:outline-none"
              type="button"
              role="menuitem"
              tabindex="-1"
              @click="setLanguage(lang.langCode)"
            >
              <div class="flex items-center space-x-2 overflow-ellipsis">
                <img
                  class="w-auto h-3 rounded-sm shadow"
                  :src="'/images/flags/'+`${ lang.countryCode }`+'.svg'"
                >
                <p
                  class="overflow-ellipsis overflow-hidden font-medium text-left text-xs text-gray-500"
                  style="width: 160px;"
                >
                  {{ lang.langName }}
                </p>
              </div>
            </button>
          </div>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import axios from "axios";
import {reactive, ref} from "vue";
import { useI18n } from 'vue-i18n'
import { Tippy } from 'vue-tippy'

export default {
  name: 'NavHeader',

  components: {
    Logo,
    Tippy,
  },

  setup() {
    const language = reactive({
      langCode :  localStorage.getItem('langCode') == null ?  'EN-AU' : localStorage.getItem('langCode'),
      langName : null,
      countryCode : localStorage.getItem('countryCode') == null ?  'au' : localStorage.getItem('countryCode') ,
    })
    const languagesOption = ref([])
    const languages = ref([])
    const languagesFiles = ref([])
    const { t, locale, setLocaleMessage } = useI18n({ useScope: 'global' })

    getLanguageList()
    getLanguageFileList()

    function getLanguageFileList() {
       axios.get("/v1/language/get-files").then(response => {
        if (response.data.data != null) {
          languagesFiles.value = response.data.data
          setDefaultLang()
            }
        })
      }

    function getLanguageList() {
      axios.get("/v1/language/get-all").then(response => {
        if (response.data.data != null) {
          response.data.data.find((lang) => {
            let obj1 = {}
            let obj2 = {}
            obj1.countryCode = lang.country_code.toLowerCase()
            obj1.langCode = lang.lang_code
            obj1.langName = lang.lang_name
            obj2.text = lang.lang_name
            obj2.value = lang.lang_code
            languages.value.push(obj1)
            languagesOption.value.push(obj2)
          })

        }
      })
    }

    function setLanguage(langCode){
      language.langCode = langCode
      languages.value.forEach((lang) => {
        if(lang.langCode == language.langCode){
          language.countryCode = lang.countryCode
          locale.value = lang.langCode;
          setLocaleMessage(lang.langCode, languagesFiles.value[lang.langCode]);
          localStorage.setItem('langCode', lang.langCode);
          localStorage.setItem('countryCode', lang.countryCode);
        }
      })
    }

    function setDefaultLang(){
      locale.value = language.langCode;
      setLocaleMessage(language.langCode, languagesFiles.value[language.langCode]);
    }

    return {
      languagesOption,
      setLanguage,
      language,
      languages,
    }
  }
}
</script>
