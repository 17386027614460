<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <Dialog
      as="div"
      :open="isOpen"
    >
      <div class="fixed inset-0 z-20 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <DialogOverlay class="fixed inset-0 bg-black opacity-70" />

          <span
            class="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
              "
            >
              <button
                class="block w-0 h-0 focus:outline-none"
                type="button"
              >
                <!-- Invisible auto-focus button -->
              </button>

              <div
                v-if="type"
                class="mb-2 flex items-center justify-center"
              >
                <div
                  class="
                    flex
                    items-center
                    justify-center
                    mx-auto
                    inline-block
                    p-3
                    rounded-full
                  "
                  :class="{
                    'bg-green-100': type == 'success',
                    'bg-red-100': type == 'error',
                    'bg-yellow-100': type == 'warning',
                    'bg-primary-light': type == 'info',
                  }"
                >
                  <svg-icon
                    v-if="type == 'success'"
                    class="w-auto h-7 text-green-600"
                    icon="check"
                  />
                  <svg-icon
                    v-if="type == 'error'"
                    class="w-auto h-7 text-red-600"
                    icon="error"
                  />
                  <svg-icon
                    v-if="type == 'warning'"
                    class="w-auto h-7 text-yellow-600"
                    icon="warning"
                  />
                  <svg-icon
                    v-if="type == 'info'"
                    class="w-auto h-7 text-blue-600"
                    icon="info"
                  />
                </div>
              </div>

              <DialogTitle
                v-if="title"
                as="h3"
                class="
                  text-lg
                  font-medium
                  leading-6
                  text-black text-center text-opacity-90
                "
              >
                {{ t(title) }}
              </DialogTitle>

              <div class="mt-2 text-sm text-center text-gray-500">
                <p
                  v-if="isArray != true && message != null"
                  id="sucessModalId_1"
                >
                  {{ t(message) }}
                </p>
                <div v-else>
                  <p
                    v-for="msg , key in message"
                    id="sucessModalId_2"
                    :key="key"
                  >
                    {{ t(msg.message) }}
                  </p>
                </div>
                <slot name="modal-body" />
              </div>

              <div
                v-if="array != null"
                class="overflow-y-auto"
                style="max-height:calc(100vh - 400px);"
              >
                <ul
                  class="w-fit mx-auto mt-3  text-gray-500 text-sm"
                  style="width:fit-content;"
                >
                  <li
                    v-for="array_1, key in array"
                    :id="'array'+key + 1"
                    :key="key"
                  >
                    {{ key + 1 }} {{ '-' }} {{ t(array_1) }}
                  </li>  
                </ul>
              </div>

              <div
                class="
                  mt-4
                  flex flex-wrap
                  items-center
                  justify-center
                  space-x-2
                "
              >
                <Button
                  v-if="confirmButton"
                  class="btn-wide"
                  type="button"
                  @click="confirmAction"
                >
                  {{ t(confirmButtonText) }}
                </Button>
                <ButtonSecondary
                  v-if="confirmButton && closeButton"
                  class="btn-wide"
                  type="button"
                  @click="closeModal"
                >
                  {{ t(buttonText) }}
                </ButtonSecondary>
                <Button
                  v-else-if="closeButton"
                  class="btn-wide"
                  type="button"
                  @click="closeModal"
                >
                  {{ t(buttonText) }}
                </Button>

                <slot name="modal-actions" />
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, watch } from 'vue'
import {
  Dialog,
  DialogTitle,
  DialogOverlay,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Modal',

  components: {
    Dialog,
    Button,
    SvgIcon,
    DialogTitle,
    DialogOverlay,
    TransitionRoot,
    TransitionChild,
    ButtonSecondary,
  },

  props: {
    type: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },
    isArray:{
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    array: {
      type: Array,
      default: null,
    },

    closeButton: {
      type: Boolean,
      default: true,
    },

    buttonText: {
      type: String,
      default: 'Ok',
    },

    confirmButtonText: {
      type: String,
      default: 'Yes',
    },

    confirmButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['openModal', 'closeModal', 'confirmModal'],

  setup(props, { emit }) {
    const isOpen = ref(true)
    const { t } = useI18n()
    watch(() => {
      if (isOpen.value) {
        openModal()
      }
    })

    function openModal() {
      emit('openModal')
      isOpen.value = true
    }

    function confirmAction() {
      emit('confirmModal')
      isOpen.value = false
    }

    function closeModal() {
      emit('closeModal')
      isOpen.value = false
    }

    return {
      isOpen,
      closeModal,
      confirmAction,
      t,
    }
  },
}
</script>
