import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store/index.js'
import CryptoJs from 'crypto-js'
import constant from "@/constant";

const CryptoJsAesJson = constant.CryptoJsAesJson

// Layouts
import AuthLayout from './layouts/AuthLayout.vue'
import DashboardLayout from './layouts/DashboardLayout.vue'

// Public
import SignIn from './pages/auth/SignIn.vue'
import SignUp from './pages/auth/SignUp.vue'
import ResetPassword from './pages/auth/ResetPassword.vue'
import ChangePassword from './pages/auth/ChangePassword.vue'
import ForgotPassword from './pages/auth/ForgotPassword.vue'
import RegistrationDetails from './pages/auth/RegistrationDetails.vue'
import NoAccess from './pages/auth/NoAccess.vue'
import NotFound from './pages/auth/NotFound.vue'

import AccountActivate from './pages/AccountActivate.vue'

// ADMIN DASHBOARD

import Dashboard from './pages/Dashboard.vue'

// Billing
import BillingHistory from './pages/billing/BillingHistory.vue'
import GenerateInvoices from './pages/billing/GenerateInvoices.vue'

// Merchants
import Merchants from './pages/Merchants.vue'

// Registrations
import Registrations from './pages/Registrations.vue'

// Transactions
import Transactions from './pages/Transactions.vue'

// Manage Tokens
import ManageTokens from './pages/manage-tokens/ManageTokens.vue'
import ManageMerchantTokens from './pages/merchant-portal/manage-tokens/ManageTokens.vue'
import MerchantTokenisedSummary from './pages/merchant-portal/manage-tokens/TokenisedSummary.vue'
import TokenisedSummary from './pages/manage-tokens/TokenisedSummary.vue'


// Qr Transactions
import QrTransactions from './pages/QrTransactions.vue'
import QrPrograms from './pages/QrPrograms.vue'
import QrWidgetCustomisation from './pages/QrWidgetCustomisation.vue'

// Settings
import CountriesCurrencies from './pages/settings/CountriesCurrencies.vue'

import DropdownField from './pages/settings/DropdownField.vue'
import DropdownFields from './pages/settings/DropdownFields.vue'
import DropdownFieldEdit from './pages/settings/DropdownFieldEdit.vue'

import Gateways from './pages/settings/Gateways.vue'
import GatewaySummary from './pages/settings/GatewaySummary.vue'

import Languages from './pages/settings/Languages'

import Plans from './pages/settings/Plans'
import PlanSummary from './pages/settings/PlanSummary'

import TaxGroups from './pages/settings/TaxGroups.vue'

import SecurityProfiles from './pages/settings/SecurityProfiles.vue'
import SecurityProfilesEdit from './pages/settings/SecurityProfilesEdit.vue'

import Term from './pages/settings/Term.vue'
import Terms from './pages/settings/Terms.vue'
import TermsAdd from './pages/settings/TermsAdd.vue'

import Users from './pages/settings/Users.vue'

// MERCHANT DASHBOARD

import MerchantDashboard from './pages/merchant-portal/Dashboard.vue'

// Accounts
import Accounts from './pages/merchant-portal/accounts/Accounts.vue'
import AccountAdd from './pages/merchant-portal/accounts/add/AccountAdd.vue'
import AccountEdit from './pages/merchant-portal/accounts/edit/AccountEdit.vue'

// Billing History
import MerchantBillingHistory from './pages/merchant-portal/billing-history/BillingHistory.vue'

// Security
import MerchantSecurityProfiles from './pages/merchant-portal/security/SecurityProfiles.vue'
import MerchantSecurityProfile from './pages/merchant-portal/security/SecurityProfile.vue'
import MerchantSecurityProfileAdd from './pages/merchant-portal/security/SecurityProfileAdd.vue'

// Transactions
import MerchantTransactions from './pages/merchant-portal/transactions/Transactions.vue'

// Transaction Router
import RuleIndex from './pages/transaction-route/RuleIndex.vue'
import RuleDefinition from './pages/transaction-route/RuleDefinition.vue'
import RouterRuleAdd from './pages/router-rule/RouterRuleAdd.vue'
import RouterRulesList from './pages/router-rule/RouterRulesList.vue'
import RouterRule from './pages/router-rule/RouterRule.vue'

// Users
import MerchantUsers from './pages/merchant-portal/users/Users.vue'

// TENANT DASHBOARD

// Countries & Currencies
import OwnerCountriesCurrencies from './pages/owner-portal/CountriesCurrencies.vue'

// Languages
import OwnerLanguages from './pages/owner-portal/Languages.vue'

// Tenants
import Tenants from './pages/owner-portal/Tenants.vue'

// Application data
import OwnerAppData from './pages/owner-portal/ApplicationData.vue'

import Tenant from '@/pages/Tenant'
import { meta } from 'eslint-plugin-prettier-vue/lib/rules/prettier/meta'
import { ref } from 'vue'
import axios from 'axios'

const routes = [{
        path: '/',
        redirect: { name: 'signin' },
        component: AuthLayout,
        children: [{
                path: 'login',
                name: 'signin',
                component: SignIn,
                meta: {
                    loggedIn: true,
                },
            },
            {
                path: 'signup',
                name: 'signup',
                component: SignUp,
            },
            {
                path: 'register/:token',
                name: 'registration-details',
                component: RegistrationDetails,
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'reset-password',
                component: ResetPassword,
            },
            {
                path: 'change-password',
                name: 'change-password',
                component: ChangePassword,
            },
            {
                path: '/unauthorized-access',
                name: 'unauthorized-access',
                component: NoAccess,
            },
        ],
    },
    {
        path: '/logout',
        name: 'logout',
    },
    {
        path: '/activate-account',
        name: 'account-activate',
        component: AccountActivate,
    },
    {
        path: '/activate-account/:token',
        name: 'account-activate',
        component: AccountActivate,
    },
    {
        path: '/tenant',
        name: 'tenant',
        component: Tenant,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardLayout,
        redirect: { name: 'dashboard-index' },
        meta: {
            requiresAuth: true,
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'dashboard-index',
            component: Dashboard,
        }, ],
    },
    {
        path: '/merchant-dashboard',
        name: 'merchant-dashboard',
        component: DashboardLayout,
        redirect: { name: 'merchant-dashboard-index' },
        meta: {
            requiresAuth: true,
            accountType: 'MCH',
        },
        children: [{
            path: 'index',
            name: 'merchant-dashboard-index',
            component: MerchantDashboard,
        }, ],
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: DashboardLayout,
        redirect: { name: 'accounts-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'MCH_1',
            accountType: 'MCH',
        },
        children: [{
                path: 'index',
                name: 'accounts-index',
                component: Accounts,
            },
            {
                path: 'add',
                name: 'account-add',
                component: AccountAdd,
                meta: {
                    menuGroup: 'accounts',
                    parent: 'accounts-index',
                },
            },
            {
                path: 'edit/:mch_acc_id',
                name: 'account-edit',
                component: AccountEdit,
                meta: {
                    menuGroup: 'accounts',
                    parent: 'accounts-index',
                },
            },
        ],
    },
    {
        path: '/billing',
        name: 'billing',
        component: DashboardLayout,
        meta: {
            menuGroup: 'billing',
            requiresAuth: true,
            securityGrantID: 'TNT_3',
            accountType: 'TNT',
        },
        children: [{
                path: 'billing-history',
                name: 'billing-history',
                component: BillingHistory,
                meta: {
                    menuGroup: 'billing',
                    securityGrantID: 'TNT_22',
                    accountType: 'TNT',
                },
            },
            {
                path: 'generate-invoices',
                name: 'generate-invoices',
                component: GenerateInvoices,
                meta: {
                    menuGroup: 'billing',
                    securityGrantID: 'TNT_21',
                    accountType: 'TNT',
                },
            },
        ],
    },

    {
        path: '/manage-tokens',
        name: 'manage-tokens',
        component: DashboardLayout,
        meta: {
            menuGroup: 'manage-tokens',
            requiresAuth: true,
            securityGrantID: 'TNT_18',
            accountType: 'TNT',
        },
        children: [{
                path: 'manage-token-index',
                name: 'manage-token-index',
                component: ManageTokens,
                meta: {
                    menuGroup: 'manage-tokens',
                },
            },
            {
                path: 'tokenised-summary/:slug',
                name: 'tokenised-summary',
                component: TokenisedSummary,
                meta: {
                    menuGroup: 'manage-tokens',
                },
            },
        ],
    },

    {
        path: '/manage-merchant-tokens',
        name: 'manage-merchant-tokens',
        component: DashboardLayout,
        meta: {
            menuGroup: 'manage-merchant-tokens',
            requiresAuth: true,
            securityGrantID: 'MCH_2',
            accountType: 'MCH',
        },
        children: [{
                path: 'manage-merchant-token-index',
                name: 'manage-merchant-token-index',
                component: ManageMerchantTokens,
                meta: {
                    menuGroup: 'manage-merchant-tokens',
                },
            },
            {
                path: 'merchant-tokenised-summary/:slug',
                name: 'merchant-tokenised-summary',
                component: MerchantTokenisedSummary,
                meta: {
                    menuGroup: 'manage-merchant-tokens',
                },
            },
        ],
    },

    {
        path: '/merchant-billing-history',
        name: 'merchant-billing-history',
        component: DashboardLayout,
        redirect: { name: 'merchant-billing-history-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'MCH_2',
            accountType: 'MCH',
        },
        children: [{
            path: 'index',
            name: 'merchant-billing-history-index',
            component: MerchantBillingHistory,
            securityGrantID: 'MCH_2',
            accountType: 'MCH',
        }, ],
    },
    {
        path: '/merchants',
        name: 'merchants',
        component: DashboardLayout,
        redirect: { name: 'merchants-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_17',
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'merchants-index',
            component: Merchants,
        }, ],
    },
    {
        path: '/registrations',
        name: 'registrations',
        component: DashboardLayout,
        redirect: { name: 'registrations-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_1',
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'registrations-index',
            component: Registrations,
        }, ],
    },
    {
        path: '/security',
        name: 'merchant-security',
        component: DashboardLayout,
        meta: {
            menuGroup: 'merchant-security',
            requiresAuth: true,
        },
        children: [{
                path: 'merchant-security-profiles',
                name: 'merchant-security-profiles-index',
                component: MerchantSecurityProfiles,
                meta: {
                    menuGroup: 'merchant-security',
                    securityGrantID: 'MCH_5',
                    accountType: 'MCH',
                },
            },
            {
                path: 'merchant-security-profile/add',
                name: 'merchant-security-profile-add',
                component: MerchantSecurityProfileAdd,
                meta: {
                    menuGroup: 'merchant-security',
                    parent: 'merchant-security-profiles-index',
                    securityGrantID: 'MCH_5',
                    accountType: 'MCH',
                    merchantOnly: true,
                },
            },
            {
                path: 'merchant-security-profile/edit/:slug',
                name: 'merchant-security-profile-edit',
                component: MerchantSecurityProfileAdd,
                meta: {
                    menuGroup: 'merchant-security',
                    parent: 'merchant-security-profiles-index',
                    securityGrantID: 'MCH_5',
                    accountType: 'MCH',
                },
            },
            {
                path: 'merchant-security-profile/:slug',
                name: 'merchant-security-profile-view',
                component: MerchantSecurityProfileAdd,
                meta: {
                    menuGroup: 'merchant-security',
                    parent: 'merchant-security-profiles-index',
                    securityGrantID: 'MCH_5',
                    accountType: 'MCH',
                },
            },
            {
                path: 'merchant-users',
                name: 'merchant-users-index',
                component: MerchantUsers,
                meta: {
                    menuGroup: 'merchant-security',
                    securityGrantID: 'MCH_6',
                    accountType: 'MCH',
                },
            },
        ],
    },

    {
        path: '/qrtransactions',
        name: 'qrtransactions',
        component: DashboardLayout,
        redirect: { name: 'qrtransactions-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_19',
            accountType: 'TNT',
        },
        children: [{
            path: 'view/:qrProgramId',
            name: 'qrtransactions-index',
            component: QrTransactions,
        }, ],
    },

    {
        path: '/merchant-qrtransactions',
        name: 'merchant-qrtransactions',
        component: DashboardLayout,
        redirect: { name: 'merchant-qrtransactions-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'MCH_8',
            accountType: 'MCH',
        },
        children: [{
            path: 'view/:qrProgramId',
            name: 'merchant-qrtransactions-index',
            component: QrTransactions,
        }, ],
    },


    {
        path: '/transactions',
        name: 'transactions',
        component: DashboardLayout,
        redirect: { name: 'transactions-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_15',
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'transactions-index',
            component: Transactions,
        }, ],
    },
    {
        path: '/merchant-transactions',
        name: 'merchant-transactions',
        component: DashboardLayout,
        redirect: { name: 'merchant-transactions-index' },
        meta: {
            requiresAuth: true,
        },
        children: [{
            path: 'index',
            name: 'merchant-transactions-index',
            component: MerchantTransactions,
            meta: {
                securityGrantID: 'MCH_3',
                accountType: 'MCH',
            },
        }, ],
    },
    {
        path: '/qrprogram',
        name: 'qrprogram',
        component: DashboardLayout,
        redirect: { name: 'qrprogram-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_19',
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'qrprogram-index',
            component: QrPrograms,
        }, ],
    },
    {
        path: '/qrwidgetcustomisation',
        name: 'qrwidgetcustomisation',
        component: DashboardLayout,
        redirect: { name: 'qrwidgetcustomisation-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'TNT_19',
            accountType: 'TNT',
        },
        children: [{
            path: 'index',
            name: 'qrwidgetcustomisation-index',
            component: QrWidgetCustomisation,
        }, ],
    },
    {
        path: '/merchant-qrwidgetcustomisation',
        name: 'merchant-qrwidgetcustomisation',
        component: DashboardLayout,
        redirect: { name: 'merchant-qrwidgetcustomisation-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'MCH_8',
            accountType: 'MCH',
        },
        children: [{
            path: 'index',
            name: 'merchant-qrwidgetcustomisation-index',
            component: QrWidgetCustomisation,
        }, ],
    },
  {
        path: '/merchant-qrprogram',
        name: 'merchant-qrprogram',
        component: DashboardLayout,
        redirect: { name: 'merchant-qrprogram-index' },
        meta: {
            requiresAuth: true,
        },
        children: [{
            path: 'index',
            name: 'merchant-qrprogram-index',
            component: QrPrograms,
            meta: {
                securityGrantID: 'MCH_8',
                accountType: 'MCH',
            },
        }, ],
    },
    {
        path: '/tenants',
        name: 'tenants',
        component: DashboardLayout,
        redirect: { name: 'tenants-index' },
        meta: {
            requiresAuth: true,
        },
        children: [{
            path: 'index',
            name: 'tenants-index',
            component: Tenants,
            meta: {
                securityGrantID: 'APO_1',
                accountType: 'APO',
            },
        }, ],
    },
    {
        path: '/owner/languages',
        name: 'owner-languages',
        component: DashboardLayout,
        redirect: { name: 'languages-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'APO_2',
            accountType: 'APO',
        },
        children: [{
            path: 'index',
            name: 'languages-index',
            component: OwnerLanguages,
        }, ],
    },
    {
        path: '/owner/countries-and-currencies',
        name: 'owner-countries-currencies',
        component: DashboardLayout,
        redirect: { name: 'owner-countries-currencies-index' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'APO_3',
            accountType: 'APO',
        },
        children: [{
            path: 'index',
            name: 'owner-countries-currencies-index',
            component: OwnerCountriesCurrencies,
        }, ],
    },
    {
        path: '/owner/app-data',
        name: 'owner-app-data',
        component: DashboardLayout,
        redirect: { name: 'app-data' },
        meta: {
            requiresAuth: true,
            securityGrantID: 'APO_4',
            accountType: 'APO',
        },
        children: [{
            path: 'index',
            name: 'owner-app-data-index',
            component: OwnerAppData,
        }, ],
    },
    {
        path: '/security',
        name: 'security',
        component: DashboardLayout,
        meta: {
            menuGroup: 'settings',
            requiresAuth: true,
        },
        children: [{
                path: 'security-profiles',
                name: 'security-profiles',
                component: SecurityProfiles,
                meta: {
                    menuGroup: 'settings',
                    childMenuGroup: 'settings',
                    securityGrantID: 'TNT_10',
                    accountType: 'TNT',
                },
            },
            {
                path: 'security-profiles/add',
                name: 'security-profiles-add',
                component: SecurityProfilesEdit,
                meta: {
                    menuGroup: 'settings',
                    childMenuGroup: 'settings',
                    parent: 'security-profiles',
                    securityGrantID: 'TNT_10',
                    accountType: 'TNT',
                },
            },
            {
                path: 'security-profiles/edit/:slug',
                name: 'security-profiles-edit',
                component: SecurityProfilesEdit,
                meta: {
                    menuGroup: 'settings',
                    childMenuGroup: 'settings',
                    parent: 'security-profiles',
                    securityGrantID: 'TNT_10',
                    accountType: 'TNT',
                },
            },
            {
                path: 'security-profiles/:slug',
                name: 'security-profiles-view',
                component: SecurityProfilesEdit,
                meta: {
                    menuGroup: 'settings',
                    childMenuGroup: 'settings',
                    parent: 'security-profiles',
                    securityGrantID: 'TNT_10',
                    accountType: 'TNT',
                },
            },
            {
                path: 'users',
                name: 'users',
                component: Users,
                meta: {
                    menuGroup: 'settings',
                    childMenuGroup: 'settings',
                    securityGrantID: 'TNT_11',
                    accountType: 'TNT',
                },
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: DashboardLayout,
        meta: {
            menuGroup: 'settings',
            requiresAuth: true,
        },
        children: [{
                path: 'countries-and-currencies',
                name: 'countries-currencies',
                component: CountriesCurrencies,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_5',
                    accountType: 'TNT',
                },
            },
            {
                path: 'dropdown-fields',
                name: 'dropdown-fields',
                component: DropdownFields,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_13',
                    accountType: 'TNT',
                },
            },
            {
                path: 'dropdown-fields/:slug',
                name: 'dropdown-field-view',
                component: DropdownField,
                meta: {
                    menuGroup: 'settings',
                    parent: 'dropdown-fields',
                    securityGrantID: 'TNT_13',
                    accountType: 'TNT',
                },
            },
            {
                path: 'dropdown-fields/edit/:slug',
                name: 'dropdown-field-edit',
                component: DropdownFieldEdit,
                meta: {
                    menuGroup: 'settings',
                    parent: 'dropdown-fields',
                    securityGrantID: 'TNT_13',
                    accountType: 'TNT',
                },
            },
            {
                path: 'gateways',
                name: 'gateways',
                component: Gateways,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_6',
                    accountType: 'TNT',
                },
            },
            {
                path: 'gateway/:slug/:action',
                name: 'gateway-summary',
                component: GatewaySummary,
                meta: {
                    menuGroup: 'settings',
                    parent: 'gateways',
                    securityGrantID: 'TNT_6',
                    accountType: 'TNT',
                },
            },
            {
                path: 'languages',
                name: 'languages',
                component: Languages,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: '',
                    accountType: 'TNT',
                },
            },
            {
                path: 'plans',
                name: 'plans',
                component: Plans,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_8',
                    accountType: 'TNT',
                },
            },
            {
                path: 'plans/:slug',
                name: 'plan-summary-view',
                component: PlanSummary,
                meta: {
                    menuGroup: 'settings',
                    parent: 'plans',
                    securityGrantID: 'TNT_8',
                    accountType: 'TNT',
                },
            },
            {
                path: 'plans/edit/:slug',
                name: 'plan-summary-edit',
                component: PlanSummary,
                meta: {
                    menuGroup: 'settings',
                    parent: 'plans',
                    securityGrantID: 'TNT_8',
                    accountType: 'TNT',
                },
            },
            {
                path: 'tax-groups',
                name: 'tax-groups',
                component: TaxGroups,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_7',
                    accountType: 'TNT',
                },
            },
            {
                path: 'terms',
                name: 'terms',
                component: Terms,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_12',
                    accountType: 'TNT',
                },
            },
            {
                path: 'terms/add',
                name: 'terms-add',
                component: TermsAdd,
                meta: {
                    menuGroup: 'settings',
                    parent: 'terms',
                    securityGrantID: 'TNT_12',
                    accountType: 'TNT',
                },
            },
            {
                path: 'terms/edit/:tc_id',
                name: 'terms-edit',
                component: TermsAdd,
                meta: {
                    menuGroup: 'settings',
                    parent: 'terms',
                    securityGrantID: 'TNT_12',
                    accountType: 'TNT',
                },
            },
            {
                path: 'terms/:tc_id',
                name: 'term',
                component: Term,
                meta: {
                    menuGroup: 'settings',
                    parent: 'terms',
                    securityGrantID: 'TNT_12',
                    accountType: 'TNT',
                },
            },
            {
                path: 'rule-index',
                name: 'rule-index',
                component: RuleIndex,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'rule-definition',
                name: 'rule-definition',
                component: RuleDefinition,
                meta: {
                    menuGroup: 'settings',
                    parent: 'rule-index',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'rule-definition/edit/:slug',
                name: 'edit-rule-definition',
                component: RuleDefinition,
                meta: {
                    menuGroup: 'settings',
                    parent: 'rule-index',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'router-rule',
                name: 'router-rule',
                component: RouterRuleAdd,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'router-rule/edit/:rule_id',
                name: 'router-rule-edit',
                component: RouterRuleAdd,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'router-rule-index',
                name: 'router-rule-index',
                component: RouterRulesList,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
            {
                path: 'router-rule/:rule_id',
                name: 'router-rule-id',
                component: RouterRule,
                meta: {
                    menuGroup: 'settings',
                    securityGrantID: 'TNT_16',
                    accountType: 'TNT',
                },
            },
        ],
    },
    {
        path: '/:catchAll(.*)',
        name: 'page-not-found',
        component: NotFound,
    },
]

const securityGrants = ref([])
const securityProfileId = ref(null)

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach(async(to, from, next) => {
    store.state.sidebarDisable = false
    getSecurityGrantsFromTheStorage(from.name)

    if (to.name == 'signin') {
        localStorage.removeItem('access_token')
    }

    if (to.matched.some((route) => route.meta.loggedIn)) {
        if (localStorage.getItem('access_token')) {
            const user_type = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, { format: CryptoJsAesJson }).toString(CryptoJs.enc.Utf8))
                .authentication_data.user.user.user_type
            if (!from.name) {
                await getSecurityGrantsPerSecurityProfile()
            }
            if (user_type == 'TNT') {
                next({ name: 'dashboard-index' })
            } else if (user_type == 'MCH') {
                next({ name: 'merchant-dashboard-index' })
            } else if (user_type == 'APO') {
                next({ name: 'tenants-index' })
            } else {
                next({ name: 'unauthorized-access' })
            }
        }
    }
    if (to.matched.some((route) => route.meta.requiresAuth)) {
        if (localStorage.getItem('access_token')) {
            const secProfile = to.meta.securityGrantID
            const accType = to.meta.accountType
            const merchantOnly = to.meta.merchantOnly

            const userAccountType = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, { format: CryptoJsAesJson }).toString(CryptoJs.enc.Utf8))
                .authentication_data.user.user.user_type
            if (!from.name) {
                await getSecurityGrantsPerSecurityProfile()
                if (localStorage.getItem('access_token') == null) {
                    await router.push({ name: 'signin' })
                }
            }
            if (to.matched.some((route) => route.meta.accountType)) {
                if (accType == userAccountType) {
                    if (to.matched.some((route) => route.meta.securityGrantID)) {
                        if (checkGrants(securityGrants.value, secProfile, merchantOnly)) {
                            /* -- Auto redirect to login if the request is unauthenticated.
                            await getSecurityProfileId()
                             if (localStorage.getItem('access_token') == null) {
                               next({ name: 'signin' })
                             } else {
                               next()
                             } */
                            next()
                        } else {
                            next({ name: 'unauthorized-access' })
                        }
                    } else {
                        next()
                    }
                } else {
                    next({ name: 'unauthorized-access' })
                }
            } else {
                next()
            }
        } else {
            await router.push({ name: 'signin' })
            next()
        }
    } else {
        next()
    }
})
const initialLoad = ref(false)
const user = ref(0)

function checkGrants(securityGrants, securityGrantId, merchantOnly) {
    var status = false
    var securityGrantIds = securityGrantId.split(" ")
    if (merchantOnly == true && (localStorage.getItem('merchant') != null)) {
        status = false
    } else {
        securityGrants.filter(function(elm) {
            securityGrantIds.filter(function(grant) {
                if (elm.sec_grant_id == grant) {
                    status = true
                }
            })

        })
    }

    return status
}

async function getSecurityGrantsPerSecurityProfile() {
    await getSecurityProfileId()
    await axios
        .get('v1/security-grants-per-security-profile/' + securityProfileId.value)
        .then((response) => {
            if (response.data.data != null) {
                securityGrants.value = response.data.data

                let oldSecurityGrants = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, { format: CryptoJsAesJson }).toString(CryptoJs.enc.Utf8))
                oldSecurityGrants.authentication_data.security_grants = response.data.data
                localStorage.setItem('user', CryptoJs.AES.encrypt(
                    JSON.stringify(oldSecurityGrants),
                    process.env.MIX_ENCRYPT_KEY, { format: constant.CryptoJsAesJson }
                ).toString())

            }
        })
}

async function getSecurityProfileId() {
    await store.dispatch('User/getSecurityProfileId')
    if (store.state.User.securityProfileId != null) {
        securityProfileId.value = await store.state.User.securityProfileId.sec_profile_id
    }
}

function getSecurityGrantsFromTheStorage(from) {
    if (
        localStorage.getItem('access_token') &&
        (initialLoad.value == false || from == 'signin')
    ) {
        securityGrants.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, { format: CryptoJsAesJson }).toString(CryptoJs.enc.Utf8)).authentication_data.security_grants
        initialLoad.value = true
    }
}

function checkGrantsIncludesMerchentGrants(securityGrants) {
    var status = false
    const userAccountType = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, { format: CryptoJsAesJson }).toString(CryptoJs.enc.Utf8))
        .authentication_data.user.user.user_type
    securityGrants.filter(function(elm) {
        if (elm.sec_grant_id.match('MCH') && userAccountType == 'TNT') {
            status = true
        }
    })
    return status
}

export default router
