<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div class="h-screen bg-white flex overflow-y-hidden">
      <div class="sm:w-1/2 xl:w-4/12 hidden sm:block relative">
        <div
          class="absolute inset-0 h-full w-full bg-primary bg-opacity-80 z-10"
        />

        <div
          class="absolute inset-0 h-full w-full bg-cover bg-no-repeat bg-center"
          style="background-image: url('/images/auth-cover_sigin-up.jpg')"
        />

        <div
          class="absolute flex flex-1 justify-center items-center inset-0 z-20"
        >
          <div class="w-10/12 xl:w-80 2xl:w-96 mx-auto text-white">
            <logo-white class="inline-block h-10" />
            <div class="mb-10" />
          </div>
        </div>

        <auth-footer />
      </div>

      <div
        class="
          w-full
          sm:w-1/2
          xl:w-8/12
          px-4
          sm:px-6
          md:px-10
          lg:px-20
          xl:px-32
          2xl:px-38
          py-36
          bg-gradient-to-r
          from-white
          to-blue-10
          overflow-y-auto
        "
      >
        <div class="w-full 2xl:w-8/12">
          <div class="my-6">
            <logo class="inline-block sm:hidden h-10" />
            <form
              action="#"
              method="POST"
              @submit.prevent="onSubmit"
            >
              <div>
                <h2 class="my-4 text-2xl font-medium text-black">
                  Add Tenant
                </h2>

                <div class="grid sm:grid-cols-2 gap-4">
                  <div>
                    <form-label for="name">
                      Tenant Name<required />
                    </form-label>
                    <form-input
                      id="name"
                      v-model="form.name"
                      class="mt-1"
                      name="name"
                      type="text"
                      autofocus
                      required
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.tnt_name
                          ? form.errors.validations.tnt_name[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      for="email"
                    >
                      Email Address<required />
                    </form-label>
                    <form-input
                      id="email"
                      v-model="form.email"
                      class="mt-1"
                      name="email"
                      type="email"
                      required
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.email
                          ? form.errors.validations.email[0]
                          : ''
                      "
                    />
                  </div>
                  <div>
                    <form-label for="username">
                      Username<required />
                    </form-label>
                    <form-input
                      id="username"
                      v-model="form.username"
                      class="mt-1"
                      name="email"
                      type="email"
                      required
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.username
                          ? form.errors.validations.username[0]
                          : ''
                      "
                    />
                  </div>

                  <div class="hidden sm:block">
                    <!-- Dummy Grid -->
                  </div>

                  <Button
                    class="mt-4 w-full"
                    type="button"
                    @click="onSubmit"
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import Logo from '@/components/Logo.vue'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import Required from '@/components/Required.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import axios from 'axios'
import Modal from '@/components/Modal'

export default {
  name: 'Tenant',

  components: {
    Logo,
    Button,
    Loading,
    Required,
    LogoWhite,
    FormError,
    FormInput,
    FormLabel,
    AuthFooter,
    Modal,
  },

  setup() {
    const isLoading = ref(false)
    const fullPage = ref(true)
    const form = reactive({
      id: null,
      name: null,
      email: null,
      username: null,
      errors: '',
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    function onSubmit() {
      let SubmitArr = {}
      SubmitArr.tnt_name = form.name
      SubmitArr.email = form.email
      SubmitArr.username = form.username
      axios.post('/v1/new-tenant', SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          modal.confirm_button = false
          modal.button_text = 'Ok'
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = 'Tenant added successfully.'
          modal.show = true
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function closeAlert() {
      modal.show = false
      resetForm()
      return true
    }

    function confirmAlert() {
      modal.show = false
      return true
    }

    function resetForm() {
      form.id = null
      form.name = null
      form.email = null
      form.username = null
      form.errors = ''
    }
    return {
      fullPage,
      isLoading,
      form,
      onSubmit,
      closeAlert,
      confirmAlert,
      modal,
    }
  },
}
</script>
