window._ = require('lodash')
window.axios = require('axios')

import CryptoJs from 'crypto-js'
import constant from "@/constant";

export default {
  namespaced: true,
  state: {
    userInfo: null,
    userLoginInfo: [],
    loginStat: false,
    errors: null,
    securityProfileId: null,
  },
  getters: {
    loggedIn() {
      return localStorage.getItem('access_token') != null
    },
  },
  mutations: {
    userInfoData(state, data) {
      state.userInfo = data
    },

    setErrors(state, data) {
      state.errors = data
    },

    clearErrors(state) {
      state.errors = null
    },

    postLoginUserData(state, data) {
      if (data != null) {
        state.userLoginInfo = data.data
        state.loginStat = data.meta.status
      } else {
        state.loginStat = false
      }
    },

    securityProfileIdData(state, data) {
      state.securityProfileId = data
    },
  },
  actions: {
    checkLoggedInUser({ commit, state }, router) {
      if (state.userInfo) {
        router.push('/dashboard')
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
      }
    },

    async getUserInfo({ commit, state }, data) {
      commit('clearErrors')
      await axios
        .get('/find-user', {
          params: {
            user_name: data,
          },
        })
        .then((response) => {
          if (!response.data.data.errors) {
            commit('userInfoData', response.data.data)
          } else {
            commit('setErrors', response.data.data.errors)
            commit('userInfoData', null)
          }
        })
        .catch((exception) => {
          commit('userInfoData', null)
        })
    },

    async postLoginUser({ commit, state }, data) {
      commit('clearErrors')
      let credentials = {
        username: data.username,
        // password: CryptoJs.AES.encrypt(
        //   process.env.MIX_ENCRYPT_KEY,
        //   data.password
        // ),
      }
      await axios
        .post('login', data)
        .then((response) => {
          if (
            response.data &&
            !response.data.data &&
            response.data.meta.status == 'true'
          ) {
            // localStorage.setItem(
            //   'access_token',
            //   response.data.authentication_data.access_token
            // )

            localStorage.setItem(
              'access_token',
              CryptoJs.AES.encrypt(
                JSON.stringify(response.data.authentication_data.access_token),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
              ).toString()
            )

            localStorage.setItem('user', CryptoJs.AES.encrypt(
                JSON.stringify( response.data),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
            ).toString())

            localStorage.setItem(
              'security_grants',
              response.data.authentication_data.security_grants
            )
            window.axios.defaults.headers.common['Authorization'] =
              'Bearer ' + (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != "" ?  JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('access_token'), process.env.MIX_ENCRYPT_KEY, {format: constant.CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)) : null)
              // 'Bearer ' + localStorage.getItem('access_token')
            commit('postLoginUserData', response.data)
          } else {
            if (response.data.data && response.data.data.errors) {
              commit('setErrors', response.data.data.errors)
            } else {
              let errors = {
                validations: {
                  password: [response.data.meta.status_message],
                },
              }

              commit('setErrors', errors)
            }

            commit('postLoginUserData', null)
          }
        })
        .catch((exception) => {
          commit('postLoginUserData', null)
        })
    },

    async getSecurityProfileId({ commit, state }, data) {
      const $user_uuid = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: constant.CryptoJsAesJson }).toString(CryptoJs.enc.Utf8))
        .authentication_data.user.user.user_uuid

      await axios
        .get('v1/security_profile_by_userUUID/' + $user_uuid)
        .then((response) => {
          if (response.data.error == 'Unauthenticated.') {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            localStorage.removeItem('security_grants')
            localStorage.removeItem('merchant')
            localStorage.removeItem('merchant_user_name')
          } else {
            if (response.data.data != null) {
              commit('securityProfileIdData', response.data.data)
            } else {
              commit('setErrors', response.data.data.errors)
            }
          }


        })
    },
  },
}
