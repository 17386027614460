<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <back-button :route-to="{ name: 'gateways' }" />
          <h1
            id="page-title"
            class="page-title"
          >
            {{ t("Gateway Summary") }}
          </h1>
        </div>

        <Button
          id="button_edit-gateway-summary"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="enableEdit"
        >
          {{ t("Edit") }}
        </Button>
      </div>

      <div class="mt-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-4 break-words">
        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Gateway Provider") }}
            </div>
            <div class="flex flex-wrap items-center gap-x-2">
              <div
                class="mr-1 font-medium text-white text-base break-all w-full "
              >
                {{ gateway.name }}
              </div>
            </div>
          </div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Status") }}
            </div>

            <div class="flex flex-wrap items-center gap-x-2">
              <div
                v-if="gateway.status == 'ACT'"
                class="
                  mt-0.5
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  rounded
                  items-center
                "
              >
                {{ t("Active") }}
              </div>
              <div
                v-else
                class="
                  mt-1
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  items-center
                  rounded
                "
              >
                {{ t("Inactive") }}
              </div>
            </div>
          </div>

          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Device Pair") }}
            </div>
            <div class="flex flex-wrap items-center gap-x-2">
              <div
                v-if="gateway.device_pairing"
                class="
                  mt-0.5
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  items-center
                  rounded
                "
              >
                {{ t("Active") }}
              </div>
              <div
                v-else
                class="
                  mt-1
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  items-center
                  rounded
                "
              >
                {{ t("Inactive") }}
              </div>
            </div>
          </div>
        </div>


        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Business Email") }}
            </div>
            <div class="font-medium text-white text-base">
              {{ gateway.business_email }}
            </div>
          </div>

          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Contact Number") }}
            </div>
            <div class="font-medium text-white text-base">
              {{ gateway.business_phone }}
            </div>
          </div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("JS Payment Support") }}
            </div>

            <div class="flex flex-wrap items-center gap-x-2">
              <div
                v-if="gateway.is_js_support"
                class="
                  mt-0.5
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  items-center
                  text-xs text-white
                  rounded
                "
              >
                {{ t("Active") }}
              </div>
              <div
                v-else
                class="
                  mt-1
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  items-center
                  text-xs text-white
                  rounded
                "
              >
                {{ t("Inactive") }}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Given Name") }}
            </div>
            <div class="font-medium text-white text-base">
              {{ gateway.given_name }}
            </div>
          </div>

          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Last Name") }}
            </div>
            <div class="font-medium text-white text-base">
              {{ gateway.last_name }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Address") }}
          </div>
          <div class="font-medium text-white text-base">
            <div>{{ gateway.address1 }}</div>
            <div>{{ gateway.address2 }}</div>
            <div>{{ gateway.country_name }}</div>
            <div>{{ gateway.state_name }}</div>
            <div>{{ gateway.suburb_name }}</div>
            <div>{{ gateway.postcode_name }}</div>
          </div>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="tab-group">
        <div class="tabs-wrapper">
          <nav
            class="tabs"
            aria-label="Tabs"
          >
            <a
              v-for="(tabItem, index) in gatewayTabItems"
              :key="index"
              href="#"
              class="tab-item"
              :class="{ active: activeGatewayTabId == tabItem.id }"
              @click="showTabContent('activeGatewayTabId', tabItem.id)"
            >{{ tabItem.name }}</a>
          </nav>
        </div>

        <div class="tab-container">
          <div v-if="activeGatewayTabId == 'tab1'">
            <accepted-countries
              :gateway-id="gatewayID"
              :action="action"
            />
          </div>

          <div v-if="activeGatewayTabId == 'tab2'">
            <payment-methods
              :gateway-id="gatewayID"
              :action="action"
            />
          </div>

          <div v-if="activeGatewayTabId == 'tab3'">
            <payment-devices
              :gateway-id="gatewayID"
              :action="action"
            />
          </div>

          <div v-if="activeGatewayTabId == 'tab4'">
            <api-keys
              :gateway-id="gatewayID"
              :action="action"
            />
          </div>

          <div v-if="activeGatewayTabId == 'tab5'">
            <api-configuration
              :gateway-id="gatewayID"
              :action="action"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Gateway Provider") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="gateway">
            {{ t("Gateway") }}  <required />
          </form-label>
          <form-input
            id="gateway"
            v-model="form.gateway"
            class="mt-1"
            name="gateway"
            type="text"
            required
          />
          <form-error
            v-if="errors.name"
            :message="errors.name ? errors.name : ''"
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="status"
            v-model="form.status"
            :items="statusOptions"
            class="mt-1"
            name="status"
          />
          <form-error
            v-if="errors.status"
            :message="errors.status ? errors.status : ''"
          />
        </div>

        <div class="flex items-center">
          <form-checkbox
            :id="is_js_support"
            v-model="form.is_js_support"
            :model-value="form.is_js_support == 1 ? 0 : 1"
            :checked="form.is_js_support == 1"
            @click="getVisibility"
          />
          <form-label
            for="status"
            class="ml-2"
          >
            {{ t("JS Payment Support") }}
          </form-label>
        </div>

        <div class="flex items-center">
          <form-checkbox
            :id="device_pairing"
            v-model="form.device_pairing"
            :model-value="form.device_pairing == 1 ? 0 : 1"
            :checked="form.device_pairing == 1"
          />
          <form-label
            for="status"
            class="ml-2"
          >
            {{ t("Device Pair") }}
          </form-label>
        </div>

        <div v-if="!disableField">
          <form-label for="gw_link">
            {{ t("Gateway type") }}
            <required v-if="!disableField" />
          </form-label>

          <form-select
            id="gw_identity_id"
            v-model="form.gw_identity_id"
            :value="form.gw_identity_id"
            class="mt-1"
            name="gw_identity_id"
            required
            :items="gatewayOptions"
            :disable="disableField"
          />
          <form-error
            v-if="errors.gw_identity_id"
            :message="errors.gw_identity_id ? errors.gw_identity_id : ''"
          />
        </div>
      </div>

      <divider />

      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Primary Contact") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="given_name">
            {{ t("Given Name") }}
          </form-label>
          <form-input
            id="given_name"
            v-model="form.givenName"
            class="mt-1"
            name="given_name"
            type="text"
          />
          <form-error
            v-if="errors.given_name"
            :message="errors.given_name ? errors.given_name : ''"
          />
        </div>

        <div class="w-full">
          <form-label for="last_name">
            {{ t("Last Name") }}
          </form-label>
          <form-input
            id="last_name"
            v-model="form.lastName"
            class="mt-1"
            name="last_name"
            type="text"
          />
          <form-error
            v-if="errors.last_name"
            :message="errors.last_name ? errors.last_name : ''"
          />
        </div>

        <div>
          <form-label
            for="business_email"
          >
            {{ t("Business Email") }}   <required />
          </form-label>
          <form-input
            v-model="form.businessEmail"
            class="mt-1"
            name="business_email"
            type="email"
            required
          />
          <form-error
            v-if="errors.business_email"
            :message="errors.business_email ? errors.business_email : ''"
          />
        </div>

        <div>
          <form-label for="phone">
            {{ t("Business Phone") }} <required />
          </form-label>
          <form-input
            id="phone"
            v-model="form.phoneNumber"
            class="mt-1"
            name="phone"
            type="text"
            required
          />
          <form-error
            v-if="errors.business_phone"
            :message="errors.business_phone ? errors.business_phone : ''"
          />
        </div>
      </div>

      <divider />

      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Address Details") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="country">
            {{ t("Country") }} <required />
          </form-label>
          <form-select
            id="country"
            v-model="form.country"
            :value="form.country"
            class="mt-1"
            name="country"
            required
            :items="countryOptions"
            @onChangeFunction="getState"
          />

          <form-error
            v-if="errors.country"
            :message="errors.country ? errors.country : ''"
          />
        </div>

        <div>
          <form-label for="state">
            {{ t("State") }} <required />
          </form-label>
          <form-select
            id="stateId"
            v-model="form.state"
            class="mt-1"
            name="stateId"
            :disable="stateDisable"
            required
            :items="stateOptions"
            @onChangeFunction="getTown"
          />
          <form-error
            v-if="errors.state"
            :message="errors.state ? errors.state : ''"
          />
        </div>

        <div>
          <form-label for="suburb">
            {{ t("Suburb/Town") }} <required />
          </form-label>
          <form-select
            id="suburbId"
            v-model="form.suburb"
            :disable="suburbDisable"
            class="mt-1"
            name="suburbId"
            required
            :items="townOptions"
            @onChangeFunction="getPostCode"
          />

          <form-error
            v-if="errors.suburb"
            :message="errors.suburb ? errors.suburb : ''"
          />
        </div>

        <div>
          <form-label for="postcode">
            {{ t("Post Code") }} {{ form.postalCode }} <required />
          </form-label>
          <form-select
            id="postcode"
            v-model="form.postalCode"
            :disable="postalCodeDisable"
            :length="postalCodeOptions.length"
            class="mt-1"
            name="postcode"
            required
            :items="postalCodeOptions"
          />
          <form-error
            v-if="errors.postal_code"
            :message="errors.postal_code ? errors.postal_code : ''"
          />
        </div>

        <div>
          <form-label
            for="address_line_1"
          >
            {{ t("Address Line 1") }}   <required />
          </form-label>
          <form-input
            id="address_line_1"
            v-model="form.address1"
            class="mt-1"
            name="address_line_1"
            type="text"
          />
          <form-error
            v-if="errors.address1"
            :message="errors.address1 ? errors.address1 : ''"
          />
        </div>

        <div>
          <form-label for="address_line_2">
            {{ t("Address Line 2") }}
          </form-label>
          <form-input
            id="address_line_2"
            v-model="form.address2"
            class="mt-1"
            name="address_line_2"
            type="text"
          />
          <form-error
            v-if="errors.address2"
            :message="errors.address2 ? errors.address2 : ''"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="editGateway"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import BackButton from '@/components/BackButton.vue'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import AcceptedCountries from '@/components/settings/gateways/AcceptedCountries'
import PaymentMethods from '@/components/settings/gateways/PaymentMethods'
import PaymentDevices from '@/components/settings/gateways/PaymentDevices'
import ApiKeys from '@/components/settings/gateways/ApiKeys'
import ApiConfiguration from '@/components/settings/gateways/ApiConfiguration'
import { useI18n } from 'vue-i18n'
import router from "../../routes";
import FormCheckbox from "@/components/FormCheckbox";
export default {
  name: 'GatewaySummary',
  components: {
    Button,
    Divider,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    PageHeader,
    BackButton,
    AcceptedCountries,
    Modal,
    PaymentMethods,
    PaymentDevices,
    Loading,
    ApiConfiguration,
    ApiKeys,
    FormCheckbox,
  },

  setup() {
    const { t, te, tm } = useI18n()
    const sortColumnDirection = ref('desc')
    let onchange = ref(false)
    const route = useRoute()
    const store = useStore()
    const gateway = ref(0)
    const gatewayID = ref(route.params.slug)
    let countryOptions = ref(0)
    const country = ref('')
    const state = ref('')
    const town = ref('')
    const postCode = ref('')
    let stateOptions = ref(0)
    let townOptions = ref(0)
    let postalCodeOptions = ref(0)
    let stateDisable = ref(true)
    let suburbDisable = ref(true)
    let postalCodeDisable = ref(true)
    const activeSubTab = ref('request')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const action = ref(route.params.action)
    const disableField = ref(null)
    const gatewayOptions = ref(0)
    const form = reactive({
      gateway: null,
      uuid:null,
      status: 'ACT',
      provider: null,
      lastName: null,
      givenName: null,
      businessEmail: null,
      phoneNumber: null,
      country: null,
      state: null,
      suburb: null,
      postalCode: null,
      address1: null,
      address2: null,
      device_pairing: 0,
      is_js_support: 0,
      row_version: 1,
      gw_identity_id : null
    })

    const errors = reactive({
      name: null,
      business_email: null,
      business_phone: null,
      country: null,
      postal_code: null,
      state: null,
      suburb: null,
      address1: null,
      address2: null,
      given_name: null,
      last_name: null,
      status: null,
      gw_identity_id: null
    })

    const statusOptions = ref([
      { value: 'ACT', text: t("Active") },
      { value: 'INA', text: t("Inactive") },
    ])

    const lengthValidationErrorMg = {
      stateError: 'A state is not configured for the selected country. Please contact opuspay.',
      suburbError: 'A suburb is not configured for the selected country/ state. Please contact opuspay..',
      postalCodeError: 'A postcode is not configured for the selected country/state/suburb. Please contact opuspay.',
      identityGatewayError : 'Please select a gateway'
    }

    onMounted(() => {
      getGateway(gatewayID)
    })

    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })

    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: t("Edit Gateway") ,
    })

    const gatewayTabItems = reactive([
      { id: 'tab1', name: t("Accepted Countries") },
      { id: 'tab2', name: t("Payment Method") },
      { id: 'tab3', name: t("Devices") },
      // { id: 'tab4', name: t("API Keys") }, #14724
      { id: 'tab5', name: t("API Configuration") },
    ])

    const activeGatewayTabId = ref(gatewayTabItems[0].id)

    watch (() =>{
      gatewayTabItems[0].name = t("Accepted Countries")
      gatewayTabItems[1].name = t("Payment Method")
      gatewayTabItems[2].name = t("Devices")
      // gatewayTabItems[3].name = t("API Keys") #14724
      gatewayTabItems[3].name = t("API Configuration")
      slideOver.title = t("Edit Gateway")
      statusOptions.value[0].text = t("Active")
      statusOptions.value[1].text = t("Inactive")
    })

    async function getGateway() {
      await store.dispatch('Gateway/viewGateway', gatewayID.value)

      gateway.value = await store.state.Gateway.gatewayGetData.data
      form.gateway = gateway.value.name
      form.status = gateway.value.status
      form.lastName = gateway.value.last_name
      form.givenName = gateway.value.given_name
      form.businessEmail = gateway.value.business_email
      form.phoneNumber = gateway.value.business_phone
      country.value = await gateway.value.country
      state.value = await gateway.value.state
      town.value = await gateway.value.suburb
      postCode.value = await gateway.value.postcode_id
      form.row_version = await gateway.value.row_version
      form.address1 = gateway.value.address1
      form.address2 = gateway.value.address2
      form.device_pairing = gateway.value.device_pairing
      form.is_js_support = gateway.value.is_js_support
      form.uuid = gateway.value.gw_uuid
      disableField.value = form.is_js_support == 1 ? false : true
      form.gw_identity_id = gateway.value.gw_identity_id
    }

    async function editGateway() {
      isLoading.value = true
      let submitForm = {}
      submitForm.id = gatewayID.value
      submitForm.uuid = form.uuid
      submitForm.name = form.gateway
      submitForm.status = form.status
      submitForm.given_name = form.givenName
      submitForm.last_name = form.lastName
      submitForm.business_email = form.businessEmail
      submitForm.business_phone = form.phoneNumber
      submitForm.country = form.country
      submitForm.state = form.state
      submitForm.suburb = form.suburb
      submitForm.postal_code = form.postalCode
      submitForm.address1 = form.address1
      submitForm.address2 = form.address2
      submitForm.device_pairing = form.device_pairing
      submitForm.is_js_support = form.is_js_support
      submitForm.row_version = form.row_version
      submitForm.gw_identity_id = form.gw_identity_id
      resetErrors()
      await store.dispatch('Gateway/updateGateway', submitForm)
      let ReturnData = await store.state.Gateway.gatewayPostData
      if (ReturnData.status == true) {
        isLoading.value = false
        reetForm()
        getGateway(gatewayID)
        modal.type = 'success'
        modal.show = true
        modal.title = 'Success'
        modal.message = 'Gateway details updated successfully.'
        slideOver.show = false
      } else if (ReturnData.status == false) {
        isLoading.value = false
        modal.type = 'error'
        modal.show = true
        modal.title = 'Error'
        modal.message = ReturnData.msg
      } else {
        isLoading.value = false
        bindValidationErrors(ReturnData.data.errors.validations)
      }
    }

    function lengthValidation(data){
      return data.value.length <= 1
    }

    function  dropDownListInitiateBehavior(){
      stateOptions.value = [{value:'',text:'Please select a state'}]
      townOptions.value = [{value:'',text:'Please select a suburb/town'}]
      postalCodeOptions.value = [{value:'',text:'Please select a post code'}]
    }

    async function getCountry(country) {
      await store.dispatch('Country/getCountryList')
      form.state = ''
      form.suburb = ''
      form.postalCode = ''
      countryOptions.value = await store.state.Country.countryListDropDown
      form.country = await country
    }

    async function getState(state) {
      isLoading.value = true
      stateDisable.value = true
      suburbDisable.value = true
      postalCodeDisable.value = true
      resetErrors("state")
      resetErrors("suburb")
      resetErrors("postal_code")
      dropDownListInitiateBehavior()
      getGatewaysAfterClickingJsSupport()
      form.state = null
      form.suburb = null
      form.postalCode = null
      if (form.country != '') {
        await store.dispatch('State/getStateListByCountryID', form.country)
        form.suburb = ''
        form.postalCode = ''
        form.state = null
        stateOptions.value = await store.state.State.stateListDropDown
        stateDisable.value = false
        form.state = await state

        if(lengthValidation(stateOptions) && stateOptions.value[0].value == ''){
          errors.state = lengthValidationErrorMg.stateError
        }

      } else {
        stateDisable.value = true
        suburbDisable.value = true
        postalCodeDisable.value = true
        form.state = ''
        form.suburb = ''
        form.postalCode = ''
      }
      isLoading.value = false
    }

    async function getTown(town) {
      isLoading.value = true
      suburbDisable.value = true
      postalCodeDisable.value = true
      if (form.state != '') {
        await store.dispatch('Suburb/getSuburbListByStateID', {
          countryId: form.country,
          stateId: form.state,
        })
        form.suburb = null
        form.postalCode = null
        townOptions.value = await store.state.Suburb.suburbListDropDown
        suburbDisable.value = false
        form.suburb = await town

        if(lengthValidation(townOptions)){
          errors.suburb = lengthValidationErrorMg.suburbError
        }
      } else {
        suburbDisable.value = true
        postalCodeDisable.value = true
        form.suburb = ''
        form.postalCode = ''
      }
      isLoading.value = false
    }

    async function getPostCode(postCode) {
      isLoading.value = true
      postalCodeDisable.value = true
      if (form.suburb != '') {
        await store.dispatch('PostalCode/getPostalCodeBySuburbID', {
          countryId: form.country,
          stateId: form.state,
          suburbId: form.suburb,
        })
        form.postalCode = null
        postalCodeOptions.value = await store.state.PostalCode
          .postalCodesListDropDown
        postalCodeDisable.value = false
        form.postalCode = await postCode

        if(form.postalCode === undefined && postalCodeOptions.value.length<=2){
          form.postalCode = postalCodeOptions.value[1].value
        }

        if(lengthValidation(postalCodeOptions) ){
          errors.postal_code = lengthValidationErrorMg.postalCodeError
        }

      } else {
        postalCodeDisable.value = true
        form.postalCode = ''
      }
      isLoading.value = false
    }

    function closeAlert() {
      modal.show = false
    }

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    function reetForm() {
      form.gateway = null
      form.status = 'ACT'
      form.provider = null
      form.lastName = null
      form.givenName = null
      form.businessEmail = null
      form.phoneNumber = null
      form.country = null
      form.state = null
      form.suburb = null
      form.postalCode = null
      form.address1 = null
      form.address2 = null
      form.device_pairing = 0
      form.is_js_support = 0
      form.gw_identity_id = null
      disableField.value = true
    }

    function resetErrors(area = 'all') {

      switch (area){
        case "all":
          errors.name = null
          errors.business_email = null
          errors.business_phone = null
          errors.country = null
          errors.postal_code = null
          errors.state = null
          errors.suburb = null
          errors.address1 = null
          errors.address2 = null
          errors.given_name = null
          errors.last_name = null
          errors.status = null
          errors.gw_identity_id = null
          break
        case "state":
          errors.state = null
          break

        case "suburb":
          errors.suburb = null
          break

        case "postal_code":
          errors.postal_code = null
          break

        case "gw_identity_id":
          errors.gw_identity_id = null
          break
      }

      errors.name = null
      errors.business_email = null
      errors.business_phone = null
      errors.country = null
      errors.postal_code = null
      errors.state = null
      errors.suburb = null
      errors.address1 = null
      errors.address2 = null
      errors.given_name = null
      errors.last_name = null
      errors.status = null
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {

          if (form.country){

            if (validationData[prop][0] == "Please select a state." && !stateDisable.value && lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.stateError]
            }

            if (suburbDisable.value && validationData[prop][0] == "Please select a suburb /town." && lengthValidation(townOptions) && lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.suburbError]
            }
            if(postalCodeDisable.value && validationData[prop][0] == "Please select a post code." && lengthValidation(stateOptions) && lengthValidation(townOptions) && lengthValidation(postalCodeOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }

            if (validationData[prop][0] == "Please select a suburb /town." && !suburbDisable.value && lengthValidation(townOptions)){
              validationData[prop] = [lengthValidationErrorMg.suburbError]
            }

            if(!suburbDisable.value && postalCodeDisable.value && validationData[prop][0] == "Please select a post code." && lengthValidation(townOptions) && !lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }

            if(validationData[prop][0] == "Please select a post code." && !postalCodeDisable.value && lengthValidation(postalCodeOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }
            if(validationData[prop][0] == "Please select a gateway." && !disableField.value && lengthValidation(gatewayOptions)){
              validationData[prop] = [lengthValidationErrorMg.identityGatewayError]
            }
          }
          errors[prop] = validationData[prop][0]
        }
      }
    }

    async function enableEdit() {
      isLoading.value = true
      slideOver.show = true
      resetErrors()
      await getCountry(country.value)
      await getState(state.value)
      await getTown(town.value)
      await getPostCode(postCode.value)
      form.gateway = gateway.value.name
      form.status = gateway.value.status
      form.lastName = gateway.value.last_name
      form.givenName = gateway.value.given_name
      form.businessEmail = gateway.value.business_email
      form.phoneNumber = gateway.value.business_phone
      form.address1 = gateway.value.address1
      form.address2 = gateway.value.address2
      form.uuid = gateway.value.gw_uuid
      form.is_js_support = gateway.value.is_js_support
      form.device_pairing = gateway.value.device_pairing
      form.gw_identity_id = gateway.value.gw_identity_id
      disableField.value = form.is_js_support == 1? false : true
      if(!disableField.value) {
        await getGatewaysAfterClickingJsSupport()
      }
      isLoading.value = false
    }

    function focusElement(el) {
      nextTick(() => {
        const focusRef = document.getElementById(el)
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }
    //This function is to check if the user clicked the is_js_support or not
    async function getVisibility() {
      disableField.value = form.is_js_support == 1? true : false
      if(!disableField.value) {
        //If user checked this will get the gateways
        await getGatewaysAfterClickingJsSupport()
      } else {
        form.gw_identity_id = null
        gatewayOptions.value = [{value:'',text:'Please select a gateway'}]
      }
    }

    //This function is to get the gateways when user clicked the JS Support
    async function getGatewaysAfterClickingJsSupport() {
      await store.dispatch('GwIdentity/getGatewayList')
      gatewayOptions.value = await store.state.GwIdentity.GatewayListDropDown
    }

    return {
      form,
      errors,
      gateway,
      gatewayTabItems,
      activeGatewayTabId,
      showTabContent,
      activeSubTab,
      onchange,
      slideOver,
      getTown,
      getState,
      getPostCode,
      townOptions,
      stateOptions,
      countryOptions,
      postalCodeOptions,
      sortColumnDirection,
      gatewayID,
      isLoading,
      fullPage,
      editGateway,
      closeAlert,
      modal,
      statusOptions,
      enableEdit,
      stateDisable,
      suburbDisable,
      postalCodeDisable,
      action,
      t,
      getVisibility,
      getGatewaysAfterClickingJsSupport,
      disableField,
      gatewayOptions
    }
  },
}
</script>
