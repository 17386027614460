import router from "../../../routes";

window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    apiConfigData: null,
    apiConfigPostData: null,
    apiConfigDeleteData: null,
  },

  mutations: {
    getApiConfigListData(state, data) {
      if (data != null) {
        state.apiConfigData = data
      } else {
        state.apiConfigPostData = data
      }
    },
    postApiConfigData(state, data) {
      if (data != null) {
        state.apiConfigPostData = data
      } else {
        state.apiConfigPostData = data
      }
    },
    deleteApiConfigData(state, data) {
      if (data != null) {
        state.apiConfigDeleteData = data
      } else {
        state.apiConfigDeleteData = null
      }
    },
  },
  actions: {
    async getApiConfigList({ commit, state }, searchData) {
      await axios
        .get(
          '/v1/gateway/api-config/list?page=' +
            searchData.page +
            '&gatewayID=' +
            searchData.gw_id +
            '&sortColumn=' +
            searchData.column +
            '&sortDirection=' +
            searchData.sortColumnDirection +
            '&count_per_page=' +
            searchData.perPage +
            '&search=' +
            searchData.searchText
        )
        .then((response) => {
          if (response.data != null) {
            commit('getApiConfigListData', response.data.data)
          } else {
            commit('getApiConfigListData', null)
          }
        })
    },

    async postApiConfig({ commit, state }, apiConfigData) {
      await axios
        .post('v1/gateway/api-config/create', apiConfigData)
        .then((response) => {
          try {
            commit('postApiConfigData', response.data)
          } catch (e) {
            commit('postApiConfigData', response.data)
          }
        })
    },

    async postEditApiConfig({ commit, state }, apiConfigData) {
      await axios
        .post(
          'v1/gateway/api-config/edit/' + apiConfigData.gw_api_rule_id,
          apiConfigData
        )
        .then((response) => {
          try {
            commit('postApiConfigData', response.data)
          } catch (e) {
            commit('postApiConfigData', response.data)
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    },

    async viewApiConfig({ commit, state }, apiConfigId) {
      await axios
        .get('v1/gateway/api-config/view/' + apiConfigId)
        .then((response) => {
          try {
            commit('postApiConfigData', response.data)
          } catch (e) {
            commit('postApiConfigData', response.data)
          }
        })
    },

    async deleteApiConfiguration({ commit, state }, apiConfigID) {
      await axios
        .post('v1/gateway/api-config/delete/' + apiConfigID)
        .then((response) => {
          if (response.data.status == true) {
            commit('deleteApiConfigData', response.data)
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    },

    async changeApiConfigStatus({ commit, state }, apiConfigData) {
      await axios
        .post(
          'v1/gateway/api-config/status-change/' + apiConfigData.gw_api_rule_id,
          apiConfigData
        )
        .then((response) => {
          commit('postApiConfigData', response.data)
        })
    },

    async copyConfiguration({ commit, state }, apiConfigData) {
      await axios
        .post('v1/gateway/api-config/copy/' + apiConfigData)
        .then((response) => {
          commit('postApiConfigData', response.data)
        })
    },
  },
}
