<template>
  <div class="mb-3 flex flex-col">
    <div class="overflow-x-auto">
      <div class="align-middle inline-block min-w-full">
        <div class="overflow-hidden">
          <slot name="table" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableDefault',
}
</script>
