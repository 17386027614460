import router from "../../../routes";

window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    acceptedCountriesData: null,
    acceptedCountriesPostData: null,
    acceptedCountriesDeleteData: null,
  },

  mutations: {
    getGatewayCountriesListData(state, data) {
      if (data != null) {
        state.acceptedCountriesData = data
      } else {
        state.acceptedCountriesData = null
      }
    },
    postGatewayCountriesData(state, data) {
      if (data != null) {
        state.acceptedCountriesPostData = data
      } else {
        state.acceptedCountriesPostData = null
      }
    },
    deleteGatewayCountriesData(state, data) {
      if (data != null) {
        state.acceptedCountriesDeleteData = data
      } else {
        state.acceptedCountriesDeleteData = null
      }
    },
  },
  actions: {
    async getGatewayCountriesList({ commit, state }, searchData) {
      await axios
        .get(
          '/v1/gateway/accepted-countries/list?page=' +
            searchData.page +
            '&gatewayID=' +
            searchData.gw_id +
            '&sortColumn=' +
            searchData.column +
            '&sortDirection=' +
            searchData.sortColumnDirection +
            '&count_per_page=' +
            searchData.perPage +
            '&search=' +
            searchData.searchText
        )
        .then((response) => {
          if (response.data != null) {
            commit('getGatewayCountriesListData', response.data.data)
          } else {
            commit('getGatewayCountriesListData', null)
          }
        })
    },

    async postGatewayCountries({ commit, state }, gatewayCountryData) {
      await axios
        .post('v1/gateway/accepted-countries/create', gatewayCountryData)
        .then((response) => {
          try {
            commit('postGatewayCountriesData', response.data)
          } catch (e) {
            console.log(e)
          }
        })
    },
    async deleteGatewayCountries({ commit, state }, gatewayCountryData) {
      await axios
        .post('v1/gateway/accepted-countries/delete', gatewayCountryData)
        .then((response) => {
          if (response.data.status == true) {
            commit('deleteGatewayCountriesData', response.data)
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    },
  },
}
