<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <h1
        id="page-title"
        class="page-title"
      >
        {{ t("Manage Merchant Accounts") }}
      </h1>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getMerchantDetails()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getMerchantDetails(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Merchant") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('organisation_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'organisation_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'country'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(merchant_data, index) in merchant"
                :id="'manage_merchants_' + index"
                :key="index"
              >
                <td class="max-w-sm">
                  {{ merchant_data.organisation_name }}
                </td>
                <td>{{ merchant_data.country_name }}</td>
                <td class="text-center">
                  <badge
                    v-if="merchant_data.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="merchant_data.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'manage_merchants_view_' + index"
                      type="info"
                      icon="eye"
                      @click="
                        ;(viewSlideOver.show = true),
                          getMerchantDetailsById(merchant_data.mch_id),
                          form.errors =''
                      "
                    />
                    <button-circle
                      :id="'manage_merchants_edit_' + index"
                      type="info"
                      icon="edit"
                      @click.stop="
                        ;(editSlideOver.show = true),
                          getMerchantDetailsById(merchant_data.mch_id),
                          form.errors =''
                      "
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!merchant.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getMerchantDetails($event)"
      />
    </div>
  </div>

  <slide-over
    :show="viewSlideOver.show"
    :slideover-size="viewSlideOver.size"
    :slideover-title="viewSlideOver.title"
    :close-button="viewSlideOver.close_button"
    @closeSlideOver="viewSlideOver.show = false, editValue=false"
  >
    <template #slideover-body>
      <div>
        <div>
          <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
            {{ t("Merchant Details") }}
          </div>
        </div>

        <div class="grid md:grid-cols-2 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant") }}
            </div>
            <div
              class="mt-1 text-black font-medium flex flex-wrap items-center"
            >
              <div class="w-full mb-1">
                <span class="break-all">{{ form.organisationName }}</span>
              </div>
            </div>
          </div>

          
          <div>
            <div class="mt-1 text-black font-medium">
              <div class="w-full">
                <ButtonSecondary
                  v-if="loginAsMerchant"
                  type="button"
                  @click="changeAccountTypeToMerchant(form.organisationName)"
                >
                  {{ t("Login As Merchant") }}
                </ButtonSecondary>
              </div>
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Plan") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.plan }}
            </div>
          </div>


          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Tax Group") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.taxGroup }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Status") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <badge
                v-if="form.status == 'ACT'"
                type="success"
                text="Active"
              />
              <badge
                v-if="form.status == 'INA'"
                type="danger"
                text="Inactive"
              />
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Main Agency Account") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <badge
                v-if="form.act_as_agent == 1"
                type="success"
                text="Yes"
              />
              <badge
                v-if="form.act_as_agent == 0"
                type="danger"
                text="No"
              />
            </div>
          </div>
        </div>

        <divider />

        <div>
          <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
            {{ t("Address Details") }}
          </div>
        </div>

        <div class="grid md:grid-cols-2 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Country") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.country }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("State") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.state }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Suburb/Town") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.suburb }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Post Code") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.postalCode }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Address Line 1") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.address1 }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Address Line 2") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.address2 }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Website URL") }}
            </div>
            <div
              v-if="
                form.websiteUrl != null && form.websiteUrl.length > urlCharCount
              "
              class="mt-1 text-black font-medium"
            >
              <div> {{ form.websiteUrl }}</div>
              <button
                v-if="showFullUrl == true"
                class="btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
                type="button"
                @click="threeDot(form.orgWebsiteUrl, urlCharCount, false)"
              >
                {{ "Hide URL" }}
              </button>
              <button
                v-else
                class="btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
                type="button"
                @click="threeDot(form.orgWebsiteUrl, urlCharCount, true)"
              >
                {{ "Show URL" }}
              </button>
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.websiteUrl }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        id="edit"
        class="btn-wide"
        type="button"
        @click=";(editSlideOver.show = true), (viewSlideOver.show = false), trapFocus(), editValue = false"
      >
        {{ t("Edit") }}
      </Button>
    </template>
  </slide-over>

  <slide-over
    :show="editSlideOver.show"
    :slideover-size="editSlideOver.size"
    :slideover-title="editSlideOver.title"
    :close-button="editSlideOver.close_button"
    @closeSlideOver="editSlideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Merchant Details") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="gateway">
            {{ t("Merchant") }}
          </form-label>
          <form-input
            id="merchant"
            v-model="form.organisationName"
            class="mt-1"
            name="merchant"
            type="text"
            required
            :disabled="true"
          />
        </div>

        <div class="flex flex-col sm:block">
          <form-label class="hidden sm:block">
&nbsp;
          </form-label>
          <ButtonSecondary
            v-if="loginAsMerchant"
            class="mt-1"
            type="button"
            @click="changeAccountTypeToMerchant(form.organisationName)"
          >
            {{ t("Login As Merchant") }}
          </ButtonSecondary>
        </div>

        <div>
          <form-label for="plan">
            {{ t("Plan") }}<required />
          </form-label>
          <form-select
            id="plan"
            v-model="form.planId"
            class="mt-1"
            :items="planOptions"
            name="plan"
            required
            :disable="merchantType"
            @focus="getPlans(form.countryId,form.planId)"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.planId
                ? form.errors.validations.planId[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="tax_group">
            {{ t("Tax Group") }} <required />
          </form-label>
          <form-select
            id="tax_group"
            v-model="form.taxGroupId"
            class="mt-1"
            :items="taxGroupOptions"
            name="tax_group"
            :disable="!manageMerchant"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.taxGroupId
                ? form.errors.validations.taxGroupId[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Status") }} <required />
          </form-label>
          <form-select
            id="status"
            v-model="form.status"
            class="mt-1"
            :items="statusOptions"
            name="status"
            :disable="!manageMerchant"
            required
            @onChangeFunction="checkStateInTaxGroup()"
          />
          <form-error
            v-if="statusError"
            :message="statusErrorMsg"
          />
        </div>

        <div class="flex items-center">
          <div class="mr-2">
            <form-checkbox
              id="act_as_agent"
              v-model="form.act_as_agent"
              :model-value="form.act_as_agent == 1 ? 0 : 1"
              :checked="form.act_as_agent == 1 ? true : false"
            />
          </div>

          <form-label for="tax_group">
            {{ t("Main Agency Account") }}
          </form-label>
        </div>
      </div>

      <divider />

      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Address Details") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="country">
            {{ t("Country") }}
          </form-label>
          <form-select
            id="country"
            v-model="form.countryId"
            class="mt-1"
            :items="countryOptions"
            name="country"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="state">
            {{ t("State") }}
          </form-label>
          <form-select
            id="state"
            v-model="form.stateId"
            class="mt-1"
            :items="stateOptions"
            name="state"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="suburb">
            {{ t("Suburb/Town") }}
          </form-label>
          <form-select
            id="suburb"
            v-model="form.suburbId"
            class="mt-1"
            :items="suburbOptions"
            name="suburb"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="postcode">
            {{ t("Post Code") }}
          </form-label>
          <form-select
            id="postcode"
            v-model="form.postalCodeId"
            class="mt-1"
            :items="postcodeOptions"
            name="postcode"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="address_line_1">
            {{ t("Address Line 1") }}
          </form-label>
          <form-input
            id="address_line_1"
            v-model="form.address1"
            class="mt-1"
            name="address_line_1"
            type="text"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="address_line_2">
            {{ t("Address Line 2") }}
          </form-label>
          <form-input
            id="address_line_2"
            v-model="form.address2"
            class="mt-1"
            name="address_line_2"
            type="text"
            :disabled="true"
          />
        </div>

        <div>
          <form-label for="website_url">
            {{ t("Website URL") }}
          </form-label>
          <form-input
            id="website_url"
            v-model="form.websiteUrl"
            class="mt-1"
            name="website_url"
            type="url"
            :disabled="true"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="warningBeforeEditingMerchantDetails()"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :array="modal.array"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="editMerchantDetails"
  />
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import focusTrap from '@/helpers/focus-trap.js'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import FormError from '@/components/FormError.vue'
import router from "../routes";
import constant from "@/constant";
import CryptoJs from 'crypto-js'
import FormCheckbox from "@/components/FormCheckbox";

export default {
  name: 'Merchants',

  components: {
    Badge,
    Button,
    Search,
    Loading,
    SvgIcon,
    Divider,
    Required,
    SlideOver,
    FormInput,
    FormLabel,
    FormSelect,
    PageHeader,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    Modal,
    FormError,
    FormCheckbox,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const searchText = ref('')
    const searchParam = ref('')
    const statusError = ref(false)
    const statusErrorMsg = ref('')
    const planType = ref(0)
    const merchantType = ref(false)
    const loginAsMerchant = ref(false)
    const manageMerchant = ref(false)
    const lastPage = ref(0)
    const perPage = ref('10')
    const merchant = ref(0)
    const merchant_id = ref(0)
    const totatlRecord = ref(0)
    const currentPage = ref(0)
    const merchantFormData = ref(0)
    const taxGroupOptions = ref([{value: null, text : "Please select tax group"}])
    const countryOptions = ref([])
    const stateOptions = ref([])
    const suburbOptions = ref([])
    const postcodeOptions = ref([])
    const planOptions = ref([{value: null, text : "Please select plan"}])
    const initialLoad = ref(false)
    const statusOptions = ref([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])
    const showFullUrl = ref(false)
    const editValue = ref(false)
    const urlCharCount = ref(constant.urlCharCount)

    const viewSlideOver = reactive({
      show: false,
      size: 'max-w-3xl',
      close_button: false,
      title: 'Merchant Summary',
    })

    const editSlideOver = reactive({
      show: false,
      size: 'max-w-3xl',
      close_button: false,
      title: 'Merchant Summary',
    })

    const form = reactive({
      id: '',
      organisationName: '',
      lastName: '',
      givenName: '',
      businessMail: '',
      phoneNo: '',
      country: '',
      countryId: '',
      state: '',
      stateId: '',
      address1: '',
      address2: '',
      suburb: '',
      suburbId: '',
      postalCode: '',
      postalCodeId: '',
      websiteUrl: '',
      orgWebsiteUrl: '',
      username: '',
      plan: '',
      planId: '',
      taxGroupId: '',
      taxGroup: '',
      errors: '',
      status: '',
      act_as_agent: 0,
    })

    function threeDot(string, count=20, fullText=false) {
      if(fullText) {
        showFullUrl.value = true
        form.websiteUrl =  string
      } else {
        showFullUrl.value = false
        count = urlCharCount.value
        const threeDots = constant.add3Dots(string, count);
        form.websiteUrl = threeDots
      }

    }

    const formEdit = reactive({
      planId: '',
      taxGroupId: '',
      status: '',
      act_as_agent: 0,
      id: '',
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      array: '',
      close_button: true,
    })

    const CryptoJsAesJson = constant.CryptoJsAesJson

    const securityGrants = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
      .authentication_data.security_grants

    function getMerchantDetails(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        searchText.value = search
      }

      axios
        .get('/v1/merchants', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            merchant.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totatlRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    async function getMerchantDetailsById(merchantId) {
      isLoading.value = true
      merchant_id.value = merchantId
      securityGrants.forEach((value) => {
        if (value.sec_grant_id == 'TNT_14') {
          loginAsMerchant.value = true
        }
        if (value.sec_grant_id == 'TNT_17') {
          manageMerchant.value = true
        }
      })

      countryOptions.value = []
      stateOptions.value = []
      suburbOptions.value = []
      postcodeOptions.value = []
      taxGroupOptions.value = [{value: '', text : "Please select tax group"}]
      planOptions.value = [{value: '', text : "Please select plan"}]
      merchantType.value = false
      statusErrorMsg.value = ''

      await axios.get('/v1/merchant/' + merchantId).then((response) => {
        if (response.data != null) {
          isLoading.value = false
          merchantFormData.value = response.data.data
          merchantFormData.value.merchant_plan.type
          if (merchantFormData.value.merchant_plan.plan_type == 1) {
            merchantType.value = true
          }
          form.countryId = merchantFormData.value.country_code
          form.id = merchantFormData.value.mch_id
          form.organisationName = merchantFormData.value.organisation_name
          form.lastName = merchantFormData.value.last_name
          form.givenName = merchantFormData.value.given_name
          form.businessMail = merchantFormData.value.business_email
          form.phoneNo = merchantFormData.value.phone_number
          form.address1 = merchantFormData.value.address1
          form.address2 = merchantFormData.value.address2
          form.websiteUrl = merchantFormData.value.website_url
          form.orgWebsiteUrl = merchantFormData.value.website_url
          form.username = merchantFormData.value.username
          form.status = merchantFormData.value.status
          form.state = merchantFormData.value.state_name
          form.planId = merchantFormData.value.merchant_plan.plan_id
          form.plan = merchantFormData.value.merchant_plan.plan_name
          form.taxGroup = merchantFormData.value.merchant_tax_group.tax_name
          form.stateId = merchantFormData.value.state_id
          form.country = merchantFormData.value.country_name
          form.suburb = merchantFormData.value.suburb_name
          form.suburbId = merchantFormData.value.suburb_id
          form.postalCode = merchantFormData.value.postcode
          form.postalCodeId = merchantFormData.value.postcode_id
          form.act_as_agent = merchantFormData.value.act_as_agent
          form.taxGroupId =
            merchantFormData.value.merchant_tax_group.tax_group_id
          threeDot(form.orgWebsiteUrl)

          let objCountry = {}
          objCountry.text = form.country
          objCountry.value = form.countryId
          countryOptions.value.push(objCountry)

          let objState = {}
          objState.text = form.state
          objState.value = form.stateId
          stateOptions.value.push(objState)

          let objSuburb = {}
          objSuburb.text = form.suburb
          objSuburb.value = form.suburbId
          suburbOptions.value.push(objSuburb)

          let objPostcode = {}
          objPostcode.text = form.postalCode
          objPostcode.value = form.postalCodeId
          postcodeOptions.value.push(objPostcode)

          let objPlan = {}
          objPlan.text = form.plan
          objPlan.value = form.planId
          planOptions.value.push(objPlan)
          initialLoad.value = false

          let obj = {}
          obj.text = form.taxGroup
          obj.value = form.taxGroupId
          taxGroupOptions.value.push(obj)

          getTaxGroups(form.countryId, form.stateId, form.taxGroupId)
        } else {
          console.error('Error!')
        }
      })
    }

    async function getPlans(countryId,plan_id) {
      if (initialLoad.value == false){
        isLoading.value = true
        await axios.get('/v1/get-plans-by-country/' + countryId).then((response) => {
            if (response.data.data != null) {
              response.data.data.find((plan) => {
              if (plan_id != plan.plan_id){
                  let obj = {}
                  obj.text = plan.plan_name
                  obj.value = plan.plan_id
                  planOptions.value.push(obj)
                }
              })
            }
          })
        form.planId = plan_id
        initialLoad.value = true
        isLoading.value = false
      }
    }

    function getAllPlans() {
      isLoading.value = true
      axios
        .get('/v1/plans', {
          params: {
            search: '',
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            isLoading.value = false
            planOptions.value = [{value: null, text : "Please select plan"}]
            response.data.data.data.forEach((plan) => {
              let obj = {}
              obj.text = plan.name
              obj.value = plan.plan_id
              planOptions.value.push(obj)
            })
          }
        })
    }
    function warningBeforeEditingMerchantDetails(){
      if (merchantFormData.value.act_as_agent != form.act_as_agent || merchantFormData.value.status != form.status || merchantFormData.value.merchant_tax_group.tax_group_id != form.taxGroupId || merchantFormData.value.merchant_plan.plan_id != form.planId)   {
        modal.type = 'warning'
        modal.confirm_button = true
        modal.title = 'Warning'
        modal.button_text = 'NO'
        modal.message ='Are you sure you want to save the changes to the merchant account?'
        modal.array = null
        modal.show = true
      }else{
        editMerchantDetails()
      }
    }

    function editMerchantDetails() {
      modal.show = false
      formEdit.planId = form.planId
      formEdit.taxGroupId = form.taxGroupId
      formEdit.status = form.status
      formEdit.act_as_agent = form.act_as_agent
      formEdit.id = form.id
      statusError.value = false
      statusErrorMsg.value = '' 
      editValue.value = false
      form.errors = ''
      if (formEdit.planId != null && formEdit.planId != '') {
        axios.get('/v1/plan/' + formEdit.planId).then((response) => {
            if (response.data.data != null) {
              planType.value = response.data.data.plan_type
              if (planType.value == 1 && formEdit.status == 'INA') {
                statusError.value = true
              }
            }
          })
          .catch(err => {
            router.push({name: 'unauthorized-access'})
          })
      }else {
        form.errors = {
          validations: {
            planId: [
              'Please select plan.',
            ],
          },
        }
      }

      if (statusError.value == true) {
        statusErrorMsg.value =
          'Merchant is linked to a custom plan. Remove merchant from the custom plan before setting status to inactive.'
      } else {
        isLoading.value = true
        editValue.value = false
        axios
          .post('/v1/edit-merchant/' + form.id, formEdit)
          .then((response) => {
            if (response.data != null && !response.data.data.errors) {
              isLoading.value = false
              modal.type = 'success'
              modal.title = 'Success'
              modal.message = 'Merchant details updated successfully.'
              modal.show = true
              modal.array = null
              modal.confirm_button = false
              modal.button_text = 'OK'
              getMerchantDetailsById(form.id)
              editValue.value = true
              viewSlideOver.show = true
            } else {
              isLoading.value = false
              form.errors = response.data.data.errors
              if (form?.errors?.validations?.act_as_agent?.length > 0 && form.errors.validations.act_as_agent[0]) {
                modal.confirm_button = false
                modal.title = 'Warning'
                modal.button_text = 'OK'
                modal.message = form.errors.validations.act_as_agent[0]
                modal.array = form.errors.validations.act_as_agent[1]
                modal.show = true
              }
            }
          })
          .catch(err => {
            router.push({name: 'unauthorized-access'})
          })
      }
    }

    async function checkStateInTaxGroup(){
      if (form.taxGroupId != null && form.taxGroupId != '' && form.status == 'ACT') {
        await axios.get('/v1/check-merchant-state-in-assigned-tax-group/' + form.id).then((response) => {
            if (response.data.data != null && !response.data.data) {
              document.getElementById("tax_group").focus();
              taxGroupOptions.value = [{value: '', text : "Please select tax group"}]
              getTaxGroups(form.countryId, form.stateId, form.taxGroupId)
              form.taxGroupId = ''
            }
          })
          .catch(err => {
            router.push({name: 'unauthorized-access'})
          })
      }
    }

    function getPlanDetailsByPlanId(planId) {}


    function closeAlert() {
      modal.show = false
      if(editValue.value == true){

        editSlideOver.show = false
        viewSlideOver.show = true
      }else{

        form.act_as_agent = merchantFormData.value.act_as_agent 
        form.taxGroupId= merchantFormData.value.merchant_tax_group.tax_group_id 
        form.status= merchantFormData.value.status,
        form.planId=merchantFormData.value.merchant_plan.plan_id 
        editSlideOver.show = true
      }
      getMerchantDetails(currentPage.value)
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getMerchantDetails(1, sortColumn.value, searchText.value)
    }

    function getTaxGroups(country, state, taxGroupId) {
      isLoading.value = true
      axios
        .get('v1/tax-group', {
          params: {
            search: '',
            state: state,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            isLoading.value = false
            response.data.data.data.find((taxGroup) => {
              if (taxGroupId != taxGroup.tax_group_id){
                let obj = {}
                obj.text = taxGroup.tax_name
                obj.value = taxGroup.tax_group_id
                taxGroupOptions.value.push(obj)
              }
            })
          }
        })
    }

    async function loginAsMerchantFunction(){
      await logout()
      const form ={ merchant_id: merchant_id.value }
      // const uuid =
      await axios.post('login-as-a-different-account' ,  form ).then((response) => {

          if (
            response.data &&
            !response.data.data &&
            response.data.meta.status == 'true'
          ) {
            localStorage.setItem(
              'access_token',
              CryptoJs.AES.encrypt(
                JSON.stringify(response.data.authentication_data.access_token),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
              ).toString()
            )
            // localStorage.setItem('user', JSON.stringify(response.data))

          localStorage.setItem('user', CryptoJs.AES.encrypt(
              JSON.stringify( response.data),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
          ).toString())

            localStorage.setItem(
              'security_grants',
              response.data.authentication_data.security_grants
            )
            window.axios.defaults.headers.common['Authorization'] =
              'Bearer ' + response.data.authentication_data.access_token
          }
        })
      await router.push({ name: 'merchant-dashboard' })
      location.reload()
    }

    async function logout() {
      await axios.post('logout').then((response) => {
        localStorage.removeItem('access_token')
      })
    }

    async function changeAccountTypeToMerchant(organization_name){
      let isAuthorizedToChangeAccountType = false

      let form = {}
      form.merchant_id = merchant_id.value
      form.source_acc_type = 'TNT'
      form.destination_acc_type = 'MCH'
      await axios.post('login-as-a-different-account' ,  form ).then((response) => {
        if(response.data != null && response.data.error == null){
          let user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
          user.authentication_data.user.user.user_type ='MCH'
            // localStorage.setItem('user', JSON.stringify(user))

          localStorage.setItem('user', CryptoJs.AES.encrypt(
              JSON.stringify( user),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
          ).toString())

            localStorage.setItem('merchant', JSON.stringify(merchant_id.value))
          localStorage.setItem('merchant_user_organization_name', organization_name)
            isAuthorizedToChangeAccountType = true
          }
        })
      if (isAuthorizedToChangeAccountType == true){
        await router.push({ name: 'merchant-dashboard' })
        location.reload()
      }
      else {
        await router.push({ name: 'unauthorized-access' })
      }
    }

    function trapFocus() {
      var focusLoop = document.getElementById("slideover_focus-loop")
      focusTrap.trapFocus(focusLoop)
    }

    return {
      t,
      modal,
      fullPage,
      isLoading,
      trapFocus,
      viewSlideOver,
      editSlideOver,
      sortColumnDirection,
      sortColumn,
      searchText,
      searchParam,
      lastPage,
      perPage,
      merchant,
      totatlRecord,
      form,
      merchantFormData,
      taxGroupOptions,
      getMerchantDetails,
      editMerchantDetails,
      getMerchantDetailsById,
      closeAlert,
      countryOptions,
      stateOptions,
      suburbOptions,
      postcodeOptions,
      statusOptions,
      planOptions,
      sort,
      getPlans,
      merchantType,
      currentPage,
      securityGrants,
      loginAsMerchant,
      getTaxGroups,
      getAllPlans,
      statusError,
      statusErrorMsg,
      getPlanDetailsByPlanId,
      planType,
      loginAsMerchantFunction,
      changeAccountTypeToMerchant,
      manageMerchant,
      checkStateInTaxGroup,
      threeDot,
      showFullUrl,
      urlCharCount,
      warningBeforeEditingMerchantDetails,
      editValue
    }
  },
}
</script>
