<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <form>
    <div class="flex items-center my-8">
      <form-checkbox
        id="active"
        v-model="form.isMerchantCred"
        name="active"
        :model-value="form.isMerchantCred == 0 ? 1 : 0"
        :checked="form.isMerchantCred == 1 ? true : false"
        :disable="actionBtn != 'edit'"
        autofocus
      />
      <label
        for="active"
        class="ml-2 text-sm"
      >{{ t('Use Merchant Credentials') }}
      </label>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('Merchant Id') }}
          <required />
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiMchId == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiMchId, 'api_mch_id')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_mch_id"
              v-model="form.encrypt_apiMchId"
              name="api_private_key"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiMchId == constantValue
              "
              required
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.api_mch_id
                  ? form.errors.validations.api_mch_id[0]
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Private Key') }}
          <required />
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiPrivateKey == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiPrivateKey, 'api_private_key')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_private_key"
              v-model="form.encrypt_apiPrivateKey"
              name="api_private_key"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiPrivateKey == constantValue
              "
              required
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.api_private_key
                  ? form.errors.validations.api_private_key[0]
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Public Key') }}
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiPublicKey == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiPublicKey, 'api_public_key')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_public_key"
              v-model="form.encrypt_apiPublicKey"
              name="api_public_key"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiPublicKey == constantValue
              "
              required
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.api_public_key
                  ? form.errors.validations.api_public_key[0]
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Key 1') }}
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiKey1 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiKey1, 'key1')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_key1"
              v-model="form.encrypt_apiKey1"
              name="api_key1"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiKey1 == constantValue
              "
              required
            />
            <form-warning
              v-if="form.warnings"
              :message="
                form.warnings.validation.apiKey1
                  ? form.warnings.validation.apiKey1[0]
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Key 2') }}
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiKey2 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiKey2, 'key2')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_key2"
              v-model="form.encrypt_apiKey2"
              name="api_key2"
              type="text"
              :disable="(form.isMerchantCred == 1 || actionBtn != 'edit') || (form.encrypt_apiKey2 == constantValue)"
              required
            />
            <form-warning
              v-if="form.warnings"
              :message="
                form.warnings.validation.apiKey2
                  ? form.warnings.validation.apiKey2[0]
                  : ''
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Key 3') }}
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiKey3 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiKey3, 'key3')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_key3"
              v-model="form.encrypt_apiKey3"
              name="api_key3"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiKey3 == constantValue
              "
              required
            />
          </div>
        </div>
      </div>
      <form-error />
    </div>
    <div class="w-full mb-4">
      <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
        <form-label
          class="whitespace-nowrap w-1/6"
          for="security_profile"
        >
          {{ t('API Key 4') }}
        </form-label>

        <div class="mt-1 lg:w-2/6 w-full">
          <div class="relative">
            <button
              v-if="form.encrypt_apiKey4 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptApiKeyData(form.apiKey4, 'key4')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api_key4"
              v-model="form.encrypt_apiKey4"
              name="api_key4"
              type="text"
              :disable="
                form.isMerchantCred == 1 ||
                  actionBtn != 'edit' ||
                  form.encrypt_apiKey4 == constantValue
              "
              required
            />
          </div>
        </div>
      </div>
      <form-error />
    </div>
  </form>
  <div class="w-full mb-4">
    <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
      <div class="whitespace-nowrap w-1/6" />
      <div class="mt-1 lg:w-2/6 w-full">
        <Button
          class="btn-wide ml-auto"
          type="button"
          :disabled="actionBtn != 'edit'"
          @click="onSubmit()"
        >
          {{ t('Save') }}
        </Button>
      </div>
    </div>
  </div>
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>

<script>
import {ref, reactive, watchEffect, watch} from 'vue'
import Button from '@/components/Button.vue'
import FormError from '@/components/FormError.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import Required from '@/components/Required.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import router from "../../../routes";
import { useI18n } from 'vue-i18n'
import FormWarning from "@/components/FormWarning";
import constant from "@/constant";

export default {
  name: 'PaymentMethods',

  components: {
    FormWarning,
    Button,
    FormError,
    FormLabel,
    FormInput,
    FormCheckbox,
    Modal,
    Required,
    Loading,
  },
  props: {
    gatewayId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: 'view',
    },
  },

  setup(props) {
    const { t, te, tm } = useI18n()
    let onchange = ref(true)
    let currencyOptions = ref(0)
    const gatewayID = ref(props.gatewayId)

    const paymentMethodOptions = [
      { value: '', text: 'Please select a payment method' },
    ]
    const actionBtn = ref(props.action)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const lastPage = ref(0)
    const totalRecord = ref(0)
    const perPage = ref(10)
    const paymentMethods = ref(0)
    const showAddNewPaymentMethodRow = ref(false)
    const constantValue = constant.encryptionKey

    const form = reactive({
      gwId: props.gatewayId,
      isMerchantCred :0,
      apiMchId: null,
      apiPrivateKey: null,
      apiPublicKey: null,
      apiKey1: null,
      apiKey2: null,
      apiKey3: null,
      apiKey4: null,
      encrypt_apiMchId: '',
      encrypt_apiPrivateKey: '',
      encrypt_apiPublicKey: '',
      encrypt_apiKey1: '',
      encrypt_apiKey2: '',
      encrypt_apiKey3: '',
      encrypt_apiKey4: '',
      row_version: null,
      errors: null,
      warnings: null,
    });
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })
    const isDataLoaded = ref(false)

    const decryptApiKeyDataForm = reactive({
      data: '',
    })

    watchEffect(() => {
      if (props.action != actionBtn.value) {
        actionBtn.value = props.action
      }
    })

    watch(() => {
      if(form.isMerchantCred == 1){
        resetErrors()
       form.warnings = null
      }
      if(form.isMerchantCred == 0 && isDataLoaded.value) {
        if ((form.apiKey1 == null || form.apiKey1 == '') && (form.apiKey2 == null || form.apiKey2 == '')) {
          form.warnings = {
            validation: {
              apiKey1: ['First key has not been entered. Please enter a key value if required by the gateway.'],
              apiKey2: ['Second key has not been entered. Please enter a key value if required by the gateway.']
            }
          }
        }else if (form.apiKey1 == null || form.apiKey1 == '') {
            form.warnings = {
              validation: {
                apiKey1: ['First key has not been entered. Please enter a key value if required by the gateway.']
              }
            }
        }else if (form.apiKey2 == null || form.apiKey2 == '') {
            form.warnings = {
              validation: {
                apiKey2: ['Second key has not been entered. Please enter a key value if required by the gateway.']
              }
            }
        }else {
          form.warnings = null
        }

      }
    })

    getGatewayApiKeys()

    function closeAlert() {
      modal.show = false
      if (modal.button_text !== 'No') {
        modal.show = false
      }
    }

    function getGatewayApiKeys() {
      isLoading.value = true
      axios
        .get('/v1/gateway/api-keys/view/' + form.gwId)
        .then((response) => {
          if (response.data != null && response.data.data != null)  {
            form.isMerchantCred = response.data.data.is_merchant_cred_used
            form.apiMchId = response.data.data.api_mch_id
            form.apiPrivateKey = response.data.data.api_private_key
            form.apiPublicKey = response.data.data.api_public_key
            form.apiKey1 = response.data.data.api_key1
            form.apiKey2 = response.data.data.api_key2
            form.apiKey3 = response.data.data.api_key3
            form.apiKey4 = response.data.data.api_key4

            form.encrypt_apiMchId = form.apiMchId ? constant.encryptionKey : ''
            form.encrypt_apiPrivateKey = form.apiPrivateKey ? constant.encryptionKey : ''
            form.encrypt_apiPublicKey = form.apiPublicKey ? constant.encryptionKey : ''
            form.encrypt_apiKey1 = form.apiKey1 ? constant.encryptionKey : ''
            form.encrypt_apiKey2 = form.apiKey2 ? constant.encryptionKey : ''
            form.encrypt_apiKey3 = form.apiKey3 ? constant.encryptionKey : ''
            form.encrypt_apiKey4 = form.apiKey4 ? constant.encryptionKey : ''
            form.row_version = response.data.data.row_version
            isDataLoaded.value = true
          }
          isLoading.value = false
        })
    }

    function onSubmit() {
      isLoading.value = true
      let SubmitArr = {}
      SubmitArr.gw_id = form.gwId

      SubmitArr.is_merchant_cred_used = form.isMerchantCred
      SubmitArr.api_mch_id = form.encrypt_apiMchId == constantValue ? form.apiMchId : form.encrypt_apiMchId
      SubmitArr.api_private_key = form.encrypt_apiPrivateKey == constantValue ? form.apiPrivateKey : form.encrypt_apiPrivateKey
      SubmitArr.api_public_key = form.encrypt_apiPublicKey == constantValue ? form.apiPublicKey : form.encrypt_apiPublicKey
      SubmitArr.api_key1 = form.encrypt_apiKey1 == constantValue ? form.apiKey1 : form.encrypt_apiKey1
      SubmitArr.api_key2 = form.encrypt_apiKey2 == constantValue ? form.apiKey2 : form.encrypt_apiKey2
      SubmitArr.api_key3 = form.encrypt_apiKey3 == constantValue ? form.apiKey3 : form.encrypt_apiKey3
      SubmitArr.api_key4 = form.encrypt_apiKey4 == constantValue ? form.apiKey4 : form.encrypt_apiKey4
      SubmitArr.encrypt_api_mch_id = form.encrypt_apiMchId
      SubmitArr.encrypt_api_private_key = form.encrypt_apiPrivateKey
      SubmitArr.encrypt_api_public_key = form.encrypt_apiPublicKey
      SubmitArr.encrypt_api_key1 = form.encrypt_apiKey1
      SubmitArr.encrypt_api_key2 = form.encrypt_apiKey2
      SubmitArr.encrypt_api_key3 = form.encrypt_apiKey3
      SubmitArr.encrypt_api_key4 = form.encrypt_apiKey4
      SubmitArr.row_version = form.row_version

      form.errors = null

      axios.post('v1/gateway/api-keys/create', SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
            modal.confirm_button = false
            modal.close_button = true
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'API Keys updated successfully.'
            modal.show = true
            getGatewayApiKeys()
        } else {
          form.errors = response.data.data.errors
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function resetForm(){
      form.api_mch_id = null
      form.apiPrivateKey = null
      form.apiPublicKey = null
      form.apiKey1 = null
      form.apiKey2 = null
      form.apiKey3 = null
      form.apiKey4 = null
      form.encrypt_apiPrivateKey = null
      form.encrypt_apiPublicKey = null
      form.encrypt_apiKey1 = null
      form.encrypt_apiKey2 = null
      form.encrypt_apiKey3 = null
      form.encrypt_apiKey4 = null
    }


    function decryptApiKeyData(data, value){
      decryptApiKeyDataForm.data = data
      isLoading.value = true
      axios.post('/v1/decrypt-data', decryptApiKeyDataForm).then((response) => {
        if (response.data != null) {
          isLoading.value = false
          if(value == 'api_mch_id'){
            form.encrypt_apiMchId = response.data
          }else if(value == 'api_private_key'){
            form.encrypt_apiPrivateKey = response.data
          }
          else if(value == 'api_public_key'){
            form.encrypt_apiPublicKey = response.data
          }
          else if(value == 'key1'){
            form.encrypt_apiKey1 = response.data
          }
          else if(value == 'key2'){
            form.encrypt_apiKey2 = response.data
          }
          else if(value == 'key3'){
            form.encrypt_apiKey3 = response.data
          }
          else if(value == 'key4'){
            form.encrypt_apiKey4 = response.data
          }
        } else {
          console.error('Error!')
          isLoading.value = false
        }
      })
    }


    function resetErrors(){
      form.errors = null
    }

    return {
      onchange,
      currencyOptions,
      gatewayID,
      isLoading,
      fullPage,
      perPage,
      totalRecord,
      lastPage,
      modal,
      closeAlert,
      paymentMethodOptions,
      showAddNewPaymentMethodRow,
      paymentMethods,
      actionBtn,
      form,
      onSubmit,
      decryptApiKeyData,
      decryptApiKeyDataForm,
      constantValue,
      resetErrors,
      isDataLoaded,
      t,
    }
  },
}
</script>
