<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 px-4 sm:px-6 lg:px-8 py-8 sm:py-20 2xl:py-16 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div
        :class="[ tokenExpired ? 'flex flex-col justify-center sm:max-w-3xl' : 'sm:max-w-6xl' ]"
        class="w-full"
      >
        <div>
          <logo-white
            id="logo-white"
            class="inline-block h-14 mb-6"
          />
        </div>

        <div
          class="sm:mb-14 p-10 sm:p-16 md:px-44 md:py-14 2xl:py-16 bg-primary-lightest rounded-lg"
        >
          <div>
            <div
              v-if="tokenExpired"
              class="w-full xl:w-96 mx-auto xl:ml-10 xl:mr-auto"
            >
              <div>
                <h2 class="mt-6 mb-3 text-2xl font-medium text-black">
                  Unable to register
                </h2>
                <h5 class="text-black text-opacity-70 font-medium">
                  Your account registration token has expired.
                </h5>
              </div>

              <div class="mt-8">
                <div class="mt-6">
                  <Button
                    v-if="tokenExpired && source=='WEB_REGISTRATION'"
                    class="btn-wide"
                    type="submit"
                    @click="$router.push({ name: 'signup' })"
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>

            <form
              v-if="!tokenExpired"
              action="#"
              method="POST"
              @submit.prevent="onSubmit"
            >
              <div v-if="!stepTwoVisible">
                <div class="mb-4 flex flex-wrap items-end justify-between">
                  <h2
                    id="heading-step1"
                    class="text-2xl font-medium text-black"
                  >
                    Hello! Welcome to opuspay
                  </h2>
                </div>

                <div
                  class="mb-6 font-semibold text-black text-opacity-80 text-lg"
                >
                  Organisation Details
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <div>
                    <form-label
                      id="label-org_name"
                      for="org_name"
                    >
                      Name of the Organisation <required />
                    </form-label>
                    <form-input
                      id="org_name"
                      v-model="form.organizationName"
                      class="mt-1 border-primary-lighter"
                      name="org_name"
                      type="text"
                      autofocus
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />

                    <form-error
                      v-if="errors.organisation_name"
                      :message="
                        errors.organisation_name ? errors.organisation_name : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-website"
                      for="website_url"
                    >
                      Website URL
                    </form-label>
                    <form-input
                      id="website_url"
                      v-model="form.websiteUrl"
                      class="mt-1 border-primary-lighter"
                      name="website_url"
                      type="url"
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />
                    <form-error
                      v-if="errors.website_url"
                      :message="errors.website_url ? errors.website_url : ''"
                    />
                  </div>
                </div>

                <divider class="my-10" />

                <div
                  class="mb-6 font-semibold text-black text-opacity-80 text-lg"
                >
                  Primary Contact
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <div>
                    <form-label
                      id="label-given_name"
                      for="given_name"
                    >
                      Given Name <required />
                    </form-label>
                    <form-input
                      id="given_name"
                      v-model="form.givenName"
                      class="mt-1 border-primary-lighter"
                      name="given_name"
                      type="text"
                      required
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />
                    <form-error
                      v-if="errors.given_name"
                      :message="errors.given_name ? errors.given_name : ''"
                    />
                  </div>

                  <div class="w-full">
                    <form-label
                      id="label-last_name"
                      for="last_name"
                    >
                      Last Name <required />
                    </form-label>
                    <form-input
                      id="last_name"
                      v-model="form.lastName"
                      class="mt-1 border-primary-lighter"
                      name="last_name"
                      type="text"
                      required
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />
                    <form-error
                      v-if="errors.last_name"
                      :message="errors.last_name ? errors.last_name : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-email"
                      for="business_email"
                    >
                      Business Email <required />
                    </form-label>
                    <form-input
                      id="business-email"
                      v-model="form.businessEmail"
                      class="mt-1 border-primary-lighter"
                      name="business_email"
                      type="text"
                      required
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />
                    <form-error
                      v-if="errors.business_email"
                      :message="
                        errors.business_email ? errors.business_email : ''
                      "
                    />
                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.fname_lname_email
                          ? form.errors.validations.fname_lname_email[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-phone"
                      for="phone"
                    >
                      Phone Number <required />
                    </form-label>
                    <form-input
                      id="phone"
                      v-model="form.phoneNumber"
                      class="mt-1 border-primary-lighter"
                      name="phone"
                      type="text"
                      required
                      :disabled="disableValue == true"
                      @keypress.enter="checkSetupTwoVisible"
                    />
                    <form-error
                      v-if="errors.phone_number"
                      :message="errors.phone_number ? errors.phone_number : ''"
                    />
                  </div>

                  <div class="hidden lg:block">
                    <!-- Dummy Grid -->
                  </div>

                  <Button
                    id="button-next"
                    class="w-full"
                    type="button"
                    :disabled="form.organizationName == null ||
                      form.givenName == null ||
                      form.lastName == null ||
                      form.businessEmail == null ||
                      form.phoneNumber == null ||
                      form.organizationName == '' ||
                      form.givenName == '' ||
                      form.lastName == '' ||
                      form.businessEmail == '' ||
                      form.phoneNumber == ''"
                    @click="checkSetupTwoVisible"
                  >
                    Next
                  </Button>
                </div>
              </div>

              <div v-if="stepTwoVisible">
                <div class="my-4 flex flex-wrap items-end justify-between">
                  <h2
                    id="heading-step2"
                    class="text-2xl font-medium text-black"
                  >
                    Hello! Welcome to opuspay
                  </h2>
                </div>

                <div
                  class="mb-6 font-semibold text-black text-opacity-80 text-lg"
                >
                  <span>Address Details</span>
                  <tippy placement="right">
                    <svg-icon
                      class="-mt-0.5 ml-2 inline-block w-7 h-7 text-primary"
                      icon="info-circle"
                    />
                    <template
                      #content
                    >
                      After entering address information, click on the desired
                      option from the drop down list.
                    </template>
                  </tippy>
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <div>
                    <form-label
                      id="label-country"
                      for="country"
                    >
                      Country <required />
                    </form-label>
                    <form-select
                      id="country"
                      v-model="form.country"
                      :change="onchange"
                      class="mt-1"
                      name="country"
                      autofocus
                      :items="countryOptions"
                      @onChangeFunction="getState()"
                    />
                    <form-error
                      v-if="errors.country_code && errorFormatIndex == 2"
                      :message="errors.country_code ? errors.country_code : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-state"
                      for="state"
                    >
                      State <required />
                    </form-label>
                    <form-select
                      id="state"
                      v-model="form.state"
                      :items="stateOptions"
                      :disable="stateDisable"
                      class="mt-1"
                      name="state"
                      @onChangeFunction="getSuburb()"
                    />
                    <form-error
                      v-if="errors.state_id && errorFormatIndex == 3"
                      :message="errors.state_id ? errors.state_id : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-town"
                      for="suburb"
                    >
                      Suburb/Town <required />
                    </form-label>
                    <form-select
                      id="suburb"
                      v-model="form.suburb"
                      :disable="suburbDisable"
                      class="mt-1"
                      name="suburb"
                      :items="townOptions"
                      @onChangeFunction="getPostCode()"
                    />

                    <form-error
                      v-if="errors.suburb_id && errorFormatIndex == 4"
                      :message="errors.suburb_id ? errors.suburb_id : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-postcode"
                      for="postcode"
                    >
                      Post Code <required />
                    </form-label>
                    <form-select
                      id="postcode"
                      v-model="form.postalCode"
                      :disable="postalCodeDisale"
                      class="mt-1"
                      name="postcode"
                      :items="postalCodeOptions"
                    />
                    <form-error
                      v-if="errors.postcode_id && errorFormatIndex == 5"
                      :message="errors.postcode_id ? errors.postcode_id : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-address_1"
                      for="address_line_1"
                    >
                      Address Line 1 <required />
                    </form-label>
                    <form-input
                      id="address_line_1"
                      v-model="form.address1"
                      class="mt-1 border-primary-lighter"
                      name="address_line_1"
                      type="text"
                      @keypress.enter="onSubmit"
                    />
                    <form-error
                      v-if="errors.address_line_1 && errorFormatIndex == 6"
                      :message="
                        errors.address_line_1 ? errors.address_line_1 : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-address_2"
                      for="address_line_2"
                    >
                      Address Line 2
                    </form-label>
                    <form-input
                      id="address_line_2"
                      v-model="form.address2"
                      class="mt-1 border-primary-lighter"
                      name="address_line_2"
                      type="text"
                      @keypress.enter="onSubmit"
                    />
                    <form-error
                      v-if="errors.address_line_2 && errorFormatIndex == 7"
                      :message="
                        errors.address_line_2 ? errors.address_line_2 : ''
                      "
                    />
                  </div>
                </div>

                <divider class="my-10" />

                <div
                  class="mb-6 font-semibold text-black text-opacity-80 text-lg"
                >
                  Login Details
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <div>
                    <form-label
                      id="label-username"
                      for="username"
                    >
                      Username <required />
                    </form-label>
                    <form-input
                      id="username"
                      v-model="form.username"
                      class="mt-1 border-primary-lighter"
                      name="username"
                      type="text"
                      @keypress.enter="onSubmit"
                    />
                    <form-error
                      v-if="errors.username && errorFormatIndex == 8"
                      :message="errors.username ? errors.username : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-email"
                      for="email"
                    >
                      Login Email <required />
                    </form-label>
                    <form-input
                      id="email"
                      v-model="form.loginEmail"
                      class="mt-1 border-primary-lighter"
                      name="email"
                      type="text"
                      @keypress.enter="onSubmit"
                    />
                    <form-error
                      v-if="errors.login_email && errorFormatIndex == 9"
                      :message="errors.login_email ? errors.login_email : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-password"
                      for="password"
                    >
                      Password <required />
                    </form-label>
                    <div class="mt-1 relative">
                      <form-input
                        id="password"
                        v-model="form.password"
                        :name="form.passwordFieldType"
                        :type="form.passwordFieldType"
                        class="pr-10 border-primary-lighter"
                        @keypress.enter="onSubmit"
                      />
                      <button
                        class="
                          absolute
                          w-7
                          h-7
                          inset-y-0
                          right-0
                          flex
                          items-center
                          justify-center
                          my-auto
                          mr-1
                          p-1
                          rounded-sm
                          opacity-75
                          hover:opacity-100
                          focus:ring-2 focus:ring-offset-1 focus:ring-primary
                          focus:outline-none
                          z-10
                        "
                        type="button"
                        @click="switchPasswordVisibility"
                      >
                        <svg-icon
                          v-if="form.passwordFieldType === 'text'"
                          class="w-5 h-5 text-gray-900"
                          icon="visible"
                        />
                        <svg-icon
                          v-if="form.passwordFieldType === 'password'"
                          class="w-5 h-5 text-gray-900"
                          icon="invisible"
                        />
                      </button>
                    </div>
                    <form-error
                      v-if="errors.password && errorFormatIndex == 10"
                      :message="errors.password ? errors.password : ''"
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-confirm_password"
                      for="c_password"
                    >
                      Confirm Password <required />
                    </form-label>
                    <div class="mt-1 relative">
                      <form-input
                        id="c_password"
                        v-model="form.confirmPassword"
                        class="pr-10 border-primary-lighter"
                        :name="form.passwordFieldType"
                        :type="form.passwordFieldType"
                        @keypress.enter="onSubmit"
                      />
                      <button
                        class="
                          absolute
                          w-7
                          h-7
                          inset-y-0
                          right-0
                          flex
                          items-center
                          justify-center
                          my-auto
                          mr-1
                          p-1
                          rounded-sm
                          opacity-75
                          hover:opacity-100
                          focus:ring-2 focus:ring-offset-1 focus:ring-primary
                          focus:outline-none
                          z-10
                        "
                        type="button"
                        @click="switchPasswordVisibility"
                      >
                        <svg-icon
                          v-if="form.passwordFieldType === 'text'"
                          class="w-5 h-5 text-gray-900"
                          icon="visible"
                        />
                        <svg-icon
                          v-if="form.passwordFieldType === 'password'"
                          class="w-5 h-5 text-gray-900"
                          icon="invisible"
                        />
                      </button>
                    </div>
                    <form-error
                      v-if="errors.confirm_password && errorFormatIndex == 11"
                      :message="
                        errors.confirm_password ? errors.confirm_password : ''
                      "
                    />
                  </div>
                </div>

                <div>
                  <div class="py-6 flex items-center">
                    <form-checkbox
                      id="terms_check"
                      name="terms_check"
                      :checked="form.acceptTermsAndConditions != null"
                      value="1"
                      @click="termsAndCondition()"
                    />
                    <label
                      class="ml-2 text-sm"
                      for="terms_check"
                    >
                      By clicking here to signup, you're agreeing to our
                      <button
                        id="button-agree_terms"
                        class="
                          mr-0.5
                          underline
                          hover:text-primary
                          focus:ring-2 focus:ring-offset-1 focus:ring-primary
                          focus:outline-none
                          rounded-sm
                        "
                        type="button"
                        @click="modalTerms.show = true"
                      >
                        Terms &amp; Conditions
                      </button>
                      <required />
                    </label>
                  </div>
                  <form-error
                    v-if="errors.accept_terms_and_conditions && errorFormatIndex == 12"
                    class="-mt-3 mb-4"
                    :message="
                      errors.accept_terms_and_conditions
                        ? errors.accept_terms_and_conditions
                        : ''
                    "
                  />
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <ButtonSecondary
                    id="button-back"
                    class="w-full"
                    type="button"
                    @click="stepTwoVisible = !stepTwoVisible"
                  >
                    Back
                  </ButtonSecondary>

                  <Button
                    id="button-submit"
                    class="w-full"
                    type="submit"
                    :disabled="form.country == null ||
                      form.state == null ||
                      form.suburb == null ||
                      form.postalCode == null ||
                      form.address1 == null ||
                      form.username == null ||
                      form.loginEmail == null ||
                      form.password == null ||
                      form.confirmPassword == null ||
                      form.acceptTermsAndConditions == null ||
                      form.country == '' ||
                      form.state == '' ||
                      form.suburb == '' ||
                      form.postalCode == '' ||
                      form.username == '' ||
                      form.loginEmail == '' ||
                      form.password == '' ||
                      form.confirmPassword == '' ||
                      form.acceptTermsAndConditions == '' ||
                      form.address1 == ''"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />

  <modal
    :show="regSuccessModal.show"
    :close-button="regSuccessModal.close_button"
    :confirm-button="regSuccessModal.confirmButton"
    :confirm-button-text="regSuccessModal.confirmButtonText"
    @confirmModal="confirmAlert"
  >
    <template #modal-body>
      <div class="mb-5 flex items-center justify-center">
        <logo
          id="logo"
          class="h-10"
        />
      </div>

      <h3 class="text-xl font-medium leading-6 text-black text-center text-opacity-90">
        Thank You!
      </h3>

      <div class="my-4 text-sm text-center text-gray-500">
        <p>Your request has been submitted. Within a few moments you will receive an email to confirm that we have received your request.</p>
      </div>
    </template>
  </modal>

  <modal
    :type="warningModal.type"
    :show="warningModal.show"
    :title="warningModal.title"
    :message="warningModal.message"
    :close-button="warningModal.close_button"
    :button-text="warningModal.button_text"
    @closeModal="closeWariningModal"
  />

  <modal-form
    :show="modalTerms.show"
    :title="modalTerms.title"
    :modal-size="modalTerms.size"
    :close-button="modalTerms.close_button"
    :confirm-button="modalTerms.confirm_button"
    @closeModal="modalTerms.show = false"
  >
    <template #modal-body>
      <div
        class="tinymce-wrapper space-y-4"
        style="min-height: calc(100vh - 230px);"
      >
        <editor
          v-model="termsAndConditionDescription"
          :disabled="true"
          :api-key="tinymce_api_key"
          :init="editorOptions"
          menubar="false"
          menu="false"
          toolbar="false"
        />
      </div>
    </template>
  </modal-form>
</template>

<script>
import { ref, reactive } from 'vue'
import { Tippy } from 'vue-tippy'
import Logo from '@/components/Logo.vue'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import ModalForm from '@/components/ModalForm.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormCheckbox from '@/components/FormCheckbox'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import { useRoute } from 'vue-router'
import tinymceConfig from '@/plugins/tinymce-config.js'
import Editor from '@tinymce/tinymce-vue'
import axios from "axios";

export default {
  name: 'RegistrationDetails',

  components: {
    Logo,
    Modal,
    Tippy,
    Button,
    SvgIcon,
    Loading,
    Divider,
    Required,
    ModalForm,
    LogoWhite,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    AuthFooter,
    FormCheckbox,
    ButtonSecondary,
    editor: Editor,
  },

  setup() {
    let stepTwoVisible = ref(false)
    let onchange = ref(true)
    let disableValue = ref(false)
    let stateDisable = ref(true)
    let suburbDisable = ref(true)
    let postalCodeDisale = ref(true)
    let checkedTermAndConditionValue = ref(false)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const route = useRoute()
    const tokenExpired = ref(false)
    const termsAndConditionDescription = ref('')
    const editorOptions = reactive(tinymceConfig.viewOnModalSettings)
    const form = reactive({
      id: null,
      organizationName: null,
      websiteUrl: null,
      lastName: null,
      givenName: null,
      businessEmail: null,
      phoneNumber: null,
      country: null,
      state: null,
      suburb: null,
      postalCode: null,
      address1: null,
      address2: null,
      username: null,
      loginEmail: null,
      password: null,
      confirmPassword: null,
      acceptTermsAndConditions: null,
      token: route.params.token,
      passwordFieldType: 'password',
    })
    const addressData = reactive({
      country: null,
      state: null,
      suburb: null,
      postalCode: null,
    })

    const errors = reactive({
      organisation_name: null,
      website_url: null,
      last_name: null,
      given_name: null,
      business_email: null,
      phone_number: null,
      country_code: null,
      state_id: null,
      suburb_id: null,
      postcode_id: null,
      address_line_1: null,
      address_line_2: null,
      username: null,
      login_email: null,
      password: null,
      confirm_password: null,
      accept_terms_and_conditions: null,
      errors: ''
    })

    const errorFormat = reactive({
      country_code: null,
      state_id: null,
      suburb_id: null,
      postcode_id: null,
      address_line_1: null,
      address_line_2: null,
      username: null,
      login_email: null,
      password: null,
      confirm_password: null,
      accept_terms_and_conditions: null,
    })

    const errorFormatIndex = ref(0)
    const countryOptions = ref([{ value: '', text: 'Select country' }])
    const stateOptions = ref([{ value: '', text: 'Select state' }])
    const townOptions = ref([{ value: '', text: 'Select town' }])
    const postalCodeOptions = ref([{ value: '', text: 'Select post code' }])

    const modal = reactive({
      type: '',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })

    const regSuccessModal = reactive({
      show: false,
      close_button: false,
      confirmButton: true,
      confirmButtonText: 'Visit OpusXenta',
    })

    const warningModal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const modalTerms = reactive({
      show: false,
      size: 'max-w-6xl',
      title: 'Terms and Conditions',
      close_button: false,
      confirm_button: false,
    })

    const params = new URLSearchParams(window.location.search)
    const targetTnt = params.get('tgt_tnt')
    const token = route.params.token
    const source = ref('')

    checkTokenValid()

    function checkTokenValid() {
      axios.get('v1/registration-token-validate/' + token).then((response) => {
        if (response.data.data != null) {
          source.value = response.data.data.source
          if (response.data.data.token_valid == true){
            tokenExpired.value = false
            getCountry()
            getTermAndCondition()
            getRegisterDetails()
          } else {
            tokenExpired.value = true
          }

        }
      })
    }

    function getRegisterDetails() {
      tokenExpired.value = false
      const token = route.params.token
      axios.get('v1/registration-get-by-token/' + token).then((response) => {
        if (response.data.data){
          if(response.data.data.reg_email){
            form.loginEmail = response.data.data.reg_email
          }
        }
        if(response.data.data){
          if (response.data.data.registration) {
            form.id = response.data.data.registration.reg_id
            form.organizationName =
              response.data.data.registration.organisation_name
            form.websiteUrl = response.data.data.registration.website_url
            form.lastName = response.data.data.registration.last_name
            form.givenName = response.data.data.registration.given_name
            form.businessEmail = response.data.data.registration.business_email
            form.phoneNumber = response.data.data.registration.phone_number
            form.country = response.data.data.registration.country_code
            addressData.state = response.data.data.registration.state_id
            addressData.suburb = response.data.data.registration.suburb_id
            addressData.postalCode = response.data.data.registration.postcode_id
            form.address1 = response.data.data.registration.address1
            form.address2 = response.data.data.registration.address2
            form.username = response.data.data.registration.username
            getState()
            if (stateOptions.value.length) {
              form.state = addressData.state
              getSuburb()
              if (townOptions.value.length) {
                form.state = addressData.state
                form.suburb = addressData.suburb
                getPostCode()
                if (postalCodeOptions.value.length) {
                  form.state = addressData.state
                  form.suburb = addressData.suburb
                  form.postalCode = addressData.postalCode
                }
              }
            }
          }
        }
        else{
          tokenExpired.value = true
        }
      })
    }

    function closeAlert() {
      modal.show = false
    }

    function confirmAlert() {
      regSuccessModal.show = false
      window.location = 'https://opusxenta.com/'
    }

    function termsAndCondition() {
      if (form.acceptTermsAndConditions == null) {
        checkedTermAndConditionValue.value = true
        form.acceptTermsAndConditions = 1
      } else {
        checkedTermAndConditionValue.value = false
        form.acceptTermsAndConditions = null
      }
    }

    async function switchPasswordVisibility() {
      form.passwordFieldType =
        form.passwordFieldType === 'password' ? 'text' : 'password'
    }

    function checkSetupTwoVisible() {
      if(form.organizationName == null ||
        form.givenName == null ||
        form.lastName == null ||
        form.businessEmail == null ||
        form.phoneNumber == null ||
        form.organizationName == '' ||
        form.givenName == '' ||
        form.lastName == '' ||
        form.businessEmail == '' ||
        form.phoneNumber == '')
      {
        errors.errors = null
      }else{
        resetErrorsInPage1()
        errors.organizationName = null
        errors.lastName = null
        errors.givenName = null
        errors.businessEmail = null
        errors.phoneNumber = null
        errors.website_url = null
        const website_url_regex_a =
          /^((?:https?\:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)$/
        const website_url_regex_b =
          /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/
        const email_regex =
          /^([a-zA-Z0-9\+_\-]+)(\.[a-zA-Z0-9\+_\-]+)*@([a-zA-Z0-9\-]+\.)+[a-z]{2,6}(?!#)$/
        const max = /^.{0,2048}$/
        const maxEmail = /^.{0,320}$/
        let index = 0
        if (form.organizationName == null || form.organizationName == '') {
          errors.organisation_name = "Please enter the organisation's name."
          index++
        }
        else if (!max.test(form.organizationName)) {
          errors.organisation_name =
            'Maximum length for organisation name is 255.'
          index++
        }
        else if (
          !(website_url_regex_a.test(form.websiteUrl) && website_url_regex_b.test(form.websiteUrl)) &&
          !(form.websiteUrl == null || form.websiteUrl == '')
        ) {
          errors.website_url = 'The website url must be valid url address.'
          index++
        } else if (!max.test(form.websiteUrl)) {
          errors.website_url = 'Maximum length for website url is 2048.'
          index++
        }
        else if (form.givenName == null || form.givenName == '') {
          errors.given_name = "Please enter the primary contact's given name."
          index++
        }
        else if (!max.test(form.givenName)) {
          errors.given_name = 'Maximum length for given name is 255.'
          index++
        }
        else if (form.lastName == null || form.lastName == '') {
          errors.last_name = "Please enter the primary contact's last name."
          index++
        }
        else if (!max.test(form.lastName)) {
          errors.last_name = 'Maximum length for last name is 255.'
          index++
        }
        else if (form.businessEmail == null || form.businessEmail == '') {
          errors.business_email = 'Please enter the business email address.'
          index++
        } else if (!email_regex.test(form.businessEmail)) {
          errors.business_email =
            'The email address must be in a valid email format.'
          index++
        } else if (!maxEmail.test(form.businessEmail)) {
          errors.business_email = 'The email address must be in a valid email format.'
          index++
        }
        else if (form.phoneNumber == null || form.phoneNumber == '') {
          errors.phone_number = "Please enter the primary contact's phone number."
          index++
        }
        else if (!max.test(form.phoneNumber)) {
          errors.phone_number = 'Maximum length for phone number is 255.'
          index++
        }
        if (index == 0) {
          checkFirstPageValidation()
        }
      }

    }

    async function getCountry() {
      isLoading.value = true
      await axios
        .get('v1/country_and_currency/get_all_tnt_countries/' + token + '/'+ targetTnt)
        .then((response) => {
          if (response.data.data != null) {
            if(response.data.data.length){
              response.data.data.find((country) => {
                let obj = {}
                obj.text = country.country_name
                obj.value = country.country_code
                countryOptions.value.push(obj)
              })
            }else{
              disableValue.value = true
              warningModal.type = 'warning'
              warningModal.confirm_button = true
              warningModal.button_text = 'OK'
              warningModal.title = 'Warning'
              warningModal.message =
                'Required information are not complete. Please contact administrator.'
              warningModal.show = true
            }

          }
        })
      isLoading.value = false
    }

    async function getState() {
      form.state = null
      form.suburb = null
      form.postalCode = null
      stateOptions.value = [{ value: '', text: 'Select state' }]
      townOptions.value = [{ value: '', text: 'Select town' }]
      postalCodeOptions.value = [{ value: '', text: 'Select post code' }]
      isLoading.value = true
      if (form.country != '') {
        await axios
          .get('v1/address/state-names-for-country/' + form.country)
          .then((response) => {
            if (response.data.data != null || response.data.data != []) {
              response.data.data.find((state) => {
                let obj = {}
                obj.text = state.state_name
                obj.value = state.state_id
                stateOptions.value.push(obj)
              })
              stateDisable.value = false
            }
          })
      }
      isLoading.value = false
    }

    async function getSuburb() {
      if (Number.isInteger(parseInt(form.state))) {
        form.suburb = null
        form.postalCode = null
        townOptions.value = [{ value: '', text: 'Select town' }]
        postalCodeOptions.value = [{ value: '', text: 'Select post code' }]
        isLoading.value = true
        await axios
          .get(
            'v1/address/suburb-names-for-state/' +
              form.country +
              '/' +
              form.state
          )
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((suburb) => {
                let obj = {}
                obj.text = suburb.suburb_name
                obj.value = suburb.suburb_id
                townOptions.value.push(obj)
              })
            }
            suburbDisable.value = false
            isLoading.value = false
          })
      } else {
        suburbDisable.value = true
        postalCodeDisale.value = true
        form.suburb = null
        form.postalCode = null
      }
    }

    async function getPostCode() {
      if (Number.isInteger(parseInt(form.suburb))) {
        form.postalCode = null
        postalCodeDisale.value = true
        postalCodeOptions.value = [
          { value: '', text: 'Please select a post code' },
        ]
        await axios
          .get(
            'v1/address/postal-codes-for-suburb/' +
            form.country +
            '/' +
            form.state +
            '/' +
            form.suburb
          )
          .then((response) => {
            if (response.data.data != null) {
              if (response.data.data.length == 1) {
                form.postalCode = 0
                postalCodeOptions.value = [
                  {
                    value: response.data.data[0].postcode_id,
                    text: response.data.data[0].postcode,
                  },
                ]
                setTimeout(
                  () => (form.postalCode = response.data.data[0].postcode_id),
                  500
                )
              } else {
                response.data.data.find((state) => {
                  let obj = {}
                  obj.text = state.postcode
                  obj.value = state.postcode_id
                  postalCodeOptions.value.push(obj)
                })
              }
            } else {
            }
          })
        postalCodeDisale.value = false
      } else {
        postalCodeDisale.value = true
        form.postalCode = null
      }
    }

    async function getTermAndCondition() {
      isLoading.value = true
      await axios.get('v1/term-and-condition-by-tenant/' + token + '/'+ targetTnt).then((response) => {
        if (response.data.data != null) {
          if(response.data.data == false){
            disableValue.value = true
            warningModal.type = 'warning'
            warningModal.confirm_button = true
            warningModal.button_text = 'OK'
            warningModal.title = 'Warning'
            warningModal.message =
              'Required information are not complete. Please contact administrator.'
            warningModal.show = true
          }
          termsAndConditionDescription.value = response.data.data.description
        }
      })
      isLoading.value = false
    }

    function onSubmit() {
      errorFormatIndex.value = 0
      let url = null
      resetErrors()

      if(form.country == null ||
        form.state == null ||
        form.suburb == null ||
        form.postalCode == null ||
        form.address1 == null ||
        form.username == null ||
        form.loginEmail == null ||
        form.password == null ||
        form.confirmPassword == null ||
        form.acceptTermsAndConditions == null ||
        form.country == '' ||
        form.state == '' ||
        form.suburb == '' ||
        form.postalCode == '' ||
        form.username == '' ||
        form.loginEmail == '' ||
        form.password == '' ||
        form.confirmPassword == '' ||
        form.acceptTermsAndConditions == '' ||
        form.address1 == '')
      {
        errors.errors = null
      }else{
        isLoading.value = true
        let SubmitArr = {}
        SubmitArr.check_first_page_validation = false
        SubmitArr.reg_id = form.id
        SubmitArr.organisation_name = form.organizationName
        SubmitArr.website_url = form.websiteUrl
        SubmitArr.last_name = form.lastName
        SubmitArr.given_name = form.givenName
        SubmitArr.business_email = form.businessEmail
        SubmitArr.phone_number = form.phoneNumber
        SubmitArr.country_code = form.country
        SubmitArr.state_id = form.state
        SubmitArr.suburb_id = form.suburb
        SubmitArr.postcode_id = form.postalCode
        SubmitArr.address_line_1 = form.address1
        SubmitArr.address_line_2 = form.address2
        SubmitArr.username = form.username
        SubmitArr.login_email = form.loginEmail
        SubmitArr.password = form.password
        SubmitArr.accept_terms_and_conditions = form.acceptTermsAndConditions
        SubmitArr.token = form.token
        SubmitArr.confirm_password = form.confirmPassword
        SubmitArr.fname_lname_email = true
        SubmitArr.target_tnt = targetTnt

        if (form.id == null) {
          url = 'v1/registration-create'
        } else {
          url = 'v1/registration-update'
        }

        axios.post(url, SubmitArr).then((response) => {
          if (response.data.status == true) {
            form.errors = ''
            if (source.value == 'API'){
              regSuccessModal.confirmButton = false
            }else{
              regSuccessModal.confirmButton = true
            }
            regSuccessModal.show = true
            resetFormValues()
            isLoading.value = false
          } else {
            if(response.data.data){
              if (response.data.data.errors.validations) {
                bindValidationErrors(response.data.data.errors.validations)
                form.errors = response.data.data.errors
                if(errors.business_email){
                  stepTwoVisible.value = !stepTwoVisible.value
                }
                errorFormat.country_code = form.errors.validations.country_code
                errorFormat.state_id = form.errors.validations.state_id
                errorFormat.suburb_id = form.errors.validations.suburb_id
                errorFormat.postcode_id = form.errors.validations.postcode_id
                errorFormat.address_line_1 = form.errors.validations.address_line_1
                errorFormat.address_line_2 = form.errors.validations.address_line_2
                errorFormat.username = form.errors.validations.username
                errorFormat.login_email = form.errors.validations.login_email
                errorFormat.password = form.errors.validations.password
                errorFormat.confirm_password = form.errors.validations.confirm_password
                errorFormat.accept_terms_and_conditions = form.errors.validations.accept_terms_and_conditions

                if (form.errors.validations.fname_lname_email){
                  stepTwoVisible.value = !stepTwoVisible.value
                  errorFormatIndex.value = 1
                }
                else if(errorFormat.country_code != null){
                  errorFormatIndex.value = 2
                }
                else if(errorFormat.state_id != null){
                  errorFormatIndex.value = 3
                }
                else if(errorFormat.suburb_id != null){
                  errorFormatIndex.value = 4
                }
                else if(errorFormat.postcode_id != null){
                  errorFormatIndex.value = 5
                }
                else if(errorFormat.address_line_1 != null){
                  errorFormatIndex.value = 6
                }
                else if(errorFormat.address_line_2 != null){
                  errorFormatIndex.value = 7
                }
                else if(errorFormat.username != null){
                  errorFormatIndex.value = 8
                }
                else if(errorFormat.login_email != null){
                  errorFormatIndex.value = 9
                }
                else if(errorFormat.password != null){
                  errorFormatIndex.value = 10
                }
                else if(errorFormat.confirm_password != null){
                  errorFormatIndex.value = 11
                }
                else if(errorFormat.accept_terms_and_conditions != null){
                  errorFormatIndex.value = 12
                }
              } else {
                modal.type = 'error'
                modal.title = 'Error'
                modal.message = 'Registration failed.'
                modal.show = true
              }
            }else{
              tokenExpired.value = true
            }

            isLoading.value = false
          }
        })
      }


    }

    function checkFirstPageValidation() {
      errorFormatIndex.value = 0
      resetErrors()
      isLoading.value = true
      let SubmitArr = {}
      SubmitArr.check_first_page_validation = true
      SubmitArr.reg_id = form.id
      SubmitArr.organisation_name = form.organizationName
      SubmitArr.website_url = form.websiteUrl
      SubmitArr.last_name = form.lastName
      SubmitArr.given_name = form.givenName
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.fname_lname_email = true

      axios.post('v1/registration-first-page-validation', SubmitArr).then((response) => {
        if (response.data != null && response.data.status == true && !response.data.data.errors) {
          form.errors = ''
          isLoading.value = false
          stepTwoVisible.value = true
        } else {
          if(response.data.data){
            if (response.data.data.errors.validations) {
              stepTwoVisible.value = false
              bindValidationErrors(response.data.data.errors.validations)
              form.errors = response.data.data.errors
            }
          }else{
            tokenExpired.value = true
          }
          isLoading.value = false
        }
      })
    }

    function resetFormValues() {
      form.organizationName = null
      form.websiteUrl = null
      form.lastName = null
      form.givenName = null
      form.businessEmail = null
      form.phoneNumber = null
      form.country = null
      form.state = null
      form.suburb = null
      form.postalCode = null
      form.address1 = null
      form.address2 = null
      form.username = null
      form.loginEmail = null
      form.password = null
      form.confirmPassword = null
      stepTwoVisible.value = false
      form.acceptTermsAndConditions = null
    }

    function resetErrors() {
      errors.organisation_name = null
      errors.website_url = null
      errors.last_name = null
      errors.given_name = null
      errors.business_email = null
      errors.phone_number = null
      errors.country_code = null
      errors.state_id = null
      errors.suburb_id = null
      errors.postcode_id = null
      errors.address_line_1 = null
      errors.address_line_2 = null
      errors.username = null
      errors.login_email = null
      errors.password = null
      errors.accept_terms_and_conditions = null
      errors.confirm_password = null
    }
    function resetErrorsInPage1() {
      errors.organisation_name = null
      errors.website_url = null
      errors.last_name = null
      errors.given_name = null
      errors.business_email = null
      errors.phone_number = null
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function checkFormStatus() {
      errors.confirmPassword = null
      if (form.password == form.confirmPassword) {
        return 1
      } else {
        errors.confirmPassword =
          "Passwords don't match. please confirm your password again."
        return 0
      }
    }

    function closeWariningModal() {
      warningModal.show = false
    }

    return {
      form,
      modal,
      errors,
      getSuburb,
      fullPage,
      getState,
      onchange,
      onSubmit,
      isLoading,
      ModalForm,
      modalTerms,
      closeAlert,
      confirmAlert,
      getPostCode,
      townOptions,
      stateDisable,
      suburbDisable,
      stateOptions,
      stepTwoVisible,
      countryOptions,
      regSuccessModal,
      postalCodeDisale,
      postalCodeOptions,
      termsAndCondition,
      checkSetupTwoVisible,
      switchPasswordVisibility,
      tokenExpired,
      termsAndConditionDescription,
      editorOptions,
      errorFormat,
      errorFormatIndex,
      disableValue,
      warningModal,
      closeWariningModal,
      source
    }
  },
}
</script>
