<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <h1
        id="page-title"
        class="page-title"
      >
        {{ t("Dropdown Fields") }}
      </h1>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              :change="onchange"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllDropdownFields()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div
          v-if="false"
          class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0"
        >
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="dropdown_fields_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Portal") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('portal')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'portal'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Field Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('field_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'field_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Description") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('description')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'description'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Date Updated") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('updated_date')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'updated_date'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
                <th
                  scope="col"
                  class="th-action"
                  style="min-width:200px"
                >
                  {{ t("Manage") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(dropdownField, index) in dropdownFields"
                :id="'dropdown_fields_' + index"
                :key="index"
              >
                <td style="max-width: 170px">
                  {{ dropdownField.portal }}
                </td>
                <td style="max-width: 240px">
                  {{ dropdownField.field_name }}
                </td>
                <td style="max-width: 320px">
                  {{ dropdownField.description }}
                </td>
                <td>
                  {{ formatDateTime(dropdownField.updated_date) }}
                </td>
                <td class="text-center">
                  <badge
                    v-if="dropdownField.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="dropdownField.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'dropdown_fields_view_' + index"
                      type="info"
                      icon="eye"
                      @click="
                        viewAllDropdownOptionsPerField(dropdownField.slug)
                      "
                    />
                  </div>
                </td>
                <td>
                  <div class="flex items-center justify-center">
                    <ButtonSecondary
                      :id="'dropdown_fields_options_' + index"
                      class="btn-xs"
                      type="button"
                      @click="
                        addOrEditDropdownOptionsPerField(dropdownField.slug)
                      "
                    >
                      {{ t("Options") }}
                    </ButtonSecondary>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!dropdownFields.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllDropdownFields($event)"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { useRouter } from 'vue-router'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import router from "../../routes";

export default {
  name: 'DropdownFields',

  components: {
    Badge,
    Search,
    Loading,
    SvgIcon,
    FormSelect,
    PageHeader,
    NoTableData,
    ButtonCircle,
    TableDefault,
    VuePagination,
    ButtonSecondary,
  },

  setup() {
    const { t } = useI18n()
    const sortColumnDirection = ref('desc')
    const dropdownFields = ref(0)
    const router = useRouter()
    const isLoading = ref(false)
    const fullPage = ref(true)
    const lastPage = ref(0)
    const perPage = ref(10)
    const sortColumn = ref('')
    const totalRecord = ref(0)

    onMounted(() => {
      getAllDropdownFields()
    })

    function getAllDropdownFields(page = 1, column = sortColumn.value) {
      isLoading.value = true

      axios
        .get(
          '/v1/get-all-dropdown-fields?page=' +
            page +
            '&sortColumn=' +
            column +
            '&sortDirection=' +
            sortColumnDirection.value +
            '&count_per_page=' +
            perPage.value
        )
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            dropdownFields.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.log('Error !')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      axios
        .get(
          '/v1/get-all-dropdown-fields?sortColumn=' +
            sortColumn.value +
            '&sortDirection=' +
            sortColumnDirection.value +
            '&count_per_page=' +
            perPage.value
        )
        .then((response) => {
          if (response.data != null) {
            dropdownFields.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.log('Error !')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function viewAllDropdownOptionsPerField(slug) {
      router.push('dropdown-fields/' + slug)
    }

    function addOrEditDropdownOptionsPerField(slug) {
      router.push('dropdown-fields/edit/' + slug)
    }

    function formatDateTime(dateTime, format = 'DD-MMM-YYYY') {
      if (format == 'DD-MMM-YYYY') {
        return moment(dateTime).format(format)
      } else {
        return moment(dateTime, format).format(format)
      }
    }

    return {
      t,
      dropdownFields,
      sortColumnDirection,
      sort,
      onMounted,
      viewAllDropdownOptionsPerField,
      addOrEditDropdownOptionsPerField,
      isLoading,
      fullPage,
      lastPage,
      getAllDropdownFields,
      perPage,
      onchange,
      formatDateTime,
      sortColumn,
      totalRecord,
    }
  },
}
</script>
