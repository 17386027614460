<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Accounts") }}
        </h1>

        <Button
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="getMerchantAccountGatewayDetailsByMchAccId()"
        >
          {{ t("Add Accounts") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getMerchantAccountDetails()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="
                getMerchantAccountDetails(1, sortColumn, $event)
              "
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Account") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Suburb/Town") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('suburb_id')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'suburb_id'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Parent Account") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('is_parent')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'is_parent'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(merchant_account_data, index) in merchantAccount"
                :id="'accounts_' + index"
                :key="index"
              >
                <td class="max-w-xs">
                  {{ merchant_account_data.mch_acc_name }}
                </td>
                <td>{{ merchant_account_data.suburb_id.suburb_name }}</td>
                <td class="text-center">
                  <badge
                    v-if="merchant_account_data.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="merchant_account_data.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  {{ merchant_account_data.is_parent == 1 ? 'Yes' : 'No' }}
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'accounts_view_' + index"
                      type="info"
                      icon="eye"
                      @click.stop="
                        ;(isAccountDetailsVisible = true),
                          viewMerchantAccount(merchant_account_data.mch_acc_id)
                      "
                    />
                    <button-circle
                      :id="'accounts_edit' + index"
                      type="info"
                      icon="edit"
                      @click.stop="
                        editMerchantAccount(merchant_account_data.mch_acc_id)
                      "
                    />

                    <button-circle
                      v-if="(merchant_account_data.is_parent == 0 && merchantUser == null) && merchant_account_data.transactions == 0"
                      :id="'accounts_delete_' + index"
                      type="danger"
                      icon="trash"
                      @click="deleteAccount(merchant_account_data.mch_acc_id)"
                    />

                    <tippy
                      v-if="(merchant_account_data.is_parent == 1 && merchantUser == null) || ( merchantUser == null && merchant_account_data.transactions > 0)"
                      placement="left"
                    >
                      <button-circle
                        v-if="true"
                        :id="'accounts_delete_' + index"
                        type="danger"
                        icon="trash"
                        disabled
                      />
                      <template #content>
                        <span>{{ t("You won't be able to delete this Account as it has a sub Account and/or is attached to one or more transactions") }}.</span>
                      </template>
                    </tippy>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!merchantAccount.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getMerchantAccountDetails($event)"
      />
    </div>
  </div>

  <view-account
    :mch="mchAccountId"
    :tab="selectedTabIdFromEdit"
    :subtab="selectedSubTabIdFromEdit"
    :show-accounts-slide-over="isAccountDetailsVisible"
    @hideAccountsSlideOver="isAccountDetailsVisible = false"
  />

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />

  <modal
    :type="modalAddAccount.type"
    :show="modalAddAccount.show"
    :title="modalAddAccount.title"
    :message="modalAddAccount.message"
    :close-button="modalAddAccount.close_button"
    :confirm-button="modalAddAccount.confirm_button"
    :button-text="modalAddAccount.button_text"
    @closeModal="closeAlertAccount"
  />
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Tippy } from 'vue-tippy'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ViewAccount from '@/pages/merchant-portal/accounts/ViewAccount.vue'
import axios from 'axios'
import { useRouter, useRoute } from 'vue-router'
import Modal from '@/components/Modal.vue'
import router from "../../../routes";
import CryptoJs from 'crypto-js'
import constant from "@/constant";

export default {
  name: 'Accounts',

  components: {
    Tippy,
    Modal,
    Badge,
    Button,
    Search,
    Loading,
    SvgIcon,
    FormSelect,
    PageHeader,
    NoTableData,
    ViewAccount,
    TableDefault,
    ButtonCircle,
    VuePagination,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const isAccountDetailsVisible = ref(false)
    const sortColumn = ref('')
    const searchText = ref('')
    const merchantAccountDetails = ref('')
    const merchantAccount = ref(0)
    const lastPage = ref(0)
    const perPage = ref('10')
    const totatlRecord = ref(0)
    const currentPage = ref(0)
    const router = useRouter()
    const route = useRoute()
    const CryptoJsAesJson = constant.CryptoJsAesJson
    const user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data
      .user.user
    const mchAccountId = ref(0)
    const selectedTabIdFromView = ref('tab0')
    const selectedSubTabIdFromView = ref('payment_methods')
    const selectedTabIdFromEdit = ref('tab0')
    const selectedSubTabIdFromEdit = ref('payment_methods')
    const IdToDelete = ref(false)
    const merchantUser = ref('')


    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: false,
      button_text: 'Done',
    })

    const modalAddAccount = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    onMounted(() => {
      if (
        route.params.activeTab != null &&
        route.params.merchantAccountId != null &&
        route.params.activeSubTab != null
      ) {
        mchAccountId.value = route.params.merchantAccountId
        selectedTabIdFromEdit.value = route.params.activeTab
        selectedSubTabIdFromEdit.value = route.params.activeSubTab
        isAccountDetailsVisible.value = true
      }
    })

    function getMerchantAccountDetails(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        searchText.value = search
      }
      if (localStorage.getItem('merchant') != ''){
        merchantUser.value = localStorage.getItem('merchant')
      }

      axios
        .get('/v1/get-merchant-account', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            merchantAccount.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totatlRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getMerchantAccountDetails(1, sortColumn.value, searchText.value)
    }

    function editMerchantAccount(mch_acc_id) {
      router.push({
        name: 'account-edit',
        params: { mch_acc_id: mch_acc_id, tab: selectedTabIdFromView.value, subtab: selectedSubTabIdFromView },
      })
    }

    function viewMerchantAccount(mch_acc_id) {
      mchAccountId.value = mch_acc_id
      selectedTabIdFromEdit.value = 'tab0'
      selectedSubTabIdFromEdit.value = 'payment_methods'
    }

    function deleteAccount(id) {
      IdToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function confirmAlert() {
      deleteMerchantAccount()
      modal.show = false
      return true
    }

    function deleteMerchantAccount() {
      let data ={}
      data.mcc_acc_id = IdToDelete.value
      axios
        .delete('/v1/delete-merchant-account/' + IdToDelete.value , {params: data})
        .then((response) => {
          if (response.data != null) {
            function closeAlert() {
              if (modal.button_text === 'No') {
                modal.show = false
              } else {
                modal.show = false
                router.push('/accounts/index')
              }
            }
            getMerchantAccountDetails()
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function closeAlert() {
      if (modal.button_text === 'No') {
        modal.show = false
      } else {
        modal.show = false
        router.push('/accounts/index')
      }
    }

    function getMerchantAccountGatewayDetailsByMchAccId() {
      isLoading.value = true
      const mchAccId = ref(0)
      const mchAccData = ref(0)

      axios
        .get('/v1/get-merchant-accounts-by-merchant-id-is-parent')
        .then((response) => {
          if (response.data != null) {
            merchantAccountDetails.value = response.data.data
            mchAccId.value = merchantAccountDetails.value.mch_acc_id

            axios
                .get('/v1/get-merchant-account-payment-gateway-credentials/' + mchAccId.value)
                .then((response) => {
                  if (response.data != null) {
                    mchAccData.value = response.data.data
                    if (mchAccData.value.length == 0) {
                      modalAddAccount.type = 'warning'
                      modalAddAccount.title = 'Warning'
                      modalAddAccount.message =
                          'Gateway details missing for parent account. please enter gateway details before creating a sub account.'
                      modalAddAccount.show = true
                    } else {
                      router.push('/accounts/add')
                    }
                  }
                })
                .catch(err => {
                  router.push({ name: 'unauthorized-access'})
                })
          } else {
            console.error('Error!')
          }
        })
    }

    function closeAlertAccount() {
      modalAddAccount.show = false
      router.push('/accounts')
      getMerchantAccountDetails()
    }

    return {
      t,
      fullPage,
      isLoading,
      sortColumnDirection,
      isAccountDetailsVisible,
      getMerchantAccountDetails,
      sortColumn,
      searchText,
      lastPage,
      perPage,
      totatlRecord,
      currentPage,
      merchantAccount,
      editMerchantAccount,
      sort,
      viewMerchantAccount,
      mchAccountId,
      modal,
      modalAddAccount,
      deleteAccount,
      confirmAlert,
      closeAlert,
      user,
      selectedTabIdFromView,
      selectedSubTabIdFromView,
      selectedTabIdFromEdit,
      selectedSubTabIdFromEdit,
      getMerchantAccountGatewayDetailsByMchAccId,
      merchantAccountDetails,
      closeAlertAccount,
      merchantUser
    }
  },
}
</script>
