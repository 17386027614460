<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <h1
        id="page-title"
        class="page-title"
      >
        {{ t("Generate Invoices") }}
      </h1>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="mb-4">
        <form-label
          class="inline-block"
          for="invoice_period"
        >
          <span>{{ t("Invoice Period to Date") }}</span>
          <required />
          <tippy placement="right">
            <svg-icon
              class="-mt-0.5 ml-1 inline-block w-5 h-5 text-primary"
              icon="info-circle"
            />
            <template #content>
              {{ t("Selected invoice date is until 11:59:59pm on that day") }}.
            </template>
          </tippy>
        </form-label>

        <div class="w-full md:w-72 mt-1 flex items-center space-x-2">
          <datepicker
            id="invoice_period"
            v-model="invoicePeriodToDate"
            name="invoice_period"
            class="vdp-input"
            input-format="dd-MM-yyyy"
            :upper-limit="serverDate"
          />
          <Button
            id="btn-generate_report"
            type="button"
            :disabled="disableRun"
            @click="run()"
          >
            {{ t("Run") }}
          </Button>
        </div>
        <form-error
          v-if="invoiceDateError"
          :message="runErrorMsg"
        />
      </div>

      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getMerchantDetails()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              :search-text="searchText"
              @dataFetchfunction="getMerchantDetails(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  class="th-action"
                >
                  <form-checkbox
                    id="allIndex"
                    v-model="form.isAllMerchantsSelected"
                    name="states"
                    :disabled="parentCheckBoxDisable"
                    :check-value="true"
                    :model-value="form.isAllMerchantsSelected == 1 ? 0 : 1"
                    :checked="form.isAllMerchantsSelected == 1 ? true : false"
                    @click="allMerchantsChecked()"
                  />
                </th><th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Plan Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('plan_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'plan_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Merchant") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_acc_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'mch_acc_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Currency") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('base_curr_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'base_curr_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-end">
                    <span>{{ t("Amount") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('inv_total')"
                    >
                      <svg-icon
                        v-if="sortColumnDirection == 'asc' && sortColumn == 'inv_total'"
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(merchant_data, index) in merchants"
                :id="'generate_invoices_' + index"
                :key="index"
              >
                <td class="tb-action text-center">
                  <form-checkbox
                    :id="merchant_data.mch_id"
                    v-model="form.selectedMerchantsId"
                    :model-value="merchant_data.is_invoiced"
                    name="states"
                    :checked="merchant_data.is_invoiced == 1"
                    @click="isInvoiced(merchant_data.temp_mch_acc_id, merchant_data.temp_mch_id, merchant_data.is_invoiced, merchant_data.is_parent)"
                  />
                </td>
                <td>{{ merchant_data.plan_name }}</td>
                <td>{{ merchant_data.mch_acc_name }}</td>
                <td>{{ merchant_data.base_curr_code }}</td>
                <td class="text-right">
                  {{ merchant_data.inv_total.toFixed(2) }}
                </td>
                <td>
                  <div
                    class="flex items-center justify-center space-x-5"
                  >
                    <button-circle
                      :id="'generate_invoices_view' + index"
                      type="info"
                      icon="eye"
                      @click="previewDetails(merchant_data.temp_mch_id, merchant_data.temp_mch_acc_id)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!merchants.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getMerchantDetails($event)"
      />

      <div class="mt-4 flex justify-end space-x-2">
        <ButtonSecondary
          :disabled="disableCancel"
          class="btn-wide"
          type="button"
          @click="cancel()"
        >
          {{ t("Cancel") }}
        </ButtonSecondary>
        <Button
          :disabled="disableAccept"
          class="btn-wide"
          type="button"
          @click="acceptMerchants()"
        >
          {{ t("Accept") }}
        </Button>
      </div>
    </div>
  </div>

  <modal
    :type="modalWarning.type"
    :show="modalWarning.show"
    :title="modalWarning.title"
    :message="modalWarning.message"
    :close-button="modalWarning.close_button"
    :confirm-button="modalWarning.confirm_button"
    :button-text="modalWarning.button_text"
    @closeModal="modalWarning.show = false"
    @confirmModal="confirmAlert"
  />

  <modal-form
    :close-button="modalInvoicePreview.close_button"
    :confirm-button="modalInvoicePreview.confirm_button"
    :modal-size="modalInvoicePreview.size"
    :show="modalInvoicePreview.show"
    @closeModal="modalInvoicePreview.show = false"
  >
    <template #modal-body>
      <div class="mb-1 font-bold text-xl text-right text-black">
        {{ t("Transaction Invoice") }}
      </div>

      <div
        class="absolute w-full flex justify-center bottom-0 opacity-50"
        style="top: 300px; height: 170px;"
      >
        <span
          class="ml-10 opacity-40 uppercase text-red-500 font-semibold text-center text-6xl md:text-9xl"
          style="transform: rotate(320deg);"
        >{{ t("Preview") }}</span>
      </div>

      <div class=" flex items-start justify-end space-x-2">
        <button type="button">
          <svg-icon
            class="w-8 h-8 text-black"
            icon="print"
            @click="print('printSection')"
          />
        </button>

        <button type="button">
          <svg-icon
            class="w-8 h-8 text-black"
            icon="download"
            @click="downloadFile(previewInvoiceDetails.temp_mch_acc_id )"
          />
        </button>
      </div>

      <div id="printSection">
        <div class="md:flex space-y-2 md:space-x-6 md:space-y-0">
          <div class="md:w-2/3 space-y-2">
            <div class="break-all flex">
              <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Organisation Name") }}: </span>
              <span class="w-full inline-block font-semibold text-black">{{ previewInvoiceDetails.account.mch_acc_name }}</span>
            </div>
            <div class="break-all flex">
              <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Plan") }}: </span>
              <span class="w-full inline-block font-semibold text-black">{{ previewInvoiceDetails.plan.plan_name }}</span>
            </div>
            <div class="break-all flex">
              <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Date") }}: </span>
              <span class="w-full inline-block font-semibold text-black">{{ formatDate(previewInvoiceDetails.inv_date, 'DD-MMMM-YYYY') }}</span>
            </div>
            <div class="break-all flex">
              <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Period") }}: </span>
              <span class="w-full inline-block font-semibold text-black">{{ formatDate(previewInvoiceDetails.inv_period, 'DD MMMM YYYY') }} to {{ formatDate(previewInvoiceDetails.inv_date, 'DD MMMM YYYY') }}</span>
            </div>
            <div class="break-all flex">
              <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Number") }}: </span>
              <span class="w-full inline-block font-semibold text-black">TBD</span>
            </div>
          </div>

          <div class="md:w-2/3">
            <div class="flex items-start">
              <div class="w-64">
                <span class="font-semibold text-gray-500">{{ t("Address") }}:</span>
              </div>
              <div class="w-full">
                <div class="mb-1 break-all">
                  <span class="mr-0.5 inline-block font-semibold text-black">{{ previewInvoiceDetails.account.address1 }}</span>
                  <span
                    v-if="previewInvoiceDetails.account.address2 != null"
                    class="inline-block font-semibold text-black"
                  >, {{ previewInvoiceDetails.account.address2 }}</span>
                </div>
                <div class="mb-1 break-all">
                  <span class="inline-block font-semibold text-black">{{ previewInvoiceDetails.account.suburb_name }}, {{ previewInvoiceDetails.account.state_name }}</span>
                </div>
                <div class="mb-1 break-all">
                  <span class="inline-block font-semibold text-black">{{ previewInvoiceDetails.account.country_name }} {{ previewInvoiceDetails.account.postcode }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 mb-3 text-base text-center font-semibold text-black">
          {{ t("Invoice Summary") }}
        </div>

        <TableDefault>
          <template #table>
            <table
              class="table table-clickable"
              aria-describedby=""
            >
              <thead>
                <tr>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Month") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Tier Level") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-end">
                      <span># {{ t("Transactions") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-end">
                      <span>{{ t("Rate") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-end">
                      <span>{{ t("Frequency") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-end">
                      <span>{{ t("Total") }}</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <no-table-data v-if="!tierSummary.length" />
                <tr
                  v-for="(tier, index4) in tierSummary"
                  :key="index4"
                >
                  <td> {{ tier.inv_month }}</td>
                  <td>{{ tier.tier_no }}</td>
                  <td class="text-right">
                    {{ tier.tnx_count }}
                  </td>
                  <td class="text-right">
                    {{ tier.rate.toFixed(2) }}
                  </td>
                  <td
                    v-if="tier.inv_option == 'month'"
                    class="text-right"
                  >
                    {{ t("Monthly") }}
                  </td>
                  <td
                    v-if="tier.inv_option == 'free'"
                    class="text-right"
                  >
                    {{ t("Monthly Free Transaction") }}
                  </td>
                  <td
                    v-if="tier.inv_option == 'tnx'"
                    class="text-right"
                  >
                    {{ t("Transaction") }}
                  </td>
                  <td class="text-right">
                    {{ tier.amount.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    {{ t("Sub Total") }} ({{ previewInvoiceDetails.base_curr_code }})
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td class="font-semibold text-right">
                    {{ previewInvoiceDetails.inv_sub_total.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    {{ t("Tax") }} ({{ previewInvoiceDetails.inv_tax_rate }}%)
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td class="font-semibold text-right">
                    {{ (previewInvoiceDetails.inv_total - previewInvoiceDetails.inv_sub_total).toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td class="font-semibold">
                    <span class="text-black">{{ t("Total") }} ({{ previewInvoiceDetails.base_curr_code }})</span>
                  </td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td class="font-semibold text-right">
                    <span class="text-black">{{ previewInvoiceDetails.inv_total.toFixed(2) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </TableDefault>

        <div class="mt-5 mb-3 text-base text-center font-semibold text-black">
          {{ previewInvoiceDetails.account.mch_acc_name }} {{ t("Transaction Details") }}
        </div>

        <TableDefault>
          <template #table>
            <table
              class="table table-clickable"
              aria-describedby=""
            >
              <thead>
                <tr>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Transaction") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Transaction Date") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Document Ref") }} #</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Transaction Type") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Customer Name") }}</span>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-end">
                      <span>{{ t("Transaction Value") }}</span>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <no-table-data v-if="!invoiceTransactions.length" />
                <tr
                  v-for="(transaction, index3) in invoiceTransactions"
                  :key="index3"
                >
                  <td>{{ index3 + 1 }}</td>
                  <td>{{ formatDate(transaction.trn_date, 'DD-MMM-YYYY') }}</td>
                  <td>{{ transaction.mch_ref }}</td>
                  <td>{{ transaction.trn_type }}</td>
                  <td>{{ transaction.customer_name }}</td>
                  <td class="text-right">
                    <span v-if="transaction.trn_type == 'REFUND'" />
                    {{ parseFloat(transaction.amount).toFixed(2) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </TableDefault>

        <div
          v-for="(account, index7) in subAccTransactions"
          :key="index7"
        >
          <div
            class="mt-5 mb-3 text-base text-center font-semibold text-black"
          >
            {{ account.mch_acc_name }} {{ t("Transaction Details") }}
          </div>

          <TableDefault>
            <template
              #table
            >
              <table
                class="table table-clickable"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction Date") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Document Ref") }} #</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction Type") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Customer Name") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span>{{ t("Transaction Value") }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <no-table-data v-if="!account.transactions.length" />
                  <tr
                    v-for="(accountTrn, index8) in account.transactions"
                    :key="index8"
                  >
                    <td>{{ index8 + 1 }}</td>
                    <td>{{ formatDate(accountTrn.trn_date, 'DD-MMM-YYYY') }}</td>
                    <td>{{ accountTrn.mch_ref }}</td>
                    <td>{{ accountTrn.trn_type }}</td>
                    <td>{{ accountTrn.customer_name }}</td>
                    <td class="text-right">
                      <span v-if="accountTrn.trn_type == 'REFUND'" />
                      {{ parseFloat(accountTrn.amount).toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TableDefault>
        </div>
      </div>
    </template>
  </modal-form>
</template>

<script>
import {ref, reactive, onMounted} from 'vue'
import { useI18n } from 'vue-i18n'
import { Tippy } from 'vue-tippy'
import Datepicker from 'vue3-datepicker'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import ModalForm from '@/components/ModalForm.vue'
import Modal from '@/components/Modal.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormError from '@/components/FormError.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from "axios";
import moment from 'moment'
import router from "../../routes";

export default {
  name: 'GenerateInvoices',

  components: {
    Tippy,
    Button,
    Search,
    Loading,
    SvgIcon,
    Required,
    ModalForm,
    Modal,
    FormLabel,
    FormSelect,
    FormError,
    Datepicker,
    PageHeader,
    NoTableData,
    TableDefault,
    ButtonCircle,
    FormCheckbox,
    VuePagination,
    ButtonSecondary,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const searchText = ref('')
    const totatlRecord = ref(0)
    const currentPage = ref(0)
    const lastPage = ref(0)
    const perPage = ref('10')
    const serverDate = ref()
    const invBatchNo = ref()
    const invoicePeriodToDate = ref()
    const disableRun = ref(false)
    const allChecked = ref(false)
    const checked = ref(true)
    const selectAll = ref(false)
    const disableAccept = ref(true)
    const disableCancel = ref(true)
    const merchants = ref(0)
    const invoiceTransactions = ref(0)
    const tierSummary = ref(0)
    const allMerchantsList = ref(0)
    const merchantsList = ref([])
    const previewInvoiceDetails = ref(0)
    const subAccTransactions = ref([])
    const invoiceDateError= ref(false)
    const runErrorMsg= ref('')
    const selectedAccCount = ref(0)
    const parentCheckBoxDisable = ref(false)
    const merchantsDataList = ref(0)
    const serverDateFixed = ref()

    const runform = reactive({
      billing_date: null,
    })
    const form = reactive({
      selectedMerchants: [],
      selectedMerchantsId: null,
      isAllMerchantsSelected: 0,
      selected: 0,
      inv_batch_no:invBatchNo
    })
    const cancelform = reactive({
      inv_batch_no:invBatchNo
    })
    const modalInvoicePreview = reactive({
      show: false,
      size: 'max-w-7xl',
      close_button: false,
      confirm_button: false,
    })
    const modalWarning = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: false,
      button_text: 'Done',
    })


    onMounted(() => {
      init()
    })

    function formatDate(date, format){
      return moment(date).format(format)
    }

    function init() {
      isLoading.value = true
      axios.get('/v1/billing/init').then((response) => {
        if (response.data != null) {
          isLoading.value = false
          serverDate.value = new Date(response.data.data.system_date)
          serverDateFixed.value = new Date(response.data.data.system_date);
          if (serverDate.value.getDate() === 1){
            serverDate.value = serverDate.value.setDate(serverDate.value.getDate());
          }else{
            serverDate.value = serverDate.value.setDate(serverDate.value.getDate() - 1);
          }
          let invBatch = response.data.data.inv_batch;
          if(invBatch != 'null') {
            invoicePeriodToDate.value = new Date(invBatch.batch_date)
            invBatchNo.value = invBatch.inv_batch_num
            disableRun.value = true
            parentCheckBoxDisable.value = false
            if(invBatch.batch_status == 'GENERATED') {
              getMerchantDetails(1, sortColumn.value, null, true)
              disableCancel.value = false
            } else {
                disableAccept.value = true
                disableCancel.value = true
                parentCheckBoxDisable.value = true
                modalWarning.type = 'info'
                modalWarning.close_button = true
                modalWarning.button_text = 'Ok'
                modalWarning.title = 'Info'
                modalWarning.message = 'Invoice generation in progress... Please wait...'
                modalWarning.show = true
            }
          }
        } else {
          console.error('Error!')
        }
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })

    }

    function run() {
      if(typeof invoicePeriodToDate.value == 'undefined' || invoicePeriodToDate.value == null) {
        invoiceDateError.value = true
        runErrorMsg.value = 'Please select an invoice date.'
      } else {
        isLoading.value = true
        invoiceDateError.value = false
        if(serverDateFixed.value.getDate() === 1){
          let selectDate = new Date(invoicePeriodToDate.value);
          let  previousMonthLastDate = new Date(selectDate.setDate(selectDate.getDate()-1));
          runform.billing_date = moment(String(previousMonthLastDate)).format('YYYY-MM-DD');
          invoicePeriodToDate.value = new Date(runform.billing_date)
        }else{
          runform.billing_date = moment(invoicePeriodToDate.value).format('YYYY-MM-DD')
        }
        axios.post('v1/billing/generate-temp-invoice', runform).then((response) => {
          isLoading.value = false
          if (response.data != null) {
              invBatchNo.value = response.data.data.inv_batch_num
              disableCancel.value = true
              disableRun.value = true
              parentCheckBoxDisable.value = true
              modalWarning.type = 'info'
              modalWarning.close_button = true
              modalWarning.button_text = 'Ok'
              modalWarning.title = 'Info'
              modalWarning.message = 'Invoice generation in progress... Please wait...'
              modalWarning.show = true
              // getMerchantDetails()
          }else {
            isLoading.value = false
          }
        })
      }
    }

    function getMerchantDetails(page = 1, column = sortColumn.value, search = null, checkErr = false) {
      merchantsList.value = []
      form.selectedMerchants = []
      isLoading.value = true
      selectedAccCount.value = 0

      if (search != null) {
        searchText.value = search
      }

      if(invBatchNo.value != null) {
        axios
          .get('/v1/billing/mch-acc-list', {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
              inv_batch_no: invBatchNo.value
            },
          })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              merchants.value = response.data.data.data
              lastPage.value = response.data.data.last_page
              totatlRecord.value = response.data.data.total
              currentPage.value = response.data.data.current_page
              // getAllMerchantAccList() Not working properly #8651
                checkAllMerchantIsInvoicedOrNot(response.data.data) // check all generated invoices 'per page' is checked or Not :: #8651
              merchants.value.find((merchants) => {
                if(merchants.is_invoiced) {
                  selectedAccCount.value = selectedAccCount.value +1
                  form.selectedMerchants.push(merchants.temp_mch_id)
                }
                merchantsList.value.push(merchants.temp_mch_id)
              })
              if(merchants.value.length > 0) {
                if(selectedAccCount.value > 0) {
                  disableAccept.value = false
                } else {
                  disableAccept.value = true
                }
                runErrorMsg.value = null
              } else {
                disableAccept.value = true
                invoiceDateError.value = true
                if(checkErr) {
                  runErrorMsg.value = 'This batch has no invoices to bill. Please cancel to proceed.'
                }
              }
            } else {
              console.error('Error!')
            }
          })
        }
        isLoading.value = false
    }

    function getAllMerchantsList() {
      merchantsList.value = []
      isLoading.value = true

      axios
        .get('/v1/billing/list/'+invBatchNo.value, {
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            allMerchantsList.value = response.data.data
            response.data.data.find((allMerchantsList) => {
              merchantsList.value.push(allMerchantsList.mch_id)
            })
          } else {
            console.error('Error!')
          }
        })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value = sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      getMerchantDetails(1, sortColumn.value, searchText.value)
    }

    function checkAllMerchantIsInvoicedOrNot(data){
      if(data.data.length > 0){
        let checkAll = true;
        data.data.find(function (value){
          if (value.is_invoiced === 0){
            checkAll = false;
          }
        })
        if(checkAll){
          form.isAllMerchantsSelected = 1;
        }else{
          form.isAllMerchantsSelected = 0;
        }
      }
    }

   /* function getAllMerchantAccList(){
      merchantsList.value = []

      axios
        .get('/v1/billing/get-all-mch-acc-list', {
          params: {
            inv_batch_no: invBatchNo.value
          },
        })
        .then((response) => {
          if (response.data != null) {
            merchantsDataList.value = response.data.data
            if(merchantsDataList.value != 0){
              if(merchantsDataList.value == totatlRecord.value){
                form.isAllMerchantsSelected = 1
              }else{
                form.isAllMerchantsSelected = 0
              }
            }
          } else {
            console.error('Error!')
          }
        })
    }*/

    function acceptMerchants() {
      isLoading.value = true

      axios.post('v1/billing/accept', form).then((response) => {
        isLoading.value = false
        if (response.data != null) {
            searchText.value = ''
            totatlRecord.value = 0
            invBatchNo.value = null
            disableAccept.value = true
            disableCancel.value = true
            disableRun.value = false
            merchants.value = []
            invoicePeriodToDate.value = null
            resetSelectedMerchants()
        }else {
          isLoading.value = false
        }
      })
    }

    function cancel() {
      isLoading.value = true
      axios.post('v1/billing/cancel', cancelform).then((response) => {
        isLoading.value = false
        if (response.data != null) {
            searchText.value = ''
            totatlRecord.value = 0
            invBatchNo.value = null
            disableAccept.value = true
            disableCancel.value = true
            disableRun.value = false
            merchants.value = []
            invoicePeriodToDate.value = null
            invoiceDateError.value = false
            resetSelectedMerchants()
        }else {
          isLoading.value = false
        }
      })
    }

    function resetSelectedMerchants() {
      form.isAllMerchantsSelected = 0
      form.selectedMerchants = []
      form.selectedMerchantsId = null
      searchText.value = ''
    }

    function previewDetails(mchId, mchAccId){
      isLoading.value = true
      axios.get('/v1/billing/preview-temp-invoice', {
        params: {
          temp_mch_id: mchId,
          temp_mch_acc_id: mchAccId,
          inv_batch_no: invBatchNo.value
        },
      })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            previewInvoiceDetails.value = response.data.data.invoice
            invoiceTransactions.value = response.data.data.transactions
            tierSummary.value = response.data.data.tiers
            subAccTransactions.value = response.data.data.sub_acc_transactions
            modalInvoicePreview.show = true
          } else {
            isLoading.value = false
            console.error('Error!')
          }
        })
    }

    function isInvoiced(temp_mch_acc_id,temp_mch_id, is_invoiced, is_parent){
      isLoading.value = true

      if(is_invoiced == 0){
        checked.value = true
      }else{
        checked.value = false
      }

      let obj = {
        temp_mch_acc_id:temp_mch_acc_id,
        temp_mch_id:temp_mch_id,
        checked:checked.value,
        is_parent:is_parent
      }

      axios.post('v1/billing/is-invoiced', obj).then((response) => {
        isLoading.value = false
        if (response.data != null) {
          if(response.data.data == true){
            checked.value = false
          }else{
            checked.value = true
          }
          getMerchantDetails(currentPage.value)
        }else {
          isLoading.value = false
        }
      })
    }

    function allMerchantsChecked(){
      isLoading.value = true
      /* start For update selected merchant is_invoiced */
      let merchId = [];
      if(merchants.value !=null){
        merchants.value.forEach(function (value) {
          merchId.push(value.temp_mch_id)
        });
      }
      /* end For update selected merchant is_invoiced */

      if(form.isAllMerchantsSelected == 0){
        allChecked.value = true
      }
      let obj = {
        merchId: merchId,
        allChecked:allChecked.value
      }

      axios.post('v1/billing/invoiced-all', obj).then((response) => {
        isLoading.value = false
        if (response.data != null) {
          if(response.data.data == true){
            allChecked.value = false
          }else{
            allChecked.value = true
          }
          getMerchantDetails(currentPage.value)
        }else {
          isLoading.value = false
        }
      })
    }

    function print(printSection){
      //to do work
      // var printContents = document.getElementById(printSection).innerHTML;
      // var originalContents = document.body.innerHTML;
      // document.body.innerHTML = printContents;
      // window.print();
      // document.body.innerHTML = originalContents;
    }

    function downloadFile(mchAccId) {
      isLoading.value = true
      let fileNameForDownload = 'TDB.pdf'
      let contentType = '';

      axios.get("v1/billing/download_pdf",
          {
              responseType: 'arraybuffer',
              params: {
                  content_type: contentType,
                  mch_acc_id: mchAccId
              }
          }).then(response => {

          isLoading.value = false
          let blob = new Blob([response.data], {type: contentType})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = fileNameForDownload;
          document.body.appendChild(link);
          link.click();
      });
    }

    return {
      t,
      fullPage,
      isLoading,
      sortColumnDirection,
      serverDate,
      invBatchNo,
      disableRun,
      disableAccept,
      disableCancel,
      parentCheckBoxDisable,
      invoiceDateError,
      runErrorMsg,
      selectedAccCount,
      init,
      onMounted,
      invoicePeriodToDate,
      getMerchantDetails,
      merchants,
      sortColumn,
      searchText,
      totatlRecord,
      currentPage,
      lastPage,
      perPage,
      sort,
      form,
      runform,
      cancelform,
      run,
      acceptMerchants,
      cancel,
      resetSelectedMerchants,
      getAllMerchantsList,
      allMerchantsList,
      modalInvoicePreview,
      modalWarning,
      previewDetails,
      previewInvoiceDetails,
      invoiceTransactions,
      tierSummary,
      allChecked,
      subAccTransactions,
      checked,
      isInvoiced,
      allMerchantsChecked,
      selectAll,
      print,
      downloadFile,
      formatDate,
    }
  },
}
</script>
