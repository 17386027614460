<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver=";(slideOver.show = false), closeEdit()"
  >
    <template #slideover-body>
      <div class="tab-wizard-group">
        <div class="tab-wizard-wrapper">
          <nav
            class="tab-wizard"
            aria-label="Tabs"
          >
            <button
              v-for="(tabItem, index) in accountTabItems"
              :key="index"
              class="tab-wizard-item"
              :class="{ active: activeTabId == tabItem.id }"
              type="button"
              @click="navigateTab(tabItem.id)"
            >
              {{ t(tabItem.name) }}
            </button>
          </nav>
        </div>

        <div class="tab-wizard-container">
          <div v-if="activeTabId == 'tab1'">
            <div class="mb-4 grid md:grid-cols-2 gap-4">
              <div>
                <form-label
                  for="field-last_name"
                >
                  {{ t("Last Name") }} <required />
                </form-label>
                <form-input
                  id="field-last_name"
                  v-model="form.lastName"
                  class="mt-1"
                  name="last_name"
                  type="text"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.last_name
                      ? form.errors.validations.last_name[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="field-first_name"
                >
                  {{ t("First Name") }} <required />
                </form-label>
                <form-input
                  id="field-first_name"
                  v-model="form.firstName"
                  class="mt-1"
                  name="first_name"
                  type="text"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.first_name
                      ? form.errors.validations.first_name[0]
                      : ''
                  "
                />
              </div>

              <div v-if="environment == 'Edit'">
                <form-label
                  for="field-username"
                >
                  {{ t("Username") }} <required />
                </form-label>
                <form-input
                  id="field-username"
                  v-model="form.username"
                  class="mt-1"
                  name="username"
                  type="text"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.username
                      ? form.errors.validations.username[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="field-email"
                >
                  {{ t("Business Email") }} <required />
                </form-label>
                <form-input
                  id="field-email"
                  v-model="form.businessEmail"
                  class="mt-1"
                  name="business_email"
                  type="email"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.business_email
                      ? form.errors.validations.business_email[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="phone-number">
                  {{ t("Phone Number") }}
                </form-label>
                <form-input
                  id="phone-number"
                  v-model="form.phoneNumber"
                  class="mt-1"
                  name="phone_number"
                  type="text"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.phone_number
                      ? form.errors.validations.phone_number[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="security-profile"
                >
                  {{ t("Security Profile") }} <required />
                </form-label>
                <form-select
                  id="security-profile"
                  v-model="form.securityProfileId"
                  class="mt-1"
                  name="security_profile"
                  required
                  :length="2"
                  :items="securityProfilesOption"
                  :disable="isOwnUserEdit && environment == 'Edit' && isAdminSecurityPfofile == 0"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.sec_profile_id
                      ? form.errors.validations.sec_profile_id[0]
                      : ''
                  "
                />
              </div>

              <div v-if="environment == 'Edit'">
                <form-label for="status">
                  {{ t("Status") }}
                </form-label>
                <form-select
                  id="status"
                  v-model="form.status"
                  class="mt-1"
                  name="status"
                  required
                  :disable="isOwnUserEdit"
                  :items="statusOption"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.status
                      ? form.errors.validations.status[0]
                      : ''
                  "
                />
                <form-error
                  v-if="form.statusError"
                  :message="
                    form.statusError
                  "
                />
              </div>

              <div v-if="environment == 'Edit'">
                <form-label
                  for="default-language"
                >
                  {{ t("Default Language") }} <required />
                </form-label>
                <form-select
                  id="default-language"
                  v-model="form.languageId"
                  class="mt-1"
                  name="default-language"
                  required
                  :items="tntLanguagesOption"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.language
                      ? form.errors.validations.language[0]
                      : ''
                  "
                />
              </div>
              <form-error
                v-if="form.errors"
                :message="
                  form.errors.validations.fname_lname_email
                    ? form.errors.validations.fname_lname_email[0]
                    : ''
                "
              />
            </div>
          </div>

          <div v-if="activeTabId == 'tab2'">
            <div class="mb-4 flex items-center justify-between">
              <label
                class="text-sm font-medium text-gray-500"
              >{{ t("Select All Accounts") }}</label>

              <Switch
                v-model="selectAllAccounts"
                :class="selectAllAccounts ? 'enabled' : ''"
                class="switch"
                :disabled="isOwnUserEdit && environment == 'Edit' && isAdminSecurityPfofile == 0"
                @click="selectAllAccountsSwitch()"
              >
                <span class="sr-only" />
                <span class="switch-dot" />
              </Switch>
            </div>

            <div
              v-if="false"
              class="mb-4 grid grid-cols-2 gap-y-3"
            >
              <div class="flex items-center">
                <span class="text-sm">{{ t("Show") }}</span>
                <div class="mx-2">
                  <form-select
                    id="results_per_page"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span class="text-sm">{{ t("entries") }}</span>
              </div>

              <div class="w-full flex justify-end">
                <div class="w-full lg:w-4/6">
                  <search />
                </div>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <table
                  class="table table-clickable"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Accounts") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sort('mch_acc_name')"
                          >
                            <svg-icon
                              v-if="
                                sortColumnDirection == 'asc' &&
                                  sortColumn == 'mch_acc_name'
                              "
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        class="th-action"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="merchantAccount in merchantAccountOption"
                      :key="merchantAccount.key"
                    >
                      <td>{{ merchantAccount.mch_acc_name }}</td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <Switch
                            v-model="form.assignAccount"
                            :disabled="isOwnUserEdit && environment == 'Edit' && isAdminSecurityPfofile == 0"
                            :class="
                              setMerchantAccountCheck(
                                merchantAccount.mch_acc_id
                              )
                                ? 'enabled'
                                : ''
                            "
                            class="switch"
                            @click="
                              setFormAssignAccount(merchantAccount.mch_acc_id)
                            "
                          >
                            <span class="sr-only" />
                            <span class="switch-dot" />
                          </Switch>
                        </div>
                      </td>
                    </tr>

                    <no-table-data v-if="!merchantAccountOption.length" />
                  </tbody>
                </table>
              </template>
            </TableDefault>

            <vue-pagination v-if="false" />
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <form-error
        v-if="form.errors"
        :message="
          form.errors.validations.assignAccounts
            ? form.errors.validations.assignAccounts[0]
            : ''
        "
      />
      <Button
        v-if="activeTabId == 'tab1'"
        class="btn-wide"
        type="button"
        @click="validateUser()"
      >
        {{ t("Continue") }}
      </Button>
      <ButtonSecondary
        v-if="activeTabId == 'tab2'"
        class="btn-wide"
        type="button"
        @click="backButton()"
      >
        {{ t("Back") }}
      </ButtonSecondary>
      <Button
        v-if="activeTabId == 'tab2' && (!isOwnUserEdit || (isOwnUserEdit && isAdminSecurityPfofile == 1))"
        class="btn-wide"
        type="button"
        @click="onSubmit()"
      >
        {{ t("Save") }}
      </Button>
      <Button
        v-if="activeTabId == 'tab2' && isOwnUserEdit && isAdminSecurityPfofile == 0"
        class="btn-wide"
        type="button"
        @click="onSubmitOwnUser()"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive, watchEffect, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Switch } from '@headlessui/vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Search from '@/components/Search.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from 'axios'
import Loading from 'vue3-loading-overlay'
import router from "../../../routes";
import constant from "@/constant";
import CryptoJs from 'crypto-js'

export default {
  name: 'UserAddEdit',

  components: {
    Modal,
    Switch,
    Search,
    Button,
    SvgIcon,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    NoTableData,
    TableDefault,
    VuePagination,
    ButtonSecondary,
    Loading,
  },
  props: {
    uuid: {
      type: String,
      default: null,
    },
    tab: {
      type: String,
      default: 'tab1',
    },

    slideoverVisibility: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['successfullyUpdated', 'editCloseSlideOver'],
  setup(props, { emit }) {
    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: 'Add User',
    })
    const { t } = useI18n()
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const accountTabItems = reactive([
      { id: 'tab1', name: 'Enter User Details' },
      { id: 'tab2', name: 'Assign Account Access' },
    ])
    const statusOption = ref([
      {
        text: 'Active',
        value: 'ACT',
      },
      {
        text: 'Inactive',
        value: 'INA',
      },
    ])
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })
    const CryptoJsAesJson = constant.CryptoJsAesJson
    let activeTabId = ref(accountTabItems[0].id)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const selectAllAccounts = ref(false)
    const assignAccount = ref(false)
    const isOwnUserEdit = ref(false)
    const  isAdminSecurityPfofile = ref(null)
    const environment = ref('Add')
    const securityProfilesOption = ref([
      { value: '', text: 'Please select a security profile' },
    ])
    const tntLanguagesOption = ref([])
    const merchantAccountOption = ref([
      { value: '', text: 'Please select an option' },
    ])
    const merchantAccountList = ref([])

    const form = reactive({
      id: null,
      lastName: null,
      firstName: null,
      username: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      securityProfileId: null,
      status: null,
      language: null,
      languageId: null,
      rowVersion: null,
      assignAccount: ref(false),
      assignAccounts: [],
      errors: '',
      statusError: '',
      mchId: null,
    })
    getSecurityProfiles()
    getTntLanguages()
    getMerchantAccounts()

    watchEffect(() => {
      setEnvironment()
      resetFrom()
      form.id = props.uuid
      activeTabId.value = props.tab
      if (form.id != null) {
        if (form.id != 'addUser') {
          setEditUser(form.id)
          slideOver.title = 'Edit User'
        } else {
          resetFrom()
          slideOver.title = 'Add User'
        }
      }

      if (props.slideoverVisibility) {
        if(form.id == null) {
          slideOver.show = false
        }
        else {
          slideOver.show = true
        }
      }
    })

    function getSecurityProfiles() {
      form.securityProfileId = ''
      securityProfilesOption.value = null
      isLoading.value = true
      axios.get('/v1/get-merchant-security-profiles').then((response) => {
        if (response.data != null) {
          securityProfilesOption.value = [
            { value: '', text: 'Please select a security profile' },
          ]
          if(response.data.data.length > 0){
            if (response.data.data.length == 1){
              securityProfilesOption.value = [
                {
                  value: response.data.data[0].sec_profile_id,
                  text: response.data.data[0].sec_profile_name,
                },
              ]
              form.securityProfileId = response.data.data[0].sec_profile_id
            }
            else {
              response.data.data.find((secProfile) => {
                let obj = {}
                obj.text = secProfile.sec_profile_name
                obj.value = secProfile.sec_profile_id
                securityProfilesOption.value.push(obj)
              })
            }
          }
        } else {
          console.error('Error!')
        }
        isLoading.value = false
      })
    }
    function getTntLanguages() {
      isLoading.value = true
      axios.get('/v1/get-tnt-languages').then((response) => {
        if (response.data != null) {
          response.data.data.find((tntLanguage) => {
            let obj = {}
            obj.text = tntLanguage.language_code
            obj.value = tntLanguage.tnt_lang_id
            tntLanguagesOption.value.push(obj)
          })
        } else {
          console.error('Error!')
        }
        isLoading.value = false
      })
    }
    function getMerchantAccounts() {
      isLoading.value = true
      axios
        .get('/v1/get-merchant-accounts-by-merchant-id', {
          params: {
            sortColumn: sortColumn.value,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            merchantAccountOption.value = response.data.data
            response.data.data.find((merchantAccount) => {
              merchantAccountList.value.push(merchantAccount.mch_acc_id)
            })
          } else {
            console.error('Error!')
          }
          isLoading.value = false
        })
    }

    async function setEditUser(registerId) {
      isLoading.value = true
      form.assignAccounts = []
      await axios.get('v1/user/' + registerId).then((response) => {
        if (response.data.data != null) {
          let user = response.data.data
          form.lastName = user.last_name
          form.firstName = user.first_name
          form.businessEmail = user.business_email
          form.phoneNumber = user.phone_number
          form.mchId = user.mch_id
          form.securityProfile = user.security_profile.sec_profile_name
          form.securityProfileId = user.security_profile.sec_profile_id
          form.username = user.username
          form.status = user.status
          form.language = user.language.language_code
          form.languageId = user.language.tnt_lang_id
          isAdminSecurityPfofile.value = user.security_profile.is_admin_profile
          user.assign_accounts.forEach((account) => {
            if (account.status == 'ACT') {
              form.assignAccounts.push(account.mch_acc_id)
            }
          })
          form.rowVersion = user.row_version
          setFormAssignAccount()
        }
        isLoading.value = false
      })
    }

    function validateUser() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.is_merchant_user = true
      SubmitArr.user_validate = true
      SubmitArr.uuid = form.id != 'addUser' ? form.id : null
      SubmitArr.last_name = form.lastName
      SubmitArr.first_name = form.firstName
      SubmitArr.username = form.username
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.sec_profile_id = form.securityProfileId
      SubmitArr.status = form.status
      SubmitArr.language = form.languageId
      SubmitArr.row_version = form.rowVersion
      SubmitArr.mch_id = form.mchId
      SubmitArr.fname_lname_email = true
      form.errors = ''
      form.statusError = ''

      if (form.id == 'adduser') {
        url = '/v1/validate-merchant-user-register'
      } else {
        url = '/v1/validate-merchant-user-register'
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
            activeTabId.value = 'tab2'
            return true
        } else {
          form.errors = response.data.data.errors
          return false
        }
      })
    }

    function onSubmit() {
      isLoading.value = true
      let SubmitArr = {}
      let url = ''
      SubmitArr.is_merchant_user = true
      SubmitArr.uuid = form.id != 'addUser' ? form.id : null
      SubmitArr.last_name = form.lastName
      SubmitArr.first_name = form.firstName
      SubmitArr.username = form.username
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.sec_profile_id = form.securityProfileId
      SubmitArr.mch_id = form.mchId
      SubmitArr.status = form.status
      SubmitArr.language = form.languageId
      SubmitArr.assignAccounts = form.assignAccounts
      SubmitArr.row_version = form.rowVersion
      SubmitArr.fname_lname_email = true

      if (form.id == 'addUser') {
        url = '/v1/merchant-new-user-register'
      } else {
        url = '/v1/merchant-edit-user/' + form.id
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == 'addUser') {
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'User created successfully.'
            modal.show = true
          } else {
            if (form.id != null && response.data.status){
              let loggedUserUUID = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.user.user.user_uuid
              let encryptUserName = CryptoJs.AES.encrypt(
                JSON.stringify(SubmitArr.username),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
              ).toString()
              if (loggedUserUUID == form.id){
                localStorage.setItem("loggedUser", encryptUserName)
              }
            }
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'User updated successfully.'
            modal.show = true
          }
          emit('successfullyUpdated')
          resetFrom()
        } else {
          form.errors = response.data.data.errors
        }
        if(form.errors != null)
        {
          if(form.errors.validations.uuid && form.errors.validations.uuid[0]){
            router.push({ name: 'unauthorized-access' })
          }
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function onSubmitOwnUser() {
      isLoading.value = true
      let SubmitArr = {}
      SubmitArr.is_merchant_user = true
      SubmitArr.isOwnUserEdit = true
      SubmitArr.uuid = form.id != 'addUser' ? form.id : null
      SubmitArr.last_name = form.lastName
      SubmitArr.first_name = form.firstName
      SubmitArr.username = form.username
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.status = form.status
      SubmitArr.sec_profile_id = form.securityProfileId
      SubmitArr.mch_id = form.mchId
      SubmitArr.language = form.languageId
      SubmitArr.row_version = form.rowVersion
      SubmitArr.fname_lname_email = true

      axios
        .post('/v1/merchant-edit-own-user/' + form.id, SubmitArr)
        .then((response) => {
          if (response.data != null && !response.data.data.errors) {
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'User updated successfully.'
            modal.show = true
            emit('successfullyUpdated')
            resetFrom()
          } else {
            form.errors = response.data.data.errors
            activeTabId.value = accountTabItems[0].id
          }
          isLoading.value = false
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function setFormAssignAccount(accountId) {
      isLoading.value = true
      if (accountId != null) {
        if (form.assignAccounts.includes(parseInt(accountId))) {
          form.assignAccounts.splice(
            form.assignAccounts.indexOf(parseInt(accountId)),
            1
          )
        } else {
          form.assignAccounts.push(accountId)
        }
      }

      let _arr1 = form.assignAccounts.sort()
      let _arr2 = merchantAccountList.value.sort()

      if (
        Array.isArray(_arr1) &&
        Array.isArray(_arr2) &&
        _arr1.length === _arr2.length
      ) {
        selectAllAccounts.value = true
      }

      if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
      ) {
        selectAllAccounts.value = false
      }
      isLoading.value = false
    }

    function setMerchantAccountCheck(merchantAccountId) {
      if (form.assignAccounts.includes(parseInt(merchantAccountId))) {
        return true
      }
    }

    function selectAllAccountsSwitch() {
      if (selectAllAccounts.value == true) {
        form.assignAccounts = []
        merchantAccountList.value.forEach((value) => {
          form.assignAccounts.push(value)
        })
      } else if (selectAllAccounts.value == false) {
        form.assignAccounts = []
      }
    }

    function setEnvironment() {
      isLoading.value = true
      if (props.uuid != 'addUser') {
        environment.value = 'Edit'
      } else {
        environment.value = 'Add'
      }
      axios.get('/v1/get_login_user_uuid').then((response) => {
        if (response.data != null) {
          if (form.id == response.data.data) {
            isOwnUserEdit.value = true
          } else {
            isOwnUserEdit.value = false
          }
        }
      })
      isLoading.value = false
    }
    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    function navigateTab(tabId) {
      form.errors = ''
      if (activeTabId.value == 'tab1' && tabId == 'tab2') {
          if (validateUser()) {
            showTabContent('activeTabId', tabId)
          }
      } else {
        showTabContent('activeTabId', tabId)
      }
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('field-last_name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function closeEdit() {
      emit('editCloseSlideOver')
    }

    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getMerchantAccounts()
    }

    function closeAlert() {
      slideOver.show = false
      modal.show = false
      return true
    }

    function confirmAlert() {
      modal.show = false
      return true
    }

    function resetFrom() {
      selectAllAccounts.value = false
      // form.id = null
      form.lastName = null
      form.firstName = null
      form.businessEmail = null
      form.phoneNumber = null
      form.securityProfile = null
      form.securityProfileId = null
      form.username = null
      form.status = null
      form.language = null
      form.languageId = null
      form.rowVersion = null
      form.assignAccount = false
      form.assignAccounts = []
      form.errors = null
    }
    function backButton() {
      activeTabId.value = 'tab1'
      form.errors = ''
    }
    return {
      t,
      slideOver,
      activeTabId,
      showTabContent,
      accountTabItems,
      selectAllAccounts,
      sortColumnDirection,
      sortColumn,
      statusOption,
      form,
      securityProfilesOption,
      tntLanguagesOption,
      merchantAccountOption,
      validateUser,
      setFormAssignAccount,
      setMerchantAccountCheck,
      assignAccount,
      merchantAccountList,
      selectAllAccountsSwitch,
      onSubmit,
      environment,
      modal,
      closeAlert,
      confirmAlert,
      isOwnUserEdit,
      onSubmitOwnUser,
      navigateTab,
      closeEdit,
      isLoading,
      fullPage,
      sort,
      backButton,
      isAdminSecurityPfofile,
    }
  },
}
</script>
