<template>
  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white class="inline-block h-14 mb-6" />
        <div class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2 class="mb-3 text-2xl font-medium text-black">
              You are not authorized.
            </h2>
            <h5 class="text-black text-opacity-70 font-medium">
              To access, please login first.
            </h5>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <Button
                class="btn-wide"
                type="submit"
                @click="redirect"
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import router from "../../routes";
import constant from "@/constant";
import CryptoJs from 'crypto-js'

export default {
  name: 'NoAccess',

  components: {
    Button,
    LogoWhite,
    AuthFooter,
  },

  setup() {
    const CryptoJsAesJson = constant.CryptoJsAesJson
    async function redirect() {
      if (localStorage.getItem('access_token') === null) {
        router.push({ name: 'signin' })
      } else {
        // router.push(router.go(-1));
        // location.reload()

        const user_type = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
            .authentication_data.user.user.user_type
        if (user_type == 'TNT') {
          await router.push({ name: 'dashboard-index' })
          location.reload()
        } else if (user_type == 'MCH') {
          await router.push({ name: 'merchant-dashboard-index' })
          location.reload()
        } else if (user_type == 'APO') {
          await router.push({ name: 'tenants-index' })
          location.reload()
        } else {
          await router.push({ name: 'unauthorized-access' })
        }
        // location.reload()

      }
    }

    return {
      redirect,
    }
  },


}
</script>
