<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Application Data") }}
        </h1>

        <Button
          id="button_add-app-data"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="
            ;(slideOver.show = true),
              resetForm(),
              resetErrors(),
              (environment = 'add')
          "
        >
          {{ t("Add Application Data") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              class="py-2"
              name="results_per_page"
              :items="[10, 25, 50, 100]"
              @onChangeFunction="getApplicationData()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getApplicationData(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="applicationData_owner_portal_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>  {{ t("Key") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('key')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'key'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>  {{ t("Value") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('value')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'value'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>  {{ t("Tenant Name") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('tnt_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'tnt_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(appData, index) in applicationData"
                :id="'applicationData_owner_portal_' + index"
                :key="index"
              >
                <td>{{ appData.key }}</td>
                <td>{{ appData.value }}</td>
                <td>{{ appData.tnt_name != null ? appData.tnt_name : '-' }}</td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="
                        ;(slideOver.show = true),
                          getApplicationDataById(appData.app_data_id),
                          (environment = 'edit')
                      "
                    />
                    <button-circle
                      type="danger"
                      icon="trash"
                      @click="deleteWarning(appData.app_data_id)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!applicationData.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        v-if="applicationData.length"
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getApplicationData($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="key">
            {{ t("Application Data Key") }}<required />
          </form-label>
          <form-input
            id="key"
            v-model="form.key"
            name="key"
            class="mt-1"
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.key
                ? form.errors.validations.key[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="email">
            {{ t("Application Data Value") }}<required />
          </form-label>
          <form-input
            id="email"
            v-model="form.value"
            name="email"
            class="mt-1"
            type="email"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.value
                ? form.errors.validations.value[0]
                : ''
            "
          />
        </div>
        <div>
          <form-checkbox
            id="states"
            v-model="form.all_system"
            name="states"
            :model-value="form.all_system == 1 ? 0 : 1"
            :checked="form.all_system == 1 ? true : false"
            @click="resetTenantValue()"
          />
          <label
            class="ml-2 text-sm"
            for="states"
          >{{ t("All System") }}</label>
        </div>
        <div>
          <form-label for="tnt_id">
            {{ t("Tenant") }}
            <required />
          </form-label>
          <form-select
            id="tnt_id"
            v-model="form.tnt_id"
            :items="tenants"
            name="tnt_id"
            class="mt-1"
            :disable="form.all_system==1"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.tnt_id
                ? form.errors.validations.tnt_id[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="onSubmit"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watch } from 'vue'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormError from '@/components/FormError.vue'
import axios from 'axios'
import Modal from '@/components/Modal'
import {useI18n} from "vue-i18n";
import Required from "@/components/Required";
import {useRouter} from "vue-router/dist/vue-router";

export default {
  name: 'ApplicationData',

  components: {
    Button,
    Search,
    Loading,
    SvgIcon,
    FormLabel,
    FormInput,
    FormCheckbox,
    SlideOver,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    Modal,
    FormError,
    Required,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const perPage = ref(10)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const currentPage = ref('')
    const lastPage = ref('')
    const totalRecord = ref('')
    const searchText = ref('')
    const applicationData = ref([])
    const environment = ref('add')
    const IdToDelete = ref(null)
    const tenants = ref([{ value: '', text: 'Please select a tenant' }])
    const router = useRouter()
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      title: t("Add Application Data"),
    })

    const form = reactive({
      id: null,
      key: null,
      value: null,
      tnt_id: null,
      all_system: null,
      row_version: null,
      errors: '',
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    getTenants()
    async function getTenants() {
      isLoading.value = true
      await axios
        .get('v1/all-tenants')
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            response.data.data.find((tenant) => {
              let obj = {}
              obj.text = tenant.tnt_name
              obj.value = tenant.tnt_id
              tenants.value.push(obj)
            })
          } else {
            console.error('Error!')
          }
        })
    }

    async function getApplicationData(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('v1/app-data/get?', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            applicationData.value = response.data.data.data
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.error('Error!')
          }
        })
      isLoading.value = false
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getApplicationData(1, sortColumn.value, searchText.value)
    }

    async function getApplicationDataById(appDataId) {
      await axios.get('v1/get/' + appDataId).then((response) => {
        if (response.data.data != null) {
          let appData = response.data.data
          resetForm()
          resetErrors()
          form.id = appData.app_data_id
          form.key = appData.key
          form.value = appData.value
          form.tnt_id = appData.tnt_id
          form.all_system = 0
          if (appData.tnt_id == null){
            form.all_system = 1
          }
          form.row_version = appData.row_version
          slideOver.title = t("Edit Application Data")
        }
      })
    }

    function onSubmit() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.app_data_id = form.id
      SubmitArr.key = form.key
      SubmitArr.value = form.value
      SubmitArr.tnt_id = form.tnt_id
      SubmitArr.all_system = form.all_system

      if (form.id == '' || form.id == null) {
        url = '/v1/app-data/create'
      } else {
        url = '/v1/app-data/edit/' + form.id
      }

      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == '' || form.id == null) {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Application data added successfully.'
            modal.show = true
          } else {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Application data updated successfully.'
            modal.show = true
          }
          slideOver.show = false
          resetForm()
          resetErrors()
          getApplicationData(currentPage)
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function deleteApplicationData() {
      axios
        .get('v1/app-data/delete/' + IdToDelete.value)
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getApplicationData()
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function deleteWarning(id) {
      IdToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function closeAlert() {
      modal.show = false
      resetForm()
      return true
    }

    function confirmAlert() {
      deleteApplicationData()
      modal.show = false
      return true
    }

    function resetTenantValue(){
      if (form.all_system == 0){
        form.tnt_id = null;
      }
    }

    function resetForm() {
      form.id = null
      form.uuid = null
      form.name = null
      form.email = null
      form.username = null
      form.row_version = null
      slideOver.title = t("Add Application Data")
    }

    function resetErrors() {
      form.errors = ''
    }


    return {
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      sortColumn,
      searchText,
      form,
      onSubmit,
      closeAlert,
      confirmAlert,
      modal,
      applicationData,
      perPage,
      getApplicationData,
      resetForm,
      resetErrors,
      environment,
      getApplicationDataById,
      totalRecord,
      lastPage,
      sort,
      tenants,
      resetTenantValue,
      deleteWarning,
      t,
    }
  },
}
</script>
