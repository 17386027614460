function trapFocus(focusLoop, focusRef) {
  var focusableEls;
  var firstFocusableEl;
  var lastFocusableEl;
  var KEYCODE_TAB;

  setTimeout(function() {
    if (focusLoop) {
      focusableEls = focusLoop.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled])');
      firstFocusableEl = focusableEls[0];
      lastFocusableEl = focusableEls[focusableEls.length - 1];
      KEYCODE_TAB = 9;

      if (!focusRef) {
        document.getElementById("focus-ref").focus()
      } else {
        focusRef.focus()
      }

      focusLoop.addEventListener('keydown', function(e) {
        var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
            e.preventDefault();
          }
        }
      });
    }
  }, 100)
}

export default {
  trapFocus
}
