window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    languagesListData: null,
    languagePostData: null,
    languageGetData: null,
  },

  mutations: {
    getLanguagesListData(state, data) {
      if (data != null) {
        state.languagesListData = data
      } else {
        state.gatewayListData = null
      }
    },
    postLanguageData(state, data) {
      if (data != null) {
        state.languagePostData = data
      } else {
        state.languagePostData = null
      }
    },
    getLanguageData(state, data) {
      if (data != null) {
        state.languageGetData = data
      } else {
        state.languageGetData = null
      }
    },
  },
  actions: {
    async getLanguagesList({ commit, state }, searchData) {
      await axios
        .get(
          '/v1/language/get?page=' +
            searchData.page +
            '&sortColumn=' +
            searchData.column +
            '&sortDirection=' +
            searchData.sortColumnDirection +
            '&count_per_page=' +
            searchData.perPage +
            '&search=' +
            searchData.searchText
        )
        .then((response) => {
          if (response.data != null) {
            commit('getLanguagesListData', response.data.data)
          } else {
            commit('getLanguagesListData', null)
          }
        })
    },

    async postLanguage({ commit, state }, languageData) {
      await axios.post('/v1/language/save', languageData).then((response) => {
        commit('postLanguageData', response.data)
      })
    },

    async updateLanguage({ commit, state }, languageData) {
      await axios
        .post('v1/language/edit/' + languageData.get('lang_id'), languageData)
        .then((response) => {
          commit('postLanguageData', response.data)
        })
    },

    async viewLanguage({ commit, state }, languageId) {
      await axios.get('/v1/language/view/' + languageId).then((response) => {
        if (response.data.status == true) {
          commit('getLanguageData', response.data)
        }
      })
    },
  },
}
