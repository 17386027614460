window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    postalCodesListDropDown: [],
  },

  mutations: {
    postalCodesListData(state, data) {
      state.postalCodesListDropDown = data
    },
  },
  actions: {
    async getPostalCodeBySuburbID(
      { commit, state },
      countryInfo,
      message = 'Please select a post code'
    ) {
      let postalCodesList = [{ value: '', text: message }]
      await axios
        .get(
          'v1/address/postal-codes-for-suburb/' +
            countryInfo.countryId +
            '/' +
            countryInfo.stateId +
            '/' +
            countryInfo.suburbId
        )
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((postalCodes) => {
              let obj = {}
              obj.text = postalCodes.postcode
              obj.value = postalCodes.postcode_id
              postalCodesList.push(obj)
            })
            commit('postalCodesListData', postalCodesList)
          }
        })
    },
  },
}
