<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center flex-wrap">
        <back-button />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("View Transaction Router") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="py-8">
        <div class="md:flex gap-x-4">
          <div
            class="w-full flex items-center justify-center md:justify-start mb-8"
            style="justify-content: space-between;"
          >
            <form-label for="name">
              {{ t("Rule Description") }}
            </form-label>
            <div class="mx-4">
              <form-input
                id="rule_des"
                v-model="form.ruleDescription"
                disabled
                class="py-2"
                name="description"
                style="width: 580px"
              />
            </div>
            <form-label for="name">
              {{ t("Priority") }}
            </form-label>
            <div class="mx-4">
              <tippy

                placement="left"
              >
                <form-input
                  id="rule_priority"
                  v-model="form.rulePriority"
                  class="py-2"
                  disabled
                  name="priority"
                  style="width: 80px"
                />
                <template #content>
                  <span>{{ t("Higher value indicates higher priority.") }}</span>
                </template>
              </tippy>
            </div>
            <form-label for="name">
              {{ t("Status") }}
            </form-label>
            <div class="mx-4">
              <form-input
                id="rule_status"
                v-model="form.ruleStatus"
                class="py-2"
                name="rule_status"
                style="width: 110px"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="table_overflow">
          <TableDefault>
            <template #table>
              <table
                id="terms_and_condition_tbl"
                class="table"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="th-action"
                    />

                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Factor") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sort('version')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'version'
                            "
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Operator") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sort('published_date')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'published_date'
                            "
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-center">
                        <span>{{ t("Value") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sort('status')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' && sortColumn == 'status'
                            "
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(routing_rule_data, index) in routingRuleDataArray"
                    :id="'routing_rule_' + index"
                    :key="index"
                  >
                    <td>
                      <form-checkbox
                        name="check"
                        checked
                        disabled
                      />
                    </td>
                    <td>
                      <form-input
                        id="factor"
                        v-model="routing_rule_data.factor"
                        class="py-2"
                        name="operator"
                        disabled
                      />
                    </td>

                    <td>
                      <form-input
                        id="operator"
                        v-model="routing_rule_data.operator"
                        class="py-2"
                        name="operator"
                        disabled
                      />
                    </td>
                    <td>
                      <form-input
                        v-if="routing_rule_data.factor == 'Merchant Account'"
                        id="value1"
                        v-model="routing_rule_data.value"
                        class="py-2"
                        name="value"
                        disabled
                      />
                      <form-input
                        v-if="routing_rule_data.factor == 'Transaction Operation'"
                        id="value2"
                        v-model="routing_rule_data.value"
                        class="py-2"
                        name="value"
                        disabled
                      />
                      <form-input
                        v-if="routing_rule_data.factor == 'Payment Category'"
                        id="value3"
                        v-model="routing_rule_data.value"
                        class="py-2"
                        name="value"
                        disabled
                      />
                      <form-input
                        v-if="routing_rule_data.factor == 'Payment Method'"
                        id="value4"
                        v-model="routing_rule_data.value"
                        class="py-2"
                        name="value"
                        disabled
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TableDefault>
        </div>
        <hr>
        <div class="flex p-3 boxBackground mb-5">
          <div
            class="w-full
              flex
              items-center
              justify-center
              justify-start
              "
          >
            <div class="w-1/3 flex items-center">
              <form-label for="name">
                {{ t("Gateway") }}
              </form-label>
              <div class="mx-2 w-full">
                <form-input
                  id="gateway"
                  v-model="form.gw"
                  class="py-2"
                  disabled
                />
              </div>
            </div>
       
            <div class="w-2/3 flex items-center">
              <form-label
                for="name"
                class="mx-4 w-80"
              >
                {{ t("API Configuration Name") }}
              </form-label>
              <div class="mx-2 w-full">
                <form-input
                  id="rule_definition"
                  v-model="form.ruleDefinition"
                  class="py-2"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import Loading from 'vue3-loading-overlay'
import PageHeader from '@/components/PageHeader.vue'
import TableDefault from "@/components/TableDefault";
import FormCheckbox from "@/components/FormCheckbox";
import FormInput from "@/components/FormInput";
import FormLabel from "@/components/FormLabel";
import BackButton from "@/components/BackButton";
import { Tippy } from 'vue-tippy';

export default {
  name: 'RouterRuleAdd',

  components: {
    Loading,
    PageHeader,
    TableDefault,
    FormCheckbox,
    FormInput,
    FormLabel,
    BackButton,
    Tippy,
  },

  setup() {
    let onchange = ref(true)
    const { t } = useI18n()
    const route = useRoute()
    const routingRuleData = ref([])
    const routingRuleDataArray = ref([])

    const form = reactive({
      ruleDescription: '',
      gw: '',
      ruleDefinition: '',
      rulePriority: '',
      ruleStatus: '',
    })

    onMounted(() => {
      getRouterRuleByRuleId()
    })

    function getRouterRuleByRuleId(){
      const rule_id = route.params.rule_id
      axios.get('/v1/get-router-rule-by-rule-id/' + rule_id).then((response) => {
        if (response.data != null) {
          routingRuleData.value = response.data.data
          let objMerchantAccount = {}
          let objTranOperation = {}
          let objPmCategory = {}
          let objPmMethod = {}
          if(routingRuleData.value.is_checked_mch_acc_id == 1){
            objMerchantAccount.factor = 'Merchant Account'
            objMerchantAccount.operator = routingRuleData.value.mch_acc_id_opr
            objMerchantAccount.value = routingRuleData.value.mch_acc_id
            routingRuleDataArray.value.push(objMerchantAccount)
          }
          if(routingRuleData.value.is_checked_tran_oprn == 1){
            objTranOperation.factor = 'Transaction Operation'
            objTranOperation.operator = routingRuleData.value.tran_oprn_opr
            objTranOperation.value = routingRuleData.value.tran_oprn
            routingRuleDataArray.value.push(objTranOperation)
          }
          if(routingRuleData.value.is_checked_pm_category == 1){
            objPmCategory.factor = 'Payment Category'
            objPmCategory.operator = routingRuleData.value.pm_category_opr
            objPmCategory.value = routingRuleData.value.pm_category
            routingRuleDataArray.value.push(objPmCategory)
          }
          if(routingRuleData.value.is_checked_pm_method == 1){
            objPmMethod.factor = 'Payment Method'
            objPmMethod.operator = routingRuleData.value.pm_method_opr
            if(routingRuleData.value.pm_method == 'ANY'){
              objPmMethod.value = routingRuleData.value.pm_method
            }else{
              objPmMethod.value = routingRuleData.value.description
            }
            routingRuleDataArray.value.push(objPmMethod)
          }
          form.ruleDefinition = routingRuleData.value.rr_def
          form.ruleDescription = routingRuleData.value.rr_des
          form.rulePriority = routingRuleData.value.rr_priority
          if(routingRuleData.value.name !=  null){
            form.gw = routingRuleData.value.name
          }else{
            form.gw = 'ANY'
          }
          if(routingRuleData.value.status == 'ACT'){
            form.ruleStatus = 'Active'
          }else{
            form.ruleStatus = 'Inactive'
          }
        } else {
          console.error('Error!')
        }
      })
    }

    return {
      t,
      onMounted,
      routingRuleData,
      onchange,
      form,
      getRouterRuleByRuleId,
      routingRuleDataArray,
    }
  },
}
</script>
<style scoped>
.table_overflow{
  height: calc(100vh - 450px);
  overflow-y: auto;
}
.boxBackground{
  background-color: #f7f8fa;
}
</style>