<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center flex-wrap">
        <back-button />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t(title + ' Transaction Router') }}
        </h1>
      </div>
    </template>
  </page-header>

  <div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <div class="py-8">
        <div class="md:flex gap-x-4">
          <div
            class="w-full flex items-center justify-center md:justify-start"
            style="justify-content: space-between"
          >
            <form-label for="name">
              {{ t('Rule Description') }} <required />
            </form-label>
            <div class="mx-4">
              <form-input
                id="rule_des"
                v-model="form.ruleDescription"
                class="py-2"
                name="results_per_page"
                style="width: 500px"
              />

              <form-error
                v-if="form.errors"
                :message="
                  form.errors.validations.ruleDescription
                    ? form.errors.validations.ruleDescription[0]
                    : ''
                "
              />
            </div>

            <form-label for="name">
              {{ t('Priority') }} <required />
            </form-label>
            <div class="mx-4">
              <tippy placement="left">
                <form-input
                  id="rule_priority"
                  v-model="form.rulePriority"
                  class="py-2"
                  name="priority"
                  type="number"
                  min="0"
                  max="9999"
                  style="width: 80px"
                  @keypress="onlyNumbers"
                />
                <template #content>
                  <span>{{
                    t('Higher value indicates higher priority.')
                  }}</span>
                </template>
              </tippy>

              <form-error
                v-if="form.errors"
                :message="
                  form.errors.validations.rulePriority
                    ? form.errors.validations.rulePriority[0]
                    : ''
                "
              />
            </div>

            <form-label for="name">
              {{ t('Status') }}
            </form-label>
            <div class="mr-3">
              <form-select
                id="rule_status"
                v-model="form.ruleStatus"
                :items="statusOptions"
                class="py-2"
                style="width: 110px"
                name="rule_status"
              />
            </div>
          </div>
        </div>
        <div class="flex w-full align-items-baseline py-5">
          <span class="text-sm ml-auto mt-1.5">{{ t('Add Filter') }} {{}}</span>
          <div class="mx-2">
            <form-select
              id="add filter"
              v-model="form.filterOption"
              :items="filterOptions"
              :change="onchange"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="addFiltersToRouterRule(form.filterOption)"
            />

            <form-error
              v-if="errorMsgForNullFactor"
              :message="errorMsgForNullFactor"
            />
            <form-error
              v-if="errorMsgForSameFactor"
              :message="errorMsgForSameFactor"
            />
          </div>
        </div>
        <div class="table_overflow">
          <TableDefault>
            <template #table>
              <table
                id="terms_and_condition_tbl"
                class="table table-clickable"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="th-action"
                    />

                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t('Filter') }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t('Operator') }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t('Value') }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(routing_rule_data, index) in routingRuleData"
                    :id="'routing_rule_' + index"
                    :key="index"
                  >
                    <td>
                      <form-checkbox
                        name="check"
                        checked
                        @click="clickCheckbox($event, index)"
                      />
                    </td>
                    <td>
                      <form-input
                        id="factor"
                        v-model="routing_rule_data.factor"
                        class="py-2"
                        name="operator"
                        disabled
                      />
                    </td>

                    <td>
                      <form-select
                        v-if="routing_rule_data.factor == 'Payment Method'"
                        id="operator"
                        v-model="routing_rule_data.operator"
                        :items="operatorOptionsPmMethod"
                        class="py-2"
                        name="operator"
                      />
                      <form-select
                        v-if="routing_rule_data.factor != 'Payment Method'"
                        id="operator2"
                        v-model="routing_rule_data.operator"
                        :items="operatorOptions"
                        class="py-2"
                        name="operator"
                      />
                    </td>
                    <td>
                      <form-select
                        v-if="routing_rule_data.factor == 'Merchant Account'"
                        id="value1"
                        v-model="routing_rule_data.value"
                        :items="merchantAccountOptions"
                        class="py-2"
                        name="value"
                      />
                      <form-select
                        v-if="
                          routing_rule_data.factor == 'Transaction Operation'
                        "
                        id="value2"
                        v-model="routing_rule_data.value"
                        :items="transactionOprnOptions"
                        class="py-2"
                        name="value"
                        :change="onchange"
                        @onChangeFunction="
                          setGateways()"
                      />
                      <form-select
                        v-if="routing_rule_data.factor == 'Payment Category'"
                        id="value3"
                        v-model="routing_rule_data.value"
                        :items="paymentCategoryOptions"
                        class="py-2"
                        name="value"
                        change="onchange"
                        @onChangeFunction="
                          changePaymentMethods(routing_rule_data.value)
                        "
                      />
                      <form-select
                        v-if="routing_rule_data.factor == 'Payment Method'"
                        id="value4"
                        v-model="routing_rule_data.value"
                        :items="paymentMethodOptions"
                        class="py-2"
                        name="value"
                        @onChangeFunction="
                          getPaymentCategory(routing_rule_data.value)
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TableDefault>
        </div>

        <div>
          <form-error
            v-if="errorMsgForTableValues"
            :message="errorMsgForTableValues"
          />
        </div>

        <hr>
        <div class="flex p-3 boxBackground mb-5">
          <div
            class="
              w-full
              flex
              items-center
              justify-center
              justify-start
            "
          >
            <div class="w-1/3 flex items-center">
              <form-label for="name">
                {{ t('Gateway') }}
              </form-label>
              <div class="mx-2 w-full">
                <form-select
                  id="gateway"
                  v-model="form.gw"
                  :items="gatewayOptions"
                  :disable="gatewayDisable"
                  class="py-2"
                />

                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.gw
                      ? form.errors.validations.gw[0]
                      : ''
                  "
                />
              </div>
            </div>

            <div class="w-2/3 flex items-center">
              <form-label
                for="name"
                class="mx-4 w-80"
              >
                {{ t('API Configuration Name') }} <required />
              </form-label>
              <div class="mx-2 w-full">
                <form-input
                  id="rule_definition"
                  v-model="form.ruleDefinition"
                  class="py-2"
                />

                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.ruleDefinition
                      ? form.errors.validations.ruleDefinition[0]
                      : ''
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between space-x-3">
          <Button
            id="button_add-plan"
            class="btn-primary-dark btn-lg btn-wide shadow-lg ml-auto"
            type="button"
            @click="saveRuleDetails()"
          >
            {{ t('Save') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import TableDefault from '@/components/TableDefault'
import FormCheckbox from '@/components/FormCheckbox'
import FormInput from '@/components/FormInput'
import FormLabel from '@/components/FormLabel'
import Button from '@/components/Button'
import Modal from '@/components/Modal.vue'
import FormError from '@/components/FormError'
import Required from '@/components/Required'
import BackButton from '@/components/BackButton'
import { Tippy } from 'vue-tippy'

export default {
  name: 'RouterRuleAdd',

  components: {
    Loading,
    PageHeader,
    FormSelect,
    TableDefault,
    FormCheckbox,
    FormInput,
    FormLabel,
    Button,
    Modal,
    FormError,
    Required,
    BackButton,
    Tippy,
  },

  setup() {
    let onchange = ref(true)
    const { t } = useI18n()
    const title = ref('')
    const isLoading = ref(false)
    const routingRuleData = ref([])
    const routingRuleDataArray = ref([])
    const paymentMethods = ref(0)
    const route = useRoute()
    const router = useRouter()
    const errorMsgForSameFactor = ref('')
    const errorMsgForNullFactor = ref('')
    const errorMsgForTableValues = ref('')
    const filterOptions = ref([
      { value: '', text: 'Please select a filter' },
      { value: 'Merchant Account', text: 'Merchant Account' },
      { value: 'Transaction Operation', text: 'Transaction Operation' },
      { value: 'Payment Category', text: 'Payment Category' },
      { value: 'Payment Method', text: 'Payment Method' },
    ])

    let gatewayDisable = ref(false)
    const merchantAccountOptions = ref([{ value: '', text: 'ANY' }])

    const transactionOprnOptions = ref([
      { value: '', text: 'Please select a value' },
      { value: 'GW_STATUS', text: 'GW_STATUS' },
      { value: 'GW_FEE', text: 'GW_FEE' },
      { value: 'PAYMENT', text: 'PAYMENT' },
      { value: 'SIGNATURE_AUTH', text: 'SIGNATURE_AUTH' },
      { value: 'REFUND', text: 'REFUND' },
      { value: 'TOKEN_PAY', text: 'TOKEN_PAY' },
      { value: 'PAIR_CODE', text: 'PAIR_CODE' },
    ])

    const paymentCategoryOptions = ref([
      { value: '', text: 'Please select a value' },
      { value: 'CARD_PRESENT', text: 'CARD_PRESENT' },
      { value: 'CARD_NOT_PRESENT', text: 'CARD_NOT_PRESENT' },
    ])

    const paymentMethodOptions = ref([
      { value: '', text: 'Please select a value' },
      { value: 'ANY', text: 'ANY' },
    ])

    const gatewayOptions = ref([{ value: '', text: 'ANY' }])

    const tempGatewayOptions = ref([{ value: '', text: 'ANY' }])

    const operatorOptions = ref([{ value: '', text: 'EQUAL' }])

    const operatorOptionsPmMethod = ref([{ value: '', text: 'EQUAL' }])

    const statusOptions = ref([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])

    const form = reactive({
      rr_id: '',
      filterOption: '',
      ruleDescription: '',
      gw: '',
      ruleDefinition: '',
      rulePriority: 0,
      ruleStatus: 'ACT',
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    onMounted(() => {
      getPaymentMethods()
      if (route.name == 'router-rule-edit') {
        title.value = 'Edit'
      } else {
        title.value = 'Add'
        getGateways()
      }
    })

    function addFiltersToRouterRule(value) {
      let obj = {}
      let error = null
      let allPaymentMethods = true
      errorMsgForSameFactor.value = ''
      obj.factor = value

      routingRuleData.value.forEach((ruleData) => {
        if (ruleData.factor == value) {
          error = 'Not allowed to add same filter.'
        }
        if (
          obj.factor == 'Payment Method' &&
          ruleData.factor == 'Payment Category'
        ) {
          allPaymentMethods = false
        }
      })

      if (error != null) {
        errorMsgForSameFactor.value = error
      } else {
        routingRuleData.value.push(obj)
        routingRuleData.value.forEach((ruleData) => {
          if (ruleData.factor == 'Payment Method') {
            operatorOptionsPmMethod.value = [
              { value: '', text: 'EQUAL' },
            ]
          }
        })
        if (allPaymentMethods == true) {
          changePaymentMethods(null)
        }
      }

      form.filterOption = ''
      filterOptions.value = [
        { value: '', text: 'Please select a filter' },
        { value: 'Merchant Account', text: 'Merchant Account' },
        { value: 'Transaction Operation', text: 'Transaction Operation' },
        { value: 'Payment Category', text: 'Payment Category' },
        { value: 'Payment Method', text: 'Payment Method' },
      ]
    }

    function saveRuleDetails() {
      let SubmitArr = {}
      let url = ''
      let msg = ''
      let error = false
      errorMsgForTableValues.value = ''
      errorMsgForNullFactor.value = ''
      if (route.name == 'router-rule-edit') {
        url = 'v1/edit-router-rule/' + route.params.rule_id
        msg = 'Router rule updated successfully.'
      } else {
        url = 'v1/create-router-rule'
        msg = 'Router rule created successfully.'
      }
      SubmitArr.data = routingRuleData.value
      SubmitArr.gw = form.gw
      SubmitArr.ruleDefinition = form.ruleDefinition
      SubmitArr.ruleDescription = form.ruleDescription
      SubmitArr.rulePriority = form.rulePriority
      SubmitArr.rr_id = form.rr_id
      SubmitArr.ruleStatus = form.ruleStatus

      if (SubmitArr.data.length != 0) {
        routingRuleData.value.find((ruleData) => {
          if (ruleData.factor == 'Transaction Operation') {
            if (!ruleData.value) {
              error = true
            }
          }
          if (ruleData.factor == 'Payment Category') {
            if (!ruleData.value) {
              error = true
            }
          }
          if (ruleData.factor == 'Payment Method') {
            if (!ruleData.value) {
              error = true
            }
          }
        })

        if (error == false) {
          axios.post(url, SubmitArr).then((response) => {
            if (response.data != null && !response.data.data.errors) {
              modal.type = 'success'
              modal.title = 'Success'
              modal.message = msg
              modal.show = true
            } else {
              form.errors = response.data.data.errors
            }
          })
        } else {
          if (routingRuleData.value.length == 1) {
            errorMsgForTableValues.value = 'Please select a value for the filter.'
          } else {
            errorMsgForTableValues.value =
              'Please select values for the filters.'
          }
        }
      } else {
        errorMsgForNullFactor.value = 'Please add at least one filter.'
      }
    }

    function clickCheckbox(event, index) {
      if (event.target.checked == false) {
        routingRuleData.value.splice(parseInt(index), 1)
        event.target.checked = true
      }
    }

    function getGateways(gw_id = null) {
      gatewayDisable.value = false
      axios.get('v1/gateway/dropdown-list', {}).then((response) => {
        if (response.data.data != null) {
          response.data.data.find((gatewayData) => {
            if (gw_id != gatewayData.gw_id) {
              let obj = {}
              obj.text = gatewayData.name
              obj.value = gatewayData.gw_id
              gatewayOptions.value.push(obj)
              tempGatewayOptions.value.push(obj)
            }
            if(routingRuleDataArray.value['tran_oprn'] == "TOKEN_PAY"){
              form.gw = null
              gatewayOptions.value =[{ value: '', text: 'ANY' }];
              gatewayDisable.value = true
            }
          })
        }
      })
    }

    function getPaymentMethods() {
      isLoading.value = true
      axios
          .get('v1/gateway/payment-methods/get-tnt-payment-methods', {
          })
          .then((response) => {
            if (response.data.data != null) {
              isLoading.value = false
              paymentMethods.value = response.data.data
              if(route.name == 'router-rule-edit'){
                getRouterRuleByRuleId()
              }
            }
          })
      isLoading.value = false
    }

    function getRouterRuleByRuleId(){
      isLoading.value = true
      const rule_id = route.params.rule_id
      gatewayOptions.value = [{ value: '', text: 'ANY' },]
      paymentMethodOptions.value = [
        { value: '', text: 'Please select a value' },
      ]
      let objPaymentMethod = {}
      objPaymentMethod.text = 'ANY'
      objPaymentMethod.value = 'ANY'

      axios.get('/v1/get-router-rule-by-rule-id/' + rule_id).then((response) => {
        if (response.data != null) {
          routingRuleDataArray.value = response.data.data
          let objMerchantAccount = {}
          let objTranOperation = {}
          let objPmCategory = {}
          let objPmMethod = {}
          if (routingRuleDataArray.value.is_checked_mch_acc_id == 1) {
            objMerchantAccount.factor = 'Merchant Account'
            objMerchantAccount.operator = ''
            objMerchantAccount.value = ''
            routingRuleData.value.push(objMerchantAccount)
          }
          if (routingRuleDataArray.value.is_checked_tran_oprn == 1) {
            objTranOperation.factor = 'Transaction Operation'
            objTranOperation.operator = ''
            objTranOperation.value = routingRuleDataArray.value.tran_oprn
            routingRuleData.value.push(objTranOperation)
          }

          form.ruleDefinition = routingRuleDataArray.value.rr_def
          form.ruleDescription = routingRuleDataArray.value.rr_des
          form.rulePriority = routingRuleDataArray.value.rr_priority
          form.rr_id = routingRuleDataArray.value.rr_id
          form.ruleStatus = routingRuleDataArray.value.status

          if (routingRuleDataArray.value.gw_id != 'ANY') {
            form.gw = routingRuleDataArray.value.gw_id
            let objGw = {}
            objGw.text = routingRuleDataArray.value.name
            objGw.value = routingRuleDataArray.value.gw_id
            gatewayOptions.value.push(objGw)
          }


          if (routingRuleDataArray.value.is_checked_pm_category == 1) {
            objPmCategory.factor = 'Payment Category'
            objPmCategory.operator = ''
            objPmCategory.value = routingRuleDataArray.value.pm_category
            routingRuleData.value.push(objPmCategory)

            if (routingRuleDataArray.value.pm_category == 'CARD_PRESENT') {
              paymentMethodOptions.value.push(objPaymentMethod)
              paymentMethods.value.find((paymentMethodData) => {
                if (paymentMethodData.pm_cat == 'CARD_PRESENT') {
                  let obj = {}
                  obj.text = paymentMethodData.description
                  obj.value = paymentMethodData.pm_uid
                  paymentMethodOptions.value.push(obj)
                }
              })
            } else {
              paymentMethods.value.find((paymentMethodData) => {
                if ((paymentMethodData.pm_cat != 'CARD_PRESENT') && (paymentMethodData.pm_cat != 'CARD_NOT_PRESENT')) {
                  let obj = {}
                  obj.text = paymentMethodData.description
                  obj.value = paymentMethodData.pm_uid
                  paymentMethodOptions.value.push(obj)
                }
              })
            }

            if (routingRuleDataArray.value.is_checked_pm_method == 1) {
              objPmMethod.factor = 'Payment Method'
              objPmMethod.operator = routingRuleDataArray.value.pm_method_opr
              objPmMethod.value = routingRuleDataArray.value.pm_method
              setTimeout(() => (routingRuleData.value.push(objPmMethod)), 500)
              operatorOptionsPmMethod.value = [
                {value: 'EQUAL', text: 'EQUAL'},
              ]
            }


            getGateways(routingRuleDataArray.value.gw_id)
          } else {
            console.error('Error!')
          }
        }
        })
      isLoading.value = false
    }

    function closeAlert() {
      modal.show = false
      router.push('/settings/router-rule-index')
    }

    function onlyNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    }

    function changePaymentMethods(category) {
      paymentMethodOptions.value = [
        { value: '', text: 'Please select a value' },
      ]
      routingRuleData.value.find((ruleData) => {
        if(ruleData.factor == 'Payment Method'){
          ruleData.value = ''
        }
      })
      let objPaymentMethod = {}
      objPaymentMethod.text = 'ANY'
      objPaymentMethod.value = 'ANY'

      if (category == null || category == '') {
        paymentMethodOptions.value.push(objPaymentMethod)
        paymentMethods.value.find((paymentMethodData) => {
          if (paymentMethodData.pm_cat != 'CARD_NOT_PRESENT') {
            let obj = {}
            obj.text = paymentMethodData.description
            obj.value = paymentMethodData.pm_uid
            paymentMethodOptions.value.push(obj)
          }
        })
      } else {
        if (category == 'CARD_PRESENT') {
          paymentMethodOptions.value = [{ value: '', text: 'Please select a value' },]
          paymentMethodOptions.value.push(objPaymentMethod)
          paymentMethods.value.find((paymentMethodData) => {
            if (paymentMethodData.pm_cat == 'CARD_PRESENT') {
              let obj = {}
              obj.text = paymentMethodData.description
              obj.value = paymentMethodData.pm_uid
              paymentMethodOptions.value.push(obj)
            }
          })
        } else {
          paymentMethodOptions.value = [{ value: '', text: 'Please select a value' },]
          paymentMethods.value.find((paymentMethodData) => {
            if ((paymentMethodData.pm_cat != 'CARD_PRESENT') && (paymentMethodData.pm_cat != 'CARD_NOT_PRESENT')) {
              let obj = {}
              obj.text = paymentMethodData.description
              obj.value = paymentMethodData.pm_uid
              paymentMethodOptions.value.push(obj)
            }
          })
        }
      }
    }

    function getPaymentCategory(paymentMethod) {
      let category = null
      paymentMethods.value.find((paymentMethodData) => {
        if (paymentMethod == paymentMethodData.pm_uid) {
          category = paymentMethodData.pm_cat
        }
      })
      if (paymentMethod == 'ANY') {
        routingRuleData.value.forEach((ruleData) => {
          if (ruleData.factor == 'Payment Category') {
            ruleData.value = 'CARD_PRESENT'
          }
        })
      } else if (paymentMethod != '') {
        if (category == 'CARD_PRESENT') {
          routingRuleData.value.forEach((ruleData) => {
            if (ruleData.factor == 'Payment Category') {
              ruleData.value = 'CARD_PRESENT'
            }
          })
        } else {
          routingRuleData.value.forEach((ruleData) => {
            if (ruleData.factor == 'Payment Category') {
              ruleData.value = 'CARD_NOT_PRESENT'
            }
          })
        }
      } else {
        paymentCategoryOptions.value = [
          { value: '', text: 'Please select a value' },
          { value: 'CARD_PRESENT', text: 'CARD_PRESENT' },
          { value: 'CARD_NOT_PRESENT', text: 'CARD_NOT_PRESENT' },
        ]
        routingRuleData.value.forEach((ruleData) => {
          if (ruleData.factor == 'Payment Category') {
            ruleData.value = ''
          }
        })
      }
    }

    function setGateways(){
      let loopBreak = false
      gatewayDisable.value = false
      form.gw = null
      routingRuleData.value.forEach((ruleData) => {
        if (!loopBreak){
          if (ruleData.factor == 'Transaction Operation' && ruleData.value == 'TOKEN_PAY') {
            gatewayOptions.value =[{ value: '', text: 'ANY' }];
            gatewayDisable.value = true
            loopBreak = true
          }else {
            gatewayOptions.value = tempGatewayOptions.value;
            loopBreak = false
          }
        }
      })
    }

    return {
      t,
      onMounted,
      routingRuleData,
      addFiltersToRouterRule,
      onchange,
      filterOptions,
      form,
      saveRuleDetails,
      clickCheckbox,
      merchantAccountOptions,
      transactionOprnOptions,
      paymentCategoryOptions,
      paymentMethodOptions,
      gatewayOptions,
      operatorOptions,
      operatorOptionsPmMethod,
      statusOptions,
      getRouterRuleByRuleId,
      modal,
      closeAlert,
      errorMsgForSameFactor,
      errorMsgForNullFactor,
      onlyNumbers,
      title,
      getPaymentMethods,
      isLoading,
      getPaymentCategory,
      errorMsgForTableValues,
      changePaymentMethods,
      setGateways,
      gatewayDisable,
    }
  },
}
</script>
<style scoped>
.table_overflow {
  height: calc(100vh - 450px);
  overflow-y: auto;
}
.boxBackground {
  background-color: #f7f8fa;
}
</style>
