<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Transaction Router") }}
        </h1>

        <div class="flex items-center justify-between space-x-3">
          <Button
            id="button_add-plan"
            class="btn-primary-dark btn-lg btn-wide shadow-lg"
            type="button"
            @click="addRule"
          >
            {{ t("Add") }}
          </Button>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page_1"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getTransactionRouter()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getTransactionRouter(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="transaction_router_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Description") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('description')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'description'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>

                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Valid") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('is_valid')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'is_valid'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>

                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Active") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transactionR, index) in transactionRoute[0]"
                :id="'transaction_router_' + index"
                :key="index"
              >
                <td class="max-w-3xl">
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/settings/rule-definition/edit/' + transactionR.rule_id }"
                    @click="viewDetails(transactionR.rule_id)"
                  >
                    {{ transactionR.description }}
                  </router-link>
                </td>
                <td class="text-center">
                  <badge
                    v-if="transactionR.is_valid == 1"
                    type="success"
                    text="Valid"
                  />
                  <badge
                    v-else
                    type="danger"
                    text="Invalid"
                  />
                </td>
                <td class="text-center">
                  <form-checkbox
                    v-model="transactionR.status"
                    name="status"
                    :checked="transactionR.status == 'ACT' ? true : false"
                    :model-value="transactionR.status == 'ACT' ? 'ACT' : 'INA'"
                    @click="statusChange(transactionR.status == 'ACT' ? 'INA' : 'ACT', transactionR.rule_id, index)"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'transaction_router_copy_' + index"
                      type="info"
                      icon="copy"
                      @click="copyApiTransactionRoute(transactionR.rule_id)"
                    />
                    <button-circle
                      :id="'transaction_router_edit_' + index"
                      type="info"
                      icon="edit"
                      @click="editRule(transactionR.rule_id)"
                    />
                    <button-circle
                      :id="'transaction_router_delete' + index"
                      type="danger"
                      icon="trash"
                      @click="confirmDeleteRule(transactionR.rule_id)"
                    />
                  </div>
                </td>
              </tr>
              <no-table-data v-if="totalValue == 0" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalValue"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getTransactionRouter($event)"
      />
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert(modal.status, modal.itemIndex)"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import Badge from '@/components/Badge.vue'
import axios from 'axios'
import router from '@/routes'
import NoTableData from '@/components/NoTableData.vue'

export default {
  name: 'TransactionRouter',

  components: {
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    FormSelect,
    PageHeader,
    TableDefault,
    ButtonCircle,
    VuePagination,
    FormCheckbox,
    Badge,
    NoTableData,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const onchange = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const perPage = ref(10)
    const currentPage = ref(1)
    const totalValue = ref(0)
    const lastPage = ref(null)
    const selectedRuleID = ref(null)
    const status = ref(null)
    const totalRecord = ref(null)
    const transactionRoute = reactive([])
    const searchText = ref('')
    const store = useStore()
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      status: null,
      itemIndex: null,
      close_button: true,
      confirm_button: true,
      button_text: 'No',
      confirm_button_text: 'Yes',
    })

    const statusChangeSubmit = reactive({
      rule_id: null,
      status: null,
    })

    const statusOptions = reactive([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])
    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getTransactionRouter()
    }

    function addRule() {
      router.push({ name: 'router-rule' })
    }

    function editRule(id) {
      router.push({
        name: 'edit-rule-definition',
        params: { slug: id },
      })
    }

    async function getTransactionRouter(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('v1/transactionrouter/rule/definition', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            transactionRoute.splice(0)
            transactionRoute.push(response.data.data.data)
            totalValue.value = response.data.data.total
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getTransactionRouter(1, sortColumn.value, searchText.value)
    }

    async function confirmAlert() {
      if(status.value != 'delete'){
        if (statusChangeSubmit.rule_id != null) {
          if (statusChangeSubmit.status == 'ACT'){
            statusChangeSubmit.status = 'INA'
          }else if( statusChangeSubmit.status == 'INA'){
            statusChangeSubmit.status = 'ACT'
          }
          await store.dispatch(
            'TransactionRules/changeRuleConfigStatus',
            statusChangeSubmit
          )
          await getTransactionRouter()
          modal.show = false
        }
      } else {
       await store.dispatch(
          'TransactionRules/deleteTransactionRule',
          selectedRuleID.value
        )
        await getTransactionRouter()
        modal.show = false
      }
    }

    function closeAlert(checkedStatus, itemIndex) {
      if(status.value != 'delete') {
        if (checkedStatus == 'INA') {
          transactionRoute[0][itemIndex].status = 'ACT'
        } else {
          transactionRoute[0][itemIndex].status = 'INA'
        }
      }
      selectedRuleID.value = null;
      status.value = null;
      modal.show = false
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }
    function checkRule(stat) {
      return stat == 'ACT' ? true : false
    }

    function confirmDeleteRule(ruleId) {
      selectedRuleID.value = ruleId
      status.value = "delete"
      modal.type = 'warning'
      modal.confirm_button = true
      modal.title = 'Warning'
      modal.button_text = 'No'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function statusChange(status, ruleID, index) {
      if (status == 'INA') {
        statusChangeSubmit.rule_id = ruleID
        statusChangeSubmit.status = 'ACT'
        modal.status = 'INA'
        modal.itemIndex = index
        transactionRoute[0][index].status = 'INA'
        modal.show = true
        modal.title = 'De-Activate Alert'
        modal.message =
          'You are about to de-activate a transaction router rule. Are you sure you want to continue?'

      } else if (status == 'ACT') {
        statusChangeSubmit.rule_id = ruleID
        statusChangeSubmit.status = 'INA'
        modal.status = 'ACT'
        modal.itemIndex = index
        transactionRoute[0][index].status = 'ACT'
        modal.show = true
        modal.title = 'Activate Alert'
        modal.message =
          'You are about to activate a transaction router rule. Are you sure you want to continue?'
      }
    }

    async function copyApiTransactionRoute(ruleId) {
      await store.dispatch('TransactionRules/copyTransactionRule', ruleId)
      let response = await store.state.TransactionRules.ruleOutput
      if (response.status == true) {
        await getTransactionRouter()
      }
    }

    function viewDetails(id) {
      router.push({
        name: 'edit-rule-definition',
        params: { slug: id, mode:'view' },
      })
    }
    return {
      t,
      modal,
      fullPage,
      isLoading,
      addRule,
      transactionRoute,
      searchText,
      sortColumnDirection,
      sortColumn,
      getTransactionRouter,
      perPage,
      currentPage,
      lastPage,
      status,
      checkRule,
      sort,
      statusChange,
      confirmAlert,
      editRule,
      closeAlert,
      copyApiTransactionRoute,
      confirmDeleteRule,
      viewDetails,
      totalValue,
    }
  },
}
</script>
