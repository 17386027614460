<!--
  *********************************************************************************
  Changing HTML structure will affect styles / collapse behavior of the navigation!
  TODO: Refactor
  *********************************************************************************
-->

<template>
  <nav
    class="mt-5 flex-1 px-2 bg-white"
    :class="[
      $store.state.sidebarDisable ? 'opacity-50 pointer-events-none' : '',
    ]"
  >
    <ul class="mb-2">
      <li
        v-for="(navItem, key) in activeUserMenu()"
        :key="key"
      >
        <router-link
          v-if="navItem.isAuthorized == true && !navItem.child"
          :id="'navlink-' + key + '_' + navItem.routeName"
          :to="{ name: navItem.routeName }"
          :class="[
            navItem.routeName == activeRouteName ? 'router-link-active' : '',
          ]"
          class="nav-link text-base text-gray-900"
          @click="toggleMenuGroup()"
        >
          <svg-icon
            class="text-gray-400 mr-3 w-auto h-6"
            :icon="navItem.icon"
          />
          <div class="flex items-center w-full overflow-hidden">
            <span class="w-full break-all">{{ navItem.title }}</span>
          </div>
        </router-link>
        <div
          v-else-if="navItem.isAuthorized == true && navItem.child"
          :id="'navgroup-' + key + '_' + navItem.routeName"
        >
          <a
            :id="'navgroup-btn' + key + '_' + navItem.routeName"
            href="#"
            class="nav-link nav-link_group text-base text-gray-900"
            :class="[
              navItem.menuGroup === activeMenuGroup ? 'router-link-active' : '',
            ]"
            @click="
              toggleMenuGroup('navgroup-btn' + key + '_' + navItem.routeName)
            "
          >
            <svg-icon
              class="text-gray-400 mr-3 w-auto h-6"
              :icon="navItem.icon"
            />
            <div class="flex items-center w-full overflow-hidden">
              <span
                class="w-full text-left break-all"
              >{{ navItem.title }}
              </span>
              <svg-icon
                v-if="navItem.child"
                class="arrowhead text-gray-400 w-6 h-6 transform"
                icon="caret-right"
              />
            </div>
          </a>
          <ul
            v-if="navItem.child"
            class="nav-group pl-9"
            :class="[navItem.menuGroup === activeMenuGroup ? '' : 'hidden']"
          >
            <li
              v-for="(subNavItem, subKey) in navItem.child"
              :key="subKey"
            >
              <router-link
                v-if="subNavItem.isAuthorized == true && !subNavItem.child"
                :id="'sub-navlink-' + subKey + '_' + subNavItem.routeName"
                :to="{ name: subNavItem.routeName }"
                :class="[
                  subNavItem.routeName == activeRouteName
                    ? 'router-link-active'
                    : '',
                ]"
                class="nav-link text-sm text-gray-800"
              >
                <span class="flex-1 truncate">{{ subNavItem.title }}</span>
              </router-link>
              <div
                v-if="subNavItem.isAuthorized == true && subNavItem.child"
                :id="'child-navgroup-' + key + '_' + subNavItem.routeName"
              >
                <a
                  :id="'child-navgroup-btn' + key + '_' + subNavItem.routeName"
                  href="#"
                  class="nav-link nav-link_group text-sm text-gray-800"
                  :class="[
                    subNavItem.menuGroup === activeChildMenuGroup
                      ? 'router-link-active'
                      : '',
                  ]"
                  @click="
                    toggleMenuGroup(
                      'child-navgroup-btn' + key + '_' + subNavItem.routeName
                    )
                  "
                >
                  <div class="flex items-center w-full">
                    <span
                      class="w-full truncate text-left"
                    >{{ subNavItem.title }}
                    </span>
                    <svg-icon
                      v-if="subNavItem.child"
                      class="arrowhead text-gray-400 w-6 h-6 transform"
                      icon="caret-right"
                    />
                  </div>
                </a>
                <ul
                  v-if="subNavItem.child"
                  class="nav-group pl-5"
                  :class="[
                    subNavItem.menuGroup === activeChildMenuGroup
                      ? ''
                      : 'hidden',
                  ]"
                >
                  <li
                    v-for="(subChildNavItem, subChildKey) in subNavItem.child"
                    :key="subChildKey"
                  >
                    <router-link
                      v-if="subChildNavItem.isAuthorized == true"
                      :id="
                        'sub-navlink-' +
                          subChildKey +
                          '_' +
                          subChildNavItem.routeName
                      "
                      :to="{ name: subChildNavItem.routeName }"
                      :class="[
                        subChildNavItem.routeName == activeRouteName
                          ? 'router-link-active'
                          : '',
                      ]"
                      class="nav-link text-sm text-gray-800"
                    >
                      <span class="flex-1 truncate">{{
                        subChildNavItem.title
                      }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from 'axios'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref, reactive, watchEffect, watch } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useI18n } from 'vue-i18n'
import constant from "@/constant";
import CryptoJs from 'crypto-js'

export default {
  name: 'Nav',

  components: {
    SvgIcon,
  },

  setup() {
    const { t, te, tm } = useI18n()
    const route = useRoute()
    const store = useStore()
    const activeRouteName = ref('')
    const activeMenuGroup = ref('')
    const activeChildMenuGroup = ref('')
    const activeMenu = ref('')
    const securityGrants = ref([])
    const CryptoJsAesJson = constant.CryptoJsAesJson

    const user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data
      .user.user

    const adminNavItems = reactive([
      {
        title: t("Home"),
        icon: 'home',
        routeName: 'dashboard-index',
        isAuthorized: true,
      },
      {
        title: t("Registrations"),
        icon: 'users',
        routeName: 'registrations-index',
        isAuthorized: false,
        securityGrantId: 'TNT_1',
      },
      {
        title: t("Manage Merchants"),
        icon: 'merchant',
        routeName: 'merchants-index',
        isAuthorized: false,
        securityGrantId: 'TNT_17',
      },
      {
        title: t("Billing"),
        icon: 'tag',
        routeName: 'billing',
        menuGroup: 'billing',
        isAuthorized: false,
        securityGrantId: 'TNT_3',
        child: [
          {
            title: t("Generate Invoices"),
            routeName: 'generate-invoices',
            menuGroup: 'billing',
            isAuthorized: false,
            securityGrantId: 'TNT_21',
          },
          {
            title: t("Billing History"),
            routeName: 'billing-history',
            menuGroup: 'billing',
            isAuthorized: false,
            securityGrantId: 'TNT_22',
          },
        ],
      },

      {
        title: t("Transactions"),
        icon: 'dollar',
        routeName: 'transactions-index',
        isAuthorized: false,
        securityGrantId: 'TNT_15',
      },
       {
        title: t("QR Payments"),
        icon: 'qr-code',
        routeName: 'qrprogram-index',
        isAuthorized: false,
        securityGrantId: 'TNT_19',
      },
            {
        title: t("Manage Tokens"),
        icon: 'token',
        routeName: 'manage-token-index',
        isAuthorized: false,
        securityGrantId: 'TNT_18',
      },

      {
        title: t("Settings"),
        icon: 'cog',
        routeName: 'settings',
        menuGroup: 'settings',
        isAuthorized: false,
        securityGrantId: 'TNT_4',
        child: [
          {
            title: t("Countries & Currencies"),
            routeName: 'countries-currencies',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_5',
          },
          {
            title: t("Gateways"),
            routeName: 'gateways',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_6',
          },
          {
            title: t("Tax Groups"),
            routeName: 'tax-groups',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_7',
          },
          {
            title: t("Plans"),
            routeName: 'plans',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_8',
          },
          {
            title: t("Languages"),
            routeName: 'languages',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: '',
          },
          {
            title: t("Security"),
            icon: 'lock',
            routeName: 'security',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_9',
            child: [
              {
                title: t("Security Profiles"),
                routeName: 'security-profiles',
                menuGroup: 'settings',
                childMenuGroup: 'settings',
                isAuthorized: false,
                securityGrantId: 'TNT_10',
              },
              {
                title: t("Users"),
                routeName: 'users',
                menuGroup: 'settings',
                childMenuGroup: 'settings',
                isAuthorized: false,
                securityGrantId: 'TNT_11',
              },
            ],
          },
          {
            title: t("Terms & Conditions"),
            routeName: 'terms',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_12',
          },
          {
            title: t("Dropdown Fields"),
            routeName: 'dropdown-fields',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_13',
          },
          {
            title: t("Transaction Router"),
            routeName: 'router-rule-index',
            menuGroup: 'settings',
            isAuthorized: false,
            securityGrantId: 'TNT_16',
          },
        ],
      },
    ])

    const merchantNavItems = reactive([
      {
        title: t("Home"),
        icon: 'home',
        routeName: 'merchant-dashboard-index',
        isAuthorized: true,
      },
      {
        title: t("Accounts"),
        icon: 'users',
        routeName: 'accounts-index',
        isAuthorized: false,
        securityGrantId: 'MCH_1',
      },
      {
        title: t("Billing History"),
        icon: 'tag',
        routeName: 'merchant-billing-history-index',
        isAuthorized: false,
        securityGrantId: 'MCH_2',
      },
       {
        title: t("Transactions"),
        icon: 'dollar',
        routeName: 'merchant-transactions-index',
        isAuthorized: false,
        securityGrantId: 'MCH_3',
      },
      {
        title: t("QR Payments"),
        icon: 'qr-code',
        routeName: 'merchant-qrprogram-index',
        isAuthorized: false,
        securityGrantId: 'MCH_8',
      },
      {
        title: t("Manage Tokens"),
        icon: 'token',
        routeName: 'manage-merchant-token-index',
        isAuthorized: false,
        securityGrantId: 'MCH_7',
      },
      {
        title: t("Security"),
        icon: 'lock',
        routeName: 'merchant-security-profiles-index',
        menuGroup: 'merchant-security',
        isAuthorized: false,
        securityGrantId: 'MCH_4',
        child: [
          {
            title: t("Security Profiles"),
            routeName: 'merchant-security-profiles-index',
            menuGroup: 'merchant-security',
            isAuthorized: false,
            securityGrantId: 'MCH_5',
          },
          {
            title: t("Users"),
            routeName: 'merchant-users-index',
            menuGroup: 'merchant-security',
            isAuthorized: false,
            securityGrantId: 'MCH_6',
          },
        ],
      },

    ])

    const ownerNavItems = reactive([
      {
        title: t("Tenants"),
        icon: 'users',
        routeName: 'tenants-index',
        isAuthorized: false,
        securityGrantId: 'APO_1',
      },
      {
        title: t("Languages"),
        icon: 'lang',
        routeName: 'languages-index',
        isAuthorized: false,
        securityGrantId: 'APO_2',
      },
      {
        title: t("Countries & Currencies"),
        icon: 'cog',
        routeName: 'owner-countries-currencies-index',
        isAuthorized: false,
        securityGrantId: 'APO_3',
      },
      {
        title: t("Application data"),
        icon: 'cog',
        routeName: 'owner-app-data-index',
        isAuthorized: false,
        securityGrantId: 'APO_4',
      },
    ])

    watch (() =>{
      adminNavItems[0].title = t("Home")
      adminNavItems[1].title = t("Registrations")
      adminNavItems[2].title = t("Manage Merchants")
      adminNavItems[3].title = t("Billing")
      adminNavItems[3].child[0].title = t("Generate Invoices")
      adminNavItems[3].child[1].title = t("Billing History")
      adminNavItems[4].title = t("Transactions")
      adminNavItems[5].title = t("QR Payments")
      adminNavItems[6].title = t("Manage Tokens")
      adminNavItems[7].title = t("Settings")
      adminNavItems[7].child[0].title = t("Countries & Currencies")
      adminNavItems[7].child[1].title = t("Gateways")
      adminNavItems[7].child[2].title = t("Tax Groups")
      adminNavItems[7].child[3].title = t("Plans")
      adminNavItems[7].child[4].title = t("Languages")
      adminNavItems[7].child[5].title = t("Security")
      adminNavItems[7].child[5].child[0].title = t("Security Profiles")
      adminNavItems[7].child[5].child[1].title = t("Users")
      adminNavItems[7].child[6].title = t("Terms & Conditions")
      adminNavItems[7].child[7].title = t("Dropdown Fields")
      adminNavItems[7].child[8].title = t("Transaction Router")


      merchantNavItems[0].title = t("Home")
      merchantNavItems[1].title = t("Accounts")
      merchantNavItems[2].title = t("Billing History")
      merchantNavItems[3].title = t("Transactions")
      merchantNavItems[4].title = t("QR Payments")
      merchantNavItems[5].title = t("Manage Tokens")
      merchantNavItems[6].title = t("Security")
      merchantNavItems[6].child[0].title = t("Security Profiles")
      merchantNavItems[6].child[1].title = t("Users")


      ownerNavItems[0].title = t("Tenants")
      ownerNavItems[1].title = t("Languages")
      ownerNavItems[2].title = t("Countries & Currencies")
    })

    getSecurityGrantsPerSecurityProfile();
    setInterval(checkAppSession, 180000);

    function checkAppSession() {
      axios.post('idle').then((response) => {
          if (response.data == 'loggedOut') {
            localStorage.removeItem('access_token')
            localStorage.removeItem('user')
            localStorage.removeItem('security_grants')
            localStorage.removeItem('merchant')
            localStorage.removeItem('merchant_user_name')
            if(sessionStorage.getItem('isWidgetCustomized') !=  null) {
              sessionStorage.removeItem('isWidgetCustomized')
            }
            location.reload();
          }
      })
    }

    async function getSecurityGrantsPerSecurityProfile() {
      securityGrants.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.security_grants
      checkNavArray(adminNavItems)
      checkNavArray(merchantNavItems)
      checkNavArray(ownerNavItems)
    }

    function checkNavArray(array) {
      array.filter(function (elm) {
        if (
          checkGrants(elm.securityGrantId) ||
          checkParentGrants(elm.securityGrantId) ||
          checkSuperParentGrants(elm.securityGrantId)
        ) {
          elm.isAuthorized = true
          if (elm.child != null) {
            elm.child.filter(function (subElm) {
              if (
                checkGrants(subElm.securityGrantId) ||
                checkParentGrants(subElm.securityGrantId) ||
                checkSuperParentGrants(subElm.securityGrantId)
              ) {
                subElm.isAuthorized = true
                if (subElm.child != null) {
                  subElm.child.filter(function (subSubElm) {
                    if (
                      checkGrants(subSubElm.securityGrantId) ||
                      checkParentGrants(subSubElm.securityGrantId) ||
                      checkSuperParentGrants(subSubElm.securityGrantId)
                    ) {
                      subSubElm.isAuthorized = true
                    }
                  })
                  subElm.isAuthorized = true
                }
              }
            })
            elm.isAuthorized = true
          }
        }
      })
    }

    function checkGrants(securityGrantId) {
      var status = false
      securityGrants.value.filter(function (elm) {
        if (elm.sec_grant_id == securityGrantId) {
          status = true
        }
      })
      return status
    }

    function checkParentGrants(securityGrantId) {
      var status = false
      securityGrants.value.filter(function (elm) {
        if (elm.parent_sec_grant_id == securityGrantId) {
          status = true
        }
      })
      return status
    }

    function checkSuperParentGrants(securityGrantId) {
      var status = false
      securityGrants.value.filter(function (elm) {
        if (elm.super_grant_id == securityGrantId) {
          status = true
        }
      })
      return status
    }

    async function getLoggedInUser(uuid) {
      await axios.get('get-logged-in-user/' + uuid).then((response) => {
        if (response.data != null) {
          user.value = response.data
        }
      })
    }

    watchEffect(
      () => (
        (activeRouteName.value = route.meta.parent),
        (activeMenuGroup.value = route.meta.menuGroup),
        (activeChildMenuGroup.value = route.meta.childMenuGroup)
      )
    )

    function toggleMenuGroup(elementId) {
      if (elementId) {
        activeMenu.value = document.getElementById(elementId)
        activeMenu.value.nextSibling.classList.toggle('hidden')
        activeMenu.value.classList.toggle('toggle-nav')
      } else {
        let navGroupElement = document.querySelectorAll('.nav-group')
        for (let i = 0; i < navGroupElement.length; i++) {
          navGroupElement[i].classList.add('hidden')
        }

        let navLinkGroupElement = document.querySelectorAll('.nav-link_group')
        for (let i = 0; i < navLinkGroupElement.length; i++) {
          navLinkGroupElement[i].classList.remove('toggle-nav')
        }
      }
    }

    function activeUserMenu() {
      if (user.user_type == 'TNT') {
        return adminNavItems
      } else if (user.user_type == 'MCH') {
        return merchantNavItems
      }
      else if (user.user_type == 'APO') {
        return ownerNavItems
      }

    }

    return {
      route,
      user,
      activeMenu,
      activeUserMenu,
      adminNavItems,
      merchantNavItems,
      ownerNavItems,
      activeRouteName,
      activeMenuGroup,
      activeChildMenuGroup,
      toggleMenuGroup,
      securityGrants,
    }
  },
}
</script>

<style scoped>
.nav-link {
  @apply group;
  @apply flex;
  @apply items-center;
  @apply w-full;
  @apply px-2;
  @apply py-2;
  @apply font-medium;
  @apply rounded;
  @apply hover:text-black;
}

.nav-link.router-link-active {
  @apply hover:text-primary;
}

.router-link-active {
  @apply text-primary;
}

.router-link-active .arrowhead,
.toggle-nav .arrowhead {
  @apply rotate-90;
}

.router-link-active.toggle-nav .arrowhead {
  @apply rotate-0;
}

.router-link-active svg {
  @apply text-primary;
}
</style>
