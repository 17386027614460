<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div class="p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2
              id="heading-sign_in"
              class="mb-3 text-2xl font-medium text-black"
            >
              Sign in to opuspay
            </h2>
            <h5 class="text-black text-opacity-70 font-medium">
              Welcome to opuspay. Please sign in to continue.
            </h5>
          </div>

          <div class="mt-8">
            <div class="mt-6 space-y-5">
              <!-- <form class=""> -->
              <div>
                <form-label
                  id="label-username"
                  for="user_name"
                >
                  Username
                </form-label>
                <form-input
                  id="user_name"
                  v-model="form.username"
                  class="mt-1 border-primary-lighter"
                  autocomplete="user_name"
                  name="user_name"
                  type="text"
                  autofocus
                  :disabled="showPasswordField"
                  @keypress.enter="getUser"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.user_name
                      ? form.errors.validations.user_name[0]
                      : ''
                  "
                />
              </div>
              <div v-if="showPasswordField">
                <form-label
                  id="label-password"
                  for="password"
                >
                  Password
                </form-label>
                <div class="mt-1 relative">
                  <form-input
                    id="password"
                    v-model="form.password"
                    class="pr-10 border-primary-lighter"
                    autocomplete="password"
                    autofocus
                    :name="form.passwordFieldType"
                    :type="form.passwordFieldType"
                    @keyup.enter="onSubmit"
                  />
                  <button
                    class="
                      absolute
                      w-7
                      h-7
                      inset-y-0
                      right-0
                      flex
                      items-center
                      justify-center
                      my-auto
                      mr-1
                      p-1
                      rounded-sm
                      opacity-75
                      hover:opacity-100
                      focus:ring-2 focus:ring-offset-1 focus:ring-primary
                      focus:outline-none
                      z-10
                    "
                    type="button"
                    @click="switchPasswordVisibility"
                  >
                    <svg-icon
                      v-if="form.passwordFieldType === 'text'"
                      class="w-5 h-5 text-gray-900"
                      icon="visible"
                    />
                    <svg-icon
                      v-if="form.passwordFieldType === 'password'"
                      class="w-5 h-5 text-gray-900"
                      icon="invisible"
                    />
                  </button>
                </div>
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.password[0]
                      ? form.errors.validations.password[0]
                      : ''
                  "
                />
              </div>

              <div class="flex items-center justify-end">
                <router-link
                  id="link-forgot_password"
                  class="
                    -mt-1
                    inline-block
                    font-medium
                    text-sm text-gray-400
                    leading-none
                    underline
                    hover:text-primary
                    focus:ring-2 focus:ring-offset-1 focus:ring-primary
                    focus:outline-none
                    rounded-sm
                  "
                  :to="{ name: 'forgot-password' }"
                >
                  Forgot Password?
                </router-link>
              </div>

              <Button
                v-if="!showPasswordField"
                id="button-next"
                class="w-full"
                type="button"
                @click="getUser"
              >
                Next
              </Button>

              <Button
                v-if="showPasswordField"
                id="button-signin"
                class="mt-5 w-full"
                type="button"
                @click="onSubmit"
              >
                Log in
              </Button>
              <!-- </form> -->
            </div>
          </div>
        </div>
        <div class="mb-10 text-center mt-6 text-white text-sm sm:text-15 rounded-sm font-medium">
          Don’t have an account?
          <router-link
            id="link-signup"
            :to="{ name: 'signup' }"
            class="
                  underline
                  text-white
                  focus:ring-2 focus:ring-offset-1 focus:ring-primary
                  focus:outline-none
                  font-medium
                  rounded-sm
                "
          >
            Register from here
          </router-link>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>
</template>

<script>
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import axios from 'axios'
import CryptoJs from 'crypto-js'
import constant from "@/constant";

export default {
  name: 'SignIn',

  components: {
    Button,
    Loading,
    SvgIcon,
    LogoWhite,
    FormLabel,
    FormError,
    FormInput,
    AuthFooter,
  },

  setup() {
    const form = reactive({
      username: '',
      password: '',
      showPasswordField: false,
      passwordFieldType: 'password',
    })
    const router = useRouter()
    const store = useStore()
    const isLoading = ref(false)
    const fullPage = ref(true)
    let showPasswordField = ref(false)
    const user = ref(0)
    const securityGrants = ref([])
    const securityProfileId = ref(null)
    const CryptoJsAesJson = constant.CryptoJsAesJson

    resetSession()

    async function getUser() {
      isLoading.value = true
      form.errors = ''
      await store.dispatch('User/getUserInfo', form.username)

      if (store.state.User.userInfo && store.state.User.userInfo.username) {
        showPasswordField.value = true
      } else {
        form.errors = store.state.User.errors
      }
      isLoading.value = false
    }

    async function switchPasswordVisibility() {
      form.passwordFieldType =
        form.passwordFieldType === 'password' ? 'text' : 'password'
    }

    async function onSubmit() {
      let formData = {}
      localStorage.clear()

      form.errors = ''
      isLoading.value = true

      if (form) {
        formData = {
          username: CryptoJs.AES.encrypt(
            JSON.stringify(form.username),
            process.env.MIX_ENCRYPT_KEY,
            { format: CryptoJsAesJson }
          ).toString(),
          password: CryptoJs.AES.encrypt(
            JSON.stringify(form.password),
            process.env.MIX_ENCRYPT_KEY,
            { format: CryptoJsAesJson }
          ).toString(),
          showPasswordField: form.showPasswordField,
          passwordFieldType: form.passwordFieldType,
        }
      }
      await store.dispatch('User/postLoginUser', formData)

      if (store.state.User.loginStat === 'true') {
        await getLoggedInUser(JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.user.user
            .user_uuid
        )

        if (user.value.user_type == 'TNT') {
          await getSecurityGrantsPerSecurityProfile()
          await router.push({ name: 'dashboard-index' })
        } else if (user.value.user_type == 'MCH') {
          await getSecurityGrantsPerSecurityProfile()
          await router.push({ name: 'merchant-dashboard-index' })
        }
        else if (user.value.user_type == 'APO') {
          await getSecurityGrantsPerSecurityProfile()
          await router.push({ name: 'tenants-index' })
        }
        else {
          await router.push({ name: 'unauthorized-access' })
        }
      } else {
        isLoading.value = false
        form.errors = store.state.User.errors
      }
    }

    async function getLoggedInUser(uuid) {
      await axios.get('get-logged-in-user/' + uuid).then((response) => {
        if (response.data != null) {
          user.value = response.data
          localStorage.setItem('langCode', response.data.tnt_language.language_code)
          localStorage.setItem('countryCode', response.data.tnt_language.country_code.toLowerCase())
        }
      })
    }

    async function getSecurityGrantsPerSecurityProfile() {
      securityProfileId.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
        .authentication_data.user.user.sec_profile_id
      await axios
        .get('v1/security-grants-per-security-profile/' + securityProfileId.value)
        .then((response) => {
          if (response.data.data != null) {
            securityGrants.value = response.data.data

            let oldSecurityGrants = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
            oldSecurityGrants.authentication_data.security_grants = response.data.data

            localStorage.setItem('user', CryptoJs.AES.encrypt(
                JSON.stringify( oldSecurityGrants),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
            ).toString())
          }
        })
    }

    async function resetSession() {
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token')
        localStorage.clear()
      }
    }

    return {
      form,
      axios,
      getUser,
      onSubmit,
      fullPage,
      isLoading,
      showPasswordField,
      switchPasswordVisibility,
    }
  },
}
</script>
