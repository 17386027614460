window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    currencyListDropDown: [],
    currencyIsoCodes: [],
  },

  mutations: {
    currencyListData(state, data) {
      state.currencyListDropDown = data
    },
  },
  actions: {
    async getCurrencyList(
      { commit, state }, countryCode,
      message = 'Please select a currency'
    ) {
      let currencyList = [{ value: '', text: message }]
      await axios.get('v1/get-all-currencies-by-country-code/' +  countryCode).then((response) => {
        if (response.data.data != null) {
          response.data.data.find((currency) => {
            let obj = {}
            obj.text = currency.currency_name
            obj.value = currency.currency_code
            currencyList.push(obj)
          })
          commit('currencyListData', currencyList)
        }else {
          currencyList = [{ value: '', text: message }]
          commit('currencyListData', currencyList)
        }
      })
    },
  },
}
