<template>
  <div
    class="
      page-header
      sidebar-shadow
      py-6
      md:py-9
      flex flex-col
      justify-center
      bg-secondary
    "
    :class="{ 'theme-bg-secondary': isLoginAsMerchant }"
  >
    <div>
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <slot name="header-content" />
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";

export default {
  name: 'PageHeader',

  setup() {
    const isLoginAsMerchant = ref(false)

    checkLoginAsMerchant()

    function checkLoginAsMerchant(){
      if (localStorage.getItem('merchant') != null && localStorage.getItem('merchant') != ''){
        isLoginAsMerchant.value = true
      }
    }

    return{
      isLoginAsMerchant,
    }
  }
}
</script>

<style>
.page-title {
  @apply mr-2;
  @apply my-2;
  @apply text-lg;
  @apply font-semibold;
  @apply text-white;
  @apply md:text-2xl;
}

@screen md {
  .page-header {
    min-height: 126px;
  }
}
</style>
