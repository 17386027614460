<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <div class="mb-4 grid grid-cols-2 gap-y-3">
    <div class="flex items-center">
      <span class="text-sm">  {{ t("Show") }} </span>
      <div class="mx-2">
        <form-select
          id="results_per_page"
          v-model="perPage"
          class="py-2"
          name="results_per_page"
          :items="[10, 25, 50, 100]"
          @onChangeFunction="getAllPaymentMethods()"
        />
      </div>
      <span class="text-sm">  {{ t("entries") }} </span>
    </div>

    <div class="w-full flex items-center justify-end space-x-2">
      <Button
        v-if="actionBtn == 'edit'"
        type="button"
        class="whitespace-nowrap"
        :disabled="showAddNewPaymentMethodRow == true"
        @click="showAddNewPaymentMethodRowButton()"
      >
        {{ t("Add Row") }}
      </Button>
    </div>
  </div>

  <TableDefault>
    <template #table>
      <table
        class="table"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="flex items-center">
                <span>  {{ t("Payment Method") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('paymentMethod')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'paymentMethod'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th
              scope="col"
              class="th-action"
            >
              {{ t("Actions") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-if="showAddNewPaymentMethodRow"
            class="td-align-top"
          >
            <td>
              <div class="w-full md:w-1/3">
                <form-select
                  v-model="paymentMethodForm.paymentMethod"
                  class="py-1.5"
                  name="paymentMethod"
                  :items="paymentMethodOptions"
                />
                <form-error
                  v-if="errors.tnt_pm_id"
                  :message="errors.tnt_pm_id ? errors.tnt_pm_id : ''"
                />
              </div>
            </td>
            <td>
              <div class="td-cell">
                <div class="flex items-center justify-center space-x-5">
                  <button-circle
                    type="success"
                    icon="check"
                    @click="addGatewayPaymentMethod()"
                  />
                  <button-circle
                    type="danger"
                    icon="trash"
                    @click="showAddNewPaymentMethodRow = false"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr
            v-for="(paymentMethod, index) in paymentMethods"
            :id="'gateway_payment_methods_' + index"
            :key="index"
            name="fade"
          >
            <td
              v-if="paymentMethod.pm_cat != 'CARD_NOT_PRESENT'"
              class="max-w-5xl"
            >
              <span>{{ paymentMethod.description }}</span>
            </td>
            <td v-if="paymentMethod.pm_cat != 'CARD_NOT_PRESENT'">
              <div class="flex items-center justify-center space-x-5">
                <button-circle
                  type="danger"
                  icon="trash"
                  :disabled="
                    showAddNewPaymentMethodRow == true || actionBtn != 'edit'
                  "
                  @click="
                    deleteGatewayPaymentMethodModal(paymentMethod.gw_pm_id, paymentMethod.tnt_pm_id, paymentMethod.description)
                  "
                />
              </div>
            </td>
          </tr>

          <no-table-data v-if="!paymentMethods.length" />
        </tbody>
      </table>
    </template>
  </TableDefault>
  <vue-pagination
    :page-size="perPage"
    :total-records="totalRecord"
    :sort-column="sortColumn"
    :sort-direction="sortColumnDirection"
    :last-page="lastPage"
    @page-changed="getAllPaymentMethods($event)"
  />
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />

  <modal
    :type="warningModel.type"
    :show="warningModel.show"
    :title="warningModel.title"
    :message="warningModel.message"
    :close-button="warningModel.close_button"
    :confirm-button="warningModel.confirm_button"
    :button-text="warningModel.button_text"
    @closeModal="closeWarningAlert"
  />
</template>

<script>
import { ref, reactive, watchEffect } from 'vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormError from '@/components/FormError.vue'
import FormSelect from '@/components/FormSelect.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import router from "../../../routes";
import { useI18n } from 'vue-i18n'

export default {
  name: 'PaymentMethods',

  components: {
    Button,
    SvgIcon,
    FormError,
    FormSelect,
    NoTableData,
    ButtonCircle,
    TableDefault,
    VuePagination,
    Modal,
    Loading,
  },
  props: {
    gatewayId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: 'view',
    },
  },

  setup(props) {
    const { t, te, tm } = useI18n()
    const sortColumnDirection = ref('desc')
    let onchange = ref(true)
    let currencyOptions = ref(0)
    const gatewayID = ref(props.gatewayId)

    const paymentMethodOptions = [
      { value: '', text: 'Please select a payment method' },
    ]
    const actionBtn = ref(props.action)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const sortColumn = ref('')
    const lastPage = ref(0)
    const totalRecord = ref(0)
    const currentPage = ref(0)
    const perPage = ref(10)
    const paymentMethods = ref(0)
    const idToDeleteGatewayPaymentMethod = ref(null)
    const showAddNewPaymentMethodRow = ref(false)
    const $paymentMethodCategory = ref('')
    const deleteOptionValue = ref(false)
    const paymentMethodsAssignToGateway = ref([])
    const isAssignedToMerchant = ref(false)
    const pmId = ref('')

    watchEffect(() => {
      if (props.action != actionBtn.value) {
        actionBtn.value = props.action
      }
    })
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })

    const warningModel = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    getAllPaymentMethods()
    getAllPaymentMethodOptions()
    getAllPaymentMethodsAssignedToGateway(gatewayID.value)

    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: 'Edit Gateway',
    })

    const paymentMethodForm = reactive({
      paymentMethod: null,
      status: null,
    })

    const errors = reactive({
      tnt_pm_id: null,
    })

    const paymentMethodErrors = reactive({
      tnt_pm_id: null,
    })

    async function getAllPaymentMethods(page = 1, column = sortColumn.value) {
      isLoading.value = true
      await axios
        .get(
          '/v1/gateway/payment-methods/get-all/' +
            gatewayID.value +
            '?page=' +
            page +
            '&sortColumn=' +
            column +
            '&sortDirection=' +
            sortColumnDirection.value +
            '&count_per_page=' +
            perPage.value
        )
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            paymentMethods.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          }
        })
    }

    function deleteGatewayPaymentMethodModal(gateway_payment_method_id, gateway_tnt_pm_id, gateway_pm_description) {
      idToDeleteGatewayPaymentMethod.value = gateway_payment_method_id
      deleteOptionValue.value = false
      isAssignedToMerchant.value = false
      pmId.value = gateway_tnt_pm_id
      axios.get('/v1/gateway/payment-methods/get-payment-method-by-id/' + gateway_payment_method_id).then((response) => {
        if (response.data != null) {
          $paymentMethodCategory.value = response.data.data.gateway_tnt_payment_methods.pm_cat
          paymentMethods.value.find((data) => {
            if((data.pm_cat == 'CNP_CARD_AND_ACH') ||
              (data.pm_cat == 'CNP_CARD') ||
              (data.pm_cat == 'CNP_ACH') ||
              (data.pm_cat == 'CNP_BPAY'))
            {
              deleteOptionValue.value = true
            }
          });

          if($paymentMethodCategory.value === 'CARD_NOT_PRESENT'){
            if(deleteOptionValue.value == true){
              warningModel.type = 'warning'
              warningModel.title = 'Warning'
              warningModel.message =
                'There are sub payment methods linked to this. Please delete those and try again.'
              warningModel.show = true
            }else{
              modal.type = 'warning'
              modal.confirm_button = true
              modal.title = 'Warning'
              modal.button_text = 'No'
              modal.message =
                'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
              modal.show = true
            }
          }else{
            let value = false
            paymentMethodsAssignToGateway.value.find((pm_method) => {
              if(pm_method == gateway_tnt_pm_id){
                value = true
              }
            });

            if(value == true){
              modal.type = 'warning'
              modal.confirm_button = true
              modal.title = 'Warning'
              modal.button_text = 'No'
              modal.message =
                  'Payment Method ' + gateway_pm_description + ' is been used by one or more Merchants; Please confirm if you would like to delete ' + gateway_pm_description + ' from their settings.'
              modal.show = true
              isAssignedToMerchant.value = true
            }else{
              modal.type = 'warning'
              modal.confirm_button = true
              modal.title = 'Warning'
              modal.button_text = 'No'
              modal.message =
                  'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
              modal.show = true
            }
          }
        } else {
          console.error('Error!')
        }
      })
    }

    function closeAlert() {
      modal.show = false
      if (modal.button_text !== 'No') {
        modal.show = false
      }
    }

    function deleteGatewayPaymentMethod() {
      let data  = {}
      data.gw_id = gatewayID.value
      axios
        .delete(
          '/v1/gateway/payment-methods/delete/' +
            idToDeleteGatewayPaymentMethod.value
          ,{params : data}
        )
        .then((response) => {
          if (response.data != null) {
            getAllPaymentMethods(currentPage.value)
            resetErrors()
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function confirmAlert() {
      if (idToDeleteGatewayPaymentMethod.value != null) {
        if(isAssignedToMerchant.value == true){
          deleteGatewayPmInMerchantAccount()
        }else{
          deleteGatewayPaymentMethod()
          resetPaymentMethodForm()
        }

      }
      modal.show = false
    }

    function resetPaymentMethodForm() {
      paymentMethodForm.paymentMethod = null
      idToDeleteGatewayPaymentMethod.value = null
    }

    async function getAllPaymentMethodOptions() {
      isLoading.value = true
      await axios
        .get('v1/gateway/payment-methods/get-tnt-payment-methods')
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((paymentMethod) => {
              if(paymentMethod.pm_cat != 'CARD_NOT_PRESENT'){
                let obj = {}
                obj.text = paymentMethod.description
                obj.value = paymentMethod.tnt_pm_id
                paymentMethodOptions.push(obj)
              }
            })
          }
        })
      isLoading.value = false
    }

    function addGatewayPaymentMethod() {
      isLoading.value = true
      let form = {}
      if (!Number.isInteger(parseInt(paymentMethodForm.paymentMethod))) {
        paymentMethodForm.paymentMethod = null
      }
      form.gw_id = gatewayID.value
      form.tnt_pm_id = paymentMethodForm.paymentMethod
      form.status = 'ACT'
      resetErrors()
      axios.post('v1/gateway/payment-methods/create', form).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          isLoading.value = false
          resetPaymentMethodForm()
          modal.confirm_button = false
          modal.button_text = 'Ok'
          showAddNewPaymentMethodRow.value = false
          getAllPaymentMethods()
        } else {
          isLoading.value = false
          bindValidationErrors(response.data.data.errors.validations)
        }
      })
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function resetErrors() {
      errors.tnt_pm_id = null
    }

    async function sort(column) {
      isLoading.value = true
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getAllPaymentMethods(1, sortColumn.value)
    }

    function showAddNewPaymentMethodRowButton() {
      if (showAddNewPaymentMethodRow.value == false) {
        errors.tnt_pm_id = ''
        paymentMethodForm.paymentMethod = null
        showAddNewPaymentMethodRow.value = true
      } else {
        showAddNewPaymentMethodRow.value = false
      }
    }

    function closeWarningAlert() {
      warningModel.show = false
    }

    function getAllPaymentMethodsAssignedToGateway(gwId) {
      axios
          .get(
              '/v1/get-all-payment-methods-assigned-to-gateway/' + gwId
          )
          .then((response) => {
            if (response.data != null) {
              response.data.data.find((pm_methods) => {
                paymentMethodsAssignToGateway.value.push(pm_methods.tnt_pm_id)
              });
            }
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
    }

    function deleteGatewayPmInMerchantAccount(){
      axios
          .post(
              '/v1/delete-gateway-pm-in-merchant-accounts/' + gatewayID.value + '/' + pmId.value
          )
          .then((response) => {
            if (response.data != null) {
              deleteGatewayPaymentMethod()
              resetPaymentMethodForm()
            }
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
    }

    return {
      onchange,
      slideOver,
      sortColumnDirection,
      currencyOptions,
      gatewayID,
      errors,
      getAllPaymentMethods,
      isLoading,
      fullPage,
      perPage,
      totalRecord,
      sortColumn,
      lastPage,
      deleteGatewayPaymentMethodModal,
      modal,
      closeAlert,
      confirmAlert,
      paymentMethodForm,
      getAllPaymentMethodOptions,
      paymentMethodOptions,
      addGatewayPaymentMethod,
      paymentMethodErrors,
      sort,
      showAddNewPaymentMethodRow,
      showAddNewPaymentMethodRowButton,
      paymentMethods,
      actionBtn,
      t,
      $paymentMethodCategory,
      warningModel,
      closeWarningAlert,
      deleteOptionValue,
    }
  },
}
</script>
