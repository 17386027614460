<template>
  <div>
    <div
      id="jsoneditor-vue"
      style="height: 300px"
    />
  </div>
</template>

<script>
import 'vue-json-editor/assets/jsoneditor.css'
import JsonEditor from 'vue-json-editor/assets/jsoneditor'
import { ref, reactive, onMounted, nextTick } from 'vue'
export default {
  props: {
    value: { type: [String, Number, Object, Array], default: null },
    showBtns: [Boolean],
    expandedOnStart: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'code',
    },
    modes: {
      type: Array,
      default: function () {
        return ['tree', 'code', 'form', 'text', 'view']
      },
    },
    lang: {
      type: String,
      default: 'en',
    },
  },
  emits: ['json-change'],
  setup(props, context) {
    let editor = reactive()
    let error = reactive()
    let json = reactive(props.value)
    let internalChange = ref(false)
    const expandedModes = reactive(['tree', 'view', 'form'])

    let options = {
      mode: props.mode,
      modes: props.modes, // allowed modes
      onChange() {
        try {
          let jsonVal = editor.get()
          json = jsonVal
          error = false
          context.emit('json-change', json)
          internalChange = true
          // context.emit('input', jsonVal)
          nextTick(() => {
            internalChange = false
          })
        } catch (e) {
          error = true
          //console.log(e)
        }
      },
    }
    onMounted(() => {
      let jsonDiv = document.getElementsByClassName(
        'jsoneditor jsoneditor-mode-code'
      )
      for (var i = 0; i < jsonDiv.length; i++) {
        jsonDiv[i].style.display = 'none'
      }
      editor = new JsonEditor(
        document.getElementById('jsoneditor-vue'),
        options,
        json
      )
    })
    return {
      editor,
      options,
    }
  },
}
</script>

<style scoped>
.ace_line_group {
  text-align: left;
}
.json-editor-container {
  display: flex;
  width: 100%;
}
.json-editor-container .tree-mode {
  width: 50%;
}
.json-editor-container .code-mode {
  flex-grow: 1;
}
.jsoneditor-btns {
  text-align: center;
  margin-top: 10px;
}
.jsoneditor-vue .jsoneditor-outer {
  min-height: 150px;
}
.jsoneditor-vue div.jsoneditor-tree {
  min-height: 350px;
}
.json-save-btn {
  background-color: #20a0ff;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.json-save-btn:focus {
  outline: none;
}
.json-save-btn[disabled] {
  background-color: #1d8ce0;
  cursor: not-allowed;
}
code {
  background-color: #f5f5f5;
}
</style>
