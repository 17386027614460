<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <h1
        id="page-title"
        class="page-title"
      >
        {{ t("Registrations") }}
      </h1>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getRegistrationDetails()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getRegistrationDetails(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Date Submitted") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('created_at')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'created_at'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Merchant") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('organisation_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'organisation_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'country_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('reg_status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'reg_status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Reason") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('rejection_comments')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'rejection_comments'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(registration_data, index) in registration"
                :id="'registration_' + index"
                :key="index"
              >
                <td>{{ formatDateTime(registration_data.created_at) }}</td>
                <td class="max-w-xs">
                  {{ registration_data.organisation_name }}
                </td>
                <td>{{ registration_data.country_name }}</td>
                <td class="text-center">
                  <badge
                    v-if="registration_data.reg_status == 'REG_APPROVED'"
                    type="success"
                    text="Approved"
                  />
                  <badge
                    v-if="registration_data.reg_status == 'REG_REJECTED'"
                    type="danger"
                    text="Rejected"
                  />
                  <badge
                    v-if="registration_data.reg_status == 'REG_SUBMITTED'"
                    type="warning"
                    text="Pending"
                  />
                </td>
                <td class="max-w-sm">
                  {{
                    registration_data.reg_status == 'REG_REJECTED'
                      ? registration_data.rejection_types.description
                      : ''
                  }}
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'registration_view_' + index"
                      type="info"
                      icon="eye"
                      @click="
                        getRegistrationDetailsById(registration_data.reg_id),
                        (slideOver.show = true),
                        (disableRejectButton = false),
                        (disableApproveButton = false)
                      "
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!registration.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getRegistrationDetails($event)"
      />

      <slide-over
        slideover-size="max-w-5xl"
        :slideover-title="slideOver.title"
        :show="slideOver.show"
        :close-button="slideOver.close_button"
        @closeSlideOver="closeSlideOverPanel"
      >
        <template #slideover-body>
          <div v-if="showRegistrationDetails">
            <div>
              <div class="text-xs font-normal text-black opacity-70">
                {{ t("Merchant Account Name") }}
              </div>
              <div class="mt-1 text-black font-medium break-all">
                {{ form.organisationName }}
              </div>
            </div>

            <divider />

            <div>
              <div
                class="mb-6 font-semibold text-black text-opacity-80 text-lg"
              >
                {{ t("Contact Details") }}
              </div>
            </div>

            <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 break-words">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Last Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.lastName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Given Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.givenName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Business Email") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.businessMail }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Phone Number") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.phoneNo }}
                </div>
              </div>
            </div>

            <divider />

            <div>
              <div
                class="mb-6 font-semibold text-black text-opacity-80 text-lg"
              >
                {{ t("Address Details") }}
              </div>
            </div>

            <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 break-words">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Country") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.country }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("State") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.state }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Suburb/Town") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.suburb }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Post Code") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.postalCode }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Address Line 1") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.address1 }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Address Line 2") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.address2 }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Website URL") }}
                </div>
                <div
                  v-if="
                    form.websiteUrl != null && form.websiteUrl.length > urlCharCount
                  "
                  class="mt-1 text-black font-medium"
                >
                  <div> {{ form.websiteUrl }}</div>
                  <button
                    v-if="showFullUrl == true"
                    class="btn_th-sort btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
                    type="button"
                    @click="threeDot(form.orgWebsiteUrl, urlCharCount, false)"
                  >
                    {{ "Hide URL" }}
                  </button>
                  <button
                    v-else
                    class="btn_th-sort btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
                    type="button"
                    @click="threeDot(form.orgWebsiteUrl, urlCharCount, true)"
                  >
                    {{ "Show URL" }}
                  </button>
                </div>
                <div
                  v-else
                  class="mt-1 text-black font-medium"
                >
                  {{ form.websiteUrl }}
                </div>
              </div>
            </div>

            <divider />

            <div>
              <div
                class="mb-6 font-semibold text-black text-opacity-80 text-lg"
              >
                {{ t("Login Details") }}
              </div>
            </div>

            <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 break-words">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Username") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.username }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #slideover-actions>
          <div class="w-full flex items-center justify-end md:justify-between flex-wrap gap-3">
            <div class="text-sm">
              {{ t("Agreed") }}
              <router-link
                class="mr-0.5 text-primary underline"
                :to="{ name: 'registrations' }"
                @click="viewTermsAndCondition()"
              >
                {{ t("Terms & Conditions") }}
              </router-link>
              <span> on</span>
              {{ formatDateTime(form.tcId) }}
            </div>
            <div class="flex space-x-2">
              <ButtonSecondary
                id="registration_reject"
                class="btn-wide btn-danger"
                type="button"
                :disabled="buttonDisable"
                @click="rejectRegistration()"
              >
                {{ t("REJECT") }}
              </ButtonSecondary>
              <Button
                id="registration_approve"
                class="btn-wide"
                type="button"
                :disabled="buttonDisable"
                @click="approveRegistration()"
              >
                {{ t("APPROVE") }}
              </Button>
            </div>
          </div>
        </template>
      </slide-over>
    </div>
  </div>

  <modal-form
    :show="modalApprove.show"
    :close-button="modalApprove.close_button"
    :confirm-button="modalApprove.confirm_button"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  >
    <template #modal-body>
      <div class="space-y-4">
        <div>
          <form-label for="assign-plan">
            Assign a Plan <required />
          </form-label>
          <form-select
            id="assign-plan"
            v-model="form.plan"
            class="mt-1"
            name="assign-plan"
            :items="planOptions"
            required
          />
          <form-error
            v-if="noPlans == true"
            :message="'No plan is available for this country. Please refer to your manager to have this corrected'"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.plan
                ? form.errors.validations.plan[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label
            for="assign-tax-group"
          >
            Assign a Tax Group <required />
          </form-label>
          <form-select
            id="assign-tax-group"
            v-model="form.taxGroup"
            class="mt-1"
            name="assign-tax-group"
            :items="taxGroupOptions"
            required
          />
          <form-error
            v-if="noTaxGroups == true"
            :message="'No tax group is available. Please refer to your manager to have this corrected'"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.taxGroup
                ? form.errors.validations.taxGroup[0]
                : ''
            "
          />
        </div>

        <div class="flex items-center">
          <div class="mr-2">
            <form-checkbox
              id="act_as_agent"
              v-model="form.act_as_agent"
              :model-value="form.act_as_agent == 1 ? 0 : 1"
              :checked="form.act_as_agent == 1 ? true : false"
            />
          </div>
          <form-label
            for="assign-tax-group"
          >
            Main Agency Account
          </form-label>
        </div>
      </div>
    </template>

    <template #modal-actions>
      <Button
        class="btn-wide"
        type="button"
        :disabled="(form.taxGroup == '' || form.plan == '') || (disableApproveButton == true)"
        @click="proceedApproval()"
      >
        {{ t("APPROVE") }}
      </Button>
    </template>
  </modal-form>

  <modal-form
    :show="modalReject.show"
    :type="modalReject.type"
    :close-button="modalReject.close_button"
    :confirm-button="modalReject.confirm_button"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  >
    <template #modal-body>
      <div class="space-y-4">
        <div>
          <form-label
            for="reason"
          >
            {{ t("Please select the reason for rejection") }} <required />
          </form-label>
          <form-select
            id="reason"
            v-model="form.mch_reg_rej_type_id"
            class="mt-1"
            name="reason"
            :items="RejectReasonOptions"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.mch_reg_rej_type_id
                ? form.errors.validations.mch_reg_rej_type_id[0]
                : ''
            "
          />
          <form-error
            v-if="noRegistrationRejection == true"
            :message="'No rejection reason is available. Please refer to your manager to have this corrected.'"
          />
        </div>

        <div>
          <form-label for="comments">
            {{ t("Additional comments") }}
          </form-label>
          <form-textarea
            id="comments"
            v-model="form.rejection_comments"
            class="mt-1"
            name="comments"
          />
        </div>
      </div>
    </template>

    <template #modal-actions>
      <ButtonSecondary
        class="btn-danger btn-wide"
        type="button"
        :disabled="form.mch_reg_rej_type_id == '' || disableRejectButton == true"
        @click="proceedReject"
      >
        {{ t("REJECT") }}
      </ButtonSecondary>
    </template>
  </modal-form>

  <modal-form
    :show="modalTerms.show"
    :title="modalTerms.title"
    :modal-size="modalTerms.size"
    :close-button="modalTerms.close_button"
    :confirm-button="modalTerms.confirm_button"
    @closeModal="modalTerms.show = false"
  >
    <template #modal-body>
      <div
        class="tinymce-wrapper space-y-4"
        style="min-height: calc(100vh - 230px);"
      >
        <editor
          v-model="form.tcDescription"
          :disabled="true"
          :api-key="tinymce_api_key"
          :init="editorOptions"
          menubar="false"
          menu="false"
          toolbar="false"
        />
      </div>
    </template>
  </modal-form>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Divider from '@/components/Divider.vue'
import Required from '@/components/Required.vue'
import ModalForm from '@/components/ModalForm.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import FormTextarea from '@/components/FormTextarea.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import tinymceConfig from '@/plugins/tinymce-config.js'
import Editor from '@tinymce/tinymce-vue'
import router from "../routes"
import constant from "../constant";
import FormCheckbox from "@/components/FormCheckbox";

export default {
  name: 'Registrations',

  components: {
    Badge,
    Search,
    Button,
    SvgIcon,
    Loading,
    Divider,
    Required,
    ModalForm,
    SlideOver,
    FormError,
    FormLabel,
    FormSelect,
    PageHeader,
    NoTableData,
    TableDefault,
    FormTextarea,
    ButtonCircle,
    VuePagination,
    editor: Editor,
    ButtonSecondary,
    FormCheckbox,
  },

  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const registration = ref(0)
    const registrationFormData = ref(0)
    const countryOptions = ref([])
    const isLoading = ref(false)
    const fullPage = ref(true)
    const lastPage = ref(0)
    const perPage = ref('10')
    const sortColumn = ref('')
    const searchText = ref('')
    const searchParam = ref('')
    const slug = ref('registration_rejection')
    const sortColumnDirection = ref('desc')
    const showRegistrationDetails = ref(true)
    const showRegistrationApproval = ref(false)
    const showRegistrationRejection = ref(false)
    const taxGroupData = ref([])
    const buttonDisable = ref(false)
    const totatlRecord = ref(0)
    const currentPage = ref(0)
    const taxGroupOptions = ref([{ value: null, text: 'Select a Tax Group' }])
    const planOptions = ref([{ value: null, text: 'Select a Plan' }])
    const RejectReasonOptions = ref([{ value: null, text: 'Select a Reason' }])
    const editorOptions = reactive(tinymceConfig.viewOnModalSettings)
    const noPlans = ref(false)
    const noTaxGroups = ref(false)
    const noRegistrationRejection = ref(false)
    const disableApproveButton = ref(false)
    const disableRejectButton = ref(false)
    const showFullUrl = ref(false)
    const urlCharCount = ref(constant.urlCharCount)

    const slideOver = reactive({
      show: false,
      close_button: false,
      title: 'Merchant Registration Details',
    })

    const modalApprove = reactive({
      show: false,
      close_button: false,
      confirm_button: false,
    })

    const modalReject = reactive({
      show: false,
      close_button: false,
      confirm_button: false,
    })

    const modalTerms = reactive({
      show: false,
      size: 'max-w-6xl',
      title: 'Terms and Conditions',
      close_button: false,
      confirm_button: false,
    })

    const form = reactive({
      id: '',
      organisationName: '',
      lastName: '',
      givenName: '',
      businessMail: '',
      phoneNo: '',
      country: '',
      countryId: '',
      state: '',
      stateId: '',
      stateShortCode: '',
      address1: '',
      address2: '',
      suburb: '',
      suburbId: '',
      postalCode: '',
      postalCodeId: '',
      websiteUrl: '',
      orgWebsiteUrl: '',
      username: '',
      plan: '',
      planId: null,
      tc: '',
      tcId: '',
      taxGroup: null,
      rejectReason: null,
      errors: '',
      mch_reg_rej_type_id: '',
      rejection_comments: '',
      tcDescription: '',
      status: '',
      act_as_agent: 0,
    })

    function threeDot(string, count=20, fullText=false) {
      if(fullText) {
        showFullUrl.value = true
        form.websiteUrl =  string
      } else {
        showFullUrl.value = false
        count = urlCharCount.value
        const threeDots = constant.add3Dots(string, count);
        form.websiteUrl = threeDots
      }

    }

    function getRegistrationDetails(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        searchText.value = search
      }

      axios
        .get('/v1/registration-get', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            registration.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totatlRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function getRegistrationDetailsById(registrationId) {
      buttonDisable.value = false

      axios.get('/v1/registration-get/' + registrationId).then((response) => {
        if (response.data != null) {
          registrationFormData.value = response.data.data
          form.id = registrationFormData.value.reg_id
          form.organisationName = registrationFormData.value.organisation_name
          form.lastName = registrationFormData.value.last_name
          form.givenName = registrationFormData.value.given_name
          form.businessMail = registrationFormData.value.business_email
          form.phoneNo = registrationFormData.value.phone_number
          form.address1 = registrationFormData.value.address1
          form.address2 = registrationFormData.value.address2
          form.websiteUrl = registrationFormData.value.website_url
          form.orgWebsiteUrl = registrationFormData.value.website_url
          form.username = registrationFormData.value.username
          form.state = registrationFormData.value.state_name
          form.stateId = registrationFormData.value.state_id
          form.stateShortCode = registrationFormData.value.state_short_code
          form.country = registrationFormData.value.country_name
          form.countryId = registrationFormData.value.country_code
          form.suburb = registrationFormData.value.suburb_name
          form.suburbId = registrationFormData.value.suburb_id
          form.postalCode = registrationFormData.value.postcode
          form.postalCodeId = registrationFormData.value.postcode_id
          form.tc = registrationFormData.value.registration_terms_and_condition.tc_id
          form.tcId = registrationFormData.value.created_at
          form.tcDescription =
            registrationFormData.value.registration_terms_and_condition.description
          form.status = registrationFormData.value.reg_status
          threeDot(form.orgWebsiteUrl)

          if (form.status == 'REG_APPROVED' || form.status == 'REG_REJECTED') {
            buttonDisable.value = true
          }
        } else {
          console.error('Error!')
        }
      })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getRegistrationDetails(1, sortColumn.value, searchText.value)
    }
    function closeSlideOverPanel() {
      slideOver.show = false
    }

    function formatDateTime(dateTime, format = 'DD-MMM-YYYY') {
      if (format == 'DD-MMM-YYYY') {
        return moment(dateTime).format(format)
      } else {
        return moment(dateTime, format).format(format)
      }
    }

    function closeAlert() {
      modalApprove.show = false
      modalReject.show = false
    }

    function confirmAlert() {
      modalApprove.show = false
    }

    async function getTaxGroups(country, state) {
      taxGroupOptions.value = [{ value: '', text: 'Select a Tax Group' }]

      await axios
        .get('v1/tax-group', {
          params: {
            search: '',
            state: state,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.data.find((taxGroup) => {
              let obj = {}
              obj.text = taxGroup.tax_name
              obj.value = taxGroup.tax_group_id
              taxGroupOptions.value.push(obj)
            })
          }
        })
      if (taxGroupOptions.value.length <= 1) {
        noTaxGroups.value = true
      }
    }

    async function getPlans(countryId) {
      planOptions.value = [{ value: '', text: 'Select a Plan' }]

      await axios
        .get('/v1/get-standard-plans-by-country/' + countryId)
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((plan) => {
              let obj = {}
              obj.text = plan.plan_name
              obj.value = plan.plan_id
              planOptions.value.push(obj)
            })
          }
        })
      if (planOptions.value.length <= 1) {
        noPlans.value = true
      }
    }

    async function getRejectReasons() {
      RejectReasonOptions.value = [{ value: '', text: 'Select a Reason' }]

      await axios
        .get('/v1/get-all-dropdown-option/registration_rejection')
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.options.data.find((rejectReason) => {
              let obj = {}
              obj.value = rejectReason.mch_reg_rej_type_id
              obj.text = rejectReason.description
              RejectReasonOptions.value.push(obj)
            })
          }
        })
      if (RejectReasonOptions.value.length <= 1) {
        noRegistrationRejection.value = true
      }
    }

    function approveRegistration() {
      modalApprove.show = true
      form.errors = ''
      form.plan = ''
      form.taxGroup = ''

      getTaxGroups(form.countryId, form.stateId)
      getPlans(form.countryId)
    }

    function proceedApproval() {
      disableApproveButton.value = true
      delete form['tcDescription'];
      axios
        .post('/v1/registration-approve/' + form.id, form)
        .then((response) => {
          if (response.data != null && !response.data.data.errors) {
            modalApprove.show = false
            slideOver.show = false
            getRegistrationDetails(currentPage.value)
          } else {
            form.errors = response.data.data.errors
            disableApproveButton.value = false
          }
        })
    }

    function rejectRegistration() {
      form.mch_reg_rej_type_id = ''
      modalReject.show = true
      form.rejection_comments = ''
      form.errors = ''
      getRejectReasons()
    }

    function proceedReject() {
      disableRejectButton.value = true
      axios
        .post('/v1/registration-reject/' + form.id, form)
        .then((response) => {
          if (response.data != null && !response.data.data.errors) {
            modalReject.show = false
            slideOver.show = false
            getRegistrationDetails()
          } else {
            form.errors = response.data.data.errors
            disableRejectButton.value = false
          }
        })
    }

    function viewTermsAndCondition() {
      modalTerms.show = true
    }

    return {
      t,
      onMounted,
      registration,
      registrationFormData,
      countryOptions,
      fullPage,
      lastPage,
      isLoading,
      perPage,
      form,
      modalApprove,
      modalReject,
      modalTerms,
      ModalForm,
      sortColumn,
      searchText,
      searchParam,
      slideOver,
      closeAlert,
      confirmAlert,
      sortColumnDirection,
      showRegistrationDetails,
      showRegistrationApproval,
      showRegistrationRejection,
      getRegistrationDetails,
      closeSlideOverPanel,
      getRegistrationDetailsById,
      sort,
      formatDateTime,
      approveRegistration,
      proceedApproval,
      route,
      getTaxGroups,
      taxGroupData,
      taxGroupOptions,
      planOptions,
      rejectRegistration,
      getRejectReasons,
      slug,
      RejectReasonOptions,
      proceedReject,
      viewTermsAndCondition,
      buttonDisable,
      totatlRecord,
      getPlans,
      editorOptions,
      currentPage,
      noPlans,
      noTaxGroups,
      noRegistrationRejection,
      disableApproveButton,
      disableRejectButton,
      threeDot,
      showFullUrl,
      urlCharCount,
    }
  },
}
</script>
