<template>
  <div class="space-y-3 w-full">
    <div>
      <form-label for="merchant-id">
        {{ t("Merchant ID") }}
      </form-label>
      <div class="mt-1 sm:flex items-center sm:space-x-3">
        <div class="w-full sm:flex-1 mb-2 sm:mb-0">
          <form-input
            id="merchant-id"
            v-model="form.op_mch_id"
            disabled
            name="merchant_id"
            type="text"
          />
        </div>
        <ButtonSecondary
          class="w-full sm:w-auto whitespace-nowrap"
          type="button"
          @click="copyOpuspayMerchnatId()"
        >
          {{ t("Copy to Clipboard") }}
        </ButtonSecondary>
      </div>
    </div>

    <div>
      <form-label for="api-key">
        {{ t("API Key") }}
      </form-label>
      <div class="relative">
        <div class="mt-1 sm:flex items-center sm:space-x-3">
          <div class="w-full sm:flex-1 mb-2 sm:mb-0 relative">
            <button
              v-if="form.encrypt_op_api_key == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.op_api_key,'op_api_key')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="api-key"
              v-model="form.encrypt_op_api_key"
              disabled
              name="api_key"
              type="text"
            />
          </div>
          <ButtonSecondary
            class="w-full sm:w-auto whitespace-nowrap"
            type="button"
            @click="decryptData(form.op_api_key,'copy_op_api_key_copy')"
          >
            {{ t("Copy to Clipboard") }}
          </ButtonSecondary>
        </div>
      </div>
    </div>

    <div>
      <form-label for="private-key">
        {{ t("API Secret Key") }}
      </form-label>
      <div class="relative">
        <div class="mt-1 sm:flex items-center sm:space-x-3">
          <div class="w-full sm:flex-1 mb-2 sm:mb-0 relative">
            <button
              v-if="form.encrypt_op_api_secret == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.op_api_secret,'op_api_secret')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="private-key"
              v-model="form.encrypt_op_api_secret"
              disabled
              name="private_key"
              type="text"
            />
          </div>
          <ButtonSecondary
            class="w-full sm:w-auto whitespace-nowrap"
            type="button"
            @click="decryptData(form.op_api_secret, 'copy_op_api_secret_copy')"
          >
            {{ t("Copy to Clipboard") }}
          </ButtonSecondary>
        </div>
      </div>
    </div>
  </div>

  <div class="gatwayFixedFooter mt-5 lg:mt-0 max-w-7xl flex items-center fixed  justify-between space-x-2 bottom-0 bg-white w-full py-4 border-t border-gray-200">
    <div
      class="text-sm"
    >
      {{ t("View") }}
      <router-link
        :to="{ path: '/accounts/edit/' + form.mch_acc_id }"
        class="text-primary underline"
        @click="viewTermsAndCondition()"
      >
        {{ t("Terms & Conditions") }}
      </router-link>
    </div>
    <div class="">
      <Button
        type="button"
        @click="generateNewApiCredentialsConfirmation()"
      >
        {{ t("Generate New API Credentials") }}
      </Button>
    </div>
  </div>
  <div class="mt-4" />


  <modal
    :close-button="modal.close_button"
    :message="modal.message"
    :show="modal.show"
    :title="modal.title"
    :type="modal.type"
    @closeModal="closeAlert"
  />

  <modal
    :type="modalGenerateCredentials.type"
    :show="modalGenerateCredentials.show"
    :title="modalGenerateCredentials.title"
    :message="modalGenerateCredentials.message"
    :close-button="modalGenerateCredentials.close_button"
    :confirm-button="modalGenerateCredentials.confirm_button"
    :button-text="modalGenerateCredentials.button_text"
    @confirmModal="generateNewApiCredentials"
    @closeModal="modalGenerateCredentials.show = false"
  />
  <modal-form
    :close-button="modalTerms.close_button"
    :confirm-button="modalTerms.confirm_button"
    :modal-size="modalTerms.size"
    :show="modalTerms.show"
    :title="modalTerms.title"
    @closeModal="modalTerms.show = false"
  >
    <template #modal-body>
      <div
        class="tinymce-wrapper space-y-4"
        style="min-height: calc(100vh - 230px);"
      >
        <editor
          v-model="tcDescription"
          :api-key="tinymce_api_key"
          :disabled="true"
          :init="editorOptions"
          menu="false"
          menubar="false"
          toolbar="false"
        />
      </div>
    </template>
  </modal-form>
</template>

<script>
  import {ref, reactive, onMounted, watchEffect} from 'vue'
  import { useI18n } from 'vue-i18n'
  import Button from '@/components/Button'
  import FormLabel from '@/components/FormLabel.vue'
  import FormInput from '@/components/FormInput.vue'
  import ButtonSecondary from '@/components/ButtonSecondary'
  import axios from 'axios'
  import Modal from '@/components/Modal.vue'
  import ModalForm from '@/components/ModalForm.vue'
  import Editor from '@tinymce/tinymce-vue'
  import tinymceConfig from '@/plugins/tinymce-config.js'
  import {copyText} from 'vue3-clipboard'
  import constant from "@/constant";
  import Loading from "vue3-loading-overlay";

  export default {
    name: 'FormApiCredentials',

    components: {
      Modal,
      Button,
      FormLabel,
      FormInput,
      ButtonSecondary,
      ModalForm,
      editor: Editor,
    },

    props: {
      mch: {
        type: Number,
        default: 0,
      },
    },

    emits: ['sendCred'],

    setup(props, {emit}) {
      const { t } = useI18n()

      const form = reactive({
        mch_acc_id: '',
        op_mch_id: '',
        op_api_key: '',
        op_api_secret: '',
        encrypt_op_api_key: constant.encryptionKey,
        encrypt_op_api_secret: constant.encryptionKey,
      });

      const formCopy = reactive({
        op_mch_id_copy: '',
        op_api_key_copy: '',
        op_api_secret_copy: '',
      });

      const modal = reactive({
        show: false,
        type: '',
        title: '',
        message: '',
        close_button: true,
      });
      const modalGenerateCredentials = reactive({
        show: false,
        type: 'warning',
        title: 'Warning',
        message: 'Are you sure you want to generate new API Credentials? This will stop the payment process in the transaction source application until it is updated with these credentials.',
        close_button: true,
        confirm_button: true,
      })

      const modalTerms = reactive({
        show: false,
        size: 'max-w-6xl',
        title: 'Terms and Conditions',
        close_button: false,
        confirm_button: false,
      });

      const decryptDataForm = reactive({
        data: '',
      })

      const tcDescription = ref('');
      const editorOptions = reactive(tinymceConfig.viewOnModalSettings);
      const constantValue = constant.encryptionKey

      watchEffect(() => {
        form.mch_acc_id = props.mch;
        findPublishedTermAndCondition()
      });

      onMounted(() => {
        getMerchantAccountCredentialsDetailsByMchAccId()
      });
      const merchantAccountCredentialsDetails = ref(0);

      function getMerchantAccountCredentialsDetailsByMchAccId() {
        axios
          .get('/v1/get-merchant-account-credentials/' + form.mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountCredentialsDetails.value = response.data.data;
              form.op_api_key = merchantAccountCredentialsDetails.value.op_api_key;
              form.op_api_secret =
                merchantAccountCredentialsDetails.value.op_api_secret;
              form.op_mch_id =
                merchantAccountCredentialsDetails.value.mch_acc_uuid;
            } else {
              console.error('Error!')
            }
          })
      }

      function generateNewApiCredentials() {
        formCopy.op_mch_id_copy = '';
        formCopy.op_api_key_copy = '';
        formCopy.op_api_secret_copy = '';
        form.encrypt_op_api_key = constant.encryptionKey;
        form.encrypt_op_api_secret = constant.encryptionKey;

        axios
          .post('/v1/create-new-api-credentials/' + form.mch_acc_id, form)
          .then((response) => {
            if (response.data != null) {
              form.op_api_key = response.data.data.op_api_key;
              form.op_api_secret = response.data.data.op_api_secret;
              modalGenerateCredentials.show = false;
              modal.type = 'success';
              modal.title = 'Success';
              modal.message =
                'Update your transaction source application with these new API credentials in order to continue to utilize opuspay for your payment transactions.';
              modal.show = true
              emit('sendCred', form)
            } else {
              form.errors = response.data.data.errors
            }
          })
      }

      function findPublishedTermAndCondition() {
        axios.get('/v1/get-published-terms-and-condition/').then((response) => {
          if (response.data != null) {
            tcDescription.value = response.data.data.description
          } else {
            console.error('Error!')
          }
        })
      }

      function closeAlert() {
        modal.show = false
      }

      function copyOpuspayMerchnatId() {
        formCopy.op_mch_id_copy = form.op_mch_id;
        copyText(form.op_mch_id, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function copyOpuspayApiKey(copyValue) {
        copyText(copyValue, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function copyOpuspayApiSecretKey(copyValue) {
        formCopy.op_api_secret_copy = form.op_api_secret;
        copyText(copyValue, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function viewTermsAndCondition() {
        modalTerms.show = true
      }

      function decryptData(data, value){
        decryptDataForm.data = data
        axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
          if (response.data != null) {
            if(value == 'op_api_key'){
              form.encrypt_op_api_key = response.data
            }
            else if(value == 'op_api_secret'){
              form.encrypt_op_api_secret = response.data
            }
            else if(value == 'copy_op_api_key_copy'){
              formCopy.op_api_key_copy = response.data
              setTimeout(() => (copyOpuspayApiKey(formCopy.op_api_key_copy)), 500);
            }
            else if(value == 'copy_op_api_secret_copy'){
              formCopy.op_api_secret_copy = response.data
              setTimeout(() => (copyOpuspayApiKey(formCopy.op_api_secret_copy)), 500);
            }
          } else {
            console.error('Error!')
          }
        })
      }

      //Function to give the warning popup to the user about the confirmation
      function generateNewApiCredentialsConfirmation() {
        modalGenerateCredentials.show = true
        modalGenerateCredentials.button_text = 'No'
      }

      return {
        t,
        onMounted,
        modal,
        form,
        formCopy,
        merchantAccountCredentialsDetails,
        getMerchantAccountCredentialsDetailsByMchAccId,
        copyOpuspayMerchnatId,
        copyOpuspayApiKey,
        copyOpuspayApiSecretKey,
        generateNewApiCredentials,
        closeAlert,
        tcDescription,
        viewTermsAndCondition,
        modalTerms,
        editorOptions,
        decryptData,
        decryptDataForm,
        constantValue,
        modalGenerateCredentials,
        generateNewApiCredentialsConfirmation
      }
    },
  }
</script>
