<template>
  <span
    :class="{
      'bg-blue-200 text-blue-700': type == 'info',
      'bg-red-200 text-red-700': type == 'danger',
      'bg-green-200 text-green-700': type == 'success',
      'bg-yellow-100 text-yellow-700': type == 'warning',
      'bg-pink-100 text-pink-700': type == 'decline',
    }"
    class="px-2.5 py-1 rounded-full font-semibold text-xs"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Badge',

  props: {
    type: {
      type: String,
      default: null,
      required: true,
    },

    text: {
      type: String,
      default: null,
      required: true,
    },
  },
}
</script>
