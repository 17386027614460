<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Countries & Currencies") }}
        </h1>

        <Button
          id="button_add-tenant"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="
            ;(slideOver.show = true),
              resetForm(),
              resetErrors(),
              (environment = 'add')
          "
        >
          {{ t("Add Countries & Currencies") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              class="py-2"
              name="results_per_page"
              :items="[10, 25, 50, 100]"
              @onChangeFunction="getCountries()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getCountries(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="countries_and_currencies_owner_portal_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'country'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Currency") }}
                    </span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('currency')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'currency'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(country, index) in countries"
                :id="'countries_and_currencies_owner_portal_' + index"
                :key="index"
              >
                <td>{{ country.country_name }}</td>
                <td>{{ country.currency_name }}</td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="
                        ;(slideOver.show = true),
                          resetErrors(),
                          getRecord(country.country_code, country.currency_code),
                          (environment = 'edit')
                      "
                    />
                    <button-circle
                      type="danger"
                      icon="trash"
                      @click="deleteRecord(country.country_code, country.currency_code)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!countries.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        v-if="countries.length"
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getCountries($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="country_code">
            {{ t("Country") }}
            <required />
          </form-label>
          <form-select
            id="country"
            v-model="form.country_code"
            :items="countryOptions"
            name="country_code"
            class="mt-1"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.country_code
                ? form.errors.validations.country_code[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="currency_code">
            {{ t("Currency") }} <required />
          </form-label>
          <form-select
            id="currency"
            v-model="form.currency_code"
            :items="currencyOptions"
            name="currency_code"
            class="mt-1"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.currency_code
                ? form.errors.validations.currency_code[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="onSubmit"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watch } from 'vue'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormError from '@/components/FormError.vue'
import axios from 'axios'
import Modal from '@/components/Modal'
import {useI18n} from "vue-i18n";

export default {
  name: 'Tenants',

  components: {
    Button,
    Search,
    Loading,
    SvgIcon,
    FormLabel,
    SlideOver,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    Modal,
    FormError,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const perPage = ref(10)
    const sortColumnDirection = ref('asc')
    const sortColumn = ref('')
    const currentPage = ref('')
    const lastPage = ref('')
    const totalRecord = ref('')
    const searchText = ref('')
    const tenants = ref([])
    const countries = ref([])
    const environment = ref('add')
    const countryOptions = ref([{ value: '', text: 'Please select a country' }])
    const currencyOptions = ref([{ value: '', text: 'Please select a currency' }])
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      title: t("Add Countries & Currencies"),
    })


    watch (() =>{

      slideOver.title = t("Add Countries & Currencies")

    })

    const form = reactive({
      country_code: null,
      country_name: null,
      currency_code: null,
      currency_name: null,
      status: null,
      errors: '',
      prv_country_code: null,
      prv_currency_code: null,
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    getCountryList()
    getCurrencyList()

    async function getCountryList() {
      await axios.get('v1/address/countries').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((country) => {
            let obj = {}
            obj.text = country.country_name
            obj.value = country.country_code
            countryOptions.value.push(obj)
          })
        }
      })
    }

    async function getCurrencyList() {
      await axios.get('v1/get-curency_list').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((currency) => {
            let obj = {}
            obj.text = currency.currency_name
            obj.value = currency.currency_code
            currencyOptions.value.push(obj)
          })
        }
      })
    }

    async function getCountries(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('v1/country_and_currency/country_and_currency_list?', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            countries.value = response.data.data.data
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
          } else {
            console.error('Error!')
          }
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getCountries(1, sortColumn.value, searchText.value)
    }
    async function getRecord(country_code, currency_code) {
      slideOver.title = t("Edit Countries & Currencies")
      await axios.get('v1/country_and_currency/get_record_by_key/' + country_code + '/' + currency_code).then((response) => {
        if (response.data.data != null) {
          let rec = response.data.data
          resetForm()
          form.country_code= rec.country_code
          form.currency_code = rec.currency_code
          form.status = rec.status
          form.prv_country_code= rec.country_code
          form.prv_currency_code = rec.currency_code
        }
      })
    }

    async function deleteRecord(country_code, currency_code) {
      let data = {}
      data.country_code = country_code
      data.currency_code = currency_code

      axios
        .delete('/v1/country_and_currency/country_and_currency_delete/' + country_code + '/' + currency_code , {params: data})
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getCountries(currentPage.value)
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function onSubmit() {

      let SubmitArr = {}
      let url = ''
      SubmitArr.country_code = form.country_code
      SubmitArr.country_name = form.country_name
      SubmitArr.currency_code = form.currency_code
      SubmitArr.currency_name = form.currency_name
      SubmitArr.prv_country_code = form.prv_country_code
      SubmitArr.prv_currency_code = form.prv_currency_code
      SubmitArr.status = 'ACT'

      if (environment.value == 'edit') {
        url = '/v1/country_and_currency/country_and_currency_update'
      } else {
        url = '/v1/country_and_currency/country_and_currency_create'
      }

      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (environment.value == 'add') {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Record added successfully.'
            modal.show = true
          } else {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Record updated successfully.'
            modal.show = true
          }
          slideOver.show = false
          resetForm()
          resetErrors()
          getCountries(currentPage)
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function closeAlert() {
      modal.show = false
      resetForm()
      return true
    }

    function confirmAlert() {
      modal.show = false
      return true
    }

    function resetForm() {
      form.country_code = null
      form.country_name = null
      form.currency_code = null
      form.currency_name = null
      form.status = null
      form.prv_country_code = null
      form.prv_currency_code = null
    }

    function resetErrors() {
      form.errors = ''
    }

    function setEnvironment() {
      if (form.id != null) {
        environment.value = 'edit'
      } else {
        environment.value = 'add'
      }
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('tenant-name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    return {
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      countryOptions,
      currencyOptions,
      sortColumn,
      searchText,
      form,
      onSubmit,
      closeAlert,
      confirmAlert,
      modal,
      tenants,
      countries,
      perPage,
      getCountries,
      resetForm,
      resetErrors,
      environment,
      getRecord,
      deleteRecord,
      totalRecord,
      lastPage,
      sort,
      t,
    }
  },
}
</script>
