<template>
  <page-header>
    <template #header-content>
      <div class="flex items-center">
        <back-button />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("View Terms & Conditions") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <form>
        <div class="mb-5 flex items-center">
          <label>{{ t("Version") }}</label>
          <div class="w-48 mx-3">
            <form-input
              id="version"
              v-model="form.version"
              autocomplete="version"
              name="version"
              type="text"
              :disabled="true"
            />
          </div>
        </div>

        <form-label
          class="inline-block mb-3"
          for="description"
        >
          {{ t("Description") }}
        </form-label>

        <editor
          v-model="form.description"
          :disabled="true"
          :api-key="tinymce_api_key"
          :init="editorOptions"
          menubar="false"
          menu="false"
          toolbar="false"
        />

        <div class="mt-8 flex items-center justify-between">
          <div class="flex items-center justify-between">
            <Switch
              v-model="enable"
              :disabled="true"
              :class="enable ? 'enabled' : ''"
              class="switch"
            >
              <span class="sr-only">{{ t("Publish terms & conditions") }}</span>
              <span class="switch-dot" />
            </Switch>

            <div>
              <div
                v-if="enable"
                class="ml-3 text-sm font-medium text-green-600"
              >
                {{ t("Published") }}
              </div>
              <div
                v-else
                class="ml-3 text-sm font-medium"
              >
                {{ t("Publish") }}
              </div>
            </div>
          </div>

          <ButtonSecondary
            id="button-back"
            class="btn-wide"
            type="button"
            @click="$router.push({ name: 'terms' })"
          >
            {{ t("Back") }}
          </ButtonSecondary>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { onMounted, reactive, ref } from 'vue'
import tinymceConfig from '@/plugins/tinymce-config.js'
import Editor from '@tinymce/tinymce-vue'
import { Switch } from '@headlessui/vue'
import FormInput from '@/components/FormInput'
import BackButton from '@/components/BackButton'
import FormLabel from '@/components/FormLabel.vue'
import PageHeader from '@/components/PageHeader.vue'
import ButtonSecondary from '@/components/ButtonSecondary'

export default {
  name: 'Term',

  components: {
    Switch,
    FormInput,
    FormLabel,
    PageHeader,
    BackButton,
    editor: Editor,
    ButtonSecondary,
  },

  setup() {
    const form = reactive({
      version: '',
      description: '',
    })
    const { t } = useI18n()
    const route = useRoute()
    const enable = ref(false)
    const editorOptions = reactive(tinymceConfig.viewModeSettings)
    const termsAndCondition = ref(0)

    onMounted(() => {
      getTermsAndConditionDetails()
    })

    function getTermsAndConditionDetails() {
      const tc_id = route.params.tc_id
      axios.get('/v1/terms-and-condition/' + tc_id).then((response) => {
        if (response.data != null) {
          termsAndCondition.value = response.data.data
          form.description = termsAndCondition.value.description
          form.version = termsAndCondition.value.version

          if (termsAndCondition.value.status == 'ACT') {
            enable.value = true
          }
        } else {
          console.error('Error!')
        }
      })
    }

    return {
      t,
      form,
      enable,
      editorOptions,
      termsAndCondition,
      getTermsAndConditionDetails,
    }
  },
}
</script>
