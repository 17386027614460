<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <div class="mb-4 grid sm:grid-cols-2 gap-y-3">
    <div class="flex items-center">
      <span class="text-sm">{{ t("Show") }}  </span>
      <div class="w-16 mx-2">
        <form-select
          id="results_per_page"
          v-model="perPage"
          :items="[10, 25, 50, 100]"
          class="py-2"
          name="results_per_page"
          @onChangeFunction="getApiRules($event)"
        />
      </div>
      <span class="text-sm">{{ t("entries") }} </span>
    </div>

    <div class="w-full flex items-center justify-end space-x-2">
      <div class="w-full lg:w-3/6">
        <search @dataFetchfunction="getApiRules(1, sortColumn, $event)" />
      </div>
      <Button
        v-if="actionBtn == 'edit'"
        type="button"
        class="whitespace-nowrap"
        @click="addConfigRow"
      >
        {{ t("Add Row") }}
      </Button>
    </div>
  </div>

  <TableDefault>
    <template #table>
      <table
        class="table"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="flex items-center">
                <span>{{ t("API Configuration Rules") }}  </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('description')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'description'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center justify-center">
                <span> {{ t("Active") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('status')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' && sortColumn == 'status'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th
              scope="col"
              class="th-action"
            >
              {{ t("Actions") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(apiRule, index) in apiRules.data"
            :id="'gateway_api_configuration_' + index"
            :key="index"
            name="fade"
          >
            <td class="max-w-4xl">
              {{ apiRule.description }}
            </td>
            <td class="text-center">
              <form-checkbox
                v-model="apiRule.status"
                name="status"
                :disabled="actionBtn != 'edit'"
                :checked="apiRule.status == 'ACT' ? true : false"
                :model-value="apiRule.status == 'ACT' ? 'ACT' : 'INA'"
                @click="statusChange(apiRule.status == 'ACT' ? 'INA' : 'ACT', apiRule.gw_api_rule_id, index, apiRule.row_version)"
              />
            </td>
            <td>
              <div class="flex items-center justify-center space-x-5">
                <button-circle
                  :disabled="actionBtn != 'edit'"
                  type="info"
                  icon="download"
                  @click="downloadApiConfig(apiRule.gw_api_rule_id, apiRule.description)"
                />
                <button-circle
                  :disabled="actionBtn != 'edit'"
                  type="info"
                  icon="copy"
                  @click="copyApiConfig(apiRule.gw_api_rule_id)"
                />
                <button-circle
                  :disabled="actionBtn != 'edit'"
                  type="info"
                  icon="edit"
                  @click="editApiConfig(apiRule.gw_api_rule_id), env = 'Edit'"
                />
                <button-circle
                  :disabled="actionBtn != 'edit'"
                  type="danger"
                  icon="trash"
                  @click="deleteApiConfig(apiRule.gw_api_rule_id)"
                />
              </div>
            </td>
          </tr>

          <no-table-data v-if="apiRules.total == 0" />
        </tbody>
      </table>
    </template>
  </TableDefault>

  <vue-pagination
    :page-size="perPage"
    :total-records="apiRules.total"
    :sort-column="sortColumn"
    :sort-direction="sortColumnDirection"
    :last-page="apiRules.last_page"
    @page-changed="getApiRules($event)"
  />

  <slide-over
    :show="slideOverApiConfig.show"
    :slideover-size="slideOverApiConfig.size"
    :slideover-title="t(slideOvertTitle())"
    :close-button="slideOverApiConfig.close_button"
    @closeSlideOver="closeSlide"
  >
    <template #slideover-body>
      <div class="mb-4 w-full md:w-1/2">
        <form-label
          for="rule_description"
        >
          {{ t("Rule Description") }}  <required />
        </form-label>
        <form-input
          id="rule_description"
          v-model="ruleDescription"
          class="mt-1"
          name="rule_description"
          type="text"
        />
        <form-error
          v-if="errors.description"
          :message="errors.description"
        />
      </div>
      <form-label
        for="rule_configuration"
      >
        {{ t("Rule Configuration") }}  <required />
      </form-label>
      <vue-json-editor
        v-if="slideOverApiConfig.show == true"
        v-model="json.val"
        :value="json.val"
        @json-change="onJsonChange"
      />
      <form-error
        v-if="errors.api_configuration"
        :message="errors.api_configuration"
      />
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="submitConfigurationRule"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />

  <modal
    :type="modalConfirm.type"
    :show="modalConfirm.show"
    :title="modalConfirm.title"
    :message="modalConfirm.message"
    :close-button="modalConfirm.close_button"
    :confirm-button="modalConfirm.confirm_button"
    :button-text="modalConfirm.button_text"
    @closeModal="closeConfirmAlert(modalConfirm.status, modalConfirm.itemIndex)"
    @confirmModal="confirmAlert"
  />
</template>
<script>
import {ref, reactive, nextTick, watchEffect, watch} from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import Required from '@/components/Required.vue'
import TableDefault from '@/components/TableDefault.vue'
import FormSelect from '@/components/FormSelect.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormError from '@/components/FormError.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import Loading from 'vue3-loading-overlay'
import VuePagination from '@/components/VuePagination'
import SlideOver from '@/components/SlideOver.vue'
import VueJsonEditor from '@/components/VueJsonEditor.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import NoTableData from '@/components/NoTableData.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'AcceptedCountries',

  components: {
    Modal,
    Button,
    Search,
    Required,
    FormLabel,
    TableDefault,
    FormSelect,
    ButtonCircle,
    VueJsonEditor,
    FormError,
    FormInput,
    SlideOver,
    Loading,
    VuePagination,
    FormCheckbox,
    SvgIcon,
    NoTableData,
  },

  props: {
    gatewayId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: 'view',
    },
  },

  setup(props) {
    const { t, te, tm } = useI18n()
    const gatewayID = ref(props.gatewayId)
    const store = useStore()
    const isLoading = ref(false)
    const fullPage = ref(true)
    const length = ref(0)
    const apiRules = ref([])
    let json = reactive({ a: 's' })
    const actionBtn = ref(props.action)
    const ruleDescription = ref(null)
    const apiConfigId = ref(null)
    const rowVerson = ref(1)
    const env = ref('Add')
    const errors = reactive({
      description: null,
      api_configuration: null,
    })
    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })
    watchEffect(() => {
      if (props.action != actionBtn.value) {
        actionBtn.value = props.action
      }
    })

    const modalConfirm = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      status: null,
      itemIndex: null,
      button_text: 'No',
      close_button: true,
      confirm_button: true,
    })

    const statusChangeSubmit = reactive({
      gw_api_rule_id: null,
      status: null,
      action: null,
      row_version: null,
    })

    const sortColumn = ref('')
    const sortColumnDirection = ref('desc')
    const perPage = ref(10)

    const slideOverApiConfig = reactive({
      show: false,
      size: 'max-w-5xl',
      close_button: true,
      title: " API Configuration Rule",
    })

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    function closeAlert() {
      modal.show = false
    }

    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getApiRules()
    }

    async function getApiRules(
      currentPage,
      column = sortColumn.value,
      search = ''
    ) {
      isLoading.value = true
      const OptionValue = {}
      OptionValue.page = currentPage
      OptionValue.gw_id = gatewayID.value
      OptionValue.column = column
      OptionValue.sortColumnDirection = sortColumnDirection.value
      OptionValue.perPage = perPage.value
      OptionValue.searchText = search
      await store.dispatch('GwApiConfig/getApiConfigList', OptionValue)
      if ((await store.state.GwApiConfig.apiConfigData) != null) {
        apiRules.value = await store.state.GwApiConfig.apiConfigData
        length.value = await store.state.GwApiConfig.apiConfigData.data
      }

      isLoading.value = false
    }

    function onJsonChange(value) {
      json.val = value
    }
    async function submitConfigurationRule() {
      let validation = 0
      errors.description = null
      errors.api_configuration = null

      if (ruleDescription.value == null || ruleDescription.value == '') {
        errors.description = 'Please enter a rule description.'
        validation = 1
      }
      if (json.val == null) {
        validation = 1
        errors.api_configuration = 'Please enter a rule configuration.'
      }
      if (validation == 0) {
        isLoading.value = true
        const submitData = {
          gw_id: gatewayID.value,
          description: ruleDescription.value,
          api_configuration: json.val,
          row_version: rowVerson.value,
        }
        let response = []

        if (apiConfigId.value != null) {
          modal.message = 'API configuration updated successfully.'
          submitData.gw_api_rule_id = apiConfigId.value
          await store.dispatch('GwApiConfig/postEditApiConfig', submitData)
          response = await store.state.GwApiConfig.apiConfigPostData
        } else {
          modal.message = 'API configuration added successfully.'
          await store.dispatch('GwApiConfig/postApiConfig', submitData)
          response = await store.state.GwApiConfig.apiConfigPostData
        }
        if (response.status == true) {
          isLoading.value = false
          getApiRules()
          modal.show = true
          modal.title = 'Success'
          modal.type = 'success'

          closeSlide()
        } else if (response.status == false) {
          isLoading.value = false
          modal.show = true
          modal.type = 'error'
          modal.title = 'Error'
          modal.message = ReturnData.msg
        } else {
          isLoading.value = false
          bindValidationErrors(response.data.errors.validations)
        }
      }
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    async function editApiConfig(apiConfId) {
      await store.dispatch('GwApiConfig/viewApiConfig', apiConfId)
      let response = await store.state.GwApiConfig.apiConfigPostData
      if (response.status == true) {
        rowVerson.value = response.data.row_version
        apiConfigId.value = response.data.gw_api_rule_id
        ruleDescription.value = response.data.description
        json.val = response.data.rule_config
        nextTick(() => {
          const focusRef = document.getElementById('jsoneditor-vue')
          focusRef.append()
        })
        resetErrors()
        slideOverApiConfig.show = true
      }
    }
    async function addConfigRow(apiConfId) {
      nextTick(() => {
        const focusRef = document.getElementById('jsoneditor-vue')
        focusRef.append()
      })
      env.value = 'Add'
      slideOverApiConfig.show = true
      resetErrors()
    }

    async function copyApiConfig(apiConfId) {
      isLoading.value = true
      await store.dispatch('GwApiConfig/copyConfiguration', apiConfId)
      let response = await store.state.GwApiConfig.apiConfigPostData
      if (response.status == true) {
        modal.confirm_button = false
        modal.close_button = true
        modal.button_text = 'Ok'
        modal.type = 'success'
        modal.title = 'Success'
        modal.message = 'API configuration copied successfully.'
        modal.show = true
        isLoading.value = false
        getApiRules()
      }
    }

    function closeSlide() {
      apiConfigId.value=null
      ruleDescription.value = null
      json.val = null
      slideOverApiConfig.show = false
    }

    function statusChange(status, gatewayConfigId, index, rowVersion) {
      if (status == 'ACT') {
        statusChangeSubmit.gw_api_rule_id = gatewayConfigId
        statusChangeSubmit.status = 'ACT'
        statusChangeSubmit.row_version = rowVersion
        statusChangeSubmit.action = null
        modalConfirm.status = 'INA'
        modalConfirm.itemIndex = index
        modalConfirm.show = true
        modalConfirm.title = 'Activate Alert'
        modalConfirm.message =
          'You are about to activate an API Configuration Rule. Are you sure you want to continue?'
      } else if (status == 'INA') {
        statusChangeSubmit.gw_api_rule_id = gatewayConfigId
        statusChangeSubmit.status = 'INA'
        statusChangeSubmit.row_version = rowVersion
        statusChangeSubmit.action = null
        modalConfirm.status = 'ACT'
        modalConfirm.itemIndex = index
        modalConfirm.show = true
        modalConfirm.title = 'De-Activate Alert'
        modalConfirm.message =
          'You are about to de-activate an API Configuration Rule. Are you sure you want to continue?'
      }
    }

    async function confirmAlert() {
      if (
        statusChangeSubmit.gw_api_rule_id != null &&
        statusChangeSubmit.action != 'delete'
      ) {
        await store.dispatch(
          'GwApiConfig/changeApiConfigStatus',
          statusChangeSubmit
        )
        let response = await store.state.GwApiConfig.apiConfigPostData
        modalConfirm.show = false
        getApiRules()
      } else if (statusChangeSubmit.action == 'delete') {
        await store.dispatch(
          'GwApiConfig/deleteApiConfiguration',
          statusChangeSubmit.gw_api_rule_id
        )
        statusChangeSubmit.action = null
        let response = await store.state.GwApiConfig.deleteApiConfigData
        modalConfirm.show = false
        getApiRules()
      }
    }

    function closeConfirmAlert(checkedStatus = null, itemIndex = null) {
      if (
          statusChangeSubmit.gw_api_rule_id != null &&
        statusChangeSubmit.action != 'delete'
      ) {
        if (checkedStatus == 'INA') {
          apiRules.value.data[itemIndex].status = 'ACT'
        } else {
          apiRules.value.data[itemIndex].status = 'INA'
        }
      }
      statusChangeSubmit.gw_api_rule_id = null
      statusChangeSubmit.status = null
      statusChangeSubmit.row_version = null
      modalConfirm.show = false
      getApiRules()
    }

    async function deleteApiConfig(gatewayConfigId) {
      statusChangeSubmit.gw_api_rule_id = gatewayConfigId
      statusChangeSubmit.action = 'delete'
      modalConfirm.title = 'Warning'
      modalConfirm.type = 'warning'
      modalConfirm.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modalConfirm.show = true
    }

    function checkRule(stat) {
      return stat == 'ACT' ? true : false
    }

    function focusElement(el) {
      nextTick(() => {
        const focusRef = document.getElementById(el)
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    async function resetErrors(){
      errors.description = null
      errors.api_configuration = null
    }

    function slideOvertTitle(){
      return env.value + slideOverApiConfig.title;
    }

    async function downloadApiConfig(apiConfId, apiConfDescription) {
        await axios({
        method: 'get',
        url: 'v1/gateway/api-config/download/' + apiConfId,
        params: {
          extension_type: 'json',
        },
      })
        .then((response) => {
          isLoading.value = false
          const jsonContent = JSON.stringify(response.data.data, null, 2)
          let blob = new Blob([jsonContent], {type: "application/json"})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = apiConfDescription+'.json';
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }

    return {
      gatewayID,
      isLoading,
      fullPage,
      length,
      errors,
      sortColumnDirection,
      sortColumn,
      perPage,
      getApiRules,
      modal,
      apiRules,
      slideOverApiConfig,
      closeAlert,
      showTabContent,
      ruleDescription,
      json,
      submitConfigurationRule,
      onJsonChange,
      editApiConfig,
      closeSlide,
      apiConfigId,
      statusChange,
      sort,
      modalConfirm,
      confirmAlert,
      checkRule,
      closeConfirmAlert,
      copyApiConfig,
      downloadApiConfig,
      deleteApiConfig,
      actionBtn,
      addConfigRow,
      resetErrors,
      env,
      t,
      slideOvertTitle,
    }
  },
}
</script>
