<template>
  <transition
    appear
    enter-active-class="ease-in-out duration-1000"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in-out duration-1000"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div class="mt-1 text-yellow-600 font-medium text-xs">
      {{ message }}
    </div>
  </transition>
</template>

<script>
import { reactive, watchEffect } from 'vue'

export default {
  name: 'FormWarning',

  props: {
    message: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const form = reactive({
      message: '',
    })

    watchEffect(() => {
      form.message = props.message
    })
  },
}
</script>
