window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    stateListDropDown: [],
  },

  mutations: {
    stateListData(state, data) {
      state.stateListDropDown = data
    },
  },
  actions: {
    async getStateListByCountryID(
      { commit, state },
      id,
      message = 'Please select a state'
    ) {
      let stateList = [{ value: '', text: message }]
      await axios
        .get('v1/address/state-names-for-country/' + id)
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((state) => {
              let obj = {}
              obj.text = state.state_name
              obj.value = state.state_id
              stateList.push(obj)
            })
            commit('stateListData', stateList)
          }
        })
    },
  },
}
