<template>
  <transition leave-active-class="duration-300">
    <div
      v-show="show"
      class="fixed inset-0 z-20 overflow-hidden"
      role="dialog"
      aria-modal="true"
    >
      <div class="absolute inset-0 overflow-hidden">
        <transition
          enter-active-class="ease-in-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in-out duration-300"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-show="show"
            class="absolute inset-0 bg-black bg-opacity-60 transition-opacity"
            aria-hidden="true"
          />
        </transition>

        <div class="fixed inset-y-0 right-0 md:pl-10 max-w-full flex">
          <transition
            enter-active-class="transform transition ease-in-out duration-300"
            enter-from-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transform transition ease-in-out duration-300"
            leave-from-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div
              v-show="show"
              class="w-screen"
              :class="[slideoverSize ? slideoverSize : 'max-w-md']"
            >
              <div
                :id="[show ? 'slideover_focus-loop' : '']"
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
              >
                <div class="px-4 sm:px-6">
                  <div class="py-5 flex items-start justify-between">
                    <p class="text-xl font-semibold text-black opacity-80">
                      {{ t(slideoverTitle) }}
                    </p>

                    <div class="ml-3 w-7 h-7 flex items-center">
                      <button
                        :id="[isOpen ? 'focus-ref' : '']"
                        ref="focusRef"
                        class="w-0 h-0 focus:outline-none"
                        type="button"
                      >
                        <!-- Invisible auto-focus button -->
                      </button>
                      <button
                        id="btn-close"
                        class="group rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                        type="button"
                        @click="closeSlideOver"
                      >
                        <span class="sr-only">Close panel</span>
                        <svg-icon
                          class="
                            w-7
                            h-7
                            text-gray-800
                            group-hover:text-gray-900
                          "
                          icon="close"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  ref="scroller"
                  class="min-h-0 py-6 flex-1 flex flex-col overflow-y-auto"
                >
                  <div
                    class="-mt-6 flex flex-col items-center w-full h-full pointer-events-none z-40"
                    :class="showPreLoader ? 'absolute' : 'hidden'"
                  >
                    <div class="h-full flex flex-col items-center justify-center">
                      <svg-icon
                        class="w-10 h-10 text-primary"
                        icon="loader"
                      />
                      <div class="mt-2 text-black text-base font-medium text-center">
                        Loading...
                      </div>
                    </div>
                  </div>

                  <div
                    class="relative flex-1 px-4 sm:px-6"
                    :class="showPreLoader ? 'pointer-events-none opacity-20' : ''"
                  >
                    <div
                      class="h-full"
                      aria-hidden="true"
                    >
                      <slot name="slideover-body" />
                    </div>
                  </div>
                </div>

                <div
                  class="p-4 flex justify-end flex-shrink-0 space-x-3"
                  :class="showPreLoader ? 'pointer-events-none opacity-20' : ''"
                >
                  <ButtonSecondary
                    v-if="closeButton"
                    class="btn-wide focus:bg-white"
                    type="button"
                    @click="closeSlideOver"
                  >
                    {{ t(closeButtonTextValue) }}
                  </ButtonSecondary>
                  <slot name="slideover-actions" />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, watchEffect,watch } from 'vue'
import focusTrap from '@/helpers/focus-trap.js'
import SvgIcon from '@/components/SvgIcon.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import {useI18n} from "vue-i18n";

export default {
  name: 'SlideOver',

  components: {
    SvgIcon,
    ButtonSecondary,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    slideoverTitle: {
      type: String,
      default: null,
    },

    closeButton: {
      type: Boolean,
      default: true,
    },

    slideoverSize: {
      type: String,
      default: null,
    },

    closeButtonText: {
      type: String,
      default: 'Cancel',
    },

    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['openSlideOver', 'closeSlideOver'],

  setup(props, { emit }) {
    const { t } = useI18n()
    const isOpen = ref(true)
    const scroller = ref(null)
    const focusRef = ref(null)
    const showPreLoader = ref(true)
    const closeButtonTextValue =  ref(t('Cancel'))
    let focusLoop = ref(null)

    watchEffect(() => {
      if (props.show) {
        openSlideOver()
      }

      props.isLoading ? showPreLoader.value = true : showPreLoader.value = false
    })

    watch(() => {
      if (props.closeButtonText == 'Cancel') {
        closeButtonTextValue.value = t('Cancel')
      }else {
        closeButtonTextValue.value = t(props.closeButtonText)
      }
    })

    function openSlideOver() {
      emit('openSlideOver')
      isOpen.value = true

      setTimeout(function() {
        focusLoop = document.getElementById('slideover_focus-loop')
        focusTrap.trapFocus(focusLoop, focusRef.value)
        showPreLoader.value = false
      }, 1000)
    }

    function closeSlideOver() {
      emit('closeSlideOver')
      isOpen.value = false
      scroller.value.scrollTop = 0
    }

    return {
      isOpen,
      scroller,
      focusRef,
      focusLoop,
      showPreLoader,
      closeSlideOver,
      closeButtonTextValue,
      t,
    }
  },
}
</script>
