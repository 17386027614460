<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <back-button :route-to="{ name: 'manage-token-index' }" />
          <h1
            id="page-title"
            class="page-title"
          >
            {{ t(`Manage Token - ${payor}`) }}
          </h1>
        </div>

        <Button
          id="button_edit-gateway-summary"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="slideOver.show=true , searchTokenTransaction()"
        >
          {{ t("Search") }}
        </Button>
      </div>

      <div class="mt-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-4 break-words">
        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Account Name") }}
            </div>
            <div class="font-medium text-white text-base">
              {{ acc_name }}
            </div>
          </div>
        </div>
        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              Payor
            </div>
            <div class="font-medium text-white text-base">
              {{ payor }}
            </div>
          </div>
        </div>
        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              Token Reference
            </div>
            <div class="font-medium text-white text-base">
              {{ opTokenReference }}
            </div>
          </div>
        </div>
        <div>
          <div class="mb-5">
            <div class="text-white text-opacity-50 text-sm">
              {{ t("Token Status") }}
            </div>
            <div class="flex flex-wrap items-center gap-x-2">
              <div
                v-if="status == 'ACT'"
                class="
                  mt-0.5
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  rounded
                "
              >
                {{ t("Active") }}
              </div>
              <div
                v-if="status == 'INA'"
                class="
                  mt-1
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  rounded
                "
              >
                {{ t("Inactive") }}
              </div>
              <div
                v-if="status == 'EXPIRED'"
                class="
                  mt-1
                  border border-white
                  px-1.5
                  py-0.5
                  font-medium
                  uppercase
                  text-xs text-white
                  rounded
                "
              >
                {{ t("EXPIRED") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="bg-gray-200 w-full mb-4">
        <div class="py-3 md:py-3 lg:py-1 px-2  rounded w-full xl:w-2/4 md:w-2/4  flex md:flex-col xl:flex-row flex-1 flex-wrap md:flex-nowrap items-center md:items-start xl:items-center sm:justify-end mt-0">
          <div class="flex flex-wrap  mb-2 md:mb-0 md:flex-nowrap items-center  md:justify-center">
            <form-label
              for="name"
              class="md:mb-2 mr-2 xl:mb-0 break-keep"
            >
              {{ t("opuspay token") }}
            </form-label>
          </div>
          <div
            class="w-full sm:flex-1 mb-2 sm:mb-0 relative"
          >
            <button
              v-if=" encryptedToken == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="ShowToken()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="token-id"
              v-model="encryptedToken"
              name="token-id"
              type="text"
              class="pl-10 "
              style="min-width: 318px !important;"
              :disabled="true"
            />
          </div>
          <div class="ml-0 xl:ml-3 md:mt-2 xl:mt-0">
            <div class="flex items-center justify-between">
              <Switch
                v-if="status !='EXPIRED'"
                :class="status === 'ACT' ? 'enabled' : ''"
                class="switch"
                @click="TransactionSave()"
              >
                <span class="sr-only">Publish terms &amp; conditions</span>
                <span class="switch-dot" />
              </Switch>

              <div>
                <div
                  v-if="status === 'ACT'"
                  class="ml-3 text-sm font-medium"
                >
                  {{ t("Inactive") }}
                </div>
                <div
                  v-if="status === 'INA'"
                  class="ml-3 text-sm font-medium text-green-600"
                >
                  {{ t("Active") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getTokenizeTransactions()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="flex flex-wrap items-center justify-center mx-1">
            <ButtonSecondary
              class="btn-secondary flex items-center justify-center btn-default mb-4 mx-1"
              type="button"
              @click="exportType = 'xlsx', exportTransactions()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4 "
                icon="export"
                stroke-width="2.5"
              />
              <span>{{ t("Export Excel") }}</span>
            </ButtonSecondary>
            <ButtonSecondary
              class="btn-secondary flex items-center justify-center btn-default mb-4 mx-1"
              type="button"
              @click="exportType = 'csv', exportTransactions()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="export"
                stroke-width="2.5"
              />
              <span>{{ t("Export CSV") }}</span>
            </ButtonSecondary>
          </div>

          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="quickSearchText"
              class="mb-0"
              @dataFetchfunction="getTokenizeTransactions(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Date") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('transaction_date')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'transaction_date'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Time") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('transaction_time')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'transaction_time'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Transaction Reference") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_ref')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'mch_ref'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="object"
                >
                  <div class="flex items-center justify-end">
                    <span>{{ t("Amount") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('amount')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'amount'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-end">
                    <span>{{ t("Payment Method") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('payment_method')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'payment_method'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody
              v-for="(token, index) in tokens"
              :id="'token_portal_'+ index"
              :key="index"
            >
              <tr>
                <td style="max-width: 130px">
                  {{ getDateAndTime("date",token.created_at) }}
                </td>
                <td>{{ getDateAndTime("time",token.created_at) }}</td>
                <td class="max-w-lg">
                  <a
                    :id="'gateways_edit_' + 1"
                    style="text-decoration: underline; "
                    title="Transaction Summery"
                    @click="previewSlideOver.show = true, getMerchantTransaction(token.pay_trn_id), search.errors = null"
                  >{{ token.mch_ref }}</a>
                </td>
                <td class="text-right">
                  {{ token.amount ? token.amount.toFixed(decimalPlaces) + " " + token.currency:"-" }}
                </td>
                <td class="text-right">
                  {{ token.pay_method }}
                </td>
              </tr>

              <no-table-data v-if="totalRecord == 0" />
            </tbody>  
          </table>
        </template>
      </TableDefault>
      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getTokenizeTransactions($event)"
      />
    </div>
  </div>
  <slide-over
    :show="previewSlideOver.show"
    :slideover-size="previewSlideOver.size"
    :slideover-title="previewSlideOver.title"
    :close-button="previewSlideOver.close_button"
    :close-button-text="previewSlideOver.close_button_text"
    @closeSlideOver="previewSlideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Transaction Details") }}
      </div>
      <div>
        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant Account") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.merchant }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.mch_id }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Document Number (Reference)") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.document_number }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Date") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ formatDateTime(form.transaction_date,'DD-MMM-YYYY') }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.payment_type }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70" />
            <div class="mt-1 text-black font-medium" />
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Amount") }}
            </div>
            <div
              v-if="form.amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? '-' + Math.abs(form.amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? form.amount + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Fee") }}
            </div>
            <div
              v-if="form.gw_fee < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? '-' + Math.abs(form.gw_fee).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? form.gw_fee + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Net") }}
            </div>
            <div
              v-if="form.net_amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? '-' + Math.abs(form.net_amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? form.net_amount + ' ' + form.currency_code : '' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Customer Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Customer Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.customer_name ? form.customer_name : '-' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payor Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ (form.payer_name ? form.payer_name : '-' )+ " " + (form.payer_last_name ? form.payer_last_name : '') }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Email") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.payer_email }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Origin of Transaction") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.origin_country_name }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payment Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_type }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Method") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_method }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Card Details") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.card_details }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.paymentDevice }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Location") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pd_location }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.pd_serial_number && form.encrypt_pd_serial_number == constantValue"
                  class="absolute inset-y-0 left-0 m-px border-l rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.pd_serial_number, 'pd_serial_no')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium">
                  {{ form.encrypt_pd_serial_number }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("opuspay Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("opuspay Tracking ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_trn_ref }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.gateway }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway Token") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.gw_token && form.encrypt_gw_token == constantValue"
                  class="absolute inset-y-0 left-o m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.gw_token, 'gw_token')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium  pr-10">
                  {{ form.encrypt_gw_token }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Status") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <badge
                v-if="form.status =='GW_APPROVED' ||
                  form.status =='GW_CAPTURED'||
                  form.status =='GW_SETTLED' ||
                  form.status =='GW_RETURNED'"
                type="success"
                text="SUCCESS"
              />
              <badge
                v-if="form.status =='GW_CANCELLED'"
                type="warning"
                text="CANCEL"
              />
              <badge
                v-if="form.status =='GW_ERROR' ||
                  form.status =='GW_FAILED'"
                type="danger"
                text="FAIL"
              />
              <badge
                v-if="form.status =='GW_DECLINED'"
                type="decline"
                text="DECLINE"
              />
              <badge
                v-if="form.status =='GW_INIT' ||
                  form.status =='GW_PROCESSING'||
                  form.status =='GW_VERIFY_SIG' ||
                  form.status =='INIT' ||
                  form.status =='GW_PENDING'"
                type="info"
                text="PENDING"
              />
            </div>
          </div>

          <!--          <div>-->
          <!--            <div class="text-xs font-normal text-black opacity-70">-->
          <!--              {{ t("opuspay Token") }}-->
          <!--            </div>-->
          <!--            <div class="mt-1 text-black font-medium">-->
          <!--              {{ form.pay_trn_ref }}-->
          <!--            </div>-->
          <!--          </div>-->


          <div v-if="form.status == 'GW_DECLINED' || form.status == 'GW_ERROR' || form.status == 'GW_FAILED'">
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Due to") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_msg }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </slide-over>
  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <button
        id="focus-ref"
        class="absolute top-0 w-0 h-0 focus:outline-none"
        type="button"
      >
        <span class="invisible">.</span>
      </button>

      <div class="space-y-4">
        <div>
          <form-label for="from_date">
            {{ t("Transaction Date From") }}
          </form-label>
          <datepicker
            id="from_date"
            v-model="search.from_date"
            name="from_date"
            class="vdp-input mt-1"
            input-format="dd-MM-yyyy"
            :upper-limit="todayDate"
          />
        </div>
        <div>
          <form-label for="to_date">
            {{ t("Transaction Date To") }}
          </form-label>
          <datepicker
            id="to_date"
            v-model="search.to_date"
            name="to_date"
            class="vdp-input mt-1"
            input-format="dd-MM-yyyy"
            :upper-limit="todayDate"
          />
        </div>
        <div>
          <form-label for="ref_no">
            {{ t("Reference") }}
          </form-label>
          <form-input
            id="ref_no"
            v-model="search.ref_no"
            class="mt-1"
            name="ref_no"
            type="text"
          />
          <form-error
            v-if="searchErrors.errors"
            :message="searchErrors.errors.noRecords ? searchErrors.errors.noRecords : ''"
          />
          <form-error
            v-if="searchErrors.errors"
            :message="searchErrors.errors.invalidRecords ? searchErrors.errors.invalidRecords : ''"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        class="btn-wide"
        type="button"
        @click="resetSearch()"
      >
        {{ t("Reset") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
        @click="getTokenizeTransactions(1,sortColumn,null,true)"
      >
        {{ t("Search") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="ActivateOrInactivateToken"
  />
  <modal
    :type="modalSuccess.type"
    :show="modalSuccess.show"
    :title="modalSuccess.title"
    :message="modalSuccess.message"
    :confirm-button="modalSuccess.confirm_button"
    :button-text="modalSuccess.button_text"
    @closeModal="closeModal"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { Switch } from '@headlessui/vue'
import Button from '@/components/Button.vue'
import moment from "moment"
import Badge from '@/components/Badge.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import BackButton from '@/components/BackButton.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import TableDefault from '@/components/TableDefault.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import Search from '@/components/Search.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import VuePagination from '@/components/VuePagination'
 import Datepicker from 'vue3-datepicker'
 import constant from "../../constant";
import { useI18n } from 'vue-i18n'
import router from "../../routes";
import axios from "axios";
import Divider from '@/components/Divider.vue';
import NoTableData from '@/components/NoTableData.vue'


export default {
  name: 'GatewaySummary',
  components: {
    Button,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    PageHeader,
    BackButton,
    TableDefault,
    ButtonSecondary,
    Search,
    SvgIcon,
    VuePagination,
    Datepicker,
    Switch,
    Modal,
    Loading,
    Badge,
    Divider,
    NoTableData
  },

  setup() {
    const { t, } = useI18n()
    const sortColumnDirection = ref('desc')
    const acc_name = ref(null)
    const payor = ref(null)
    const status = ref(null)
    const opToken = ref(null)
    const opTokenReference = ref(null)
    const searchParam = ref('')
    const quickSearchText = ref('')
    const totalRecord = ref(0)
    const lastPage = ref(0)
    const currentPage = ref(0)
    const route = useRoute()
    const enable = ref(false)
    const payTokenId = ref(route.params.slug)
    const sortColumn = ref('')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const tokens = ref(0)
    const perPage = ref('10')
    const decimalPlaces = ref(constant.decimalPlaces)
    const constantValue = constant.encryptionKey
    const encryptedToken = ref(null)
    const exportType = ref('xlsx')
    const todayDate = ref();

    const search = reactive({
      ref_no:null,
      from_date:null,
      to_date:null,
    })

    const searchValues = reactive({
      ref_no:null,
      from_date:null,
      to_date:null,
    })
    const form = reactive({
      transaction_id: null,
      merchant: null,
      transaction_date: null,
      invoice_number: null,
      amount: null,
      payer_name: null,
      payer_last_name: null,
      payment_type: null,
      status: null,
      gateway: null,
      customer_name:null,
      gw_fee: null,
      net_amount: null,
      payer_email: null,
      origin_country_name: null,
      pay_method: null,
      pay_type: null,
      card_details: null,
      pay_device_name: null,
      pd_location: null,
      pd_serial_number: null,
      pay_trn_ref: null,
      gw_token: null,
      document_number: null,
      mch_id: null,
      currency_code: null,
      pay_msg: null,
      encrypt_gw_token : constant.encryptionKey,
      encrypt_pd_serial_number : constant.encryptionKey
    })
    const searchErrors = reactive({
      ref_no: null,
    })
    const previewSlideOver = reactive({
      show: false,
      size: 'max-w-4xl',
      close_button: true,
      close_button_text: 'Close',
      title: 'Transaction Summary',
    })
    const statusOptions = ref([
      { value: 'ACT', text: t("Active") },
      { value: 'INA', text: t("Inactive") },
    ])

    const modal = reactive({
      type: 'success',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })

    const modalSuccess = reactive({
      type: 'success',
      show: false,
      title: '',
      message:'',
      close_button: true,
    })

    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: t("Search Transaction") ,
    })
    function resetTransactionSummary(){
      form.transaction_id = null
      form.amount = null
      form.payer_name = null
      form.payer_last_name = null
      form.status = null
      form.gateway = null
      form.payment_type = null

      form.gw_fee = null
      form.net_amount = null
      form.payer_email = null
      form.origin_country_name = null
      form.pay_method = null
      form.pay_type = null
      form.card_details = null
      form.paymentDevice = null
      form.pd_location = null
      form.pd_serial_number = null
      form.pay_trn_ref = null
      form.gw_token = null
      form.document_number = null
      form.mch_id = null
      form.merchant = null
      form.transaction_date = null
      form.pay_msg = null
    }
    async function getTokenizeTransactions(
    page = 1,
      column = sortColumn.value,
      quickSearch = null,
      fromAdvanceSearch = false
    ) {
      const currentDate = moment().format('YYYY-MM-DD');
      todayDate.value = new Date(currentDate);
      todayDate.value = todayDate.value.setDate(todayDate.value.getDate())
      const max = /^.{0,255}$/
      let index = 0

      if (!max.test(search.ref_no)) {
        searchErrors.ref_no = 'Maximum length for document number (reference) is 255.'
        index++
      }
      if(fromAdvanceSearch == true && search.from_date > search.to_date){
        searchErrors.errors = {
          invalidRecords: "The given date range is not valid"
        }
        index++
      }
      if (index == 0){
        isLoading.value = true
        let searchText = ''
        if (isSearch()) {
          searchParam.value = '&search='
          searchText = JSON.stringify(search)
        }
        if (quickSearch != null) {
          quickSearchText.value = quickSearch
        }
        await axios
          .get('/v1/manage-tokens/get-all-token-transactions/'+payTokenId.value, {
            params: {
              quickSearch: quickSearchText.value,
              search: searchText,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              tokens.value = response.data.data.data
              encryptedToken.value = constantValue
              if(tokens.value.length > 0) {
                acc_name.value = acc_name.value == null ? tokens.value[0].mch_acc_name : acc_name.value
                payor.value = payor.value == null ? (tokens.value[0].payer_first_name != null ? tokens.value[0].payer_first_name : '') + ' ' + (tokens.value[0].payer_last_name != null ? tokens.value[0].payer_last_name : '') : payor.value
                status.value = status.value == null ? tokens.value[0].status : status.value
                opToken.value = opToken.value == null ? tokens.value[0].op_token : opToken.value,
                opTokenReference.value = opTokenReference.value == null ? tokens.value[0].reference : opTokenReference.value
              }

              if(status.value == "ACT"  && enable.value == null){
                enable.value = true
              }

              else if(status.value == "INA"  && enable.value == null){
                enable.value = false
              }

              lastPage.value = response.data.data.last_page
              totalRecord.value = response.data.data.total
              currentPage.value = response.data.data.current_page

              if(fromAdvanceSearch == true &&
                search.ref_no == null &&
                search.to_date == null &&
                search.from_date == null
                ){
                    searchErrors.errors = {
                      noRecords: "No search filters are found. Please select and try again."
                    }
                }else{

                    searchValues.ref_no = search.ref_no
                    searchValues.to_date = search.to_date
                    searchValues.from_date = search.from_date
                    searchTokenTransaction()
                    slideOver.show = false
                }
            } else {
              console.error('Error!')
            }
          })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
      }
    }

    async function searchTokenTransaction() {
      searchErrors.errors = ''
      if(searchValues.to_date != null){
        search.to_date = searchValues.to_date
      }
      else{
        search.to_date = new Date();
      }
      if(searchValues.from_date != null){
        search.from_date = searchValues.from_date
      }
      else{
        search.from_date = null;
      }
      search.ref_no = searchValues.ref_no
    }

    function formatDateTime(dateTime, format = 'DD-MMM-YYYY HH:mm') {
      if (!moment(dateTime, format).isValid()) {
        return moment.utc(dateTime).format(format)
      } else {
        return moment.utc(dateTime, format).format(format)
      }
    }
    const decryptDataForm = reactive({
      data: '',
    })
    function isSearch() {
      if (
        search.ref_no != null ||
        search.from_date != null ||
        search.to_date != null
      ) {
        return true
      } else {
        return false
      }
    }

    async function resetSearch(){
      search.from_date = null
      search.to_date = new Date()
      search.ref_no = null
      searchErrors.errors = ''
      searchValues.from_date = null
      searchValues.to_date = new Date()
      searchValues.ref_no = null
    }
    function decryptData(data, value) {
      decryptDataForm.data = data
      axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
        if (response.data != null) {
          if (value == 'gw_token') {
            form.encrypt_gw_token = response.data
          }
          else if (value == 'pd_serial_no') {
            form.encrypt_pd_serial_number = response.data
          }

        } else {
          console.error('Error!')
        }
      })
    }

    async function exportTransactions(
                                       column = sortColumn.value,
                                       quickSearch = null) {
      isLoading.value = true
      let searchText = ''
      if (isSearch()) {
        searchParam.value = '&search='
        searchText = JSON.stringify(search)
      }
      if (quickSearch != null) {
        quickSearchText.value = quickSearch
      }

      let dataTime = moment().format("YYYY-MM-DD h:mm:ss");
      await axios
        ({
          method: 'get',
          responseType: 'blob',
          url: 'v1/manage-tokens/export',
          params: {
                  payTokenId : payTokenId.value,
                  quickSearch: quickSearchText.value,
                  search: searchText,
                  count_per_page: totalRecord.value,
                  page: 1,
                  sortColumn: column,
                  sortDirection: sortColumnDirection.value,
                  exportType: exportType.value,
                  dataTime: dataTime,
                },
        })
      if(exportType.value == 'xlsx'){
        modalSuccess.message = "Transaction Excel will be sent to the user's business email address."
      }else{
        modalSuccess.message = "Transaction CSV will be sent to the user's business email address."
      }
      modalSuccess.show=true
      isLoading.value = false
    }

    function getDateAndTime(selection,dateAndTime)
    {
      dateAndTime = formatDateTime(dateAndTime);
      let splitArray = dateAndTime.split(' ');
      if(selection == "date"){
        return splitArray[0]
      }
      else{
        return splitArray[1]
      }
    }

    function ShowToken(){
      encryptedToken.value = opToken.value

      return encryptedToken
    }

    function closeAlert() {
      modal.show = false
    }
    function closeModal(){
      modalSuccess.show = false
    }

    function TransactionSave(){
      if(status.value === 'ACT'){
        slideOver.show = false
        modal.type = 'warning'
        modal.confirm_button = true
        modal.button_text = 'No'
        modal.title = 'Warning'
        modal.message = 'You are about to inactivate a token. Are you sure you want to continue?'
        modal.show = true
      }else if(status.value === 'INA'){
        slideOver.show = false
        modal.type = 'warning'
        modal.confirm_button = true
        modal.button_text = 'No'
        modal.title = 'Warning'
        modal.message = 'You are about to activate a token. Are you sure you want to continue?'
        modal.show = true
      }

    }

    async function ActivateOrInactivateToken(){
      let toggleStatus = status.value === 'ACT' ? "INA" : "ACT"
      await axios
            .get('/v1/manage-tokens/activate-inactivate-state/'+payTokenId.value, {
              params: {
                status : toggleStatus,
                tokenId: payTokenId.value
              },
            })
            .then((response) => {
              if(response.data.data.errors != null && 
              response.data.data.errors.validations && 
              response.data.data.errors.validations.status){
                modalSuccess.type = 'warning'
                modalSuccess.button_text = 'OK'
                modalSuccess.title = 'Warning'
                modalSuccess.message = response.data.data.errors.validations.status[0]
                modalSuccess.show = true
              }else{
                status.value = toggleStatus                
              }
            })
            .catch(err => {
              router.push({ name: 'unauthorized-access'})
            })
      closeAlert()
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      getTokenizeTransactions(1, sortColumn.value, quickSearchText.value)
    }
    async function getMerchantTransaction(transaction_id){
      form.encrypt_gw_token = ''
      form.encrypt_pd_serial_number = ''
      resetTransactionSummary()
      await axios.get('v1/transaction/view/' + transaction_id).then((response) => {
        if (response.data.data != null) {
          let merchant = response.data.data
          form.transaction_id = merchant.pay_trn_id
          if(merchant.amount != null){
            form.amount = Number((merchant.amount)).toFixed(constant.decimalPlaces)
          }else{
            form.amount = merchant.amount
          }
          form.payer_name = merchant.payer_name
          form.payer_last_name = merchant.payer_last_name
          form.status = merchant.status
          form.gateway = merchant.gateway.name
          form.payment_type = merchant.payment_type
          if(merchant.gw_fee != null){
            form.gw_fee = Number(merchant.gw_fee).toFixed(constant.decimalPlaces)
          }else{
            form.gw_fee = merchant.gw_fee
          }
          if(merchant.net_amount != null){
            form.net_amount = Number(merchant.net_amount).toFixed(constant.decimalPlaces)
          }else{
            form.net_amount = merchant.net_amount
          }
          form.payer_email = merchant.payer_email
          form.origin_country_name = merchant.origin_country_name
          form.pay_method = merchant.pay_method
          form.pay_type = merchant.pay_type
          form.card_details = merchant.card_details
          form.pd_location = merchant.pd_location
          form.pd_serial_number = merchant.pd_serial_number
          if(form.pd_serial_number){
            form.encrypt_pd_serial_number = constant.encryptionKey
          }
          form.pay_trn_ref = merchant.pay_trn_ref
          form.gw_token = merchant.gw_token
          if(form.gw_token){
            form.encrypt_gw_token = constant.encryptionKey
          }
          form.document_number = merchant.mch_ref
          form.mch_id = merchant.mch_id
          form.merchant = merchant.merchant.mch_acc_name
          form.transaction_date = merchant.created_at
          form.currency_code = merchant.currency.currency_code
          form.customer_name = merchant.customer_name
          form.pay_msg = merchant.pay_msg
          if(merchant.pay_device != null){
            form.paymentDevice = merchant.pay_device.pd_name
          }
        }
      })
    }
    return {
      enable,
      slideOver,
      sortColumnDirection,
      payTokenId,
      isLoading,
      fullPage,
      closeAlert,
      TransactionSave,
      modal,
      modalSuccess,
      t,
      sortColumn,
      getTokenizeTransactions,
      quickSearchText,
      perPage,
      searchParam,
      totalRecord,
      lastPage,
      currentPage,
      tokens,
      acc_name,
      payor,
      status,
      sort,
      closeModal,
      formatDateTime,
      getDateAndTime,
      decimalPlaces,
      VuePagination,
      ButtonSecondary,
      ButtonCircle,
      encryptedToken,
      ShowToken,
      constantValue,
      isSearch,
      searchTokenTransaction,
      searchErrors,
      searchValues,
      search,
      exportTransactions,
      ActivateOrInactivateToken,
      resetSearch,
      exportType,
      previewSlideOver,
      form,
      getMerchantTransaction,
      decryptDataForm,
      resetTransactionSummary,
      decryptData,
      todayDate,
      opTokenReference
    }
  },
}
</script>
<!-- <style scoped>
.min-w-fit{
  min-width: fit-content;
}
</style> -->
