<template>
  <div class="flex items-center justify-between space-x-2">
    <label class="text-sm text-gray-900">
      {{ t("showing") }}
      <span
        v-if="!totalRecords"
        class="font-medium"
      >{{ 0 }} </span>
      <span
        v-if="totalRecords"
        class="font-medium"
      >{{ pageSize * (page - 1) + 1 }} </span>
      {{ t("to") }}
      <span
        v-if="pageSize * page < totalRecords"
        class="font-medium"
      >{{
        pageSize * page
      }}</span>
      <span
        v-else
        class="font-medium"
      > {{ totalRecords }} </span>
      {{ t("of") }} <span class="font-medium">{{ totalRecords }}</span> {{ t("entries") }}
    </label>

    <div
      v-if="pageSize < totalRecords"
      class="flex items-center justify-end space-x-2"
    >
      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        @click="prev"
      >
        <svg-icon
          class="w-auto h-6 text-primary-light"
          icon="caret-left"
        />
      </button>

      <button
        :class="highlited(page, 1)"
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        @click="pageMove(1)"
      >
        1
      </button>

      <div
        v-if="page != 2 && page != 3 && page != 1"
        class="text-primary font-medium tracking-widest"
      >
        ..
      </div>

      <button
        v-for="(item, index) in itemRefs"
        :key="index"
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        :class="highlited(page, item.text)"
        @click="pageMove(item.text)"
      >
        {{ item.text }}
      </button>

      <div
        v-if="page != lastPage && page != lastPage - 1 && page != lastPage - 2"
        class="text-primary font-medium tracking-widest"
      >
        ..
      </div>

      <button
        v-if="itemRefs.length == 0 && page == lastPage - 2"
        :class="highlited(page, 2)"
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        @click="pageMove(2)"
      >
        2
      </button>

      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        :class="highlited(page, lastPage)"
        @click="pageMove(lastPage)"
      >
        {{ lastPage }}
      </button>

      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
        @click="next"
      >
        <svg-icon
          class="w-auto h-6 text-primary-light"
          icon="caret-right"
        />
      </button>
    </div>

    <div
      v-else
      class="flex items-center justify-end space-x-2"
    >
      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
      >
        <svg-icon
          class="w-auto h-6 text-primary-light"
          icon="caret-left"
        />
      </button>

      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-white
          bg-primary-light
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
      >
        1
      </button>

      <button
        class="
          w-7
          h-7
          p-0.5
          flex
          items-center
          justify-center
          text-xs text-primary-light
          bg-primary-lighter
          font-semibold
          rounded-full
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-primary
        "
        type="button"
      >
        <svg-icon
          class="w-auto h-6 text-primary-light"
          icon="caret-right"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'VuePagination',

  components: {
    SvgIcon,
  },

  props: {
    pageSize: {
      type: Number,
      default: 0,
    },

    totalRecords: {
      type: Number,
      default: 0,
    },

    sortColumn: {
      type: String,
      default: '',
    },

    sortDirection: {
      type: String,
      default: '',
    },

    lastPage: {
      type: Number,
      default: 0,
    },
  },

  emits: ['page-changed'],

  setup(props, context) {
    const { t, te, tm } = useI18n()
    const page = ref(1)
    const totalRecords = ref(1)
    const pageCount = ref(1)
    const itemRefs = ref([])
    const sortColumn = ref([])
    const sortDirection = ref('desc')
    watch(page, () => {
      context.emit('page-changed', page.value)
    })

    watch(() => {
      if (props.pageSize != pageCount.value) {
        page.value = 1
      }
      if (props.sortColumn != sortColumn.value) {
        page.value = 1
      }
      if (props.sortDirection != sortColumn.value.sortDirection) {
        page.value = 1
      }
      if (props.sortDirection != sortColumn.value.sortDirection) {
        page.value = 1
      }
      if (props.totalRecords != sortColumn.value.totalRecords) {
        page.value = 1
        itemRefs.value = []
      }
    })

    const next = () => {
      if (page.value < props.lastPage) {
        page.value++
        itemRefs.value = []
        makeArray(page.value)
      }
    }

    const pageMove = (number) => {
      page.value = number
      itemRefs.value = []
      makeArray(page.value)
    }

    function makeArray(number) {
      for (let i = number - 1; i < number + 2; i++) {
        if (i > 1 && i < props.lastPage) {
          let obj = {}
          obj.text = i
          itemRefs.value.push(obj)
        }
      }
    }

    function highlited(currentPage, page) {
      if (currentPage == page) {
        return 'active'
      }
      return 'inactive'
    }

    const prev = () => {
      if (page.value > 1) {
        page.value--
        itemRefs.value = []
        makeArray(page.value)
      }
    }

    return {
      page,
      next,
      prev,
      pageMove,
      highlited,
      itemRefs,
      t,
    }
  },
}
</script>

<style scoped>
.active {
  @apply bg-primary;
  @apply text-white;
}
</style>
