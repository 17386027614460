<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <div class="mb-4 grid sm:grid-cols-2 gap-y-3">
    <div class="flex items-center">
      <span class="text-sm">  {{ t("Show") }}  </span>
      <div class="w-16 mx-2">
        <form-select
          id="results_per_page"
          v-model="perPage"
          :items="[10, 25, 50, 100]"
          class="py-2"
          name="results_per_page"
          @onChangeFunction="getCountryCurrency($event)"
        />
      </div>
      <span class="text-sm"> {{ t("entries") }}</span>
    </div>

    <div class="w-full flex items-center justify-end space-x-2">
      <div class="w-full lg:w-3/6">
        <search @dataFetchfunction="getCountryCurrency(1, $event)" />
      </div>

      <Button
        v-if="actionBtn == 'edit'"
        class="whitespace-nowrap"
        :disabled="showFormAdd == true"
        type="button"
        @click="showCountryForm(1)"
      >
        {{ t("Add Row") }}
      </Button>
      <!-- <Button
        v-else-if="actionBtn == 'edit'"
        class="btn-sm"
        type="button"
        @click="showCountryForm(0)"
        >Hide Row</Button
      > -->
    </div>
  </div>

  <TableDefault>
    <template #table>
      <table
        class="table"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="flex items-center">
                <span>  {{ t("Country") }}</span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('country_name')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'country_name'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center">
                <span>  {{ t("Country ISO") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('country_code')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'country_code'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center">
                <span>  {{ t("Currency") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('currency_name')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'currency_name'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center">
                <span>  {{ t("Currency ISO") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('currency_code')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'currency_code'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th
              scope="col"
              class="th-action"
            >
              {{ t("Actions") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-if="showFormAdd"
            class="td-align-top"
          >
            <td class="w-72">
              <form-select
                v-model="form.country"
                class="py-1.5"
                :items="countryOptions"
                name="country"
                required
                @onChangeFunction="getCountryShortCode"
              />
              <form-error
                v-if="errors.country_id != null"
                :message="errors.country_id"
              />
            </td>
            <td>
              <form-input
                v-model="form.country"
                class="py-1.5"
                disable="true"
                name="currency_iso"
                type="text"
              />
            </td>
            <td class="w-72">
              <form-select
                v-model="form.currancy"
                class="py-1.5"
                :items="currencyOptions"
                :disabled="currencyDisable"
                name="currency"
                required
                @onChangeFunction="getCurrencyIsoCode"
              />
              <form-error
                v-if="errors.currency_id != null"
                :message="errors.currency_id"
              />
            </td>
            <td>
              <form-input
                v-model="form.currancy"
                class="py-1.5"
                disable="true"
                name="currency_iso"
                type="text"
              />
            </td>
            <td>
              <div class="td-cell">
                <div class="flex items-center justify-center space-x-5">
                  <button-circle
                    type="success"
                    :disabled="saveBtnDisable"
                    icon="check"
                    @click="submitCountryCurrency"
                  />
                  <button-circle
                    type="danger"
                    icon="trash"
                    @click="showCountryForm(0)"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr
            v-for="(country, index) in countries.data"
            :id="'gateway_accepted_countries_' + index"
            :key="index"
          >
            <td>{{ country.country_name }}</td>
            <td>{{ country.country_code }}</td>
            <td>{{ country.currency_name }}</td>
            <td>{{ country.currency_code }}</td>
            <td>
              <div
                class="flex items-center justify-center justify-end space-x-2"
              >
                <button-circle
                  :disabled="actionBtn != 'edit'"
                  type="danger"
                  icon="trash"
                  @click="
                    confirmdeleteCountry(
                      country.gw_id,
                      country.country_code,
                      country.currency_code
                    )
                  "
                />
              </div>
            </td>
          </tr>

          <no-table-data v-if="countries.total == 0" />
        </tbody>
      </table>
    </template>
  </TableDefault>

  <vue-pagination
    :page-size="perPage"
    :total-records="countries.total"
    :sort-column="sortColumn"
    :sort-direction="sortColumnDirection"
    :last-page="countries.last_page"
    @page-changed="getCountryCurrency($event)"
  />

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="deleteCountry"
  />
</template>
<script>
import { ref, reactive, watchEffect } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import TableDefault from '@/components/TableDefault.vue'
import FormSelect from '@/components/FormSelect.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import Loading from 'vue3-loading-overlay'
import VuePagination from '@/components/VuePagination'
import SvgIcon from '@/components/SvgIcon.vue'
import NoTableData from '@/components/NoTableData.vue'
import { useI18n } from 'vue-i18n'
import axios from "axios";

export default {
  name: 'AcceptedCountries',

  components: {
    Modal,
    Button,
    Search,
    TableDefault,
    FormSelect,
    ButtonCircle,
    FormError,
    FormInput,
    Loading,
    SvgIcon,
    VuePagination,
    NoTableData,
  },
  props: {
    gatewayId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: 'view',
    },
  },

  setup(props) {
    const { t, te, tm } = useI18n()
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const gatewayID = ref(props.gatewayId)
    const counryID = ref('')
    const currencyID = ref('')
    const actionBtn = ref(props.action)
    const showFormAdd = ref(false)
    const store = useStore()
    const isLoading = ref(false)
    const fullPage = ref(true)
    const length = ref(0)
    let countryOptions = ref(0)
    let currencyOptions = ref(0)
    const form = reactive({
      country: null,
      countryShorCode: null,
      currancy: null,
      currancyIsoCode: null,
    })
    const countries_currencies = ref([])
    const currencyDisable = ref(false)
    const saveBtnDisable = ref(false)

    watchEffect(() => {
      if (props.action != actionBtn.value) {
        actionBtn.value = props.action
      }
    })

    const errors = reactive({
      country_id: null,
      currency_id: null,
    })
    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })
    const countries = ref(0)
    const perPage = ref(10)

    async function getCountryCurrency(page = 1, search = '') {
      isLoading.value = true
      const OptionValue = {}
      OptionValue.page = page
      OptionValue.gw_id = gatewayID.value
      OptionValue.column = sortColumn.value
      OptionValue.sortColumnDirection = sortColumnDirection.value
      OptionValue.perPage = perPage.value
      OptionValue.searchText = search
      await store.dispatch(
        'GwAcceptedCountries/getGatewayCountriesList',
        OptionValue
      )
      if ((await store.state.GwAcceptedCountries.acceptedCountriesData) != null)
        countries.value = await store.state.GwAcceptedCountries
          .acceptedCountriesData
      isLoading.value = false
    }

    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getCountryCurrency()
    }

    function closeAlert() {
      modal.show = false
    }

    async function showCountryForm(value) {
      currencyOptions.value = []
      currencyDisable.value = false
      saveBtnDisable.value = false
      errors.country = null
      errors.currancy = null
      form.country = null
      form.countryShorCode = null
      form.currancy = null
      form.currancyIsoCode = null
      if (value == 1) {
        resetErrors()
        showFormAdd.value = true
        await getCountry()
        currencyOptions.value = [{value:'',text:'Please select a currency'}]

      } else showFormAdd.value = false
    }

    function getCountryShortCode() {
      currencyOptions.value = []
      form.countryShorCode = store.state.Country.countryCodes[form.country]
      getCurrency(form.country)
    }

    function getCurrencyIsoCode() {
      form.currancyIsoCode =
        store.state.Currency.currencyIsoCodes[form.currancy]
    }

    async function submitCountryCurrency() {
        resetErrors()
        const submitData = {}
        submitData.gw_id = gatewayID.value
        submitData.country_id = form.country
        submitData.currency_id = form.currancy
        submitData.row_version = 1
        await store.dispatch(
          'GwAcceptedCountries/postGatewayCountries',
          submitData
        )
        let response = await store.state.GwAcceptedCountries
          .acceptedCountriesPostData
        try {
          if (response.status == true) {
            form.country = null
            form.countryShorCode = null
            form.currancy = null
            form.currancyIsoCode = null
            getCountryCurrency()
            showFormAdd.value = false
          } else {
              bindValidationErrors(response.data.errors.validations)
          }
        } catch (error) {
          modal.show = true
          modal.type = 'error'
          modal.title = 'Error'
          modal.message = 'Insert was not Success.'
        }

    }

     function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function resetErrors() {
        errors.country_id = null
      errors.currency_id = null
    }

    function confirmdeleteCountry(gwID, counry, Currency) {
      gatewayID.value = gwID
      counryID.value = counry
      currencyID.value = Currency
      modal.confirm_button = false

      let $index = 0
      countries.value.data.find((country) => {
        if(country.country_code == counryID.value){
          $index++
        }
      })

      let $value = 0
      axios
        .get('v1/get-countries-by-gw-id/' + gatewayID.value)
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((gateway) => {
              if(gateway.country_code == counryID.value && $index < 2){
                $value++
              }
            })

            if($value == 0){
              modal.type = 'warning'
              modal.confirm_button = true
              modal.title = 'Warning'
              modal.button_text = 'NO'
              modal.message =
                'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
              modal.show = true
            }else{
              modal.type = 'warning'
              modal.title = 'Warning'
              modal.button_text = 'OK'
              modal.message =
                'You are not allowed to delete this country as there are accounts setup with selected country'
              modal.show = true
            }
          }
        })
    }

    async function deleteCountry() {
      let submitData = {}
      submitData.gw_id = gatewayID.value
      submitData.country_id = counryID.value
      submitData.currency_id = currencyID.value
      await store.dispatch(
        'GwAcceptedCountries/deleteGatewayCountries',
        submitData
      )
      let response = await store.state.GwAcceptedCountries
        .acceptedCountriesDeleteData
      try {
        if (response.status == true) {
          form.country = null
          form.countryShorCode = null
          form.currancy = null
          modal.show = false
          form.currancyIsoCode = null
          getCountryCurrency()
        } else {
          modal.show = true
          modal.title = 'Error'
          modal.type = 'error'
          modal.button_text = 'Close'
          modal.message = 'Delete was not success.'
        }
      } catch (error) {
        modal.show = true
        modal.type = 'error'
        modal.confirm_button = false
        modal.title = 'Error'
        modal.message = 'Delete was not success.'
      }
      counryID.value = ''
      currencyID.value = ''
    }

    async function getCountry() {
      if (countryOptions.value == 0){
        await store.dispatch('Country/getCountryList')
        countryOptions.value = await store.state.Country.countryListDropDown
      }
    }

    async function getCurrency(country) {
      resetErrors()
      currencyDisable.value = false
      saveBtnDisable.value = false
      countries_currencies.value = []
      if(country !=''){
        await store.dispatch('Currency/getCurrencyList', country)
        currencyOptions.value = await store.state.Currency.currencyListDropDown

        if (currencyOptions.value.length <= 1){
          errors.currency_id = "A currency is not configured for the selected country. Please contact opuspay."
          saveBtnDisable.value = true
        }else{
          countryAndCurrencyCombination(country)
        }
      }

    }

    function  countryAndCurrencyCombination(countryId){
      for (let i=0; i< countries.value.data.length; i++){
        if(countryId === countries.value.data[i].country_code){
          let temp = {}
          temp.country_code = countries.value.data[i].country_code
          temp.currency_code = countries.value.data[i].currency_code
          countries_currencies.value.push(temp)
        }
      }
      if(countries_currencies.value.length === currencyOptions.value.length-1){
        errors.country_id = "A country and currency combination matching this selection already exists. Please select another country."
        currencyDisable.value = true
        saveBtnDisable.value = true
      }
    }


    return {
      gatewayID,
      isLoading,
      fullPage,
      length,
      errors,
      showFormAdd,
      sortColumnDirection,
      perPage,
      form,
      modal,
      countryOptions,
      currencyOptions,
      countries,
      sortColumn,
      deleteCountry,
      showCountryForm,
      closeAlert,
      getCountryShortCode,
      getCurrencyIsoCode,
      getCountryCurrency,
      submitCountryCurrency,
      sort,
      confirmdeleteCountry,
      actionBtn,
      t,
      currencyDisable,
      saveBtnDisable,
    }
  },
}
</script>
