<template>
  <svg-vue
    :fill="fill"
    :icon="icon"
    :stroke="stroke"
    :stroke-width="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
import SvgVue from 'svg-vue3'

export default {
  name: 'SvgIcon',

  components: {
    SvgVue,
  },

  props: {
    fill: {
      type: String,
      required: false,
      default: 'none',
    },

    icon: {
      type: String,
      required: true,
    },

    stroke: {
      type: String,
      required: false,
      default: 'currentColor',
    },

    strokeWidth: {
      type: String,
      required: false,
      default: '1.5',
    },
  },
}
</script>
