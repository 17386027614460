<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Countries & Currencies") }}
        </h1>

        <Button
          id="button_add-country"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          :disabled="showAddNewRow == true || selectedRow !== false"
          @click="
            ;(showAddNewRow = !showAddNewRow),
              ($store.state.sidebarDisable = true)
          "
        >
          {{ t("Add Row") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              class="py-2"
              name="results_per_page"
              :items="[10, 25, 50, 100]"
              @onChangeFunction="getAllCountriesAndCurrencies()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="
                getAllCountriesAndCurrencies(1, sortColumn, $event)
              "
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="country_and_currencies_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'country'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country ISO Code") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'country_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Currency") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('currency')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'currency'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Currency ISO Code") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('currency_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'currency_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Blocked") }}</span>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-if="showAddNewRow"
                class="td-align-top"
              >
                <td style="min-width: 280px">
                  <div class="td-cell">
                    <form-select
                      v-model="form.country"
                      :change="onchange"
                      class="py-1.5"
                      name="country"
                      :items="countryOptions"
                      @onChangeFunction="getCountryCode(form.country)"
                    />
                    <form-error
                      v-if="errors.country_code"
                      :message="errors.country_code ? errors.country_code : ''"
                    />
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <form-input
                      v-model="form.country_code"
                      class="py-1.5"
                      name="country_iso"
                      type="text"
                      :disabled="true"
                      :items="countryOptions"
                      required
                    />
                  </div>
                </td>
                <td style="min-width: 260px">
                  <div class="td-cell">
                    <form-select
                      id="currency"
                      v-model.lazy="form.currency"
                      class="py-1.5"
                      name="currency"
                      :disable="currencyDisable"
                      :items="currencyOptions"
                      @onChangeFunction="getCurrencyCode(form.country_code, form.currency)"
                    />
                    <form-error
                      v-if="errors.currency_name"
                      :message="errors.currency_name ? errors.currency_name : ''"
                    />
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <form-input
                      id="currency_iso"
                      v-model="form.currency_code"
                      class="py-1.5"
                      :disabled="true"
                      name="currency_iso"
                      type="text"
                      required
                    />
                  </div>
                </td>
                <td class="text-center">
                  <div class="td-cell flex items-center justify-center">
                    <form-checkbox
                      v-model="form.blocked"
                      name="blocked"
                      :disabled="blockDisable"
                      :model-value="form.blocked == 1 ? 0 : 1"
                    />
                    <form-error
                      v-if="errors.is_blocked"
                      :message="errors.is_blocked ? errors.is_blocked : ''"
                    />
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <div class="flex items-center justify-center space-x-5">
                      <button-circle
                        id="country_check"
                        type="success"
                        :disabled="saveBtnDisable"
                        icon="check"
                        @click="blockCountryAndCurrencyModal()"
                      />
                      <button-circle
                        id="country_close"
                        type="danger"
                        icon="close"
                        @click="clearAddRow()"
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr
                v-for="(countryAndCurrency, index) in countriesAndCurrencies"
                :id="'countries_and_currencies_' + index"
                :key="index"
                :class="(index === selectedRow) ? 'td-align-top' : ''"
              >
                <td style="min-width: 280px">
                  <div class="td-cell">
                    <span
                      v-if="index !== selectedRow"
                      :id="'countryLabel' + index"
                    >
                      {{ countryAndCurrency.country_name }}
                    </span>
                    <div v-if="index === selectedRow">
                      <form-select
                        :id="'countryInput' + index"
                        v-model="form.country"
                        class="py-1.5"
                        name="country"
                        :items="countryOptions"
                        @onChangeFunction="getCountryCode(form.country)"
                      />
                      <form-error
                        v-if="errors.country_code"
                        :message="errors.country_code ? errors.country_code : ''"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <span
                      v-if="index !== selectedRow"
                      :id="'countryCodeLabel' + index"
                    >{{ countryAndCurrency.country_code }}</span>
                    <div v-if="index === selectedRow">
                      <form-input
                        :id="'countryCodeInput' + index"
                        v-model="form.country_code"
                        class="py-1.5"
                        name="country_iso"
                        type="text"
                        required
                        :disabled="true"
                      />
                    </div>
                  </div>
                </td>
                <td style="min-width: 260px">
                  <div class="td-cell">
                    <span
                      v-if="index !== selectedRow"
                      :id="'currencyLabel' + index"
                    >{{ countryAndCurrency.currency_name }}</span>
                    <div v-if="index === selectedRow">
                      <form-select
                        :id="'currencyInput' + index"
                        v-model="form.currency"
                        class="py-1.5"
                        :disable="currencyDisable"
                        name="country"
                        :change="onchange"
                        :items="currencyOptions"
                        @onChangeFunction="getCurrencyCode(form.country_code, form.currency)"
                      />
                      <form-error
                        v-if="errors.currency_name || errors.currency_code "
                        :message="
                          errors.currency_name ? errors.currency_name :
                          errors.currency_code ? errors.currency_code : ''
                        "
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <span
                      v-if="index !== selectedRow"
                      :id="'currencyCodeLabel' + index"
                    >{{ countryAndCurrency.currency_code }}</span>
                    <div v-if="index === selectedRow">
                      <form-input
                        :id="'currencyCodeInput' + index"
                        v-model="form.currency_code"
                        :disabled="true"
                        class="py-1.5"
                        name="country_iso"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="td-cell flex items-center justify-center">
                    <form-checkbox
                      v-if="index !== selectedRow"
                      :id="'isBlockedLabel' + index"
                      v-model="form.blocked"
                      :disabled="true"
                      name="blocked"
                      type="checkbox"
                      :checked="countryAndCurrency.is_blocked == 1 ? true : false"
                    />
                    <div v-if="index === selectedRow">
                      <form-checkbox
                        v-if="index === selectedRow"
                        :id="'isBlockedInput' + index"
                        v-model="form.blocked"
                        name="blocked"
                        :disabled="index !== selectedRow"
                        :model-value="form.blocked == 1 ? 0 : 1"
                        :checked="
                          countryAndCurrency.is_blocked == 1 ? true : false
                        "
                      />
                      <form-error
                        v-if="errors.is_blocked"
                        :message="errors.is_blocked ? errors.is_blocked : ''"
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-cell">
                    <div class="flex items-center justify-center space-x-5">
                      <button-circle
                        v-if="
                          index !== selectedRow &&
                            attachedPlanIsNotActive(
                              countryAndCurrency.pl_country_code,
                              countryAndCurrency.pl_status
                            )
                        "
                        :id="'editCountryAndCurrencyButton' + index"
                        type="info"
                        icon="edit"
                        :disabled="selectedRow !== false || showAddNewRow == true"
                        @click="
                          selectRow(
                            index,
                            countryAndCurrency,
                            countryAndCurrency.country_code,
                            countryAndCurrency.currency_code
                          ),
                          ($store.state.sidebarDisable = true)
                        "
                      />
                      <tippy
                        v-if="
                          index !== selectedRow &&
                            countryAndCurrency.pl_country_code != null &&
                            countryAndCurrency.pl_status == 'ACT'
                        "
                        placement="left"
                      >
                        <button-circle
                          :id="'editCountryAndCurrencyButton' + index"
                          type="info"
                          icon="edit"
                          :disabled="
                            selectedRow !== false ||
                              showAddNewRow == true ||
                              countryAndCurrency.pl_country_code != null
                          "
                        /><template #content>
                          <span>{{ t("You won't be able to edit or block this country & currency, as it is attached to a plan.") }}.</span>
                        </template>
                      </tippy>
                      <button-circle
                        v-if="index === selectedRow"
                        :id="'updateCountryAndCurrencyButton' + index"
                        type="success"
                        icon="check"
                        @click="
                          blockCountryAndCurrencyModal(
                            countryAndCurrency.country_code,
                            countryAndCurrency.currency_code
                          )
                        "
                      />
                      <button-circle
                        v-if="index === selectedRow"
                        id="country_close"
                        type="danger"
                        icon="close"
                        @click="clearEditRow()"
                      />
                      <button-circle
                        v-if="
                          attachedPlanIsNotActive(
                            countryAndCurrency.pl_country_code,
                            countryAndCurrency.pl_status
                          )
                        "
                        :id="'deleteCountryAndCurrencyButton' + index"
                        type="danger"
                        icon="trash"
                        :disabled="selectedRow !== false || showAddNewRow == true"
                        @click="
                          deleteCountruAndCurrencyModal(
                            countryAndCurrency.country_code,
                            countryAndCurrency.currency_code
                          )
                        "
                      />
                      <tippy
                        v-else
                        placement="left"
                      >
                        <button-circle
                          :id="'deleteCountryAndCurrencyButton' + index"
                          type="danger"
                          icon="trash"
                          :disabled="
                            selectedRow !== false ||
                              showAddNewRow == true ||
                              countryAndCurrency.pl_country_code != null
                          "
                          @click="
                            deleteCountruAndCurrencyModal(
                              countryAndCurrency.country_code,
                              countryAndCurrency.currency_code
                            )
                          "
                        />
                        <template #content>
                          <span>{{ t("You won't be able to delete this country & currency, as it is attached to a plan") }}.</span>
                        </template>
                      </tippy>
                    </div>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!countriesAndCurrencies.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllCountriesAndCurrencies($event)"
      />
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useStore } from 'vuex'
import Search from '@/components/Search'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormInput from '@/components/FormInput.vue'
import FormError from '@/components/FormError.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import FormCheckbox from '@/components/FormCheckbox.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'
import { Tippy } from 'vue-tippy'
import router from "../../routes";

export default {
  name: 'CountriesCurrencies',

  components: {
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    FormError,
    FormInput,
    PageHeader,
    FormSelect,
    NoTableData,
    FormCheckbox,
    ButtonCircle,
    TableDefault,
    VuePagination,
    Tippy,
  },

  setup() {
    const { t } = useI18n()
    const store = useStore()
    const countriesAndCurrencies = ref(0)
    let onchange = ref(true)
    const showAddNewRow = ref(false)
    const sortColumnDirection = ref('desc')
    const countryISOCodes = ref(0)
    const allCountries = ref(0)
    const allCurrencies = ref([])
    const isLoading = ref(false)
    const fullPage = ref(true)
    const countryOptions = [{ value: '', text: 'Please select a country' }]
    const editRows = ref(false)
    const updateRaw = ref(false)
    const selectedRow = ref(false)
    const sortColumn = ref('')
    const searchText = ref('')
    const lastPage = ref(0)
    const totalRecord = ref(0)
    const currentPage = ref(0)
    const perPage = ref(10)
    const countries_currencies = ref([])
    let currencyDisable = ref(false)
    let saveBtnDisable = ref(false)
    let blockDisable = ref(false)

    const currencyOptions = ref([
      { value: '', text: 'Please select a currency' },
    ])
    const form = reactive({
      country: null,
      country_code: null,
      country_name: null,
      currency: null,
      currency_code: null,
      currency_name: null,
      blocked: 0,
    })
    const errors = reactive({
      country_code: null,
      currency_code: null,
      country_name: null,
      currency_name: null,
      is_blocked: null,
    })
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
      confirmAlertType : ''
    })
    const editForm = reactive({
      country: null,
      country_code: null,
      currency: null,
      currency_code: null,
      blocked: 0,
    })
    const IdsToDeleteCountryAndCurrency = reactive({
      country_code: null,
      currency_code: null,
    })
    const IdsToUpdateCountryAndCurrency = reactive({
      country_code: null,
      currency_code: null,
    })
    const IdsToValidateCountryAndCurrency = reactive({
      country_code: null,
      currency_code: null,
    })
    getCountry()

    function getAllCountriesAndCurrencies(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      axios
        .get('/v1/country_and_currency/get_all_tnt_currencies_and_countries', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            countriesAndCurrencies.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getAllCountriesAndCurrencies(1, sortColumn.value, searchText.value)
    }

    async function getCountry() {
      isLoading.value = true
      await axios.get('v1/get-all-countries').then((response) => {
        if (response.data.data != null) {
          allCountries.value = response.data.data
          response.data.data.find((country) => {
            let obj = {}
            obj.text = country.country_name
            obj.value = country.country_code
            countryOptions.push(obj)
            countryISOCodes.value = country.country_code
          })
        }
      })
      isLoading.value = false
    }

    async function getCurrency(countryID,currency_code = null) {
      countries_currencies.value =[]
      currencyDisable.value = false
      saveBtnDisable.value = false
      blockDisable.value = false
      isLoading.value = true
      currencyOptions.value = null
      form.currency = null
      resetErrors()
      await axios
        .post(
          'v1/country_and_currency/get_currencies_for_country/'+ IdsToUpdateCountryAndCurrency.country_code,
          {
            country_code: countryID,
            currency_code: currency_code
          }
        )
        .then((response) => {
            if (response.data.data.length <= 0){
              errors.currency_name = 'A currency is not configured for the selected country. Please contact opuspay.'
            }
          if (response.data != null && !response.data.data.errors) {
            currencyOptions.value = [
              { value: '', text: 'Please select a currency' },
            ]
            if (response.data.data.length > 0) {
              if (response.data.data.length == 1) {
                currencyOptions.value = [
                  {
                    value: response.data.data[0].currency_code,
                    text: response.data.data[0].currency_name,
                  },
                ]
                form.currency = response.data.data[0].currency_code
                form.currency_name = response.data.data[0].currency_name
                form.currency_code = response.data.data[0].currency_code
              } else {
                response.data.data.find((currency) => {
                  let obj = {}
                  obj.value = currency.currency_code
                  obj.text = currency.currency_name
                  currencyOptions.value.push(obj)
                  allCurrencies.value.push(currency)
                })
                countryAndCurrencyCombination(countryID)
              }
            } else {
              currencyOptions.value = [
                { value: '', text: 'Please select a currency' },
              ]
              form.currency = ''
            }
          } else {
            currencyOptions.value = [
              { value: '', text: 'Please select a currency' },
            ]
            form.currency = ''
            bindValidationErrors(response.data.data.errors.validations)
            currencyDisable.value = true
            saveBtnDisable.value = true
            blockDisable.value = true
          }
          isLoading.value = false
        })
    }

    function  countryAndCurrencyCombination(countryID){
      for (let i=0; i< countriesAndCurrencies.value.length; i++){
        if(countryID === countriesAndCurrencies.value[i].country_code){
          let temp = {}
          temp.country_code = countriesAndCurrencies.value[i].country_code
          temp.currency_code = countriesAndCurrencies.value[i].currency_code
          countries_currencies.value.push(temp)
        }
      }
      if(countries_currencies.value.length === currencyOptions.value.length-1){
        errors.country_code = "A country and currency combination matching this selection already exists. Please select another country."
        currencyDisable.value = true
        saveBtnDisable.value = true
        blockDisable.value = true
      }
    }
    async function getCountryCode(countryId) {
      form.currency = null
      if (countryId) {
        allCountries.value.filter(function (elm) {
          if (elm.country_code == countryId) {
            form.country_code = elm.country_code
            form.country_name = elm.country_name
          }
        })
        form.currency_code = ''
        await getCurrency(countryId)
      } else {
        currencyOptions.value = [
          { value: '', text: 'Please select a currency' },
        ]
        form.currency = ''
        form.country_code = null
        form.currency_name = null
      }
    }

    async function getCurrencyCode(countryCode, currencyId) {
      isLoading.value = true
      errors.currency_name = ''

      if (currencyId) {
        countriesAndCurrencies.value.find((country_currency) => {
          if(country_currency.country_code == countryCode){
            if(country_currency.currency_code == currencyId){
              errors.currency_name = 'A country and currency combination matching this selection already exists. Please select another currency.'
            }
          }
        })
        
        await allCurrencies.value.filter(function (elm) {
          if (elm.currency_code == currencyId) {
            form.currency_code = elm.currency_code
            form.currency_name = elm.currency_name
          }
        })
      } else {
        form.currency_name = null
        form.currency_code = null
      }
      isLoading.value = false
    }

    async function createCountryAndCurrency() {
      let addForm = {}
      addForm.country_code = form.country_code
      addForm.country_name = form.country_name
      addForm.currency_code = form.currency_code
      addForm.currency_name = form.currency_name
      addForm.is_blocked = form.blocked
      isLoading.value = true
      resetErrors()
      await axios
        .post('v1/country_and_currency/create_country_and_currency', addForm)
        .then((response) => {
          if (response.data != null && !response.data.data.errors) {
            resetFormValues()
            getAllCountriesAndCurrencies()
            store.state.sidebarDisable = false
          } else {
            bindValidationErrors(response.data.data.errors.validations)
          }
          isLoading.value = false
        })
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          if(currencyOptions.value.length <=1 && validationData[prop][0] === 'Please select a currency.' && form.country != null) {
            validationData[prop][0] = 'A currency is not configured for the selected country. Please contact opuspay.'
          }
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function resetErrors() {
      errors.currency_code = null
      errors.country_code = null
      errors.currency_name = null
      errors.country_name = null
      errors.is_blocked = null
    }

    function resetFormValues() {
      currencyOptions.value = [{ value: '', text: 'Please select a currency' }]
      form.currency = null
      form.country = null
      form.currency_code = null
      form.country_code = null
      form.currency_name = null
      form.currency_name = null
      form.blocked = 0
      showAddNewRow.value = false
      IdsToDeleteCountryAndCurrency.country_code = null
      IdsToDeleteCountryAndCurrency.currency_code = null
    }

    function clearAddRow() {
      showAddNewRow.value = false
      currencyDisable.value = false
      saveBtnDisable.value = false
      blockDisable.value = false

      resetFormValues()
      resetErrors()
      store.state.sidebarDisable = false
    }
    function clearEditRow() {
      selectedRow.value = false
      resetErrors()
      resetFormValues()
      store.state.sidebarDisable = false
    }

    function updateCountryAndCurrency( country_code, currency_code) {
      let addForm = {}
      addForm.country_code = form.country_code
      addForm.currency_code = form.currency_code
      addForm.currency_name = form.currency_name
      addForm.country_name = form.country_name
      addForm.is_blocked = form.blocked
      isLoading.value = true
      resetErrors()
      axios
        .post(
          'v1/country_and_currency/edit_tnt_currencies_and_countries/' +
            country_code +
            '/' +
            currency_code,
          addForm
        )
        .then((response) => {
          if (response.data != null && !response.data.data.errors) {
            resetFormValues()
            getAllCountriesAndCurrencies(currentPage.value)
            selectedRow.value = false
            IdsToUpdateCountryAndCurrency.currency_code = null
            IdsToUpdateCountryAndCurrency.country_code = null
            store.state.sidebarDisable = false
          } else {
            bindValidationErrors(response.data.data.errors.validations)
          }
          isLoading.value = false
        })
      .catch(err => {
        router.push({ name: 'unauthorized-access'})
      })
    }

    function deleteCountryAndCurrency(tntId, country_code, currency_code) {
      let data = {}
      data.country_code = IdsToDeleteCountryAndCurrency.country_code
      data.currency_code = IdsToDeleteCountryAndCurrency.currency_code

      axios
        .delete(
          '/v1/country_and_currency/delete_tnt_currencies_and_countries/' +
            IdsToDeleteCountryAndCurrency.country_code +
            '/' +
            IdsToDeleteCountryAndCurrency.currency_code
          ,
          {params: data}
        )
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getAllCountriesAndCurrencies(currentPage.value)
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function deleteCountruAndCurrencyModal( country_code, currency_code) {
      IdsToDeleteCountryAndCurrency.country_code = country_code
      IdsToDeleteCountryAndCurrency.currency_code = currency_code
      modal.type = 'warning'
      modal.confirm_button = true
      modal.title = 'Warning'
      modal.button_text = 'No'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
      modal.confirmAlertType = 'delete'
    }

    function blockCountryAndCurrencyModal( country_code = null , currency_code = null) {
      let modalView = false;
      IdsToValidateCountryAndCurrency.country_code = null;
      IdsToValidateCountryAndCurrency.currency_code = null;

      if (country_code != null && currency_code != null){
        IdsToValidateCountryAndCurrency.country_code = country_code;
        IdsToValidateCountryAndCurrency.currency_code = currency_code;
      }
      countriesAndCurrencies.value.forEach((value) => {

        if (value.country_code == form.country && value.is_blocked != form.blocked){
          modalView = true;
          return false;
        }
      })
      if (modalView) {
        modal.type = 'warning'
        modal.confirm_button = true
        modal.title = 'Warning'
        modal.button_text = 'No'
        modal.message =
          'All the other entries with the same country will be updated with blocked/unblocked status as per the current record.'
        modal.show = true
        modal.confirmAlertType = 'block'

      }else {
        if(IdsToValidateCountryAndCurrency.country_code != null  && IdsToValidateCountryAndCurrency.currency_code  != null){
          updateCountryAndCurrency(IdsToValidateCountryAndCurrency.country_code, IdsToValidateCountryAndCurrency.currency_code)
        }else {
          createCountryAndCurrency()
        }
      }

    }

    function closeAlert() {
      modal.show = false
      if (modal.button_text !== 'No') {
        modal.show = false
      }
    }

    function confirmAlert() {
      if (modal.confirmAlertType == 'delete'){
        deleteCountryAndCurrency()
      }else if(modal.confirmAlertType == 'block'){
        if(IdsToValidateCountryAndCurrency.country_code != null  && IdsToValidateCountryAndCurrency.currency_code  != null){
          updateCountryAndCurrency(IdsToValidateCountryAndCurrency.country_code, IdsToValidateCountryAndCurrency.currency_code)
        }else {
          createCountryAndCurrency()
        }
      }

      modal.show = false
    }

    async function selectRow(
      index,
      countryAndCurrency,
      country_code,
      currency_code
    ) {
      selectedRow.value = index
      IdsToUpdateCountryAndCurrency.currency_code = currency_code
      IdsToUpdateCountryAndCurrency.country_code = country_code
      await getCurrency(country_code,currency_code)
      form.country = country_code
      form.currency = currency_code
      form.country_code = country_code
      form.currency_code = currency_code
      form.currency_name = countryAndCurrency.currency_name
      form.country_name = countryAndCurrency.country_name
      form.blocked = countryAndCurrency.is_blocked
      IdsToUpdateCountryAndCurrency.currency_code = currency_code
      IdsToUpdateCountryAndCurrency.country_code = country_code

    }

    function attachedPlanIsNotActive(planId, planStatus) {
      if (planId != null && planStatus == 'INA') {
        return true
      } else if (planId == null) {
        return true
      } else {
        return false
      }
    }

    return {
      t,
      showAddNewRow,
      sortColumnDirection,
      form,
      onchange,
      countryOptions,
      countryISOCodes,
      currencyOptions,
      getCountry,
      getCountryCode,
      getCurrencyCode,
      getCurrency,
      createCountryAndCurrency,
      errors,
      resetErrors,
      isLoading,
      fullPage,
      modal,
      closeAlert,
      clearAddRow,
      getAllCountriesAndCurrencies,
      countriesAndCurrencies,
      editRows,
      editForm,
      updateRaw,
      deleteCountryAndCurrency,
      confirmAlert,
      deleteCountruAndCurrencyModal,
      selectRow,
      selectedRow,
      updateCountryAndCurrency,
      perPage,
      sortColumn,
      sort,
      searchText,
      IdsToUpdateCountryAndCurrency,
      totalRecord,
      lastPage,
      attachedPlanIsNotActive,
      blockCountryAndCurrencyModal,
      currencyDisable,
      saveBtnDisable,
      blockDisable,
      clearEditRow,
    }
  },
}
</script>
