<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Transaction Router") }}
        </h1>

        <div class="flex items-center justify-between space-x-3">
          <Button
            id="button_add-plan"
            class="btn-primary-dark btn-lg btn-wide shadow-lg"
            type="button"
            @click="addRule"
          >
            {{ t("Add") }}
          </Button>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page_1"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getRouterRuleData()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getRouterRuleData(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="transaction_router_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Rule") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('description')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'description'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Priority") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('priority')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'priority'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transactionR, index) in transactionRoute"
                :id="'transaction_router_' + index"
                :key="index"
              >
                <td class="max-w-3xl">
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/settings/router-rule/' + transactionR.rule_id }"
                    @click="viewRouterRuleById(transactionR.rr_id)"
                  >
                    {{ transactionR.rr_des }}
                  </router-link>
                </td>
                <td class="max-w-3xl">
                  {{ transactionR.rr_priority }}
                </td>
                <td class="text-center">
                  <badge
                    v-if="transactionR.rr_status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="transactionR.rr_status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="copy"
                      @click="copyRouterRule(transactionR.rr_id)"
                    />
                    <button-circle
                      type="info"
                      icon="eye"
                      @click="viewRouterRuleById(transactionR.rr_id)"
                    />
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="editRouterRule(transactionR.rr_id)"
                    />
                    <button-circle
                      type="danger"
                      icon="trash"
                      @click="deleteRouterRule(transactionR.rr_id)"
                    />
                  </div>
                </td>
              </tr>
              <no-table-data v-if="totalValue == 0" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalValue"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getRouterRuleData($event)"
      />
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import axios from 'axios'
import router from '@/routes'
import NoTableData from '@/components/NoTableData.vue'
import Modal from '@/components/Modal.vue'
import Badge from "@/components/Badge";

export default {
  name: 'RouterRuleList',

  components: {
    Button,
    Search,
    Loading,
    SvgIcon,
    FormSelect,
    PageHeader,
    TableDefault,
    ButtonCircle,
    VuePagination,
    NoTableData,
    Modal,
    Badge,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const perPage = ref(10)
    const currentPage = ref(1)
    const totalValue = ref(0)
    const lastPage = ref(null)
    const status = ref(null)
    const transactionRoute = ref(0)
    const searchText = ref('')
    const IdToDelete = ref(false)

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: false,
      button_text: 'Done',
    })

    function addRule() {
      router.push({ name: 'router-rule' })
    }

    async function getRouterRuleData(
        page = 1,
        column = sortColumn.value,
        search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
          .get('v1/get-all-router-rules', {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              transactionRoute.value = response.data.data.data
              totalValue.value = response.data.data.total
              currentPage.value = response.data.data.current_page
              lastPage.value = response.data.data.last_page
            } else {
              console.error('Error!')
            }
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
    }

    async function sort(column) {
      sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getRouterRuleData(1, sortColumn.value, searchText.value)
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('name')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function viewRouterRuleById(rule_id){
      router.push({ name: 'router-rule-id', params: { rule_id: rule_id } })
    }

    function editRouterRule(rule_id){
      router.push({ name: 'router-rule-edit', params: { rule_id: rule_id } })
    }

    function deleteRouterRule(rule_id){
      IdToDelete.value = rule_id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
          'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function closeAlert() {
      if (modal.button_text === 'No') {
        modal.show = false
      } else {
        modal.show = false
        router.push('/settings/router-rule-index')
      }
    }

    function confirmAlert() {
      deleteData()
      modal.show = false
      return true
    }

    function deleteData() {
      let data = {}

      axios
          .delete('/v1/delete-router-rule/' + IdToDelete.value, {params: data})
          .then((response) => {
            if (response.data.data != null && response.data.status == true) {
              getRouterRuleData()
            }
            else {
              router.push({ name: 'unauthorized-access'})
            }
          })
    }

    function copyRouterRule(rule_id){
      isLoading.value = true
      axios
          .post('/v1/copy-router-rule/' + rule_id)
          .then((response) => {
            if (response.data.data != null) {
              modal.confirm_button = false
              modal.close_button = true
              modal.button_text = 'Ok'
              modal.type = 'success'
              modal.title = 'Success'
              modal.message = 'Router rule copied successfully.'
              modal.show = true
              isLoading.value = false
              getRouterRuleData()
            }
            else {
              router.push({ name: 'unauthorized-access'})
            }
      })
    }

    return {
      t,
      fullPage,
      isLoading,
      addRule,
      transactionRoute,
      searchText,
      sortColumnDirection,
      sortColumn,
      getRouterRuleData,
      perPage,
      currentPage,
      lastPage,
      status,
      sort,
      totalValue,
      viewRouterRuleById,
      editRouterRule,
      deleteRouterRule,
      modal,
      confirmAlert,
      closeAlert,
      copyRouterRule,
    }
  },
}
</script>
