<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center flex-wrap">
        <back-button :route-to="{ name: 'dropdown-fields' }" />
        <h1
          v-if="slu === 'registration_rejection'"
          class="page-title"
        >
          {{ t("Field Options") }}: {{ t("Registration Rejection") }}
        </h1>
        <h1
          v-if="slu === 'payment_methods'"
          class="page-title"
        >
          {{ t("Field Options") }}: {{ t("Payment Methods") }}
        </h1>
        <h1
          v-if="slu === 'payment_device_type'"
          class="page-title"
        >
          {{ t("Field Options") }}: {{ t("Gateway Device Capability") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div
        v-if="false"
        class="md:flex gap-x-3"
      >
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Options") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('description')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'description'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  v-if="slu === 'payment_methods'"
                  scope="col"
                >
                  <div class="flex items-center justify-center">
                    <span>{{ t("Credit/Debit") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('is_card_pmt')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'is_card_pmt'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  v-if="slu === 'payment_methods'"
                  scope="col"
                >
                  <div class="flex items-center justify-center">
                    <span>{{ t("Category") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('pm_cat')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'pm_cat'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(dropdownOption, index) in dropdownOptions"
                :key="index"
              >
                <td
                  v-if="dropdownOption.pm_cat != 'CARD_NOT_PRESENT'"
                  class="max-w-6xl"
                >
                  {{ dropdownOption.description }}
                </td>
                <td
                  v-if="slu === 'payment_methods' && dropdownOption.pm_cat != 'CARD_NOT_PRESENT'"
                  class="text-center"
                >
                  <form-checkbox
                    v-if="slu === 'payment_methods' "
                    :id="'isBlockedLabel' + index"
                    v-model="credit_or_debit"
                    :disabled="true"
                    name="blocked"
                    type="checkbox"
                    :model-value="credit_or_debit == 1 ? 0 : 1"
                    :checked="
                      dropdownOption.is_card_pmt == 1 ? true : false
                    "
                  />
                </td>
                <td
                  v-if="slu === 'payment_methods' && dropdownOption.pm_cat != 'CARD_NOT_PRESENT'"
                  class="text-center"
                >
                  <form-select
                    v-if="slu === 'payment_methods'"
                    id="assign-pay-cat"
                    v-model="dropdownOption.pm_cat"
                    class="mt-1"
                    :items="paymentCategoryOptions"
                    name="assign-tax-group"
                    :disabled="true"
                  />
                </td>
              </tr>

              <no-table-data v-if="!dropdownOptions.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <div class="flex items-center justify-end">
        <Button
          v-if="true"
          class="btn-wide"
          type="button"
          @click="$router.push({ name: 'dropdown-field-edit' })"
        >
          {{ t("Edit") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { useRoute } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import PageHeader from '@/components/PageHeader.vue'
import FormSelect from '@/components/FormSelect.vue'
import BackButton from '@/components/BackButton.vue'
import NoTableData from '@/components/NoTableData.vue'
import TableDefault from '@/components/TableDefault.vue'
import FormCheckbox from "../../components/FormCheckbox";

export default {
  name: 'DropdownField',

  components: {
    Search,
    Button,
    Loading,
    SvgIcon,
    FormSelect,
    BackButton,
    PageHeader,
    NoTableData,
    TableDefault,
    FormCheckbox,
  },

  setup() {
    const { t } = useI18n()
    const sortColumnDirection = ref('desc')
    const route = useRoute()
    const dropdownOptions = ref(0)
    const slu = ref('')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const sortColumn = ref('')
    const credit_or_debit = ref(0)

    const paymentCategoryOptions = ref([{ value: '', text: 'Select a Payment Category' },
      {value: 'CARD_PRESENT', text: 'Card Present'},
      {value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
      {value: 'CNP_CARD', text: 'Online Card'},
      {value: 'CNP_ACH', text: ' ACH'},
    ])

    onMounted(() => {
      getAllOptionsPerDropdownField()
    })

    async function getAllOptionsPerDropdownField(
      page = 1,
      column = sortColumn.value
    ) {
      isLoading.value = true
      const slug = route.params.slug
      slu.value = slug
      await axios
        .get(
          '/v1/get-all-dropdown-option/' +
            slug +
            '?sortColumn=' +
            column +
            '&sortDirection=' +
            sortColumnDirection.value
        )
        .then((response) => {
          if (response.data != null) {
            dropdownOptions.value = response.data.data.options.data
            isLoading.value = false
          } else {
            console.log('Error !')
          }
        })
    }

    async function sort(column, direction) {
      sortColumn.value = column
      const slug = route.params.slug

      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getAllOptionsPerDropdownField(1, sortColumn.value)
    }

    return {
      t,
      dropdownOptions,
      sortColumnDirection,
      getAllOptionsPerDropdownField,
      sort,
      slu,
      fullPage,
      isLoading,
      sortColumn,
      credit_or_debit,
      paymentCategoryOptions,
    }
  },
}
</script>
