<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <back-button :route-to="{ name: 'plans' }" />
          <h1
            id="page-title"
            class="page-title"
          >
            {{ t("Plan Summary") }}
          </h1>
        </div>

        <Button
          id="button_edit-plan-summary"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="NavigateToEdit()"
        >
          {{ t("Edit") }}
        </Button>
      </div>

      <div class="mt-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-4 break-words">
        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Name") }}
          </div>
          <div class="flex flex-wrap items-center gap-x-2">
            <div class="mr-1 font-medium text-white text-base break-all w-full">
              {{ displayValues.name }}
            </div>
            <div
              v-if="displayValues.status == 'ACT'"
              class="
                border border-white
                mt-0.5
                px-1.5
                py-0.5
                font-medium
                uppercase
                text-xs text-white
                rounded
              "
            >
              {{ t("Active") }}
            </div>
            <div
              v-if="displayValues.status == 'INA'"
              class="
                border border-white
                mt-1
                px-1.5
                py-0.5
                font-medium
                uppercase
                text-xs text-white
                rounded
              "
            >
              {{ t("Inactive") }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Merchant Country") }}
          </div>
          <div class="font-medium text-white text-base">
            {{ displayValues.country }}
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Currency") }}
          </div>
          <div class="font-medium text-white text-base">
            {{ displayValues.currency }}
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Type") }}
          </div>
          <div
            v-if="form.type == 0"
            class="font-medium text-white text-base"
          >
            {{ t("Standard") }}
          </div>
          <div
            v-if="form.type == 1"
            class="font-medium text-white text-base"
          >
            {{ t("Custom") }}
          </div>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="tab-group">
        <div
          v-if="displayValues.type != 0"
          class="tabs-wrapper"
        >
          <nav
            class="tabs"
            aria-label="Tabs"
          >
            <a
              v-for="(tabItem, index) in tabItems"
              :key="index"
              href="#"
              class="tab-item"
              :class="{ active: activeTabId == tabItem.id }"
              @click="showTabContent('activeTabId', tabItem.id)"
            >{{ tabItem.name }}</a>
          </nav>
        </div>

        <div class="tab-container">
          <div v-if="activeTabId == 'tab1'">
            <div
              v-if="displayValues.type != 1"
              class="mb-6 font-medium text-black text-opacity-75"
            >
              {{ t("opuspay Transaction Charges") }}
            </div>

            <div class="mb-4 grid grid-cols-2 gap-y-3">
              <div
                v-if="false"
                class="flex items-center"
              >
                <span class="text-sm">{{ t("Show") }}</span>
                <div class="mx-2">
                  <form-select
                    id="results_per_page"
                    v-model="tiersPerPage"
                    :items="[10, 25, 50, 100]"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span class="text-sm">{{ t("entries") }}</span>
              </div>

              <div class="w-full flex items-center justify-end space-x-2">
                <div class="w-full lg:w-3/6">
                  <search
                    v-if="false"
                    v-model="tiersSearchText"
                    @dataFetchfunction="
                      getPlanTiersByPlanId(1, tiersSortColumn, $event)
                    "
                  />
                </div>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <table
                  class="table"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th
                        class="th-action"
                        scope="col"
                      />
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Quantity From") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                            @click="sortPlanTiersByPlanId('quantity_from')"
                          >
                            <svg-icon
                              v-if="
                                tiersSortColumnDirection == 'desc' &&
                                  tiersSortColumn == 'quantity_from'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Quantity To") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                            @click="sortPlanTiersByPlanId('quantity_to')"
                          >
                            <svg-icon
                              v-if="
                                tiersSortColumnDirection == 'desc' &&
                                  tiersSortColumn == 'quantity_to'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Invoice By") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                            @click="sortPlanTiersByPlanId('invoice_by')"
                          >
                            <svg-icon
                              v-if="
                                tiersSortColumnDirection == 'desc' &&
                                  tiersSortColumn == 'invoice_by'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Rate") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                            @click="sortPlanTiersByPlanId('tier_rate')"
                          >
                            <svg-icon
                              v-if="
                                tiersSortColumnDirection == 'desc' &&
                                  tiersSortColumn == 'tier_rate'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        class="th-action"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(transactionCharge, index) in form.tiers"
                      :key="index"
                    >
                      <td class="text-center">
                        {{ index + 1 }}
                      </td>
                      <td>
                        <span v-if="index !== selectedRow">{{
                          transactionCharge.quantity_from
                        }}</span>
                        <div v-if="index == selectedRow">
                          <form-input
                            v-model="form.tier.quantity_from"
                            class="py-1.5"
                            name="quantity_form"
                            type="number"
                            min="0"
                            required
                            oninput="javascript:this.value = this.value.replace(/[^0-9][.]/g, '');"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.quantity_from
                                ? form.errors.validations.quantity_from[0]
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>
                        <span v-if="index !== selectedRow">{{
                          transactionCharge.quantity_to
                        }}</span>
                        <div v-if="index == selectedRow">
                          <form-input
                            v-model="form.tier.quantity_to"
                            class="py-1.5"
                            name="quantity_to"
                            type="number"
                            min="0"
                            required
                            oninput="javascript:this.value = this.value.replace(/[^0-9][.]/g, '');"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.quantity_to
                                ? form.errors.validations.quantity_to[0]
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>
                        <span v-if="index !== selectedRow">{{
                          capitalizeFirstLetter(transactionCharge.invoice_by)
                        }}</span>
                        <div v-if="index == selectedRow">
                          <form-select
                            v-model="form.tier.invoice_by"
                            class="py-1.5"
                            name="invoice_by"
                            required
                            :items="invoiceByOptions"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.invoice_by
                                ? form.errors.validations.invoice_by[0]
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>
                        <span v-if="index !== selectedRow">{{
                          parseFloat(transactionCharge.tier_rate).toFixed(2)
                        }}</span>
                        <div v-if="index == selectedRow">
                          <form-input
                            v-model="form.tier.tier_rate"
                            class="py-1.5"
                            name="tier_rate"
                            type="number"
                            min="0"
                            required
                            step="0.01"
                            oninput="javascript:this.value = this.value.replace(/[^0-9][.]/g, '');"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.tier_rate
                                ? form.errors.validations.tier_rate[0]
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            v-if="selectedRow != index"
                            type="info"
                            icon="edit"
                            :disabled="true"
                            @click="
                              selectRow(index),
                              (showTransactionChargesAddRow = false)
                            "
                          />
                          <button-circle
                            v-if="isEditMode && selectedRow == index"
                            type="success"
                            icon="check"
                            :disabled="true"
                            @click="onSubmitPlanTier()"
                          />
                          <button-circle
                            v-if="selectedRow != index"
                            type="danger"
                            icon="trash"
                            :disabled="true"
                            @click="
                              deleteTierValue(transactionCharge.plan_tier_id)
                            "
                          />
                        </div>
                      </td>
                    </tr>

                    <tr v-if="showTransactionChargesAddRow">
                      <td class="text-center" />
                      <td>
                        <form-input
                          v-model="form.tier.quantity_from"
                          class="py-1.5"
                          name="quantity_form"
                          type="number"
                          min="0"
                          required
                          oninput="javascript:this.value = this.value.replace(/[^0-9][.]/g, '');"
                        />
                        <form-error
                          v-if="form.errors"
                          :message="
                            form.errors.validations.quantity_from
                              ? form.errors.validations.quantity_from[0]
                              : ''
                          "
                        />
                      </td>
                      <td>
                        <form-input
                          v-model="form.tier.quantity_to"
                          class="py-1.5"
                          name="quantity_to"
                          type="number"
                          min="0"
                          required
                          oninput="javascript:this.value = this.value.replace(/[^0-9]/g, '');"
                        />
                        <form-error
                          v-if="form.errors"
                          :message="
                            form.errors.validations.quantity_to &&
                              !form.errors.validations.quantity_from
                              ? form.errors.validations.quantity_to[0]
                              : ''
                          "
                        />
                      </td>
                      <td>
                        <form-select
                          v-model="form.tier.invoice_by"
                          class="py-1.5"
                          name="invoice_by"
                          required
                          :items="invoiceByOptions"
                        />
                        <form-error
                          v-if="form.errors"
                          :message="
                            form.errors.validations.invoice_by &&
                              !form.errors.validations.quantity_from &&
                              !form.errors.validations.quantity_to
                              ? form.errors.validations.invoice_by[0]
                              : ''
                          "
                        />
                      </td>
                      <td>
                        <form-input
                          v-model="form.tier.tier_rate"
                          class="py-1.5"
                          name="tier_rate"
                          type="number"
                          min="0"
                          required
                          step="0.01"
                          oninput="javascript:this.value = this.value.replace(/[^0-9][.]/g, '');"
                        />
                        <form-error
                          v-if="form.errors"
                          :message="
                            form.errors.validations.tier_rate &&
                              !form.errors.validations.quantity_from &&
                              !form.errors.validations.quantity_to &&
                              !form.errors.validations.invoice_by
                              ? form.errors.validations.tier_rate[0]
                              : ''
                          "
                        />
                      </td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            v-if="true"
                            type="success"
                            icon="check"
                            @click="onSubmitPlanTier()"
                          />
                          <button-circle
                            v-if="true"
                            type="danger"
                            icon="trash"
                            @click="
                              ;(showTransactionChargesAddRow = false),
                                resetTier()
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <no-table-data v-if="!form.tiers.length" />
                  </tbody>
                </table>
              </template>
            </TableDefault>

            <vue-pagination
              v-if="false"
              :page-size="tiersPerPage"
              :total-records="tiersTotalRecord"
              :sort-column="tiersSortColumn"
              :sort-direction="tiersSortColumnDirection"
              :last-page="tiersLastPage"
              @page-changed="getPlanTiersByPlanId()"
            />
          </div>
          <div v-if="activeTabId == 'tab2'">
            <div class="mb-4 grid grid-cols-2 gap-y-3">
              <div
                v-if="false"
                class="flex items-center"
              >
                <span class="text-sm">{{ t("Show") }}</span>
                <div class="mx-2">
                  <form-select
                    id="results_per_page"
                    v-model="assignedAccountsPerPage"
                    :items="[10, 25, 50, 100]"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span class="text-sm">{{ t("entries") }}</span>
              </div>

              <div class="w-full flex items-center justify-end space-x-2">
                <div class="w-full lg:w-3/6">
                  <search
                    v-if="false"
                    v-model="assignedAccountsSearchText"
                    @dataFetchfunction="
                      getMerchantsByPlanId(
                        1,
                        assignedAccountsSortColumn,
                        $event
                      )
                    "
                  />
                </div>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <table
                  class="table"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Merchant") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('organisation_name')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn ==
                                  'organisation_name'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Address 1") }}</span>
                          <button
                            v-if="false"
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('address_1')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'asc' &&
                                  assignedAccountsSortColumn == 'address_1'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Suburb/Town") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('suburb')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'suburb'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("State") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('state')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'state'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Country") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sortMerchantsByPlanId('country')"
                          >
                            <svg-icon
                              v-if="
                                assignedAccountsSortColumnDirection == 'desc' &&
                                  assignedAccountsSortColumn == 'country'
                              "
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        class="th-action"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(assignedAccount, index) in form.assignedAccounts"
                      :key="index"
                    >
                      <td class="max-w-xs">
                        {{ assignedAccount.merchant_name }}
                      </td>
                      <td class="max-w-xs">
                        {{ assignedAccount.address1 }}
                      </td>
                      <td>
                        {{ assignedAccount.town }}
                      </td>
                      <td>
                        {{ assignedAccount.state }}
                      </td>
                      <td>
                        {{ assignedAccount.country }}
                      </td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            type="danger"
                            icon="trash"
                            :disabled="true"
                            @click="
                              setAssignPlanData(assignedAccount.merchant),
                              resetErrors()
                            "
                          />
                        </div>
                      </td>
                    </tr>

                    <tr v-if="showAssignedAccountRow">
                      <td>
                        <div>
                          <form-select
                            v-model="form.assignedAccount.merchant"
                            class="py-1.5"
                            name=""
                            required
                            :change="onchange"
                            :items="merchantOptions"
                            @onChangeFunction="setMerchantsDetails()"
                          />
                          <form-error
                            v-if="form.errors"
                            :message="
                              form.errors.validations.merchant_id
                                ? form.errors.validations.merchant_id[0]
                                : ''
                            "
                          />
                        </div>
                      </td>
                      <td>{{ form.assignedAccount.address1 }}</td>
                      <td>{{ form.assignedAccount.town }}</td>
                      <td>{{ form.assignedAccount.state }}</td>
                      <td>{{ form.assignedAccount.country }}</td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <button-circle
                            type="success"
                            icon="check"
                            @click="onSubmitAssignAccount()"
                          />
                          <button-circle
                            type="danger"
                            icon="trash"
                            @click="
                              ;(showAssignedAccountRow = false),
                                resetAssignedAccounts()
                            "
                          />
                        </div>
                      </td>
                    </tr>
                    <no-table-data v-if="!form.assignedAccounts.length" />
                  </tbody>
                </table>
              </template>
            </TableDefault>

            <vue-pagination
              v-if="false"
              :page-size="assignedAccountsPerPage"
              :total-records="assignedAccountsTotalRecord"
              :sort-column="assignedAccountsSortColumn"
              :sort-direction="assignedAccountsSortColumnDirection"
              :last-page="assignedAccountsLastPage"
              @page-changed="getMerchantsByPlanId()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="name">
            {{ t("Name") }} <required />
          </form-label>
          <form-input
            id="name"
            class="mt-1"
            name="name"
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.name
                ? form.errors.validations.name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="country">
            {{ t("Merchant Country") }} <required />
          </form-label>
          <form-select
            id="country"
            class="mt-1"
            name="country"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.country && !form.errors.validations.name
                ? form.errors.validations.country[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="currency">
            {{ t("Currency") }} <required />
          </form-label>
          <form-select
            id="currency"
            class="mt-1"
            name="currency"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.currency &&
                !form.errors.validations.name &&
                !form.errors.validations.country
                ? form.errors.validations.currency[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="status"
            class="mt-1"
            name="status"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.status &&
                !form.errors.validations.name &&
                !form.errors.validations.country &&
                !form.errors.validations.currency
                ? form.errors.validations.status[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Type") }}
          </form-label>
          <div class="mt-2 flex items-center">
            <form-radio
              id="type_standard"
              name="type"
            />
            <label
              class="ml-2 text-sm"
              for="type_standard"
            >{{ t("Standard") }}</label>
          </div>
          <div class="mt-1 flex items-center">
            <form-radio
              id="type_custom"
              name="type"
            />
            <label
              class="ml-2 text-sm"
              for="type_custom"
            >{{ t("Custom") }}</label>
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <slide-over
    :show="assignPlanSlideOver.show"
    :slideover-size="assignPlanSlideOver.size"
    :slideover-title="assignPlanSlideOver.title"
    :close-button="assignPlanSlideOver.close_button"
    @closeSlideOver="assignPlanSlideOver.show = false"
  >
    <template #slideover-body>
      <p class="mb-3 text-sm">
        {{ t("The following Merchant has been removed from a Custom Plan and must be assigned to a new Plan") }}.
      </p>

      <div class="space-y-4">
        <div>
          <form-label
            for="merchant-name"
          >
            {{ t("Merchant Name") }} <required />
          </form-label>
          <form-input
            id="merchant-name"
            class="mt-1"
            name="name"
            type="text"
            disabled
          />
        </div>

        <div>
          <form-label
            for="assign-tax-group"
          >
            {{ t("Assign a Plan") }} <required />
          </form-label>
          <form-select
            id="assign-plan"
            class="mt-1"
            name="assign-plan"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.plan_id
                ? form.errors.validations.plan_id[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
  >
    <template #modal-body>
      <p class="mb-1">
        {{ t("Changing the Plan Type will delete all Tier-Level rows") }}.
      </p>
      <p class="mb-1">
        {{ t("Would you like to continue") }}?
      </p>
      <p>{{ t("Click Cancel to revert back to the previous Plan Type") }}.</p>
    </template>

    <template #modal-actions>
      <ButtonSecondary
        class="btn-wide btn-danger"
        type="button"
      >
        {{ t("Cancel") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
      >
        {{ t("Continue") }}
      </Button>
    </template>
  </modal>
</template>

<script>
import { ref, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormRadio from '@/components/FormRadio.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary'
import BackButton from '@/components/BackButton'
import axios from 'axios'
import router from '@/routes'
import { useRoute } from 'vue-router'

export default {
  name: 'PlanSummary',

  components: {
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormRadio,
    FormSelect,
    PageHeader,
    NoTableData,
    ButtonCircle,
    TableDefault,
    VuePagination,
    ButtonSecondary,
    BackButton,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const navigateToEditPage = ref(false)
    const onchange = ref(true)
    const sortColumnDirection = ref('desc')
    const tiersLastPage = ref(0)
    const tiersCurrentPage = ref(0)
    const tiersPerPage = ref('10')
    const tiersSortColumnDirection = ref('desc')
    const tiersSortColumn = ref('')
    const tiersSearchParam = ref('')
    const tiersSearchText = ref('')
    const tiersTotalRecord = ref(0)
    const assignedAccountsLastPage = ref(0)
    const assignedAccountsCurrentPage = ref(0)
    const assignedAccountsPerPage = ref('10')
    const assignedAccountsSortColumnDirection = ref('desc')
    const assignedAccountsSortColumn = ref('')
    const assignedAccountsSearchParam = ref('')
    const assignedAccountsSearchText = ref('')
    const assignedAccountsTotalRecord = ref(0)
    const previousType = ref(null)
    const environment = ref('')
    const countryOptions = ref([
      { value: '', text: 'Please select a merchant country' },
    ])
    const merchantOptions = ref([
      { value: '', text: 'Please select a merchant' },
    ])
    const planOptions = ref([{ value: '', text: 'Select a Plan' }])
    const currencyOptions = ref([
      { value: '', text: 'Please select a currency' },
    ])
    const selectedRow = ref(null)
    const isEditMode = ref(false)
    const isSaveSummeryChanges = ref(false)
    const showAssignedAccountRow = ref(false)
    const showTransactionChargesAddRow = ref(false)
    const route = useRoute()
    const planTempData = reactive({
      id: null,
      name: null,
      country: null,
      currency: null,
      status: null,
      type: null,
    })
    const displayValues = reactive({
      name: null,
      country: null,
      currency: null,
      status: null,
      type: null,
      is_merchant_available: null,
    })
    const form = reactive({
      id: null,
      name: null,
      country: null,
      currency: null,
      status: null,
      type: null,
      tier: {
        plan_tier_id: null,
        quantity_from: null,
        quantity_to: null,
        invoice_by: 'transaction',
        tier_rate: null,
      },
      assignedAccount: {
        merchant: null,
        merchant_name: null,
        address1: null,
        town: null,
        state: null,
        country: null,
      },
      tiers: [],
      assignedAccounts: [],
      row_version: null,
      errors: '',
    })
    const assignPlanForm = reactive({
      merchant: null,
      merchant_name: null,
      plan: null,
    })

    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      title: 'Edit Plan Summary',
    })

    const assignPlanSlideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      title: 'Assign A Plan',
    })

    const modal = reactive({
      show: false,
      type: 'error',
      title: 'Warning',
      close_button: false,
      confirm_button: false,
      button_text: '',
    })
    const tabItems = reactive([
      { id: 'tab1', name: 'Transaction Charges' },
      // { id: 'tab2', name: 'Assigned Accounts' },
    ])
    const activeTabId = ref(tabItems[0].id)

    const invoiceByOptions = reactive([
      { value: 'month', text: 'Month' },
      { value: 'transaction', text: 'Transaction' },
    ])
    const statusOptions = reactive([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])
    setEnvironment()
    getPlanById()
    getMerchantsByPlanId()

    function isClickedType() {
      if (displayValues.type == 1 && tabItems.length < 2) {
        tabItems.push({ id: 'tab2', name: 'Assigned Accounts' })
      } else if (displayValues.type == 0) {
        tabItems.splice(1, 1)
        activeTabId.value = 'tab1'
      }
    }

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    function selectRow(index) {
      selectedRow.value = index
      isEditMode.value = true
      form.tier = form.tiers[index]
    }

    async function getMerchantsByPlanId(
      page = 1,
      column = assignedAccountsSortColumn.value,
      search = null
    ) {
      isLoading.value = true
      const plan_id = route.params.slug
      if (search != null) {
        assignedAccountsSearchText.value = search
      }
      await axios
        .get('v1/merchants', {
          params: {
            plan_id: plan_id,
            search: assignedAccountsSearchText.value,
            count_per_page: assignedAccountsPerPage.value,
            page: page,
            sortColumn: column,
            sortDirection: assignedAccountsSortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            form.assignedAccounts = []
            response.data.data.data.find((merchant) => {
              let obj = {}
              obj.merchant = merchant.mch_id
              obj.merchant_name = merchant.organisation_name
              obj.address1 = merchant.address1
              obj.state = merchant.state_name
              obj.town = merchant.suburb_name
              obj.country = merchant.country_name
              form.assignedAccounts.push(obj)
            })
            assignedAccountsLastPage.value = response.data.data.last_page
            assignedAccountsTotalRecord.value = response.data.data.total
          }
        })
      isLoading.value = false
    }

    async function sortMerchantsByPlanId(column) {
      assignedAccountsSortColumn.value = column

      assignedAccountsSortColumnDirection.value =
        assignedAccountsSortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getMerchantsByPlanId(
        1,
        assignedAccountsSortColumn.value,
        assignedAccountsSearchText.value
      )
    }

    async function getPlanTiersByPlanId(
      page = 1,
      column = tiersSortColumn.value,
      search = null
    ) {
      column = 'quantity_from'
      tiersSortColumnDirection.value = 'asc'
      isLoading.value = true
      const plan_id = route.params.slug
      if (search != null) {
        tiersSearchText.value = search
      }
      await axios
        .get('v1/plan-tiers/' + plan_id, {
          params: {
            search: tiersSearchText.value,
            count_per_page: tiersPerPage.value,
            page: page,
            sortColumn: column,
            sortDirection: tiersSortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            form.tiers = response.data.data.data
            tiersLastPage.value = response.data.data.last_page
            tiersTotalRecord.value = response.data.data.total
          }
        })
      isLoading.value = false
    }

    async function sortPlanTiersByPlanId(column) {
      tiersSortColumn.value = column
      tiersSortColumnDirection.value =
        tiersSortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getPlanTiersByPlanId(
        1,
        tiersSortColumn.value,
        tiersSearchText.value
      )
    }

    async function getPlanById() {
      isLoading.value = true
      const plan_id = route.params.slug
      if (plan_id != null) {
        await axios.get('/v1/plan/' + plan_id).then((response) => {
          if (response.data != null) {
            form.id = response.data.data.plan_id
            form.name = response.data.data.plan_name
            form.country = response.data.data.country_code
            planTempData.currency = response.data.data.currency_code
            form.type = response.data.data.plan_type
            form.status = response.data.data.status
            form.row_version = response.data.data.row_version
            form.tiers = response.data.data.plan_tiers
            displayValues.name = response.data.data.plan_name
            displayValues.country = response.data.data.country_name
            displayValues.currency = response.data.data.currency_name
            displayValues.status = response.data.data.status
            displayValues.type = response.data.data.plan_type
            displayValues.is_merchant_available =
              response.data.data.merchants.length
            previousType.value = response.data.data.plan_type
          } else {
            console.error('Error!')
          }
          isLoading.value = false
        })
      }
      isClickedType()
    }

    function setEnvironment() {
      if (router.currentRoute.value.name == 'plan-summary-view') {
        environment.value = 'View'
      }
      if (router.currentRoute.value.name == 'plan-summary-edit') {
        environment.value = 'Edit'
      }
    }

    function NavigateToEdit() {
      try{
        const plan_id = route.params.slug
        router.push({ name: 'plans', params: { slug: plan_id } })
        sessionStorage.setItem('plan_id', plan_id);
        environment.value = 'Edit'
      }catch(e) {
        console.error("error")
      }
    }
    
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return {
      t,
      form,
      modal,
      tabItems,
      fullPage,
      isLoading,
      slideOver,
      selectRow,
      isEditMode,
      selectedRow,
      activeTabId,
      showTabContent,
      currencyOptions,
      invoiceByOptions,
      assignPlanSlideOver,
      sortColumnDirection,
      showAssignedAccountRow,
      showTransactionChargesAddRow,
      statusOptions,
      countryOptions,
      merchantOptions,
      onchange,
      environment,
      isClickedType,
      displayValues,
      tiersLastPage,
      tiersCurrentPage,
      tiersPerPage,
      tiersSortColumnDirection,
      tiersSortColumn,
      tiersSearchParam,
      tiersSearchText,
      tiersTotalRecord,
      assignedAccountsLastPage,
      assignedAccountsCurrentPage,
      assignedAccountsPerPage,
      assignedAccountsSortColumnDirection,
      assignedAccountsSortColumn,
      assignedAccountsSearchParam,
      assignedAccountsSearchText,
      assignedAccountsTotalRecord,
      getMerchantsByPlanId,
      sortMerchantsByPlanId,
      assignPlanForm,
      planOptions,
      NavigateToEdit,
      getPlanById,
      navigateToEditPage,
      capitalizeFirstLetter
    }
  },
}
</script>
