<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <back-button :route-to="{ name: 'rule-index' }" />
          <h1
            id="page-title"
            class="page-title"
          >
            {{ t("Rule Definition") }}
          </h1>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="mb-4 flex items-end justify-between space-x-3">
        <div class="w-full md:w-2/5">
          <div class="flex items-center space-x-3">
            <form-label
              for="rule_description"
            >
              {{ t("Rule Description") }} <required />
            </form-label>
            <form-input
              id="rule_description"
              v-model="description"
              class="flex-1"
              name="rule_description"
              type="text"
              :disabled="disableValue == true"
            />
          </div>
          <form-error
            v-if="errors.description"
            :message="errors.description"
            class="ml-36"
          />
        </div>

        <div>
          <ButtonSecondary
            v-if="list.length == 0 && ID != null"
            class="btn-default whitespace-nowrap"
            type="button"
            @click="initLoading"
          >
            {{ t("Load Existing Rule Data") }}
          </ButtonSecondary>

          <ButtonSecondary
            v-else
            class="btn-default whitespace-nowrap"
            type="button"
            :disabled="disableValue == true"
            @click="addNewRule"
          >
            {{ t("Insert Row") }}
          </ButtonSecondary>
        </div>
      </div>

      <div
        v-if="renderComponent"
        class="mb-2 px-2 py-1.5 w-full border border-primary-lighter bg-primary-50 flex items-center rounded"
      >
        <div class="mr-3 flex items-center">
          <button-circle
            class="invisible"
            type="info"
            icon="caret-up-down"
            :disabled="disableValue == true"
          />
        </div>
        <div class="flex flex-1 items-center">
          <div class="w-full text-xs font-semibold text-gray-500 text-center">
            <div class="w-full">
              {{ t("Function") }}
            </div>
          </div>
          <div class="w-full text-xs font-semibold text-gray-500 text-center">
            <div
              class="w-full border-l border-primary-lighter"
              style="margin-left: -1px;"
            >
              {{ t("Record") }}
            </div>
          </div>
          <div class="w-full text-xs font-semibold text-gray-500 text-center">
            <div
              class="w-full border-r border-l border-primary-lighter"
              style="margin-left: -1px;"
            >
              {{ t("Operator") }}
            </div>
          </div>
          <div class="w-full text-xs font-semibold text-gray-500 text-center">
            <div
              class="w-full border-r border-primary-lighter"
              style="margin-left: 1px;"
            >
              {{ t("Value") }}
            </div>
          </div>
          <div class="w-full text-xs font-semibold text-gray-500 text-center">
            <div class="w-full">
              {{ t("Next") }}
            </div>
          </div>
        </div>
        <div class="ml-3 flex items-center">
          <button-circle
            class="invisible"
            type="danger"
            icon="trash"
          />
        </div>
      </div>

      <div
        v-if="(!list.length && isLoadExistingRule) || (ID == null && !list.length)"
        class="my-4 px-3 py-10 border border-primary-lighter bg-primary-50 text-center text-sm rounded"
        :disabled="disableValue == true"
      >
        {{ t("No rule definitions added") }}.
      </div>
      <div
        v-if="!isLoadExistingRule && ID != null && !list.length"
        class="my-4 px-3 py-10 border border-primary-lighter bg-primary-50 text-center text-sm rounded"
        :disabled="disableValue == true"
      />

      <draggable
        v-if="renderComponent"
        v-model="list"
        transition="100"
      >
        <template #item="{ item }">
          <div
            v-if="item.id != null"
            class="mb-2 p-3 border border-primary-lighter w-full flex items-center bg-primary-50 cursor-move rounded"
          >
            <div class="mr-3 flex items-center">
              <button-circle
                class="cursor-move"
                type="info"
                icon="caret-up-down"
                :disabled="disableValue == true"
              />
            </div>
            <div class="flex flex-1 items-center space-x-3">
              <form-select
                id="status"
                v-model="item.function"
                :items="functionOptions"
                name="status"
                :disabled="disableValue == true"
              />
              <form-select
                id="status"
                v-model="item.record"
                :items="RecordOptions"
                name="status"
                :disabled="disableValue == true"
              />
              <form-select
                id="status"
                v-model="item.operator"
                :items="OperatorOptions"
                name="status"
                :disabled="disableValue == true"
              />
              <form-select
                v-if="item.function != 'FETCH'"
                id="status"
                v-model="item.value"
                :items="ValueOptions"
                name="status"
                :disabled="disableValue == true"
              />
              <form-input
                v-else
                id="status"
                v-model="item.value"
                name="status"
                type="text"
                :disabled="disableValue == true"
              />
              <form-select
                id="status"
                v-model="item.next"
                :items="NextOptions"
                name="status"
                :disabled="disableValue == true"
              />
            </div>
            <div class="ml-3 flex items-center">
              <button-circle
                type="danger"
                icon="trash"
                :disabled="disableValue == true"
                @click="deleteItem(item.id)"
              />
            </div>
          </div>
        </template>
      </draggable>

      <form-error
        v-if="errors.json"
        :message="errors.json"
      />

      <div class="my-4 flex justify-end space-x-2">
        <ButtonSecondary
          v-if="buttonDisableValue == true"
          class="btn-wide"
          type="button"
          :disabled="disableValue == true"
          @click="validateRule"
        >
          {{ t("Validate") }}
        </ButtonSecondary>
        <Button
          id="button_save-rules"
          class="btn-wide"
          type="button"
          :disabled="list.length == 0 || disableValue == true"
          @click="saveJson"
        >
          {{ t("Save") }}
        </Button>
      </div>

      <div
        v-if="list.length && seen"
      >
        >
        <code class="p-3 border border-gray-200 bg-gray-100 rounded">
          <pre class="text-xs">{{ list }}</pre>
        </code>
      </div>
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :is-array="modal.array"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/routes'
import Modal from '@/components/Modal.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Draggable from 'vue3-draggable'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import FormError from '@/components/FormError.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import Required from '@/components/Required.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import BackButton from '@/components/BackButton.vue'

export default {
  name: 'Transactions',

  components: {
    Button,
    Loading,
    FormSelect,
    PageHeader,
    Draggable,
    Modal,
    ButtonCircle,
    FormError,
    FormLabel,
    FormInput,
    Required,
    ButtonSecondary,
    BackButton
  },

  setup() {
    const { t } = useI18n()
    const store = useStore()
    const fullPage = ref(true)
    const seen = ref(false)
    const isLoading = ref(false)
    const isLoadExistingRule = ref(false)
    const disableValue = ref(false)
    const buttonDisableValue = ref(false)
    const renderComponent = ref(false)
    const sortColumnDirection = ref('desc')
    const successMessage = ref('')
    const functionOptions = ref(null)
    const OperatorOptions = ref(null)
    const ValueOptions = ref(null)
    const NextOptions = ref(null)
    const RecordOptions = ref(null)
    const description = ref(null)
    const rowVersion  = ref(1)
    const ID = ref(null)
    const submited = ref(null)
    const route = useRoute()
    const previewSlideOver = ref({
      show: false,
      size: 'max-w-4xl',
      close_button: true,
      close_button_text: 'Close',
      title: 'Transactions Summary',
    })
    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
      array :false,
    })

    const errors = reactive({
      description: null,
      json: null,
    })

    let list = ref([])


    if(route.params.mode == 'view'){
      disableValue.value = true
    }

    if(route.params.mode == 'edit'){
      buttonDisableValue.value = true
      disableValue.value = false
    }

    async function getIDvalue(){
      ID.value = route.params.slug
      if (typeof ID.value !== 'undefined') {
        await store.dispatch('TransactionRules/getRoute', ID.value)
        description.value = await store.state.TransactionRules.ruleInfo.description
        rowVersion.value = await store.state.TransactionRules.ruleInfo.row_version
      }


    }
   getIDvalue()
    async function initLoading() {
      renderComponent.value = false
      isLoadExistingRule.value = true
      ID.value = route.params.slug
      if (typeof ID.value !== 'undefined') {
        list.value = await store.state.TransactionRules.ruleInfo.json
      }
      nextTick(() => {
        renderComponent.value = true
      })

    }

    getRule()

    async function getRule() {
      await store.dispatch('TransactionRules/getRuleList')
        let obj = {}
          obj.value = ''
          obj.text = '-'
         let nextOptionvalue = []
         let functionOptionValue = []
         let operatorOptionValue = []
         let valueOptionValue = []
         let recordOptionValue = []
          nextOptionvalue.push(obj)
          functionOptionValue.push(obj)
          operatorOptionValue.push(obj)
          valueOptionValue.push(obj)
          recordOptionValue.push(obj)
           await store.state.TransactionRules.ruleListDropDown.find((rule) => {
            let obj = {}
            obj.value = rule.value
            obj.text = rule.text
            if(rule.header == 'next'){
            nextOptionvalue.push(obj)
            }
            if(rule.header == 'function'){
            functionOptionValue.push(obj)
            }
            if(rule.header == 'operator'){
            operatorOptionValue.push(obj)
            }
            if(rule.header == 'value'){
            valueOptionValue.push(obj)
            }
            if(rule.header == 'record'){
            recordOptionValue.push(obj)
            }
          })
         NextOptions.value = nextOptionvalue
         functionOptions.value = functionOptionValue
         OperatorOptions.value = operatorOptionValue
         ValueOptions.value  = valueOptionValue
         RecordOptions.value = recordOptionValue
    }

    const searchSlideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Search Transactions',
    })

    function addNewRule() {
      resetErrors()
      renderComponent.value = false
      buttonDisableValue.value = true

      let obj = {
        id: list.value.length + 1,
        function: null,
        record: null,
        operator: null,
        value: null,
        next: null,
      }
      list.value.push(obj)

      nextTick(() => {
        renderComponent.value = true
      })
    }

    function deleteItem(id) {
      let tempArray = []
      list.value.find((JsonINfo) => {
        if (JsonINfo.id == id) {
          JsonINfo.id = null
        }
      })

      isNotNullChecking()

      nextTick(() => {
        renderComponent.value = true
      })

    }

    async function isNotNullChecking(){
      buttonDisableValue.value = false
      list.value.find((JsonINfo) => {
        if (JsonINfo.id != null) {
          buttonDisableValue.value = true
        }
      })
    }
    async function saveJson() {
      resetErrors()
      successMessage.value = ''
      let newValue = []
      list.value.find((listval) => {
        if(listval.id != null){
        let obj = {}
        obj.id = listval.id
        obj.function = listval.function != '' ? listval.function : null
        obj.record = listval.record != '' ? listval.record : null
        obj.operator = listval.operator != '' ? listval.operator : null
        obj.value = listval.value != '' ? listval.value : null
        obj.next = listval.next != '' ? listval.next : null
        newValue.push(obj)
        }
      })

      const SubmitInfo = {
        json: newValue,
        description: description.value,
      }
      let response = []
      if(ID.value == null){
        await store.dispatch('TransactionRules/saveRule', SubmitInfo)
        successMessage.value = 'Rule definition created successfully.'
      }else {
        SubmitInfo.id = ID.value
        SubmitInfo.row_version = rowVersion.value
        await store.dispatch('TransactionRules/editRule', SubmitInfo)
        successMessage.value = 'Rule definition updated successfully.'
      }
      response = store.state.TransactionRules.ruleOutput
      if (response.status == true) {
        resetErrors()
        submited.value = true
        rowVersion.value = response.data.row_version
        ID.value = response.data.rule_id
        modal.type = 'success'
        modal.array = false
        modal.title = 'Success'
        modal.message = successMessage.value
        modal.show = true
      } else if (response.status == false) {
        modal.type = 'error'
        modal.title = 'Error'
        modal.array = false
        modal.message = 'Error'
        modal.show = true
      } else {
        isLoading.value = false
        bindValidationErrors(response.data.errors.validations)
      }
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    async function validateRule() {
      let newValue = []
      list.value.find((listval) => {
        if(listval.id != null){
        let obj = {}
        obj.id = listval.id
        obj.function = listval.function != '' ? listval.function : null
        obj.record = listval.record != '' ? listval.record : null
        obj.operator = listval.operator != '' ? listval.operator : null
        obj.value = listval.value != '' ? listval.value : null
        obj.next = listval.next != '' ? listval.next : null
        newValue.push(obj)
        }
      })

      const SubmitInfo = {
        json: newValue,
        description: description.value,
      }

      if(ID.value == null){
        await store.dispatch('TransactionRules/saveRule', SubmitInfo)
      }else {
        SubmitInfo.id = ID.value
        SubmitInfo.row_version = rowVersion.value
        await store.dispatch('TransactionRules/editRule', SubmitInfo)
      }
        let response = []
         response = store.state.TransactionRules.ruleOutput
      if (response.status == true) {
        resetErrors()
        rowVersion.value = response.data.row_version
        ID.value = response.data.rule_id
        await store.dispatch('TransactionRules/validateTransactionRule',ID.value)
        response = store.state.TransactionRules.ruleStat
       if (response.status == true) {
        modal.type = 'success'
        modal.title = 'Success'
        modal.message = 'Validate successfully.'
        modal.array = false
        modal.show = true
      } else if(response.msg == 'validation') {
    let errorMsg  = []
      response.data.find((data) => {
          let obj = {}
          obj.message = data.msg
          errorMsg.push(obj)
      })
        modal.type = 'error'
        modal.title = 'Error'
        modal.array = true
        modal.message = errorMsg
        modal.show = true
      } else {
        modal.type = 'error'
        modal.title = 'Error'
        modal.array = false
        modal.message = 'Invalid rule'
        modal.show = true
      }
      } else if (response.status == false) {
        modal.type = 'error'
        modal.title = 'Error'
        modal.message = 'Invalid rule'
        modal.show = true
        modal.array = false
      } else {
        isLoading.value = false
        bindValidationErrors(response.data.errors.validations)
      }



      }

    function resetErrors() {
      errors.description = null
      errors.json = null
    }

    function closeAlert() {
      modal.show = false
    }

    return {
      t,
      fullPage,
      isLoading,
      searchSlideOver,
      previewSlideOver,
      sortColumnDirection,
      list,
      deleteItem,
      functionOptions,
      OperatorOptions,
      ValueOptions,
      NextOptions,
      RecordOptions,
      renderComponent,
      addNewRule,
      saveJson,
      closeAlert,
      modal,
      description,
      errors,
      ID,
      initLoading,
      validateRule,
      submited,
      seen,
      disableValue,
      buttonDisableValue,
      isNotNullChecking,
      isLoadExistingRule,
      successMessage,
    }
  },
}
</script>
