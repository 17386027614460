<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <div class="grid md:grid-cols-2">
    <div class="mt-1 text-black font-medium">
      {{ t("Name") }}: {{ plan_name }}
    </div>
  </div>

  <h4 class="my-3 font-medium text-black text-opacity-75 text-sm">
    {{ t("opuspay") }} {{ t("Transaction Charges") }}
  </h4>

  <TableDefault>
    <template #table>
      <table
        class="table"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th
              class="th-action"
              scope="col"
            />
            <th scope="col">
              <div class="flex items-center justify-end">
                <span>{{ t("Quantity From") }}</span>
                <button
                  v-if="false"
                  class="btn_th-sort"
                  type="button"
                  @click="sortPlanTiersByPlanId('quantity_from')"
                >
                  <svg-icon
                    v-if="
                      tiersSortColumnDirection == 'desc' &&
                        tiersSortColumn == 'quantity_from'
                    "
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center justify-end">
                <span>{{ t("Quantity To") }}</span>
                <button
                  v-if="false"
                  class="btn_th-sort"
                  type="button"
                  @click="sortPlanTiersByPlanId('quantity_to')"
                >
                  <svg-icon
                    v-if="
                      tiersSortColumnDirection == 'desc' &&
                        tiersSortColumn == 'quantity_to'
                    "
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center justify-end">
                <span>{{ t("Invoice By") }}</span>
                <button
                  v-if="false"
                  class="btn_th-sort"
                  type="button"
                  @click="sortPlanTiersByPlanId('invoice_by')"
                >
                  <svg-icon
                    v-if="
                      tiersSortColumnDirection == 'desc' &&
                        tiersSortColumn == 'invoice_by'
                    "
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center justify-end">
                <span>{{ t("Rate") }}</span>
                <button
                  v-if="false"
                  class="btn_th-sort"
                  type="button"
                  @click="sortPlanTiersByPlanId('tier_rate')"
                >
                  <svg-icon
                    v-if="
                      tiersSortColumnDirection == 'desc' &&
                        tiersSortColumn == 'tier_rate'
                    "
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                </button>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(transactionCharge, index) in form.tiers"
            :key="index"
          >
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td class="text-right">
              {{ transactionCharge.quantity_from }}
            </td>
            <td class="text-right">
              {{ transactionCharge.quantity_to }}
            </td>
            <td class="text-right">
              {{ transactionCharge.invoice_by }}
            </td>
            <td class="text-right">
              {{ transactionCharge.tier_rate }}
            </td>
          </tr>

          <no-table-data v-if="!form.tiers" />
        </tbody>
      </table>
    </template>
  </TableDefault>

  <div>
    <div
      v-if="false"
      class="mt-3 flex items-center"
    >
      <form-checkbox
        id="invoice_account_directly"
        v-model="form.is_invoice_directly"
        name="invoice_account_directly"
        :model-value="form.is_invoice_directly == 1 ? 0 : 1"
        :checked="
          ($emit('sendStatus', form.is_invoice_directly),
           form.is_invoice_directly == 1 ? true : false)
        "
      />
      <label
        class="ml-2 text-sm"
        for="invoice_account_directly"
      >{{ t("Invoice Account Directly") }}</label>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import SvgIcon from '@/components/SvgIcon.vue'
import NoTableData from '@/components/NoTableData.vue'
import TableDefault from '@/components/TableDefault.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import Loading from 'vue3-loading-overlay'
import constant from "@/constant";
import CryptoJs from 'crypto-js'

export default {
  name: 'AccountPlan',

  components: {
    Loading,
    SvgIcon,
    NoTableData,
    TableDefault,
    FormCheckbox,
  },

  emits: ['sendStatus'],

  setup(props, { emit }) {
    const { t } = useI18n()
    const sortColumnDirection = ref('desc')
    const CryptoJsAesJson = constant.CryptoJsAesJson
    const user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data
      .user.user
    const plan_id = ref(0)
    const plan_name = ref('')
    const mch_account_id = ref('')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const tiersSortColumnDirection = ref('asc')
    const tiersSortColumn = ref('')
    const tiersSearchText = ref('')
    const merchantID = ref('')




    const form = reactive({
      id: null,
      name: null,
      country: null,
      currency: null,
      status: null,
      type: null,
      tier: {
        plan_tier_id: null,
        quantity_from: null,
        quantity_to: null,
        invoice_by: 'transaction',
        tier_rate: null,
      },
      assignedAccount: {
        merchant: null,
        merchant_name: null,
        address1: null,
        town: null,
        state: null,
        country: null,
      },
      tiers: [],
      assignedAccounts: [],
      row_version: null,
      errors: '',
      is_invoice_directly: 0,
    })

    setMerchantId(user)

    function setMerchantId(user){
      if (localStorage.getItem('merchant') != null){
        merchantID.value = localStorage.getItem('merchant')
      }
      else{
        merchantID.value = user.mch_id
      }
    }

    getPlanDetailsByMerchantId()


    async function getPlanDetailsByMerchantId() {
      isLoading.value = true
      await axios
        .get('v1/get-plan-by-merchant-id/' + merchantID.value)
        .then((response) => {
          if (response.data.data != null) {
            plan_id.value = response.data.data.merchant_plan.plan_id
            plan_name.value = response.data.data.merchant_plan.plan_name
            mch_account_id.value =
              response.data.data.merchant_account.mch_acc_id
            form.is_invoice_directly =
              response.data.data.merchant_account.is_invoice_directly
            getPlanTiersByPlanId()
          }
        })
      isLoading.value = false
    }

    async function getPlanTiersByPlanId(
      page = 1,
      column = tiersSortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        tiersSearchText.value = search
      }

      await axios
        .get('v1/plan-tiers/' + plan_id.value, {
          params: {
            search: '',
            page: page,
            sortColumn: column,
            sortDirection: tiersSortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data.data != null) {
            form.tiers = response.data.data.data
          }
        })
      isLoading.value = false
    }

    async function sortPlanTiersByPlanId(column) {
      tiersSortColumn.value = column
      tiersSortColumnDirection.value =
        tiersSortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getPlanTiersByPlanId(
        1,
        tiersSortColumn.value,
        tiersSearchText.value
      )
    }

    return {
      t,
      form,
      sortColumnDirection,
      plan_name,
      fullPage,
      isLoading,
      sortPlanTiersByPlanId,
      tiersSortColumnDirection,
      tiersSortColumn,
      tiersSearchText,
    }
  },
}
</script>
