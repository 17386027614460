<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2
              id="heading-reset_password"
              class="mb-3 text-2xl font-medium text-black"
            >
              Reset Password
            </h2>
            <h5 class="text-black text-opacity-70 font-medium">
              Add your username to reset password.
            </h5>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form class="space-y-5">
                <div>
                  <form-label
                    id="label-username"
                    for="email"
                  >
                    Username to reset password
                  </form-label>
                  <form-input
                    id="email"
                    v-model="form.username"
                    class="mt-1 border-primary-lighter"
                    name="email"
                    autofocus
                    type="text"
                  />
                  <form-error
                    v-if="form.errors.username"
                    :message="form.errors.username[0]"
                  />

                  <form-error
                    v-if="$inactiveUser == true"
                    :message="$inactiveUserMsg"
                  />
                </div>

                <Button
                  id="button-reset_password"
                  class="w-full"
                  type="submit"
                  @click.prevent="setResetLink"
                >
                  Send Password Reset Link
                </Button>

                <div class="flex items-center justify-end">
                  <router-link
                    id="link-signin"
                    class="
                      inline-block
                      font-medium
                      text-sm text-gray-400
                      leading-none
                      underline
                      hover:text-primary
                      focus:ring-2 focus:ring-offset-1 focus:ring-primary
                      focus:outline-none
                      rounded-sm
                    "
                    :to="{ name: 'signin' }"
                  >
                    Back to Login
                  </router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
  >
    <template #modal-actions>
      <Button
        id="button-close"
        class="btn-wide"
        type="button"
        @click="closeMessage()"
      >
        Ok
      </Button>
    </template>
  </modal>
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import axios from "axios";

export default {
  name: 'ForgotPassword',

  components: {
    Modal,
    Button,
    Loading,
    LogoWhite,
    FormError,
    FormInput,
    FormLabel,
    AuthFooter,
  },

  setup() {
    const router = useRouter()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const $inactiveUser = ref(false)
    const $inactiveUserMsg = ref('')
    const form = reactive({
      username: '',
      errors: '',
    })

    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: 'Password reset email was sent successfully.',
      close_button: false,
    })

    resetSession()

    function setResetLink() {
      $inactiveUser.value = false
      $inactiveUserMsg.value = ''
      form.errors = ''
      isLoading.value = true
      axios
        .post('/forgot-password?user_name=' + form.username, form)
        .then((response) => {
          isLoading.value = false
          if (response.data.status == true) {
            modal.show = true
          } else {
            if(response.data.data){
              form.errors = response.data.data.errors.validations
            }else{
              $inactiveUser.value = true
              $inactiveUserMsg.value = 'Inactive user.'
            }

          }
        })
    }

    async function resetSession() {
      if (localStorage.getItem('access_token')) {
        localStorage.removeItem('access_token')
      }
    }

    function closeMessage() {
      modal.show = false
      router.push({ name: 'signin' })
    }

    return {
      form,
      modal,
      router,
      fullPage,
      isLoading,
      setResetLink,
      closeMessage,
      $inactiveUser,
      $inactiveUserMsg,
      resetSession,
    }
  },
}
</script>
