<template>
  <div
    class="
      w-full sm:absolute sm:inset-x-0 sm:bottom-0 mt-4 sm:mt-0 p-2 sm:p-5 sm:flex flex-wrap items-center sm:bg-primary sm:bg-opacity-20 text-xs z-20
    "
  >
    <div class="sm:w-1/3" />
  
  
  
    <div class="text-center sm:w-1/3">
      <a
        id="link-copyright"
        class="
          text-white
          hover:underline
          focus:ring-2 focus:ring-offset-1 focus:ring-primary
          focus:outline-none
          font-medium
          rounded-sm
        "
        href="https://opusxenta.com/copyright"
        target="_blank"
        rel="noopener noreferrer"
      >Copyright &copy; {{ new Date().getFullYear() }} opuspay</a>
    </div>

    <div class="text-center sm:text-right sm:w-1/3 mt-0.5 sm:mt-0">
      <a
        id="link-security"
        class="
          text-white
          hover:underline
          focus:ring-2 focus:ring-offset-1 focus:ring-primary
          focus:outline-none
          font-medium
          rounded-sm
        "
        href="https://opusxenta.com/security"
        target="_blank"
        rel="noopener noreferrer"
      >Security</a>
      <span class="px-3 text-white">|</span>
      <a
        id="link-privacy"
        class="
          text-white
          hover:underline
          focus:ring-2 focus:ring-offset-1 focus:ring-primary
          focus:outline-none
          font-medium
          rounded-sm
        "
        href="https://opusxenta.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >Privacy</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthFooter',
}
</script>
