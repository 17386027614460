<template>
  <div class="relative">
    <svg-icon
      class="absolute inset-y-0 left-0 text-gray-400 mx-2 my-2 w-auto h-5"
      icon="search"
    />
    <form-input
      v-model="query"
      class="pl-8"
      type="text"
      :placeholder="t('Search')"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'
import FormInput from '@/components/FormInput.vue'
import useDebouncedRef from '@/components/ref/useDebouncedRef'
import { useI18n } from 'vue-i18n'
import { watch, watchEffect } from 'vue'
export default {
  name: 'Search',

  components: {
    SvgIcon,
    FormInput,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },

    searchText: {
      type: [String, Number],
      default: null,
    },

  },
  emits: ['dataFetchfunction'],
  setup(props, context, emit) {
    const { t, te, tm } = useI18n()
    const query = useDebouncedRef('', 400)
    watch(
      query,
      (newQuery) => {
        context.emit('dataFetchfunction', newQuery)
      },
      { immediate: true }
    )

    watchEffect(() => {
      if (props.searchText == '') {
        query.value = ''
      }
    })
    return {
      query,
      t,
    }
  },
}
</script>
