require('./bootstrap')
window.Vue = require('vue').default

import { createApp } from 'vue'
import AppComponent from './AppComponent.vue'
import router from './routes'
import store from './store'
import i18n from "@/i18n"

const app = createApp(AppComponent).use(router).use(store).use(i18n).mount('#app')
