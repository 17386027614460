<template>
  <input
    ref="input"
    title=""
    class="
      border border-gray-200
      appearance-none
      block
      w-full
      pt-2
      pr-3
      pb-2
      pl-3
      text-sm
      rounded
      shadow-none
      placeholder-gray-400
      focus:outline-none
      focus:ring-primary
      focus:border-primary
      disabled:bg-gray-50
      disabled:cursor-not-allowed
    "
    :autofocus="autofocus"
    :value="modelValue"
    :disabled="disable"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'FormInput',

  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },

    disable: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const input = ref(null)

    onMounted(() => {
      if (props.autofocus) {
        input.value.focus()
      }
    })

    return {
      input,
    }
  },
}
</script>
