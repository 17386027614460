<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div
          class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg"
        >
          <div>
            <h2
              id="heading-change_password"
              class="mb-3 text-2xl font-medium text-black"
            >
              Change Password
            </h2>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form
                method="POST"
                class="space-y-5"
              >
                <div>
                  <form-label
                    id="label-current_password"
                    for="current_password"
                  >
                    Current Password
                  </form-label>
                  <div class="mt-1 relative">
                    <form-input
                      id="current_password"
                      v-model="form.current_password"
                      class="pr-10 border-primary-lighter"
                      name="current_password"
                      :type="form.passwordFieldType"
                    />
                    <button
                      class="absolute w-7 h-7 inset-y-0 right-0 flex items-center justify-center my-auto mr-1 p-1 rounded-sm opacity-75 hover:opacity-100 focus:ring-2 focus:ring-offset-1 focus:ring-primary focus:outline-none z-10"
                      type="button"
                      @click="switchPasswordVisibility"
                    >
                      <svg-icon
                        v-if="form.passwordFieldType === 'text'"
                        class="w-5 h-5 text-gray-900"
                        icon="visible"
                      />
                      <svg-icon
                        v-if="form.passwordFieldType === 'password'"
                        class="w-5 h-5 text-gray-900"
                        icon="invisible"
                      />
                    </button>
                  </div>
                  <form-error
                    v-if="errors.validations"
                    :message="
                      errors.validations.current_password
                        ? errors.validations.current_password[0]
                        : ''
                    "
                  />
                </div>

                <div>
                  <form-label
                    id="label-new_password"
                    for="new_password"
                  >
                    New Password
                  </form-label>
                  <div class="mt-1 relative">
                    <form-input
                      id="new_password"
                      v-model="form.new_password"
                      class="pr-10 border-primary-lighter"
                      name="new_password"
                      :type="form.passwordFieldType"
                    />
                    <button
                      class="absolute w-7 h-7 inset-y-0 right-0 flex items-center justify-center my-auto mr-1 p-1 rounded-sm opacity-75 hover:opacity-100 focus:ring-2 focus:ring-offset-1 focus:ring-primary focus:outline-none z-10"
                      type="button"
                      @click="switchPasswordVisibility"
                    >
                      <svg-icon
                        v-if="form.passwordFieldType === 'text'"
                        class="w-5 h-5 text-gray-900"
                        icon="visible"
                      />
                      <svg-icon
                        v-if="form.passwordFieldType === 'password'"
                        class="w-5 h-5 text-gray-900"
                        icon="invisible"
                      />
                    </button>
                  </div>
                  <form-error
                    v-if="errors.validations"
                    :message="
                      errors.validations.new_password
                        ? errors.validations.new_password[0]
                        : ''
                    "
                  />
                </div>

                <div>
                  <form-label
                    id="label-confirm_password"
                    for="confirm_password"
                  >
                    Confirm Password
                  </form-label>
                  <div class="mt-1 relative">
                    <form-input
                      id="confirm_password"
                      v-model="form.password_confirmation"
                      class="pr-10 border-primary-lighter"
                      name="confirm_password"
                      :type="form.passwordFieldType"
                    />
                    <button
                      class="absolute w-7 h-7 inset-y-0 right-0 flex items-center justify-center my-auto mr-1 p-1 rounded-sm opacity-75 hover:opacity-100 focus:ring-2 focus:ring-offset-1 focus:ring-primary focus:outline-none z-10"
                      type="button"
                      @click="switchPasswordVisibility"
                    >
                      <svg-icon
                        v-if="form.passwordFieldType === 'text'"
                        class="w-5 h-5 text-gray-900"
                        icon="visible"
                      />
                      <svg-icon
                        v-if="form.passwordFieldType === 'password'"
                        class="w-5 h-5 text-gray-900"
                        icon="invisible"
                      />
                    </button>
                  </div>
                  <form-error
                    v-if="errors.validations"
                    :message="
                      errors.validations.password_confirmation
                        ? errors.validations.password_confirmation[0]
                        : ''
                    "
                  />
                </div>

                <div>
                  <Button
                    id="button-change_password"
                    class="w-full"
                    type="submit"
                    @click.prevent="changePassword"
                  >
                    Change Password
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'

export default {
  name: 'ChangePassword',

  components: {
    Modal,
    Button,
    SvgIcon,
    Loading,
    FormError,
    LogoWhite,
    FormLabel,
    FormInput,
    AuthFooter,
  },

  setup() {
    const form = reactive({
      current_password: '',
      new_password: '',
      password_confirmation: '',
      passwordFieldType: 'password',
    })
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })
    const errors = reactive({
      validations: '',
    })
    const isLoading = ref(false)
    const fullPage = ref(true)
    const router = useRouter()
    const route = useRoute()

    function changePassword() {
      isLoading.value = true
      axios
        .post('/password/change', form)
        .then((response) => {
          isLoading.value = false
          if (response.data.status) {
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Password changed successfully.'
            modal.close_button = true
            modal.show = true
            resetErrors()
          } else if (response.data.data.errors) {
            errors.validations = response.data.data.errors.validations
          } else {
            console.error('Error!')
          }
        })
        .catch((exception) => {
          isLoading.value = false
          console.log(exception)
        })
    }

    async function switchPasswordVisibility() {
      form.passwordFieldType =
        form.passwordFieldType === 'password' ? 'text' : 'password'
    }

    function closeAlert() {
      modal.show = false
      router.push({ name: 'signin' })
    }

    function resetErrors() {
      errors.validations = ''
    }

    return {
      form,
      modal,
      route,
      errors,
      fullPage,
      isLoading,
      closeAlert,
      changePassword,
      switchPasswordVisibility,
    }
  },
}
</script>
