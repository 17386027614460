import router from "../../../routes";

window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    gatewayListData: null,
    gatewayPostData: null,
    gatewayGetData: null,
  },

  mutations: {
    getGatewayListData(state, data) {
      if (data != null) {
        state.gatewayListData = data
      } else {
        state.gatewayListData = null
      }
    },
    postGatewayData(state, data) {
      if (data != null) {
        state.gatewayPostData = data
      } else {
        state.gatewayPostData = null
      }
    },
    getGatewayData(state, data) {
      if (data != null) {
        state.gatewayGetData = data
      } else {
        state.gatewayGetData = null
      }
    },
  },
  actions: {
    async getGatewayList({ commit, state }, searchData) {
      await axios
        .get(
          '/v1/gateway/list?page=' +
            searchData.page +
            '&sortColumn=' +
            searchData.column +
            '&sortDirection=' +
            searchData.sortColumnDirection +
            '&count_per_page=' +
            searchData.perPage +
            '&search=' +
            searchData.searchText
        )
        .then((response) => {
          if (response.data != null) {
            commit('getGatewayListData', response.data.data)
          } else {
            commit('getGatewayListData', null)
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    },

    async postGateway({ commit, state }, gatewayData) {
      await axios.post('/v1/gateway/create', gatewayData).then((response) => {
        commit('postGatewayData', response.data)
      })
    },

    async updateGateway({ commit, state }, gatewayData) {
      await axios
        .post('/v1/gateway/edit/' + gatewayData.id, gatewayData)
        .then((response) => {
          commit('postGatewayData', response.data)
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    },

    async viewGateway({ commit, state }, gatewayID) {
      await axios.get('/v1/gateway/view/' + gatewayID).then((response) => {
        if (response.data.status == true) {
          commit('getGatewayData', response.data)
        }
      })
    },
  },
}
