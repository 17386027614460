<template>
  <label class="block text-sm font-normal text-black opacity-70">
    <slot />
  </label>
</template>

<script>
export default {
  name: 'FormLabel',
}
</script>
