<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center">
        <back-button />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t(title + " Terms &amp; Conditions") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <form>
        <form-label
          class="inline-block mb-3"
          for="description"
        >
          {{ t("Description") }}<required />
        </form-label>

        <editor
          v-model="description"
          :api-key="tinymce_api_key"
          :init="editorOptions"
        />
        <form-error
          v-if="form.errors"
          :message="
            form.errors.validations.description
              ? form.errors.validations.description[0]
              : ''
          "
        />

        <form-error
          v-if="form.versionError"
          :message="form.versionError"
        />
        <div class="mt-8 flex items-center justify-between">
          <div class="flex items-center justify-between">
            <Switch
              v-model="enable"
              :class="enable ? 'enabled' : ''"
              class="switch"
            >
              <span class="sr-only">Publish terms &amp; conditions</span>
              <span class="switch-dot" />
            </Switch>

            <div>
              <div
                v-if="enable"
                class="ml-3 text-sm font-medium text-green-600"
              >
                {{ t("Published") }}
              </div>
              <div
                v-else
                class="ml-3 text-sm font-medium"
              >
                {{ t("Publish") }}
              </div>
            </div>
          </div>

          <Button
            id="btn-save"
            class="btn-wide"
            type="button"
            @click="saveTermsAndCondition()"
          >
            {{ t("Save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />

  <modal
    :type="modalWarning.type"
    :show="modalWarning.show"
    :title="modalWarning.title"
    :message="modalWarning.message"
    :close-button="modalWarning.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import tinymceConfig from '@/plugins/tinymce-config.js'
import Editor from '@tinymce/tinymce-vue'
import { Switch } from '@headlessui/vue'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Required from '@/components/Required.vue'
import BackButton from '@/components/BackButton'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import PageHeader from '@/components/PageHeader.vue'
import router from "../../routes";

export default {
  name: 'TermsAdd',

  components: {
    Modal,
    Switch,
    Button,
    Loading,
    Required,
    FormLabel,
    FormError,
    PageHeader,
    BackButton,
    editor: Editor,
  },

  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    const termsAndCondition = ref(0)
    const type = ref('')
    const url = ref('')
    const description = ref('')
    const enable = ref(false)
    const alertMsg = ref('')
    const isLoading = ref(false)
    const fullPage = ref(true)
    const title = ref('')
    const duplicateEntryError = ref('')
    const editorOptions = reactive(tinymceConfig.editorModeSettings)

    const form = reactive({
      errors: '',
      versionError: '',
      description: '',
      tc_id: '',
      status: ref('INA'),
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const modalWarning = reactive({
      show: false,
      size: 'max-w-3xl',
      title: '',
      confirm_button: true,
    })

    onMounted(() => {
      if (route.name === 'terms-edit') {
        getTermsAndConditionDetails()
        type.value = 'Update'
        title.value = 'Edit'
      } else {
        type.value = 'Create'
        title.value = 'Add'
      }
    })

    function saveTermsAndCondition() {
      isLoading.value = true
      form.description = description.value
      form.tc_id = null

      if (enable.value == true) {
        form.status = 'ACT'
      } else if (enable.value == false) {
        form.status = 'INA'
      }

      if (type.value == 'Create') {
        url.value = '/v1/new-terms-and-condition'
        alertMsg.value = 'Terms and conditions created successfully.'
      } else if (type.value == 'Update') {
        const tc_id = route.params.tc_id
        form.tc_id = tc_id
        url.value = '/v1/edit-terms-and-condition/' + tc_id
        alertMsg.value = 'Terms and conditions updated successfully.'
      }

      axios.post(url.value, form).then((response) => {
        isLoading.value = false

        if (
          response.data != null &&
          !response.data.data.errors &&
          response.data.msg != 'Can not proceed with the same version.'
        ) {
          form.errors = ''
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = alertMsg.value
          modal.show = true
        } else {
          isLoading.value = false
          form.errors = response.data.data.errors
          form.versionError = response.data.msg
        }
        if(form.errors != null && form.errors != '')
        {
          if(form.errors.validations.tc_id && form.errors.validations.tc_id[0]){
            router.push({ name: 'unauthorized-access' })
          }
        }
      })
    }

    function getTermsAndConditionDetails() {
      const tc_id = route.params.tc_id
      axios.get('/v1/terms-and-condition/' + tc_id).then((response) => {
        if (response.data != null) {
          termsAndCondition.value = response.data.data
          description.value = termsAndCondition.value.description
          if (termsAndCondition.value.status == 'ACT') {
            enable.value = true
          }
        } else {
          console.error('Error!')
        }
      })
    }

    function closeAlert() {
      modal.show = false
      router.push('/settings/terms')
    }

    return {
      form,
      modal,
      status,
      enable,
      alertMsg,
      description,
      editorOptions,
      fullPage,
      isLoading,
      title,
      tinymce_api_key: process.env.MIX_TINYMCE_API_KEY,
      getTermsAndConditionDetails,
      saveTermsAndCondition,
      closeAlert,
      duplicateEntryError,
      modalWarning,
      t,
    }
  },
}
</script>
