<template>
  <select
    ref="input"
    title=""
    class="
      border border-gray-200
      appearance-none
      block
      w-full
      pt-2
      pr-8
      pb-2
      pl-3
      text-sm
      rounded
      shadow-none
      placeholder-gray-400
      focus:outline-none
      focus:ring-primary
      focus:border-primary
      disabled:bg-gray-50
      disabled:cursor-not-allowed
    "
    :value="modelValue"
    :disabled="disable"
    :autofocus="autofocus"
    @change="changeCountPerPage($event.target.value)"
    @input="$emit('update:modelValue', $event.target.value)"
  >
    <option
      v-for="(item, index) in items"
      :key="index"
      :value="item.value ?? item"
      :disabled="length == 2 && item.value == ''"
    >
      {{ item.text ?? item }}
    </option>
  </select>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'FormSelect',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    disable: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: null,
    },

    change: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: [String, Number],
      default: null,
    },

    onOnChangeFunction: {
      type: Function,
      default: null,
    },
    length: {
      type: Number,
      default: null,
    },
  },

  emits: ['update:modelValue', 'onChangeFunction'],

  setup(props, { emit }) {
    const input = ref(null)
    const changeCountPerPage = (e) => {
      emit('onChangeFunction')
    }

    onMounted(() => {
      if (props.autofocus) {
        input.value.focus()
      }
    })

    return {
      input,
      changeCountPerPage,
    }
  },
}
</script>
