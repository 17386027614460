<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center">
          <back-button />
          <h1
            v-if="slu === 'registration_rejection'"
            class="page-title"
          >
            {{ t("Field Options") }}: {{ t("Registration Rejection") }}
          </h1>
          <h1
            v-if="slu === 'payment_methods'"
            class="page-title"
          >
            {{ t("Field Options") }}: {{ t("Payment Methods") }}
          </h1>
          <h1
            v-if="slu === 'payment_device_type'"
            class="page-title"
          >
            {{ t("Field Options") }}: {{ t("Gateway Device Capability") }}
          </h1>
        </div>

        <Button
          v-if="true"
          id="button_add-row"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          :disabled="showAddNewRow == true || selectedRow !== false"
          @click="resetAddForm(),credit_or_debit = 0, disableDropdownOption = true, showAddNewRowButton()"
        >
          {{ t("Add Row") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div
        v-if="false"
        class="md:flex gap-x-3"
      >
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search />
          </div>
        </div>
      </div>

      <form-error
        v-if="editForm.errors"
        :message="
          editForm.errors.validations.dropdownOption
            ? editForm.errors.validations.dropdownOption[0]
            : ''
        "
      />

      <TableDefault>
        <template #table>
          <table
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Options") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('description')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'description'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  v-if="slu === 'payment_methods'"
                  scope="col"
                >
                  <div class="flex items-center justify-center">
                    <span>{{ t("Credit/Debit") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('is_card_pmt')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'is_card_pmt'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  v-if="slu === 'payment_methods'"
                  scope="col"
                >
                  <div class="flex items-center justify-center">
                    <span>{{ t("Category") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('pm_cat')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' &&
                            sortColumn == 'pm_cat'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                  style="min-width:160px;"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-if="showAddNewRow"
                class="td-align-top"
              >
                <td>
                  <div class="td-cell">
                    <form-input
                      id="option"
                      v-model="option"
                      class="py-1.5"
                      name="option"
                      type="text"
                      required
                    />
                  </div>
                  <form-error
                    v-if="addForm.errors"
                    :message="
                      addForm.errors.validations.dropdownOption
                        ? addForm.errors.validations.dropdownOption[0]
                        : ''
                    "
                  />
                  <form-error
                    v-if="errorMsgForOptionRequired != null"
                    :message="errorMsgForOptionRequired"
                  />
                </td>
                <td v-if="slu === 'payment_methods'">
                  <div class="td-cell flex justify-center">
                    <form-checkbox
                      v-if="slu === 'payment_methods'"
                      :id="'addCreditDebitLabel' + index"
                      v-model="credit_or_debit"
                      :model-value="credit_or_debit == 1 ? 0 : 1"
                      :disabled="false"
                      name="blocked"
                      type="checkbox"
                      :checked="true"
                      @click="checkedFunction(credit_or_debit)"
                    />
                  </div>
                </td>
                <td v-if="slu === 'payment_methods'">
                  <div class="td-cell flex justify-center">
                    <form-select
                      v-if="slu === 'payment_methods'"
                      id="assign-pay-cat2"
                      v-model="addForm.pm_cat"
                      class="mt-1"
                      :disabled="disableDropdownOption == true"
                      name="assign-tax-group"
                      :items="paymentCategoryOptionsInAdd"
                    />
                  </div>
                  <form-error
                    v-if="errorMsgForCardNotPresent != null"
                    :message="errorMsgForCardNotPresent"
                  />
                  <form-error
                    v-if="errorMsgForAddingSubMethod != null"
                    :message="errorMsgForAddingSubMethod"
                  />
                </td>
                <td>
                  <div class="td-cell flex justify-center">
                    <div class="flex items-center justify-center">
                      <button-circle
                        type="danger"
                        icon="trash"
                        @click="showAddNewRow = false"
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <tr
                v-for="(dropdownOption, index) in dropdownOptions"
                :key="index"
                :class="(selectedRow === index) ? 'td-align-top' : ''"
              >
                <td
                  v-if="dropdownOption.pm_cat != 'CARD_NOT_PRESENT'"
                  style="max-width: 1100px"
                >
                  <span
                    v-if="index !== selectedRow"
                    :id="'descriptionEditInput' + index"
                    class="td-cell"
                  >{{ dropdownOption.description }}</span>
                  <div v-if="index === selectedRow">
                    <form-input
                      :id="'descriptionEditInput' + index"
                      v-model="editForm.dropdownOption"
                      class="py-1.5"
                      name="description1"
                      type="text"
                      required
                      :disabled="false"
                    />
                    <form-error
                      v-if="addForm.errors"
                      :message="
                        addForm.errors.validations.dropdownOption
                          ? addForm.errors.validations.dropdownOption[0]
                          : ''
                      "
                    />
                    <form-error
                      v-if="errorMsgForOptionRequired != null"
                      :message="errorMsgForOptionRequired"
                    />
                  </div>
                </td>
                <td
                  v-if="slu === 'payment_methods' && dropdownOption.pm_cat != 'CARD_NOT_PRESENT'"
                  class="text-center"
                >
                  <div class="td-cell flex justify-center">
                    <form-checkbox
                      v-if="slu === 'payment_methods' && index !== selectedRow"
                      :id="'isBlockedLabel' + index"
                      :disabled="true"
                      name="blocked"
                      type="checkbox"
                      :checked="
                        dropdownOption.is_card_pmt == 1 ? true : false
                      "
                    />
                    <form-checkbox
                      v-if="slu === 'payment_methods' && index === selectedRow"
                      :id="'isBlockedLabel' + index"
                      v-model="credit_or_debit"
                      :disabled="index !== selectedRow"
                      name="blocked"
                      type="checkbox"
                      :model-value="credit_or_debit == 1 ? 0 : 1"
                      :checked="
                        dropdownOption.is_card_pmt == 1 ? true : false
                      "
                      @click="checkedFunctionInEditMode(credit_or_debit, dropdownOption)"
                    />
                  </div>
                </td>
                <td v-if="slu === 'payment_methods' && dropdownOption.pm_cat != 'CARD_NOT_PRESENT'">
                  <div class="td-cell flex justify-center">
                    <form-select
                      v-if="slu === 'payment_methods' && index !== selectedRow"
                      id="assign-pay-cat5"
                      v-model="dropdownOption.pm_cat"
                      class="mt-1"
                      name="assign-tax-group"
                      :items="paymentCategoryOptions"
                      :disabled="true"
                    />
                    <form-select
                      v-if="slu === 'payment_methods' && index === selectedRow"
                      id="assign-pay-cat1"
                      v-model="addForm.pm_cat"
                      class="mt-1"
                      name="assign-tax-group"
                      :items="paymentCategoryOptionsInEdit"
                      :disabled="disableDropdownValueInEditMode == true"
                    />
                  </div>
                  <form-error
                    v-if="slu === 'payment_methods' && index === selectedRow && errorMsgForCardNotPresent != null"
                    :message="errorMsgForCardNotPresent"
                  />
                  <form-error
                    v-if="slu === 'payment_methods' && index === selectedRow && errorMsgForAddingSubMethod != null"
                    :message="errorMsgForAddingSubMethod"
                  />
                </td>
                <td v-if="dropdownOption.pm_cat != 'CARD_NOT_PRESENT'">
                  <div
                    :class="(selectedRow == index) ? 'td-align-top' : ''"
                  >
                    <div class="td-cell flex items-center justify-center space-x-5">
                      <button-circle
                        v-if="slu === 'payment_methods'"
                        :id="'payment_method' + index"
                        type="info"
                        icon="eye"
                        @click="showUid(dropdownOption)"
                      />
                      <button-circle
                        v-if="
                          (slu === 'payment_methods' && index !== selectedRow) && (dropdownOption.pm_cat != 'CARD_NOT_PRESENT')
                        "
                        :id="'editCountryAndCurrencyButton' + index"
                        type="info"
                        icon="edit"
                        :disabled="showAddNewRow == true || selectedRow !== false"
                        @click="
                          selectRow(
                            index,
                            dropdownOption
                          )
                        "
                      />

                      <tippy
                        v-else
                        placement="left"
                      >
                        <button-circle
                          v-if="
                            slu === 'payment_methods' && index !== selectedRow
                          "
                          :id="'editCountryAndCurrencyButton' + index"
                          type="info"
                          icon="edit"
                          :disabled="true"
                          @click="
                            selectRow(
                              index,
                              dropdownOption
                            )
                          "
                        />
                        <template #content>
                          <span>{{ t("'Online Payment' cannot be updated") }}.</span>
                        </template>
                      </tippy>

                      <button-circle
                        v-if="slu === 'payment_methods' && index === selectedRow"
                        :id="'updateDropDownOptionButton' + index"
                        type="success"
                        icon="check"
                        style="margin:0px"
                        @click="
                          updateDropDownOption(
                            dropdownOption
                          )
                        "
                      />
                      <button-circle
                        v-if="slu === 'registration_rejection'"
                        type="danger"
                        icon="trash"
                        :disabled="selectedRow !== false || showAddNewRow == true"
                        @click="
                          deleteDropdownOption(dropdownOption.mch_reg_rej_type_id)
                        "
                      />
                      <button-circle
                        v-if="slu === 'payment_device_type'"
                        type="danger"
                        icon="trash"
                        :disabled="selectedRow !== false || showAddNewRow == true"
                        @click="
                          deleteDropdownOption(dropdownOption.tnt_pd_type_id)
                        "
                      />
                      <div v-if="dropdownOption.pm_cat != 'CARD_NOT_PRESENT'">
                        <button-circle
                          v-if="slu === 'payment_methods' && (dropdownOption.gateway_payment_methods_count == 0) "
                          type="danger"
                          icon="trash"
                          :disabled="selectedRow !== false || showAddNewRow == true"
                          @click="
                            deleteDropdownOption(dropdownOption.tnt_pm_id, dropdownOption)
                          "
                        />
                        <tippy
                          v-else
                          placement="left"
                        >
                          <button-circle
                            v-if="slu === 'payment_methods'"
                            type="danger"
                            icon="trash"
                            :disabled="true"
                            @click="
                              deleteDropdownOption(dropdownOption.tnt_pm_id)
                            "
                          />
                          <template #content>
                            <span>{{ t("You won't be able to delete this payment method, as it is attached to a gateway") }}.</span>
                          </template>
                        </tippy>
                      </div>
                      <div v-else>
                        <tippy
                          placement="left"
                        >
                          <button-circle
                            v-if="slu === 'payment_methods'"
                            type="danger"
                            icon="trash"
                            :disabled="true"
                          />
                          <template #content>
                            <span>{{ t("'Online Payment' cannot be deleted") }}.</span>
                          </template>
                        </tippy>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!dropdownOptions.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <div class="flex items-center justify-end">
        <Button
          v-if="true"
          :disabled="showAddNewRow == false"
          class="btn-wide"
          type="submit"
          @click="addDropdownOption()"
        >
          {{ t("Save") }}
        </Button>
      </div>
    </div>
  </div>

  <modal-form
    :show="modalCopy.show"
    :close-button="modalCopy.close_button"
    :title="modalCopy.title"
    :confirm-button="modalCopy.confirm_button"
    @closeModal="closePmAlert"
    @confirmModal="confirmAlert"
  >
    <template #modal-body>
      <div class="space-y-4">
        <table class="table border border-slate-500 mt-4 mb-2">
          <tr>
            <th class="p-2 w-32">
              {{ t('Options') }}
            </th>
            <td class="p-2">
              {{ modalCopy.option }}
            </td>
          </tr>
          <tr>
            <th class="p-2 w-32">
              {{ t('Credit/Debit') }}
            </th>
            <td class="p-2">
              <form-checkbox
                :disabled="true"
                name="uuidblocked"
                type="checkbox"
                :checked="modalCopy.credit_debit"
              />
            </td>
          </tr>
          <tr>
            <th class="p-2 w-32">
              {{ t('Category') }}
            </th>
            <td class="p-2">
              {{ modalCopy.category }}
            </td>
          </tr>
        </table>


        <div class="relative mt-4">
          <div
            class="form-control w-full rounded-sm border-solid border-1 bg-gray-100 border-gray-200 p-2 border-gray-200 border-2"
          >
            {{ pmUid }}
          </div>
          <button
            class="h-full rounded-r-sm absolute right-0 bg-gray-200  w-12  border-gray-200 border-l-2 flex  justify-center top-0 justify-items-center hover:bg-gray-300"
            @click="copyUid()"
          >
            <svg
              className="w-5  text-primary-light"
              class="h-full  w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            ><path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="{2}"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            /></svg>
          </button>
          <span
            id="uuidCopyFade"
            class="bg-emerald-800 font-semibold absolute left-0 -bottom-7 hidden"
          >UUID Copied</span>
        </div>
      </div>
    </template>
  </modal-form>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />

  <modal
    :type="warningModel.type"
    :show="warningModel.show"
    :title="warningModel.title"
    :message="warningModel.message"
    :close-button="warningModel.close_button"
    :confirm-button="warningModel.confirm_button"
    :button-text="warningModel.button_text"
    @closeModal="closeWarningAlert"
  />
</template>



<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { ref, onMounted, reactive } from 'vue'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import BackButton from '@/components/BackButton.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'
import router from "../../routes";
import {Tippy} from "vue-tippy";
import FormCheckbox from "../../components/FormCheckbox";
import ModalForm from "@/components/ModalForm";
//import ButtonSecondary from "@/components/ButtonSecondary";
import {copyText} from "vue3-clipboard";

export default {
  name: 'DropdownFieldEdit',

  components: {
    Modal,
    Search,
    Button,
    SvgIcon,
    Loading,
    FormInput,
    FormError,
    FormSelect,
    PageHeader,
    BackButton,
    NoTableData,
    ButtonCircle,
    TableDefault,
    Tippy,
    FormCheckbox,
    ModalForm,
   // ButtonSecondary,

  },

  setup() {
    const { t } = useI18n()
    const route = useRoute()
    const showAddNewRow = ref(false)
    const updateOptionButton = ref(false)
    const sortColumnDirection = ref('desc')
    const dropdownOptions = ref(0)
    const slu = ref('')
    const field_id = ref('')
    const option = ref('')
    const credit_or_debit = ref(0)
    const alertMsg = ref('')
    const router = useRouter()
    const IdToDelete = ref(false)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const sortColumn = ref('')
    const selectedRow = ref(false)
    const disableDropdownOption = ref(true)
    const disableDropdownValueInEditMode = ref(false)
    const errorMsgForCardNotPresent = ref(null)
    const deleteOptionValue = ref(false)
    const errorMsgForOptionRequired = ref(null)
    const errorMsgForAddingSubMethod = ref(null)
    const cardNotPresent = ref(false)
    const isCardNotPresentValue = ref(false)
    const pmUid = ref('')

    const paymentCategoryOptions = ref([{ value: 'CARD_PRESENT', text: 'Card Present' },
      {value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
      {value: 'CNP_CARD', text: 'Online Card'},
      {value: 'CNP_ACH', text: ' ACH'},
    ])

    const paymentCategoryOptionsInAdd = ref([{ value: 'CARD_PRESENT', text: 'Card Present'},
      {value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
      {value: 'CNP_CARD', text: 'Online Card'},
      {value: 'CNP_ACH', text: ' ACH'},
    ])

    const paymentCategoryOptionsInEdit = ref([{value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
      {value: 'CNP_CARD', text: 'Online Card'},
      {value: 'CNP_ACH', text: ' ACH'},
    ])


    const deleteform = reactive({
      dropdownSlug: '',
    })
    const addForm = reactive({
      errors: '',
      dropdownOption: '',
      dropdownId: '',
      credit_or_debit: 0,
      pm_cat: '',
    })
    const editForm = reactive({
      dropdownOption: '',
      dropdownId: '',
      credit_or_debit: 0,
      pm_cat: '',
      errors: '',
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button: false,
      button_text: 'Ok',
    })

    const warningModel = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const modalCopy = reactive({
      show: false,
      title: 'Payment method UUID',
      close_button: false,
      confirm_button: false,
      option: null,
      credit_debit: true,
      category: null,
    })

    onMounted(() => {
      getAllOptionsPerDropdownField()
    })

    function getAllOptionsPerDropdownField(
      page = 1,
      column = sortColumn.value
    ) {
      isLoading.value = true
      const slug = route.params.slug
      slu.value = slug
      cardNotPresent.value = false
      axios
        .get(
          '/v1/get-all-dropdown-option/' +
            slug +
            '?sortColumn=' +
            column +
            '&sortDirection=' +
            sortColumnDirection.value
        )
        .then((response) => {
          if (response.data != null) {
            field_id.value = response.data.data.field_id
            dropdownOptions.value = response.data.data.options.data
            dropdownOptions.value.find((data) => {
              if(data.pm_cat == 'CARD_NOT_PRESENT')
              {
                cardNotPresent.value = true
              }
            });
            isLoading.value = false
          } else {
            console.log('Error !')
          }
        })
    }

    function deleteDropdownOption(id, data) {
      deleteOptionValue.value = false
      deleteform.dropdownSlug = route.params.slug
      IdToDelete.value = id

      if(deleteform.dropdownSlug == 'payment_methods'){
        dropdownOptions.value.find((data) => {
          if((data.pm_cat == 'CNP_CARD_AND_ACH') ||
            (data.pm_cat == 'CNP_CARD') ||
            (data.pm_cat == 'CNP_ACH') ||
            (data.pm_cat == 'CNP_BPAY'))
          {
            deleteOptionValue.value = true
          }
        });

        if(data.pm_cat == 'CARD_NOT_PRESENT' && deleteOptionValue.value == true){
          warningModel.type = 'warning'
          warningModel.title = 'Warning'
          warningModel.message =
            'There are sub payment methods linked to the category: Card Not Present . Please delete those and try again.'
          warningModel.show = true
        }else{
          modal.type = 'warning'
          modal.title = 'Warning'
          modal.confirm_button = true
          modal.button_text = 'No'
          modal.message =
            'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
          modal.show = true
        }
      }else{
        modal.type = 'warning'
        modal.title = 'Warning'
        modal.confirm_button = true
        modal.button_text = 'No'
        modal.message =
          'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
        modal.show = true
      }


    }

    function addDropdownOption() {
      errorMsgForCardNotPresent.value = null
      errorMsgForOptionRequired.value = null
      errorMsgForAddingSubMethod.value = null
      addForm.dropdownOption = option.value
      if(credit_or_debit.value == 1){
        addForm.credit_or_debit = 0
      }else if(credit_or_debit.value == 0){
        addForm.credit_or_debit = 1
      }
      isLoading.value = true

      if(addForm.dropdownOption == '' || addForm.dropdownOption == null){
        errorMsgForOptionRequired.value = 'Please enter an option value'
      }

      if((cardNotPresent.value == false) &&
        ((addForm.pm_cat == 'CNP_CARD_AND_ACH') ||
        (addForm.pm_cat == 'CNP_CARD') ||
        (addForm.pm_cat == 'CNP_ACH') ||
        (addForm.pm_cat == 'CNP_BPAY')))
      {
        errorMsgForAddingSubMethod.value = 'Please add Card Not Present payment method first.'
      }

      if((credit_or_debit.value == 1 && addForm.pm_cat == 'CARD_PRESENT') || credit_or_debit.value == 1 && addForm.pm_cat == ''){
        errorMsgForCardNotPresent.value = 'Please select a payment category.'
      }

      if(errorMsgForCardNotPresent.value == null && errorMsgForOptionRequired.value == null && errorMsgForAddingSubMethod.value == null){
        axios
          .post('v1/add-dropdown-option/' + field_id.value, addForm)
          .then((response) => {
            if (response.data != null && !response.data.data.errors) {
              modal.confirm_button = false
              modal.button_text = 'Ok'
              modal.type = 'success'
              modal.title = 'Success'
              modal.message = 'Option created successfully.'
              modal.show = true
              addForm.errors = null
            } else {
              showAddNewRow.value = true
              addForm.errors = response.data.data.errors
            }
            isLoading.value = false
          })
      }else{
        isLoading.value = false
      }
    }

    function sort(column, direction) {
      sortColumn.value = column
      const slug = route.params.slug

      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getAllOptionsPerDropdownField(1, sortColumn.value)
    }

    function closeAlert() {
      modal.show = false
      if (modal.button_text !== 'No') {
        router.push('/settings/dropdown-fields/' + slu.value)
      }
    }

    function closeWarningAlert() {
      warningModel.show = false
    }

    function confirmAlert() {
      deleteOption()
      modal.show = false
      return true
    }

    function deleteOption() {
      axios
        .post('/v1/delete-dropdown-option/' + IdToDelete.value, deleteform)
        .then((response) => {
          if (response.data != null) {
            getAllOptionsPerDropdownField()
          }
          router.push('/settings/dropdown-fields/' + slu.value)
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function ShowUpdateButton() {
      updateOptionButton.value = true
    }

    function showAddNewRowButton() {
      if (showAddNewRow.value == false) {
        addForm.errors = ''
        option.value = ''
        showAddNewRow.value = true
        let objOption = {}
        objOption.text = 'Card Present'
        objOption.value = 'CARD_PRESENT'
        paymentCategoryOptionsInAdd.value.push(objOption)
        addForm.pm_cat = objOption.value
      } else {
        showAddNewRow.value = false
      }
    }

    async function selectRow(
      index,
      dropdownOption
    ) {
      selectedRow.value = index
      editForm.dropdownOption = dropdownOption.description
      credit_or_debit.value= dropdownOption.is_card_pmt
      disableDropdownValueInEditMode.value = false
      paymentCategoryOptionsInEdit.value = []
      errorMsgForCardNotPresent.value = null
      errorMsgForOptionRequired.value = null
      errorMsgForAddingSubMethod.value = null

      if(dropdownOption.pm_cat == 'CARD_PRESENT'){
        disableDropdownValueInEditMode.value = true
        let objOption = {}
          objOption.text = 'Card Present'
          objOption.value = 'CARD_PRESENT'
          paymentCategoryOptionsInEdit.value.push(objOption)
          addForm.pm_cat = objOption.value
      }else{
        addForm.pm_cat = dropdownOption.pm_cat
        if(addForm.pm_cat == 'CARD_NOT_PRESENT'){
          paymentCategoryOptionsInEdit.value = [{value: '', text: 'Select a payment category'},
            {value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
            {value: 'CNP_CARD', text: 'Online Card'},
            {value: 'CNP_ACH', text: ' ACH'},
          ]
        }else{
          paymentCategoryOptionsInEdit.value = [{value: '', text: 'Select a payment category'},
            {value: 'CNP_CARD', text: 'Online Card'},
            {value: 'CNP_ACH', text: ' ACH'},
          ]
        }
      }
    }

    async function updateDropDownOption( dropdownOption) {
      errorMsgForCardNotPresent.value = null
      errorMsgForOptionRequired.value = null
      errorMsgForAddingSubMethod.value = null
      addForm.dropdownOption = editForm.dropdownOption
      addForm.credit_or_debit = await credit_or_debit.value
      addForm.dropdownId = dropdownOption.tnt_pm_id
      addForm.errors = null
      isLoading.value = true


      if(addForm.dropdownOption == '' || addForm.dropdownOption == null){
        errorMsgForOptionRequired.value = 'Please enter an option value'
      }

      if((addForm.credit_or_debit == 0 && addForm.pm_cat == 'CARD_PRESENT') || (addForm.credit_or_debit == 0 && addForm.pm_cat == '')){
        errorMsgForCardNotPresent.value = 'Please select a payment category.'
      }else {
        if(dropdownOption.pm_cat != 'CARD_NOT_PRESENT'){
          dropdownOptions.value.find((value) => {
            if(value.pm_cat == 'CARD_NOT_PRESENT' && addForm.pm_cat == 'CARD_NOT_PRESENT'){
              errorMsgForCardNotPresent.value = 'Only one payment method of Card Not Present is allowed.'
            }
          })

          dropdownOptions.value.find((value) => {
            if(value.pm_cat == 'CARD_NOT_PRESENT'){
              isCardNotPresentValue.value = true
            }
          })

          if((isCardNotPresentValue.value == false) && ((addForm.pm_cat == 'CNP_CARD_AND_ACH') ||
            (addForm.pm_cat == 'CNP_CARD') ||
            (addForm.pm_cat == 'CNP_ACH') ||
            (addForm.pm_cat == 'CNP_BPAY')))
          {
            errorMsgForAddingSubMethod.value = 'Please add Card Not Present payment method first.'
          }
        }else if(dropdownOption.pm_cat == 'CARD_NOT_PRESENT' && (addForm.pm_cat != 'CARD_NOT_PRESENT'))
        {
          dropdownOptions.value.find((value) => {
            if((value.pm_cat == 'CNP_CARD_AND_ACH') ||
              (value.pm_cat == 'CNP_CARD') ||
              (value.pm_cat == 'CNP_ACH') ||
              (value.pm_cat == 'CNP_BPAY')){
              errorMsgForCardNotPresent.value = 'You are not allow to edit the category as there are child levels linked it this record.'
            }
          })

          if(errorMsgForCardNotPresent.value == null){
            if(dropdownOption.pm_cat == 'CARD_NOT_PRESENT' &&
              (addForm.pm_cat == 'CNP_CARD_AND_ACH') ||
              (addForm.pm_cat == 'CNP_CARD') ||
              (addForm.pm_cat == 'CNP_ACH') ||
              (addForm.pm_cat == 'CNP_BPAY'))
            {
              errorMsgForAddingSubMethod.value = 'Please add Card Not Present payment method first.'
            }
          }
        }
      }

      if(errorMsgForCardNotPresent.value == null && errorMsgForOptionRequired.value == null && errorMsgForAddingSubMethod.value == null){
        await axios
          .post(
            'v1/edit-dropdown-option/' +
            field_id.value,
            addForm
          )
          .then((response) => {
            if (response.data != null && !response.data.data.errors) {
              modal.confirm_button = false
              modal.button_text = 'Ok'
              modal.type = 'success'
              modal.title = 'Success'
              modal.message = 'Option updated successfully.'
              modal.show = true
              addForm.errors = null
              credit_or_debit.value = 0
              getAllOptionsPerDropdownField()
            } else {
              addForm.errors = response.data.data.errors
            }
            isLoading.value = false
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
      }else{
        isLoading.value = false
      }

    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    const errors = reactive({
      country_code: null,
      currency_code: null,
      country_name: null,
      currency_name: null,
      is_blocked: null,
    })

    function checkedFunction(value){
      disableDropdownOption.value = false
      paymentCategoryOptionsInAdd.value = []

      if(value == 1){
        disableDropdownOption.value = true
        let objOption = {}
        objOption.text = 'Card Present'
        objOption.value = 'CARD_PRESENT'
        paymentCategoryOptionsInAdd.value.push(objOption)
        addForm.pm_cat = objOption.value
        errorMsgForCardNotPresent.value = null
      }else{
        disableDropdownOption.value = false
        paymentCategoryOptionsInAdd.value = [{value: '', text: 'Select a payment category'},
          {value: 'CNP_CARD', text: 'Online Card'},
          {value: 'CNP_ACH', text: ' ACH'},
        ]
      }
    }

    function checkedFunctionInEditMode(value, data){
      disableDropdownValueInEditMode.value = false
      paymentCategoryOptionsInEdit.value = []
      if(value == 0){
        errorMsgForCardNotPresent.value = null
        let objOption = {}
        objOption.text = 'Card Present'
        objOption.value = 'CARD_PRESENT'
        paymentCategoryOptionsInEdit.value.push(objOption)
        disableDropdownValueInEditMode.value = true
        addForm.pm_cat = objOption.value
      }else{
        if(data.pm_cat == 'CARD_NOT_PRESENT'){
          paymentCategoryOptionsInEdit.value = [{value: '', text: 'Select a payment category'},
            {value: 'CARD_NOT_PRESENT', text: 'Card Not Present'},
            {value: 'CNP_CARD', text: 'Online Card'},
            {value: 'CNP_ACH', text: ' ACH'},
          ]
        }else{
          paymentCategoryOptionsInEdit.value = [{value: '', text: 'Select a payment category'},
            {value: 'CNP_CARD', text: 'Online Card'},
            {value: 'CNP_ACH', text: ' ACH'},
          ]
        }
      }
    }

    function resetAddForm(){
      addForm.errors = ''
      addForm.dropdownOption = ''
      addForm.dropdownId = ''
      addForm.credit_or_debit = 0
      addForm.pm_cat = ''
      errorMsgForCardNotPresent.value = null
      errorMsgForOptionRequired.value = null
      errorMsgForAddingSubMethod.value = null
    }

    function showUid(pm_uid_data) {
      modalCopy.show = true
      pmUid.value = pm_uid_data['pm_uid']
      modalCopy.option = pm_uid_data['description']
      paymentCategoryOptions.value.find((data)=>{
        if (data.value === pm_uid_data['pm_cat']){
          modalCopy.category = data.text
        }
      })
      modalCopy.credit_debit = pm_uid_data['is_card_pmt']
    }

    function copyUid() {
      copyText(pmUid.value, undefined, (error, event) => {
        if (error) {
          console.error('Error!')
        }
      })
      var uuidCopyFade = document.getElementById("uuidCopyFade");
      uuidCopyFade.classList.add('uuidCopyFade')
      setTimeout(function() {
          uuidCopyFade.classList.remove('uuidCopyFade')
      },3000)
    }

    function closePmAlert() {
      modalCopy.show = false
      modalCopy.category = null
      modalCopy.option = null
    }

    return {
      t,
      dropdownOptions,
      showAddNewRow,
      sortColumnDirection,
      getAllOptionsPerDropdownField,
      slu,
      deleteDropdownOption,
      option,
      tinymce_api_key: process.env.MIX_TINYMCE_API_KEY,
      addForm,
      addDropdownOption,
      sort,
      modal,
      alertMsg,
      closeAlert,
      confirmAlert,
      IdToDelete,
      deleteOption,
      isLoading,
      fullPage,
      updateOptionButton,
      editForm,
      ShowUpdateButton,
      showAddNewRowButton,
      sortColumn,
      credit_or_debit,
      selectRow,
      selectedRow,
      updateDropDownOption,
      paymentCategoryOptions,
      checkedFunction,
      disableDropdownOption,
      paymentCategoryOptionsInAdd,
      disableDropdownValueInEditMode,
      checkedFunctionInEditMode,
      paymentCategoryOptionsInEdit,
      errorMsgForCardNotPresent,
      warningModel,
      closeWarningAlert,
      deleteOptionValue,
      errorMsgForOptionRequired,
      errorMsgForAddingSubMethod,
      cardNotPresent,
      resetAddForm,
      isCardNotPresentValue,
      showUid,
      modalCopy,
      ModalForm,
      pmUid,
      copyUid,
      closePmAlert,
    }
  },
}
</script>
<style scoped>
.uuidCopyFade {
	display: block !important;
}
</style>
