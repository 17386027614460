<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Languages") }}
        </h1>

        <Button
          id="button_add-language"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="slideOver.show = true,slideOver.title = t('Add Languages'), environment = 'add', resetErrors(), resetForm()"
        >
          {{ t("Add new language") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm"> {{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllLanguages()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getAllLanguages(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="languages_owner_portal_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Language Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'country_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Language Code") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('lang_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'lang_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Language Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('lang_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'lang_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(language, index) in languages"
                :id="'languages_owner_portal_' + index"
                :key="index"
              >
                <td>{{ language.country_name }}</td>
                <td>{{ language.lang_code }}</td>
                <td>{{ language.lang_name }}</td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="edit"
                      @click="slideOver.show = true, slideOver.title = t('Edit Language') , environment = 'edit', resetErrors(), getLanguageById(language.lang_id)"
                    />
                    <tippy
                      v-if="language.user_count > 0"
                      placement="left"
                    >
                      <button-circle
                        type="danger"
                        icon="trash"
                        :disabled="true"
                      />
                      <template #content>
                        <span> {{ t("You won't be able to delete this Language, as it is attached to a User.") }} </span>
                      </template>
                    </tippy>
                    <button-circle
                      v-else
                      type="danger"
                      icon="trash"
                      @click="deleteWarning(language.lang_id)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!languages.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllLanguages($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    :close-button-text="slideOver.close_button_text"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <div
            class="
              px-3
              py-2.5
              border border-gray-200
              rounded
              bg-gray-100
              text-sm
            "
          >
            {{ t("Sample language file") }}

            <router-link
              class="text-primary hover:underline"
              to=""
              @click="downloadSampleFile()"
            >
              {{ t("download") }}
            </router-link>
          </div>
        </div>

        <div>
          <form-label for="country">
            {{ t("Country") }}
            <required />
          </form-label>
          <form-select
            id="country"
            v-model="form.country"
            :items="countryOptions"
            name="country"
            class="mt-1"
            :disable="environment=='edit'"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.country_code
                ? form.errors.validations.country_code[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="lang_code">
            {{ t("Language Code") }}
            <required />
          </form-label>
          <form-input
            id="lang_code"
            v-model="form.langCode"
            type="text"
            name="lang_code"
            class="mt-1"
            :disabled="environment=='edit'"
          />

          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.lang_code
                ? form.errors.validations.lang_code[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="lang_name">
            {{ t("Language Name") }}
            <required />
          </form-label>
          <form-input
            id="lang_name"
            v-model="form.langName"
            type="text"
            name="lang_name"
            class="mt-1"
          />

          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.lang_name
                ? form.errors.validations.lang_name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label>
            {{ t("Language File") }}
            <required />
          </form-label>
          <div class="mt-1 break-words text-sm">
            {{ form.fileName }}
          </div>
          <input
            ref="file"
            class="hidden"
            type="file"
            @change="handleFileUpload"
          >
          <button-secondary
            id="lang-file"
            name="lang-file"
            class="mt-1 btn-xs"
            type="button"
            @click="$refs.file.click()"
          >
            {{ t("Browse File") }}
          </button-secondary>

          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.file
                ? form.errors.validations.file[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="onSubmit"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watch } from 'vue'
import { Tippy } from 'vue-tippy'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormError from '@/components/FormError.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

export default {
  name: 'Languages',

  components: {
    Tippy,
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormLabel,
    FormInput,
    FormError,
    PageHeader,
    FormSelect,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
  },

  setup() {
    const { t, te, tm } = useI18n()
    const environment = ref('add')
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const countryOptions = ref([{ value: '', text: 'Please select a country' }])
    const searchText = ref('')
    const searchParam = ref('')
    const lastPage = ref(0)
    const perPage = ref(10)
    const IdToDelete = ref(false)
    const sortColumn = ref('')
    const totalRecord = ref(0)
    const currentPage = ref(0)
    const languages = ref([])
    const router = useRouter()
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: true,
      close_button_text: t('Close'),
      title: t("Create Language") ,
    })

   watch (() =>{
      slideOver.title = t("Create Language")
    })

    const form = reactive({
      id:null,
      country: null,
      langCode: null,
      langName: null,
      file: null,
      fileName: null,
      errors: null
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
      confirm_button : false,
      button_text : 'Ok'
    })
    getCountry()
    getAllLanguages()

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('country')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function handleFileUpload(e){
      form.file = e.target.files[0]
      form.fileName = form.file.name
    }

    async function getCountry() {
      await axios.get('v1/address/countries').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((country) => {
            let obj = {}
            obj.text = country.country_name
            obj.value = country.country_code
            countryOptions.value.push(obj)
          })
        }
      })
    }

    function getAllLanguages(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true

      if (search != null) {
        searchText.value = search
      }

      axios
        .get('/v1/language/get', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            languages.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
          } else {
            console.error('Error!')
          }
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getAllLanguages(1, sortColumn.value, searchText.value)
    }

    async function getLanguageById(langId) {
      await axios.get('v1/language/view/' + langId).then((response) => {
        if (response.data.data != null) {
          let language = response.data.data
          resetForm()
          form.id = language.lang_id
          form.country = language.country_code
          form.langCode = language.lang_code
          form.langName = language.lang_name
          if (language.lang_file){
            form.fileName = language.lang_code + '.json'
          }
        }
      })
    }

    function onSubmit(){
      resetErrors()
      isLoading.value = true
      let formData = new FormData();
      formData.append("file", form.file != null ? form.file : '');
      formData.append("lang_id",  form.id != null ? form.id : '');
      formData.append("country_code",  form.country != null  ? form.country : '');
      formData.append("lang_code",  form.langCode != null ? form.langCode : '');
      formData.append("lang_name",  form.langName != null ? form.langName : '');

      let url =null;
      if(form.id == null){
        url ='v1/language/save'
      }else{
        url ='v1/language/edit'
        formData.append("lang_file",  form.fileName != null ? true : false);
      }
      axios.post(url, formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        isLoading.value = false
        if (response.data != null && !response.data.data.errors) {
          slideOver.show = false
          if(form.id == null){
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Language added successfully.'
            modal.show = true
          }else {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Language updated successfully.'
            modal.show = true
          }
          getAllLanguages()
          resetForm()
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function deleteLanguage() {
      axios
        .get('v1/language/delete/' + IdToDelete.value)
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getAllLanguages()
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function downloadSampleFile() {
      axios
        .get('v1/language/download-sample',{responseType: 'arraybuffer'})
        .then((response) => {
          if (response.data != null) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample_language.'+ 'json'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
    }

    function deleteWarning(id) {
      IdToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function closeAlert() {
      modal.show = false
      return true
    }

    function resetForm(){
      form.id = null
      form.country = null
      form.langCode = null
      form.langName = null
      form.file = null
      form.fileName = null
      resetErrors()
    }

    function confirmAlert() {
      deleteLanguage()
      modal.show = false
      return true
    }

    function resetErrors(){
      form.errors = null
    }


    return {
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      sortColumn,
      form,
      onSubmit,
      modal,
      closeAlert,
      countryOptions,
      getCountry,
      handleFileUpload,
      languages,
      perPage,
      getAllLanguages,
      totalRecord,
      lastPage,
      sort,
      getLanguageById,
      deleteLanguage,
      deleteWarning,
      confirmAlert,
      resetForm,
      downloadSampleFile,
      searchText,
      environment,
      resetErrors,
      t,

    }
  },
}
</script>
