<template>
  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white class="inline-block h-14 mb-6" />
        <div class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2 class="mb-3 text-2xl font-medium text-black">
              404 - Page Not Found
            </h2>
            <h5 class="text-black text-opacity-70 font-medium">
              The page you're looking for is not available.
            </h5>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <Button
                class="btn-wide"
                type="submit"
                @click="$router.push($router.go(-1))"
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>
</template>

<script>
import Button from '@/components/Button.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'

export default {
  name: 'PageNotFound',

  components: {
    Button,
    LogoWhite,
    AuthFooter,
  },
}
</script>
