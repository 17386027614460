<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Manage Tokens") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllTokens()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              :search-text="quickSearchText"
              @dataFetchfunction="getAllTokens(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Account Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_acc_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'mch_acc_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Payer Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('payer_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'payer_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Token Reference") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_ref')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'mch_ref'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Token Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'mch_status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(token, index) in tokens"
                :id="'token_portal_'+ index"
                :key="index"
              >
                <td class="whitespace-nowrap">
                  {{ token.mch_acc_name }}
                </td>
                <td class="max-w-xs">
                  {{ (token.payer_first_name ? token.payer_first_name : '' )+ " " + (token.payer_last_name ? token.payer_last_name : '') }}
                </td>
                <td class="max-w-lg">
                  <a
                    :id="'gateways_edit_' + 1"
                    style="text-decoration: none; "
                    title="Transaction Summery"
                  >{{ token.reference }}</a>
                </td>
                <td class="max-w-lg">
                  <badge
                    v-if="token.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="token.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                  <badge
                    v-if="token.status == 'EXPIRED'"
                    type="warning"
                    text="Expired"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      type="info"
                      icon="eye"

                      @click.stop="
                        $router.push({
                          name: 'tokenised-summary',
                          params: { slug: token.pay_token_id },
                        })
                      "
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="totalRecord == 0" />
            </tbody>
          </table>
        </template>
      </TableDefault>


      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllTokens($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="t(slideOver.title)"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Transaction Details") }}
      </div>
      <div>
        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant Account") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.merchant }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.mch_id }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Document Number (Reference)") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.document_number }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Date") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ formatDateTime(form.transaction_date) }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.payment_type }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70" />
            <div class="mt-1 text-black font-medium" />
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Amount") }}
            </div>
            <div
              v-if="form.amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? '-' + Math.abs(form.amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? form.amount + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Fee") }}
            </div>
            <div
              v-if="form.gw_fee < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? '-' + Math.abs(form.gw_fee).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? form.gw_fee + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Net") }}
            </div>
            <div
              v-if="form.net_amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? '-' + Math.abs(form.net_amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? form.net_amount + ' ' + form.currency_code : '' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Customer Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Customer Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.customer_name ? form.customer_name : '-' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payor Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ (form.payer_name ? form.payer_name : '-' )+ " " + (form.payer_last_name ? form.payer_last_name : '') }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Email") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.payer_email }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Origin of Transaction") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.origin_country_name }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payment Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_type }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Method") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_method }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Card Details") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.card_details }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.paymentDevice }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Location") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pd_location }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.pd_serial_number && form.encrypt_pd_serial_number == constantValue"
                  class="absolute inset-y-0 left-0 m-px border-l rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.pd_serial_number, 'pd_serial_no')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium">
                  {{ form.encrypt_pd_serial_number }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("opuspay Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("opuspay Tracking ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_trn_ref }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.gateway }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway Token") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.gw_token && form.encrypt_gw_token == constantValue"
                  class="absolute inset-y-0 left-o m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.gw_token, 'gw_token')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium  pr-10">
                  {{ form.encrypt_gw_token }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Status") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <badge
                v-if="form.status =='GW_APPROVED' ||
                  form.status =='GW_CAPTURED'||
                  form.status =='GW_SETTLED' ||
                  form.status =='GW_RETURNED'"
                type="success"
                text="SUCCESS"
              />
              <badge
                v-if="form.status =='GW_CANCELLED'"
                type="warning"
                text="CANCEL"
              />
              <badge
                v-if="form.status =='GW_DECLINED' ||
                  form.status =='GW_ERROR' ||
                  form.status =='GW_FAILED'"
                type="danger"
                text="FAIL"
              />
              <badge
                v-if="form.status =='GW_INIT' ||
                  form.status =='GW_PROCESSING'||
                  form.status =='GW_VERIFY_SIG' ||
                  form.status =='INIT' ||
                  form.status =='GW_PENDING'"
                type="info"
                text="PENDING"
              />
            </div>
          </div>

          <!--          <div>-->
          <!--            <div class="text-xs font-normal text-black opacity-70">-->
          <!--              {{ t("opuspay Token") }}-->
          <!--            </div>-->
          <!--            <div class="mt-1 text-black font-medium">-->
          <!--              {{ form.pay_trn_ref }}-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-if="form.status == 'GW_DECLINED' || form.status == 'GW_ERROR' || form.status == 'GW_FAILED'">
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Due to") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_msg }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </slide-over>
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Badge from '@/components/Badge.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import Divider from '@/components/Divider.vue'
import axios from "axios";
import moment from "moment";
import router from "../../routes";
import constant from "../../constant";

export default {
  name: 'ManageTokens',

  components: {
    Badge,
    Search,
    Loading,
    SvgIcon,
    SlideOver,
    FormSelect,
    PageHeader,
    TableDefault,
    ButtonCircle,
    VuePagination,
    Divider,
    NoTableData
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const searchParam = ref('')
    const quickSearchText = ref('')
    const totalRecord = ref(0)
    const lastPage = ref(0)
    const currentPage = ref(0)
    const perPage = ref('10')
    const tokens = ref(0)
    const constantValue = constant.encryptionKey

    const slideOver = reactive({
      show: false,
      size: 'max-w-4xl',
      close_button: true,
      close_button_text: 'Close',
      title: 'Transaction Summary',
    })

    const search = reactive({
      inv_date_from: null,
      inv_date_to: null,
      inv_number: null,
      merchant: null,
      errors: null,
    })

    const searchValues = reactive({
      inv_date_from: null,
      inv_date_to: null,
      inv_number: null,
      merchant: null,
    })

    const form = reactive({
      transaction_id: null,
      merchant: null,
      transaction_date: null,
      invoice_number: null,
      amount: null,
      payer_name: null,
      payer_last_name: null,
      payer_first_name:null,
      payment_type: null,
      status: null,
      gateway: null,
      customer_name:null,
      gw_fee: null,
      net_amount: null,
      payer_email: null,
      origin_country_name: null,
      pay_method: null,
      pay_type: null,
      card_details: null,
      pay_device_name: null,
      pd_location: null,
      pd_serial_number: null,
      pay_trn_ref: null,
      gw_token: null,
      document_number: null,
      mch_id: null,
      currency_code: null,
      pay_msg: null,
      encrypt_gw_token : constant.encryptionKey,
      encrypt_pd_serial_number : constant.encryptionKey
    })

    function decryptData(data, value) {
      decryptDataForm.data = data
      axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
        if (response.data != null) {
          if (value == 'gw_token') {
            form.encrypt_gw_token = response.data
          }
          else if (value == 'pd_serial_no') {
            form.encrypt_pd_serial_number = response.data
          }

        } else {
          console.error('Error!')
        }
      })
    }
    const decryptDataForm = reactive({
      data: '',
    })
    async function getAllTokens(
      page = 1,
      column = sortColumn.value,
      quickSearch = null,
      fromAdvanceSearch = false
    ) {
      const max = /^.{0,255}$/
      let index = 0


      if (index == 0){
        isLoading.value = true
        let searchText = ''
        if (quickSearch != null) {
          quickSearchText.value = quickSearch
        }

          await axios
            .get('/v1/manage-tokens/get-all', {
              params: {
                quickSearch: quickSearchText.value,
                count_per_page: perPage.value,
                page: page,
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
              if (response.data != null) {
                isLoading.value = false
                tokens.value = response.data.data.data
                lastPage.value = response.data.data.last_page
                totalRecord.value = response.data.data.total
                currentPage.value = response.data.data.current_page
              } else {
                slideOver.show = false
                console.error('Error!')
              }
            })
            .catch(err => {
              router.push({ name: 'unauthorized-access'})
            })
      }
    }
    async function getMerchantTransaction(transaction_id){
      form.encrypt_gw_token = ''
      form.encrypt_pd_serial_number = ''
      resetTransactionSummary()
      await axios.get('v1/transaction/view/' + transaction_id).then((response) => {
        if (response.data.data != null) {
          let merchant = response.data.data
          form.transaction_id = merchant.pay_trn_id
          if(merchant.amount != null){
            form.amount = Number((merchant.amount)).toFixed(constant.decimalPlaces)
          }else{
            form.amount = merchant.amount
          }
          form.payer_name = merchant.payer_name
          form.payer_last_name = merchant.payer_last_name
          form.status = merchant.status
          form.gateway = merchant.gateway.name
          form.payment_type = merchant.payment_type
          if(merchant.gw_fee != null){
            form.gw_fee = Number(merchant.gw_fee).toFixed(constant.decimalPlaces)
          }else{
            form.gw_fee = merchant.gw_fee
          }
          if(merchant.net_amount != null){
            form.net_amount = Number(merchant.net_amount).toFixed(constant.decimalPlaces)
          }else{
            form.net_amount = merchant.net_amount
          }
          form.payer_email = merchant.payer_email
          form.origin_country_name = merchant.origin_country_name
          form.pay_method = merchant.pay_method
          form.pay_type = merchant.pay_type
          form.card_details = merchant.card_details
          form.pd_location = merchant.pd_location
          form.pd_serial_number = merchant.pd_serial_number
          if(form.pd_serial_number){
            form.encrypt_pd_serial_number = constant.encryptionKey
          }
          form.pay_trn_ref = merchant.pay_trn_ref
          form.gw_token = merchant.gw_token
          if(form.gw_token){
            form.encrypt_gw_token = constant.encryptionKey
          }
          form.document_number = merchant.mch_ref
          form.mch_id = merchant.mch_id
          form.merchant = merchant.merchant.mch_acc_name
          form.transaction_date = merchant.created_at
          form.currency_code = merchant.currency.currency_code
          form.customer_name = merchant.customer_name
          form.pay_msg = merchant.pay_msg
          if(merchant.pay_device != null){
            form.paymentDevice = merchant.pay_device.pd_name
          }
        }
      })
    }

    function resetTransactionSummary(){
      form.transaction_id = null
      form.amount = null
      form.payer_name = null
      form.payer_last_name = null
      form.status = null
      form.gateway = null
      form.payment_type = null

      form.gw_fee = null
      form.net_amount = null
      form.payer_email = null
      form.origin_country_name = null
      form.pay_method = null
      form.pay_type = null
      form.card_details = null
      form.paymentDevice = null
      form.pd_location = null
      form.pd_serial_number = null
      form.pay_trn_ref = null
      form.gw_token = null
      form.document_number = null
      form.mch_id = null
      form.merchant = null
      form.transaction_date = null
      form.pay_msg = null
    }
    function formatDateTime(dateTime, format = 'DD-MMM-YYYY') {
      if (!moment(dateTime, format).isValid()) {
        return moment(dateTime).format(format)
      } else {
        return moment(dateTime, format).format(format)
      }
    }

    function isSearch() {
      if (
        search.inv_date_from != null ||
        search.inv_date_to != null ||
        search.inv_number != null ||
        search.merchant != null
      ) {
        return true
      } else {
        return false
      }
    }


    function searchDetails() {
      search.inv_date_from = searchValues.inv_date_from
      search.inv_date_to = searchValues.inv_date_to
      search.inv_number = searchValues.inv_number
      search.merchant = searchValues.merchant
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      getAllTokens(1, sortColumn.value, quickSearchText.value)
    }

    return {
      t,
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      sortColumn,
      searchParam,
      quickSearchText,
      totalRecord,
      lastPage,
      currentPage,
      perPage,
      search,
      isSearch,
      searchValues,
      searchDetails,
      sort,
      formatDateTime,
      form,
      getAllTokens,
      tokens,
      getMerchantTransaction,
      constantValue,
      decryptData,
    }
  },
}
</script>
