window._ = require('lodash')
window.axios = require('axios')
export default {
    namespaced: true,
    state: {
        widgetData: null,
        programData: null,
    },

    mutations: {
        widgetData(state, data) {
            if (data != null) {
                state.widgetData = data
            } else {
                state.widgetData = null
            }
        },
        qrProgramData(state, data) {
            if (data != null) {
                state.programData = data
            } else {
                state.programData = null
            }
        },
    },
    actions: {
        async getWidgetData({ commit, state }, widgetData) {
            commit('widgetData', widgetData)
                
        },
        async getProgram({ commit, state }, programData) {
            commit('qrProgramData', programData)
        
        }
    },
}
