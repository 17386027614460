<template>
  <button
    ref="btnCircle"
    class="
      p-1
      flex
      items-center
      justify-center
      rounded-full
      focus:outline-none
      focus:ring-2 focus:ring-offset-2 focus:ring-primary
    "
    :class="{
      'bg-primary-lighter': type == 'info',
      'bg-red-200': type == 'danger',
      'bg-green-200': type == 'success',
      'bg-yellow-100': type == 'warning',
    }"
    type="button"
    @click="btnClick"
  >
    <svg-icon
      class="w-5 h-5"
      :class="{
        'text-primary-light': type == 'info',
        'text-red-700': type == 'danger',
        'text-green-700': type == 'success',
        'text-yellow-700': type == 'warning',
      }"
      :icon="icon"
    />
  </button>
</template>

<script>
import { ref } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'ButtonCircle',

  components: {
    SvgIcon,
  },

  props: {
    type: {
      type: String,
      default: null,
      required: true,
    },

    icon: {
      type: String,
      default: null,
      required: true,
    },
  },

  setup() {
    const btnCircle = ref(null)

    function btnClick() {
      btnCircle.value.blur()
    }

    return {
      btnClick,
      btnCircle,
    }
  }
}
</script>
