<template>
  <tr>
    <td
      class="text-center"
      colspan="100"
    >
      <span class="my-4 block opacity-70">{{ t("No records available") }} </span>
    </td>
  </tr>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'NoTableData',
  setup(){
    const { t, te, tm } = useI18n()
    return{
      t,
    }
  }
}
</script>

<style scoped>
.table.table-clickable tbody tr:hover {
  @apply bg-white;
  @apply cursor-default;
}
</style>
