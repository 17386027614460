<template>
  <div class="flex-shrink-0 flex flex-wrap">
    <tippy
      class="w-full"
      placement="top"
      theme="dropdown"
      trigger="click"
      :arrow="false"
    >
      <div class="w-full cursor-pointer bg-primary-lightest">
        <div class="p-4 flex items-center justify-between">
          <div class="flex items-center">
            <div>
              <img
                v-if="false"
                id="user-avatar"
                class="inline-block h-9 w-9 rounded-full"
                src=""
                alt=""
              >
              <svg-icon
                v-else
                id="user-default-avatar"
                class="w-auto h-6"
                icon="avatar-fill"
              />
            </div>
            <div class="ml-3 mr-2 break-all">
              <label
                id="label-user_name"
                class="cursor-pointer text-sm font-medium text-black opacity-90"
              >{{ user_name }}</label>
            </div>
          </div>

          <button
            class="
              rounded-full
              focus:outline-none
              focus:ring-2 focus:ring-primary
            "
            type="button"
          >
            <svg-icon
              class="w-auto h-6 text-gray-900"
              icon="caret-up"
            />
          </button>
        </div>
      </div>

      <template #content>
        <div class="w-52 space-y-1">
          <div class="w-full px-2.5 py-2 text-xs text-gray-500 break-all">
            {{ user_email }}
          </div>

          <div
            v-if="merchantUser != null "
            class="w-full border-t border-gray-100"
          />

          <button
            v-if="merchantUser != null "
            id="button-backToAdminPortal"
            class="w-full px-2.5 py-2 hover:bg-gray-50 focus:outline-none"
            type="button"
            role="menuitem"
            tabindex="-1"
            @click="backToAdminPortal()"
          >
            <div class="flex items-center justify-between space-x-2">
              <span class="font-medium text-xs text-gray-500">{{ t("Back to admin portal") }}</span>
              <svg-icon
                class="w-auto h-5 text-gray-900"
                icon="undo"
              />
            </div>
          </button>

          <div class="w-full border-t border-gray-100" />

          <button
            id="button-changepassword"
            class="w-full px-2.5 py-2 hover:bg-gray-50 focus:outline-none"
            type="button"
            role="menuitem"
            tabindex="-1"
            @click="changePassword"
          >
            <div class="flex items-center justify-between space-x-2">
              <span class="font-medium text-xs text-gray-500">{{ t("Change password") }}</span>
              <svg-icon
                class="w-auto h-4 text-gray-900"
                icon="lock-open"
              />
            </div>
          </button>

          <div class="w-full border-t border-gray-100" />

          <button
            id="button-signout"
            class="w-full px-2.5 py-2 hover:bg-gray-50 focus:outline-none"
            type="button"
            role="menuitem"
            tabindex="-1"
            @click="logout"
          >
            <div class="flex items-center justify-between space-x-2">
              <span class="font-medium text-xs text-gray-500">{{ t("Sign out") }}</span>
              <svg-icon
                class="w-auto h-4 text-gray-900"
                icon="signout"
              />
            </div>
          </button>
        </div>
      </template>
    </tippy>

    <div
      v-if="merchantUser != null"
      class="px-4 py-2 w-full break-all bg-green-500 flex items-center"
    >
      <label
        id="label-logged_in_user"
        class="text-xs font-medium text-white opacity-90"
      >  Currently logged in as: {{ merchantUserOrganizationName }}</label>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import axios from "axios";
import router from "../../routes";
import { Tippy } from 'vue-tippy'
import SvgIcon from '@/components/SvgIcon.vue'
import constant from "@/constant";
import CryptoJs from 'crypto-js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'NavFooter',

  components: {
    Tippy,
    SvgIcon,
  },

  setup() {
    const user_name = ref('')
    const user_email = ref('')
    const merchantUser = ref('')
    const merchantUserOrganizationName = ref('')
    const router = useRouter()
    const CryptoJsAesJson = constant.CryptoJsAesJson
    const { t } = useI18n()

    getUser()
    setInterval(editUser, 1000)
    function editUser(){
      if (localStorage.getItem("loggedUser")!=null){
        user_name.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('loggedUser'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
      }
    }

    async function getUser() {
      user_name.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.user.username
      user_email.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.user.email
      if (localStorage.getItem('merchant') != ''){
        merchantUser.value = localStorage.getItem('merchant')
        merchantUserOrganizationName.value = localStorage.getItem('merchant_user_organization_name')
      }
    }

    async function logout() {
      await axios.post('logout').then((response) => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('user')
        localStorage.removeItem('security_grants')
        localStorage.removeItem('merchant')
        localStorage.removeItem('merchant_user_name')
        localStorage.removeItem('loggedUser')
        if(sessionStorage.getItem('isWidgetCustomized') !=  null) {
          sessionStorage.removeItem('isWidgetCustomized')
        }
      })

      router.push({ name: 'signin' })
    }

    async function changePassword() {
      router.push({ name: 'change-password' })
    }

    async function backToAdminPortal(){
      let user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
      user.authentication_data.user.user.user_type ='TNT'
      localStorage.setItem('user', CryptoJs.AES.encrypt(
          JSON.stringify(user),
          process.env.MIX_ENCRYPT_KEY,
          { format: constant.CryptoJsAesJson }
      ).toString())

      let form = {}
      form.source_acc_type = 'MCH'
      form.destination_acc_type = 'TNT'
      await axios.post('change-user-to-admin' ,  form ).then((response) => {
        if(response.data != null){
          localStorage.removeItem('merchant')
          localStorage.removeItem('merchant_user_name')
        }
      })
      await router.push({ name: 'dashboard' })
      location.reload()

    }

    return {
      logout,
      user_name,
      backToAdminPortal,
      merchantUser,
      user_email,
      merchantUserOrganizationName,
      changePassword,
      t,
    }
  },
}
</script>
