export default {
  editorModeSettings: {
    fontsize_formats:
      '10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 34px 36px 42px 72px',
    menu: {
      edit: {
        title: 'Edit',
        items: 'undo redo | cut copy paste pastetext | selectall',
      },
      insert: {
        title: 'Insert',
        items: 'image link pagebreak',
      },
      format: {
        title: 'Format',
        items:
          'bold italic underline strikethrough superscript subscript | backcolor | forecolor | formats | removeformat',
      },
      table: {
        title: 'Table',
        items: 'inserttable tableprops deletetable | cell row column',
      },
    },
    menubar: 'edit insert format table',
    min_height: 360,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor code fullscreen forecolor searchreplace visualblocks insertdatetime media table paste help wordcount',
    ],
    resize: false,
    toolbar:
      'undo redo | fontselect | bold italic underline backcolor forecolor | code fullscreen |  alignleft aligncenter alignright alignjustify | fontsizeselect |  bullist numlist outdent indent | removeformat',
  },
  viewModeSettings: {
    menubar: false,
    min_height: 300,
    resize: false,
    toolbar: false,
  },
  viewOnModalSettings: {
    menubar: false,
    min_height: 500,
    resize: false,
    toolbar: false,
  },
}
