window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    countryListDropDown: [],
    countryCodes: [],
  },

  mutations: {
    countryListData(state, data) {
      state.countryListDropDown = data
    },
  },

  actions: {
    async getCountryList(
      { commit, state },
      message = 'Please select a country'
    ) {
      let countryList = [{ value: '', text: message }]
      var countryCode = []
      await axios.get('v1/address/countries').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((country) => {
            let obj = {}
            obj.value = country.country_code
            obj.text = country.country_name
            countryList.push(obj)
          })
          commit('countryListData', countryList)
        }
      })
    },
  },
}
