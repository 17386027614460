window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    ruleListDropDown: [],
    ruleInfo: null,
    ruleOutput: [],
    ruleStat: [],
    transactionRouteDelete: null,
  },

  mutations: {
    ruleListData(state, data) {
      state.ruleListDropDown = data
    },
    ruleInfoData(state, data) {
      state.ruleInfo = data
    },
    ruleOutputData(state, data) {
      state.ruleOutput = data
    },
    deleteTransactionRuleData(state, data) {
      if (data != null) {
        state.transactionRouteDelete = data
      } else {
        state.transactionRouteDelete = null
      }
    },
    validateTransactionRuleData(state, data) {
      state.ruleStat = data
    },
  },

  actions: {
    async getRuleList({ commit, state }, slug) {
      let ruleList = []
      await axios.get('/v1/transactionrouter/rule/get').then((response) => {
        if (response.data.data != null) {
          response.data.data.data.find((rule) => {
            let obj = {}
            obj.value = rule.colum_description
            obj.text = rule.colum_description
            if (rule.rule_column_header != null) {
              obj.header = rule.rule_column_header
            }
            ruleList.push(obj)
          })
          commit('ruleListData', ruleList)
        }
      })
    },

    async saveRule({ commit, state }, Rule) {
      let ruleList = []
      await axios
        .post('/v1/transactionrouter/rule/save', Rule)
        .then((response) => {
          if (response.data.data != null) {
            commit('ruleOutputData', response.data)
          }
        })
    },
    async editRule({ commit, state }, Rule) {
      await axios
        .post('/v1/transactionrouter/rule/edit/' + Rule.id, Rule)
        .then((response) => {
          try {
            commit('ruleOutputData', response.data)
          } catch (e) {
            commit('ruleOutputData', response.data)
          }
        })
    },

    async getRoute({ commit, state }, RuleID) {
      let ruleList = []
      await axios
        .get('/v1/transactionrouter/rule/view/' + RuleID)
        .then((response) => {
          if (response.data.data != null) {
            commit('ruleInfoData', response.data.data)
          }
        })
    },
    async changeRuleConfigStatus({ commit, state }, RuleID) {
      let ruleList = []
      await axios
        .post('/v1/transactionrouter/rule/stat-change', RuleID)
        .then((response) => {})
    },
    async copyTransactionRule({ commit, state }, RuleID) {
      let ruleList = []
      await axios
        .get('/v1/transactionrouter/rule/copy-rule/' + RuleID)
        .then((response) => {
          commit('ruleOutputData', response.data)
        })
    },
    async deleteTransactionRule({ commit, state }, RuleID) {
      await axios
        .post('/v1/transactionrouter/rule/delete-rule/' + RuleID)
        .then((response) => {
          if (response.data.status == true) {
            commit('deleteTransactionRuleData', response.data)
          }
        })
    },

    async validateTransactionRule({ commit, state }, RuleID) {
      await axios
        .post('/v1/transactionrouter/rule/validate-rule/' + RuleID)
        .then((response) => {
          try {
            commit('validateTransactionRuleData', response.data)
          } catch (e) {
            commit('validateTransactionRuleData', response.data)
          }
        })
    },
  },
}
