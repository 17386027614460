<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <page-header>
    <template #header-content>
      <h1
        id="page-title"
        class="page-title"
      >
        {{ t("Home") }}
      </h1>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="mt-4 sm:mt-2 sm:py-8">
      <div class="sm:-m-5 flex flex-wrap justify-center lg:justify-start">
        <router-link
          v-for="(item, key) in grantedLinks"
          :id="'dashboard-link-' + key + '_' + item.routeName"
          :key="key"
          :to="{ name: item.routeName }"
          class="
            group
            flex flex-col
            items-center
            justify-center
            w-32
            h-32
            md:w-44
            md:h-44
            m-2
            sm:m-5
            rounded-lg
            border-2 border-gray-200
            hover:border-primary
            hover:text-primary
            transition-all
          "
        >
          <svg-icon
            class="w-auto h-14 md:h-20 text-gray-400 group-hover:text-primary"
            :icon="item.icon"
            stroke-width="0.8"
          />
          <span
            class="
              px-2
              font-medium
              text-center
              text-sm
              text-gray-900
              group-hover:text-primary
            "
          >{{ t(item.title) }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import SvgIcon from '@/components/SvgIcon.vue'
import PageHeader from '@/components/PageHeader.vue'
import axios from 'axios'
import Loading from 'vue3-loading-overlay'
import { useStore } from 'vuex'
import constant from "@/constant";
import CryptoJs from 'crypto-js'

export default {
  name: 'Dashboard',

  components: {
    SvgIcon,
    PageHeader,
    Loading,
  },

  setup() {
    const { t } = useI18n()
    const isLoading = ref(false)
    const fullPage = ref(true)
    const store = useStore()
    const adminLinks = reactive([
      {
        title: 'Registrations',
        icon: 'users',
        routeName: 'registrations-index',
        securityGrantId: 'TNT_1',
      },
      {
        title: 'Manage Merchants',
        icon: 'merchant',
        routeName: 'merchants-index',
        securityGrantId: 'TNT_17',
      },
      {
        title: 'Billing',
        icon: 'tag',
        routeName: 'generate-invoices',
        securityGrantId: 'TNT_3 TNT_21 TNT_22',
      },

       {
        title: 'Transactions',
        icon: 'dollar',
        routeName: 'transactions-index',
        securityGrantId: 'TNT_15',

      },
      {
        title: t("QR Payments"),
        icon: 'qr-code',
        routeName: 'qrprogram-index',
        securityGrantId: 'TNT_19',
      },

       {
        title: 'Manage Tokens',
        icon: 'token',
        routeName: 'manage-token-index',
        securityGrantId: 'TNT_18',

      },




      //Development Bug Fix #7525
      // {
      //   title: 'Transaction Router',
      //   icon: 'router',
      //   routeName: 'rule-index',
      //   securityGrantId: '',
      // },
      {
        title: 'Settings',
        icon: 'cog',
        securityGrantId: 'TNT_5 TNT_13 TNT_6 TNT_8 TNT_10 TNT_12 TNT_7 TNT_11',
        routeName: 'settings',
      },
    ])

    const subAdminLinks = reactive([
      {
        title: 'Countries & Currencies',
        routeName: 'countries-currencies',
        securityGrantId: 'TNT_5',
      },
      {
        title: 'Dropdown Fields',
        routeName: 'dropdown-fields',
        securityGrantId: 'TNT_13',
      },
      {
        title: 'Gateways',
        routeName: 'gateways',
        securityGrantId: 'TNT_6',
      },
      {
        title: 'Plans',
        routeName: 'plans',
        securityGrantId: 'TNT_8',
      },
      {
        title: 'Security Profiles',
        routeName: 'security-profiles',
        securityGrantId: 'TNT_10',
      },
      {
        title: 'Terms & Conditions',
        routeName: 'terms',
        securityGrantId: 'TNT_12',
      },
      {
        title: 'Tax Groups',
        routeName: 'tax-groups',
        securityGrantId: 'TNT_7',
      },
      {
        title: 'Users',
        routeName: 'users',
        securityGrantId: 'TNT_11',
      },
      {
        title: 'Transaction Router',
        routeName: 'rule-index',
        securityGrantId: 'TNT_16',
      },
      {
        title: 'Generate Invoices',
        routeName: 'generate-invoices',
        securityGrantId: 'TNT_21',
      },
      {
        title: 'Billing History',
        routeName: 'billing-history',
        securityGrantId: 'TNT_22',
      }
    ])

    const securityGrants = ref([])

    const grantedLinks = reactive([])

    const nestedRouteGrantId = ref(null)

    const CryptoJsAesJson = constant.CryptoJsAesJson

    getSecurityGrantsPerSecurityProfile()

    async function getSecurityGrantsPerSecurityProfile() {
      isLoading.value = true
      securityGrants.value = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.security_grants
      getAuthorizedLinks(adminLinks)
      isLoading.value = false
    }

    function getAuthorizedLinks(array) {
      array.filter(function (elm) {
        const nestedNav = ref([])
        if (elm.securityGrantId != null) {
          nestedNav.value = elm.securityGrantId.split(' ')
        }
        if (checkGrants(nestedNav.value)) {
          let obj = {}
          obj.title = elm.title
          obj.icon = elm.icon
          obj.routeName = elm.routeName
          obj.securityGrantId = elm.securityGrantId
          if (
            elm.securityGrantId.split(' ').length > 1 &&
            nestedRouteGrantId.value != null
          ) {
            subAdminLinks.filter(function (nestedSecurityGrant) {
              if (
                nestedSecurityGrant.securityGrantId == nestedRouteGrantId.value
              ) {
                obj.routeName = nestedSecurityGrant.routeName
                nestedRouteGrantId.value = null
              }
            })
          }
          grantedLinks.push(obj)
        }
      })
    }

    function checkGrants(securityGrantId) {
      var status = false
      securityGrants.value.filter(function (elm) {
        if (elm.sec_grant_id == securityGrantId || securityGrantId == '' ) {
          status = true
        }
        if (securityGrantId.length > 1 && nestedRouteGrantId.value == null) {
          securityGrantId.filter(function (nestedSecurityGrant) {
            if (nestedSecurityGrant == elm.sec_grant_id) {
              status = true
              nestedRouteGrantId.value = nestedSecurityGrant
            }
          })
        }
      })
      return status
    }

    return {
      t,
      adminLinks,
      grantedLinks,
      isLoading,
      fullPage,
    }
  },
}
</script>
