<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center flex-wrap">
        <back-button
          @click="goBack"
        />
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Security Profiles") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="mb-4">
        <div class="w-full mb-4">
          <div class="sm:flex flex-wrap md:flex-nowrap items-center md:space-x-3">
            <form-label
              class="font-semibold whitespace-nowrap"
              for="security_profile"
            >
              {{ t("Security Profile") }}<required v-if="environment != 'View'" />
            </form-label>

            <div
              v-if="environment == 'View'"
              class="w-full border border-gray-200 bg-gray-100 mt-1 md:mt-0 px-3 py-2 text-sm break-all rounded cursor-not-allowed"
              style="min-height: 35px;"
            >
              {{ form.profileName }}
            </div>

            <div
              v-else
              class="w-full mt-1 md:mt-0"
            >
              <form-input
                id="security_profile"
                v-model="form.profileName"
                name="user_name"
                type="text"
                :disabled="environment == 'View'"
                autofocus
                required
              />
            </div>
          </div>

          <form-error
            v-if="form.errors"
            class="ml-36"
            :message="
              form.errors.validations.profile_name
                ? form.errors.validations.profile_name[0]
                : ''
            "
          />
        </div>

        <div class="w-full">
          <div
            class="
              px-3
              py-2.5
              flex
              items-center
              justify-between
              rounded-t
              bg-gray-200
            "
          >
            <div class="flex items-center">
              <button
                class="focus:outline-none"
                type="button"
              >
                <svg-icon
                  class="w-auto h-8 text-gray-400"
                  :class="openLevel1 ? 'transform rotate-90 text-primary' : ''"
                  icon="caret-right"
                  @click="
                    openLevel1 ? (openLevel1 = false) : (openLevel1 = true)
                  "
                />
              </button>

              <div class="ml-1 font-semibold">
                {{ t("Functions") }}
              </div>
            </div>

            <div class="mx-1">
              <form-checkbox
                id="function"
                v-model="form.isAllActive"
                name="status"
                :model-value="form.isAllActive == 1 ? 0 : 1"
                :checked="form.isAllActive == 1 ? true : false"
                :disable="environment == 'View'"
                @click="selectAllGrantsCheck()"
              />
            </div>
          </div>

          <div class="px-1.5 border border-gray-200 rounded-b">
            <!-- Main feature -->
            <div
              v-for="(feature, index) in features"
              :key="index"
              class="feature"
            >
              <div
                v-if="feature && openLevel1"
                class="
                  px-1.5
                  py-2
                  flex
                  items-center
                  justify-between
                  rounded
                  bg-gray-100
                "
              >
                <div class="flex items-center">
                  <button
                    class="focus:outline-none"
                    :class="{ invisible: !feature.children.length }"
                    type="button"
                    :disabled="!feature.children.length"
                  >
                    <svg-icon
                      class="w-auto h-8 text-gray-400"
                      :class="
                        checkOpenLevelStatus(feature.sec_grant_id)
                          ? 'transform rotate-90 text-primary'
                          : ''
                      "
                      icon="caret-right"
                      @click="setOpenLevel(feature.sec_grant_id)"
                    />
                  </button>

                  <div
                    class="ml-1 font-normal"
                    :for="index"
                    v-html="feature.feature_name"
                  />
                </div>

                <div class="mx-1">
                  <form-checkbox
                    :id="feature.sec_grant_id"
                    v-model="form.securityGrant"
                    name="status"
                    :model-value="feature.sec_grant_id"
                    :checked="setSecurityGrantsCheck(feature.sec_grant_id)"
                    :disable="environment == 'View'"
                    @click="
                      setFromSecurityGrants(
                        feature.sec_grant_id,
                        feature.parent_sec_grant_id,
                        '',
                        feature.children
                      )
                    "
                  />
                </div>
              </div>

              <!-- Sub feature -->
              <div
                v-for="(child, childIndex) in feature.children"
                :key="childIndex"
                class="feature ml-11"
              >
                <div
                  v-if="
                    openLevel1 &&
                      checkOpenLevelStatus(child.parent_sec_grant_id)
                  "
                  class="
                    px-1.5
                    py-2
                    flex
                    items-center
                    justify-between
                    rounded
                    bg-gray-100
                  "
                >
                  <div class="flex items-center">
                    <button
                      class="focus:outline-none"
                      :class="{ invisible: !child.children.length }"
                      type="button"
                      :disabled="!child.children.length"
                    >
                      <svg-icon
                        class="w-auto h-8 text-gray-400"
                        :class="
                          checkOpenLevelStatus(child.sec_grant_id)
                            ? 'transform rotate-90'
                            : ''
                        "
                        icon="caret-right"
                        @click="setOpenLevel(child.sec_grant_id)"
                      />
                    </button>
                    <div
                      class="ml-1 text-gray-900 font-medium text-sm"
                      v-html="child.feature_name"
                    />
                  </div>

                  <div class="mx-1">
                    <form-checkbox
                      :id="child.sec_grant_id"
                      v-model="form.securityGrant"
                      name="status"
                      :model-value="child.sec_grant_id"
                      :checked="setSecurityGrantsCheck(child.sec_grant_id)"
                      :disable="environment == 'View'"
                      @click="
                        setFromSecurityGrants(
                          child.sec_grant_id,
                          child.parent_sec_grant_id,
                          feature.parent_sec_grant_id,
                          child.children
                        )
                      "
                    />
                  </div>
                </div>

                <!-- Sub-child feature -->
                <div
                  v-for="(superChild, superChildIndex) in child.children"
                  :key="superChildIndex"
                  class="feature ml-6"
                >
                  <div
                    v-if="
                      openLevel1 &&
                        checkOpenLevelStatus(child.parent_sec_grant_id) &&
                        checkOpenLevelStatus(superChild.parent_sec_grant_id)
                    "
                    class="
                      py-2
                      pr-1.5
                      pl-5
                      flex
                      items-center
                      justify-between
                      rounded
                    "
                  >
                    <div class="flex items-center">
                      <svg-icon
                        class="w-auto h-2 text-gray-400 opacity-70"
                        icon="circle-solid"
                      />

                      <div
                        class="ml-4 text-gray-500 text-sm"
                        v-html="superChild.feature_name"
                      />
                    </div>

                    <div class="mx-1">
                      <form-checkbox
                        :id="superChild.sec_grant_id"
                        v-model="form.securityGrant"
                        name="status"
                        :model-value="superChild.sec_grant_id"
                        :checked="
                          setSecurityGrantsCheck(superChild.sec_grant_id)
                        "
                        :disable="environment == 'View'"
                        @click="
                          setFromSecurityGrants(
                            superChild.sec_grant_id,
                            superChild.parent_sec_grant_id,
                            child.parent_sec_grant_id,
                            []
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <form-error
            v-if="form.errors"
            class="my-0.5"
            :message="
              form.errors.validations.sec_grants
                ? form.errors.validations.sec_grants[0]
                : ''
            "
          />
        </div>
      </div>

      <div class="flex items-center justify-end">
        <Button
          v-if="environment != 'View'"
          class="btn-wide"
          type="button"
          @click="onSubmit"
        >
          {{ t("Save") }}
        </Button>
        <Button
          v-if="environment == 'View' && form.is_admin_profile != 1"
          class="btn-wide"
          type="button"
          @click="navigateToEdit()"
        >
          {{ t("Edit") }}
        </Button>
        <tippy
          v-if="environment == 'View' && form.is_admin_profile == 1"
          placement="left"
        >
          <Button
            v-if="environment == 'View'"
            class="btn-wide"
            type="button"
            disabled
            @click="navigateToEdit()"
          >
            Edit
          </Button>
          <template #content>
            <span> Admin profile cannot be edited.</span>
          </template>
        </tippy>
      </div>
    </div>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { reactive, ref } from 'vue'
import router from '@/routes'
import { useRoute } from 'vue-router'
import axios from 'axios'
import Modal from '@/components/Modal'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import BackButton from '@/components/BackButton'
import FormError from '@/components/FormError.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormInput from '@/components/FormInput.vue'
import PageHeader from '@/components/PageHeader.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import { Tippy } from 'vue-tippy'
import { useI18n } from 'vue-i18n'

export default {
  name: 'SecurityProfilesEdit',

  components: {
    Button,
    SvgIcon,
    FormError,
    FormLabel,
    FormInput,
    PageHeader,
    FormCheckbox,
    BackButton,
    Modal,
    Loading,
    Required,
    Tippy,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const features = ref([])
    const openLevel1 = ref(true)
    const openLevel = ref([])
    const route = useRoute()
    const isLoading = ref(false)
    const grantList = ref([])
    const grantListRelation = ref([])
    const parentChildRelation = ref([])
    const environment = ref('')
    const isFromViewPage = ref(false)
    const form = reactive({
      id: '',
      profileName: '',
      securityGrant: '',
      profileType: '',
      securityGrants: [],
      isAllActive: 0,
      rowVersion: '',
      is_admin_profile: null,
      errors: '',
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    getSecurityGrants()
    setEnvironment()

    async function getSecurityGrants() {
      isLoading.value = true
      await axios.get('v1/security-grants').then((response) => {
        if (response.data != null) {
          isLoading.value = false
          features.value = response.data.data
          getSecurityGrantList()
        } else {
          console.error('Error!')
        }
      })
    }

    async function getSecurityProfileDetails() {
      const sp_id = route.params.slug
      if (sp_id != null) {
        await axios.get('/v1/security-profile/' + sp_id).then((response) => {
          if (response.data != null) {
            form.id = response.data.data.sec_profile_id
            form.profileName = response.data.data.sec_profile_name
            form.is_admin_profile = response.data.data.is_admin_profile
            form.rowVersion = response.data.data.row_version
            if (response.data.data.grants.constructor.name == 'Object'){
              let f;
              for (f in response.data.data.grants) {
                form.securityGrants.push(response.data.data.grants[f].sec_grant_id)
              }
            }
          else{
              response.data.data.grants.forEach((value) => {
                form.securityGrants.push(value.sec_grant_id)
              })
            }
            isLoading.value = false
            checkAllGrantsCheck()
          } else {
            console.error('Error!')
          }
        })
      }
    }

    async function getSecurityGrantList() {
      isLoading.value = true
      await axios.get('v1/security-grant-list').then((response) => {
        if (response.data != null) {
          response.data.forEach((value) => {
            let obj = {}
            let parentHas = false
            let obj1 = {
              sec_grant_id: '',
              child_sec_grant_ids: [],
            }
            obj.sec_profile_id = value.sec_grant_id
            obj.parent_sec_grant_id = value.parent_sec_grant_id
            grantListRelation.value.push(obj)
            grantList.value.push(value.sec_grant_id)

            parentChildRelation.value.forEach((value1) => {
              if (value1.sec_grant_id == value.parent_sec_grant_id) {
                value1.child_sec_grant_ids.push(value.sec_grant_id)
                parentHas = true
              }
            })
            if (!parentHas) {
              obj1.sec_grant_id = value.parent_sec_grant_id
              obj1.child_sec_grant_ids.push(value.sec_grant_id)
              parentChildRelation.value.push(obj1)
              parentHas = false
            }
          })
          isLoading.value = false
        } else {
          console.error('Error!')
        }
      })
      parentChildRelation.value = [...new Set(parentChildRelation.value)]
      await getSecurityProfileDetails()
    }

    function setFromSecurityGrants(
      securityGrantId,
      securityGrantParentId,
      securityGrantSupperParentId,
      securityGrantIdObjectArray
    ) {
      if (securityGrantId) {
        if (form.securityGrants.includes(securityGrantId)) {
          form.securityGrants.splice(
            form.securityGrants.indexOf(securityGrantId),
            1
          )
          if (securityGrantParentId) {
            if (form.securityGrants.includes(securityGrantParentId)) {
              form.securityGrants.splice(
                form.securityGrants.indexOf(securityGrantParentId),
                1
              )
              if (securityGrantSupperParentId) {
                if (form.securityGrants.includes(securityGrantSupperParentId)) {
                  form.securityGrants.splice(
                    form.securityGrants.indexOf(securityGrantSupperParentId),
                    1
                  )
                }
              }
            }
          }
        } else {
          form.securityGrants.push(securityGrantId)
        }
      }
      if (securityGrantIdObjectArray) {
        securityGrantIdObjectArray.forEach((value) => {
          if (
            form.securityGrants.includes(value.sec_grant_id) &&
            !form.securityGrants.includes(value.parent_sec_grant_id)
          ) {
            form.securityGrants.splice(
              form.securityGrants.indexOf(value.sec_grant_id),
              1
            )
          } else {
            form.securityGrants.push(value.sec_grant_id)
          }
          if (value.children) {
            value.children.forEach((childValue) => {
              if (
                form.securityGrants.includes(childValue.sec_grant_id) &&
                !form.securityGrants.includes(childValue.parent_sec_grant_id)
              ) {
                form.securityGrants.splice(
                  form.securityGrants.indexOf(childValue.sec_grant_id),
                  1
                )
              } else {
                form.securityGrants.push(childValue.sec_grant_id)
              }
            })
          }
        })
      }
      form.securityGrants = [...new Set(form.securityGrants)]
      checkParentGrantsCheck()
      checkAllGrantsCheck()
      checkParentGrantsCheck()
      checkAllGrantsCheck()
    }

    function checkAllGrantsCheck() {
      isLoading.value = true
      let _arr1 = form.securityGrants.sort()
      let _arr2 = grantList.value.sort()
      if (
        Array.isArray(_arr1) &&
        Array.isArray(_arr2) &&
        _arr1.length === _arr2.length
      ) {
        form.isAllActive = 1
      }

      if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
      ) {
        form.isAllActive = 0
      }
      isLoading.value = false
    }

    function checkParentGrantsCheck() {
      parentChildRelation.value.forEach((value) => {
        let _arr1 = form.securityGrants.sort()
        let _arr2 = value.child_sec_grant_ids.sort()
        if (
          hasSubArray(_arr1, _arr2) &&
          !_arr1.includes(value.sec_grant_id) &&
          value.sec_grant_id
        ) {
          form.securityGrants.push(value.sec_grant_id)
        }
      })
    }
    function hasSubArray(master, sub) {
      return sub.every(
        (
          (i) => (v) =>
            (i = master.indexOf(v, i) + 1)
        )(0)
      )
    }

    function setSecurityGrantsCheck(securityGrantId) {
      if (form.securityGrants.includes(securityGrantId)) {
        return true
      }
    }

    function selectAllGrantsCheck() {
      if (form.isAllActive == 0) {
        form.securityGrants = []
        grantList.value.forEach((value) => {
          form.securityGrants.push(value)
        })
      } else if (form.isAllActive == 1) {
        form.securityGrants = []
      }
    }

    function onSubmit() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.is_merchant = false
      SubmitArr.sec_profile_id = form.id
      SubmitArr.profile_name = form.profileName
      SubmitArr.sec_grants = form.securityGrants
      SubmitArr.row_version = form.rowVersion
      SubmitArr.sec_profile_type = 'TNT'
      isLoading.value = true
      form.errors = ''
      if (form.id == '') {
        url = '/v1/new-security-profile'
      } else {
        url = '/v1/edit-security-profile/' + form.id
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == '') {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Security profile created successfully.'
            modal.show = true
          } else {
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Security profile updated successfully.'
            modal.show = true
          }
        } else {
          form.errors = response.data.data.errors
          if(form.errors.validations.sec_profile_id != null){
            if(form.errors.validations.sec_profile_id[0]){
              router.push({ name: 'unauthorized-access' })
            }
          }
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function closeAlert() {
      modal.show = false
      router.push({ name: 'security-profiles' })
    }

    function confirmAlert() {
      modal.show = false
      return true
    }

    function setEnvironment() {
      isFromViewPage.value = false
      if (router.currentRoute.value.name == 'security-profiles-add') {
        environment.value = 'Add'
      }
      if (router.currentRoute.value.name == 'security-profiles-edit') {
        environment.value = 'Edit'
      }
      if (router.currentRoute.value.name == 'security-profiles-view') {
        environment.value = 'View'
      }
    }

    function navigateToEdit() {
      const sp_id = route.params.slug
      router.push({ name: 'security-profiles-edit', params: { slug: sp_id } })
      environment.value = 'Edit'
      isFromViewPage.value = true
    }

    function setOpenLevel(ParentId) {
      if (ParentId) {
        if (openLevel.value.includes(ParentId)) {
          openLevel.value.splice(openLevel.value.indexOf(ParentId), 1)
        } else {
          openLevel.value.push(ParentId)
        }
      }
    }

    function checkOpenLevelStatus(ParentId) {
      if (openLevel.value.includes(ParentId)) {
        return true
      }
    }

    function goBack() {
      if(isFromViewPage.value == true){
        environment.value = 'View'
      }
    }

    return {
      openLevel1,
      openLevel,
      features,
      form,
      modal,
      fullPage,
      setFromSecurityGrants,
      setSecurityGrantsCheck,
      closeAlert,
      confirmAlert,
      onSubmit,
      isLoading,
      grantList,
      selectAllGrantsCheck,
      environment,
      setEnvironment,
      navigateToEdit,
      setOpenLevel,
      checkOpenLevelStatus,
      parentChildRelation,
      grantListRelation,
      isFromViewPage,
      goBack,
      t,
    }
  },
}
</script>

<style scoped>
.feature {
  @apply my-1.5;
}
</style>
