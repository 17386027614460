<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Tax Groups") }}
        </h1>

        <Button
          id="button_add-tax-group"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="
            ;(slideOver.show = true),
              slideOverType('add'),
              (taxGroupForm = true),
              (atLeastOneStateAssignToMerchantAccount = false)
          "
        >
          {{ t("Add Tax Group") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getTaxGroups()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="searchText"
              @dataFetchfunction="getTaxGroups(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="tax_groups_tbl"
            class="table"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Tax Group Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Country") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('country_code')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'country_code'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("States") }}</span>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Tax Rate") }} (%)</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('rate')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' && sortColumn == 'rate'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(taxGroup, index) in taxGroups"
                :id="'tax_groups_' + index"
                :key="index"
              >
                <td class="max-w-xs">
                  {{ taxGroup.name }}
                </td>
                <td>{{ taxGroup.country.country_name }}</td>
                <td>
                  <div class="flex items-center justify-center">
                    <span v-if="taxGroup.is_all_states">All</span>
                    <ButtonSecondary
                      v-if="!taxGroup.is_all_states"
                      class="btn-xs"
                      type="button"
                      @click="
                        setEditTaxGroup(taxGroup.tax_group_id),
                        (slideOver.show = true),
                        slideOverType('view'),
                        (isClickedSelectStates = true)
                      "
                    >
                      {{ t("View") }}
                    </ButtonSecondary>
                  </div>
                </td>
                <td>{{ taxGroup.rate.toFixed(2) }}</td>
                <td class="text-center">
                  <badge
                    v-if="taxGroup.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="taxGroup.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                  <badge
                    v-if="taxGroup.status == 2"
                    type="warning"
                    text="Pending"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'tax_groups_edit_' + index"
                      type="info"
                      icon="edit"
                      @click="isEditMode = true,
                              (atLeastOneStateAssignToMerchantAccount = false),
                              setEditTaxGroup(taxGroup.tax_group_id),
                              (slideOver.show = true),
                              slideOverType('edit'),
                              (taxGroupForm = true)
                      "
                    />
                    <button-circle
                      v-if="!taxGroup.merchants > 0"
                      :id="'tax_groups_delete_' + index"
                      type="danger"
                      icon="trash"
                      @click="deleteTax(taxGroup.tax_group_id)"
                    />
                    <tippy
                      v-if="taxGroup.merchants > 0"
                      placement="left"
                    >
                      <button-circle
                        :id="'tax_groups_delete_' + index"
                        type="danger"
                        icon="trash"
                        disabled
                        @click="deleteTax(taxGroup.tax_group_id)"
                      />
                      <template #content>
                        <span>{{ t("You won't be able to delete this Tax Group as it is attached to one or more merchants") }}.</span>
                      </template>
                    </tippy>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!taxGroups.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getTaxGroups($event)"
      />
    </div>
  </div>

  <slide-over
    :slideover-title="
      isClickedAddTaxGroup
        ? isClickedSelectStates
          ? 'Select States'
          : 'Add Tax Group'
        : isClickedViewTaxGroup
          ? 'Select States'
          : isClickedSelectStates
            ? 'Select States'
            : 'Edit Tax Group'
    "
    :show="slideOver.show"
    :close-button="slideOver.close_button"
    :close-button-text="slideOver.closeButtonText"
    @closeSlideOver="closeSlideOverPanel"
  >
    <template #slideover-body>
      <form>
        <div
          v-if="taxGroupForm"
          class="space-y-4"
        >
          <div>
            <form-label
              for="tax_group_name"
            >
              {{ t("Tax Group Name") }}
              <required />
            </form-label>
            <form-input
              id="tax_group_name"
              v-model="form.name"
              class="mt-1"
              name="tax_group_name"
              type="text"
              required
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.name
                  ? form.errors.validations.name[0]
                  : ''
              "
            />
          </div>
          <div>
            <form-label
              for="country"
            >
              {{ t("Country") }}
              <required />
            </form-label>
            <form-select
              id="country"
              v-model="form.country"
              :change="onchange"
              class="mt-1"
              name="country"
              required
              :items="countryOptions"
              @onChangeFunction=";(form.states = []), getState()"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.country_code &&
                  !form.errors.validations.name
                  ? form.errors.validations.country_code[0]
                  : ''
              "
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.status &&
                  !form.errors.validations.name &&
                  !form.errors.validations.country_code &&
                  !form.errors.validations.rate
                  ? form.errors.validations.status[0]
                  : ''
              "
            />
          </div>

          <div>
            <form-label
              for="tax_rate"
            >
              {{ t("Tax Rate") }} (%)
              <required />
            </form-label>
            <form-input
              id="tax_rate"
              v-model="form.rate"
              class="mt-1"
              name="tax_rate"
              type="number"
              min="0"
              step="0.01"
              required
              onkeydown="javascript: return event.keyCode == 69 ? false : true"
              @keypress="onlyTwoDecimalNumbers"
            />
            <form-error
              v-if="form.errors"
              :message="
                form.errors.validations.rate &&
                  !form.errors.validations.name &&
                  !form.errors.validations.country_code
                  ? form.errors.validations.rate[0]
                  : ''
              "
            />
          </div>

          <div class="flex items-center">
            <form-checkbox
              id="active"
              v-model="form.status"
              name="active"
              :model-value="form.status == 'ACT' ? 'INA' : 'ACT'"
              :checked="form.status == 'ACT' ? true : false"
            />
            <label
              class="ml-2 text-sm"
              for="active"
            >{{ t("Active") }}</label>
          </div>


          <div>
            <form-label>
              {{ t("States") }}
            </form-label>
            <div class="mt-1 flex items-center">
              <tippy
                v-if="(atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                placement="left"
              >
                <form-checkbox
                  id="states_tippy"
                  v-model="form.isAllActive"
                  name="states"
                  :model-value="form.isAllActive == 1 ? 0 : 1"
                  :checked="form.isAllActive == 1 ? true : false"
                  :disable=" (atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                  @click="; selectAllStateCheck(), trapFocus()"
                />
                <label
                  class="ml-2 text-sm"
                  for="states"
                >{{ t("All States") }}</label>
                <template #content>
                  <span>{{ t("There is at least one merchant account in the state selected for this tax group.This cannot be unchecked until a different tax group is assigned to all merchant accounts") }}.</span>
                </template>
              </tippy>

              <div v-else>
                <form-checkbox
                  id="states"
                  v-model="form.isAllActive"
                  name="states"
                  :model-value="form.isAllActive == 1 ? 0 : 1"
                  :checked="form.isAllActive == 1 ? true : false"
                  :disable=" (atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                  @click="; selectAllStateCheck(), trapFocus()"
                />
                <label
                  class="ml-2 text-sm"
                  for="states"
                >{{ t("All States") }}</label>
              </div>
              <ButtonSecondary
                id="btn-select-states"
                class="ml-3 btn-xs"
                type="button"
                @click=";(isClickedSelectStates = true), (taxGroupForm = false), trapFocus()"
              >
                {{ t("Select States") }}
              </ButtonSecondary>
            </div>
          </div>
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.state &&
                !form.errors.validations.name &&
                !form.errors.validations.country_code &&
                !form.errors.validations.rate
                ? form.errors.validations.state[0]
                : ''
            "
          />
        </div>

        <div
          v-if="isClickedSelectStates || isClickedViewTaxGroup"
          class="space-y-4"
        >
          <div class="mb-3">
            <form-label
              class="pl-1"
              for="all_states"
            >
              {{ t("States") }}
            </form-label>
            <div class="mt-1 pl-1 flex items-center">
              <tippy
                v-if="stateDisale || isClickedViewTaxGroup || (atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                placement="left"
              >
                <form-checkbox
                  id="all_states_tippy"
                  v-model="form.isAllActive"
                  name="all_states"
                  :check-value="true"
                  :disable="stateDisale || isClickedViewTaxGroup || (atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                  :model-value="form.isAllActive == 1 ? 0 : 1"
                  :checked="form.isAllActive == 1 ? true : false"
                  @click="selectAllStateCheck()"
                />
                <label
                  class="ml-2 text-sm"
                  for="all_states"
                >{{ t("All States") }}</label>
                <template #content>
                  <span>{{ t("There is at least one merchant account in the state selected for this tax group.This cannot be unchecked until a different tax group is assigned to all merchant accounts") }}.</span>
                </template>
              </tippy>

              <div v-else>
                <form-checkbox
                  id="all_states"
                  v-model="form.isAllActive"
                  name="all_states"
                  :check-value="true"
                  :disable="stateDisale || isClickedViewTaxGroup || (atLeastOneStateAssignToMerchantAccount && form.isAllActive == 1)"
                  :model-value="form.isAllActive == 1 ? 0 : 1"
                  :checked="form.isAllActive == 1 ? true : false"
                  @click="selectAllStateCheck()"
                />
                <label
                  class="ml-2 text-sm"
                  for="all_states"
                >{{ t("All States") }}</label>
              </div>
            </div>
          </div>

          <div
            class="overflow-y-auto sm:-mr-6 p-1"
            style="max-height: calc(100vh - 280px)"
          >
            <div
              v-for="state in stateOptions"
              :key="state.key"
              class="mt-1 flex items-center"
            >
              <tippy
                v-if="state.canEditable == true && isEditMode == true && checkAlreadyAddedInTaxGroup(state.key) == true"
                placement="left"
              >
                <form-checkbox
                  :id="state.key"
                  v-model="form.state"
                  name="state_name_new"
                  :model-value="state.key"
                  :checked="setStateCheck(state.key)"
                  :disable="isClickedViewTaxGroup || (state.canEditable == true && checkAlreadyAddedInTaxGroup(state.key) == true)"
                  @click="setFromState(state.key)"
                />
                <label
                  class="ml-2 text-sm"
                  :for="state.key"
                >{{
                  state.value
                }}</label>
                <template #content>
                  <span>{{ t("There is at least one merchant account in the state selected for this tax group. This state cannot be unchecked until a different tax group is assigned to all merchant accounts in the state") }}.</span>
                </template>
              </tippy>
              <div v-else>
                <form-checkbox
                  :id="state.key"
                  v-model="form.state"
                  name="state_name_new"
                  :model-value="state.key"
                  :checked="setStateCheck(state.key)"
                  :disable="isClickedViewTaxGroup || (state.canEditable == true && checkAlreadyAddedInTaxGroup(state.key) == true)"
                  @click="setFromState(state.key)"
                />
                <label
                  class="ml-2 text-sm"
                  :for="state.key"
                >{{
                  state.value
                }}</label>
              </div>
            </div>
          </div>

          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.state &&
                !form.errors.validations.name &&
                !form.errors.validations.country_code &&
                !form.errors.validations.rate
                ? form.errors.validations.state[0]
                : ''
            "
          />
        </div>
      </form>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        v-if="isClickedSelectStates && !isClickedViewTaxGroup"
        class="btn-wide"
        type="button"
        @click=";(taxGroupForm = true), (isClickedSelectStates = false), trapFocus()"
      >
        {{ t("Back") }}
      </ButtonSecondary>
      <Button
        v-if="taxGroupForm && !isClickedViewTaxGroup"
        id="btn-save"
        class="btn-wide"
        type="button"
        @click="onSubmit()"
      >
        {{ t("Save") }}
      </Button>
      <Button
        v-if="isClickedSelectStates && !isClickedViewTaxGroup"
        class="btn-wide"
        type="button"
        @click="; stateValidation(), trapFocus()"
      >
        {{ t("Add") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { Tippy } from 'vue-tippy'
import { useRouter } from 'vue-router'
import focusTrap from '@/helpers/focus-trap.js'
import Search from '@/components/Search'
import Loading from 'vue3-loading-overlay'
import Badge from '@/components/Badge.vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import FormCheckbox from '@/components/FormCheckbox.vue'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import router from "../../routes";

export default {
  name: 'TaxGroups',

  components: {
    Badge,
    Modal,
    Button,
    Search,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormError,
    FormLabel,
    FormInput,
    FormSelect,
    PageHeader,
    NoTableData,
    TableDefault,
    FormCheckbox,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    Tippy,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const lastPage = ref(0)
    const currentPage = ref(0)

    let isClickedAddTaxGroup = ref(false)
    let isClickedEditTaxGroup = ref(false)
    let isClickedSelectStates = ref(false)
    let isClickedViewTaxGroup = ref(false)

    const searchText = ref('')
    const searchParam = ref('')
    const router = useRouter()
    const perPage = ref('10')
    const isLoading = ref(false)
    const IdToDelete = ref(false)
    let taxGroupForm = ref(false)
    let onchange = ref(true)
    let stateDisale = ref(true)
    const totalRecord = ref(0)
    const statesAssignedToMerchant = ref([])
    const form = reactive({
      id: '',
      name: '',
      country: '',
      rate: '',
      status: 'ACT',
      isAllActive: 0,
      state: '',
      states: [],
      errors: '',
      count:0
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })
    const sortColumnDirection = ref('desc')
    const countryOptions = ref([{ value: '', text: 'Please select a country' }])
    const stateOptions = ref([])
    const statesList = ref([])
    const taxGroups = ref([])
    const sortColumn = ref('')
    const isLinkToMerchant = ref(false)
    const isEditMode = ref(false)
    const prevTaxGroupStates = ref([])
    const atLeastOneStateAssignToMerchantAccount = ref(false)

    const slideOver = reactive({
      show: false,
      close_button: false,
      closeButtonText: 'Close',
    })

    getCountry()

    watch(() => {
      if(form.rate != 0){
        form.rate = checkTwoDecimalPlacesInValueCopyPaste(form.rate)
      }

    })

    function checkTwoDecimalPlacesInValueCopyPaste(value) {
      if (value != null) {
        if (value != null && value != '') {
          let input = value.toString()
          let output
          if (input.indexOf('.') > -1 && input.split('.')[1].length > 2) {
            output = input.substring(input.indexOf('.') + 3, 0)
          } else {
            output = input
          }
          return output
        }
      }
    }

    function onlyTwoDecimalNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || (form.rate != null && form.rate.indexOf('.') != -1))
      ) {
        // 46 is dot
        $event.preventDefault()
      }

      // restrict to 2 decimal places
      if (
        form.rate != null &&
        form.rate.indexOf('.') > -1 &&
        form.rate.split('.')[1].length > 1
      ) {
        $event.preventDefault()
      }

      if (
        form.rate != null &&
        form.rate.indexOf('.') > -1 &&
        form.rate.toString().indexOf('.') == 0
      ) {
        form.rate = '0' + form.rate
        $event.preventDefault()
      }
    }

    async function getCountry() {
      await axios.get('v1/address/countries').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((country) => {
            let obj = {}
            obj.text = country.country_name
            obj.value = country.country_code
            countryOptions.value.push(obj)
          })
        }
      })
    }

    async function getTaxGroups(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }
      await axios
        .get('/v1/tax-group', {
          params: {
            search: searchText.value,
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            isLoading.value = false
            taxGroups.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            currentPage.value = response.data.data.current_page
            totalRecord.value = response.data.data.total
          } else {
            console.error('Error!')
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getTaxGroups(1, sortColumn.value, searchText.value)
    }

    async function getState() {
      statesList.value = []
      stateOptions.value = []
      if (!isClickedEditTaxGroup.value && !isClickedViewTaxGroup.value){
        form.isAllActive = true
        form.status = 'ACT'
      }

      if (isClickedEditTaxGroup.value && isEditMode.value){
        ++form.count
        if (form.count >2){
          form.isAllActive = true
          form.status = 'ACT'
        }
      }

      if(form.country){
        await axios
          .get('v1/address/state-names-for-country/' + form.country)
          .then((response) => {
            if (response.data.data != null) {
              statesList.value = []
              stateOptions.value = []
              response.data.data.find((state) => {
                let obj = {}
                obj.value = state.state_name
                obj.key = state.state_id
                obj.canEditable = false
                if (isClickedEditTaxGroup.value) {
                  statesAssignedToMerchant.value.forEach((value) => {
                    if (value) {
                      if (value.state_id == parseInt(obj.key)) {
                        obj.canEditable = true
                      }
                    }
                    atLeastOneStateAssignToMerchantAccount.value = true
                  })
                }
                stateOptions.value.push(obj)
                statesList.value.push(state.state_id)
              })
            }
            stateDisale.value = false
            if (isClickedAddTaxGroup.value || (isClickedEditTaxGroup.value && isEditMode.value && (form.count > 2)) ) {
              statesList.value.forEach((value) => {
                form.states.push(value)
              })
            }
          })
      }
    }

    function setFromState(stateId) {
      if (form.states.includes(parseInt(stateId))) {
        form.states.splice(form.states.indexOf(parseInt(stateId)), 1)
      } else {
        form.states.push(stateId)
      }
      let _arr1 = form.states.sort()
      let _arr2 = statesList.value.sort()

      if (
        Array.isArray(_arr1) &&
        Array.isArray(_arr2) &&
        _arr1.length === _arr2.length
      ) {
        form.isAllActive = 1
      }

      if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
      ) {
        form.isAllActive = 0
      }
    }

    async function setEditTaxGroup(taxGroupId) {
      prevTaxGroupStates.value = []
      resetForm()
      await axios.get('v1/tax-group/' + taxGroupId).then((response) => {
        if (response.data.data != null) {
          let taxGroup = response.data.data
          resetForm()
          form.id = taxGroup.tax_group_id
          form.name = taxGroup.tax_name
          form.country = taxGroup.country_code
          form.rate = taxGroup.tax_rate.toFixed(2)
          form.status = taxGroup.status
          form.isAllActive = taxGroup.is_all_states
          taxGroup.tax_group_states.forEach((value) => {
            form.states.push(value.state_id)
            prevTaxGroupStates.value.push(value.state_id)
          })
        }
      })
      getMerchantStatesByTaxGroupId()
      getState()
    }

    function setStateCheck(stateId) {
      if (form.states.includes(parseInt(stateId))) {
        return true
      }
    }

    function checkAlreadyAddedInTaxGroup(stateId) {
      if (prevTaxGroupStates.value.includes(parseInt(stateId))) {
        return true
      }
    }

    async function getMerchantStatesByTaxGroupId() {
      if(isEditMode.value == true){
        await axios.get('v1/get-merchant-states/' + form.id).then((response) => {
          if (response.data.data != null) {
            statesAssignedToMerchant.value = response.data.data
          }
        })
        getState()
      }

    }

    function selectAllStateCheck() {
      if (form.isAllActive == 0) {
        form.states = []
        statesList.value.forEach((value) => {
          form.states.push(value)
        })
      } else if (form.isAllActive == 1) {
        isLoading.value = true
        form.states = []
        setTimeout(() => navigateToSelectStateView(), 100)
        isLoading.value = false
      }
    }

    function navigateToSelectStateView() {
      isClickedSelectStates.value = true
      taxGroupForm.value = false
    }
    function stateValidation() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.state_validation = true
      SubmitArr.state = form.states
      SubmitArr.tax_group_id = form.id
      SubmitArr.country_code = form.country
      SubmitArr.is_all_states = form.isAllActive
      url = '/v1/validate-tax-group-states'
      form.errors = ''
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          taxGroupForm.value = true
          isClickedSelectStates.value = false
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    function onSubmit() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.state_validation = false
      SubmitArr.tax_group_id = form.id
      SubmitArr.name = form.name
      SubmitArr.country_code = form.country
      SubmitArr.rate = form.rate
      SubmitArr.status = form.status
      SubmitArr.is_all_states = form.isAllActive
      SubmitArr.state = form.states
      isLoading.value = true
      if (form.id == '') {
        url = '/v1/new-tax-group'
      } else {
        url = '/v1/edit-tax-group/' + form.id
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == '') {
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Tax group created successfully.'
            modal.show = true
          } else {
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Tax group updated successfully.'
            modal.show = true
          }
          getTaxGroups(currentPage.value)
        } else {
          form.errors = response.data.data.errors
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function deleteTax(id) {
      IdToDelete.value = id
      modal.type = 'warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.title = 'Warning'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function confirmAlert() {
      deleteTaxGroup()
      modal.show = false
      return true
    }

    function deleteTaxGroup() {
      let data = {}
      data.tax_group_id = IdToDelete.value
      axios
        .delete('/v1/delete-tax-group/' + IdToDelete.value, {params: data})
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getTaxGroups(currentPage.value)
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function closeAlert() {
      modal.show = false
      router.push({ name: 'tax-groups' })
    }

    function trapFocus() {
      var focusLoop = document.getElementById("slideover_focus-loop")
      focusTrap.trapFocus(focusLoop)
    }

    function closeSlideOverPanel() {
      slideOver.show = false
      isEditMode.value = false
    }

    function slideOverType(type) {
      switch (type) {
        case 'add':
          isClickedAddTaxGroup.value = true
          isClickedViewTaxGroup.value = false
          isClickedEditTaxGroup.value = false
          taxGroupForm.value = true
          isClickedSelectStates.value = false
          slideOver.close_button = false
          resetForm()
          stateOptions.value = []
          break

        case 'edit':
          isClickedEditTaxGroup.value = true
          isClickedViewTaxGroup.value = false
          isClickedAddTaxGroup.value = false
          taxGroupForm.value = true
          isClickedSelectStates.value = false
          slideOver.close_button = false
          form.count = 0
          break

        case 'view':
          isClickedViewTaxGroup.value = true
          isClickedAddTaxGroup.value = false
          isClickedEditTaxGroup.value = false
          taxGroupForm.value = false
          isClickedSelectStates.value = true
          slideOver.close_button = true
          break
      }
    }

    function resetForm() {
      form.id = ''
      form.name = ''
      form.country = ''
      form.rate = ''
      form.status = 'ACT'
      form.isAllActive = 1
      form.state = ''
      form.states = []
      form.errors = ''
    }

    function focusElement() {
      if (isClickedSelectStates.value == false) {
        nextTick(() => {
          const focusRef = document.getElementById('tax_group_name')
          focusRef.focus()
        })
      }
    }

    return {
      t,
      form,
      sort,
      modal,
      perPage,
      onSubmit,
      fullPage,
      lastPage,
      onchange,
      getState,
      isLoading,
      taxGroups,
      slideOver,
      closeAlert,
      deleteTax,
      statesList,
      IdToDelete,
      searchText,
      trapFocus,
      searchParam,
      stateDisale,
      confirmAlert,
      taxGroupForm,
      getTaxGroups,
      setFromState,
      stateOptions,
      setStateCheck,
      slideOverType,
      countryOptions,
      deleteTaxGroup,
      setEditTaxGroup,
      closeSlideOverPanel,
      selectAllStateCheck,
      sortColumnDirection,
      isClickedAddTaxGroup,
      isClickedSelectStates,
      isClickedViewTaxGroup,
      sortColumn,
      totalRecord,
      stateValidation,
      onlyTwoDecimalNumbers,
      getMerchantStatesByTaxGroupId,
      isLinkToMerchant,
      statesAssignedToMerchant,
      isEditMode,
      checkAlreadyAddedInTaxGroup,
      atLeastOneStateAssignToMerchantAccount,
    }
  },
}
</script>
