<template>
  <transition
    appear
    enter-active-class="ease-in-out duration-1000"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="ease-in-out duration-1000"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div class="mt-1 text-red-500 font-medium text-xs">
      {{ message !=null ? t(message) : '' }}
    </div>
  </transition>
</template>

<script>
import { reactive, watchEffect } from 'vue'
import {useI18n} from "vue-i18n";

export default {
  name: 'FormError',

  props: {
    message: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const form = reactive({
      message: '',
    })
    const { t } = useI18n()

    watchEffect(() => {
      form.message = props.message
    })

    return {
      t,
    }
  },
}
</script>
