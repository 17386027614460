<template>
  <input
    ref="input"
    class="
      border border-gray-300
      w-4
      h-4
      text-primary
      focus:ring-primary
      disabled:cursor-not-allowed
    "
    type="radio"
    :value="modelValue"
    :disabled="disable"
    :checked="checked"
    :autofocus="autofocus"
    @input="$emit('update:modelValue', $event.target.value)"
  >
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'FormRadio',

  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },

    disable: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: null,
    },

    checked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {
    const input = ref(null)

    onMounted(() => {
      if (props.autofocus) {
        input.value.focus()
      }
    })

    return {
      input,
    }
  },
}
</script>
