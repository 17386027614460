<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Gateways") }}
        </h1>

        <Button
          id="button_add-gateway"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="showSlideover"
        >
          {{ t("Add Gateway") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllGateways"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              @dataFetchfunction="getAllGateways(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="gateways_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Gateway") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'asc' && sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(gateway, index) in gateways"
                :id="'gateways_' + index"
                :key="index"
              >
                <td class="max-w-4xl">
                  {{ gateway.name }}
                </td>
                <td class="text-center">
                  <badge
                    v-if="gateway.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-else
                    type="danger"
                    text="Inactive"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'gateways_view_' + index"
                      type="info"
                      icon="eye"
                      @click.stop="
                        $router.push({
                          name: 'gateway-summary',
                          params: { slug: gateway.gw_id, action: 'view' },
                        })
                      "
                    />
                    <button-circle
                      :id="'gateways_edit_' + index"
                      type="info"
                      icon="edit"
                      @click.stop="
                        $router.push({
                          name: 'gateway-summary',
                          params: { slug: gateway.gw_id, action: 'edit' },
                        })
                      "
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!gateways.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totatlRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllGateways($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="closeSlideOverPanel"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Gateway Provider") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="gateway">
            {{ t("Gateway") }}  <required />
          </form-label>
          <form-input
            id="gateway"
            v-model="form.gateway"
            class="mt-1"
            name="gateway"
            type="text"
            required
          />
          <form-error
            v-if="errors.name"
            :message="errors.name ? errors.name : ''"
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="status"
            v-model="form.status"
            :items="statusOptions"
            class="mt-1"
            name="status"
          />
          <form-error
            v-if="errors.status"
            :message="errors.status ? errors.status : ''"
          />
        </div>

        <div class="flex items-center">
          <form-checkbox
            :id="is_js_support"
            v-model="form.is_js_support"
            :model-value="form.is_js_support == 1 ? 0 : 1"
            :checked="form.is_js_support == 1"
            @click="getVisibility"
          />
          <form-label
            for="status"
            class="ml-2"
          >
            {{ t("JS Payment Support") }}
          </form-label>
        </div>

        <div class="flex items-center">
          <form-checkbox
            :id="device_pairing"
            v-model="form.device_pairing"
            :change="onchange"
            :checked="form.device_pairing == 1"
            :model-value="form.device_pairing == 1 ? 0 : 1"
          />
          <form-label
            for="status"
            class="ml-2"
          >
            {{ t("Device Pair") }}
          </form-label>
        </div>

        <div v-if="!disableField">
          <form-label for="gw_link">
            {{ t("Gateway type") }}
            <required v-if="!disableField" />
          </form-label>

          <form-select
            id="gw_identity_id"
            v-model="form.gw_identity_id"
            :value="form.gw_identity_id"
            class="mt-1"
            name="gw_identity_id"
            required
            :items="gatewayOptions"
            :disable="disableField"
          />
          <form-error
            v-if="errors.gw_identity_id"
            :message="errors.gw_identity_id ? errors.gw_identity_id : ''"
          />
        </div>
      </div>

      <divider />

      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Primary Contact") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="given_name">
            {{ t("Given Name") }}
          </form-label>
          <form-input
            id="given_name"
            v-model="form.givenName"
            class="mt-1"
            name="given_name"
            type="text"
          />
          <form-error
            v-if="errors.given_name"
            :message="errors.given_name ? errors.given_name : ''"
          />
        </div>

        <div class="w-full">
          <form-label for="last_name">
            {{ t("Last Name") }}
          </form-label>
          <form-input
            id="last_name"
            v-model="form.lastName"
            class="mt-1"
            name="last_name"
            type="text"
          />
          <form-error
            v-if="errors.last_name"
            :message="errors.last_name ? errors.last_name : ''"
          />
        </div>

        <div>
          <form-label
            for="business_email"
          >
            {{ t("Business Email") }} <required />
          </form-label>
          <form-input
            v-model="form.businessEmail"
            class="mt-1"
            name="business_email"
            type="email"
            required
          />
          <form-error
            v-if="errors.business_email"
            :message="errors.business_email ? errors.business_email : ''"
          />
        </div>

        <div>
          <form-label for="phone">
            {{ t("Business Phone") }} <required />
          </form-label>
          <form-input
            id="phone"
            v-model="form.phoneNumber"
            class="mt-1"
            name="phone"
            type="text"
            required
          />
          <form-error
            v-if="errors.business_phone"
            :message="errors.business_phone ? errors.business_phone : ''"
          />
        </div>
      </div>

      <divider />

      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Address Details") }}
      </div>

      <div class="grid sm:grid-cols-2 gap-4">
        <div>
          <form-label for="country">
            {{ t("Country") }} <required />
          </form-label>
          <form-select
            id="country"
            v-model="form.country"
            :value="form.country"
            :change="onchange"
            class="mt-1"
            name="country"
            required
            :items="countryOptions"
            @onChangeFunction="getState"
          />
          <form-error
            v-if="errors.country"
            :message="errors.country ? errors.country : ''"
          />
        </div>

        <div>
          <form-label for="state">
            {{ t("State") }} <required />
          </form-label>
          <form-select
            id="state"
            v-model="form.state"
            :disable="stateDisable"
            :items="stateOptions"
            class="mt-1"
            name="state"
            required
            @onChangeFunction="getTown"
          />
          <form-error
            v-if="errors.state"
            :message="errors.state ? errors.state : ''"
          />
        </div>

        <div>
          <form-label for="suburb">
            {{ t("Suburb/Town") }} <required />
          </form-label>
          <form-select
            id="suburb"
            v-model="form.suburb"
            :disable="suburbDisable"
            class="mt-1"
            name="suburb"
            :items="townOptions"
            required
            @onChangeFunction="getPostCode"
          />

          <form-error
            v-if="errors.suburb"
            :message="errors.suburb ? errors.suburb : ''"
          />
        </div>

        <div>
          <form-label for="postcode">
            {{ t("Post Code") }}<required />
          </form-label>
          <form-select
            id="postcode"
            v-model="form.postalCode"
            :length="postalCodeOptions.length"
            class="mt-1"
            name="postcode"
            required
            :items="postalCodeOptions"
            :disable="postalCodeDisable"
          />
          <form-error
            v-if="errors.postal_code"
            :message="errors.postal_code ? errors.postal_code : ''"
          />
        </div>

        <div>
          <form-label
            for="address_line_1"
          >
            {{ t("Address Line 1") }} <required />
          </form-label>
          <form-input
            id="address_line_1"
            v-model="form.address1"
            class="mt-1"
            name="address_line_1"
            type="text"
          />
          <form-error
            v-if="errors.address1"
            :message="errors.address1 ? errors.address1 : ''"
          />
        </div>

        <div>
          <form-label for="address_line_2">
            {{ t("Address Line 2") }}
          </form-label>
          <form-input
            id="address_line_2"
            v-model="form.address2"
            class="mt-1"
            name="address_line_2"
            type="text"
          />
          <form-error
            v-if="errors.address2"
            :message="errors.address2 ? errors.address2 : ''"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        class="btn-wide"
        type="button"
        @click="SubmitGateway"
      >
        {{ t("Save") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>

<script>
import {ref, reactive, onMounted, watch} from 'vue'
import { useStore } from 'vuex'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Badge from '@/components/Badge.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import router from "../../routes";
import FormCheckbox from "@/components/FormCheckbox";
export default {
  name: 'Gateways',

  components: {
    Modal,
    Badge,
    Search,
    Button,
    Divider,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    PageHeader,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    FormCheckbox,
  },

  setup() {
    const { t, te, tm } = useI18n()
    const sortColumnDirection = ref('desc')
    let onchange = ref(true)
    let stateDisable = ref(true)
    let suburbDisable = ref(true)
    let postalCodeDisable = ref(true)
    let countryOptions = ref(0)
    const stateOptions = ref(0)
    const townOptions = ref(0)
    const searchText = ref('')
    const postalCodeOptions = ref(0)
    const statusOptions = ref([
      { value: 'ACT', text: t("Active") },
      { value: 'INA', text: t("Inactive") },
    ])
    const sortColumn = ref('')
    const page = ref(1)
    const perPage = ref(10)
    const router = useRouter()
    const lastPage = ref(0)
    const totatlRecord = ref(0)
    const gateways = ref(0)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const store = useStore()
    const gateway_id = ref(0)
    let disableField = ref(true)
    const gatewayOptions = ref(0)

    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: 'Add Gateway Summary',
    })

    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })

    const form = reactive({
      gateway: null,
      status: 'ACT',
      provider: null,
      lastName: null,
      givenName: null,
      businessEmail: null,
      phoneNumber: null,
      country: '',
      state: '',
      suburb: '',
      postalCode: '',
      address1: null,
      address2: null,
      device_pairing: 0,
      is_js_support: 0,
      gw_identity_id : null
    })

    const errors = reactive({
      name: null,
      business_email: null,
      business_phone: null,
      country: null,
      postal_code: null,
      state: null,
      suburb: null,
      address1: null,
      address2: null,
      given_name: null,
      last_name: null,
      status: null,
      gw_identity_id: null
    })

    const lengthValidationErrorMg = {
      stateError: 'A state is not configured for the selected country. Please contact opuspay.',
      suburbError: 'A suburb is not configured for the selected country/ state. Please contact opuspay..',
      postalCodeError: 'A postcode is not configured for the selected country/state/suburb. Please contact opuspay.',
      identityGatewayError : 'Please select a gateway'
    }

    watch (() =>{
      slideOver.title = t("Add Gateway Summary")
      statusOptions.value[0].text = t("Active")
      statusOptions.value[1].text = t("Inactive")
    })


    function closeSlideOverPanel() {
      reetForm()
      resetErrors()
      slideOver.show = false
    }

    onMounted(() => {
      // getAllGateways()
    })

    function closeAlert() {
      router.push({
        name: 'gateway-summary',
        params: { slug: gateway_id.value, action: 'edit' },
      })
      modal.show = false
      slideOver.show = false

    }

    async function sort(column) {
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getAllGateways()
    }

    async function getAllGateways(
      currentPage,
      column = sortColumn.value,
      search = '@#@'
    ) {
      isLoading.value = true
      const OptionValue = {}
      OptionValue.page = currentPage
      OptionValue.column = column
      OptionValue.sortColumnDirection = sortColumnDirection.value
      OptionValue.perPage = perPage.value
      if (search != '@#@') {
        searchText.value = search
        OptionValue.searchText = searchText.value
      } else {
        OptionValue.searchText = searchText.value
      }
      await store.dispatch('Gateway/getGatewayList', OptionValue)
      if ((await store.state.Gateway.gatewayListData) != null) {
        gateways.value = await store.state.Gateway.gatewayListData.data
        lastPage.value = await store.state.Gateway.gatewayListData.last_page
        totatlRecord.value = await store.state.Gateway.gatewayListData.total
      }
      else {
        router.push({ name: 'unauthorized-access'})
      }
      isLoading.value = false
    }

    async function getCountry() {
      await store.dispatch('Country/getCountryList')
      countryOptions.value = await store.state.Country.countryListDropDown
      stateDisable.value = true

    }

    async function getState() {
      isLoading.value = true
      suburbDisable.value = true
      postalCodeDisable.value = true
      resetErrors("state")
      resetErrors("suburb")
      resetErrors("postal_code")
      dropDownListInitiateBehavior()
      if (form.country != '') {
        form.state = null
        form.suburb = null
        form.postalCode = null
        errors.country = null
        await store.dispatch('State/getStateListByCountryID', form.country)
        stateOptions.value = await store.state.State.stateListDropDown
        stateDisable.value = false

        if(lengthValidation(stateOptions) && stateOptions.value[0].value == ''){
            errors.state = lengthValidationErrorMg.stateError
        }

      } else {
        stateDisable.value = true
        suburbDisable.value = true
        postalCodeDisable.value = true
        form.state = ''
        form.suburb = ''
        form.postalCode = ''
      }
      isLoading.value = false
    }

    async function getTown() {
      isLoading.value = true
      postalCodeDisable.value = true
      if (form.state != '') {
        await store.dispatch('Suburb/getSuburbListByStateID', {
          countryId: form.country,
          stateId: form.state,
        })
        form.suburb = null
        form.postalCode = ''
        townOptions.value = await store.state.Suburb.suburbListDropDown
        suburbDisable.value = false

        if(lengthValidation(townOptions)){
          errors.suburb = lengthValidationErrorMg.suburbError
        }
      } else {
        suburbDisable.value = true
        postalCodeDisable.value = true
        form.suburb = ''
        form.postalCode = ''
      }
      isLoading.value = false
    }

    async function getPostCode() {
      isLoading.value = true
      if (form.suburb != '') {
        postalCodeDisable.value = false
        await store.dispatch('PostalCode/getPostalCodeBySuburbID', {
          countryId: form.country,
          stateId: form.state,
          suburbId: form.suburb,
        })
        postalCodeOptions.value = await store.state.PostalCode
          .postalCodesListDropDown
          if(postalCodeOptions.value.length == 2){
           form.postalCode = await  postalCodeOptions.value[1].value
            postalCodeDisable.value = false
          } else {
            form.postalCode = '';
          }
        if(lengthValidation(postalCodeOptions) ){
          errors.postal_code = lengthValidationErrorMg.postalCodeError
        }
      } else {
        postalCodeDisable.value = true
      }
      isLoading.value = false
    }

    async function SubmitGateway() {
      let submitForm = {}
      isLoading.value = true
      submitForm.name = form.gateway
      submitForm.status = form.status
      submitForm.given_name = form.givenName
      submitForm.last_name = form.lastName
      submitForm.business_email = form.businessEmail
      submitForm.business_phone = form.phoneNumber
      submitForm.country = form.country
      submitForm.state = form.state
      submitForm.suburb = form.suburb
      submitForm.postal_code = form.postalCode
      submitForm.address1 = form.address1
      submitForm.address2 = form.address2
      submitForm.device_pairing = form.device_pairing
      submitForm.is_js_support = form.is_js_support
      submitForm.gw_identity_id = form.gw_identity_id


      resetErrors()
      await store.dispatch('Gateway/postGateway', submitForm)
      let ReturnData = await store.state.Gateway.gatewayPostData

      if (ReturnData.status == true) {
        isLoading.value = false
        reetForm()
        //modal.show = true
        modal.confirm_button = false
        modal.button_text = 'Ok'
        modal.type = 'success'
        modal.title = 'Success'
        modal.message = 'Gateway created successfully.'
        modal.show = true
        gateway_id.value = ReturnData.data.gw_id
        // await getAllGateways()

      } else {
        isLoading.value = false
        bindValidationErrors(ReturnData.data.errors.validations)
      }
    }

    function lengthValidation(data){
      return data.value.length <= 1
    }

    function bindValidationErrors(validationData) {
      let temp = validationData
      for (var prop in errors) {
        if (validationData[prop]) {
          if (form.country){
            if (validationData[prop][0] == "Please select a state." && !stateDisable.value && lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.stateError]
            }
            if (suburbDisable.value && validationData[prop][0] == "Please select a suburb /town." && lengthValidation(townOptions) && lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.suburbError]
            }
            if(postalCodeDisable.value && validationData[prop][0] == "Please select a post code." && lengthValidation(stateOptions) && lengthValidation(townOptions) && lengthValidation(postalCodeOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }
            if (validationData[prop][0] == "Please select a suburb /town." && !suburbDisable.value && lengthValidation(townOptions)){
              validationData[prop] = [lengthValidationErrorMg.suburbError]
            }
            if(!suburbDisable.value && postalCodeDisable.value && validationData[prop][0] == "Please select a post code." && lengthValidation(townOptions) && !lengthValidation(stateOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }
            if(validationData[prop][0] == "Please select a post code." && !postalCodeDisable.value && lengthValidation(postalCodeOptions)){
              validationData[prop] = [lengthValidationErrorMg.postalCodeError]
            }
            if(validationData[prop][0] == "Please select a gateway." && !disableField.value && lengthValidation(gatewayOptions)){
              validationData[prop] = [lengthValidationErrorMg.identityGatewayError]
            }
          }
            errors[prop] = validationData[prop][0]
        }
      }

    }

    function  dropDownListInitiateBehavior(){
      stateOptions.value = [{value:'',text:'Please select a state'}]
      townOptions.value = [{value:'',text:'Please select a suburb/town'}]
      postalCodeOptions.value = [{value:'',text:'Please select a post code'}]
      gatewayOptions.value = [{value:'',text:'Please select a gateway'}]
    }

    function reetForm() {
      form.gateway = null
      form.status = 'ACT'
      form.provider = null
      form.lastName = null
      form.givenName = null
      form.businessEmail = null
      form.phoneNumber = null
      form.country = null
      form.state = null
      form.suburb = null
      form.postalCode = null
      form.address1 = null
      form.address2 = null
      form.device_pairing = 0
      form.is_js_support = 0
      form.gw_identity_id = null
      disableField.value = true
    }

    function resetErrors(area = 'all') {

      switch (area){
        case "all":
          errors.name = null
          errors.business_email = null
          errors.business_phone = null
          errors.country = null
          errors.postal_code = null
          errors.state = null
          errors.suburb = null
          errors.address1 = null
          errors.address2 = null
          errors.given_name = null
          errors.last_name = null
          errors.status = null
          errors.gw_identity_id = null
          break

        case "state":
          errors.state = null
          break

        case "suburb":
          errors.suburb = null
          break

        case "postal_code":
          errors.postal_code = null
          break

        case "gw_identity_id":
          errors.gw_identity_id = null
          break

      }

    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('gateway')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    async function showSlideover() {
      isLoading.value = true
      await getCountry()
      dropDownListInitiateBehavior();
      slideOver.show = true
      isLoading.value = false
    }
    //This function is to check if the user clicked the is_js_support or not
     async function getVisibility() {
      disableField.value = form.is_js_support == 1? true : false
      if(!disableField.value) {
      //If user checked this will get the gateways
          await getGatewaysAfterClickingJsSupport()
      } else {
        form.gw_identity_id = null
        gatewayOptions.value = [{value:'',text:'Please select a gateway'}]
        }
    }

    //This function is to get the gateways when user clicked the JS Support
    async function getGatewaysAfterClickingJsSupport() {
      await store.dispatch('GwIdentity/getGatewayList')
      gatewayOptions.value = await store.state.GwIdentity.GatewayListDropDown
    }

    return {
      form,
      errors,
      gateways,
      onchange,
      slideOver,
      page,
      totatlRecord,
      isLoading,
      fullPage,
      sortColumn,
      perPage,
      stateDisable,
      suburbDisable,
      postalCodeDisable,
      townOptions,
      stateOptions,
      statusOptions,
      countryOptions,
      postalCodeOptions,
      lastPage,
      modal,
      sortColumnDirection,
      closeAlert,
      getTown,
      getState,
      getPostCode,
      sort,
      SubmitGateway,
      closeSlideOverPanel,
      getAllGateways,
      showSlideover,
      getVisibility,
      disableField,
      getGatewaysAfterClickingJsSupport,
      gatewayOptions,
      t,
    }
  },
}
</script>
