window._ = require('lodash')

window.axios = require('axios')

import CryptoJs from 'crypto-js'
import constant from "@/constant";

axios.defaults.baseURL = '/api'

window.axios.defaults.headers.common['Authorization'] = 'Authorization'

let token = 'Bearer ' + (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != "" ?  JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('access_token'), process.env.MIX_ENCRYPT_KEY, {format: constant.CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)) : null)

if (token) {
  window.axios.defaults.headers.common['Authorization'] = token
} else {
  console.error('Token Not Found')
}
