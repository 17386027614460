<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Users") }}
        </h1>

        <div class="flex items-center justify-between space-x-3">
          <Button
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            @click="slideOver.show = true, searchUser()"
          >
            {{ t("Search User") }}
          </Button>

          <Button
            v-if="merchantUser == null "
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            @click="
              ;(showingUserAddEditSlideOver = true),
                userAddEdit('addUser'),
                (editModeTab = 'tab1')
            "
          >
            {{ t("Add User") }}
          </Button>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getUsers()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="flex flex-wrap items-center justify-center mx-1">
            <ButtonSecondary
              v-if="isRestFilterEnable"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="resetSearchFilter(), getUsers()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="refresh"
                stroke-width="2.5"
              />
              <span>{{ t("Reset Filters") }}</span>
            </ButtonSecondary>
          </div>

          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="quickSearchText"
              @dataFetchfunction="getUsers(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="users_merchant_portal_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Last Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('last_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'last_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("First Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('first_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'first_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Username") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('username')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'username'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Email Address") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('email_address')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'email_address'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Security Profile") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('security_profile')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'security_profile'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(userData, index) in users"
                :id="'users_merchant_portal_' + index"
                :key="userData.uuid"
              >
                <td style="max-width: 230px">
                  {{ userData.last_name }}
                </td>
                <td style="max-width: 230px">
                  {{ userData.first_name }}
                </td>
                <td style="max-width: 200px">
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/security/merchant-users' }"
                    @click="
                      userAddEdit(userData.uuid),(showingViewAccountSlideOver = true),
                      (showingUserAddEditSlideOver = false)
                    "
                  >
                    {{ userData.username }}
                  </router-link>
                </td>
                <td style="max-width: 230px">
                  {{ userData.business_email }}
                </td>
                <td>
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/security/merchant-security-profile/' + userData.security_profile.sec_profile_id}"
                  >
                    {{ userData.security_profile.sec_profile_name }}
                  </router-link>
                </td>
                <td class="text-center">
                  <badge
                    v-if="userData.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="userData.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                  <badge
                    v-if="userData.status == 'INIT'"
                    type="warning"
                    text="Pending"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'users_merchant_portal_view_' + index"
                      type="info"
                      icon="eye"
                      @click.stop="
                        ;(showingViewAccountSlideOver = true),
                          (showingUserAddEditSlideOver = false),
                          userAddEdit(userData.uuid)
                      "
                    />
                    <button-circle
                      :id="'users_merchant_portal_email_' + index"
                      type="info"
                      icon="Envelope"
                      :disabled="userData.status != 'INIT'"
                      @click.stop="resendRegisterEmail(userData)"
                    />
                    <button-circle
                      v-if="merchantUser == null"
                      :id="'users_merchant_portal_edit_' + index"
                      type="info"
                      icon="edit"
                      :disabled="userData.status == 'INIT'"
                      @click.stop="
                        ;(showingUserAddEditSlideOver = true),
                          (showingViewAccountSlideOver = false),
                          userAddEdit(userData.uuid),
                          (editModeTab = 'tab1')
                      "
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!users.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getUsers($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="username">
            {{ t("Username") }}
          </form-label>
          <form-input
            id="username"
            v-model="search.username"
            class="mt-1"
            name="username"
            type="text"
            @keyup.enter="getUsers()"
          />

          <form-error
            v-if="searchErrors.user_name"
            :message="searchErrors.user_name ? searchErrors.user_name : ''"
          />
        </div>
        <div>
          <form-label for="email">
            {{ t("Email Address") }}
          </form-label>
          <form-input
            id="email"
            v-model="search.businessEmail"
            class="mt-1"
            name="email"
            type="email"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.email"
            :message="searchErrors.email ? searchErrors.email : ''"
          />
        </div>
        <div>
          <form-label for="last-name">
            {{ t("Last Name") }}
          </form-label>
          <form-input
            id="last-name"
            v-model="search.lastName"
            class="mt-1"
            name="last_name"
            type="text"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.last_name"
            :message="searchErrors.last_name ? searchErrors.last_name : ''"
          />
        </div>
        <div>
          <form-label for="first-name">
            {{ t("First Name") }}
          </form-label>
          <form-input
            id="first-name"
            v-model="search.firstName"
            class="mt-1"
            name="first_name"
            type="text"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.first_name"
            :message="searchErrors.first_name ? searchErrors.first_name : ''"
          />
        </div>
        <div>
          <form-label for="status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="status"
            v-model="search.status"
            class="mt-1"
            name="status"
            :items="SearchStatusOption"
            @keyup.enter="getUsers()"
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        v-if="isSearch()"
        class="btn-wide"
        type="button"
        @click="resetSearch()"
      >
        {{ t("Reset") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
        @click="searchFilter()"
      >
        {{ t("Search") }}
      </Button>
    </template>
  </slide-over>

  <user-view
    v-if="showingViewAccountSlideOver"
    :uuid="userAddEditUuid"
    :slideover-visibility="showingViewAccountSlideOver"
    @view-close-slide-over="
      ;(showingViewAccountSlideOver = false), (userAddEditUuid = null)
    "
    @is-edit="
      ;(showingViewAccountSlideOver = false),
        (showingUserAddEditSlideOver = true)
    "
    @tab-1="editModeTab = 'tab1'"
    @tab-2="editModeTab = 'tab2'"
  />

  <user-add-edit
    v-if="showingUserAddEditSlideOver"
    :uuid="userAddEditUuid"
    :tab="editModeTab != '' ? editModeTab : 'tab1'"
    :slideover-visibility="showingUserAddEditSlideOver"
    @edit-close-slide-over="
      ;(showingUserAddEditSlideOver = false), (userAddEditUuid = null)
    "
    @successfully-updated="getUsers(currentPage), (userAddEditUuid = null)"
  />

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>


<script>
import { ref, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue3-loading-overlay'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'
import Search from '@/components/Search.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import UserView from '@/pages/merchant-portal/users/UserView.vue'
import UserAddEdit from '@/pages/merchant-portal/users/UserAddEdit.vue'
import FormError from '@/components/FormError.vue'
import axios from 'axios'
import router from "../../../routes";
import Modal from "@/components/Modal";

export default {
  name: 'Users',

  components: {
    Badge,
    Search,
    Button,
    Loading,
    SvgIcon,
    UserView,
    SlideOver,
    FormInput,
    FormLabel,
    FormSelect,
    FormError,
    PageHeader,
    NoTableData,
    UserAddEdit,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    Modal,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const isClickAddOrEdit = ref(false)
    const isClickView = ref(false)
    const lastPage = ref(0)
    const currentPage = ref(0)
    const perPage = ref('10')
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const searchParam = ref('')
    const searchText = ref('')
    const quickSearchText = ref('')
    const totalRecord = ref(0)
    const editModeTab = ref('')
    const isRestFilterEnable = ref(false)
    const merchantUser = ref('')

    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Search User',
    })
    const statusOption = ref([
      {
        text: 'Active',
        value: 'ACT',
      },
      {
        text: 'Inactive',
        value: 'INA',
      },
    ])
    const SearchStatusOption = ref([
      {
        text: 'Active',
        value: 'ACT',
      },
      {
        text: 'Inactive',
        value: 'INA',
      },
      {
        text: 'Pending',
        value: 'INIT',
      },
    ])
    const showingUserAddEditSlideOver = ref(false)
    const showingViewAccountSlideOver = ref(false)
    const search = reactive({
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      username: null,
      status: null,
    })

    const searchErrors = reactive({
      user_name: null,
      email: null,
      last_name: null,
      first_name: null,
    })

    const searchFreeze = reactive({
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      username: null,
      status: null,
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const users = ref([])
    const userAddEditUuid = ref(null)

    async function getUsers(
      page = 1,
      column = sortColumn.value,
      quickSearch = null
    ) {
        isRestFilterEnable.value = false
        isLoading.value = true
        let searchText = ''
        if (isSearchFreeze()) {
          isRestFilterEnable.value = true
          searchParam.value = '&search='
          searchText = JSON.stringify(searchFreeze)
        }

        if (quickSearch != null) {
          quickSearchText.value = quickSearch
        }

        if (localStorage.getItem('merchant') != ''){
          merchantUser.value = localStorage.getItem('merchant')
        }

        await axios
          .get('v1/merchant-users', {
            params: {
              quickSearch: quickSearchText.value,
              search: searchText,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data != null) {
              users.value = response.data.data.data
              lastPage.value = response.data.data.last_page
              currentPage.value = response.data.data.current_page
              totalRecord.value = response.data.data.total
            } else {
              console.error('Error!')
            }
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
        isLoading.value = false
        slideOver.show = false
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getUsers(1, sortColumn.value)
    }


    function searchFilter() {
      const max = /^.{0,255}$/
      let index = 0

      if (!max.test(search.username)) {
        searchErrors.user_name = 'Maximum length for user name is 255.'
        index++
      }
      if (!max.test(search.businessEmail)) {
        searchErrors.email = 'Maximum length for email is 255.'
        index++
      }
      if (!max.test(search.lastName)) {
        searchErrors.last_name = 'Maximum length for last name is 255.'
        index++
      }
      if (!max.test(search.firstName)) {
        searchErrors.first_name = 'Maximum length for first name is 255.'
        index++
      }

      if (index == 0) {
        searchFreeze.username = search.username
        searchFreeze.lastName = search.lastName
        searchFreeze.firstName = search.firstName
        searchFreeze.businessEmail = search.businessEmail
        searchFreeze.status = search.status
        getUsers();
      }
    }

    function isSearchFreeze() {
      if (
        searchFreeze.businessEmail != null ||
        searchFreeze.username != null ||
        searchFreeze.status != null ||
        searchFreeze.firstName != null ||
        searchFreeze.lastName != null
      ) {
        return true
      } else {
        return false
      }
    }

    function isSearch() {
      if (
        search.businessEmail != null ||
        search.username != null ||
        search.status != null ||
        search.firstName != null ||
        search.lastName != null
      ) {
        return true
      } else {
        return false
      }
    }
    function userAddEdit(uuid) {
      userAddEditUuid.value = uuid
    }

    function resetSearchFilter() {
      searchFreeze.username = null
      searchFreeze.lastName = null
      searchFreeze.firstName = null
      searchFreeze.businessEmail = null
      searchFreeze.status = null
    }

    function resetSearch() {
      search.lastName = null
      search.firstName = null
      search.businessEmail = null
      search.username = null
      search.status = null
      searchErrors.user_name = null
      searchErrors.email = null
      searchErrors.last_name = null
      searchErrors.first_name = null
    }

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('username')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function searchUser() {
      search.username = searchFreeze.username
      search.lastName = searchFreeze.lastName
      search.firstName = searchFreeze.firstName
      search.businessEmail = searchFreeze.businessEmail
      search.status = searchFreeze.status
    }

    function resendRegisterEmail(userData){
      isLoading.value = true
      axios.post('/v1/re-send-register-email', userData).then((response) => {
        if (response.data != null && (response.data.data.errors == null || response.data.data.errors == undefined)) {
          isLoading.value = false
          modal.confirm_button = false
          modal.button_text = 'Ok'
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = 'Please check your email for registration details.'
          modal.show = true
        }else {
          router.push({ name: 'unauthorized-access'})
          isLoading.value = false
          console.error('Error!')
        }
      }) .catch(err => {
        router.push({ name: 'unauthorized-access'})
      })
    }

    function closeAlert() {
      modal.show = false
    }

    return {
      t,
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      showingUserAddEditSlideOver,
      showingViewAccountSlideOver,
      statusOption,
      search,
      users,
      userAddEditUuid,
      userAddEdit,
      searchText,
      searchParam,
      perPage,
      totalRecord,
      lastPage,
      sortColumn,
      getUsers,
      isSearch,
      resetSearch,
      sort,
      isClickAddOrEdit,
      isClickView,
      editModeTab,
      quickSearchText,
      isRestFilterEnable,
      currentPage,
      SearchStatusOption,
      merchantUser,
      searchErrors,
      searchUser,
      resetSearchFilter,
      searchFilter,
      resendRegisterEmail,
      closeAlert,
      modal,
    }
  },
}
</script>
