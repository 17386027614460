<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen px-4 sm:px-6 lg:px-8 py-20 sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 bg-cover bg-no-repeat overflow-hidden lg:overflow-visible"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div class="w-full sm:max-w-3xl">
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div class="mb-14 p-10 sm:p-16 md:px-48 md:py-28 bg-primary-lightest rounded-lg">
          <div>
            <h2
              id="heading-sign_up"
              class="mb-3 text-2xl font-medium text-black"
            >
              Register with opuspay
            </h2>
            <h5 class="text-black text-opacity-70 font-medium">
              Please enter your email address. We will send you a link to
              register.
            </h5>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <div class="mb-3">
                <form-label
                  id="label-email"
                  for="email"
                >
                  Email Address
                </form-label>
                <form-input
                  id="email"
                  v-model="form.email"
                  class="mt-1 border-primary-lighter"
                  name="email"
                  type="text"
                  autocomplete="email"
                  autofocus
                  @keyup.enter="onSubmit"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.email
                      ? form.errors.validations.email[0]
                      : ''
                  "
                />
              </div>

              <div
                ref="recaptcha"
                class="recaptcha-wrapper h-22 sm:h-32 -mb-3 sm:-mb-2 md:-mb-4 origin-top-left transform"
              />

              <Button
                id="button-signup "
                class="w-full relative"
                type="button"
                :disabled="!capchaVerify && disableSubmitButtonValue == false"
                @click="onSubmit(), isClickSubmit = true"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import Button from '@/components/Button.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import Loading from 'vue3-loading-overlay'

export default {
  name: 'SignUp',
  components: {
    Button,
    LogoWhite,
    FormLabel,
    FormError,
    FormInput,
    AuthFooter,
    Modal,
    Loading,
  },

  setup() {
    const form = reactive({
      email: null,
      errors: null,
    })
    let recaptcha = ref('null')
    const fullPage = ref(true)
    const isLoading = ref(false)
    const capchaVerify  = ref(false)
    const isClickSubmit  = ref(false)
    const disableSubmitButtonValue  = ref(false)
    const modal = reactive({
      show: false,
      type: 'success',
      title: 'Success',
      message: 'Please check your email for registration details.',
      close_button: true,
    })
    onMounted(() => {
      if (window.grecaptcha == null) {
        new Promise((resolve) => {
          window.recaptchaReady = function () {
            resolve()
          }

          const doc = window.document
          const scriptId = 'recaptcha-script'
          const scriptTag = doc.createElement('script')
          scriptTag.id = scriptId
          scriptTag.setAttribute(
            'src',
            'https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit'
          )
          doc.head.appendChild(scriptTag)
        }).then(() => {
          renderRecaptcha()
        })
      } else {
        renderRecaptcha()
      }
    })

    function reset() {
      grecaptcha.reset(this.recaptcha)
    }
    function renderRecaptcha() {
      recaptcha = grecaptcha.render(recaptcha.value, {
        sitekey: process.env.MIX_CLIENT_CAPTCHA,
        theme: 'light',
        size: 'normal',
        tabindex: 'normal',
        callback: (response) => recaptchaVerified(response),
        'expired-callback': () => recaptchaExpired(),
        'error-callback': () => recaptchaFailed(),
      })
    }

    function onSubmit() {
      isLoading.value = true
      let SubmitArr = {}
      SubmitArr.email = form.email
      disableSubmitButtonValue.value = false
    if(capchaVerify.value == true){
      capchaVerify.value = false
      axios.post('v1/registration-email', SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          resetForm()
          isClickSubmit.value = false
          modal.show = true
        } else {
          form.errors = response.data.data.errors
          capchaVerify.value = true
        }
      })
    }
      isLoading.value = false
    }
    function recaptchaVerified(response) {
       capchaVerify.value = true;
    }
    function recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    }
    function recaptchaFailed() {
       capchaVerify.value = false;
    }
    function closeAlert() {
      modal.show = false
      disableSubmitButtonValue.value = true
      window.location.reload(true)
    }
    function resetForm() {
      ;(form.email = null), (form.errors = null)
    }
    return {
      form,
      onSubmit,
      modal,
      recaptcha,
      closeAlert,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      fullPage,
      isLoading,
      capchaVerify,
      isClickSubmit,
      disableSubmitButtonValue,
    }
  },
}
</script>

<style scoped>
  .recaptcha-wrapper {
    transform: scale(0.73);
  }

  @screen sm {
    .recaptcha-wrapper {
      transform: scale(1.192);
    }
  }

  @screen 2xl {
    .recaptcha-wrapper {
      transform: scale(1.264);
    }
  }
</style>
