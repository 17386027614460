<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
    class="DarkWhiteLoading"
  />

  <transition
    appear
    name="fade"
  >
    <div
      class="relative min-h-screen sm:flex sm:flex-wrap sm:flex-no-wrap sm:items-center sm:justify-center bg-gray-50 px-4 sm:px-6 lg:px-8 py-8 sm:py-20 2xl:py-16 bg-cover bg-no-repeat"
      style="background-image: url('/images/auth-cover.jpg')"
    >
      <div
        :class="[ tokenExpired ? 'sm:max-w-3xl' : 'sm:max-w-6xl' ]"
        class="w-full"
      >
        <logo-white
          id="logo-white"
          class="inline-block h-14 mb-6"
        />
        <div class="sm:mb-14 p-10 sm:p-16 md:px-44 md:py-14 2xl:py-16 bg-primary-lightest rounded-lg">
          <div class="my-6">
            <div
              v-if="tokenExpired"
              class="w-full xl:w-96 mx-auto xl:ml-10 xl:mr-auto"
            >
              <div>
                <h2 class="mt-6 mb-3 text-2xl font-medium text-black">
                  Unable to activate account
                </h2>
                <h5 class="text-black text-opacity-70 font-medium">
                  Your account activation token has expired.
                </h5>
              </div>

              <div class="mt-8">
                <div class="mt-6">
                  <h5 class="text-black text-opacity-70 font-medium">
                    Send us a message if you need any assistance: <a
                      style="color: #001A66; "
                      href="mailto:support@opusxenta.com"
                    >support@opusxenta.com</a>
                  </h5>
                </div>
              </div>
            </div>

            <form
              v-if="!tokenExpired"
              action="#"
              method="POST"
              @submit.prevent="onSubmit"
            >
              <div>
                <h2
                  id="heading-title"
                  class="mb-4 text-2xl font-medium text-black"
                >
                  Hello! Welcome to opuspay
                </h2>

                <div class="mb-10 font-medium text-lg text-gray-600">
                  Enter your username and password to activate your account
                </div>

                <div class="grid lg:grid-cols-2 gap-4">
                  <div>
                    <form-label
                      id="label-last_name"
                      for="last_name"
                    >
                      Last Name<required />
                    </form-label>
                    <form-input
                      id="last_name"
                      v-model="form.lastName"
                      class="mt-1 border-primary-lighter"
                      name="last_name"
                      type="text"
                      autofocus
                      required
                      @keypress.enter="onSubmit"
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.last_name
                          ? form.errors.validations.last_name[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-first_name"
                      for="first_name"
                    >
                      First Name<required />
                    </form-label>
                    <form-input
                      id="first_name"
                      v-model="form.firstName"
                      class="mt-1 border-primary-lighter"
                      name="first_name"
                      type="text"
                      required
                      @keypress.enter="onSubmit"
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.first_name
                          ? form.errors.validations.first_name[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-phone"
                      for="phone"
                    >
                      Phone Number<required />
                    </form-label>
                    <form-input
                      id="phone"
                      v-model="form.phoneNumber"
                      class="mt-1 border-primary-lighter"
                      name="phone"
                      type="text"
                      required
                      @keypress.enter="onSubmit"
                    />
                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.phone_number
                          ? form.errors.validations.phone_number[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-username"
                      for="username"
                    >
                      Username<required />
                    </form-label>
                    <form-input
                      id="username"
                      v-model="form.username"
                      class="mt-1 border-primary-lighter"
                      name="username"
                      type="text"
                      required
                      @keypress.enter="onSubmit"
                    />

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.username
                          ? form.errors.validations.username[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="label-password"
                      for="password"
                    >
                      Password<required />
                    </form-label>
                    <div class="mt-1 relative">
                      <form-input
                        id="password"
                        v-model="form.password"
                        class="pr-10 border-primary-lighter"
                        name="password"
                        :type="form.passwordFieldType"
                        required
                        @keypress.enter="onSubmit"
                      />
                      <button
                        class="
                          absolute
                          w-7
                          h-7
                          inset-y-0
                          right-0
                          flex
                          items-center
                          justify-center
                          my-auto
                          mr-1
                          p-1
                          rounded-sm
                          opacity-75
                          hover:opacity-100
                          focus:ring-2 focus:ring-offset-1 focus:ring-primary
                          focus:outline-none
                          z-10
                        "
                        type="button"
                        @click="switchPasswordVisibility"
                      >
                        <svg-icon
                          v-if="form.passwordFieldType === 'text'"
                          class="w-5 h-5 text-gray-900"
                          icon="visible"
                        />
                        <svg-icon
                          v-if="form.passwordFieldType === 'password'"
                          class="w-5 h-5 text-gray-900"
                          icon="invisible"
                        />
                      </button>
                    </div>

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.password
                          ? form.errors.validations.password[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label
                      id="confirm-password"
                      for="confirm_password"
                    >
                      Confirm Password<required />
                    </form-label>
                    <div class="mt-1 relative">
                      <form-input
                        id="confirm_password"
                        v-model="form.confirmPassword"
                        class="pr-10 border-primary-lighter"
                        name="confirm_password"
                        :type="form.passwordFieldType"
                        required
                        @keypress.enter="onSubmit"
                      />
                      <button
                        class="
                          absolute
                          w-7
                          h-7
                          inset-y-0
                          right-0
                          flex
                          items-center
                          justify-center
                          my-auto
                          mr-1
                          p-1
                          rounded-sm
                          opacity-75
                          hover:opacity-100
                          focus:ring-2 focus:ring-offset-1 focus:ring-primary
                          focus:outline-none
                          z-10
                        "
                        type="button"
                        @click="switchPasswordVisibility"
                      >
                        <svg-icon
                          v-if="form.passwordFieldType === 'text'"
                          class="w-5 h-5 text-gray-900"
                          icon="visible"
                        />
                        <svg-icon
                          v-if="form.passwordFieldType === 'password'"
                          class="w-5 h-5 text-gray-900"
                          icon="invisible"
                        />
                      </button>
                    </div>

                    <form-error
                      v-if="form.errors"
                      :message="
                        form.errors.validations.confirm_password
                          ? form.errors.validations.confirm_password[0]
                          : ''
                      "
                    />
                  </div>

                  <div>
                    <form-label>Business Email Address</form-label>
                    <div class="mt-2 break-words">
                      {{ form.businessEmail }}
                    </div>
                  </div>

                  <div>
                    <form-label>Security Profile</form-label>
                    <div class="mt-2 break-words">
                      {{ form.securityProfile }}
                    </div>
                  </div>

                  <form-error
                    v-if="form.errors"
                    class="col-span-2"
                    :message="
                      form.errors.validations.token
                        ? form.errors.validations.token[0]
                        : ''
                    "
                  />

                  <div class="hidden lg:block">
                    <!-- Dummy Grid -->
                  </div>

                  <div>
                    <Button
                      id="button-activate"
                      class="w-full"
                      type="button"
                      @click="onSubmit"
                    >
                      Activate
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <auth-footer />
    </div>
  </transition>
</template>

<script>
import { ref, reactive } from 'vue'
import Loading from 'vue3-loading-overlay'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import LogoWhite from '@/components/LogoWhite.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import AuthFooter from '@/components/auth/AuthFooter.vue'
import axios from 'axios'
import { useRoute } from 'vue-router'
import router from '@/routes'

export default {
  name: 'AccountActivate',

  components: {
    Button,
    Loading,
    SvgIcon,
    Required,
    LogoWhite,
    FormError,
    FormInput,
    FormLabel,
    AuthFooter,
  },

  setup() {
    const isLoading = ref(false)
    const fullPage = ref(true)
    const tokenExpired = ref(false)
    const route = useRoute()
    const form = reactive({
      id: null,
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      securityProfile_id: null,
      username: null,
      password: null,
      confirmPassword: null,
      status: null,
      language: null,
      type: null,
      rowVersion: null,
      errors: '',
      passwordFieldType: 'password',
    })
    logout()
    getUserRegisterByToken()

    function getUserRegisterByToken() {
      isLoading.value = true
      const token = route.params.token
      axios.get('/v1/user-register/' + token).then((response) => {
        if (response.data.data != null) {
          isLoading.value = false
          form.lastName = response.data.data.last_name
          form.firstName = response.data.data.first_name
          form.phoneNumber = response.data.data.phone_number
          form.businessEmail = response.data.data.business_email
          form.type = response.data.data.user_type
          form.securityProfile =
            response.data.data.security_profile.sec_profile_name
          form.securityProfile_id =
            response.data.data.security_profile.sec_profile_id
        } else {
          tokenExpired.value = true
          isLoading.value = false
          console.error('Error!')
        }
      })
    }

    function onSubmit() {
      let SubmitArr = {}
      let url = ''
      SubmitArr.user_id = form.id
      SubmitArr.last_name = form.lastName
      SubmitArr.first_name = form.firstName
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.sec_profile_id = form.securityProfile_id
      SubmitArr.username = form.username
      SubmitArr.password = form.password
      SubmitArr.confirm_password = form.confirmPassword
      SubmitArr.token = route.params.token
      SubmitArr.row_version = form.rowVersion

      if (form.type == 'TNT') {
        url = '/v1/new-user'
      } else {
        url = '/v1/merchant-new-user'
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          router.push({ name: 'signin' })
        } else {
          form.errors = response.data.data.errors
        }
      })
    }

    async function switchPasswordVisibility() {
      form.passwordFieldType =
        form.passwordFieldType === 'password' ? 'text' : 'password'
    }

    async function logout() {
      if (localStorage.getItem('access_token')) {
        await axios.post('logout').then((response) => {
          localStorage.removeItem('access_token')
        })
      }
    }

    return {
      fullPage,
      isLoading,
      form,
      onSubmit,
      tokenExpired,
      switchPasswordVisibility,
    }
  },
}
</script>
