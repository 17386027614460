<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 343.566 62.923"
  >
    <g transform="translate(0 0.02)">
      <g
        data-name="Group 694"
        transform="translate(65.489 19.606)"
        opacity="0.8"
      >
        <path
          data-name="Path 371"
          d="M77.67,61.67A14.754,14.754,0,1,1,92.424,76.178,14.472,14.472,0,0,1,77.67,61.67Z"
          transform="translate(-77.668 -46.67)"
          fill="#ffffff"
        />
      </g>
      <g
        data-name="Group 695"
        transform="translate(0 5.645)"
        opacity="0.8"
      >
        <path
          data-name="Path 372"
          d="M5.76,53.07A21.685,21.685,0,0,1,27.836,31.34c12.359,0,22.14,9.545,22.14,21.73S40.24,74.809,27.836,74.809A21.658,21.658,0,0,1,5.76,53.07Z"
          transform="translate(-5.76 -31.34)"
          fill="#ffffff"
        />
      </g>
      <g
        data-name="Group 696"
        transform="translate(30.473 -0.02)"
        opacity="0.8"
      >
        <path
          data-name="Path 373"
          d="M39.22,47.888A22.736,22.736,0,0,1,62.371,25.12a22.771,22.771,0,1,1,0,45.537A22.747,22.747,0,0,1,39.22,47.888Z"
          transform="translate(-39.22 -25.12)"
          fill="#ffffff"
        />
      </g>
      <path
        data-name="Path 374"
        d="M125.48,60.243a15.127,15.127,0,1,1,15.127,14.982A14.872,14.872,0,0,1,125.48,60.243Zm27.076,0a11.84,11.84,0,1,0-3.421,8.565A11.922,11.922,0,0,0,152.556,60.243Z"
        transform="translate(-16.447 -26.912)"
        fill="#ffffff"
      />
      <path
        data-name="Path 375"
        d="M197.376,60.277a14.863,14.863,0,0,1-14.845,14.982,13.916,13.916,0,0,1-11.885-6.375V89.83H167.54V46.279l2.978-.729v6.375a13.934,13.934,0,0,1,12.013-6.576,14.854,14.854,0,0,1,14.845,14.927Zm-3.169,0a11.84,11.84,0,1,0-3.381,8.524,11.885,11.885,0,0,0,3.381-8.524Z"
        transform="translate(-20.201 -26.946)"
        fill="#ffffff"
      />
      <path
        data-name="Path 376"
        d="M232.524,45.57V74.714h-2.969V69.932a11.22,11.22,0,0,1-9.9,5.346c-6.475,0-11.166-4.29-11.166-12.277V46.3l3.106-.729V62.737c0,6.375,3.57,9.572,8.452,9.572,5.464,0,9.381-3.962,9.381-10.1V46.3Z"
        transform="translate(-23.856 -26.965)"
        fill="#ffffff"
      />
      <path
        data-name="Path 377"
        d="M244,72.025l.592-2.969c2.732,2.177,5.464,3.433,8.388,3.433,3.5,0,5.81-2.186,5.81-5.355,0-2.5-1.585-4.353-5.883-5.738l-1.056-.4c-4.681-1.585-6.867-4.417-6.867-8.115,0-4.426,3.506-7.532,8.26-7.532a15.1,15.1,0,0,1,7.459,2.177l-.592,2.905a12.75,12.75,0,0,0-6.867-2.3c-3.169,0-5.282,1.913-5.282,4.681,0,2.313,1.248,4.035,5.346,5.464l1.12.328c4.818,1.585,7.4,4.554,7.4,8.579,0,4.754-3.561,8.124-8.843,8.124A15.1,15.1,0,0,1,244,72.025Z"
        transform="translate(-27.026 -26.946)"
        fill="#ffffff"
      />
      <path
        data-name="Path 378"
        d="M303.4,60.1a15.064,15.064,0,0,1-14.991,15.182,13.661,13.661,0,0,1-10.692-4.945V89.81H273.09V45.976l4.362-.865V50.2a13.661,13.661,0,0,1,10.929-5.282A15.064,15.064,0,0,1,303.4,60.1Zm-4.754,0a10.647,10.647,0,1,0-3.01,7.7,10.637,10.637,0,0,0,3.01-7.7Z"
        transform="translate(-29.623 -26.907)"
        fill="#ffffff"
      />
      <path
        data-name="Path 379"
        d="M380.1,45.64,358.243,89.874H353.1l9.772-19.207L350.52,45.64h5.146l9.645,20.209,9.636-20.209Z"
        transform="translate(-36.534 -26.972)"
        fill="#ffffff"
      />
      <path
        data-name="Path 380"
        d="M338.1,50.207c-.228-.3-.483-.574-.729-.847l-.383-.41c-.237-.237-.492-.464-.738-.683s-.246-.209-.364-.319c-.291-.228-.6-.446-.911-.656l-.3-.219c-.355-.219-.729-.428-1.1-.619l-.219-.118a13.792,13.792,0,0,0-1.311-.556h-.109a14.271,14.271,0,0,0-4.8-.811,15.182,15.182,0,0,0,0,30.364,14.5,14.5,0,0,0,4.8-.811l.109-.046a13.187,13.187,0,0,0,1.311-.556l.219-.118c.373-.2.747-.4,1.1-.628l.3-.209c.31-.209.619-.428.911-.665l.364-.319c.246-.219.5-.437.738-.674l.383-.419c.246-.273.5-.546.729-.838v5l4.353-.856V45.963L338.1,45.1Zm.064,9.9a10.628,10.628,0,0,1-21.257,0h0a10.628,10.628,0,0,1,21.257,0Z"
        transform="translate(-33.091 -26.912)"
        fill="#ffffff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoWhite',
}
</script>
