<template>
  <button
    ref="btnPrimary"
    class="btn-primary flex items-center justify-center"
    @click="btnClick"
  >
    <slot />
  </button>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Button',

  setup() {
    const btnPrimary = ref(null)

    function btnClick() {
      btnPrimary.value.blur()
    }

    return {
      btnClick,
      btnPrimary,
    }
  }
}
</script>

<style>
.btn-primary {
  @apply border-2 border-transparent;
  @apply px-5;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  @apply rounded;
  @apply shadow-sm;
  @apply text-sm;
  @apply font-semibold;
  @apply leading-none;
  @apply uppercase;
  @apply text-white;
  @apply bg-primary;
  @apply hover:bg-primary-light;
  @apply hover:text-white;
  @apply focus:bg-primary;
  @apply focus:text-white;
  @apply focus:outline-none;
  @apply focus:ring-2 focus:ring-offset-2 focus:ring-primary;
  @apply transition;
  @apply duration-200;
  @apply ease-in-out;
}

.btn-primary[disabled]:hover {
  @apply bg-primary;
  @apply text-white;
}

.btn-primary .btn-icon {
  margin-top: -2px;
  margin-bottom: -2px;
}

.btn-primary-light {
  @apply bg-primary-light;
  @apply hover:bg-primary;
}

.btn-primary-light[disabled]:hover {
  @apply bg-primary-light;
  @apply text-white;
}

.btn-danger {
  @apply border-red-500;
  @apply bg-red-500;
  @apply text-white;
  @apply hover:border-red-600;
  @apply hover:bg-red-600;
  @apply focus:border-red-600;
  @apply focus:bg-red-600;
  @apply focus:ring-red-600;
}

.btn-danger[disabled]:hover {
  @apply bg-red-500;
  @apply text-white;
}

.btn-default {
  @apply px-2.5;
}

.btn-primary.btn-xs {
  @apply px-2;
  @apply py-1;
  @apply uppercase;
}

.btn-lg {
  @apply py-3;
}

.btn-wide {
  min-width: 8.6rem;
}
.btn-edit{
  min-width: 13rem !important;
}

@screen 2xl {
  .btn-primary {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
}
</style>
