<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver=";(slideOver.show = false), closeView()"
  >
    <template #slideover-body>
      <div class="tab-wizard-group">
        <div class="tab-wizard-wrapper">
          <nav
            class="tab-wizard"
            aria-label="Tabs"
          >
            <button
              v-for="(tabItem, index) in accountTabItems"
              :key="index"
              class="tab-wizard-item"
              :class="{ active: activeTabId == tabItem.id }"
              type="button"
              @click="showTabContent('activeTabId', tabItem.id)"
            >
              {{ t(tabItem.name) }}
            </button>
          </nav>
        </div>

        <div class="tab-wizard-container">
          <div v-if="activeTabId == 'tab1'">
            <div class="grid md:grid-cols-2 gap-4 break-words">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Last Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.lastName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("First Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.firstName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Username") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.username }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Status") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  <badge
                    v-if="displayValue.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="displayValue.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                  <badge
                    v-if="displayValue.status == 'INIT'"
                    type="warning"
                    text="Pending"
                  />
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Business Email Address") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.businessEmail }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Phone Number") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.phoneNumber }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Security Profile") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.securityProfile }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Default Language") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ displayValue.language }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTabId == 'tab2'">
            <div
              v-if="merchantUser == null "
              class="mb-4 flex items-center justify-between"
            >
              <label
                class="text-sm font-medium text-gray-500"
              >{{ t("Select All Accounts") }}</label>

              <Switch
                v-model="selectAllAccounts"
                :class="selectAllAccounts ? 'enabled' : ''"
                class="switch"
                :disabled="true"
              >
                <span class="sr-only" />
                <span class="switch-dot" />
              </Switch>
            </div>

            <div
              v-if="false"
              class="mb-4 grid grid-cols-2 gap-y-3"
            >
              <div class="flex items-center">
                <span class="text-sm">{{ t("Show") }}</span>
                <div class="mx-2">
                  <form-select
                    id="results_per_page"
                    class="py-2"
                    name="results_per_page"
                  />
                </div>
                <span class="text-sm">{{ t("entries") }}</span>
              </div>

              <div class="w-full flex justify-end">
                <div class="w-full lg:w-4/6">
                  <search />
                </div>
              </div>
            </div>

            <TableDefault>
              <template #table>
                <table
                  class="table table-clickable"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Accounts") }}</span>
                          <button
                            class="btn_th-sort"
                            type="button"
                            @click="sort('mch_acc_name')"
                          >
                            <svg-icon
                              v-if="sortColumnDirection &&
                                sortColumn == 'mch_acc_name'"
                              class="icon_th-sort"
                              icon="th-caret-up"
                            />
                            <svg-icon
                              v-else
                              class="icon_th-sort"
                              icon="th-caret-down"
                            />
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        class="th-action"
                      >
                        {{ t("Actions") }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="merchantAccount in merchantAccountOption"
                      :key="merchantAccount.key"
                    >
                      <td>{{ merchantAccount.mch_acc_name }}</td>
                      <td>
                        <div class="flex items-center justify-center space-x-5">
                          <Switch
                            :class="
                              setMerchantAccountCheck(
                                merchantAccount.mch_acc_id
                              )
                                ? 'enabled'
                                : ''
                            "
                            class="switch"
                            :disabled="true"
                          >
                            <span class="sr-only" />
                            <span class="switch-dot" />
                          </Switch>
                        </div>
                      </td>
                    </tr>

                    <no-table-data v-if="!merchantAccountOption.length" />
                  </tbody>
                </table>
              </template>
            </TableDefault>

            <vue-pagination v-if="false" />
          </div>
        </div>
      </div>
      <modal
        :type="modal.type"
        :show="modal.show"
        :title="modal.title"
        :message="modal.message"
        :close-button="modal.close_button"
        :confirm-button="modal.confirm_button"
        :button-text="modal.button_text"
        @closeModal="closeAlert"
      />
    </template>

    <template #slideover-actions>
      <Button
        v-if="displayValue.status == 'INIT'"
        class="btn-wide"
        type="button"
        @click="registerEmail(allUserData)"
      >
        {{ t("Registration Email") }}
      </Button>
      <Button
        v-if="merchantUser == null "
        class="btn-wide"
        type="button"
        :disabled="displayValue.status == 'INIT'"
        @click="activeEditMode()"
      >
        {{ t("Edit") }}
      </Button>
    </template>
  </slide-over>
</template>


<script>
import { ref, reactive, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { Switch } from '@headlessui/vue'
import Badge from '@/components/Badge.vue'
import Button from '@/components/Button.vue'
import Search from '@/components/Search.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormSelect from '@/components/FormSelect.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import axios from 'axios'
import Loading from 'vue3-loading-overlay'
import Modal from "@/components/Modal";

export default {
  name: 'UserView',

  components: {
    Badge,
    Switch,
    Search,
    Button,
    SvgIcon,
    SlideOver,
    FormSelect,
    NoTableData,
    TableDefault,
    VuePagination,
    Loading,
    Modal,
  },

  props: {
    uuid: {
      type: String,
      default: null,
    },
    slideoverVisibility: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['isEdit', 'tab1', 'tab2', 'viewCloseSlideOver'],

  setup(props, { emit }) {
    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: 'View User',
    })

    const accountTabItems = reactive([
      { id: 'tab1', name: 'User Details' },
      { id: 'tab2', name: 'Merchant Account Access' },
    ])
    const { t } = useI18n()
    const isLoading = ref(false)
    const fullPage = ref(true)
    const sortValue = ref(false)
    const activeTabId = ref(accountTabItems[0].id)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const selectAllAccounts = ref(false)
    const editPage = ref(false)
    const merchantUser = ref('')
    const merchantAccountOption = ref([
      { value: '', text: 'Please select an option' },
    ])
    const merchantAccountList = ref([])
    const allUserData = ref([])

    const displayValue = reactive({
      id: null,
      lastName: null,
      firstName: null,
      username: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      securityProfileId: null,
      status: null,
      language: null,
      languageId: null,
      rowVersion: null,
      assignAccount: ref(false),
      assignAccounts: [],
      errors: '',
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    watchEffect(() => {
      if(sortValue.value == false){
        activeTabId.value = 'tab1'
        displayValue.id = props.uuid
        if (displayValue.id != null) {
          getMerchantAccounts()
          setEditUser(displayValue.id)
        } else {
          resetFrom()
        }
        if (props.slideoverVisibility) {
          slideOver.show = true
        }
      }

    })

    function getMerchantAccounts() {
      isLoading.value = true
      axios.get('/v1/get-merchant-accounts-by-merchant-id', {
        params: {
          sortColumn: sortColumn.value,
          sortDirection: sortColumnDirection.value,
        },
      }).then((response) => {
        if (response.data != null) {
          merchantAccountOption.value = response.data.data
          response.data.data.find((merchantAccount) => {
            merchantAccountList.value.push(merchantAccount.mch_acc_id)
          })
        } else {
          console.error('Error!')
        }
        isLoading.value = false
      })
    }

    async function setEditUser(registerId) {
      isLoading.value = true
      if (localStorage.getItem('merchant') != ''){
        merchantUser.value = localStorage.getItem('merchant')
      }
      resetFrom()
      await axios.get('v1/user/' + registerId).then((response) => {
        if (response.data.data != null) {
          let user = response.data.data
          allUserData.value = response.data.data
          displayValue.lastName = user.last_name
          displayValue.firstName = user.first_name
          displayValue.businessEmail = user.business_email
          displayValue.phoneNumber = user.phone_number
          displayValue.securityProfile = user.security_profile.sec_profile_name
          displayValue.securityProfileId = user.security_profile.sec_profile_id
          displayValue.username = user.username
          displayValue.status = user.status
          displayValue.language = user.language.language_code
          displayValue.languageId = user.language.tnt_lang_id
          user.assign_accounts.forEach((account) => {
            if (account.status == 'ACT') {
              displayValue.assignAccounts.push(account.mch_acc_id)
            }
            setFormAssignAccount()
          })
          if(user.assign_accounts.length == 0){
            getAssignAccounts(user.reg_id);
          }
        }
        isLoading.value = false
      })
    }

    function setFormAssignAccount() {
      isLoading.value = true
      let _arr1 = displayValue.assignAccounts.sort()
      let _arr2 = merchantAccountList.value.sort()

      if (
        Array.isArray(_arr1) &&
        Array.isArray(_arr2) &&
        _arr1.length === _arr2.length
      ) {
        selectAllAccounts.value = true
      }

      if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
      ) {
        selectAllAccounts.value = false
      }
      isLoading.value = false
    }

    function setMerchantAccountCheck(merchantAccountId) {
      if (displayValue.assignAccounts.includes(parseInt(merchantAccountId))) {

        return true
      }
    }

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    function closeView() {
      emit('viewCloseSlideOver')
    }

    function activeEditMode() {
      if (activeTabId.value == 'tab1') {
        emit('tab1')
      } else {
        emit('tab2')
      }
      emit('isEdit')
    }

    function resetFrom() {
      selectAllAccounts.value = false
      displayValue.id = null
      displayValue.lastName = null
      displayValue.firstName = null
      displayValue.businessEmail = null
      displayValue.phoneNumber = null
      displayValue.securityProfile = null
      displayValue.securityProfileId = null
      displayValue.username = null
      displayValue.status = null
      displayValue.language = null
      displayValue.languageId = null
      displayValue.rowVersion = null
      displayValue.assignAccount = false
      displayValue.assignAccounts = []
      displayValue.errors = null
    }

    async function sort(column) {
      sortValue.value = true
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sortColumn.value = column
      await getMerchantAccounts()
    }

    function registerEmail(userData){
      isLoading.value = true
      userData.uuid = userData.user_uuid
      userData.phone_no = userData.phone_number

      axios.post('/v1/re-send-register-email', userData).then((response) => {
        if (response.data != null) {
          isLoading.value = false
          modal.confirm_button = false
          modal.button_text = 'Ok'
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = 'Please check your email for registration details.'
          modal.show = true
        }else {
          isLoading.value = false
          console.error('Error!')
        }
      })
    }

    function closeAlert() {
      modal.show = false
    }

    function getAssignAccounts(regId) {
      axios.get('/v1/get-assigned-accounts-for-pending-users/' + regId).then((response) => {
        let user = response.data.data
        user.forEach((account) => {
          if (account.status == 'ACT') {
            displayValue.assignAccounts.push(account.mch_acc_id)
          }
          setFormAssignAccount()
        })
      })
    }

    return {
      t,
      slideOver,
      activeTabId,
      showTabContent,
      accountTabItems,
      selectAllAccounts,
      sortColumnDirection,
      sortColumn,
      displayValue,
      setMerchantAccountCheck,
      merchantAccountOption,
      editPage,
      activeEditMode,
      closeView,
      isLoading,
      fullPage,
      merchantUser,
      sort,
      registerEmail,
      allUserData,
      closeAlert,
      modal,
    }
  },
}
</script>
