<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />
  <div class="mb-4 grid grid-cols-2 gap-y-3">
    <div class="flex items-center">
      <span class="text-sm">{{ t("Show") }} </span>
      <div class="mx-2">
        <form-select
          id="results_per_page"
          v-model="perPage"
          class="py-2"
          name="results_per_page"
          :items="[10, 25, 50, 100]"
          @onChangeFunction="getAllPaymentDevices($event)"
        />
      </div>
      <span class="text-sm">{{ t("entries") }} </span>
    </div>

    <div class="w-full flex items-center justify-end space-x-2">
      <Button
        v-if="actionBtn == 'edit'"
        type="button"
        class="whitespace-nowrap"
        :disabled="showAddNewPaymentDeviceRow == true"
        @click="showAddNewPaymentDeviceRowButton()"
      >
        {{ t("Add Row") }}
      </Button>
    </div>
  </div>

  <TableDefault>
    <template #table>
      <table
        class="table"
        aria-describedby=""
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="flex items-center">
                <span> {{ t("Device Type") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('paymentDevice')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'paymentDevice'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center">
                <span> {{ t("Details") }} </span>
              </div>
            </th>
            <th scope="col">
              <div class="flex items-center justify-center">
                <span> {{ t("Status") }} </span>
                <button
                  class="btn_th-sort"
                  type="button"
                  @click="sort('status')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' && sortColumn == 'status'
                    "
                    class="icon_th-sort"
                    icon="th-caret-up"
                  />
                  <svg-icon
                    v-else
                    class="icon_th-sort"
                    icon="th-caret-down"
                  />
                </button>
              </div>
            </th>
            <th
              scope="col"
              class="th-action"
            >
              {{ t("Actions") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-if="showAddNewPaymentDeviceRow"
            class="td-align-top"
          >
            <td>
              <form-input
                v-model="paymentDeviceForm.paymentDevice"
                class="py-1.5"
                name="device_type"
                type="text"
              />
              <form-error
                v-if="errors.gw_pd_type"
                :message="errors.gw_pd_type ? errors.gw_pd_type : ''"
              />
            </td>
            <td>
              <form-input
                v-model="paymentDeviceForm.details"
                class="py-1.5"
                name="device_details"
                type="text"
                required
              />
              <form-error
                v-if="errors.description"
                :message="errors.description ? errors.description : ''"
              />
            </td>
            <td>
              <form-select
                v-model="paymentDeviceForm.status"
                class="py-1.5"
                name="status"
                :items="statusOptions"
              />
              <form-error
                v-if="errors.status"
                :message="errors.status ? errors.status : ''"
              />
            </td>
            <td>
              <div class="td-cell">
                <div class="flex items-center justify-center space-x-5">
                  <button-circle
                    type="success"
                    icon="check"
                    @click="addGatewayPaymentDevice()"
                  />
                  <button-circle
                    type="danger"
                    icon="trash"
                    @click="showAddNewPaymentDeviceRow = false"
                  />
                </div>
              </div>
            </td>
          </tr>

          <tr
            v-for="(device, index) in paymentDevices"
            :id="'gateway_payment_devices_' + index"
            :key="index"
            name="fade"
          >
            <td class="max-w-xs">
              <span>{{ device.gw_pd_type }}</span>
            </td>
            <td class="max-w-xs">
              <span>{{ device.description }}</span>
            </td>
            <td>
              <div class="text-center">
                <badge
                  v-if="device.status === 'ACT'"
                  type="success"
                  text="Active"
                />
                <badge
                  v-if="device.status === 'INA'"
                  type="danger"
                  text="Inactive"
                />
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center space-x-5">
                <button-circle
                  v-if="device.merchant_payment_device == null"
                  type="danger"
                  icon="trash"
                  :disabled="
                    showAddNewPaymentDeviceRow == true ||
                      actionBtn != 'edit' ||
                      device.merchant_payment_device != null
                  "
                  @click="deleteGatewayPaymentDeviceModal(device.gw_pd_id)"
                />
                <button-circle
                  v-else
                  type="danger"
                  icon="trash"
                  disabled="true"
                />
              </div>
            </td>
          </tr>

          <no-table-data v-if="!paymentDevices.length" />
        </tbody>
      </table>
    </template>
  </TableDefault>
  <vue-pagination
    :page-size="perPage"
    :total-records="totalRecord"
    :sort-column="sortColumn"
    :sort-direction="sortColumnDirection"
    :last-page="lastPage"
    @page-changed="getAllPaymentDevices($event)"
  />
  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
    @confirmModal="confirmAlert"
  />
</template>

<script>
import { ref, reactive, watchEffect } from 'vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormError from '@/components/FormError.vue'
import FormSelect from '@/components/FormSelect.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import axios from 'axios'
import Modal from '@/components/Modal.vue'
import FormInput from '../../FormInput'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import router from "../../../routes";
import { useI18n } from 'vue-i18n'

export default {
  name: 'PaymentDevices',

  components: {
    Button,
    SvgIcon,
    FormError,
    FormInput,
    FormSelect,
    NoTableData,
    ButtonCircle,
    TableDefault,
    Modal,
    Badge,
    VuePagination,
    Loading,
  },
  props: {
    gatewayId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: 'view',
    },
  },

  setup(props) {
    const { t, te, tm } = useI18n()
    const sortColumnDirection = ref('desc')
    let onchange = ref(true)
    const gatewayID = ref(props.gatewayId)

    const paymentDeviceOptions = ref([
      { value: null, text: 'Please select an option' },
    ])
    const actionBtn = ref(props.action)
    const isLoading = ref(false)
    const fullPage = ref(true)
    const sortColumn = ref('')
    const lastPage = ref(0)
    const totalRecord = ref(0)
    const currentPage = ref(0)
    const perPage = ref(10)
    const paymentDevices = ref(0)
    const idToDeleteGatewayPaymentDevice = ref(null)
    const showAddNewPaymentDeviceRow = ref(false)

    const statusOptions = reactive([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])
    watchEffect(() => {
      if (props.action != actionBtn.value) {
        actionBtn.value = props.action
      }
    })
    const modal = reactive({
      type: '',
      show: false,
      title: '',
      message: '',
      close_button: true,
    })
    getAllPaymentDeviceOptions()
    getAllPaymentDevices()

    const slideOver = reactive({
      show: false,
      size: 'max-w-2xl',
      close_button: false,
      title: 'Edit Gateway',
    })

    const paymentDeviceForm = reactive({
      paymentDevice: null,
      details: null,
      status: 'ACT',
    })

    const errors = reactive({
      gw_pd_type: null,
      description: null,
      status: null,
    })

    const paymentMethodErrors = reactive({
      tnt_pm_id: null,
    })

    function getAllPaymentDevices(page = 1, column = sortColumn.value) {
      isLoading.value = true
      axios
        .get('/v1/gateway/payment-device/get-all/' + gatewayID.value, {
          params: {
            count_per_page: perPage.value,
            page: page,
            sortColumn: column,
            sortDirection: sortColumnDirection.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            paymentDevices.value = response.data.data.data
            lastPage.value = response.data.data.last_page
            totalRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
            isLoading.value = false
          }
        })
    }

    function deleteGatewayPaymentDeviceModal(gateway_payment_device_id) {
      idToDeleteGatewayPaymentDevice.value = gateway_payment_device_id
      modal.type = 'warning'
      modal.title = 'Warning'
      modal.confirm_button = true
      modal.button_text = 'No'
      modal.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modal.show = true
    }

    function closeAlert() {
      modal.show = false
      if (modal.button_text !== 'No') {
        modal.show = false
      }
    }

    function deleteGatewayPaymentDevice() {
      let data  = {}
      data.gw_pd_type_id = idToDeleteGatewayPaymentDevice.value
      data.gw_id = gatewayID.value
      axios
        .delete(
          '/v1/gateway/payment-device/delete/' +
            idToDeleteGatewayPaymentDevice.value
          , {params : data}
        )
        .then((response) => {
          if (response.data.data != null && response.data.status == true) {
            getAllPaymentDevices(currentPage.value)
            resetErrors()
          }
          else {
            router.push({ name: 'unauthorized-access'})
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function confirmAlert() {
      if (idToDeleteGatewayPaymentDevice.value != null) {
        deleteGatewayPaymentDevice()
        resetPaymentDeviceForm()
      }
      modal.show = false
    }

    function resetPaymentDeviceForm() {
      paymentDeviceForm.paymentDevice = null
      paymentDeviceForm.details = null
      paymentDeviceForm.status = null
      idToDeleteGatewayPaymentDevice.value = null
    }

    async function getAllPaymentDeviceOptions() {
      isLoading.value = true
      await axios
        .get('v1/get-all-dropdown-option/payment_device_type')
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.options.data.find((paymentDevice) => {
              let obj = {}
              obj.text = paymentDevice.description
              obj.value = paymentDevice.gw_pd_type
              paymentDeviceOptions.value.push(obj)
            })
          }
        })
      isLoading.value = false
    }

    function addGatewayPaymentDevice() {
      isLoading.value = true
      let form = {}
      form.gw_id = gatewayID.value
      form.gw_pd_type = paymentDeviceForm.paymentDevice
      form.description = paymentDeviceForm.details
      form.status = paymentDeviceForm.status
      resetErrors()
      axios.post('v1/gateway/payment-device/create', form).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          isLoading.value = false
          resetPaymentDeviceForm()
          showAddNewPaymentDeviceRow.value = false
          getAllPaymentDevices()
        } else {
          isLoading.value = false
          bindValidationErrors(response.data.data.errors.validations)
        }
      })
    }

    function bindValidationErrors(validationData) {
      for (var prop in errors) {
        if (validationData[prop]) {
          errors[prop] = validationData[prop][0]
        }
      }
    }

    function resetErrors() {
      errors.gw_pd_type = null
      errors.description = null
      errors.status = null
    }

    async function sort(column) {
      isLoading.value = true
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      getAllPaymentDevices(1, sortColumn.value)
    }

    function showAddNewPaymentDeviceRowButton() {
      if (showAddNewPaymentDeviceRow.value == false) {
        errors.gw_pd_type = ''
        errors.description = ''
        errors.status = ''
        paymentDeviceForm.paymentDevice = null
        paymentDeviceForm.details = null
        paymentDeviceForm.status = 'ACT'
        showAddNewPaymentDeviceRow.value = true
      } else {
        showAddNewPaymentDeviceRow.value = false
      }
    }

    return {
      onchange,
      slideOver,
      sortColumnDirection,
      gatewayID,
      errors,
      getAllPaymentDevices,
      isLoading,
      fullPage,
      perPage,
      totalRecord,
      sortColumn,
      lastPage,
      deleteGatewayPaymentDeviceModal,
      modal,
      closeAlert,
      confirmAlert,
      paymentDeviceForm,
      getAllPaymentDeviceOptions,
      paymentDeviceOptions,
      addGatewayPaymentDevice,
      paymentMethodErrors,
      sort,
      showAddNewPaymentDeviceRow,
      showAddNewPaymentDeviceRowButton,
      paymentDevices,
      statusOptions,
      actionBtn,
      t,
    }
  },
}
</script>
