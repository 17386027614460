window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    GatewayListDropDown: [],
  },

  mutations: {
    GatewayListData(state, data) {
      state.GatewayListDropDown = data
    },
  },
  actions: {
    async getGatewayList(
      { commit, state },
      message = 'Please select a gateway'
    ) {
      let gatewayList = [{ value: '', text: message }]
      await axios
        .get(
          'v1/gateway/get-gateways'
        )
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((gateway) => {
              let obj = {}
              obj.value = gateway.id
              obj.text = gateway.gw_name
              gatewayList.push(obj)
            })
            commit('GatewayListData', gatewayList)
          }
        })
    },
  },
}
