window._ = require('lodash')
window.axios = require('axios')
export default {
  namespaced: true,
  state: {
    suburbListDropDown: [],
  },

  mutations: {
    suburbListData(state, data) {
      state.suburbListDropDown = data
    },
  },
  actions: {
    async getSuburbListByStateID(
      { commit, state },
      countryInfo,
      message = 'Please select a suburb/town'
    ) {
      let suburbList = [{ value: '', text: message }]
      await axios
        .get(
          'v1/address/suburb-names-for-state/' +
            countryInfo.countryId +
            '/' +
            countryInfo.stateId
        )
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((suburb) => {
              let obj = {}
              obj.text = suburb.suburb_name
              obj.value = suburb.suburb_id
              suburbList.push(obj)
            })
            commit('suburbListData', suburbList)
          }
        })
    },
  },
}
