import { createStore } from 'vuex'
import UserModule from '../store/UserModule'
import CountryModule from './Settings/Common/CountryModule'
import StateModule from '../store/Settings/Common/StateModule'
import SuburbModule from '../store/Settings/Common/SuburbModule'
import PostalCodeModule from '../store/Settings/Common/PostalCodeModule'
import GatewayModule from '../store/Settings/Gateways/GatewayModule'
import RulesModule from '../store/TransactionRouter/RulesModule'
import ApiConfigModule from '../store/Settings/Gateways/ApiConfigModule'
import AcceptedCountriesModule from '../store/Settings/Gateways/AcceptedCountriesModule'
import CurrencyModule from '../store/Settings/Common/CurrencyModule'
import LanguageModule from '../store/Settings/Languages/LanguageModule'
import QrPaymentModule from '../store/Settings/Common/QRPaymentModule'
import GwIdentityModule from "@/store/Settings/Common/GwIdentityModule";

export default createStore({
  state: {
    sidebarDisable: false,
  },
  mutations: {},
  actions: {},
  modules: {
    User: UserModule,
    Country: CountryModule,
    State: StateModule,
    Suburb: SuburbModule,
    PostalCode: PostalCodeModule,
    Gateway: GatewayModule,
    GwApiConfig: ApiConfigModule,
    Currency: CurrencyModule,
    GwAcceptedCountries: AcceptedCountriesModule,
    TransactionRules: RulesModule,
    Languages: LanguageModule,
    QR:QrPaymentModule,
    GwIdentity:GwIdentityModule
  },
})
