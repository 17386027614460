<template>
  <button
    id="header-back_button"
    type="button"
    class="
      mr-3
      w-8
      h-8
      bg-white bg-opacity-50
      rounded-full
      hover:bg-opacity-60
      focus:outline-none
      focus:ring-2 focus:ring-offset-2 focus:ring-primary
    "
    @click=";[routeTo ? $router.push(routeTo) : $router.back()]"
  >
    <svg-icon
      class="w-auto h-8 text-secondary bg-opacity-100"
      icon="caret-left"
    />
  </button>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'BackButton',

  components: {
    SvgIcon,
  },
  props: {
    routeTo: {
      type: Object,
      default: null,
    },
  },
}
</script>
