<template>
  <TransitionRoot
    appear
    :show="show"
  >
    <div class="fixed inset-0 z-30 overflow-y-auto">
      <div class="min-h-screen px-4 text-center">
        <div class="fixed inset-0 z-in1 bg-black opacity-70" />

        <span
          class="inline-block h-screen align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <div
            :id="[show ? 'modal_focus-loop' : '']"
            :class="[modalSize ? modalSize : 'max-w-md']"
            class="inline-block w-full p-6 my-2 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
          >
            <div class="flex items-center">
              <div class="flex-1">
                <div
                  v-if="title"
                  as="h4"
                  class="mr-2 text-lg font-medium leading-6 text-black text-opacity-90"
                >
                  {{ t(title) }}
                </div>
              </div>


              <button
                v-if="printIcon"
                class="px-2"
                type="button"
                @click="printIconBtn"
              >
                <svg-icon
                  class="w-6 h-6 text-black"
                  icon="print"
                />
              </button>

              <button
                v-if="downloadIcon"
                class="px-2"
                type="button"
                @click="downloadIconBtn"
              >
                <svg-icon
                  class="w-8 h-8 text-black"
                  icon="download"
                />
              </button>

              <button
                v-if="closeIcon"
                class="group rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary px-2"
                type="button"
                @click="closeModal"
              >
                <svg-icon
                  class="w-7 h-7 text-gray-800 group-hover:text-gray-900"
                  icon="close"
                />
              </button>
            </div>

            <div
              class="mt-2 text-sm text-gray-500"
              :class="showPreLoader ? 'pointer-events-none' : ''"
            >
              <p
                v-if="message"
                class="mb-3"
              >
                {{ t(message) }}
              </p>
              <slot name="modal-body" />
            </div>

            <div
              class="flex flex-wrap items-center justify-end gap-2"
              :class="showPreLoader ? 'pointer-events-none' : ''"
            >
              <button
                :id="[show ? 'btn-close_modal' : '']"
                class="w-0 h-0 focus:outline-none"
                type="button"
              >
                <!-- Invisible auto-focus button -->
              </button>
              <Button
                v-if="confirmButton"
                class="btn-wide"
                type="button"
                @click="confirmAction"
              >
                {{ t(confirmButtonText) }}
              </Button>
              <ButtonSecondary
                v-if="confirmButton && closeButton"
                class="btn-wide"
                type="button"
                @click="closeModal"
              >
                {{ t(buttonText) }}
              </ButtonSecondary>
              <Button
                v-else-if="closeButton"
                class="btn-wide"
                type="button"
                @click="closeModal"
              >
                {{ t(buttonText) }}
              </Button>
              <slot name="modal-actions" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
import { ref, watchEffect } from 'vue'
import focusTrap from '@/helpers/focus-trap.js'
import { TransitionRoot, TransitionChild } from '@headlessui/vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import {useI18n} from "vue-i18n";

export default {
  name: 'ModalForm',

  components: {
    Button,
    SvgIcon,
    TransitionRoot,
    TransitionChild,
    ButtonSecondary,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: null,
    },

    modalSize: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    message: {
      type: String,
      default: null,
    },

    closeIcon: {
      type: Boolean,
      default: true,
    },

    downloadIcon:{
      type: Boolean,
      default: false,
    },

    printIcon:{
      type: Boolean,
      default: false,
    },

    closeButton: {
      type: Boolean,
      default: true,
    },

    buttonText: {
      type: String,
      default: 'Done',
    },

    confirmButtonText: {
      type: String,
      default: 'Yes',
    },

    confirmButton: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['openModal', 'closeModal', 'confirmModal', 'downloadIconBtn', 'printIconBtn'],

  setup(props, { emit }) {
    const isOpen = ref(true)
    const focusRef = ref(null)
    const showPreLoader = ref(true)
    let focusLoop = ref(null)
    const { t } = useI18n()

    watchEffect(() => {
      if (props.show) {
        openModal()
      }

      props.isLoading ? showPreLoader.value = true : showPreLoader.value = false
    })

    function openModal() {
      emit('openModal')
      isOpen.value = true

      setTimeout(() => {
        focusLoop = document.getElementById('modal_focus-loop')
        focusRef.value = document.getElementById('btn-close_modal')
        focusTrap.trapFocus(focusLoop, focusRef.value)
        showPreLoader.value = false
      }, 1000)
    }

    function closeModal() {
      emit('closeModal')
      isOpen.value = false
    }

    function downloadIconBtn() {
      emit('downloadIconBtn')
      // isOpen.value = false
    }
    function printIconBtn(){
      emit('printIconBtn')

    }

    function confirmAction() {
      emit('confirmModal')
      isOpen.value = false
    }

    return {
      t,
      isOpen,
      focusRef,
      focusLoop,
      closeModal,
      confirmAction,
      showPreLoader,
      downloadIconBtn,
      printIconBtn,
    }
  },
}
</script>
